import { BoxVector, FlexBoxPosition } from '@prisma/client'
import { transformBoxPosition } from 'components/Box/Box'
import { colors } from 'utils/styles'

export function PositionSettingsItem({
  actualVector,
  vector,
  horizontalPosition,
  verticalPosition,
  actualHorizontalPosition,
  actualVerticalPosition,
  onUpdatePosition,
}: {
  actualVector: BoxVector
  vector: BoxVector
  horizontalPosition: FlexBoxPosition
  verticalPosition: FlexBoxPosition
  actualHorizontalPosition: FlexBoxPosition
  actualVerticalPosition: FlexBoxPosition
  onUpdatePosition: ({}: { horizontalPosition: FlexBoxPosition; verticalPosition: FlexBoxPosition }) => void
}) {
  const active =
    actualHorizontalPosition == horizontalPosition && actualVerticalPosition == verticalPosition && vector == actualVector

  const transformedVerticalPosition = transformBoxPosition(verticalPosition)
  const transformedHorizontalPosition = transformBoxPosition(horizontalPosition)

  return (
    <div
      onClick={() => {
        onUpdatePosition({ horizontalPosition, verticalPosition })
      }}
      css={{
        willChange: 'alignContent, alignItems, justifyContent, flexDirection, background',
        width: 20,
        height: 20,
        margin: 1,
        display: 'flex',
        padding: '2px',
        cursor: 'pointer',
        position: 'relative',
      }}
      style={{
        alignContent: transformedVerticalPosition,
        alignItems: transformedVerticalPosition,
        justifyContent: transformedHorizontalPosition,
        flexDirection: vector == 'Bottom' ? 'column' : undefined,
        background: active ? colors.blue : undefined,
      }}
    >
      <div
        css={{
          willChange: 'border',
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          borderRadius: '1px',
        }}
        style={{
          border: `0.5px ${active ? 'none' : 'solid'} ${colors.blue}`,
        }}
      ></div>
      {[30, 70, 40].map((height, index) => {
        return (
          <div
            key={index}
            css={{
              willChange: 'margin, height, width, background',
              borderRadius: '1px',
            }}
            style={{
              margin: vector == 'Bottom' ? '0.5px 0' : '0 0.5px',
              height: vector == 'Bottom' ? '3px' : `${height}%`,
              width: vector == 'Bottom' ? `${height}%` : '3px',
              background: active ? 'white' : colors.blue,
            }}
          ></div>
        )
      })}
    </div>
  )
}
