import { Group, SimpleGrid, Text, createStyles } from '@mantine/core'
import { IconBrandPagekit, IconBrandTelegram, IconBrandVk, IconCode, IconLayoutList, IconMail } from '@tabler/icons'

import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import Link from 'next/link'
import React from 'react'

import { mqBox } from 'config/mq'

type IntergrationsTab =
  | 'metrika'
  | 'mailNotifications'
  | 'telegramNotifications'
  | 'telegramGift'
  | 'vk'
  | 'bitrix'
  | 'headCode'
  | 'bodyCode'

const siteInnerPages: {
  tab: IntergrationsTab
  name: string
  icon: (color: string) => React.ReactNode
}[] = [
  {
    tab: 'metrika',
    name: 'Яндекс Метрика',
    icon: (color: string) => (
      <>
        <IconBrandPagekit color={color} />
      </>
    ),
  },
  {
    tab: 'telegramNotifications',
    name: 'Уведомления в ТГ',
    icon: (color: string) => (
      <>
        <IconLayoutList color={color} />
      </>
    ),
  },
  {
    tab: 'mailNotifications',
    name: 'Уведомления на почту',
    icon: (color: string) => (
      <>
        <IconMail color={color} />
      </>
    ),
  },
  {
    tab: 'telegramGift',
    name: 'Продвижение ТГ',
    icon: (color: string) => (
      <>
        <IconBrandTelegram color={color} />
      </>
    ),
  },
  {
    tab: 'vk',
    name: 'Пиксель Вконтакте',
    icon: (color: string) => (
      <>
        <IconBrandVk color={color} />
      </>
    ),
  },
  {
    tab: 'bitrix',
    name: 'Битрикс24',
    icon: (color: string) => (
      <>
        <IconBrandPagekit color={color} />
      </>
    ),
  },
  {
    tab: 'headCode',
    name: 'Код в тег <head>',
    icon: (color: string) => (
      <>
        <IconCode color={color} />
      </>
    ),
  },
  {
    tab: 'bodyCode',
    name: 'Код в тег <body>',
    icon: (color: string) => (
      <>
        <IconCode color={color} />
      </>
    ),
  },
]

const menuWidth = 250

export function IntegrationsMenu({ activeTab, children }: { activeTab: IntergrationsTab; children: React.ReactNode }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const { classes: classesForTab } = createStyles({
    tab: {
      border: 'none',
      background: 'none',
      margin: '0 8px 0 0',
    },
  })()

  const siteInfoHeight = 36

  return (
    <div
      css={{
        position: 'relative',
        zIndex: 1000,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        overflowY: 'auto',
      }}
    >
      <div
        css={{
          borderRight: 'none',
          height: 'auto',
          width: '100%',
          borderBottom: `1px solid ${cabinetColors.close}`,
          [mqBox.desktop]: {
            width: menuWidth,
            height: '100%',
            borderRight: `1px solid ${cabinetColors.close}`,
            borderBottom: 'none',
            overflowY: 'auto',
            flexWrap: 'wrap',
          },
        }}
      >
        <>
          <SimpleGrid p={12}>
            <Group
              css={{
                gap: 12,
                display: 'flex',
                flexWrap: 'wrap',
                [mqBox.desktop]: {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  display: 'flex',
                  gap: 4,
                },
              }}
            >
              {siteInnerPages.map(tab => {
                return (
                  <Link href={`/panel/sitesV2/${siteID}/intergrations/${tab.tab == 'metrika' ? '' : tab.tab}`}>
                    <Group
                      css={{
                        cursor: 'pointer',
                        [mqBox.desktop]: {
                          border: `1px solid ${activeTab == tab.tab ? cabinetColors.border : 'transparent'}`,
                          padding: '12px 8px',
                          borderRadius: 8,
                          width: '100%',
                          display: 'block',
                          textAlign: 'left',
                          '&:hover': {
                            background: `white`,
                            border: `1px dashed ${cabinetColors.border}`,
                          },
                        },
                      }}
                      className={classesForTab.tab}
                    >
                      <Group
                        css={{
                          gap: 4,
                          [mqBox.desktop]: {
                            gap: 12,
                          },
                        }}
                      >
                        {tab.icon(activeTab == tab.tab ? '#065BFF' : '#969696')}
                        <Text
                          size={14}
                          css={{
                            color: activeTab == tab.tab ? '555454' : '#333',
                          }}
                        >
                          {tab.name}
                        </Text>
                      </Group>
                    </Group>
                  </Link>
                )
              })}
            </Group>
          </SimpleGrid>
        </>
      </div>
      <div
        css={{
          width: '100%',
          height: 'auto',
          position: 'relative',
          paddingTop: cabinetSizes.pageTop,
          paddingBottom: cabinetSizes.pageTop,
          [mqBox.desktop]: {
            width: `calc(100% - ${menuWidth}px)`,
            height: '100%',
            overflowY: 'auto',
          },
        }}
      >
        {children}
      </div>
    </div>
  )
}
