import { Button, Paper, SimpleGrid, Text } from '@mantine/core'
import { PageLoader } from 'components/PageLoader/PageLoader'
import { DataSelectContext, MyDataSelectContext, useGetSelectData } from 'generation/centerFrontedEngine'
import { synchronFrontendEngineAtSite } from 'generation/synchronFrontendAtSite'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { getPathToFont } from 'utils/getPathToFile'
import { trpc } from 'utils/trpc'

export const MySyncSiteContext = createContext<{ siteID: string; userID: string }>({
  siteID: '',
  userID: '',
})
export const useSyncSiteContext = () => useContext(MySyncSiteContext)

export const SyncSiteContextProviderAuth = ({
  userToken,
  children,
  userID,
}: {
  userToken: string
  children: JSX.Element | JSX.Element[] | undefined
  userID: string
}) => {
  const router = useRouter()
  const { siteID } = router.query

  const [connected, setConnected] = useState(!synchronFrontendEngineAtSite.getIsNotConnected({ modelID: `${siteID}` }))

  useEffect(() => {
    synchronFrontendEngineAtSite.connect({
      callback: value => {
        setConnected(value)
      },
      modelID: `${siteID}`,
      userToken,
    })
  }, [])

  const [syncContextState, setSyncContextState] = useState({ siteID: `${siteID}`, userID })

  useEffect(() => {
    setSyncContextState({ siteID: `${siteID}`, userID })
    console.log('setSyncContextState({ siteID: `${siteID}`, userID })')
  }, [siteID, userID])

  const [typeDataSelectContext] = useState<DataSelectContext>({
    type: 'Site',
  })

  return (
    <MyDataSelectContext.Provider value={typeDataSelectContext}>
      <MySyncSiteContext.Provider value={syncContextState}>
        {connected ? (
          <CheckAccessToSite siteID={`${siteID}`} userID={userID}>
            <>
              <SiteColorsStyleProviderShell modelID={`${siteID}`}>
                <>{children}</>
              </SiteColorsStyleProviderShell>
            </>
          </CheckAccessToSite>
        ) : (
          <PageLoader
            textAnimation={[
              'Загружаются страницы',
              'Загружается библиотека картинок',
              'Загружаются формы',
              'Загружаются квизы',
              'Загружаются настройки',
              'Загружаются заявки',
            ]}
          />
        )}
      </MySyncSiteContext.Provider>
    </MyDataSelectContext.Provider>
  )
}

const CheckAccessToSite = ({ siteID, userID, children }: { siteID: string; userID: string; children: JSX.Element }) => {
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const router = useRouter()
  return site.userId == userID ? (
    children
  ) : (
    <div
      css={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        css={{
          width: 300,
        }}
        withBorder
        p={'sm'}
      >
        <SimpleGrid cols={1}>
          <Text>У вас не доступа к сайту</Text>

          <Button onClick={() => router.push(`/panel/`)}>Перейти к сайтам</Button>
        </SimpleGrid>
      </Paper>
    </div>
  )
}

const SyncSiteContextProviderAuthMemo = React.memo(SyncSiteContextProviderAuth, () => true)

const SiteColorsStyleProviderShell = ({ modelID, children }: { modelID: string; children: JSX.Element }) => {
  const site = useGetSelectData({ modelID, selectID: 'Site1' })

  return (
    <>
      {site.colors.map(color => (
        <SiteColorsStyleProvider key={color.id} selectID={color.selectID} modelID={color.id} />
      ))}
      {site.fontWeights.map(fontWeight => (
        <SiteFontStyleProvider key={fontWeight.id} selectID={fontWeight.selectID} modelID={fontWeight.id} />
      ))}
      {children}
    </>
  )
}

const SiteColorsStyleProvider = ({ selectID, modelID }: { selectID: 'Color1'; modelID: string }) => {
  const color = useGetSelectData({ modelID, selectID })

  return (
    <style>
      {`body {
      --color-${color.name}: ${color.value};
    }`}
    </style>
  )
}
const SiteFontStyleProvider = ({ selectID, modelID }: { selectID: 'FontWeight1'; modelID: string }) => {
  const fontWeight = useGetSelectData({ modelID, selectID })

  return (
    <style>{`@font-face {
    font-family: "${fontWeight.id}";
    src: url('${getPathToFont({ fontName: fontWeight.name, folderName: fontWeight.folder })}');
  }`}</style>
  )
}

export const SyncSiteContextProvider = ({ children }: { children: JSX.Element | JSX.Element[] | undefined }) => {
  const userToken = trpc.useQuery(['user.getUserToken'], {})

  return userToken.error ? (
    <>token not found</>
  ) : userToken.data?.userToken ? (
    <SyncSiteContextProviderAuthMemo children={children} userToken={userToken.data.userToken} userID={userToken.data.userID} />
  ) : (
    <PageLoader />
  )
}
