import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'

import { Paper, SimpleGrid, Text } from '@mantine/core'

export function ChooseSiteTableViewSync({
  onChooseTable,
  title = 'Выберите вывод таблицы',
  tableID,
  showNestedTables,
}: {
  title?: string
  onChooseTable: ({}: { tableID: string }) => void
  tableID?: string
  showNestedTables?: boolean
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  // костыль, таблицу надо проверить в списке таблиц сайта, вдруг она удалена
  const currentTable = useGetSelectDataUndefined({ modelID: tableID, selectID: 'Table1' })

  if (currentTable) {
    if (currentTable.columnTableTypeSchema) {
      return (
        <TableColumnSimpleSync
          modelID={currentTable.columnTableTypeSchema.id}
          selectID={currentTable.columnTableTypeSchema.selectID}
        />
      )
    }
    return (
      <>
        <Text>{currentTable.name}</Text>
      </>
    )
  }

  return (
    <>
      <SimpleGrid spacing={8}>
        <Text
          css={{
            fontWeight: 'bold',
          }}
        >
          {title}
        </Text>
        <SimpleGrid>
          {site.tableConnectors.map(tableConnector => {
            return (
              <TableConnectorSync
                onChooseTable={onChooseTable}
                key={tableConnector.id}
                modelID={tableConnector.id}
                selectID={tableConnector.selectID}
                showNestedTables={showNestedTables}
              />
            )
          })}
        </SimpleGrid>
      </SimpleGrid>
    </>
  )
}
function TableColumnSimpleSync({ modelID, selectID }: { modelID: string; selectID: 'TableColumnSimple' }) {
  const tableColumn = useGetSelectData({ modelID, selectID })

  return <Text>{tableColumn.name}</Text>
}
function TableConnectorSync({
  modelID,
  selectID,
  onChooseTable,
  showNestedTables,
}: {
  modelID: string
  selectID: 'TableConnector1'
  onChooseTable: ({}: { tableID: string }) => void
  showNestedTables?: boolean
}) {
  const tableConnector = useGetSelectData({ modelID, selectID })

  return (
    <>
      <TableDataSync
        showNestedTables={showNestedTables}
        onChooseTable={onChooseTable}
        modelID={tableConnector.tableSchema.id}
        selectID={tableConnector.tableSchema.selectID}
      />
    </>
  )
}
function TableDataSync({
  name,
  modelID,
  selectID,
  onChooseTable,
  showNestedTables,
}: {
  name?: string
  modelID: string
  selectID: 'Table1'
  onChooseTable: ({}: { tableID: string }) => void
  showNestedTables?: boolean
}) {
  const table = useGetSelectData({ modelID, selectID })

  return (
    <>
      <SimpleGrid
        css={{
          cursor: 'pointer',
        }}
      >
        <Text
          onClick={() => {
            onChooseTable({ tableID: table.id })
          }}
        >
          {name || table.name}
        </Text>

        <Paper>
          <SimpleGrid
            css={{
              cursor: 'pointer',
            }}
          >
            {table.colums.map(column => {
              return (
                <TableColumnSync
                  showNestedTables={showNestedTables}
                  key={column.id}
                  modelID={column.id}
                  selectID={column.selectID}
                  onChooseTable={onChooseTable}
                />
              )
            })}
          </SimpleGrid>
        </Paper>
      </SimpleGrid>
    </>
  )
}
function TableColumnSync({
  modelID,
  selectID,
  onChooseTable,
  showNestedTables,
}: {
  modelID: string
  selectID: 'TableColumn1'
  onChooseTable: ({}: { tableID: string }) => void
  showNestedTables?: boolean
}) {
  const tableColumn = useGetSelectData({ modelID, selectID })

  if (showNestedTables && tableColumn.type == 'Table' && tableColumn.columnTableSchema) {
    return (
      <TableDataSync
        onChooseTable={onChooseTable}
        name={tableColumn.name}
        modelID={tableColumn.columnTableSchema.id}
        selectID={tableColumn.columnTableSchema.selectID}
      />
    )
  }

  return <></>
}
