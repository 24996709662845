import { ActionIcon, Button, Group, Paper, SimpleGrid, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconHandGrab } from '@tabler/icons'
import EditorText from 'components/EditorText/EditorText'
import { SiteContext, SiteContextProvider } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { arrayMove, List } from 'react-movable'
import { FormSelect } from 'server/selects'
import { trpc } from 'utils/trpc'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

export function SiteForms() {
  const site = useContext(SiteContext)
  const createForm = trpc.useMutation('user.createForm')

  return (
    <div>
      <Button
        loading={createForm.isLoading}
        m={30}
        compact
        onClick={() =>
          createForm.mutate({
            siteID: site.id,
          })
        }
      >
        Создать форму
      </Button>

      <SimpleGrid cols={2}>
        {site.formsV1.map(form => (
          <SiteForm key={form.id} {...form} />
        ))}
      </SimpleGrid>
    </div>
  )
}

function SiteForm(form: NonNullable<FormSelect>) {
  const updateForm = trpc.useMutation('user.updateForm')
  const deleteForm = trpc.useMutation('user.deleteForm')
  const createFormInput = trpc.useMutation('user.createFormInput')
  const updateFormInput = trpc.useMutation('user.updateFormInput')

  const updateFormForm = useForm({
    initialValues: {
      title: form.title,
      formButtonText: form.formButtonText,
    },
  })

  const formExtraInput = useForm({
    initialValues: {
      placeholder: '',
    },
  })

  return (
    <div css={{ minWidth: '300px', marginRight: '30px' }}>
      <Paper withBorder p="xs">
        <form
          onSubmit={updateFormForm.onSubmit(async values => {
            await updateForm.mutate({
              formID: form.id,
              title: values.title,
              formButtonText: values.formButtonText,
            })
          })}
        >
          <EditorText
            defaultValue={form.title}
            title="Заголовок"
            onChange={value => updateFormForm.setValues({ ...updateFormForm.values, title: value })}
          />

          <TextInput required label="Текст кнопки" placeholder="" {...updateFormForm.getInputProps('formButtonText')} />

          <Group position="right" mt="md">
            <Button
              color="red"
              loading={deleteForm.isLoading}
              onClick={() =>
                deleteForm.mutate({
                  formID: form.id,
                })
              }
            >
              Удалить
            </Button>
            <Button loading={updateForm.isLoading} type="submit">
              Обновить
            </Button>
          </Group>
        </form>

        <Paper mt={16} withBorder p="xs">
          <List
            lockVertically
            values={form.extraInputs}
            onChange={({ oldIndex, newIndex }) => {
              // TODO это пиздец какой костыль массивом проходиться и сохранять порядок
              arrayMove(form.extraInputs, oldIndex, newIndex).map(async (extraInput, index) => {
                updateFormInput.mutate({
                  formInputID: extraInput.id,
                  order: index,
                })
              })
            }}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ value, props }) => (
              <div
                css={{
                  zIndex: 10000,
                  position: 'relative',
                }}
                key={value.id}
                {...props}
              >
                <SiteFormExtraInput key={value.id} {...value} />
              </div>
            )}
          />
        </Paper>

        <Button
          mt="md"
          onClick={() =>
            createFormInput.mutate({
              formID: form.id,
            })
          }
          loading={createFormInput.isLoading}
          type="submit"
        >
          Добавить поле ввода
        </Button>
      </Paper>
    </div>
  )
}

function SiteFormExtraInput(formInput: NonNullable<FormSelect>['extraInputs'][0]) {
  const updateFormInput = trpc.useMutation('user.updateFormInput')
  const deleteFormInput = trpc.useMutation('user.deleteFormInput')

  const form = useForm({
    initialValues: {
      placeholder: formInput.placeholder,
    },
  })

  return (
    <form
      onSubmit={form.onSubmit(async values => {
        await updateFormInput.mutate({
          formInputID: formInput.id,
          placeholder: values.placeholder,
        })
      })}
    >
      <TextInput size="xs" required label="placeholder" placeholder="" {...form.getInputProps('placeholder')} />

      <Group position="right" mt="md">
        <ActionIcon size={'md'} data-movable-handle>
          <IconHandGrab />
        </ActionIcon>
        <Button
          compact
          onClick={() =>
            deleteFormInput.mutate({
              formInputID: formInput.id,
            })
          }
          loading={deleteFormInput.isLoading}
          color="red"
        >
          Удалить
        </Button>
        <Button compact loading={updateFormInput.isLoading} type="submit">
          Обновить
        </Button>
      </Group>
    </form>
  )
}
