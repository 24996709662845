import { memo, useEffect, useState } from 'react'

export const CodeComponentMemo = memo(CodeComponent, (prev, next) => {
  return true
})

export function CodeComponent({ code }: { code: string }) {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])
  return (
    <div
      style={{
        width: '100%',
      }}
      dangerouslySetInnerHTML={{ __html: code }}
    ></div>
  )
}
