import {
  ActionIcon,
  Badge,
  Button,
  Container,
  Divider,
  Group,
  Menu,
  Paper,
  SimpleGrid,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import {
  IconArrowBackUp,
  IconCirclePlus,
  IconPlaylistAdd,
  IconRefresh,
  IconSettings,
  IconSquareArrowDown,
  IconTrash,
} from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { getDataAtStoreSite } from 'generation/synchronFrontendAtSite'
import Link from 'next/link'
import { memo, useEffect, useState } from 'react'
import { List, arrayMove } from 'react-movable'
import { EditorTextSync } from 'syncComponents/EditorTextSync/EditorTextSync'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { SiteFaviconSync } from 'syncComponents/SiteFaviconSync/SiteFaviconSync'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'

import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { synchronFrontendEngineAtSite } from 'generation/synchronFrontendAtSite'
import { trpc } from 'utils/trpc'

function DragJSX() {
  return (
    <svg
      css={{
        display: 'block',
        cursor: 'pointer',
      }}
      data-movable-handle
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="14" height="18" rx="1.5" fill="#F9F9F9" />
      <rect x="0.5" y="0.5" width="14" height="18" rx="1.5" stroke="#E1E2E4" />
      <path
        d="M4.2002 9.50033C4.2002 9.71029 4.26604 9.91165 4.38325 10.0601C4.50046 10.2086 4.65943 10.292 4.8252 10.292C4.99096 10.292 5.14993 10.2086 5.26714 10.0601C5.38435 9.91165 5.4502 9.71029 5.4502 9.50033C5.4502 9.29036 5.38435 9.089 5.26714 8.94053C5.14993 8.79207 4.99096 8.70866 4.8252 8.70866C4.65943 8.70866 4.50046 8.79207 4.38325 8.94053C4.26604 9.089 4.2002 9.29036 4.2002 9.50033ZM4.2002 15.042C4.2002 15.252 4.26604 15.4533 4.38325 15.6018C4.50046 15.7503 4.65943 15.8337 4.8252 15.8337C4.99096 15.8337 5.14993 15.7503 5.26714 15.6018C5.38435 15.4533 5.4502 15.252 5.4502 15.042C5.4502 14.832 5.38435 14.6307 5.26714 14.4822C5.14993 14.3337 4.99096 14.2503 4.8252 14.2503C4.65943 14.2503 4.50046 14.3337 4.38325 14.4822C4.26604 14.6307 4.2002 14.832 4.2002 15.042ZM4.2002 3.95866C4.2002 4.16862 4.26604 4.36999 4.38325 4.51845C4.50046 4.66692 4.65943 4.75033 4.8252 4.75033C4.99096 4.75033 5.14993 4.66692 5.26714 4.51845C5.38435 4.36999 5.4502 4.16862 5.4502 3.95866C5.4502 3.7487 5.38435 3.54733 5.26714 3.39887C5.14993 3.2504 4.99096 3.16699 4.8252 3.16699C4.65943 3.16699 4.50046 3.2504 4.38325 3.39887C4.26604 3.54733 4.2002 3.7487 4.2002 3.95866Z"
        stroke="#B6B6B6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.6001 9.50033C9.6001 9.71029 9.66595 9.91165 9.78316 10.0601C9.90037 10.2086 10.0593 10.292 10.2251 10.292C10.3909 10.292 10.5498 10.2086 10.667 10.0601C10.7842 9.91165 10.8501 9.71029 10.8501 9.50033C10.8501 9.29036 10.7842 9.089 10.667 8.94053C10.5498 8.79207 10.3909 8.70866 10.2251 8.70866C10.0593 8.70866 9.90037 8.79207 9.78316 8.94053C9.66595 9.089 9.6001 9.29036 9.6001 9.50033ZM9.6001 15.042C9.6001 15.252 9.66595 15.4533 9.78316 15.6018C9.90037 15.7503 10.0593 15.8337 10.2251 15.8337C10.3909 15.8337 10.5498 15.7503 10.667 15.6018C10.7842 15.4533 10.8501 15.252 10.8501 15.042C10.8501 14.832 10.7842 14.6307 10.667 14.4822C10.5498 14.3337 10.3909 14.2503 10.2251 14.2503C10.0593 14.2503 9.90037 14.3337 9.78316 14.4822C9.66595 14.6307 9.6001 14.832 9.6001 15.042ZM9.6001 3.95866C9.6001 4.16862 9.66595 4.36999 9.78316 4.51845C9.90037 4.66692 10.0593 4.75033 10.2251 4.75033C10.3909 4.75033 10.5498 4.66692 10.667 4.51845C10.7842 4.36999 10.8501 4.16862 10.8501 3.95866C10.8501 3.7487 10.7842 3.54733 10.667 3.39887C10.5498 3.2504 10.3909 3.16699 10.2251 3.16699C10.0593 3.16699 9.90037 3.2504 9.78316 3.39887C9.66595 3.54733 9.6001 3.7487 9.6001 3.95866Z"
        stroke="#B6B6B6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const firstLevelButtonWidth = 40
const secondLevelButtonWidth = 40
const columnHeight = 32
const rowOpenOpacity = 0.3

export function SiteTablesSync({}: {}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const createTable = trpc.useMutation('user.table.createTable', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <Container>
      <Paper p="md">
        <Group px="md" position="apart">
          <Group
            css={{
              alignCenter: 'flex-start',
            }}
          >
            <Title order={2}>CMS</Title>
            <Text
              css={{
                lineHeight: '110%',
              }}
              size={10}
              color={cabinetColors.placeholder}
            >
              система <br /> управления
              <br /> контентом
            </Text>
          </Group>
          <AccentButton
            loading={createTable.isLoading}
            onClick={() => {
              const name = prompt('Введите название списка')
              if (name) {
                createTable.mutate({
                  siteID: site.id,
                  name,
                })
              }
            }}
            leftIcon={<IconCirclePlus />}
          >
            Добавить новый список
          </AccentButton>
        </Group>
        {site.tableConnectors.length ? (
          <>
            <Divider my={'xl'} />
            <SimpleGrid px="md">
              {site.tableConnectors.map(tableConnector => (
                <SiteTableConnectorSyncSimple
                  key={tableConnector.id}
                  modelID={tableConnector.id}
                  selectID={tableConnector.selectID}
                />
              ))}
            </SimpleGrid>
          </>
        ) : null}
      </Paper>
    </Container>
  )
}

export function SiteTableConnectorSyncSimple({ selectID, modelID }: { selectID: 'TableConnector1'; modelID: string }) {
  const tableConnector = useGetSelectData({ modelID, selectID })
  const table = useGetSelectDataUndefined({
    modelID: tableConnector.tableSchema.id,
    selectID: tableConnector.tableSchema.selectID,
  })
  if (!table) return <Text color="red">table not found</Text>
  const { siteID } = useSyncSiteContext()

  return (
    <>
      <Link href={`/panel/sitesV2/${siteID}/tables/${tableConnector.id}`}>
        <Paper
          css={{
            cursor: 'pointer',
            background: cabinetColors.background,
            border: `1px solid ${cabinetColors.border}`,
            boxShadow: 'none',
          }}
          p="lg"
        >
          <Group position="apart">
            <Title order={3}>{table.name}</Title>
            <GrayButton white={true} leftIcon={<IconSettings />}>
              Редактировать
            </GrayButton>
          </Group>
        </Paper>
      </Link>
    </>
  )
}

export function SiteTableConnectorSync({ selectID, modelID }: { selectID: 'TableConnector1'; modelID: string }) {
  const tableConnector = useGetSelectData({ modelID, selectID })

  return (
    <>
      <Group position="center">
        <SimpleGrid px="xs" py={cabinetSizes.pageTop}>
          <Paper
            css={{
              overflowX: 'auto',
              maxWidth: '100%',
            }}
            p="lg"
          >
            <SiteTableDataSyncMemo
              tableSchemaModelID={tableConnector.tableSchema.id}
              tableSchemaSelectID={tableConnector.tableSchema.selectID}
              modelID={tableConnector.tableData.id}
              selectID={tableConnector.tableData.selectID}
              firstLevel={true}
            />
          </Paper>
        </SimpleGrid>
      </Group>
    </>
  )
}

const SiteTableDataSyncMemo = memo(SiteTableDataSync, (prev, next) => {
  return (
    prev.modelID == next.modelID &&
    prev.tableSchemaModelID == next.tableSchemaModelID &&
    prev.hideName == next.hideName &&
    prev.firstLevel == next.firstLevel
  )
})

function SiteTableDataSync({
  selectID,
  modelID,
  tableSchemaModelID,
  tableSchemaSelectID,
  hideName,
  firstLevel,
}: {
  selectID: 'TableData1'
  modelID: string
  tableSchemaModelID: string
  tableSchemaSelectID: 'Table1'
  hideName?: boolean
  firstLevel?: boolean
}) {
  const tableData = useGetSelectDataUndefined({ modelID, selectID })

  const createRow = trpc.useMutation('user.table.createRow', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createColumn = trpc.useMutation('user.table.createColumn', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateRowOrder = trpc.useMutation('user.table.updateRowOrder', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  if (!tableData) return <Text color="red">table data not found</Text>

  const table = useGetSelectDataUndefined({ modelID: tableSchemaModelID, selectID: tableSchemaSelectID })
  if (!table) return <Text color="red">table not found</Text>

  const [items, setItems] = useState(tableData.rows)
  const [newColumnName, setNewColumnName] = useState('')
  const [newColumnType, setNewColumnType] = useState<'Text' | 'Image' | 'Number' | 'Table'>('Text')

  const [rowsIsOpen, setRowsIsOpen] = useState(firstLevel ? true : false)

  useEffect(() => {
    setItems(tableData.rows)
  }, [tableData.rows])
  const { siteID } = useSyncSiteContext()

  return (
    <SimpleGrid
      css={{
        backgroundColor: 'white',
        // boxShadow: '0 0 10px 0 rgba(0,0,0,0.45) inset',

        overflowX: 'auto',
      }}
    >
      {hideName ? undefined : (
        <>
          <Group position="apart">
            <Group>
              <Link href={`/panel/sitesV2/${siteID}/tables`}>
                <ActionIcon size="lg">
                  <IconArrowBackUp />
                </ActionIcon>
              </Link>

              <Title order={firstLevel ? 2 : 3}>{table.name}</Title>
            </Group>
          </Group>
        </>
      )}

      <SimpleGrid
        css={{
          border: firstLevel ? `1px solid ${cabinetColors.border}` : undefined,
          width: 'max-content',
          minWidth: '100%',
        }}
        spacing={0}
      >
        <Group
          css={{
            flexWrap: 'nowrap',
            gap: 0,
            alignItems: 'stretch',
            // borderBottom: rowsIsOpen ? undefined : `1px solid ${cabinetColors.border}`,
            width: '100%',
          }}
        >
          <Group
            css={{
              width: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
              minWidth: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
              minHeight: columnHeight,
              alignItems: 'center',
              alignContent: 'center',
              padding: 6,
              opacity: rowsIsOpen ? 1 : rowOpenOpacity,
            }}
            position="center"
          >
            <IconPlaylistAdd
              css={{
                cursor: 'pointer',
              }}
              onClick={async () => {
                await createRow.mutateAsync({
                  tableDataID: tableData.id,
                })
                setRowsIsOpen(true)
              }}
              color={cabinetColors.black}
            />
          </Group>
          <Group
            css={{
              width: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
              minWidth: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,

              borderRight: `1px solid ${cabinetColors.border}`,
              minHeight: columnHeight,
              alignItems: 'center',
              alignContent: 'center',
              padding: 6,
            }}
            position="center"
          >
            <IconSquareArrowDown
              onClick={async () => {
                setRowsIsOpen(o => !o)
              }}
              css={{
                cursor: 'pointer',
                transform: `rotate(${rowsIsOpen ? 0 : 180}deg)`,
              }}
              color={cabinetColors.black}
            />
          </Group>

          {table.colums?.map(column => {
            return (
              <SiteTableColumnOnlyTitleSync
                opacity={rowsIsOpen ? 1 : rowOpenOpacity}
                key={column.id}
                modelID={column.id}
                selectID={column.selectID}
              />
            )
          })}

          <div
            css={{
              width: 200,
              minWidth: 200,
              borderRight: `1px solid ${cabinetColors.border}`,
              minHeight: columnHeight,
              alignItems: 'center',
              alignContent: 'center',
              padding: 6,
              opacity: rowsIsOpen ? 1 : rowOpenOpacity,
            }}
          >
            <Text
              weight={500}
              css={{
                lineHeight: '100%',
              }}
            >
              url
            </Text>
          </div>

          <Menu withinPortal width={250} offset={4} shadow="md">
            <Menu.Target>
              <Group
                css={{
                  padding: 6,
                  alignContent: 'center',
                  flexWrap: 'nowrap',
                  cursor: 'pointer',
                  // borderBottom: `1px solid ${cabinetColors.border}`,
                  minHeight: columnHeight,
                  alignItems: 'center',
                  opacity: rowsIsOpen ? 1 : rowOpenOpacity,
                }}
                spacing={6}
              >
                <IconCirclePlus color={cabinetColors.placeholder} />
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    lineHeight: '100%',
                  }}
                  weight={500}
                >
                  добавить колонку
                </Text>
              </Group>
            </Menu.Target>
            <Menu.Dropdown
              css={{
                padding: 8,
              }}
            >
              <SimpleGrid spacing={8}>
                <TextInput
                  required
                  value={newColumnName}
                  size="xs"
                  placeholder="введите название колонки"
                  onChange={event => {
                    setNewColumnName(event.target.value)
                  }}
                />
                <Group spacing={4}>
                  {[
                    { ruName: 'текст', value: 'Text' },
                    { ruName: 'картинка', value: 'Image' },
                    { ruName: 'число', value: 'Number' },
                    { ruName: 'вложенный список', value: 'Table' },
                  ].map(item => {
                    return (
                      <Button
                        variant={item.value == newColumnType ? 'outline' : 'light'}
                        key={item.value}
                        onClick={() => {
                          if (
                            item.value == 'Number' ||
                            item.value == 'Text' ||
                            item.value == 'Image' ||
                            item.value == 'Table'
                          ) {
                            setNewColumnType(item.value)
                          }
                        }}
                        compact
                      >
                        {item.ruName}
                      </Button>
                    )
                  })}
                </Group>
                <Button
                  onClick={async () => {
                    if (newColumnName) {
                      createColumn.mutate({
                        tableID: table.id,
                        type: newColumnType,
                        name: newColumnName,
                      })
                      setNewColumnName('')
                      setNewColumnType('Text')
                    } else {
                      showNotification({
                        title: 'Введите название колонки',
                        message: '',
                        color: 'red',
                      })
                    }
                  }}
                  size="md"
                  loading={createColumn.isLoading}
                >
                  создать новую колонку
                </Button>
              </SimpleGrid>
            </Menu.Dropdown>
          </Menu>
        </Group>

        {rowsIsOpen ? (
          <List
            lockVertically
            values={items}
            onChange={({ oldIndex, newIndex }) => {
              const newOrder = arrayMove(tableData.rows, oldIndex, newIndex)
              setItems(newOrder)
              updateRowOrder.mutate({
                tableDataID: tableData.id,
                order: newOrder.map((step, index) => ({ id: step.id, order: index })),
              })
            }}
            renderList={({ children, props }) => (
              <SimpleGrid
                css={{
                  width: '100%',
                }}
                spacing={0}
                {...props}
              >
                {children}
              </SimpleGrid>
            )}
            renderItem={({ value, props }) => (
              <div
                css={{
                  zIndex: 10000,
                  position: 'relative',
                  width: '100%',
                }}
                key={value.id}
                {...props}
              >
                <SiteTableDataRowSync
                  firstLevel={firstLevel}
                  key={value.id}
                  tableSchemaModelID={tableSchemaModelID}
                  tableSchemaSelectID={tableSchemaSelectID}
                  modelID={value.id}
                  selectID={value.selectID}
                  hideName={hideName}
                />
              </div>
            )}
          />
        ) : null}
      </SimpleGrid>
    </SimpleGrid>
  )
}

function SiteTableDataRowSync({
  selectID,
  modelID,
  tableSchemaModelID,
  tableSchemaSelectID,
  hideName,
  firstLevel,
}: {
  selectID: 'TableDataRow1'
  modelID: string
  tableSchemaModelID: string
  tableSchemaSelectID: 'Table1'
  hideName?: boolean
  firstLevel?: boolean
}) {
  const tableRow = useGetSelectDataUndefined({ modelID, selectID })
  if (!tableRow) return <Text color="red">row not found</Text>
  const table = useGetSelectDataUndefined({ modelID: tableSchemaModelID, selectID: tableSchemaSelectID })
  if (!table) return <Text color="red">table not found</Text>

  const deleteRow = trpc.useMutation('user.table.deleteRow', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateRow = trpc.useMutation('user.table.updateRow', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <Group
      css={{
        alignItems: 'stretch',
        flexWrap: 'nowrap',
        gap: 0,
        borderTop: `1px solid ${cabinetColors.border}`,
      }}
    >
      <Group
        css={{
          width: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
          minWidth: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
        }}
      ></Group>
      <Group
        position="center"
        css={{
          width: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
          minWidth: firstLevel ? firstLevelButtonWidth : secondLevelButtonWidth,
          borderRight: `1px solid ${cabinetColors.border}`,
          alignItems: 'flex-start',
        }}
        pt={8}
      >
        <DragJSX />
      </Group>
      {table.colums?.map(column => {
        const matchRowItemForColumn = tableRow.items.find(item => {
          const found = getDataAtStoreSite({ modelID: item.id, selectID: item.selectID })
          if (found && found.tableColumnID == column.id) return item
        })
        return (
          <SiteTableColumnSync
            rowID={tableRow.id}
            rowItemModelID={matchRowItemForColumn?.id}
            rowItemSelectID={matchRowItemForColumn?.selectID}
            key={column.id}
            modelID={column.id}
            selectID={column.selectID}
          />
        )
      })}

      <div
        css={{
          width: 200,
          minWidth: 200,
          padding: 6,
          borderRight: `1px solid ${cabinetColors.border}`,
        }}
      >
        <TextInputWithUpdate
          unstyled={true}
          value={tableRow.slug ? `${tableRow.slug}` : ''}
          size="md"
          placeholder={'введите url'}
          onChange={async value => {
            await updateRow.mutate({
              tableRowID: tableRow.id,
              slug: value,
            })
          }}
        />
      </div>
      <Group
        pl={6}
        pt={6}
        css={{
          alignItems: 'flex-start',
        }}
        spacing={6}
      >
        <IconTrash
          css={{
            cursor: 'pointer',
          }}
          color="red"
          onClick={() => {
            if (confirm('Вы уверены что хотите удалить?'))
              deleteRow.mutate({
                tableRowID: tableRow.id,
              })
          }}
        />
      </Group>
    </Group>
  )
}

function SiteTableColumnOnlyTitleSync({
  selectID,
  modelID,
  rowItemModelID,
  rowItemSelectID,
  opacity,
}: {
  selectID: 'TableColumn1'
  modelID: string
  rowItemModelID?: string
  rowItemSelectID?: 'TableDataRowItem1'
  opacity: number
}) {
  const tableColumn = useGetSelectDataUndefined({ modelID, selectID })
  if (!tableColumn) return <Text color="red">column not found</Text>
  // const tableRowItem = useGetSelectDataUndefined({ modelID: rowItemModelID, selectID: rowItemSelectID })

  let color = 'blue'
  if (tableColumn.type == 'Text') color = 'orange'
  if (tableColumn.type == 'Number') color = 'dark'
  if (tableColumn.type == 'Table') color = 'green'
  if (tableColumn.type == 'Image') color = 'blue'

  const updateColumn = trpc.useMutation('user.table.updateColumn', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <>
      <Group
        css={{
          minWidth: tableColumn.width,
          width: tableColumn.width,
          borderRight: `1px solid ${cabinetColors.border}`,
          flexWrap: 'nowrap',
          padding: '6px 0px 6px 6px',
          minHeight: columnHeight,
          opacity,
        }}
        // withBorder
        position="apart"
      >
        <SimpleGrid spacing={4}>
          <Group
            css={{
              flexWrap: 'nowrap',
            }}
            spacing={6}
          >
            <Text
              css={{
                lineHeight: '100%',
                color: cabinetColors.placeholder,
              }}
              weight={500}
            >
              {tableColumn.name}
            </Text>
            <Badge color={color}>
              {tableColumn.type == 'Text' ? 'Текст' : ''}
              {tableColumn.type == 'Number' ? 'Число' : ''}
              {tableColumn.type == 'Image' ? 'Картинка' : ''}
              {tableColumn.type == 'Table' ? 'Список' : ''}
            </Badge>
          </Group>
        </SimpleGrid>

        <DragOffsetPosition
          limited={true}
          startValue={tableColumn.width}
          onStart={() => {}}
          onChangePosition={({ offsetPosition, startValue }) => {
            if (startValue) {
              const width = offsetPosition.x + startValue
              synchronFrontendEngineAtSite.updatePartialData.TableColumn({
                id: tableColumn.id,
                data: {
                  width,
                },
              })
              return {
                resultSizes: {
                  x: width,
                },
              }
            }

            return {
              resultSizes: {},
            }
          }}
          onEndChangePosition={({ resultPosition }) => {
            updateColumn.mutate({
              columnID: tableColumn.id,
              width: resultPosition.x,
            })
          }}
          style={{
            willChange: 'background',
            width: 3,
            height: '100%',
            // background: cabinetColors.black,
            // border: '1px solid #0086ff',
            // transform: 'translate(50%, -50%) rotate(45deg)',
            cursor: 'ew-resize',
            transform: 'translate(2px, 0)',
          }}
        ></DragOffsetPosition>
      </Group>
    </>
  )
}

function SiteTableColumnSync({
  selectID,
  modelID,
  rowItemModelID,
  rowItemSelectID,
  rowID,
}: {
  selectID: 'TableColumn1'
  modelID: string
  rowItemModelID?: string
  rowItemSelectID?: 'TableDataRowItem1'
  rowID: string
}) {
  const tableColumn = useGetSelectDataUndefined({ modelID, selectID })
  if (!tableColumn) return <Text color="red">column not found</Text>
  const tableRowItem = useGetSelectDataUndefined({ modelID: rowItemModelID, selectID: rowItemSelectID })

  const createItem = trpc.useMutation('user.table.createItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createTableItem = trpc.useMutation('user.table.createTableItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateTableItem = trpc.useMutation('user.table.updateTableItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)

  return (
    <>
      <ImagesSync
        opened={imagesOpened}
        onClose={() => setImagesOpened(false)}
        imageChoosen={async ({ imageID, imageName }) => {
          if (tableRowItem) {
            await updateTableItem.mutate({
              tableRowItemID: tableRowItem.id,
              imageID,
            })
          } else {
            createItem.mutate({
              tableRowID: rowID,
              tableColumnID: tableColumn.id,
              imageID,
            })
          }
          setImagesOpened(false)
        }}
      />

      <Group
        css={{
          backgroundColor: 'transparent',
          width: tableColumn.width,
          minWidth: tableColumn.width,
          borderRight: `1px solid ${cabinetColors.border}`,
          alignItems: 'flex-start',
        }}
        // withBorder
        // py={4}
      >
        <SimpleGrid
          css={{
            width: '100%',
          }}
          spacing={4}
        >
          {tableColumn.type == 'Table' ? (
            <>
              {tableRowItem ? (
                tableRowItem.tableData && tableColumn.columnTableSchema ? (
                  <>
                    <SiteTableDataSyncMemo
                      tableSchemaModelID={tableColumn.columnTableSchema.id}
                      tableSchemaSelectID={tableColumn.columnTableSchema.selectID}
                      modelID={tableRowItem.tableData.id}
                      selectID={tableRowItem.tableData.selectID}
                      hideName={true}
                    />
                  </>
                ) : (
                  <></>
                )
              ) : (
                <Button
                  m={6}
                  onClick={() => {
                    createTableItem.mutate({
                      tableRowID: rowID,
                      tableColumnID: tableColumn.id,
                    })
                  }}
                  compact
                >
                  заполнить списком
                </Button>
              )}
            </>
          ) : (
            <>
              {tableRowItem ? (
                <>
                  <Group
                    css={{
                      width: '100%',
                    }}
                    p={6}
                  >
                    {tableColumn.type == 'Text' ? (
                      <EditorTextSync
                        hideTypes={true}
                        placeholder={'текст'}
                        defaultValue={typeof tableRowItem.text == 'string' ? tableRowItem.text : ''}
                        onChange={async value => {
                          await updateTableItem.mutate({
                            tableRowItemID: tableRowItem.id,
                            text: value,
                          })
                        }}
                      />
                    ) : null}{' '}
                    {tableColumn.type == 'Number' ? (
                      <TextInputWithUpdate
                        unstyled={true}
                        value={typeof tableRowItem.number == 'number' ? `${tableRowItem.number}` : '0'}
                        size="xs"
                        placeholder={'число'}
                        onChange={async value => {
                          await updateTableItem.mutate({
                            tableRowItemID: tableRowItem.id,
                            number: Number(value),
                          })
                        }}
                      />
                    ) : null}{' '}
                    {tableColumn.type == 'Image' && tableRowItem.image ? (
                      <>
                        <SiteFaviconSync imageID={tableRowItem.image.id} selectID={tableRowItem.image.selectID} />

                        <IconRefresh
                          // title={'Изменить картинку'}
                          onClick={() => {
                            setImagesOpened(true)
                          }}
                          css={{
                            cursor: 'pointer',
                          }}
                          color={cabinetColors.placeholder}
                        />
                      </>
                    ) : null}
                  </Group>
                </>
              ) : (
                <>
                  <Button
                    m={6}
                    compact
                    onClick={() => {
                      if (tableColumn.type == 'Text') {
                        createItem.mutate({
                          tableRowID: rowID,
                          tableColumnID: tableColumn.id,
                          text: '',
                        })
                      } else if (tableColumn.type == 'Number') {
                        createItem.mutate({
                          tableRowID: rowID,
                          tableColumnID: tableColumn.id,
                          number: 0,
                        })
                      } else if (tableColumn.type == 'Image') {
                        setImagesOpened(true)
                      }
                    }}
                  >
                    добавить
                  </Button>
                </>
              )}
            </>
          )}
        </SimpleGrid>
      </Group>
    </>
  )
}
