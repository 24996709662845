import { Modal } from '@mantine/core'
import { FakeForm } from 'components/Form/Form'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { SiteFormSyncMemo } from 'syncComponents/SiteFormsSync/SiteFormsSync'

export function FormComponentSync({
  modelID,
  selectID,
  radius,
  opened,
  onClose,
}: {
  modelID: string
  selectID: 'FormComponent1'
  radius: string
  opened?: boolean
  onClose?: () => void
}) {
  const formComponent = useGetSelectData({ modelID, selectID })

  return (
    <>
      {opened ? (
        <Modal
          withinPortal
          size={800}
          transitionDuration={0}
          opened={opened}
          onClose={() => {
            if (onClose) onClose()
          }}
        >
          <SiteFormSyncMemo
            formData={{
              modelID: formComponent.form.id,
              selectID: formComponent.form.selectID,
            }}
          />
        </Modal>
      ) : null}

      <FakeForm modelID={formComponent.form.id} selectID={formComponent.form.selectID} radius={radius} />
    </>
  )
}
