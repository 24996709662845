import { showNotification } from '@mantine/notifications'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { BoxBackground1SelectWithID } from 'generation/centerFrontedTypesEngine'
import React, { useEffect, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { getPathToFile } from 'utils/getPathToFile'
import { trpc } from 'utils/trpc'

export const BoxBackgroundSyncMemo = React.memo(BoxBackgroundSync, (prev, next) => {
  return (
    prev.modelID == next.modelID &&
    prev.backgroundEditIsShow == next.backgroundEditIsShow &&
    prev.boxBorderRadius == next.boxBorderRadius
  )
})
export function BoxBackgroundSync({
  modelID,
  selectID,
  backgroundEditIsShow,
  boxBorderRadius,
}: {
  modelID: string
  selectID: 'BoxBackground1'
  backgroundEditIsShow: boolean
  boxBorderRadius: string
}) {
  const boxBackground = useGetSelectData({ modelID, selectID })

  return (
    <div
      style={{
        overflow: backgroundEditIsShow ? undefined : 'hidden', //
        borderRadius: boxBorderRadius,
      }}
      css={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}
    >
      {boxBackground.color ? (
        <BoxBackgroundColorSync modelID={boxBackground.color.id} selectID={boxBackground.color.selectID} />
      ) : null}
      {boxBackground.image ? (
        <BoxBackgroundImageSync
          boxBackground={boxBackground}
          modelID={boxBackground.image.id}
          selectID={boxBackground.image.selectID}
        />
      ) : null}
    </div>
  )
}

const BoxBackgroundColorSync = ({ modelID, selectID }: { modelID: string; selectID: 'Color1' }) => {
  const color = useGetSelectData({ modelID, selectID })

  return (
    <div
      css={{
        position: 'absolute',
        left: '0',
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        background: color.value,
        // zIndex: 2,
        // opacity: 0.5,
      }}
      style={{}}
    ></div>
  )
}

const BoxBackgroundImageSync = ({
  boxBackground,
  modelID,
  selectID,
}: {
  boxBackground: BoxBackground1SelectWithID
  modelID: string
  selectID: 'Image1'
}) => {
  const image = useGetSelectData({ modelID, selectID })

  const [boxBackgroundState, setBoxBackgroundState, boxBackgroundStateRef] = useStateRef(boxBackground)
  useEffect(() => {
    setBoxBackgroundState(boxBackground)
  }, [boxBackground])

  const widthAndHeight = 8

  const boxBackgroundImageRef = useRef<HTMLImageElement>(null)

  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [positionX, setPositionX] = useState<number | undefined>()
  const [positionY, setPositionY] = useState<number | undefined>()
  const [width, setWidth] = useState<number | undefined>()
  const [height, setHeight] = useState<number | undefined>()

  return (
    <div
      css={{
        position: 'absolute',
        left: '50%',
        cursor: 'move',
        // zIndex: 2,
        // opacity: 0.5,
      }}
      style={{
        top:
          boxBackgroundState.verticalPosition == 'Top' ? 0 : boxBackgroundState.verticalPosition == 'Center' ? '50%' : '100%',
        width: typeof width == 'undefined' ? boxBackgroundState.width : width,
        height: typeof height == 'undefined' ? boxBackgroundState.height : height,
        transform: `translate(calc(-50% + ${
          typeof positionX == 'undefined' ? boxBackgroundState.positionX : positionX
        }px), calc(-50% + ${typeof positionY == 'undefined' ? boxBackgroundState.positionY : positionY}px))`,
      }}
    >
      <>
        <DragOffsetPosition
          onChangePosition={({ offsetPosition }) => {
            const pixelBoxDifferentBackgroundPositionPrevPixelX =
              (boxBackgroundStateRef.current?.positionX || 0) + offsetPosition.x

            const pixelBoxDifferentBackgroundPositionPrevPixelY =
              (boxBackgroundStateRef.current?.positionY || 0) + offsetPosition.y

            // setBoxBackgroundState({
            //   ...boxBackgroundStateRef.current,
            //   positionX: pixelBoxDifferentBackgroundPositionPrevPixelX,
            //   positionY: pixelBoxDifferentBackgroundPositionPrevPixelY,
            // })
            setPositionX(pixelBoxDifferentBackgroundPositionPrevPixelX)
            setPositionY(pixelBoxDifferentBackgroundPositionPrevPixelY)
            return {
              resultSizes: {
                x: pixelBoxDifferentBackgroundPositionPrevPixelX,
                y: pixelBoxDifferentBackgroundPositionPrevPixelY,
              },
            }
          }}
          onEndChangePosition={async ({ resultPosition }) => {
            if (boxBackgroundStateRef.current) {
              setBoxBackgroundState({
                ...boxBackgroundStateRef.current,
                positionX: resultPosition.x || boxBackgroundStateRef.current.positionX,
                positionY: resultPosition.y || boxBackgroundStateRef.current.positionY,
              })
              await updateBoxBackground.mutateAsync({
                boxBackgroundID: boxBackground.id,
                positionX: resultPosition.x || boxBackgroundStateRef.current.positionX,
                positionY: resultPosition.y || boxBackgroundStateRef.current.positionY,
              })
            }
            setPositionX(undefined)
            setPositionY(undefined)
          }}
          onDrag={({ is }) => {}}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <img
            src={getPathToFile({
              fileName: image.name,
               folderName:image.folder
            })}
            css={{
              position: 'fixed',
              left: -99999,
              pointerEvents: 'none',
              opacity: 0,
            }}
            onLoad={async () => {
              if (boxBackgroundImageRef.current) {
                const width = boxBackgroundImageRef.current.clientWidth
                const height = boxBackgroundImageRef.current.clientHeight

                if (boxBackgroundStateRef.current) {
                  if (boxBackgroundStateRef.current.height == 0 && boxBackgroundStateRef.current.width == 0) {
                    setBoxBackgroundState({
                      ...boxBackgroundStateRef.current,
                      width,
                      height,
                    })
                    await updateBoxBackground.mutateAsync({
                      boxBackgroundID: boxBackground.id,
                      width,
                      height,
                    })
                  }
                }
              }
            }}
            ref={boxBackgroundImageRef}
          />
          <img
            css={{
              width: '100%',
              height: '100%',
            }}
            src={getPathToFile({
              fileName: image.name,
              folderName: image.folder
            })}
          />
        </DragOffsetPosition>

        <DragOffsetPosition
          onChangePosition={({ offsetPosition }) => {
            let newBackgrounWidth = 0
            let newBackgrounHeight = 0
            if (boxBackgroundStateRef.current) {
              const actualImageWidth = boxBackgroundStateRef.current.width
              const actualImageHeight = boxBackgroundStateRef.current.height
              newBackgrounWidth = actualImageWidth + offsetPosition.x * 2

              const widthPercentDifference = (newBackgrounWidth / actualImageWidth) * 100
              newBackgrounHeight = (actualImageHeight * widthPercentDifference) / 100

              // setBoxBackgroundState({
              //   ...boxBackgroundStateRef.current,
              //   width: newBackgrounWidth,
              //   height: newBackgrounHeight,
              // })
              setWidth(newBackgrounWidth)
              setHeight(newBackgrounHeight)
            }
            return {
              resultSizes: {
                x: newBackgrounWidth,
                y: newBackgrounHeight,
              },
            }
          }}
          onEndChangePosition={async ({ resultPosition }) => {
            if (boxBackgroundStateRef.current) {
              setBoxBackgroundState({
                ...boxBackgroundStateRef.current,
                width: resultPosition.x || 0,
                height: resultPosition.y || 0,
              })
              await updateBoxBackground.mutateAsync({
                boxBackgroundID: boxBackground.id,
                width: resultPosition.x || 0,
                height: resultPosition.y || 0,
              })
            }
            setWidth(undefined)
            setHeight(undefined)
          }}
          style={{
            willChange: 'width, height',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #0086ff',
            bottom: 0,
            right: 0,
            transform: 'translate(50%, 50%)',
            cursor: 'nwse-resize',
            zIndex: 2,
            // background: boxWidthIsDrag ? '#0086ff' : 'white',
            width: widthAndHeight,
            height: widthAndHeight,
          }}
          onDrag={({ is }) => {
            // boxBackground.setIsDrag(is)
          }}
        />
      </>
    </div>
  )
}
