import { useElementSize } from '@mantine/hooks'
import { colors } from 'utils/styles'

import { commonBorderRadius } from 'components/Form/Form'

export const QuizProgress = ({
  text,
  percent,
  color = colors.buttonMantineDefaultColor,
  secondColor = 'white',
}: {
  text: string
  percent: number
  color?: string
  secondColor?: string
}) => {
  const { ref, width } = useElementSize()
  return (
    <div
      css={{
        position: 'relative',
        height: 30,
        border: `1px solid ${color}`,
        borderRadius: commonBorderRadius,
        overflow: 'hidden',
        lineHeight: '100%',
        fontSize: 16,
        fontWeight: 500,
      }}
      ref={ref}
    >
      <div
        css={{
          width: `${percent}%`,
          transition: '0.1s',
          overflow: 'hidden',
          height: '100%',
          background: color,
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      >
        {width ? (
          <div
            css={{
              width: width,
              textAlign: 'center',
              color: secondColor,
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          >
            {text}
          </div>
        ) : null}
      </div>
      <div
        css={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
          color: color,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        {text}
      </div>
    </div>
  )
}
