import { Modal } from '@mantine/core'
import { FakeQuiz } from 'components/Quiz/Quiz'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { QuizSyncMemo } from 'syncComponents/SiteQuizzesSync/SiteQuizzesSync'

export function QuizComponentSync({
  modelID,
  selectID,
  opened,
  onClose,
}: {
  modelID: string
  selectID: 'QuizComponent1'
  opened?: boolean
  onClose?: () => void
}) {
  const quizComponent = useGetSelectData({ modelID, selectID })
  return (
    <>
      {opened ? (
        <Modal
          withinPortal
          size={800}
          transitionDuration={0}
          opened={opened}
          onClose={() => {
            if (onClose) onClose()
          }}
        >
          <QuizSyncMemo modelID={quizComponent.quiz.id} selectID={quizComponent.quiz.selectID} />
        </Modal>
      ) : null}{' '}
      <FakeQuiz modelID={quizComponent.quiz.id} selectID={quizComponent.quiz.selectID} />
    </>
  )
}
