import { ActionIcon, Group } from '@mantine/core'
import { IconPhoto, IconRefresh, IconTrash } from '@tabler/icons'
import { cabinetColors, cabinetShadows, cabinetSizes } from 'utils/cabinetTheme'
import { getPathToFile } from 'utils/getPathToFile'

import { useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'

export function ImageWithUpdateSync({
  image,
  onChoose,
  onClear,
  big,
}: {
  image?: { imageID: string; selectID: 'Image1' }
  onChoose: () => void
  onClear: () => void
  big?: boolean
}) {
  const imageStorage = useGetSelectDataUndefined({ modelID: image?.imageID, selectID: image?.selectID })

  const width = 50

  const iconWidth = 18

  const paddingLeftWidth = 8

  return (
    <Group
      css={{
        width: big ? '100%' : width + iconWidth + paddingLeftWidth,
      }}
      spacing={paddingLeftWidth}
    >
      <CalcWidth
        width={iconWidth}
        customCSS={{
          width: '100%',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
        }}
        leftNode={
          <Group
            css={{
              width: '100%',
            }}
            pr={paddingLeftWidth}
          >
            {imageStorage ? (
              <img
                onClick={() => {
                  onChoose()
                }}
                src={getPathToFile({ fileName: imageStorage.name, folderName: imageStorage.folder })}
                css={{
                  width: '100%',
                  borderRadius: cabinetSizes.radius,
                  cursor: 'pointer',
                  backgroundSize: 'cover',
                  border: `1px solid ${cabinetColors.border}`,
                  boxShadow: cabinetShadows.input,
                }}
              />
            ) : (
              <Group
                css={{
                  alignItems: 'center',
                  width: '100%',
                  height: width,
                  borderRadius: cabinetSizes.radius,
                  background: cabinetColors.background,
                  cursor: 'pointer',
                  border: `1px solid ${cabinetColors.border}`,
                  boxShadow: cabinetShadows.input,
                }}
                onClick={() => {
                  onChoose()
                }}
                position="center"
              >
                <IconPhoto size={width / 2} />
              </Group>
            )}
          </Group>
        }
        rightNode={
          <Group
            css={{
              flexDirection: 'column',
            }}
            spacing={4}
          >
            <ActionIcon
              onClick={() => {
                onClear()
              }}
              title="Убрать картинку"
              size={'xs'}
            >
              <IconTrash />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                onChoose()
              }}
              title="Обновить картинку"
              size={'xs'}
            >
              <IconRefresh />
            </ActionIcon>
          </Group>
        }
      />
    </Group>
  )
}
