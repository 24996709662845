import { Interpolation, Theme } from '@emotion/react'
import { HorizontalPosition, ScreeenType } from '@prisma/client'
import { mqBox } from 'config/mq'
import { MouseEvent } from 'react'
import { TextComponentSelect, TextSettingsSelect } from 'server/selects'

function transformHorizontalPositionToTextAlign(horizontalPosition: HorizontalPosition) {
  if (horizontalPosition == 'Left') return 'left'
  else if (horizontalPosition == 'Center') return 'center'
  else return 'right'
}

export function getTextSettingsStyles({
  textSettings,
  responsiveMode,
}: {
  textSettings: NonNullable<TextSettingsSelect>
  responsiveMode?: ScreeenType
}): {
  withResposiveMode: {
    fontSize: string
    lineHeight: string
  }
  media: {
    mobile: {
      fontSize: string
      lineHeight: string
      fontFamily: string
    }
    tablet: {
      fontSize: string
      lineHeight: string
      fontFamily: string
    }
    desktop: {
      fontSize: string
      lineHeight: string
      fontFamily: string
    }
  }
} {
  let fontSizeMobile: number | null
  if (textSettings.fontSizeMobileResponsiveMode == 'Mobile') {
    fontSizeMobile = textSettings.fontSizeMobile
  } else if (textSettings.fontSizeMobileResponsiveMode == 'Tablet') {
    fontSizeMobile = textSettings.fontSizeTablet
  } else {
    fontSizeMobile = textSettings.fontSizeDesktop
  }

  let fontSizeTablet: number | null
  if (textSettings.fontSizeTabletResponsiveMode == 'Mobile') {
    fontSizeTablet = textSettings.fontSizeMobile
  } else if (textSettings.fontSizeTabletResponsiveMode == 'Tablet') {
    fontSizeTablet = textSettings.fontSizeTablet
  } else {
    fontSizeTablet = textSettings.fontSizeDesktop
  }

  let fontSizeDesktop: number | null
  if (textSettings.fontSizeDesktopResponsiveMode == 'Mobile') {
    fontSizeDesktop = textSettings.fontSizeMobile
  } else if (textSettings.fontSizeDesktopResponsiveMode == 'Tablet') {
    fontSizeDesktop = textSettings.fontSizeTablet
  } else {
    fontSizeDesktop = textSettings.fontSizeDesktop
  }

  let lineHeightMobile: number | null
  if (textSettings.lineHeightMobileResponsiveMode == 'Mobile') {
    lineHeightMobile = textSettings.lineHeightMobile
  } else if (textSettings.lineHeightMobileResponsiveMode == 'Tablet') {
    lineHeightMobile = textSettings.lineHeightTablet
  } else {
    lineHeightMobile = textSettings.lineHeightDesktop
  }

  let lineHeightTablet: number | null
  if (textSettings.lineHeightTabletResponsiveMode == 'Mobile') {
    lineHeightTablet = textSettings.lineHeightMobile
  } else if (textSettings.lineHeightTabletResponsiveMode == 'Tablet') {
    lineHeightTablet = textSettings.lineHeightTablet
  } else {
    lineHeightTablet = textSettings.lineHeightDesktop
  }

  let lineHeightDesktop: number | null
  if (textSettings.lineHeightDesktopResponsiveMode == 'Mobile') {
    lineHeightDesktop = textSettings.lineHeightMobile
  } else if (textSettings.lineHeightDesktopResponsiveMode == 'Tablet') {
    lineHeightDesktop = textSettings.lineHeightTablet
  } else {
    lineHeightDesktop = textSettings.lineHeightDesktop
  }

  let fontWeightMobile: string | undefined | null
  if (textSettings.fontWeightMobileResponsiveMode == 'Mobile') {
    fontWeightMobile = textSettings.fontWeightMobile?.id
  } else if (textSettings.fontWeightMobileResponsiveMode == 'Tablet') {
    fontWeightMobile = textSettings.fontWeightTablet?.id
  } else {
    fontWeightMobile = textSettings.fontWeightDesktop?.id
  }

  let fontWeightTablet: string | undefined | null
  if (textSettings.fontWeightTabletResponsiveMode == 'Mobile') {
    fontWeightTablet = textSettings.fontWeightMobile?.id
  } else if (textSettings.fontWeightTabletResponsiveMode == 'Tablet') {
    fontWeightTablet = textSettings.fontWeightTablet?.id
  } else {
    fontWeightTablet = textSettings.fontWeightDesktop?.id
  }

  let fontWeightDesktop: string | undefined | null
  if (textSettings.fontWeightDesktopResponsiveMode == 'Mobile') {
    fontWeightDesktop = textSettings.fontWeightMobile?.id
  } else if (textSettings.fontWeightDesktopResponsiveMode == 'Tablet') {
    fontWeightDesktop = textSettings.fontWeightTablet?.id
  } else {
    fontWeightDesktop = textSettings.fontWeightDesktop?.id
  }

  return {
    withResposiveMode: {
      fontSize: `${
        responsiveMode == 'Mobile' ? fontSizeMobile : responsiveMode == 'Tablet' ? fontSizeTablet : fontSizeDesktop
      }px`,
      lineHeight: `${
        responsiveMode == 'Mobile' ? lineHeightMobile : responsiveMode == 'Tablet' ? lineHeightTablet : lineHeightDesktop
      } !important`,
    },
    media: {
      mobile: {
        fontSize: `${fontSizeMobile}px`,
        lineHeight: `${lineHeightMobile} !important`,
        fontFamily: `${fontWeightMobile ? `"${fontWeightMobile}", sans-serif` : 'inherit'} !important`,
      },
      tablet: {
        fontSize: `${fontSizeTablet}px !important`,
        lineHeight: `${lineHeightTablet} !important`,
        fontFamily: `${fontWeightTablet ? `"${fontWeightTablet}", sans-serif` : 'inherit'} !important`,
      },
      desktop: {
        fontSize: `${fontSizeDesktop}px !important`,
        lineHeight: `${lineHeightDesktop} !important`,
        fontFamily: `${fontWeightDesktop ? `"${fontWeightDesktop}", sans-serif` : 'inherit'} !important`,
      },
    },
  }
}

export function getTextComponentStyles({
  textComponent,
  responsiveMode,
}: {
  textComponent: NonNullable<TextComponentSelect>
  responsiveMode?: ScreeenType
}): Interpolation<Theme> {
  let textAlignMobile: HorizontalPosition
  if (textComponent.alignMobileResponsiveMode == 'Mobile') {
    textAlignMobile = textComponent.alignMobile
  } else if (textComponent.alignMobileResponsiveMode == 'Tablet') {
    textAlignMobile = textComponent.alignTablet
  } else {
    textAlignMobile = textComponent.alignDesktop
  }

  let textAlignTablet: HorizontalPosition
  if (textComponent.alignTabletResponsiveMode == 'Mobile') {
    textAlignTablet = textComponent.alignMobile
  } else if (textComponent.alignTabletResponsiveMode == 'Tablet') {
    textAlignTablet = textComponent.alignTablet
  } else {
    textAlignTablet = textComponent.alignDesktop
  }

  let textAlignDesktop: HorizontalPosition
  if (textComponent.alignDesktopResponsiveMode == 'Mobile') {
    textAlignDesktop = textComponent.alignMobile
  } else if (textComponent.alignDesktopResponsiveMode == 'Tablet') {
    textAlignDesktop = textComponent.alignTablet
  } else {
    textAlignDesktop = textComponent.alignDesktop
  }

  const commonCSS: Interpolation<Theme> = {
    width: '100%',
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    '& * a': {
      color: 'inherit',
    },
    '& > p:after': {
      content: "''",
      display: 'inline-block',
      width: '0',
    },
    '& *': {
      lineHeight: '1.2',
    },
    mark: {
      padding: '0px 4px 1px 4px',
      borderRadius: '2px',
      color: 'inherit !important',
    },
    '& * ol, & * ul': {
      // listStylePosition: 'inside',
      paddingLeft: 0,
    },
    strong: {
      fontWeight: '700 !important',
      '& *': {
        fontWeight: '700 !important',
      },
    },
  }

  const settingsStyle = getTextSettingsStyles({ textSettings: textComponent.textSettings, responsiveMode })

  return responsiveMode
    ? {
        ...commonCSS,
        textAlign:
          responsiveMode == 'Mobile'
            ? transformHorizontalPositionToTextAlign(textAlignMobile)
            : responsiveMode == 'Tablet'
            ? transformHorizontalPositionToTextAlign(textAlignTablet)
            : transformHorizontalPositionToTextAlign(textAlignDesktop),
        '*': {
          ...settingsStyle.withResposiveMode,
          fontWeight: 400,
        },
      }
    : {
        ...commonCSS,
        textAlign: transformHorizontalPositionToTextAlign(textAlignMobile),
        '*': {
          fontSize: settingsStyle.media.mobile.fontSize,
          lineHeight: settingsStyle.media.mobile.lineHeight,
          fontFamily: settingsStyle.media.mobile.fontFamily,
          fontWeight: 400,
        },
        [mqBox.tablet]: {
          textAlign: transformHorizontalPositionToTextAlign(textAlignTablet),
          '*': {
            fontSize: settingsStyle.media.tablet.fontSize,
            lineHeight: settingsStyle.media.tablet.lineHeight,
            fontFamily: settingsStyle.media.tablet.fontFamily,
          },
        },
        [mqBox.desktop]: {
          textAlign: transformHorizontalPositionToTextAlign(textAlignDesktop),
          '*': {
            fontSize: settingsStyle.media.desktop.fontSize,
            lineHeight: settingsStyle.media.desktop.lineHeight,
            fontFamily: settingsStyle.media.desktop.fontFamily,
          },
        },
      }
}

export function TransofrmTextContentHTML({
  textComponent,
  onDoubleClick,
  responsiveMode,
  showEmptyState,
  prepositions,
  value,
}: {
  textComponent: NonNullable<TextComponentSelect>
  onDoubleClick?: (e: MouseEvent) => void
  responsiveMode?: ScreeenType
  showEmptyState?: boolean
  prepositions?: boolean
  value?: string
}) {
  let html = value ? value : `${textComponent.text ? textComponent.text : ''}`

  try {
    if (!html.includes('<p')) {
      html = `<p>${html}</p>`
    }
  } catch (error) {}

  if (textComponent.type == 'H1') {
    try {
      html = html.replaceAll('<p', '<h1')
      html = html.replaceAll('</p>', '</h1>')
    } catch (error) {}
  }
  if (textComponent.type == 'H2') {
    try {
      html = html.replaceAll('<p', '<h2')
      html = html.replaceAll('</p>', '</h2>')
    } catch (error) {}
  }
  if (textComponent.type == 'H3') {
    try {
      html = html.replaceAll('<p', '<h3')
      html = html.replaceAll('</p>', '</h3>')
    } catch (error) {}
  }
  if (textComponent.type == 'H4') {
    try {
      html = html.replaceAll('<p', '<h4')
      html = html.replaceAll('</p>', '</h4>')
    } catch (error) {}
  }
  if (textComponent.type == 'H5') {
    try {
      html = html.replaceAll('<p', '<h5')
      html = html.replaceAll('</p>', '</h5>')
    } catch (error) {}
  }
  if (textComponent.type == 'H6') {
    try {
      html = html.replaceAll('<p', '<h6')
      html = html.replaceAll('</p>', '</h6>')
    } catch (error) {}
  }

  // if (prepositions) html = parsePrepositions(html)

  return (
    <div
      css={getTextComponentStyles({ textComponent, responsiveMode })}
      onDoubleClick={event => {
        if (onDoubleClick) onDoubleClick(event)
      }}
      dangerouslySetInnerHTML={{ __html: !showEmptyState ? html : html ? html : 'Пустой текст' }}
    />
  )
}
