import { AspectRatio, Group, Loader, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext, useState } from 'react'
import { ImageSelect } from 'server/selects'
import { trpc } from 'utils/trpc'

export function ImageGallery({
  imageChoosen,
  imageName,
  placeholder,
}: {
  imageChoosen: ({ imageID }: { imageID: string }) => void
  imageName?: string
  placeholder?: string
}) {
  const [opened, setOpened] = useState(false)
  const [imageNameState, setImageNameState] = useState('')
  return (
    <div>
      Компонент не поддерживается, напишите в поддержку
      {/* {opened ? (
        <Drawer
          transitionDuration={0}
          overlayOpacity={0.55}
          overlayBlur={3}
          padding={'md'}
          size="calc(100vh - 50px)"
          lockScroll={true}
          position="bottom"
          opened={opened}
          onClose={() => setOpened(false)}
          title="Выбрать картинку"
          css={{
            overflowY: 'scroll',
          }}
        >
          <div>
            {opened ? (
              <Images
                imageChoosen={({ imageID, imageName }) => {
                  imageChoosen({ imageID })
                  setImageNameState(imageName)
                }}
                onClose={() => setOpened(false)}
              />
            ) : null}
          </div>
        </Drawer>
      ) : null}

      <Paper withBorder px={12} py="xs">
        <Group>
          {placeholder ? <Text size={'md'}>{placeholder}:</Text> : null}
          {imageNameState ? (
            <Image width={28} height={28} src={getPathToFile({ fileName: imageNameState })} />
          ) : imageName ? (
            <Image width={28} height={28} src={getPathToFile({ fileName: imageName })} />
          ) : null}
          <ActionIcon onClick={() => setOpened(true)} variant="light">
            <IconRefresh />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              imageChoosen({ imageID: '' })
              setImageNameState('')
            }}
            variant="light"
          >
            <IconXboxX />
          </ActionIcon>
        </Group>
      </Paper> */}
    </div>
  )
}

export function Images({
  onClose,
  imageChoosen,
}: {
  onClose: () => void
  imageChoosen: (data: { imageID: string; imageName: string }) => void
}) {
  const site = useContext(SiteContext)
  const images = trpc.useQuery(
    [
      'user.getImagesBySite',
      {
        siteID: site.id,
      },
    ],
    {
      refetchInterval: 500,
    }
  )

  const uploadToServer = async ({ file, siteID }: { file: File; siteID: string }) => {
    setImageLoading(true)

    const body = new FormData()

    const imageNode = document.createElement('img')
    imageNode.onload = async () => {
      const width = imageNode.clientWidth
      const height = imageNode.clientHeight

      document.body.removeChild(imageNode)

      body.append('file', file)
      try {
        await fetch('/api/file', {
          method: 'POST',
          body,
          headers: {
            siteid: siteID,
            width: `${width}`,
            height: `${height}`,
          },
        })
      } catch (error) {
        // TODO handle error
      } finally {
        setImageLoading(false)
      }
    }
    imageNode.style.position = 'absolute'
    imageNode.style.pointerEvents = 'none'
    imageNode.style.opacity = '0'
    imageNode.src = URL.createObjectURL(file)
    document.body.appendChild(imageNode)
  }

  const [imageLoading, setImageLoading] = useState(false)

  return (
    <>
      {images.error ? (
        images.error.message
      ) : images.isLoading ? (
        <Group position="center">
          <Loader />
        </Group>
      ) : images.data ? (
        <Group>
          <div
            css={{
              height: 150,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              width: 150,
            }}
          >
            <Dropzone
              loading={imageLoading}
              onDrop={files => {
                files.map(file => {
                  uploadToServer({ file, siteID: site.id })
                })
              }}
              sx={theme => ({
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px dashed #333333',

                '&[data-accept]': {
                  color: theme.white,
                  backgroundColor: theme.colors.blue[6],
                },

                '&[data-reject]': {
                  color: theme.white,
                  backgroundColor: theme.colors.red[6],
                },
              })}
            >
              {/* <IconUpload color="#333" /> */}
              <Text align="center">Картинки сюда</Text>
            </Dropzone>
          </div>
          {images.data.map(image => (
            <div
              key={image.id}
              css={{
                width: 150,
              }}
            >
              <ImageGalleryCard
                image={image}
                imageChoosen={() => {
                  imageChoosen({ imageID: image.id, imageName: image.name })
                  onClose()
                }}
              />
            </div>
          ))}
        </Group>
      ) : (
        'Данных нет'
      )}
    </>
  )
}

const ImageGalleryCard = ({ image, imageChoosen }: { image: NonNullable<ImageSelect>; imageChoosen: () => void }) => {
  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        border: '0.5px solid #efefef',
        cursor: 'pointer',
        transition: '0.25s',
        ':hover': {
          transform: 'scale(1.05)',
        },
      }}
      key={image.id}
      onClick={() => {
        imageChoosen()
      }}
    >
      <AspectRatio css={{ width: '100%' }} ratio={1 / 1}>
        {/* <div>
          <img
            src={getPathToFile({ fileName: image.name })}
            css={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </div> */}
        Не поддерживается
      </AspectRatio>
      {/* {(image.size / (1024 * 1024)).toFixed(2)} Mb */}
    </div>
  )
}
