import { Button, Group, Loader, SimpleGrid, Skeleton } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconArrowRight } from '@tabler/icons'
import BoxBlock from 'blocks/BoxBlock/BoxBlock'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import React, { RefObject, useEffect, useState } from 'react'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

export function PageCollections({
  parentRef,
  siteID,
  onClose,
}: {
  parentRef: RefObject<HTMLDivElement>
  siteID: string
  onClose: () => void
}) {
  const [currentPaidPlaceID, setCurrentPaidPlaceID] = useState('')
  const pageCollections = trpc.useQuery(['user.blockCollections.getPageCollections'], {
    onSuccess: data => {
      if (data && !currentPaidPlaceID) {
        for (let index = 0; index < data.paidPlaces.length; index++) {
          const paidPlace = data.paidPlaces[index]
          if (paidPlace.site) return setCurrentPaidPlaceID(paidPlace.id)
        }
      }
    },
  })

  const site = pageCollections.data?.paidPlaces.find(p => p.id == currentPaidPlaceID)?.site
  return (
    <>
      <Group p={'sm'}>
        {pageCollections.data ? (
          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              alignContent: 'start',
              justifyContent: 'flex-start',
              gap: 12,
            }}
            onClick={event => {
              event.stopPropagation()
            }}
          >
            <Group
              // position="center"
              css={{
                width: '100%',
              }}
              spacing={8}
            >
              {[...pageCollections.data.paidPlaces]
                .filter(p => p.site)
                .map(paidPlace => {
                  return (
                    <Button
                      onClick={() => {
                        setCurrentPaidPlaceID(paidPlace.id)
                      }}
                      color={colors.blue}
                      size={'xs'}
                      leftIcon={paidPlace.id == currentPaidPlaceID ? <IconArrowRight /> : undefined}
                    >
                      {paidPlace.site?.name}
                    </Button>
                  )
                })}
            </Group>

            {site ? (
              <Group
                css={{
                  background: '#eee',
                  borderRadius: 4,
                  width: '100%',
                }}
                p={'sm'}
                spacing={8}
              >
                {site.pagesV1.map(page => (
                  <PageCollectionShell
                    pageTitle={page.title}
                    key={page.id}
                    siteID={siteID}
                    parentRef={parentRef}
                    pageID={page.id}
                    onClick={() => {}}
                    onClose={onClose}
                  />
                ))}
              </Group>
            ) : null}
          </div>
        ) : (
          <SimpleGrid title={'Загрузка страниц'}>
            <Group>
              {[1, 2, 3, 4, 5].map(() => (
                <Skeleton height={20} width={60}></Skeleton>
              ))}
            </Group>
            <Group>
              {[1, 2, 3, 4, 5, 6, 1, 1, 1, 1, 1, 1].map(() => (
                <Skeleton height={130} width={300}></Skeleton>
              ))}
            </Group>
          </SimpleGrid>
        )}
      </Group>
    </>
  )
}

function PageCollectionShell({
  pageID,
  parentRef,
  onClick,
  siteID,
  pageTitle,
  onClose,
}: {
  pageID: string
  parentRef: RefObject<HTMLDivElement>
  onClick: () => void
  siteID: string
  pageTitle: string
  onClose: () => void
}) {
  const { ref, entry } = useIntersection({
    root: parentRef.current,
    threshold: 0.05,
  })
  const { classes } = useMainStyles()

  const copyTemplatePage = trpc.useMutation('user.blockCollections.copyTemplatePage', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [opened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(!!entry?.isIntersecting)
  }, [entry?.isIntersecting])

  return (
    <>
      <div
        onClick={async () => {
          showNotification({
            title: 'Копирование займет какое то время, но вы уже можете перейти к редактированию страницы',
            message: '',
            color: 'green',
          })
          onClose()
          await copyTemplatePage.mutateAsync({
            pageForCopyID: pageID,
            siteID,
          })
          showNotification({
            title: 'Страница скопирована полностью',
            message: '',
            color: 'green',
          })
        }}
        css={{
          width: 250,
          height: 450,
          border: `2px solid #ddd`,
          overflowY: 'auto',
          cursor: 'pointer',
          position: 'relative',
          transition: '0.3s',
          background: 'white',
          '&:hover': {
            transform: `scale(1.05)`,
          },
        }}
        ref={ref}
        className={classes.hideScrollbar}
      >
        <div
          css={{
            // background: "#ddd",
            color: '#333',
            fontSize: 12,
            borderBottom: '1px solid #ddd',
            padding: 4,
          }}
        >
          {pageTitle}
        </div>
        {copyTemplatePage.isLoading ? (
          <div
            css={{
              left: '50%',
              top: '50%',
              position: 'absolute',
              transform: `translate(-50%, -50%)`,
              zIndex: 1000,
            }}
          >
            <Loader />
          </div>
        ) : null}
        {opened ? <PageCollectionMemo pageID={pageID} /> : <></>}
      </div>
    </>
  )
}

const PageCollectionMemo = React.memo(PageCollection, () => true)

function PageCollection({ pageID }: { pageID: string }) {
  const blockCollection = trpc.useQuery(
    [
      'user.blockCollections.getPageCollection',
      {
        pageID,
      },
    ],
    {
      refetchInterval: 5000,
    }
  )

  return (
    <>
      {blockCollection.data ? (
        <>
          <ReduceComponent
            hideBorder={true}
            component={
              <>
                {blockCollection.data.sectionsPinnedCollection.map(sectionPinnedCollection => (
                  <>
                    {sectionPinnedCollection.sectionsCollection.sectionsChild.map(sectionChild =>
                      sectionChild.boxBlock ? <BoxBlock {...sectionChild.boxBlock.box} /> : <></>
                    )}
                  </>
                ))}
              </>
            }
          />
        </>
      ) : (
        <div
          css={{
            left: '50%',
            top: '50%',
            position: 'absolute',
            transform: `translate(-50%, -50%)`,
          }}
        >
          <Loader />
        </div>
      )}
    </>
  )
}
