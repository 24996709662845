import { ActionIcon } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { ScreeenType } from '@prisma/client'
import { IconDeviceDesktop, IconDeviceMobile, IconDeviceTablet } from '@tabler/icons'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import equal from 'fast-deep-equal'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { synchronFrontendEngineAtSite } from 'generation/synchronFrontendAtSite'
import { ReactNode, memo, useEffect, useRef, useState, useTransition } from 'react'
import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

const getLayerResposiveModes = (
  mode: ScreeenType
): {
  icon: ReactNode
  mode: ScreeenType
  isActive: boolean
}[] => [
  {
    icon: <IconDeviceDesktop color={colors.blue} />,
    mode: 'Desktop',
    isActive: mode == 'Desktop',
  },
  {
    icon: <IconDeviceTablet color={colors.blue} />,
    mode: 'Tablet',
    isActive: mode == 'Tablet',
  },
  {
    icon: <IconDeviceMobile color={colors.blue} />,
    mode: 'Mobile',
    isActive: mode == 'Mobile',
  },
]

function getCurrentValueAndResponsiveMode<P>({
  mode,
  value,
}: {
  mode: ResponsiveModesObject<ScreeenType>
  value: ResponsiveModesObject<P>
}): {
  mode: ScreeenType
  value: P
} {
  const responsiveMode = activeResponsiveModeCenter.getResponsiveMode()
  if (responsiveMode == 'Desktop') {
    if (mode.desktop == 'Desktop')
      return {
        mode: 'Desktop',
        value: value.desktop,
      }
    else if (mode.desktop == 'Tablet')
      return {
        mode: 'Tablet',
        value: value.tablet,
      }
    else
      return {
        mode: 'Mobile',
        value: value.mobile,
      }
  } else if (responsiveMode == 'Tablet') {
    if (mode.tablet == 'Desktop')
      return {
        mode: 'Desktop',
        value: value.desktop,
      }
    else if (mode.tablet == 'Tablet')
      return {
        mode: 'Tablet',
        value: value.tablet,
      }
    else
      return {
        mode: 'Mobile',
        value: value.mobile,
      }
  } else {
    if (mode.mobile == 'Desktop')
      return {
        mode: 'Desktop',
        value: value.desktop,
      }
    else if (mode.mobile == 'Tablet')
      return {
        mode: 'Tablet',
        value: value.tablet,
      }
    else
      return {
        mode: 'Mobile',
        value: value.mobile,
      }
  }
}

type ResponsiveModesObject<T> = {
  desktop: T
  tablet: T
  mobile: T
}

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useResponsiveGroup<
  Schema extends {
    [group: string]: {
      value: ResponsiveModesObject<any>
      mode: ResponsiveModesObject<ScreeenType>
      onResponsiveModeUpdate: ({}: { newLayerResponsiveMode: ScreeenType; responsiveMode: ScreeenType }) => void
    }
  }
>(groups: Schema, params?: { isTransition?: boolean }) {
  const [settingOfGroups, setSettingOfGroups] = useState(
    Object.keys(groups).reduce(
      (accum, key) => {
        const keyIndex = key as keyof Schema

        accum[keyIndex] = {
          isDrag: false,
          isOpen: false,
        }

        return accum
      },
      {} as {
        [P in keyof Schema]: {
          isDrag: boolean
          isOpen: boolean
        }
      }
    )
  )

  const setValuesRef = (groupsParam?: typeof groups) => {
    const groupsForParse = groupsParam ? groupsParam : groups
    return Object.keys(groupsForParse).reduce(
      (accum, key) => {
        const keyIndex = key as keyof Schema

        const group = groupsForParse[keyIndex]

        const { value, mode } = getCurrentValueAndResponsiveMode(group)

        accum[keyIndex] = {
          value,
          mode,
        }

        return accum
      },
      {} as {
        [P in keyof Schema]: {
          value: any
          mode: ScreeenType
        }
      }
    )
  }

  const valuesRef = useRef(setValuesRef())
  const groupsRef = useRef(groups)

  useEffect(() => {
    valuesRef.current = setValuesRef()
    groupsRef.current = groups
  })

  // const currentResponsiveMode = useCurrentResponsiveMode()
  const [count, setCount] = useState(0)

  const [isPending, startTransition] = useTransition()
  const transitionRef = useRef(params?.isTransition)
  useEffect(() => {
    transitionRef.current = params?.isTransition
  }, [params?.isTransition])

  useEffect(() => {
    const { unlisten } = activeResponsiveModeCenter.subscribe({
      callback: ({ responsiveMode }) => {
        const newValues = setValuesRef(groupsRef.current)
        const prevValues = valuesRef.current
        const valuesIsEqual = equal(newValues, prevValues)
        if (!valuesIsEqual) {
          if (params?.isTransition) {
            startTransition(() => {
              setCount(s => ++s)
            })
          } else {
            setCount(s => ++s)
          }
        }
      },
    })
    return () => {
      unlisten()
    }
  }, [params?.isTransition])

  return Object.keys(groups).reduce(
    (accum, key) => {
      const keyIndex = key as keyof Schema

      const group = groups[keyIndex]

      // if (key == 'height') {
      //   console.log(`name ${name} groups?.height ${JSON.stringify(groups?.height, null, 2)}`)
      // }

      const { value, mode } = getCurrentValueAndResponsiveMode(group)

      accum[keyIndex] = {
        value,
        getValue: () => {
          return getCurrentValueAndResponsiveMode(groupsRef.current[keyIndex]).value
        },

        mode,
        getMode: () => {
          return getCurrentValueAndResponsiveMode(groupsRef.current[keyIndex]).mode
        },

        isDrag: settingOfGroups[keyIndex].isDrag,
        setIsDrag: (isDrag: boolean) => {
          setSettingOfGroups(values => {
            const prevValueGroup = values[keyIndex]

            return {
              ...values,
              [keyIndex]: {
                ...prevValueGroup,
                isDrag,
              },
            }
          })
        },

        controls: (
          <>
            <div
              css={{
                position: 'relative',
                display: 'inline-block',
              }}
              onClick={() => {
                setSettingOfGroups(values => {
                  const prevValueGroup = values[keyIndex]

                  return {
                    ...values,
                    [keyIndex]: {
                      ...prevValueGroup,
                      isOpen: !prevValueGroup.isOpen,
                    },
                  }
                })
              }}
            >
              {settingOfGroups[keyIndex].isOpen ? (
                <div
                  css={{
                    width: 30,
                    border: `1px solid ${colors.blue}`,
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 100,
                    background: 'white',
                    borderRadius: '2px',
                    // boxShadow: '0 0 9px 1px rgba(0, 145, 227, 0.47)',
                  }}
                >
                  {getLayerResposiveModes(mode).map((mode, index) => {
                    return (
                      <div
                        onClick={() => {
                          const responsiveMode = activeResponsiveModeCenter.getResponsiveMode()

                          const groupMethods = groups[keyIndex]

                          groupMethods.onResponsiveModeUpdate({
                            responsiveMode,
                            newLayerResponsiveMode: mode.mode,
                          })
                        }}
                        css={{
                          willChange: 'background, color',
                          padding: 2,
                          width: '100%',
                          fontSize: 14,
                          height: 25,
                          display: 'flex',
                          alignContent: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          '&:hover': {
                            background: `rgba(0, 145, 227, ${mode.isActive ? '0.45' : '0.3'}) !important`,
                          },
                        }}
                        style={{
                          background: `rgba(0, 145, 227, ${mode.isActive ? '0.15' : '0'})`,
                        }}
                      >
                        {mode.icon}
                      </div>
                    )
                  })}
                </div>
              ) : null}
              <ActionIcon size={20}>
                {mode == 'Desktop' ? <IconDeviceDesktop color={colors.blue} /> : null}
                {mode == 'Tablet' ? <IconDeviceTablet color={colors.blue} /> : null}
                {mode == 'Mobile' ? <IconDeviceMobile color={colors.blue} /> : null}
              </ActionIcon>
            </div>
          </>
        ),
      }
      return accum
    },
    {} as {
      [P in keyof Schema]: {
        value: Schema[P]['value']['desktop']
        getValue: () => Schema[P]['value']['desktop']

        mode: ScreeenType
        getMode: () => ScreeenType

        isDrag: boolean
        setIsDrag: (b: boolean) => void

        controls: ReactNode
      }
    }
  )
}

export const BoxSyncV2 = memo(B, () => true)

function B({ modelID, selectID }: { modelID: string; selectID: 'Box1' }) {
  const box = useGetSelectData({ modelID, selectID })

  const responsiveBoxSettings = useGetSelectData({
    modelID: box.responsiveBoxSettings.id,
    selectID: box.responsiveBoxSettings.selectID,
  })

  const desktopBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.desktopBoxSettings.id,
    selectID: responsiveBoxSettings.desktopBoxSettings.selectID,
  })
  const tabletBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.tabletBoxSettings.id,
    selectID: responsiveBoxSettings.tabletBoxSettings.selectID,
  })
  const mobileBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.mobileBoxSettings.id,
    selectID: responsiveBoxSettings.mobileBoxSettings.selectID,
  })

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateBoxResponsiveSettings = trpc.useMutation('user.box.updateBoxResponsiveSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const responsive = useResponsiveGroup({
    width: {
      value: {
        desktop: desktopBoxSettings.width,
        tablet: tabletBoxSettings.width,
        mobile: mobileBoxSettings.width,
      },
      mode: {
        desktop: box.widthDesktopResponsiveMode,
        tablet: box.widthTabletResponsiveMode,
        mobile: box.widthMobileResponsiveMode,
      },
      onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: box.id,
          data: {
            widthDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
            widthTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
            widthMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
          },
        })
        await updateBoxResponsiveSettings.mutateAsync({
          boxID: box.id,
          type: 'width',
          boxBlockScreenType: responsiveMode,
          screenType: newLayerResponsiveMode,
        })
      },
    },
    hidden: {
      value: {
        desktop: desktopBoxSettings.hidden,
        tablet: tabletBoxSettings.hidden,
        mobile: mobileBoxSettings.hidden,
      },
      mode: {
        desktop: box.hiddenDesktopResponsiveMode,
        tablet: box.hiddenTabletResponsiveMode,
        mobile: box.hiddenMobileResponsiveMode,
      },
      onResponsiveModeUpdate: () => {},
    },
  })

  console.log('render box')

  return (
    <div
      css={{
        padding: 100,
      }}
    >
      <div
        css={{
          textAlign: 'center',
        }}
      >
        render
      </div>
      <button onClick={() => {}}>hidden {JSON.stringify(responsive.hidden.value)}</button>
      <button onClick={() => {}}>width {responsive.width.value}</button>
      {responsive.width.controls}
      <DragOffsetPosition
        limited={true}
        valueX={responsive.width.value}
        onStart={async () => {
          if (responsive.width.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
            synchronFrontendEngineAtSite.updatePartialData.Box({
              id: box.id,
              data: {
                widthDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
                widthTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
                widthMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
              },
            })

            if (activeResponsiveModeCenter.getResponsiveMode() == 'Desktop') {
              synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
                id: desktopBoxSettings.id,
                data: {
                  width: responsive.width.getValue(),
                },
              })
            } else if (activeResponsiveModeCenter.getResponsiveMode() == 'Tablet') {
              synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
                id: tabletBoxSettings.id,
                data: {
                  width: responsive.width.getValue(),
                },
              })
            } else {
              synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
                id: mobileBoxSettings.id,
                data: {
                  width: responsive.width.getValue(),
                },
              })
            }

            updateBoxResponsiveSettings.mutateAsync({
              boxID: box.id,
              type: 'width',
              boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
              screenType: activeResponsiveModeCenter.getResponsiveMode(),
            })
          }
        }}
        onChangePosition={({ offsetPosition, differencePositionX, startX }) => {
          // const boxDifferentWidth = Math.floor(startX + offsetPosition.x)

          if (responsive.width.getMode() == 'Desktop') {
            synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
              id: desktopBoxSettings.id,
              data: {
                width: differencePositionX,
              },
            })
          } else if (responsive.width.getMode() == 'Tablet') {
            synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
              id: tabletBoxSettings.id,
              data: {
                width: differencePositionX,
              },
            })
          } else {
            synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
              id: mobileBoxSettings.id,
              data: {
                width: differencePositionX,
              },
            })
          }

          return {
            resultSizes: {
              x: differencePositionX, // differencePositionX,
            },
          }

          // if (typeof differencePositionX == 'number') {
          //   if (responsive.width.getMode() == 'Desktop') {
          //     synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
          //       id: desktopBoxSettings.id,
          //       data: {
          //         width: differencePositionX,
          //       },
          //     })
          //   } else if (responsive.width.getMode() == 'Tablet') {
          //     synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
          //       id: tabletBoxSettings.id,
          //       data: {
          //         width: differencePositionX,
          //       },
          //     })
          //   } else {
          //     synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
          //       id: mobileBoxSettings.id,
          //       data: {
          //         width: differencePositionX,
          //       },
          //     })
          //   }
          // }
        }}
        onEndChangePosition={async ({ resultPosition }) => {
          if (typeof resultPosition.x == 'number') {
            await updateBoxSettings.mutateAsync({
              boxID: box.id,
              screenType: activeResponsiveModeCenter.getResponsiveMode(),
              width: resultPosition.x,
            })
          }
        }}
        style={{}}
        onDrag={({ is }) => {
          responsive.width.setIsDrag(is)
        }}
        onClick={() => {
          // boxWidth.setValue(null)
          // boxHeight.setValue(null)
        }}
      >
        drag width {responsive.width.isDrag ? 'isDrag' : 'isNotDrag'}
      </DragOffsetPosition>
    </div>
  )
}
