import { ActionIcon, Badge, Group, Popover, SimpleGrid, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconPlus, IconTrash } from '@tabler/icons'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

export function ChooseBlockCategorySync({ blockID }: { blockID: string }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const createCategoryBlock = trpc.useMutation('user.categoryBlocks.createCategoryBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinOrUnpinCategoryToBlock = trpc.useMutation('user.categoryBlocks.pinOrUnpinCategoryToBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const { classes } = useMainStyles()

  return (
    <Popover position="right-start" width={'auto'} shadow="md">
      <Popover.Target>
        <Badge
          css={{
            cursor: 'pointer',
          }}
          variant="filled"
        >
          добавить категорию
        </Badge>
      </Popover.Target>
      <Popover.Dropdown
        css={{
          padding: '8px 12px',
          borderRadius: 2,
          maxHeight: 500,
          overflowY: 'auto',
        }}
        className={classes.hideScrollbar}
      >
        <SimpleGrid>
          <CalcWidth
            width={60}
            leftNode={
              <Text
                css={{
                  color: '#333',
                  padding: '2px 0',
                  fontWeight: 'bold',
                }}
                size="sm"
              >
                Категории
              </Text>
            }
            rightNode={
              <Group position={'right'}>
                <ActionIcon
                  onClick={async event => {
                    event.stopPropagation()
                    createCategoryBlock.mutate({
                      siteID: site.id,
                    })
                  }}
                  size={20}
                >
                  <IconPlus color={colors.blue} />
                </ActionIcon>
              </Group>
            }
          />

          <SimpleGrid>
            {site.categoryBlocks.map(category => (
              <CategoryBlockSync
                key={category.id}
                modelID={category.id}
                selectID={category.selectID}
                onChoose={() => {
                  pinOrUnpinCategoryToBlock.mutate({
                    blockID,
                    categoryID: category.id,
                  })
                }}
              />
            ))}
          </SimpleGrid>
        </SimpleGrid>
      </Popover.Dropdown>
    </Popover>
  )
}

const CategoryBlockSync = ({
  selectID,
  modelID,
  onChoose,
}: {
  selectID: 'BlockCategory1'
  modelID: string
  onChoose: () => void
}) => {
  const category = useGetSelectData({ modelID, selectID })

  const updateCategoryBlock = trpc.useMutation('user.categoryBlocks.updateCategoryBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteCategoryBlock = trpc.useMutation('user.categoryBlocks.deleteCategoryBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  return (
    <CalcWidth
      width={60}
      leftNode={
        <TextInputWithUpdate
          unstyled={true}
          value={category.name}
          placeholder={'Название категории'}
          onChange={async value => {
            updateCategoryBlock.mutate({
              сategoryBlockID: category.id,
              name: value,
            })
          }}
        />
      }
      rightNode={
        <Group position={'right'}>
          <ActionIcon
            color={'red'}
            onClick={() => {
              deleteCategoryBlock.mutate({
                сategoryBlockID: category.id,
              })
            }}
            size={20}
          >
            <IconTrash />
          </ActionIcon>
          <ActionIcon
            color={colors.blue}
            onClick={() => {
              onChoose()
            }}
            size={20}
          >
            <IconPlus />
          </ActionIcon>
        </Group>
      }
    />
  )
}
