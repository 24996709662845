import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Container,
  CopyButton,
  Divider,
  Drawer,
  Grid,
  Group,
  Image,
  Modal,
  Paper,
  Popover,
  SimpleGrid,
  Space,
  Spoiler,
  Switch,
  Tabs,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  createStyles,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconArrowsMoveVertical, IconExternalLink, IconPlus, IconSettings, IconTarget, IconTrash, IconX } from '@tabler/icons'
import { PageCollections } from 'components/PageCollections/PageCollections'
import { PageLoader } from 'components/PageLoader/PageLoader'
import { VinglaSupport } from 'components/VinglaSupport/VinglaSupport'
import { SiteTablesSync } from 'syncComponents/SiteTablesSync/SiteTablesSync'

import { SyncSiteContextProvider, useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { frontendCallbackDataCenterSite } from 'generation/synchronFrontendAtSite'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'
import { ChooseTarget } from 'syncComponents/ChooseTarget/ChooseTarget'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'

import { TargetSync } from 'syncComponents/TargetSync/TargetSync'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { animationEmotionBounce } from 'utils/animationEmotionBounce'

import { VINGLA } from 'utils/VINGLA'
import { getPathToFile } from 'utils/getPathToFile'
import { ruMoment } from 'utils/ruMoment'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { validPathname } from 'utils/validPathname'

import { List, arrayMove } from 'react-movable'
import useStateRef from 'react-usestateref'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip as TooltipChart, XAxis, YAxis } from 'recharts'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { connectChannelToSiteByCode, vinglaServiceBotUserName } from 'utils/telegramGiftCons'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

import { css } from '@emotion/react'
import { Favicon } from 'utils/Favicon'

export function SiteFaviconSync({ selectID, imageID }: { imageID: string; selectID: 'Image1' }) {
  const favicon = useGetSelectData({ modelID: imageID, selectID })

  return <Image width={24} height={24} src={getPathToFile({ fileName: favicon.name, folderName: favicon.folder })} />
}
