import { ActionIcon, Box, Button, Group, Paper, Space } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconHandGrab } from '@tabler/icons'

import EditorText from 'components/EditorText/EditorText'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { arrayMove, List } from 'react-movable'
import { FaqComponentItemSelect, FaqComponentSelect } from 'server/selects'
import { trpc } from 'utils/trpc'
import Resize from '../Resize/Resize'

export function EditOneFaqComponent(faqComponent: NonNullable<FaqComponentSelect>) {
  const site = useContext(SiteContext)
  const updateFaqComponentItem = trpc.useMutation('user.box.updateFaqComponentItem')
  const createFaqComponentItem = trpc.useMutation('user.box.createFaqComponentItem')

  return (
    <Group my="md">
      <Button
        compact
        loading={createFaqComponentItem.isLoading}
        onClick={() =>
          createFaqComponentItem.mutate({
            faqComponentID: faqComponent.id,
            order: faqComponent.faqItems.length,
          })
        }
      >
        Создать faq
      </Button>
      <List
        lockVertically
        values={faqComponent.faqItems}
        onChange={({ oldIndex, newIndex }) => {
          // TODO это пиздец какой костыль массивом проходиться и сохранять порядок
          arrayMove(faqComponent.faqItems, oldIndex, newIndex).map(async (faq, index) => {
            updateFaqComponentItem.mutate({
              faqComponentItemID: faq.id,
              order: index,
            })
          })
        }}
        renderList={({ children, props }) => <div {...props}>{children}</div>}
        renderItem={({ value, props }) => (
          <Box
            mb="xs"
            key={value.id}
            css={{
              zIndex: 10000,
              position: 'relative',
            }}
            {...props}
          >
            <EditOneFaqComponentItem key={value.id} {...value} />
          </Box>
        )}
      />
    </Group>
  )
}

function EditOneFaqComponentItem(faq: NonNullable<FaqComponentItemSelect>) {
  const updateFaqComponentItem = trpc.useMutation('user.box.updateFaqComponentItem')
  const deleteFaqComponentItem = trpc.useMutation('user.box.deleteFaqComponentItem')

  const form = useForm({
    initialValues: {
      question: faq.question,
      answer: faq.answer,
    },
  })

  return (
    <Paper withBorder p={'md'}>
      <form
        onSubmit={form.onSubmit(values =>
          updateFaqComponentItem.mutate({
            faqComponentItemID: faq.id,
            question: values.question,
            answer: values.answer,
          })
        )}
      >
        <EditorText
          defaultValue={faq.question}
          title="Вопрос "
          onChange={value => form.setValues({ ...form.values, question: value })}
        />
        <Space mb="xs" />

        <EditorText
          defaultValue={faq.answer}
          title="Ответ"
          onChange={value => form.setValues({ ...form.values, answer: value })}
        />

        <Group position="right" mt="md">
          <ActionIcon size={'md'} data-movable-handle>
            <IconHandGrab />
          </ActionIcon>
          <Button
            loading={deleteFaqComponentItem.isLoading}
            color="red"
            variant="filled"
            onClick={() =>
              deleteFaqComponentItem.mutate({
                faqComponentItemID: faq.id,
              })
            }
          >
            Удалить
          </Button>
          <Button type="submit" loading={updateFaqComponentItem.isLoading}>
            Обновить
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
