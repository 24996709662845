import { ActionIcon, AspectRatio, Group, Image, Modal, Paper, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { showNotification } from '@mantine/notifications'
import { IconRefresh, IconX, IconXboxX } from '@tabler/icons'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { useState } from 'react'
import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'
import { getPathToFile } from 'utils/getPathToFile'
import { trpc } from 'utils/trpc'

export function ImageGallerySync({
  imageChoosen,
  imageName,
  imageFolder,
  placeholder,
}: {
  imageChoosen: ({ imageID }: { imageID: string }) => void
  imageName?: string
  imageFolder: string | null
  placeholder?: string
}) {
  const [opened, setOpened] = useState(false)
  const [imageNameState, setImageNameState] = useState('')
  const [imageFolderState, setImageFolderState] = useState<string | null>('')
  return (
    <div>
      <ImagesSync
        opened={opened}
        onClose={() => setOpened(false)}
        imageChoosen={({ imageID, imageName, imageFolder }) => {
          imageChoosen({ imageID })
          setImageNameState(imageName)
          setImageFolderState(imageFolder)
        }}
      />

      <Paper withBorder px={12} py="xs">
        <Group>
          {placeholder ? <Text size={'md'}>{placeholder}:</Text> : null}
          {imageNameState ? (
            <Image width={28} height={28} src={getPathToFile({ fileName: imageNameState, folderName: imageFolderState })} />
          ) : imageName ? (
            <Image width={28} height={28} src={getPathToFile({ fileName: imageName, folderName: imageFolder })} />
          ) : null}
          <ActionIcon onClick={() => setOpened(true)} variant="light">
            <IconRefresh />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              imageChoosen({ imageID: '' })
              setImageNameState('')
              setImageFolderState('')
            }}
            variant="light"
          >
            <IconXboxX />
          </ActionIcon>
        </Group>
      </Paper>
    </div>
  )
}

export function ImagesSync({
  onClose,
  imageChoosen,
  opened,
}: {
  onClose: () => void
  imageChoosen: (data: { imageID: string; imageName: string; imageFolder: string | null }) => void
  opened: boolean
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const uploadToServer = async ({ file, siteID }: { file: File; siteID: string }) => {
    setImageLoading(true)

    const body = new FormData()

    const imageNode = document.createElement('img')
    imageNode.onload = async () => {
      const width = imageNode.clientWidth
      const height = imageNode.clientHeight

      document.body.removeChild(imageNode)

      body.append('file', file)
      try {
        const request = await fetch('/api/file', {
          method: 'POST',
          body,
          headers: {
            siteid: siteID,
            width: `${width}`,
            height: `${height}`,
          },
        })
        if (request.status == 400) {
          const json = await request.json()
          showNotification({
            title: `${json.data}`,
            message: '',
            color: 'red',
          })
        }
      } catch (error) {
      } finally {
      }
      setImageLoading(false)
    }
    imageNode.style.position = 'absolute'
    imageNode.style.pointerEvents = 'none'
    imageNode.style.opacity = '0'
    imageNode.src = URL.createObjectURL(file)
    document.body.appendChild(imageNode)
  }

  const [imageLoading, setImageLoading] = useState(false)

  return (
    <Modal
      transitionDuration={0}
      size={1200}
      opened={opened}
      onClose={() => {
        if (onClose) onClose()
      }}
      title="Выбрать картинку"
      css={{
        overflowY: 'scroll',
        zIndex: 10000000,
      }}
    >
      <Group>
        <div
          css={{
            height: 150,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            width: 150,
          }}
        >
          <Dropzone
            loading={imageLoading}
            onDrop={files => {
              files.map(file => {
                uploadToServer({ file, siteID: site.id })
              })
            }}
            sx={theme => ({
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `2px dashed ${cabinetColors.border}`,
              borderRadius: cabinetSizes.radius,

              '&[data-accept]': {
                color: theme.white,
                backgroundColor: theme.colors.blue[6],
              },

              '&[data-reject]': {
                color: theme.white,
                backgroundColor: theme.colors.red[6],
              },
            })}
          >
            <Text align="center">Загрузить картинки</Text>
          </Dropzone>
        </div>
        {site.images.map(image => (
          <div
            key={image.id}
            css={{
              width: 150,
            }}
          >
            <ImageGalleryCardSync
              image={{
                id: image.id,
                selectID: image.selectID,
              }}
              imageChoosen={({ imageName, imageFolder }) => {
                imageChoosen({ imageID: image.id, imageName, imageFolder })
                onClose()
              }}
            />
          </div>
        ))}
      </Group>
    </Modal>
  )
}

export const ImageGalleryCardSync = ({
  image,
  imageChoosen,
}: {
  image: {
    id: string
    selectID: 'Image1'
  }
  imageChoosen: ({}: { imageName: string; imageFolder: string | null }) => void
}) => {
  const imageData = useGetSelectData({ modelID: image.id, selectID: image.selectID })

  const deleteImage = trpc.useMutation('user.image.deleteImage', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '0.25s',
        // border: `0.5px solid transparent`,
        borderRadius: cabinetSizes.radius,
        ':hover': {
          transform: 'scale(1.05)',
          // background: '#fff',
          // border: `0.5px solid ${cabinetColors.border}`,
        },
        // background: '#eee',
        position: 'relative',
      }}
      key={imageData.id}
      onClick={() => {
        imageChoosen({ imageName: imageData.name, imageFolder: imageData.folder })
      }}
    >
      <div
        css={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 100,
          transform: 'translate(30%, -30%)',
        }}
      >
        <ActionIcon
          size={'sm'}
          onClick={event => {
            event.stopPropagation()
            deleteImage.mutate({
              imageID: imageData.id,
            })
          }}
          variant="filled"
          color={'red'}
        >
          <IconX color={'white'} />
        </ActionIcon>
      </div>

      <Text
        css={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 100,
          padding: '4px',
          textShadow: 'rgba(0,0,0,0.55) 0 0 4px',
          color: 'white',
          fontWeight: 600,
        }}
      >
        {(imageData.size / (1024 * 1024)).toFixed(2)} Mb
      </Text>
      <AspectRatio css={{ width: '100%' }} ratio={1 / 1}>
        <div>
          <img
            src={getPathToFile({ fileName: imageData.name, folderName: imageData.folder })}
            css={{
              maxWidth: '100%',
              maxHeight: '100%',
              border: `0.5px solid ${cabinetColors.border}`,
              borderRadius: cabinetSizes.radius,
            }}
          />
        </div>
      </AspectRatio>
    </div>
  )
}
