export const isInputValid = ({ type, value }: { type: 'Phone' | 'Text'; value: string }) => {
  if (type == 'Text' && value) {
    return true
  }
  if (type == 'Phone' && value && !value.includes('_')) {
    // +7(788)-888-88-__, _ means empty number
    return true
  }

  return false
}
