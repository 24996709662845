import { MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { ActionIcon, Badge, Button, Drawer, Group, Loader, Popover, SimpleGrid, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconArrowsMoveVertical, IconDots, IconPlus, IconTrash, IconX } from '@tabler/icons'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { BoxBlockSync } from 'pages/panel/sitesV2/[siteID]/pages/[pageID]'
import { RefObject, useEffect, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { ChooseSitePopupsSync } from 'syncComponents/ChooseSitePopupsSync/ChooseSitePopupsSync'
import { SiteBlocksSync } from 'syncComponents/SiteBlocksSync/SiteBlocksSync'

import { useIntersection } from '@mantine/hooks'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { List } from 'react-movable'

export function BlockGroupsSync({
  onChooseSection,
  parentRef,
}: {
  parentRef: RefObject<HTMLDivElement>
  onChooseSection: ({}: { sectionsID: string }) => void
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const { classes } = useMainStyles()
  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 12,
        background: '#eee',
        borderRadius: 4,
        padding: 16,
      }}
    >
      <div
        css={{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Badge color={'dark'}>блоки этого сайта</Badge>
      </div>
      {site.sectionsCollection.map(sectionsV1 => (
        <SectionsV1Sync
          parentRef={parentRef}
          onChooseSection={onChooseSection}
          key={sectionsV1.id}
          modelID={sectionsV1.id}
          selectID={sectionsV1.selectID}
        />
      ))}
    </div>
  )
}

function SectionsV1Sync({
  modelID,
  selectID,
  onChooseSection,
  parentRef,
}: {
  modelID: string
  selectID: 'Sections1'
  onChooseSection: ({}: { sectionsID: string }) => void
  parentRef: RefObject<HTMLDivElement>
}) {
  const sectionsV1 = useGetSelectData({ modelID, selectID })

  const [chooseBlocksOpened, setChooseBlocksOpened] = useState(false)

  const deleteSections = trpc.useMutation('user.deleteSections', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createSection = trpc.useMutation('user.createSection', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSection = trpc.useMutation('user.updateSection', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteSection = trpc.useMutation('user.deleteSection', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSectionsOrder = trpc.useMutation('user.updateSectionsOrder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteSectionsPinned = trpc.useMutation('user.deleteSectionsPinned', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [sectionsChild, setSectionsChild, sectionsChildRef] = useStateRef(sectionsV1.sectionsChild)

  useEffect(() => {
    setSectionsChild(sectionsV1.sectionsChild)
  }, [sectionsV1.sectionsChild])

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
      // delay: 100,
      // tolerance: 100,
    },
  })

  const sensors = useSensors(mouseSensor)

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const { classes } = useMainStyles()
  const { ref, entry } = useIntersection({
    root: parentRef.current,
    threshold: 0.05,
  })

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {chooseBlocksOpened ? (
        <Drawer
          transitionDuration={0}
          css={{
            overflowY: 'auto',
          }}
          onClose={() => setChooseBlocksOpened(false)}
          position="left"
          size={1200}
          opened={chooseBlocksOpened}
          zIndex={400}
          // @ts-ignore
          ref={containerRef}
        >
          <SimpleGrid
            css={{
              alignItems: 'flex-start',
            }}
            spacing={50}
            cols={1}
          >
            <SiteBlocksSync
              parentRef={containerRef}
              onChooseBlock={({ blockID }) => {
                createSection.mutate({
                  sectionsID: sectionsV1.id,
                  blockID,
                  type: 'box',
                  order: sectionsV1.sectionsChild.length,
                })
                // setChooseBlocksOpened(false)
              }}
            />
          </SimpleGrid>
        </Drawer>
      ) : null}

      <div
        css={{
          width: 250,
          display: 'inline-block',
          position: 'relative',
        }}
      >
        <div css={{ background: 'white', position: 'relative' }}>
          <List
            lockVertically
            values={sectionsChild}
            onChange={({ oldIndex, newIndex }) => {
              const newOrder = arrayMove(sectionsChildRef.current, oldIndex, newIndex)

              setSectionsChild(newOrder)

              updateSectionsOrder.mutate({
                sectionsID: sectionsV1.id,
                order: newOrder.map((input, index) => ({ id: input.id, order: index })),
              })

              // newOrder.map(async (sectionChild, index) => {
              //   await updateSection.mutate({
              //     sectionID: sectionChild.id,
              //     order: index,
              //   })
              // })
            }}
            renderList={({ children, props }) => (
              <SimpleGrid
                spacing={4}
                onClick={() => {
                  onChooseSection({ sectionsID: sectionsV1.id })
                }}
                ref={ref}
              >
                <div
                  css={{
                    position: 'relative',
                  }}
                >
                  <Group
                    onClick={event => {
                      event.stopPropagation()
                    }}
                    css={{
                      position: 'absolute',
                      bottom: 12,
                      right: 12,
                      zIndex: 200,
                    }}
                    spacing={4}
                  >
                    {site.withBlockGroups ? (
                      <ActionIcon
                        onClick={() => {
                          setChooseBlocksOpened(true)
                        }}
                        color={colors.blue}
                        variant="light"
                        size={'xs'}
                      >
                        <IconPlus size={20} />
                      </ActionIcon>
                    ) : null}

                    <ActionIcon
                      onClick={() => {
                        deleteSections.mutate({ sectionsID: sectionsV1.id })
                      }}
                      color={'red'}
                      variant="light"
                      size={'xs'}
                    >
                      <IconTrash size={20} />
                    </ActionIcon>
                  </Group>
                  <div
                    css={{
                      height: 200,
                      overflowY: 'auto',
                      border: `2px solid #ddd`,
                      cursor: 'pointer',
                      transition: '0.3s',
                      position: 'relative',
                      '&:hover': {
                        transform: `scale(1.02)`,
                      },
                    }}
                    className={classes.hideScrollbar}
                    {...props}
                  >
                    {entry?.isIntersecting ? (
                      children
                    ) : (
                      <div
                        css={{
                          left: '50%',
                          top: '50%',
                          position: 'absolute',
                          transform: `translate(-50%, -50%)`,
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>

                <Button color={colors.blue} compact fullWidth>
                  вставить блок на страницу
                </Button>
              </SimpleGrid>
            )}
            renderItem={({ value, props }) => (
              <div
                css={{
                  zIndex: 10000,
                  position: 'relative',
                }}
                key={value.id}
                {...props}
              >
                <SectionsSyncForBlockGroups
                  onClick={() => {}}
                  onContextMenu={() => {}}
                  width={1920}
                  key={value.id}
                  modelID={value.id}
                  selectID={value.selectID}
                />
              </div>
            )}
          />
        </div>
      </div>
    </>
  )
}

export const SectionsSyncForBlockGroups = ({
  modelID,
  selectID,
  width,
  hideLine,
  onClick,
  onContextMenu,
}: {
  modelID: string
  selectID: 'Section1'
  width: number
  hideLine?: true
  onClick: () => void
  onContextMenu: () => void
}) => {
  const section = useGetSelectData({ modelID, selectID })
  const deleteSection = trpc.useMutation('user.deleteSection', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSection = trpc.useMutation('user.updateSection', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  return (
    <>
      <div
        style={{
          position: 'relative',
        }}
      >
        <Group
          onClick={event => {
            event.stopPropagation()
          }}
          css={{
            position: 'absolute',
            top: 12,
            right: 12,
            zIndex: 100,
          }}
          spacing={4}
        >
          {site.withBlockGroups ? (
            <>
              <ActionIcon variant="light" color={'grape'} size={'xs'} data-movable-handle>
                <IconArrowsMoveVertical style={{ cursor: 'ns-resize' }} />
              </ActionIcon>

              <ActionIcon
                onClick={event => {
                  event.stopPropagation()
                  deleteSection.mutate({ sectionID: section.id })
                }}
                color={'red'}
                title={'Открепить'}
                size={'xs'}
                variant="light"
              >
                <IconX
                  css={{
                    cursor: 'not-allowed',
                  }}
                  width={20}
                />
              </ActionIcon>
            </>
          ) : (
            <>
              <div data-movable-handle></div>
            </>
          )}

          <Popover withinPortal position="right-start" width={250} shadow="md">
            <Popover.Target>
              <ActionIcon
                onClick={event => {
                  event.stopPropagation()
                }}
                variant="light"
                color={'grey'}
                size={'xs'}
              >
                <IconDots />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown
              css={{
                padding: '8px 12px',
                borderRadius: 2,
              }}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              {section.popupOpen ? (
                <Text
                  css={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    updateSection.mutate({
                      sectionID: section.id,
                      popupID: '',
                    })
                  }}
                >
                  Открепить всплывашку{' '}
                  <span
                    css={{
                      fontWeight: 'bold',
                    }}
                  >
                    "<PopupNameSync modelID={section.popupOpen.id} selectID={section.popupOpen.selectID} />"
                  </span>
                </Text>
              ) : (
                <ChooseSitePopupsSync
                  title={'Открывать при проскроливании всплывашку'}
                  onChoosePopup={({ popupID }) => {
                    updateSection.mutate({
                      sectionID: section.id,
                      popupID,
                    })
                  }}
                />
              )}
            </Popover.Dropdown>
          </Popover>
        </Group>
        <div
          onClick={() => {
            onClick()
          }}
          onContextMenu={() => {
            onContextMenu()
          }}
          css={{
            cursor: 'pointer',
          }}
        >
          <div
            css={{
              pointerEvents: 'none',
            }}
          >
            <ReduceComponent
              hideBorder={true}
              component={
                <>
                  {section.boxBlock ? (
                    <BoxBlockSync
                      hideLine={hideLine}
                      width={width}
                      modelID={section.boxBlock.id}
                      selectID={section.boxBlock.selectID}
                    />
                  ) : (
                    'boxBlock not found'
                  )}
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}

function PopupNameSync({ modelID, selectID }: { modelID: string; selectID: 'PopupWithName' }) {
  const popup = useGetSelectData({ modelID, selectID })
  return <>{popup.name}</>
}
