import { createStyles, Paper, Text } from '@mantine/core'
import { Editor } from '@tiptap/react'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { Feather } from 'utils/Feather'

const iconWidth = 12
const buttonWidth = 25

const useStyles = createStyles(theme => ({
  button: {},
}))

export const RichEditorControlsTipTap = ({ editor }: { editor: Editor | null }) => {
  const site = useContext(SiteContext)
  const styles = useStyles()

  if (!editor) {
    return null
  }

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }

  return (
    <div
      onClick={event => {
        event.stopPropagation()
      }}
      css={{
        button: {
          margin: `0 2px 0 0 `,
          width: buttonWidth,
          height: buttonWidth,
          display: 'inline-flex',
          // padding: '2px 6px',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          border: '1px solid grey',
          background: 'transparent',
          lineHeight: `${buttonWidth}px`,

          '&:hover': {
            cursor: 'pointer',
          },
        },
        'button.is-active': {
          background: '#333',
          color: '#fff',
        },
      }}
    >
      <Paper mb={4}>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <Text mr="xs" size={'xs'}>
            Расскрасить
          </Text>
          {site.colors.map(color => (
            <div
              onClick={() => editor.commands.setColor(`var(--color-${color.name})`)}
              css={{
                width: buttonWidth,
                height: buttonWidth,
                background: `${color.value}`,
                border: '1px solid grey',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
          ))}
          <button className={editor.isActive('color') ? 'is-active' : ''} onClick={() => editor.commands.unsetColor()}>
            <Feather.Slash width={iconWidth} />
          </button>
        </div>
      </Paper>

      {/* <button
        className={editor.isActive('color') ? 'is-active' : ''}
        onClick={() => editor.commands.setColor('var(--main-bg-color)')}
      >
        attribute color 1
      </button> */}
      {/* <br /> */}
      <Paper mb={4}>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <Text mr="xs" size={'xs'}>
            Выделить
          </Text>
          {site.colors.map(color => (
            <div
              onClick={() => editor.commands.setHighlight({ color: `var(--color-${color.name})` })}
              css={{
                width: buttonWidth,
                height: buttonWidth,
                background: `${color.value}`,
                border: '1px solid grey',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
          ))}
          <button className={editor.isActive('highlight') ? 'is-active' : ''} onClick={() => editor.commands.unsetHighlight()}>
            <Feather.Slash width={iconWidth} />
          </button>
        </div>
      </Paper>

      {/* <button
        className={editor.isActive('highlight') ? 'is-active' : ''}
        onClick={() => editor.commands.setHighlight({ color: 'var(--main-bg-color)' })}
      >
        highlight color 2
      </button> */}

      {/* <br /> */}
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <Feather.Bold width={iconWidth} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <Feather.Italic width={iconWidth} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          <Feather.Minus width={iconWidth} />
        </button>
        {/* <button onClick={() => editor.chain().focus().toggleCode().run()} className={editor.isActive('code') ? 'is-active' : ''}>
        code
      </button> */}
        {/* <br /> */}
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          <Feather.Type width={iconWidth} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          h1
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          h2
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          h3
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          h4
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          h5
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          h6
        </button>
        {/* <br /> */}
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <Feather.List width={iconWidth} />
        </button>
        {/* <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Feather.List width={iconWidth} /> цифры
      </button> */}
        {/* <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        code block
      </button> */}
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <Feather.Square width={iconWidth} />
        </button>
        {/* <br /> */}
        <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          <Feather.Slash width={iconWidth} />
          {/* clear marks */}
        </button>
        <button onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
          <Feather.Link width={iconWidth} />
        </button>
        <button
          css={{
            position: 'relative',
            '&:after': {
              content: `""`,
              position: 'absolute',
              width: '70%',
              height: '1px',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              transformOrigin: 'center center',
              background: 'black',
            },
          }}
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive('link')}
        >
          <Feather.Link width={iconWidth} />
        </button>
        {/* <button onClick={() => editor.chain().focus().clearNodes().run()}>clear nodes</button> */}
        {/* <button onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</button> */}
        {/* <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</button>
      <button onClick={() => editor.chain().focus().undo().run()}>undo</button>
      <button onClick={() => editor.chain().focus().redo().run()}>redo</button> */}
      </div>
    </div>
  )
}
