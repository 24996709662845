import { ActionIcon, Drawer, Group, Modal, Paper, SimpleGrid, Text, TextInput, Textarea, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import {
  IconArrowsMoveVertical,
  IconCirclePlus,
  IconExternalLink,
  IconRefresh,
  IconSettings,
  IconTrash,
  IconX,
} from '@tabler/icons'
import { PageCollections } from 'components/PageCollections/PageCollections'
import { mqBox } from 'config/mq'
import Link from 'next/link'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { cabinetColors } from 'utils/cabinetTheme'

import { animationEmotionBounce } from 'utils/animationEmotionBounce'

import { ChooseSiteTableViewSync } from 'syncComponents/ChooseSiteTableViewSync/ChooseSiteTableViewSync'
import { trpc } from 'utils/trpc'

import { validPathname } from 'utils/validPathname'

import { List, arrayMove } from 'react-movable'
import useStateRef from 'react-usestateref'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'

import { css } from '@emotion/react'
import { AccentButton } from 'components/AccentButton/AccentButton'

export const SitePagesMemo = React.memo(SitePages, () => true)

function SitePages({ onClose }: { onClose?: () => void }) {
  const { siteID } = useSyncSiteContext()

  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updatePagesOrder = trpc.useMutation('user.updatePagesOrder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const [pages, setPages, pagesRef] = useStateRef(site.pagesV1)
  useEffect(() => {
    setPages(site.pagesV1)
  }, [site.pagesV1])

  const [createPageOpened, setCreatePageOpened] = useState(false)

  const form = useForm({
    initialValues: {
      title: '',
      description: '',
      pathname: '',
      tableID: '',
    },
    validate: {
      pathname: validPathname,
    },
  })
  const [choosePageCollectionOpened, setChoosePageCollectionOpened] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const { classes } = useMainStyles()

  const createPage = trpc.useMutation('user.createPage', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <>
      {choosePageCollectionOpened ? (
        <Drawer
          css={{
            overflowY: 'auto',
          }}
          withinPortal={true}
          opened={choosePageCollectionOpened}
          onClose={() => setChoosePageCollectionOpened(false)}
          size={1200}
          shadow="md"
          className={classes.hideScrollbar}
          // @ts-ignore
          ref={containerRef}
          position="right"
        >
          <PageCollections
            onClose={() => {
              setChoosePageCollectionOpened(false)
            }}
            parentRef={containerRef}
            siteID={site.id}
          />
        </Drawer>
      ) : null}
      {/* {createPageOpened ? (
        <Modal
          transitionDuration={0}
          opened={createPageOpened}
          onClose={() => setCreatePageOpened(false)}
          title="Cоздать страницу"
          padding="md"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          
        </Modal>
      ) : null} */}

      <SimpleGrid cols={1} spacing={30}>
        <Group position="apart">
          <Group>
            <Title order={2}>СТРАНИЦЫ</Title>
          </Group>

          <Modal
            onClose={() => {
              setCreatePageOpened(false)
            }}
            opened={createPageOpened}
            centered
            title="Добавить страницу"
          >
            <form
              onSubmit={form.onSubmit(async values => {
                await createPage.mutateAsync({
                  siteID: site.id,
                  title: values.title,
                  description: values.description,
                  pathname: values.pathname,
                  order: site.pagesV1.length,
                  tableID: values.tableID,
                })
                setCreatePageOpened(false)
              })}
            >
              <SimpleGrid spacing={12} cols={1}>
                <TextInput label="Заголовок SEO" placeholder="Заголовок для seo" {...form.getInputProps('title')} />
                <Textarea autosize label="Описание SEO" placeholder="Описание для seo" {...form.getInputProps('description')} />
                <TextInput label="URL" placeholder="kvartiry-kemerovo" {...form.getInputProps('pathname')} />

                <Paper withBorder p="xs">
                  <Group>
                    <Text>Прикрепить к списку</Text>
                    <ChooseSiteTableViewSync
                      showNestedTables={true}
                      tableID={form.values.tableID}
                      onChooseTable={async ({ tableID }) => {
                        form.setFieldValue('tableID', tableID)
                      }}
                    />
                    {form.values.tableID ? (
                      <Text
                        onClick={() => {
                          form.setFieldValue('tableID', '')
                        }}
                        color="red"
                      >
                        {' '}
                        открепить
                      </Text>
                    ) : null}
                  </Group>
                </Paper>

                <AccentButton leftIcon={<IconCirclePlus />} fullWidth loading={createPage.isLoading} type="submit">
                  Добавить страницу
                </AccentButton>
              </SimpleGrid>
            </form>
          </Modal>

          <Group>
            <AccentButton
              css={
                site.pagesV1.length
                  ? undefined
                  : css`
                      animation: ${animationEmotionBounce} 2s ease infinite;
                    `
              }
              leftIcon={<IconCirclePlus />}
              onClick={() => setCreatePageOpened(true)}
            >
              Добавить страницу
            </AccentButton>
            {onClose ? (
              <ActionIcon
                onClick={() => {
                  if (onClose) onClose()
                }}
              >
                <IconX />
              </ActionIcon>
            ) : null}
          </Group>
        </Group>

        {pages.length ? (
          <>
            <SimpleGrid spacing={6}>
              <SimpleGrid spacing={6} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} cols={1}>
                <List
                  lockVertically
                  values={pages}
                  onChange={({ oldIndex, newIndex }) => {
                    const newOrder = arrayMove(pagesRef.current, oldIndex, newIndex)

                    setPages(newOrder)

                    updatePagesOrder.mutate({
                      siteID: site.id,
                      order: newOrder.map((input, index) => ({ id: input.id, order: index })),
                    })
                  }}
                  renderList={({ children, props }) => <div {...props}>{children}</div>}
                  renderItem={({ value, props }) => (
                    <div
                      css={{
                        zIndex: 10000,
                        position: 'relative',
                        marginBottom: 12,
                      }}
                      key={value.id}
                      {...props}
                    >
                      <SitePage
                        onClick={() => {
                          if (onClose) onClose()
                        }}
                        key={value.id}
                        pageID={value.id}
                        selectID={value.selectID}
                      />
                    </div>
                  )}
                />
                {/* {site.pagesV1.map(page => (
          <div key={page.id}>
          </div>
        ))} */}
              </SimpleGrid>
            </SimpleGrid>
          </>
        ) : (
          <Text>Страниц нет</Text>
        )}
      </SimpleGrid>
    </>
  )
}

export function SitePage({ pageID, selectID, onClick }: { pageID: string; selectID: 'Page1'; onClick?: () => void }) {
  const { siteID } = useSyncSiteContext()
  const page = useGetSelectData({ modelID: pageID, selectID })
  const router = useRouter()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const pinTable = useGetSelectDataUndefined({ modelID: page.pinTable?.id, selectID: page.pinTable?.selectID })

  const updatePage = trpc.useMutation('user.updatePage', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deletePage = trpc.useMutation('user.deletePage', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const rightSideWidth = 35

  const form = useForm({
    initialValues: {
      title: page.title,
      description: page.description,
      pathname: page.pathname,
    },
    validate: {
      pathname: validPathname,
    },
  })

  const [settingsOpened, setSettingsOpened] = useState(false)
  const pushToPage = `/panel/sitesV2/${siteID}/pages/${page.id}`

  const thereIsText = page.title || page.description

  const [settingsIsOpen, setSettingsIsOpen] = useState(false)

  return (
    <>
      {settingsIsOpen ? (
        <Modal withinPortal={true} centered={true} opened={settingsIsOpen} onClose={() => setSettingsIsOpen(false)}>
          <form
            onSubmit={form.onSubmit(async values => {
              updatePage.mutate({
                pageID: page.id,
                title: values.title,
                description: values.description,
                pathname: values.pathname,
              })
            })}
            onClick={event => {
              event.stopPropagation()
            }}
          >
            <SimpleGrid spacing={12} cols={1}>
              <TextInput label="Заголовок SEO" {...form.getInputProps('title')} />
              <Textarea autosize label="Описание SEO" {...form.getInputProps('description')} />
              <TextInput label="URL" {...form.getInputProps('pathname')} />
              <AccentButton leftIcon={<IconRefresh />} loading={updatePage.isLoading} type="submit">
                Обновить страницу
              </AccentButton>
            </SimpleGrid>
          </form>
        </Modal>
      ) : null}

      <div
        css={{
          // border: '1px solid #eee',
          borderRadius: 16,
          padding: '8px',
          boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.08)',
          background: 'white',
        }}
      >
        <Group
          position="apart"
          css={{
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
          }}
        >
          <SimpleGrid
            css={{
              background: 'rgb(17, 85, 204, 0.1)',
              borderRadius: 16,
              padding: 22,
              minHeight: 'auto',
              width: `calc(100% - ${rightSideWidth}px)`,
              [mqBox.desktop]: {
                minHeight: thereIsText ? 180 : 'auto',
              },
              alignContent: 'space-between',
              display: 'flex',
              flexWrap: 'wrap',
            }}
            cols={1}
          >
            <Link
              onClick={() => {
                if (onClick) onClick()
              }}
              href={pushToPage}
            >
              <div
                onClick={event => {
                  // event.stopPropagation()
                }}
                css={{
                  width: '100%',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.75,
                  },
                }}
              >
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <strong
                    css={{
                      fontSize: 24,
                      [mqBox.desktop]: {
                        fontSize: 32,
                      },
                    }}
                  >
                    {page.title ? page.title : 'Без названия'}
                  </strong>
                </Text>
              </div>
            </Link>
            <Group>
              {site.domain ? (
                <SitePagePathSync
                  isID={!!page.pinTable}
                  domainID={site.domain.id}
                  selectID={site.domain.selectID}
                  pagePathName={page.pathname}
                />
              ) : (
                <Text size={'lg'}>
                  /{page.pathname}
                  {pinTable ? `[${pinTable.name}-ID]` : ''}
                </Text>
              )}
            </Group>
          </SimpleGrid>
          <SimpleGrid
            css={{
              minWidth: rightSideWidth,
              width: rightSideWidth,
              justifyContent: 'flex-end',
            }}
            cols={1}
          >
            <Group position={'right'}>
              <ActionIcon data-movable-handle>
                <IconArrowsMoveVertical />
              </ActionIcon>

              <div
                onClick={event => {
                  event.stopPropagation()
                }}
              >
                <ActionIcon
                  loading={deletePage.isLoading}
                  onClick={() =>
                    deletePage.mutate({
                      pageID: page.id,
                    })
                  }
                  color={'red'}
                >
                  <IconTrash />
                </ActionIcon>
              </div>
              <div
                onClick={event => {
                  event.stopPropagation()
                  setSettingsOpened(true)
                }}
              >
                <ActionIcon
                  onClick={() => {
                    setSettingsIsOpen(true)
                  }}
                >
                  <IconSettings />
                </ActionIcon>
              </div>
            </Group>
          </SimpleGrid>
        </Group>
      </div>
    </>
  )
}

export function SitePagePathSync({
  domainID,
  selectID,
  pagePathName,
  color,
  isID,
}: {
  domainID: string
  selectID: 'Domain1'
  pagePathName: string
  color?: string
  isID?: boolean | string
}) {
  const domain = useGetSelectData({ modelID: domainID, selectID: selectID })

  return (
    <a
      target={'_blank'}
      href={`${domain.domain.includes('localhost') ? 'http' : 'https'}://${domain.domain}/${pagePathName}/${
        typeof isID == 'string' && isID ? `/${isID}` : ''
      }`}
      onClick={event => {
        event.stopPropagation()
      }}
      css={{
        textDecoration: 'none',
      }}
    >
      <Group spacing={4}>
        <Text
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 250,
            color: color ? color : cabinetColors.placeholder,
          }}
          size={'sm'}
        >
          <strong>{domain.domain}</strong>/{pagePathName}
          {typeof isID == 'boolean' && isID ? '/[ID]' : ''}
          {typeof isID == 'string' && isID ? `/${isID}` : ''}
        </Text>
        <IconExternalLink size={16} color={color ? color : cabinetColors.placeholder} />
      </Group>
    </a>
  )
}
