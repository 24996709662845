import {
  IsCan,
  setImageAddAbsoluteLayerState,
  turnAddAbsoluteLayerState,
  useAddAbsoluteLayerState,
} from 'utils/addAbsoluteLayerState'

import { Group, Menu } from '@mantine/core'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { useState } from 'react'
import { colors } from 'utils/styles'

import { IconPhoto, IconTextSize } from '@tabler/icons'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'

const RechagleSVG = ({ color }: { color?: 'blue' }) => {
  const currentColor = color == 'blue' ? colors.blue : 'white'
  return (
    <svg width="137" height="138" viewBox="0 0 137 138" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="25" y="25" width="101" height="102" stroke={currentColor} stroke-opacity="0.66" stroke-width="8" />
      <path d="M24 24H51.5V30H30V52H24V24Z" fill={currentColor} />
      <rect x="16" width="22" height="52" rx="2" fill={currentColor} />
      <rect x="13.5" y="116.5" width="21" height="21" rx="1.5" fill={currentColor} stroke={currentColor} />
      <rect x="115.5" y="115.5" width="21" height="21" rx="1.5" fill={currentColor} stroke={currentColor} />
      <rect x="115.5" y="13.5" width="21" height="21" rx="1.5" fill={currentColor} stroke={currentColor} />
      <rect x="52" y="16" width="22" height="52" rx="2" transform="rotate(90 52 16)" fill={currentColor} />
    </svg>
  )
}

export function AddFreeLayer() {
  const isCan = useAddAbsoluteLayerState(state => state.isCan)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [imagesOpened, setImagesOpened] = useState(false)
  const { classes } = useMainStyles()

  const [currentIsCan, setCurrentIsCan] = useState<IsCan>('rechtagle')
  return (
    <>
      <ImagesSync
        opened={imagesOpened}
        onClose={() => setImagesOpened(false)}
        imageChoosen={async ({ imageID, imageName }) => {
          setImageAddAbsoluteLayerState({
            imageID,
          })
          setCurrentIsCan('image')
          setImagesOpened(false)
        }}
      />
      <Group
        css={{
          background: `rgba(255,255,255, ${isCan ? '0.3' : '0.1'})`,
          borderRadius: '3px',
          padding: '3px 5px',
          cursor: 'pointer',
          ':hover': {
            background: `rgba(255,255,255, ${isCan ? '0.4' : '0.2'})`,
          },
          '& svg': {
            width: 20,
            height: 'auto',
          },
        }}
        spacing={6}
        onClick={() => {
          if (isCan) {
            turnAddAbsoluteLayerState(undefined)
          } else {
            turnAddAbsoluteLayerState(currentIsCan)
          }
        }}
      >
        {/* <div css={{
        width: 30,
        height: 20,
        border: '1px solid white'
      }}></div> */}

        {/* <RechagleSVG /> */}
        {currentIsCan == 'rechtagle' ? <RechagleSVG /> : null}
        {currentIsCan == 'image' ? <IconPhoto color={'white'} size={14} /> : null}
        {currentIsCan == 'text' ? <IconTextSize color={'white'} size={14} /> : null}

        <Menu
          width={250}
          opened={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          closeOnItemClick={false}
          closeOnClickOutside={false}
          transitionDuration={0}
          shadow="md"
          position="bottom"
        >
          <Menu.Target>
            <div
              css={{
                display: 'block',
              }}
              onClick={event => {
                setMenuIsOpen(!menuIsOpen)
                event.stopPropagation()
              }}
            >
              <svg
                css={{
                  display: 'block',
                  transform: `${menuIsOpen ? 'rotate(180deg)' : ''}`,
                }}
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.18164 7.70711L13.182 18.7075L24.1824 7.70711L23.4753 7L13.182 17.2933L2.88875 7L2.18164 7.70711Z"
                  fill="white"
                />
              </svg>

              {/* <IconArrowNarrowDown
              color={'white'}
              size={14}
            /> */}
            </div>
          </Menu.Target>

          <Menu.Dropdown
            className={classes.hideScrollbar}
            css={{
              maxHeight: 400,
              overflowY: 'auto',
            }}
            onClick={event => {
              event.stopPropagation()
              setMenuIsOpen(false)
            }}
          >
            <Menu.Item
              onClick={() => {
                turnAddAbsoluteLayerState('rechtagle')
                setCurrentIsCan('rechtagle')
              }}
              icon={<RechagleSVG color="blue" />}
            >
              Прямоугольник
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setImagesOpened(true)
              }}
              icon={<IconPhoto color={colors.blue} size={14} />}
            >
              Картинка
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                turnAddAbsoluteLayerState('text')
                setCurrentIsCan('text')
              }}
              icon={<IconTextSize color={colors.blue} size={14} />}
            >
              Текст
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  )
}
