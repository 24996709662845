import { Global } from '@emotion/react'
import { MantineProvider, Text } from '@mantine/core'
import { useRouter } from 'next/router'
import { VinglaLabel } from 'pages/[[...page]]'
import { createContext } from 'react'
import { NestedTableDataSelect } from 'server/selects'
import { getLandingColorVariables } from 'utils/getLandingColorVariables'
import { getPathToFont } from 'utils/getPathToFile'
import { landingTheme } from 'utils/landingTheme'
import { InferQueryOutput, trpc } from 'utils/trpc'

export const PublicSiteContext = createContext<{
  publicSite: NonNullable<NonNullable<InferQueryOutput<'public.getSite'>>['page']>
  tableData?: NestedTableDataSelect
}>(
  // @ts-ignore
  {}
)

export const PublicSiteContextProvider = ({
  children,
  siteID,
}: {
  children: JSX.Element | JSX.Element[] | undefined
  siteID?: string
}) => {
  const router = useRouter()
  const { page } = router.query
  const pagePathName = page ? (Array.isArray(page) ? `${page[0] ? page[0] : ''}` : page) : ''
  const rowIDPathName = page ? (Array.isArray(page) ? `${page[1] ? page[1] : ''}` : '') : ''

  const site = trpc.useQuery(
    [
      'public.getSite',
      {
        siteID: siteID,
        pagePathName,
        rowIDPathName,
      },
    ],
    {
      refetchInterval: siteID ? 500 : undefined,
      notifyOnChangeProps: ['isSuccess', 'data', 'isError'],
      retry: 0,
    }
  )

  if (site.data?.page) {
    const colorVariables = getLandingColorVariables({ colors: site.data.page.siteV1.colors })
    return (
      <MantineProvider theme={landingTheme({ color: site.data.page.siteV1.mainColor?.value })}>
        <Global
          styles={`
  html,
  body {
      ${colorVariables}
    }
    body {
      overflow-x: hidden;
    }
    ${site.data.page.siteV1.fontWeights
      .map(
        fontWeight => `
    @font-face {
      font-family: "${fontWeight.id}";
      src: url('${getPathToFont({ fontName: fontWeight.name, folderName: fontWeight.folder })}');
    }
    
    `
      )
      .join('')}
    `}
        />
        <PublicSiteContext.Provider
          value={{
            publicSite: site.data.page,
            // @ts-ignore
            tableData: site.data.tableData,
          }}
        >
          {children}
        </PublicSiteContext.Provider>
      </MantineProvider>
    )
  }

  if (site.data?.status == 'siteNotFound') return <SiteNotFound />

  return <NotFoundPage adminHidLabel={site.data?.status == 'adminHidLabel'} />
}

const SiteNotFound = () => {
  return (
    <div
      css={{
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div>
        <a
          css={{
            color: 'black',
            textDecoration: 'none',
          }}
          href={'https://vingla.ru'}
        >
          <Text
            align="center"
            css={{
              fontWeight: 'bold',
              fontSize: 84,
              ['@media (max-width: 500px)']: {
                fontSize: 50,
              },
            }}
          >
            VINGLA
          </Text>
        </a>

        <Text
          align="center"
          css={{
            fontWeight: 'bold',
          }}
          size={18}
        >
          Сайт не найден
        </Text>
      </div>
    </div>
  )
}

const NotFoundPage = ({ adminHidLabel }: { adminHidLabel: boolean }) => {
  return (
    <>
      <VinglaLabel adminHidLabel={adminHidLabel} fixed={true} />
      <div
        css={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <div>
          <Text
            align="center"
            css={{
              fontWeight: 'bold',
            }}
            size={124}
          >
            404
          </Text>

          <Text
            align="center"
            css={{
              fontWeight: 'bold',
            }}
            size={18}
          >
            Страница не найдена
          </Text>
        </div>
      </div>
    </>
  )
}
