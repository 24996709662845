import { replaceUrlAndPopupData } from 'blocks/BoxBlock/BoxBlock'
import { Section } from 'components/Section/Section'
import { PublicSiteContext } from 'contexts/PublicSiteContextProvider'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { PageSelect } from 'server/selects'
import { trpc } from 'utils/trpc'

export function Page({ page }: { page: NonNullable<PageSelect> }) {
  const router = useRouter()
  const { giftRecipientID } = router.query

  const { publicSite, tableData } = useContext(PublicSiteContext)

  const checkGiftRecipient = trpc.useMutation('public.checkGiftRecipient')

  async function checkTarget() {
    try {
      if (typeof giftRecipientID == 'string') {
        const target = await checkGiftRecipient.mutateAsync({
          giftRecipientID,
        })

        if (publicSite.siteV1.yandexMetrikaId) {
          console.log('target yandex launched')
          try {
            // @ts-ignore
            window.ym(publicSite.siteV1.yandexMetrikaId, 'reachGoal', target.name)
          } catch (error) {}
        }

        if (publicSite.siteV1.vkontaktePixelId) {
          console.log('target vk launched')
          try {
            // @ts-ignore
            VK.Retargeting.Event(target.name)
          } catch (error) {}
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    checkTarget()
  }, [])

  let title = page.title
  let description = page.description
  if (tableData?.rows[0]?.items) {
    try {
      title = replaceUrlAndPopupData({
        text: title,
        // @ts-ignore
        values: tableData.rows[0].items,
      })
      description = replaceUrlAndPopupData({
        text: description,
        // @ts-ignore
        values: tableData.rows[0].items,
      })
    } catch (error) {}
  }

  console.log(`tableData.pinnedToTableID ${tableData?.pinnedToTableID}`)

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={`${description}`} />
      </Head>
      <div id="top"></div>
      {page.sectionsPinnedCollection.map(sectionPinned => (
        <div
          css={
            sectionPinned.isPinned
              ? {
                  position: 'sticky',
                  top: 0,
                  zIndex: 50,
                }
              : undefined
          }
          key={sectionPinned.id}
        >
          {sectionPinned.sectionsCollection.sectionsChild.map((sectionChild, index) => {
            return (
              <div key={sectionChild.id} id={`${sectionPinned.id}-${sectionChild.id}`}>
                <Section
                  // @ts-ignore
                  tableData={tableData}
                  key={sectionChild.id}
                  {...sectionChild}
                />
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}
