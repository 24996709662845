import { ActionIcon, Button, Grid, Group, Paper, Popover, SimpleGrid, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { showNotification } from '@mantine/notifications'
import { IconTextSize, IconTrash } from '@tabler/icons'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useState } from 'react'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

export function FontsSync({
  fontWeightID,
  onChooseFontWeight,
}: {
  fontWeightID?: string
  onChooseFontWeight: ({}: { fontWeightID?: string }) => void
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const activeFontWeight = useGetSelectDataUndefined({ modelID: fontWeightID, selectID: 'FontWeight1' })

  const uploadToServer = async ({ file, siteID }: { file: File; siteID: string }) => {
    setFontIsLoading(true)

    const body = new FormData()

    body.append('file', file)

    try {
      const request = await fetch('/api/file', {
        method: 'POST',
        body,
        headers: {
          siteid: siteID,
          filetype: 'font',
        },
      })
      if (request.status == 400) {
        const json = await request.json()
        showNotification({
          title: `${json.data}`,
          message: '',
          color: 'red',
        })
      }
    } catch (error) {
    } finally {
      setFontIsLoading(false)
    }
  }

  const [fontIsLoading, setFontIsLoading] = useState(false)

  return (
    <Popover
      position={'left'}
      styles={{
        dropdown: {},
      }}
      withinPortal={true}
      shadow="md"
      width={350}
    >
      <Popover.Target>
        <Grid
          css={{
            alignItems: 'center',
            cursor: 'pointer',
          }}
          columns={10}
          p={8}
        >
          <Grid.Col span={10}>
            <Group
              position="apart"
              css={{
                fontSize: 12,
              }}
              pr={7}
            >
              <div
                css={{
                  color: colors.blue,
                }}
              >
                Шрифт
              </div>
              <div>{activeFontWeight ? activeFontWeight.previewName : 'Inter'}</div>
            </Group>
          </Grid.Col>
        </Grid>
      </Popover.Target>
      <Popover.Dropdown
        css={{
          background: '#fff',
          borderRadius: 2,
        }}
        p={'xs'}
      >
        <SimpleGrid spacing={8} cols={1}>
          <Dropzone
            loading={fontIsLoading}
            onDrop={files => {
              files.map(file => {
                uploadToServer({ file, siteID })
              })
            }}
            padding={0}
            radius={2}
            sx={theme => ({
              cursor: 'pointer',
              '&[data-accept]': {
                color: theme.white,
                backgroundColor: theme.colors.blue[6],
              },

              '&[data-reject]': {
                color: theme.white,
                backgroundColor: theme.colors.red[6],
              },
            })}
          >
            <Text px={4} py={3} size="xs" align="center">
              Загрузить шрифты
            </Text>
          </Dropzone>

          {activeFontWeight ? (
            <Button

              onClick={() => {
                onChooseFontWeight({ fontWeightID: undefined })
              }}
              size={'xs'}
              fullWidth
              variant="light"
            >
              Сбросить к Inter
            </Button>
          ) : null}

          {site.fontWeights.length ? (
            <>
              <SimpleGrid spacing={8} cols={1}>
                <SimpleGrid spacing={8} cols={1}>
                  {site.fontWeights.map(fontWeight => (
                    <GroupFont
                      onChooseFontWeight={() => {
                        onChooseFontWeight({ fontWeightID: fontWeight.id })
                      }}
                      key={fontWeight.id}
                      modelID={fontWeight.id}
                      selectID={fontWeight.selectID}
                    />
                  ))}
                </SimpleGrid>
              </SimpleGrid>
            </>
          ) : null}
        </SimpleGrid>
      </Popover.Dropdown>
    </Popover>
  )
}

function GroupFont({
  modelID,
  selectID,
  onChooseFontWeight,
}: {
  modelID: string
  selectID: 'FontWeight1'
  onChooseFontWeight: ({}: { fontWeightID: string }) => void
}) {
  const fontWeight = useGetSelectData({ modelID, selectID })
  const deleteFont = trpc.useMutation('user.fonts.deleteFontWeight', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateFontWeight = trpc.useMutation('user.fonts.updateFontWeight', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <Paper withBorder p={4}>
      <SimpleGrid cols={1} spacing={4}>
        <Group
          key={fontWeight.id}
          css={{
            // cursor: 'pointer',
            alignItems: 'center',
            alignContent: 'center',
          }}
          position="apart"
        >
          <Group
            css={{
              // cursor: 'pointer',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <ActionIcon
              variant="light"
              color={colors.blue}
              onClick={() => {
                onChooseFontWeight({ fontWeightID: fontWeight.id })
              }}
              radius={2}
            >
              <IconTextSize
                css={{
                  cursor: 'pointer',
                }}
                size={18}
              />
            </ActionIcon>

            <TextInputWithUpdate
              unstyled={true}
              value={fontWeight.previewName}
              placeholder={'Название шрифта'}
              onChange={async value => {
                updateFontWeight.mutate({
                  fontWeightID: fontWeight.id,
                  previewName: value,
                })
              }}
            />
          </Group>
          <Group
            spacing={4}
            css={{
              // cursor: 'pointer',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <ActionIcon
              color={'red'}
              onClick={() => {
                deleteFont.mutate({
                  fontWeightID: fontWeight.id,
                })
              }}
            >
              <IconTrash size={18} />
            </ActionIcon>
          </Group>
        </Group>
      </SimpleGrid>
    </Paper>
  )
}
