import { css } from '@emotion/react'
import { useForm } from '@mantine/form'
import { Color } from '@tiptap/extension-color'
import FontFamily from '@tiptap/extension-font-family'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { synchronFrontendEngineAtSite } from 'generation/synchronFrontendAtSite'
import { useResponsiveGroup } from 'hooks/useResponsiveProperty/useResponsiveGroup'
import React, { useEffect, useRef, useState } from 'react'
import { ChooseSiteColorsSync } from 'syncComponents/ChooseSiteColorsSync/ChooseSiteColorsSync'
import { FontsSync } from 'syncComponents/FontsSync/FontsSync'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { activeResponsiveModeCenter, useCurrentResponsiveMode } from 'utils/activeResponsiveModeCenter'

import {
  ActionIcon,
  Button,
  Divider,
  Grid,
  Group,
  HoverCard,
  Popover,
  SelectItem,
  SimpleGrid,
  TextInput,
  createStyles,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { HorizontalPosition, TextComponentType } from '@prisma/client'
import { Editor } from '@tiptap/react'
import { useResponsiveProperty } from 'hooks/useResponsiveProperty/useResponsiveProperty'

import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconClearFormatting,
  IconColorPicker,
  IconColorSwatch,
  IconGridDots,
  IconHeading,
  IconItalic,
  IconLink,
  IconList,
  IconMinus,
  IconTextSize,
} from '@tabler/icons'
import { EditOneBoxEntity, TitleOfSettings } from 'components/EditOneBoxEntity/EditOneBoxEntity'
import {
  TransofrmTextContentHTMLSync,
  commonTextComponentCSS,
  getTextComponentStylesSync,
  transformHorizontalPositionToTextAlign,
} from 'syncComponents/TransofrmTextContentHTMLSync/TransofrmTextContentHTMLSync'
import { clearHTMLTags } from 'utils/clearHTMLTags'

const iconWidth = 12
const buttonWidth = 25
const colorButtonWidth = 15

const useStyles = createStyles(theme => ({
  button: {
    margin: `0 2px 0 0 `,
    width: buttonWidth,
    minWidth: buttonWidth,
    height: buttonWidth,
    display: 'inline-flex',
    // padding: '2px 6px',
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: `1px solid ${colors.blue}`,
    background: 'transparent',
    lineHeight: `${buttonWidth}px`,
    cursor: 'pointer',
  },
  activeButton: {
    background: `${colors.blue}`,
    color: '#fff',
  },
}))

const EditorCenter = () => {
  let editor: Editor | undefined = undefined
  return {
    get: () => {
      return editor
    },
    set: (newEditor: Editor) => {
      editor = newEditor
    },
  }
}

export function useResponsiveTextGroup({ modelID, selectID }: { modelID: string; selectID: 'TextComponent1' }) {
  const updateTextComponentResponsiveSettings = trpc.useMutation('user.box.updateTextComponentResponsiveSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateTextSettingsResponsiveSettings = trpc.useMutation('user.box.updateTextSettingsResponsiveSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateTextSettings = trpc.useMutation('user.box.updateTextSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateTextComponent = trpc.useMutation('user.box.updateTextComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const textComponent = useGetSelectData({ modelID, selectID })
  const textComponentRef = useRef(textComponent)
  useEffect(() => {
    textComponentRef.current = textComponent
  }, [textComponent])

  const textSettings = useGetSelectData({
    modelID: textComponent.textSettings.id,
    selectID: textComponent.textSettings.selectID,
  })
  const textSettingsRef = useRef(textSettings)
  useEffect(() => {
    textSettingsRef.current = textSettings
  }, [textSettings])

  const responsiveGroup = useResponsiveGroup({
    fontSize: {
      value: {
        desktop: textSettings.fontSizeDesktop,
        tablet: textSettings.fontSizeTablet,
        mobile: textSettings.fontSizeMobile,
      },
      mode: {
        desktop: textSettings.fontSizeDesktopResponsiveMode,
        tablet: textSettings.fontSizeTabletResponsiveMode,
        mobile: textSettings.fontSizeMobileResponsiveMode,
      },
      onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
        await updateTextSettingsResponsiveSettings.mutateAsync({
          textSettingsID: textSettingsRef.current.id,
          type: 'fontSize',
          textSettingsScreenType: responsiveMode,
          screenType: newLayerResponsiveMode,
        })
      },
    },
    lineHeight: {
      value: {
        desktop: textSettings.lineHeightDesktop,
        tablet: textSettings.lineHeightTablet,
        mobile: textSettings.lineHeightMobile,
      },
      mode: {
        desktop: textSettings.lineHeightDesktopResponsiveMode,
        tablet: textSettings.lineHeightTabletResponsiveMode,
        mobile: textSettings.lineHeightMobileResponsiveMode,
      },
      onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
        await updateTextSettingsResponsiveSettings.mutateAsync({
          textSettingsID: textSettingsRef.current.id,
          type: 'lineHeight',
          textSettingsScreenType: responsiveMode,
          screenType: newLayerResponsiveMode,
        })
      },
    },
    align: {
      value: {
        desktop: textComponent.alignDesktop,
        tablet: textComponent.alignTablet,
        mobile: textComponent.alignMobile,
      },

      mode: {
        desktop: textComponent.alignDesktopResponsiveMode,
        tablet: textComponent.alignTabletResponsiveMode,
        mobile: textComponent.alignMobileResponsiveMode,
      },
      onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
        synchronFrontendEngineAtSite.updatePartialData.TextComponent({
          id: textComponentRef.current.id,
          data: {
            alignDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
            alignTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
            alignMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
          },
        })

        await updateTextComponentResponsiveSettings.mutateAsync({
          textComponentID: textComponentRef.current.id,
          type: 'align',
          textComponentScreenType: responsiveMode,
          screenType: newLayerResponsiveMode,
        })
      },
    },
  })

  const beforeSet = {
    lineHeight: () => {
      if (responsiveGroup.lineHeight.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        const value = responsiveGroup.lineHeight.getValue()
        synchronFrontendEngineAtSite.updatePartialData.TextSettings({
          id: textSettingsRef.current.id,
          data: {
            lineHeightDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
            lineHeightTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
            lineHeightMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,

            lineHeightDesktopResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            lineHeightTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            lineHeightMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        updateTextSettings.mutateAsync({
          textSettingsID: textSettingsRef.current.id,
          lineHeightDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
          lineHeightTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
          lineHeightMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
        })

        updateTextSettingsResponsiveSettings.mutateAsync({
          textSettingsID: textSettingsRef.current.id,
          type: 'lineHeight',
          textSettingsScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    fontSize: () => {
      if (responsiveGroup.fontSize.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        const value = responsiveGroup.fontSize.getValue()
        synchronFrontendEngineAtSite.updatePartialData.TextSettings({
          id: textSettingsRef.current.id,
          data: {
            fontSizeDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
            fontSizeTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
            fontSizeMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,

            fontSizeDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            fontSizeTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            fontSizeMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        updateTextSettings.mutateAsync({
          textSettingsID: textSettingsRef.current.id,
          fontSizeDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
          fontSizeTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
          fontSizeMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
        })

        updateTextSettingsResponsiveSettings.mutateAsync({
          textSettingsID: textSettingsRef.current.id,
          type: 'fontSize',
          textSettingsScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    align: () => {
      if (responsiveGroup.align.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        const value = responsiveGroup.align.getValue()
        synchronFrontendEngineAtSite.updatePartialData.TextComponent({
          id: textComponentRef.current.id,
          data: {
            alignDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
            alignTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
            alignMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,

            alignDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            alignTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            alignMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        updateTextComponent.mutateAsync({
          textComponentID: textComponentRef.current.id,

          alignDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
          alignTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
          alignMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
        })

        updateTextComponentResponsiveSettings.mutateAsync({
          textComponentID: textComponentRef.current.id,
          type: 'align',
          textComponentScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
  }

  return {
    responsiveGroup,
    beforeSet,
    textComponent,
    textSettings,
  }
}

const editorCenter = EditorCenter()

export const RichEditorControlsTipTapSync = ({ hideTypes, editor }: { hideTypes?: boolean; editor: Editor | null }) => {
  // const site = useContext(SiteContext)

  const { classes } = useStyles()

  if (!editor) return <></>

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }

  return (
    <div
      onClick={event => {
        event.stopPropagation()
      }}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // flexWrap: 'wrap',
        gap: 12,
      }}
      // css={{
      //   button: {
      //     margin: `0 2px 0 0 `,
      //     width: buttonWidth,
      //     minWidth: buttonWidth,
      //     height: buttonWidth,
      //     display: 'inline-flex',
      //     // padding: '2px 6px',
      //     alignContent: 'center',
      //     justifyContent: 'center',
      //     borderRadius: 4,
      //     border: `1px solid ${colors.blue}`,
      //     background: 'transparent',
      //     lineHeight: `${buttonWidth}px`,
      //     cursor: 'pointer',
      //   },
      //   'button.is-active': {
      //     background: `${colors.blue}`,
      //     color: '#fff',
      //   },
      // }}
    >
      {/* <SimpleGrid mb={8} spacing={2}> */}
      {/* <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      > */}
      {/* <TitleOfSettings title={'Расскрасить'} /> */}
      {/* {site.colors.map(color => (
            <div
              onClick={() => editor.commands.setColor(`var(--color-${color.name})`)}
              css={{
                width: colorButtonWidth,
                height: colorButtonWidth,
                background: `${color.value}`,
                border: '1px solid grey',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
          ))} */}
      {/* <button className={editor.isActive('color') ? 'is-active' : ''}></button> */}
      {/* </div> */}

      {/* <button
        className={editor.isActive('color') ? 'is-active' : ''}
        onClick={() => editor.commands.setColor('var(--main-bg-color)')}
      >
        attribute color 1
      </button> */}
      {/* <br /> */}

      {/* <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TitleOfSettings title={'Выделить'} /> */}
      {/* {site.colors.map(color => (
            <div
              onClick={() => editor.commands.setHighlight({ color: `var(--color-${color.name})` })}
              css={{
                width: colorButtonWidth,
                height: colorButtonWidth,
                background: `${color.value}`,
                border: '1px solid grey',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
          ))} */}
      {/* <Feather.Slash onClick={() => editor.commands.unsetHighlight()} width={colorButtonWidth} /> */}
      {/* <button className={editor.isActive('highlight') ? 'is-active' : ''} >
          </button> */}
      {/* </div> */}

      {/* <button
        className={editor.isActive('highlight') ? 'is-active' : ''}
        onClick={() => editor.commands.setHighlight({ color: 'var(--main-bg-color)' })}
      >
        highlight color 2
      </button> */}
      {/* </SimpleGrid> */}
      {/* <SimpleGrid> */}
      {/* <br /> */}
      {hideTypes ? null : (
        <>
          <ActionIcon
            onClick={() => editor.chain().focus().setParagraph().run()}
            color={editor.isActive('paragraph') ? colors.blue : undefined}
            size={20}
          >
            <IconTextSize />
          </ActionIcon>
          {/* <button
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={`${classes.button} ${editor.isActive('paragraph') ? classes.activeButton : ''} `}
            >
              p
            </button> */}
          <ActionIcon
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            color={editor.isActive('heading') ? colors.blue : undefined}
            size={20}
          >
            <IconHeading />
            <sub css={{ fontWeight: 'bold', fontSize: 8 }}>1</sub>
          </ActionIcon>
          <ActionIcon
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            color={editor.isActive('heading') ? colors.blue : undefined}
            size={20}
          >
            <IconHeading />
            <sub css={{ fontWeight: 'bold', fontSize: 8 }}>2</sub>
          </ActionIcon>
          <ActionIcon
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            color={editor.isActive('heading') ? colors.blue : undefined}
            size={20}
          >
            <IconHeading />
            <sub css={{ fontWeight: 'bold', fontSize: 8 }}>3</sub>
          </ActionIcon>
          <ActionIcon
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            color={editor.isActive('heading') ? colors.blue : undefined}
            size={20}
          >
            <IconHeading />
            <sub css={{ fontWeight: 'bold', fontSize: 8 }}>4</sub>
          </ActionIcon>
          {/* <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              className={`${classes.button} ${editor.isActive('heading', { level: 1 }) ? classes.activeButton : ''} `}
            >
              h1
            </button> */}
          {/* <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              className={`${classes.button} ${editor.isActive('heading', { level: 2 }) ? classes.activeButton : ''} `}
            >
              h2
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              className={`${classes.button} ${editor.isActive('heading', { level: 3 }) ? classes.activeButton : ''} `}
            >
              h3
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
              className={`${classes.button} ${editor.isActive('heading', { level: 4 }) ? classes.activeButton : ''} `}
            >
              h4
            </button> */}
          {/* <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
              className={`${classes.button} ${editor.isActive('heading', { level: 5 }) ? classes.activeButton : ''} `}
            >
              h5
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
              className={`${classes.button} ${editor.isActive('heading', { level: 6 }) ? classes.activeButton : ''} `}
            >
              h6
            </button> */}
        </>
      )}
      {/* <button className={classes.button}>
          <ChooseSiteColorsSync
            onChooseColor={async ({ colorName }) => {
              editor.commands.setHighlight({ color: `var(--color-${colorName})` })
            }}
            onClearColor={() => {
              editor.commands.unsetHighlight()
            }}
          >
            <IconColorSwatch
              css={{
                cursor: 'pointer',
              }}
              color={colors.blue}
              width={iconWidth}
            />
          </ChooseSiteColorsSync>
        </button> */}
      <ChooseSiteColorsSync
        onChooseColor={async ({ colorName }) => {
          editor.commands.setHighlight({ color: `var(--color-${colorName})` })
        }}
        onClearColor={() => {
          editor.commands.unsetHighlight()
        }}
      >
        <ActionIcon size={20}>
          <IconColorSwatch
            css={{
              cursor: 'pointer',
            }}
            color={colors.blue}
          />
        </ActionIcon>
      </ChooseSiteColorsSync>
      {/* <button className={classes.button}>
          <IconColorSwatchOff onClick={() => editor.commands.unsetHighlight()} color={colors.blue} width={iconWidth} />
        </button> */}
      {/* <button className={classes.button}>
          <ChooseSiteColorsSync
            onChooseColor={async ({ colorName }) => {
              editor.commands.setColor(`var(--color-${colorName})`)
            }}
            onClearColor={() => {
              editor.commands.unsetColor()
            }}
          >
            <IconColorPicker
              css={{
                cursor: 'pointer',
              }}
              color={colors.blue}
              width={iconWidth}
            />
          </ChooseSiteColorsSync>
        </button> */}

      <ChooseSiteColorsSync
        onChooseColor={async ({ colorName }) => {
          // console.log(editor.commands.setFontFamily('Roboto'))

          editor.commands.setColor(`var(--color-${colorName})`)
          // console.log(editor.isActive('textStyle', { color: 'var(--color-7f4027da-08d7-496d-b279-fd54e10f07e7)' }))
        }}
        onClearColor={() => {
          editor.commands.unsetColor()
        }}
      >
        <ActionIcon size={20}>
          <IconColorPicker
            css={{
              cursor: 'pointer',
            }}
            color={colors.blue}
          />
        </ActionIcon>
      </ChooseSiteColorsSync>
      {/* <button className={classes.button}>
          <IconColorPickerOff onClick={() => editor.commands.unsetColor()} color={colors.blue} width={iconWidth} />
        </button> */}
      <ActionIcon
        onClick={() => editor.chain().focus().toggleBold().run()}
        color={editor.isActive('bold') ? colors.blue : undefined}
        size={20}
      >
        <IconBold />
      </ActionIcon>
      {/* <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`${classes.button} ${editor.isActive('bold') ? classes.activeButton : ''} `}
        >
          <Feather.Bold color={editor.isActive('bold') ? 'white' : colors.blue} width={iconWidth} />
        </button> */}
      <ActionIcon
        onClick={() => editor.chain().focus().toggleItalic().run()}
        color={editor.isActive('italic') ? colors.blue : undefined}
        size={20}
      >
        <IconItalic />
      </ActionIcon>
      {/* <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`${classes.button} ${editor.isActive('italic') ? classes.activeButton : ''} `}
        >
          <Feather.Italic color={editor.isActive('italic') ? 'white' : colors.blue} width={iconWidth} />
        </button> */}
      <ActionIcon
        onClick={() => editor.chain().focus().toggleStrike().run()}
        color={editor.isActive('strike') ? colors.blue : undefined}
        size={20}
      >
        <IconMinus />
      </ActionIcon>
      {/* <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`${classes.button} ${editor.isActive('strike') ? classes.activeButton : ''} `}
        >
          <Feather.Minus color={editor.isActive('strike') ? 'white' : colors.blue} width={iconWidth} />
        </button> */}
      {/* <button onClick={() => editor.chain().focus().toggleCode().run()} className={editor.isActive('code') ? 'is-active' : ''}>
        code
      </button> */}
      {/* <br /> */}
      {/* <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Feather.List width={iconWidth} /> цифры
      </button> */}
      {/* <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        code block
      </button> */}
      {/* <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <Feather.Square width={iconWidth} />
        </button> */}
      {/* <br /> */}
      {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          <Feather.Slash width={iconWidth} />
        </button>

        <button onClick={() => editor.chain().focus().clearNodes().run()}>
          <Feather.Slash width={iconWidth} />
        </button> */}

      <ActionIcon
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        color={editor.isActive('bulletList') ? colors.blue : undefined}
        size={20}
      >
        <IconList />
      </ActionIcon>
      <ActionIcon
        onClick={() => {
          editor.chain().focus().clearNodes().run()
          editor.chain().focus().unsetAllMarks().run()
        }}
        size={20}
      >
        <IconClearFormatting />
      </ActionIcon>
      <ActionIcon
        onClick={() => {
          if (editor.isActive('link')) {
            editor.chain().focus().unsetLink().run()
          } else {
            setLink()
          }
        }}
        size={20}
        color={editor.isActive('link') ? colors.blue : undefined}
      >
        <IconLink />
      </ActionIcon>

      {/* <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`${classes.button} ${editor.isActive('bulletList') ? classes.activeButton : ''} `}
        >
          <Feather.List color={editor.isActive('bulletList') ? 'white' : colors.blue} width={iconWidth} />
        </button>
        <button
          onClick={() => {
            editor.chain().focus().clearNodes().run()
            editor.chain().focus().unsetAllMarks().run()
          }}
          className={`${classes.button}`}
        >
          <Feather.Slash color={colors.blue} width={iconWidth} />
        </button>

        <button
          onClick={() => {
            if (editor.isActive('link')) {
              editor.chain().focus().unsetLink().run()
            } else {
              setLink()
            }
          }}
          className={`${classes.button} ${editor.isActive('link') ? classes.activeButton : ''} `}
        >
          <Feather.Link color={editor.isActive('link') ? 'white' : colors.blue} width={iconWidth} />
        </button> */}

      {/* <button
          css={{
            position: 'relative',
            '&:after': {
              content: `""`,
              position: 'absolute',
              width: '70%',
              height: '1px',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              transformOrigin: 'center center',
              background: 'black',
            },
          }}
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive('link')}
        >
          <Feather.Link color={editor.isActive('link') ? 'white' : colors.blue} width={iconWidth} />
        </button> */}
      {/* <button onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</button> */}
      {/* <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</button>
      <button onClick={() => editor.chain().focus().undo().run()}>undo</button>
      <button onClick={() => editor.chain().focus().redo().run()}>redo</button> */}

      {/* </SimpleGrid> */}
    </div>
  )
}

export const EditorTextComponentSyncMemo = React.memo(EditorTextComponentSync, (prev, next) => {
  return prev.textComponentData.id == next.textComponentData.id && prev.active == next.active && prev.canEdit == next.canEdit
})

export function EditorTextComponentSync({
  textComponentData,
  active,
  canEdit,
}: {
  textComponentData: { id: string; selectID: 'TextComponent1' }
  active: boolean
  canEdit: boolean
}) {
  const textComponent = useGetSelectData({ modelID: textComponentData.id, selectID: textComponentData.selectID })
  const textSettings = useGetSelectData({
    modelID: textComponent.textSettings.id,
    selectID: textComponent.textSettings.selectID,
  })

  const responsiveMode = useCurrentResponsiveMode()

  const responsiveModeRef = useRef(responsiveMode)
  useEffect(() => {
    responsiveModeRef.current = responsiveMode
  }, [responsiveMode])

  const generateText = trpc.useMutation('user.box.generateText', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateTextComponent = trpc.useMutation('user.box.updateTextComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const editor = useEditor({
    onUpdate: event => {
      const html = event.editor?.getHTML()
      updateTextComponent.mutate({ textComponentID: textComponent.id, text: html })
    },
    extensions: [
      StarterKit,
      Text,
      TextStyle,
      FontFamily.configure({
        types: ['textStyle'],
      }),
      Color.configure({
        types: ['textStyle'],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Link.configure({
        HTMLAttributes: {
          class: 'tip-tap-link',
        },
      }),
    ],
    content: textComponent.text ? `${textComponent.text}` : '<p></p>',
    parseOptions: {
      preserveWhitespace: 'full',
    },
    injectCSS: false,
  })

  // console.log(editor?.getAttributes('textStyle').fontFamily)

  useEffect(() => {
    if (editor) {
      const html = editor.getHTML()
      if (typeof html == 'string' && html != textComponent.text && !editor.isFocused) {
        editor.commands.setContent(textComponent.text)
      }
    }
  }, [textComponent.text])

  const shellRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const callbackKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation()
    }
    shellRef.current?.addEventListener('keydown', callbackKeyDown)
    return () => {
      shellRef.current?.removeEventListener('keydown', callbackKeyDown)
    }
  }, [])

  const html = clearHTMLTags({
    html: `${editor?.getHTML()}`,
  })

  const [opened, setOpened] = useState(false)

  const [genering, setGenering] = useState(false)

  const placeholderText = 'Введите текст'

  return (
    <div
      style={{
        width: '100%',
        // height: '100%',
        position: 'relative',
      }}
      onContextMenu={event => event.stopPropagation()}
      ref={shellRef}
      css={{}}
      onClick={() => {
        setOpened(true)
      }}
    >
      {html == '' || html == '<p></p>' ? (
        <div
          style={{
            pointerEvents: 'none',
            color: '#ddd',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          css={getTextComponentStylesSync({
            textComponent,
            textSettings,
            responsiveMode,
          })}
        >
          <p>{placeholderText}</p>
        </div>
      ) : null}
      {canEdit ? (
        <div
          // start duplicate
          onKeyDown={event => {
            event.stopPropagation()
          }}
          onKeyUp={event => {
            event.stopPropagation()
          }}
          onMouseDown={event => {
            event.stopPropagation()
          }}
          onDoubleClick={event => {
            event.stopPropagation()
          }}
          onMouseUp={event => {
            event.stopPropagation()
          }}
          onMouseEnter={event => {
            event.stopPropagation()
          }}
          onMouseOver={event => {
            event.stopPropagation()
          }}
          onMouseMove={event => {
            event.stopPropagation()
          }}
          onMouseLeave={event => {
            event.stopPropagation()
          }}
          onMouseDownCapture={event => {
            event.stopPropagation()
          }}
          onMouseOut={event => {
            event.stopPropagation()
          }}
          // end duplicate
          css={{
            userSelect: 'auto',
            position: 'relative',
            '[contenteditable]': {
              minHeight: '10px',
              minWidth: '5px',
              outline: 'none',
            },
          }}
        >
          {genering ? (
            <div
              css={getTextComponentStylesSync({
                textComponent,
                textSettings,
                responsiveMode,
              })}
            >
              <p>Идет генерация</p>
            </div>
          ) : (
            <>
              <EditorContent
                placeholder="Введите текст"
                css={getTextComponentStylesSync({
                  textComponent,
                  textSettings,
                  responsiveMode,
                })}
                onKeyUp={async event => {
                  if (event.key == 'Enter' && editor?.getHTML().startsWith('<p>/')) {
                    setGenering(true)
                    try {
                      await generateText.mutateAsync({
                        textComponentID: textComponent.id,
                        request: `${editor?.getHTML().substring(1)}`,
                      })
                    } catch (error) {
                      setGenering(false)
                    }
                    setGenering(false)
                  }
                }}
                style={{
                  width: '100%',
                  // height: '100%',
                }}
                editor={editor}
              />
            </>
          )}
        </div>
      ) : (
        <TransofrmTextContentHTMLSync
          responsiveMode={responsiveMode}
          textSettings={textSettings}
          textComponent={textComponent}
        />
      )}
      <Popover
        styles={{
          dropdown: {},
        }}
        opened={opened}
        onChange={setOpened}
        withinPortal={true}
        withArrow
        shadow="md"
        arrowSize={20}
        width={'auto'}
      >
        <Popover.Target>
          <div
            css={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          ></div>
        </Popover.Target>
        <Popover.Dropdown
          css={{
            background: '#fff',
            // border: `1px solid ${colors.blue}`,
            borderRadius: 2,
            padding: '14px 25px',
            // width: 400,
          }}
          style={{
            width: 'auto !important',
          }}
        >
          <RichEditorControlsTipTapSync hideTypes={true} editor={editor} />
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}

export function TextSettings({ textComponentData }: { textComponentData: { id: string; selectID: 'TextComponent1' } }) {
  const textComponent = useGetSelectData({ modelID: textComponentData.id, selectID: textComponentData.selectID })
  const textSettings = useGetSelectData({
    modelID: textComponent.textSettings.id,
    selectID: textComponent.textSettings.selectID,
  })

  const responsiveMode = useCurrentResponsiveMode()

  const responsiveModeRef = useRef(responsiveMode)
  useEffect(() => {
    responsiveModeRef.current = responsiveMode
  }, [responsiveMode])

  const updateTextComponent = trpc.useMutation('user.box.updateTextComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateTextSettings = trpc.useMutation('user.box.updateTextSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createTextSettings = trpc.useMutation('user.box.createTextSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateTextComponentResponsiveSettings = trpc.useMutation('user.box.updateTextComponentResponsiveSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateTextSettingsResponsiveSettings = trpc.useMutation('user.box.updateTextSettingsResponsiveSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const textFontSize = useResponsiveProperty({
    resposiveMode: {
      desktop: textSettings.fontSizeDesktopResponsiveMode,
      tablet: textSettings.fontSizeTabletResponsiveMode,
      mobile: textSettings.fontSizeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textSettings.fontSizeDesktop,
      tablet: textSettings.fontSizeTablet,
      mobile: textSettings.fontSizeMobile,
    },
    propertyName: 'FS',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateTextSettings.mutateAsync({
        textSettingsID: textSettings.id,
        fontSizeDesktop: responsiveMode == 'Desktop' ? value : undefined,
        fontSizeTablet: responsiveMode == 'Tablet' ? value : undefined,
        fontSizeMobile: responsiveMode == 'Mobile' ? value : undefined,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateTextSettingsResponsiveSettings.mutateAsync({
        textSettingsID: textSettings.id,
        type: 'fontSize',
        textSettingsScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const lineHeight = useResponsiveProperty({
    resposiveMode: {
      desktop: textSettings.lineHeightDesktopResponsiveMode,
      tablet: textSettings.lineHeightTabletResponsiveMode,
      mobile: textSettings.lineHeightMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textSettings.lineHeightDesktop,
      tablet: textSettings.lineHeightTablet,
      mobile: textSettings.lineHeightMobile,
    },
    propertyName: 'Line Height',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateTextSettings.mutateAsync({
        textSettingsID: textSettings.id,
        lineHeightDesktop: responsiveMode == 'Desktop' ? value : undefined,
        lineHeightTablet: responsiveMode == 'Tablet' ? value : undefined,
        lineHeightMobile: responsiveMode == 'Mobile' ? value : undefined,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateTextSettingsResponsiveSettings.mutateAsync({
        textSettingsID: textSettings.id,
        type: 'lineHeight',
        textSettingsScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const fontWeight = useResponsiveProperty({
    resposiveMode: {
      desktop: textSettings.fontWeightDesktopResponsiveMode,
      tablet: textSettings.fontWeightTabletResponsiveMode,
      mobile: textSettings.fontWeightMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textSettings.fontWeightDesktop?.id,
      tablet: textSettings.fontWeightTablet?.id,
      mobile: textSettings.fontWeightMobile?.id,
    },
    propertyName: 'Font weight',

    onValueUpdated: async ({ responsiveMode, value }) => {
      // await updateTextSettings.mutateAsync({
      //   textSettingsID: textSettings.id,
      //   fontWeightDesktopID: responsiveMode == 'Desktop' ? (value ? value : null) : undefined,
      //   fontWeightTabletID: responsiveMode == 'Tablet' ? (value ? value : null) : undefined,
      //   fontWeightMobileID: responsiveMode == 'Mobile' ? (value ? value : null) : undefined,
      // })
      await updateTextSettings.mutateAsync({
        textSettingsID: textSettings.id,
        fontWeightDesktopID: value ? value : null,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      // await updateTextSettingsResponsiveSettings.mutateAsync({
      //   textSettingsID: textSettings.id,
      //   type: 'fontWeight',
      //   textSettingsScreenType: boxBlockResponsiveMode,
      //   screenType: responsiveMode,
      // })
    },
  })

  const textAlign = useResponsiveProperty({
    resposiveMode: {
      desktop: textComponent.alignDesktopResponsiveMode,
      tablet: textComponent.alignTabletResponsiveMode,
      mobile: textComponent.alignMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textComponent.alignDesktop,
      tablet: textComponent.alignTablet,
      mobile: textComponent.alignMobile,
    },
    propertyName: 'Align',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateTextComponent.mutateAsync({
        textComponentID: textComponent.id,
        alignDesktop: responsiveMode == 'Desktop' ? value : undefined,
        alignTablet: responsiveMode == 'Tablet' ? value : undefined,
        alignMobile: responsiveMode == 'Mobile' ? value : undefined,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateTextComponentResponsiveSettings.mutateAsync({
        textComponentID: textComponent.id,
        type: 'align',
        textComponentScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const fontSizes: SelectItem[] = [{ value: '', label: 'По умолчанию' }]

  for (let i = 0; i < 40; i++) {
    let fontSize = 10 + i * 2
    fontSizes.push({ value: `${fontSize}`, label: `${fontSize}px` })
  }

  const lineHeights: SelectItem[] = [{ value: '', label: 'По умолчанию' }]

  for (let i = 0; i < 40; i++) {
    let lineHeight = (0 + i * 0.1).toFixed(1)
    lineHeights.push({ value: `${lineHeight}`, label: `${lineHeight}` })
  }

  const types: TextComponentType[] = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Paragraph']
  const aligns: HorizontalPosition[] = ['Left', 'Center', 'Right']

  const createTextSettingsForm = useForm({
    initialValues: {
      name: '',
    },
  })

  return (
    <div
      onClick={event => {
        event.stopPropagation()
        // event.isPropagationStopped()
        // event.preventDefault()
      }}
    >
      <SimpleGrid spacing={8} cols={1}>
        <Grid columns={10}>
          <Grid.Col span={4}>
            <TitleOfSettings title={'Выравнивание'} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Group spacing={2} position="right">
              {aligns.map(align => (
                <div
                  css={{
                    border: `1px solid ${align == textAlign.value ? colors.blue : '#A9A9A9'}`,
                    width: 20,
                    height: 20,
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  key={align}
                  onClick={() => textAlign.setValue(align)}
                >
                  {align == 'Left' ? (
                    <IconAlignLeft
                      css={{ display: 'inline-block', cursor: 'pointer' }}
                      width={'90%'}
                      height={'90%'}
                      color={align == textAlign.value ? colors.blue : '#A9A9A9'}
                    />
                  ) : null}
                  {align == 'Center' ? (
                    <IconAlignCenter
                      css={{ display: 'inline-block', cursor: 'pointer' }}
                      width={'90%'}
                      height={'90%'}
                      color={align == textAlign.value ? colors.blue : '#A9A9A9'}
                    />
                  ) : null}
                  {align == 'Right' ? (
                    <IconAlignRight
                      css={{ display: 'inline-block', cursor: 'pointer' }}
                      width={'90%'}
                      height={'90%'}
                      color={align == textAlign.value ? colors.blue : '#A9A9A9'}
                    />
                  ) : null}
                </div>
              ))}
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group position="right"> {textAlign.controls()}</Group>
          </Grid.Col>
        </Grid>
        <Group spacing={0} position="apart">
          <TitleOfSettings title={'Тип текста'} />
          <Group spacing={1}>
            {types.map(type => (
              <div
                key={type}
                css={{
                  display: 'inline-block',
                  padding: '1px 2px',
                  marginRight: 2,
                  border: `1px solid ${type == textComponent.type ? colors.blue : '#A9A9A9'}`,
                  color: `${type == textComponent.type ? colors.blue : '#A9A9A9'}`,
                  fontSize: 10,
                  cursor: 'pointer',
                }}
                onClick={() => updateTextComponent.mutate({ textComponentID: textComponent.id, type })}
              >
                {type}
              </div>
            ))}
          </Group>
        </Group>
      </SimpleGrid>
      <Divider color={'#EFEFEF'} ml={8} my={12} />
      <SimpleGrid cols={1} spacing={4}>
        <FontsSync
          fontWeightID={fontWeight.viewValue}
          onChooseFontWeight={({ fontWeightID }) => {
            fontWeight.setValue(fontWeightID)
          }}
        />

        {1 ? null : (
          <Group mb={12} css={{ alignItems: 'center' }} position="apart">
            <HoverCard position="left-end" width={380} shadow="md">
              <HoverCard.Target>
                <Group css={{ alignItems: 'center', width: '100%' }} position="apart">
                  <TitleOfSettings
                    title={`Шрифт: ${textSettings.lib ? `${textSettings.name || 'Без названия'}` : 'кастомный'}`}
                  />

                  <IconGridDots color={colors.blue} size={20} />
                </Group>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SimpleGrid cols={1} spacing={8} mb={8}>
                  {/* {site.textSettings.map(textSettings => {
                  const styles = getTextSettingsStyles({
                    textSettings,
                    responsiveMode: responsiveModeRef.current,
                  }).withResposiveMode
                  return (
                    <>
                      {' '}
                      <Divider color={'#EFEFEF'} />
                      <div
                        css={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                          ...styles,
                        }}
                        onClick={async () => {
                          await updateTextComponent.mutateAsync({
                            textComponentID: textComponent.id,
                            textSettingsID: textSettings.id,
                          })
                        }}
                      >
                        {textSettings.id == textSettings.id ? '+ ' : undefined} {textSettings.name || 'Без названия'}
                      </div>
                    </>
                  )
                })} */}
                </SimpleGrid>

                {textSettings.lib ? (
                  <Button
                    fullWidth
                    size={'sm'}
                    onClick={async () => {
                      await createTextSettings.mutateAsync({
                        textComponentID: textComponent.id,
                      })
                    }}
                  >
                    Открепиться от библиотеки
                  </Button>
                ) : (
                  <form>
                    <SimpleGrid cols={2}>
                      <TextInput size="xs" placeholder={'Название'} {...createTextSettingsForm.getInputProps('name')} />
                      <Button
                        onClick={async () => {
                          await updateTextSettings.mutateAsync({
                            textSettingsID: textSettings.id,
                            lib: true,
                            name: createTextSettingsForm.values.name,
                          })
                        }}
                        loading={updateTextSettings.isLoading}
                        size="xs"
                        type="submit"
                      >
                        создать шрифт
                      </Button>
                    </SimpleGrid>
                  </form>
                )}
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
        )}
        <Grid css={{ alignItems: 'center' }} columns={10}>
          <Grid.Col span={7}>
            <EditOneBoxEntity
              value={textFontSize.viewValue}
              onChange={value => typeof value == 'number' && textFontSize.setValue(value)}
              title={'Размер шрифта'}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Group position="right">{textFontSize.controls()}</Group>
          </Grid.Col>
        </Grid>
        <Grid css={{ alignItems: 'center' }} columns={10}>
          <Grid.Col span={7}>
            <EditOneBoxEntity
              value={lineHeight.viewValue}
              onChange={value => typeof value == 'number' && lineHeight.setValue(value)}
              title={'Высота шрифта'}
              decimal={0.1}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Group position="right">{lineHeight.controls()}</Group>
          </Grid.Col>
        </Grid>
      </SimpleGrid>
    </div>
  )
}

export const EditorTextComponentSyncMemoV2 = React.memo(EditorTextComponentSyncV2, (prev, next) => {
  return (
    prev.textComponentData.id == next.textComponentData.id &&
    prev.active == next.active &&
    prev.canEdit == next.canEdit &&
    prev.value == next.value
  )
})

export function EditorTextComponentSyncV2({
  textComponentData,
  active,
  canEdit,
  value,
}: {
  textComponentData: { id: string; selectID: 'TextComponent1' }
  active: boolean
  canEdit: boolean
  value?: string
}) {
  const { responsiveGroup, beforeSet, textComponent, textSettings } = useResponsiveTextGroup({
    modelID: textComponentData.id,
    selectID: textComponentData.selectID,
  })

  const generateText = trpc.useMutation('user.box.generateText', {
    onSuccess: data => {
      showNotification({
        title: `Списано ${data.costInRubles}`,
        message: '',
        color: 'green',
      })
    },
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateTextComponent = trpc.useMutation('user.box.updateTextComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const editor = useEditor({
    onUpdate: event => {
      const html = event.editor?.getHTML()
      updateTextComponent.mutate({ textComponentID: textComponent.id, text: html })
    },
    extensions: [
      StarterKit,
      Text,
      TextStyle,
      FontFamily.configure({
        types: ['textStyle'],
      }),
      Color.configure({
        types: ['textStyle'],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Link.configure({
        HTMLAttributes: {
          class: 'tip-tap-link',
        },
      }),
    ],
    content: textComponent.text ? `${textComponent.text}` : '<p></p>',
    parseOptions: {
      preserveWhitespace: 'full',
    },
    injectCSS: false,
  })

  // console.log(editor?.getAttributes('textStyle').fontFamily)

  useEffect(() => {
    if (editor) {
      const html = editor.getHTML()
      if (typeof html == 'string' && html != textComponent.text && !editor.isFocused) {
        editor.commands.setContent(textComponent.text)
      }
    }
  }, [textComponent.text])

  const shellRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const callbackKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation()
    }
    shellRef.current?.addEventListener('keydown', callbackKeyDown)
    return () => {
      shellRef.current?.removeEventListener('keydown', callbackKeyDown)
    }
  }, [])

  const html = clearHTMLTags({
    html: `${editor?.getHTML()}`,
  })

  const [opened, setOpened] = useState(false)

  const [genering, setGenering] = useState(false)

  const textComponentCSS = css(
    {
      '& *': {
        textAlign: transformHorizontalPositionToTextAlign(responsiveGroup.align.value),
        lineHeight: `${responsiveGroup.lineHeight.value} !important`,
        fontSize: `${responsiveGroup.fontSize.value}px !important`,
        fontFamily: `${textSettings.fontWeightDesktop ? `"${textSettings.fontWeightDesktop.id}"` : 'inherit'} !important`,
      },
    },
    commonTextComponentCSS
  )

  return (
    <div
      style={{
        width: '100%',
        // height: '100%',
        position: 'relative',
      }}
      onContextMenu={event => event.stopPropagation()}
      ref={shellRef}
      css={{}}
      onClick={() => {
        setOpened(true)
      }}
    >
      {html == '' || html == '<p></p>' ? (
        <div
          style={{
            pointerEvents: 'none',
            color: '#ddd',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          css={textComponentCSS}
        >
          <p>{'Введите текст'}</p>
        </div>
      ) : null}
      {!value && canEdit ? (
        <div
          onKeyUp={event => {
            event.stopPropagation()
          }}
          onKeyDown={event => {
            event.stopPropagation()
          }}
          onMouseDown={event => {
            event.stopPropagation()
          }}
          onDoubleClick={event => {
            event.stopPropagation()
          }}
          onMouseUp={event => {
            event.stopPropagation()
          }}
          onMouseEnter={event => {
            event.stopPropagation()
          }}
          onMouseOver={event => {
            event.stopPropagation()
          }}
          onMouseMove={event => {
            event.stopPropagation()
          }}
          onMouseLeave={event => {
            event.stopPropagation()
          }}
          onMouseDownCapture={event => {
            event.stopPropagation()
          }}
          onMouseOut={event => {
            event.stopPropagation()
          }}
          css={{
            userSelect: 'auto',
            position: 'relative',
            '[contenteditable]': {
              minHeight: '10px',
              minWidth: '5px',
              outline: 'none',
            },
          }}
        >
          {genering ? (
            <div css={textComponentCSS}>
              <p>Идет генерация</p>
            </div>
          ) : (
            <>
              <EditorContent
                placeholder="Введите текст"
                css={textComponentCSS}
                onKeyUp={async event => {
                  if (event.key == 'Enter' && editor?.getHTML().startsWith('<p>/')) {
                    setGenering(true)
                    try {
                      await generateText.mutateAsync({
                        textComponentID: textComponent.id,
                        request: `${editor?.getHTML().substring(1)}`,
                      })
                    } catch (error) {
                      setGenering(false)
                    }
                    setGenering(false)
                  }
                }}
                style={{
                  width: '100%',
                  // height: '100%',
                }}
                editor={editor}
              />
            </>
          )}
        </div>
      ) : (
        <div
          css={{
            ...textComponentCSS,
          }}
        >
          <p
            css={{
              '& > p:after': {
                content: "''",
                display: 'inline-block',
                width: '0',
              },
            }}
            dangerouslySetInnerHTML={{ __html: value ? `${value}` : textComponent.text ? textComponent.text : '' }}
          ></p>
        </div>
      )}
      <Popover
        styles={{
          dropdown: {},
        }}
        opened={opened}
        onChange={setOpened}
        withinPortal={true}
        withArrow
        shadow="md"
        arrowSize={20}
        width={'auto'}
      >
        <Popover.Target>
          <div
            css={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          ></div>
        </Popover.Target>
        <Popover.Dropdown
          css={{
            background: '#fff',
            // border: `1px solid ${colors.blue}`,
            borderRadius: 2,
            padding: '14px 25px',
            // width: 400,
          }}
          style={{
            width: 'auto !important',
          }}
        >
          <RichEditorControlsTipTapSync hideTypes={true} editor={editor} />
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}

export function TextSettingsV2({ textComponentData }: { textComponentData: { id: string; selectID: 'TextComponent1' } }) {
  const { responsiveGroup, beforeSet, textComponent, textSettings } = useResponsiveTextGroup({
    modelID: textComponentData.id,
    selectID: textComponentData.selectID,
  })

  const updateTextComponent = trpc.useMutation('user.box.updateTextComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateTextSettings = trpc.useMutation('user.box.updateTextSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createTextSettings = trpc.useMutation('user.box.createTextSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const fontSizes: SelectItem[] = [{ value: '', label: 'По умолчанию' }]

  for (let i = 0; i < 40; i++) {
    let fontSize = 10 + i * 2
    fontSizes.push({ value: `${fontSize}`, label: `${fontSize}px` })
  }

  const lineHeights: SelectItem[] = [{ value: '', label: 'По умолчанию' }]

  for (let i = 0; i < 40; i++) {
    let lineHeight = (0 + i * 0.1).toFixed(1)
    lineHeights.push({ value: `${lineHeight}`, label: `${lineHeight}` })
  }

  const types: TextComponentType[] = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Paragraph']
  const aligns: HorizontalPosition[] = ['Left', 'Center', 'Right']

  const createTextSettingsForm = useForm({
    initialValues: {
      name: '',
    },
  })

  return (
    <div
      onClick={event => {
        event.stopPropagation()
        // event.isPropagationStopped()
        // event.preventDefault()
      }}
    >
      <SimpleGrid spacing={8} cols={1}>
        <Grid columns={10}>
          <Grid.Col span={4}>
            <TitleOfSettings title={'Выравнивание'} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Group spacing={2} position="right">
              {aligns.map(align => (
                <div
                  css={{
                    border: `1px solid ${align == responsiveGroup.align.value ? colors.blue : '#A9A9A9'}`,
                    width: 20,
                    height: 20,
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  key={align}
                  onClick={() => {
                    beforeSet.align()

                    synchronFrontendEngineAtSite.updatePartialData.TextComponent({
                      id: textComponent.id,
                      data: {
                        alignDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? align : undefined,
                        alignTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? align : undefined,
                        alignMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? align : undefined,
                      },
                    })

                    updateTextComponent.mutateAsync({
                      textComponentID: textComponent.id,

                      alignDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? align : undefined,
                      alignTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? align : undefined,
                      alignMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? align : undefined,
                    })
                  }}
                >
                  {align == 'Left' ? (
                    <IconAlignLeft
                      css={{ display: 'inline-block', cursor: 'pointer' }}
                      width={'90%'}
                      height={'90%'}
                      color={align == responsiveGroup.align.value ? colors.blue : '#A9A9A9'}
                    />
                  ) : null}
                  {align == 'Center' ? (
                    <IconAlignCenter
                      css={{ display: 'inline-block', cursor: 'pointer' }}
                      width={'90%'}
                      height={'90%'}
                      color={align == responsiveGroup.align.value ? colors.blue : '#A9A9A9'}
                    />
                  ) : null}
                  {align == 'Right' ? (
                    <IconAlignRight
                      css={{ display: 'inline-block', cursor: 'pointer' }}
                      width={'90%'}
                      height={'90%'}
                      color={align == responsiveGroup.align.value ? colors.blue : '#A9A9A9'}
                    />
                  ) : null}
                </div>
              ))}
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Group position="right"> {responsiveGroup.align.controls}</Group>
          </Grid.Col>
        </Grid>
        <Group spacing={0} position="apart">
          <TitleOfSettings title={'Тип текста'} />
          <Group spacing={1}>
            {types.map(type => (
              <div
                key={type}
                css={{
                  display: 'inline-block',
                  padding: '1px 2px',
                  marginRight: 2,
                  border: `1px solid ${type == textComponent.type ? colors.blue : '#A9A9A9'}`,
                  color: `${type == textComponent.type ? colors.blue : '#A9A9A9'}`,
                  fontSize: 10,
                  cursor: 'pointer',
                }}
                onClick={() => updateTextComponent.mutate({ textComponentID: textComponent.id, type })}
              >
                {type}
              </div>
            ))}
          </Group>
        </Group>
      </SimpleGrid>
      <Divider color={'#EFEFEF'} ml={8} my={12} />
      <SimpleGrid cols={1} spacing={4}>
        <FontsSync
          fontWeightID={textSettings.fontWeightDesktop?.id}
          onChooseFontWeight={({ fontWeightID }) => {
            updateTextSettings.mutateAsync({
              textSettingsID: textSettings.id,
              fontWeightDesktopID: fontWeightID ? fontWeightID : null,
            })
          }}
        />

        {1 ? null : (
          <Group mb={12} css={{ alignItems: 'center' }} position="apart">
            <HoverCard position="left-end" width={380} shadow="md">
              <HoverCard.Target>
                <Group css={{ alignItems: 'center', width: '100%' }} position="apart">
                  <TitleOfSettings
                    title={`Шрифт: ${textSettings.lib ? `${textSettings.name || 'Без названия'}` : 'кастомный'}`}
                  />

                  <IconGridDots color={colors.blue} size={20} />
                </Group>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SimpleGrid cols={1} spacing={8} mb={8}>
                  {/* {site.textSettings.map(textSettings => {
                  const styles = getTextSettingsStyles({
                    textSettings,
                    responsiveMode: responsiveModeRef.current,
                  }).withResposiveMode
                  return (
                    <>
                      {' '}
                      <Divider color={'#EFEFEF'} />
                      <div
                        css={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                          ...styles,
                        }}
                        onClick={async () => {
                          await updateTextComponent.mutateAsync({
                            textComponentID: textComponent.id,
                            textSettingsID: textSettings.id,
                          })
                        }}
                      >
                        {textSettings.id == textSettings.id ? '+ ' : undefined} {textSettings.name || 'Без названия'}
                      </div>
                    </>
                  )
                })} */}
                </SimpleGrid>

                {textSettings.lib ? (
                  <Button
                    fullWidth
                    size={'sm'}
                    onClick={async () => {
                      await createTextSettings.mutateAsync({
                        textComponentID: textComponent.id,
                      })
                    }}
                  >
                    Открепиться от библиотеки
                  </Button>
                ) : (
                  <form>
                    <SimpleGrid cols={2}>
                      <TextInput size="xs" placeholder={'Название'} {...createTextSettingsForm.getInputProps('name')} />
                      <Button
                        onClick={async () => {
                          await updateTextSettings.mutateAsync({
                            textSettingsID: textSettings.id,
                            lib: true,
                            name: createTextSettingsForm.values.name,
                          })
                        }}
                        loading={updateTextSettings.isLoading}
                        size="xs"
                        type="submit"
                      >
                        создать шрифт
                      </Button>
                    </SimpleGrid>
                  </form>
                )}
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
        )}
        <Grid css={{ alignItems: 'center' }} columns={10}>
          <Grid.Col span={7}>
            <EditOneBoxEntity
              onStart={() => {
                beforeSet.fontSize()
              }}
              value={responsiveGroup.fontSize.value}
              onTemporaryChange={value => {
                if (typeof value == 'number') {
                  synchronFrontendEngineAtSite.updatePartialData.TextSettings({
                    id: textSettings.id,
                    data: {
                      fontSizeDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
                      fontSizeTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
                      fontSizeMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
                    },
                  })
                }
              }}
              onChange={value => {
                if (typeof value == 'number') {
                  updateTextSettings.mutateAsync({
                    textSettingsID: textSettings.id,
                    fontSizeDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
                    fontSizeTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
                    fontSizeMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
                  })
                  synchronFrontendEngineAtSite.updatePartialData.TextSettings({
                    id: textSettings.id,
                    data: {
                      fontSizeDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
                      fontSizeTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
                      fontSizeMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
                    },
                  })
                }
              }}
              title={'Размер шрифта'}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Group position="right">{responsiveGroup.fontSize.controls}</Group>
          </Grid.Col>
        </Grid>
        <Grid css={{ alignItems: 'center' }} columns={10}>
          <Grid.Col span={7}>
            <EditOneBoxEntity
              onStart={() => {
                beforeSet.lineHeight()
              }}
              value={responsiveGroup.lineHeight.value}
              onTemporaryChange={value => {
                if (typeof value == 'number') {
                  synchronFrontendEngineAtSite.updatePartialData.TextSettings({
                    id: textSettings.id,
                    data: {
                      lineHeightDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
                      lineHeightTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
                      lineHeightMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
                    },
                  })
                }
              }}
              onChange={value => {
                if (typeof value == 'number') {
                  updateTextSettings.mutateAsync({
                    textSettingsID: textSettings.id,
                    lineHeightDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
                    lineHeightTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
                    lineHeightMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
                  })
                  synchronFrontendEngineAtSite.updatePartialData.TextSettings({
                    id: textSettings.id,
                    data: {
                      lineHeightDesktop: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? value : undefined,
                      lineHeightTablet: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? value : undefined,
                      lineHeightMobile: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? value : undefined,
                    },
                  })
                }
              }}
              title={'Высота шрифта'}
              decimal={0.1}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Group position="right">{responsiveGroup.lineHeight.controls}</Group>
          </Grid.Col>
        </Grid>
      </SimpleGrid>
    </div>
  )
}
