import BoxBlock from 'blocks/BoxBlock/BoxBlock'
import { useEffect, useRef } from 'react'
import { NestedTableDataSelect, SectionSelect } from 'server/selects'
import { popupActiveCenter } from 'utils/popupActiveCenter'
import {
  addSection,
  getSectionIDAtBottomOfWindow,
  unlistenSection,
  updateSectionBottomIndent,
} from 'utils/usePublicPageSectionsPosition'

export function Section({
  tableData,
  tableID,
  ...section
}: { tableData?: NestedTableDataSelect; tableID?: string } & NonNullable<SectionSelect>) {
  const sectionRef = useRef<HTMLDivElement>(null)

  const callback = () => {
    var viewportOffset = sectionRef.current?.getBoundingClientRect()
    if (viewportOffset) {
      const indentToViewPortWindow = viewportOffset.top

      const windowHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      const heightDiff = indentToViewPortWindow - windowHeight
      // console.log({ heightDiff })
      updateSectionBottomIndent({ sectionID: section.id, bottomIndent: heightDiff })

      const sectionIsAbove = 0 > heightDiff
      if (sectionIsAbove) {
        const sectionIsAboveOfBottomViewPortWindow = sectionIsAbove

        if (sectionIsAboveOfBottomViewPortWindow && section.popupOpen?.id) {
          const { sectionID } = getSectionIDAtBottomOfWindow()
          if (sectionID == section.id) {
            popupActiveCenter.setPopupID({
              popupID: section.popupOpen.id,
              data: null,
              fromScroll: true,
              isCenter: section.popupOpen.horizontalPosition == 'Center' && section.popupOpen.verticalPosition == 'Center',
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    var viewportOffset = sectionRef.current?.getBoundingClientRect()
    if (viewportOffset) {
      const indentToViewPortWindow = viewportOffset.top

      if (indentToViewPortWindow == 0 && section.popupOpen) {
        // console.log({ indentToViewPortWindow })
        popupActiveCenter.setPopupID({
          popupID: section.popupOpen.id,
          data: null,
          fromScroll: true,
          isCenter: section.popupOpen.horizontalPosition == 'Center' && section.popupOpen.verticalPosition == 'Center',
        })
      }
    }
  }, [])

  useEffect(() => {
    const { subscriberID } = addSection({ sectionID: section.id })
    window.addEventListener('scroll', callback, false)
    return () => {
      window.removeEventListener('scroll', callback, false)
      unlistenSection({ subscriberID })
    }
  }, [section])

  return (
    <div ref={sectionRef}>
      {section.boxBlock ? (
        <BoxBlock
          parentTableID={tableID}
          values={tableData?.rows[0]?.items ? tableData.rows[0].items : undefined}
          {...section.boxBlock.box}
        />
      ) : null}
    </div>
  )
}
