import { Accordion, ActionIcon, Button, Group, SimpleGrid } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconHandGrab, IconPlus, IconTrash } from '@tabler/icons'
import { useEffect, useState } from 'react'

import { useGetSelectData } from 'generation/centerFrontedEngine'
import { List, arrayMove } from 'react-movable'

import { useFaqStyles } from 'blocks/FAQBlockV1/FAQBlockV1'
import { EditorTextSync } from 'syncComponents/EditorTextSync/EditorTextSync'
import { trpc } from 'utils/trpc'

import { showNotification } from '@mantine/notifications'

export function FaqComponentSync({ modelID, selectID }: { modelID: string; selectID: 'FaqComponent1' }) {
  const faqComponent = useGetSelectData({ modelID, selectID })
  const createFaqComponentItem = trpc.useMutation('user.box.createFaqComponentItem', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateFaqItemOrder = trpc.useMutation('user.box.updateFaqItemOrder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [faqItems, setFaqItems] = useState(faqComponent.faqItems)
  useEffect(() => {
    setFaqItems(faqComponent.faqItems)
  }, [faqComponent.faqItems])

  const { classes } = useFaqStyles()

  const create = () => {
    createFaqComponentItem.mutate({
      faqComponentID: faqComponent.id,
      order: faqComponent.faqItems.length,
    })
  }

  return (
    <SimpleGrid
      css={{
        width: '100%',
        position: 'relative',
      }}
    >
      {faqComponent.faqItems.length ? (
        <>
          <Group
            css={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              zIndex: 1000,
            }}
            spacing={4}
          >
            <ActionIcon onClick={() => create()} size={'xs'}>
              <IconPlus />
            </ActionIcon>
          </Group>
          <Accordion
            variant="filled"
            classNames={classes}
            className={classes.root}
            radius="lg"
            defaultValue={`${faqComponent.faqItems[0]?.id}`}
            css={{ width: '100%' }}
          >
            <List
              lockVertically
              values={faqItems}
              onChange={({ oldIndex, newIndex }) => {
                const newOrder = arrayMove(faqComponent.faqItems, oldIndex, newIndex)
                setFaqItems(newOrder)
                updateFaqItemOrder.mutate({
                  faqComponentID: faqComponent.id,
                  order: newOrder.map((input, index) => ({ id: input.id, order: index })),
                })
              }}
              renderList={({ children, props }) => <div {...props}>{children}</div>}
              renderItem={({ value, props }) => (
                <div
                  css={{
                    zIndex: 10000,
                    position: 'relative',
                  }}
                  {...props}
                  key={value.id}
                >
                  <FaqComponentItemSync key={value.id} modelID={value.id} selectID={value.selectID} />
                </div>
              )}
            />
          </Accordion>
        </>
      ) : (
        <Button compact loading={createFaqComponentItem.isLoading} onClick={() => create()}>
          Создать вопрос
        </Button>
      )}
    </SimpleGrid>
  )
}

function FaqComponentItemSync({ modelID, selectID }: { modelID: string; selectID: 'FaqComponentItem1' }) {
  const updateFaqComponentItem = trpc.useMutation('user.box.updateFaqComponentItem', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteFaqComponentItem = trpc.useMutation('user.box.deleteFaqComponentItem', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const faqItem = useGetSelectData({ modelID, selectID })

  const form = useForm({
    initialValues: {
      question: faqItem.question,
      answer: faqItem.answer,
    },
  })

  return (
    <div>
      <Accordion.Item value={faqItem.id}>
        <Accordion.Control
          onKeyDown={event => {
            event.stopPropagation()
          }}
          onKeyUp={event => {
            event.stopPropagation()
          }}
        >
          <Group
            css={{
              position: 'absolute',
              top: 4,
              right: 4,
              zIndex: 1000,
            }}
            spacing={4}
          >
            <ActionIcon size={'xs'} data-movable-handle>
              <IconHandGrab />
            </ActionIcon>
            <ActionIcon
              size={'xs'}
              color={'red'}
              onClick={() =>
                deleteFaqComponentItem.mutate({
                  faqComponentItemID: faqItem.id,
                })
              }
            >
              <IconTrash />
            </ActionIcon>
          </Group>
          <EditorTextSync
            defaultValue={faqItem.question}
            placeholder={'Вопрос'}
            onChange={async value => {
              updateFaqComponentItem.mutate({
                faqComponentItemID: faqItem.id,
                question: value,
              })
            }}
          />
        </Accordion.Control>
        <Accordion.Panel
          onKeyDown={event => {
            event.stopPropagation()
          }}
          onKeyUp={event => {
            event.stopPropagation()
          }}
        >
          <EditorTextSync
            defaultValue={faqItem.answer}
            placeholder={'Ответ'}
            onChange={async value => {
              updateFaqComponentItem.mutate({
                faqComponentItemID: faqItem.id,
                answer: value,
              })
            }}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </div>
  )
}
