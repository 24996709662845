import { DragOffsetPosition, OffsetPosition, ResultSizes } from 'components/DragOffsetPosition/DragOffsetPosition'
import { useEffect, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { ImageComponentSelect } from 'server/selects'

export function EditImage({
  image,
  onUpdateSize,
  onUpdatePosition,
  onChangeShellSize,
}: {
  image: NonNullable<ImageComponentSelect>
  onUpdateSize: ({}: { width: number; height: number }) => void
  onChangeShellSize: ({}: { width: number; height: number }) => void
  onUpdatePosition: ({}: { x: number; y: number }) => void
}) {
  const startXDragPosition = useRef({
    x: 0,
    y: 0,
  })

  const [imageState, setImageState, imageStateRef] = useStateRef(image)
  useEffect(() => {
    setImageState(image)
  }, [image])

  const [imageTemporaryPosition, setImageTemporaryPosition, imageTemporaryPositionRef] = useStateRef<null | {
    x: number
    y: number
  }>(null)

  const [imageTemporarySize, setImageTemporarySize, imageTemporarySizeRef] = useStateRef<null | {
    width: number
    height: number
  }>(null)

  const shellSizeIsDrag = useRef(false)
  const [shellTemporarySize, setShellTemporarySize] = useState<null | { width: number; height: number }>(null)

  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (imageRef.current) {
      const width = imageRef.current.clientWidth
      const height = imageRef.current.clientHeight

      if (imageStateRef.current.height == 0 && imageStateRef.current.width == 0) {
        onUpdateSize({ width, height })
        onChangeShellSize({ width, height })
      }
    }
  }, [])

  const onChangeSize = ({ offsetPosition }: { offsetPosition: OffsetPosition }): { resultSizes: ResultSizes } => {
    const actualImageWidth = imageStateRef.current.width
    const actualImageHeight = imageStateRef.current.height
    const newImageWidth = actualImageWidth + offsetPosition.x * 2
    const widthPercentDifference = (newImageWidth / actualImageWidth) * 100

    const x = newImageWidth
    const y = (actualImageHeight * widthPercentDifference) / 100

    setImageTemporarySize({
      width: x,
      height: y,
    })

    return {
      resultSizes: {
        x,
        y,
      },
    }
  }
  const onEndChangeSize = ({ resultPosition }: { resultPosition: ResultSizes }): void => {
    setImageTemporarySize(null)
    if (typeof resultPosition.x == 'number' && typeof resultPosition.y == 'number') {
      setImageState({
        ...imageStateRef.current,
        width: resultPosition.x,
        height: resultPosition.y,
      })
      onUpdateSize({
        width: resultPosition.x,
        height: resultPosition.y,
      })
    }
  }
  const onChangePosition = ({ offsetPosition }: { offsetPosition: OffsetPosition }): { resultSizes: ResultSizes } => {
    const x = imageStateRef.current.positionX + offsetPosition.x
    const y = imageStateRef.current.positionY + offsetPosition.y

    setImageTemporaryPosition({
      x,
      y,
    })

    return {
      resultSizes: {
        x,
        y,
      },
    }
  }
  const onEndChangePosition = ({ resultPosition }: { resultPosition: ResultSizes }): void => {
    setImageTemporaryPosition(null)
    if (typeof resultPosition.x == 'number' && typeof resultPosition.y == 'number') {
      setImageState({
        ...imageStateRef.current,
        positionX: resultPosition.x,
        positionY: resultPosition.y,
      })
      onUpdatePosition({
        x: resultPosition.x,
        y: resultPosition.y,
      })
    }
  }

  return (
    <div
      css={{
        willChange: 'width, height',
        position: 'relative',
        border: '1px solid #0086ff',
      }}
      style={{
        width: shellTemporarySize ? shellTemporarySize.width : imageState.shellWidth,
        height: shellTemporarySize ? shellTemporarySize.height : imageState.shellHeight,
      }}
    >
      {/* <img
        ref={imageRef}
        src={getPathToFile({ fileName: image.image.name })}
        css={{ opacity: 0, pointerEvents: 'none', position: 'fixed', left: -99999 }}
      /> */}
      <ImageEditorViewLayer
        onChangeSize={onChangeSize}
        onEndChangeSize={onEndChangeSize}
        onChangePosition={onChangePosition}
        onEndChangePosition={onEndChangePosition}
        image={{
          id: image.id,
          image: image.image,
          width: imageTemporarySize ? imageTemporarySize.width : imageState.width,
          height: imageTemporarySize ? imageTemporarySize.height : imageState.height,
          positionX: imageTemporaryPosition ? imageTemporaryPosition.x : imageState.positionX,
          positionY: imageTemporaryPosition ? imageTemporaryPosition.y : imageState.positionY,
          shellWidth: imageState.shellWidth,
          shellHeight: imageState.shellHeight,
          isCover: imageState.isCover,
        }}
        overflow={true}
      />
      <ImageEditorViewLayer
        onChangeSize={onChangeSize}
        onEndChangeSize={onEndChangeSize}
        onChangePosition={onChangePosition}
        onEndChangePosition={onEndChangePosition}
        image={{
          id: image.id,
          image: image.image,
          width: imageTemporarySize ? imageTemporarySize.width : imageState.width,
          height: imageTemporarySize ? imageTemporarySize.height : imageState.height,
          positionX: imageTemporaryPosition ? imageTemporaryPosition.x : imageState.positionX,
          positionY: imageTemporaryPosition ? imageTemporaryPosition.y : imageState.positionY,
          shellWidth: imageState.shellWidth,
          shellHeight: imageState.shellHeight,
          isCover: imageState.isCover,
        }}
      />
      <DragOffsetPosition
        onChangePosition={({ offsetPosition }) => {
          const newShellWidth = imageStateRef.current.shellWidth + offsetPosition.x
          const newShellHeight = imageStateRef.current.shellHeight - offsetPosition.y

          setShellTemporarySize({
            width: newShellWidth,
            height: newShellHeight,
          })

          return {
            resultSizes: {
              x: newShellWidth,
              y: newShellHeight,
            },
          }
        }}
        onEndChangePosition={({ resultPosition }) => {
          setShellTemporarySize(null)
          if (typeof resultPosition.x == 'number' && typeof resultPosition.y == 'number') {
            setImageState({
              ...imageStateRef.current,
              shellWidth: resultPosition.x,
              shellHeight: resultPosition.y,
            })
            onChangeShellSize({
              width: resultPosition.x,
              height: resultPosition.y,
            })
          }
        }}
        style={{
          position: 'absolute',
          zIndex: 1,
          cursor: 'nwse-resize',
          transform: 'translate(50%, -50%)',
          width: '10px',
          height: '10px',
          background: 'white',
          border: '1px solid #0086ff',
          right: 0,
          top: 0,
        }}
        onDrag={({ is }) => {
          shellSizeIsDrag.current = is
        }}
      ></DragOffsetPosition>

      {/* <div
        ref={shellSizeDragRef}
        css={{
          position: 'absolute',
          zIndex: 1,
          cursor: 'nwse-resize',
          transform: 'translate(50%, -50%)',
          width: '10px',
          height: '10px',
          background: 'white',
          border: '1px solid #0086ff',
          right: 0,
          top: 0,
        }}
      /> */}
    </div>
  )
}

function ImageEditorViewLayer({
  image,
  onChangeSize,
  onEndChangeSize,
  onChangePosition,
  onEndChangePosition,
  overflow,
}: {
  image: NonNullable<ImageComponentSelect>
  overflow?: boolean
  onChangeSize: ({}: { offsetPosition: OffsetPosition }) => { resultSizes: ResultSizes }
  onEndChangeSize: ({}: { resultPosition: ResultSizes }) => void
  onChangePosition: ({}: { offsetPosition: OffsetPosition }) => { resultSizes: ResultSizes }
  onEndChangePosition: ({}: { resultPosition: ResultSizes }) => void
}) {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        willChange: 'overflow',
      }}
      style={{
        overflow: overflow ? 'hidden' : undefined,
      }}
    >
      <div
        css={{
          willChange: 'transform',
          position: 'absolute',
          left: '50%',
          top: '50%',
          cursor: 'move',
          border: '1px solid #0086ff',
        }}
        style={{
          transform: `translate(calc(-50% + ${image.positionX}px), calc(-50% + ${image.positionY}px))`,
        }}
      >
        {/* <DragOffsetPosition
          onChangePosition={onChangePosition}
          onEndChangePosition={onEndChangePosition}
          style={{
            opacity: overflow ? 1 : 0.5,
            width: image.width,
            height: image.height,
          }}
        >
          <img
            css={{
              willChange: 'opacity, width, height',
              display: 'block',
            }}
            style={{
              width: '100%',
              height: '100%',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
            src={getPathToFile({ fileName: image.image.name })}
          />
        </DragOffsetPosition> */}
        <DragOffsetPosition
          onChangePosition={onChangeSize}
          onEndChangePosition={onEndChangeSize}
          style={{
            position: 'absolute',
            zIndex: 1,
            cursor: 'nwse-resize',
            width: '10px',
            height: '10px',
            background: 'white',
            border: '1px solid #0086ff',
            transform: 'translate(50%, 50%)',
            right: 0,
            bottom: 0,
          }}
        ></DragOffsetPosition>
        {/* <div
          ref={imageSizeDragRef}
          css={{
            position: 'absolute',
            zIndex: 1,
            cursor: 'nwse-resize',
            width: '10px',
            height: '10px',
            background: 'white',
            border: '1px solid #0086ff',
            transform: 'translate(50%, 50%)',
            right: 0,
            bottom: 0,
          }}
        /> */}
      </div>
    </div>
  )
}
