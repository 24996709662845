import { ActionIcon, Badge, Group, SimpleGrid, Title, Divider } from '@mantine/core'
import { IconTrash } from '@tabler/icons'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { BoxBlockSync } from 'pages/panel/sitesV2/[siteID]/pages/[pageID]'
import { RefObject } from 'react'
import { ChooseBlockCategorySync } from 'syncComponents/ChooseBlockCategorySync/ChooseBlockCategorySync'
import { trpc } from 'utils/trpc'

import { useIntersection } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { cabinetColors, cabinetShadows, cabinetSizes } from 'utils/cabinetTheme'

export const widthBlockPreview = 250
export const heightBlockPreview = 150

export function SiteBlocksSync({
  onChooseBlock,
  parentRef,
}: {
  onChooseBlock?: ({}: { blockID: string }) => void
  parentRef: RefObject<HTMLDivElement>
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  return (
    <SimpleGrid
      css={{
        background: cabinetColors.background,
        border: `1px solid ${cabinetColors.border}`,
        borderRadius: cabinetSizes.radius,
      }}
      p={'md'}
      spacing={'md'}
    >
      <Title order={4}>Блоки этого сайта</Title>

      <Group spacing={'md'}>
        {site.boxBlocks.map(boxBlock => (
          <SiteBlock parentRef={parentRef} onChooseBlock={onChooseBlock} modelID={boxBlock.id} selectID={boxBlock.selectID} />
        ))}
      </Group>
    </SimpleGrid>
  )
}

function SiteBlock({
  modelID,
  selectID,
  onChooseBlock,
  parentRef,
}: {
  modelID: string
  selectID: 'BoxBlock1'
  parentRef: RefObject<HTMLDivElement>
  onChooseBlock?: ({}: { blockID: string }) => void
}) {
  const boxBlock = useGetSelectData({ modelID, selectID })
  if (boxBlock.pinedPopup) return <></>
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const deleteBoxBlock = trpc.useMutation('user.site.deleteBoxBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinOrUnpinCategoryToBlock = trpc.useMutation('user.categoryBlocks.pinOrUnpinCategoryToBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { classes } = useMainStyles()

  const { ref, entry } = useIntersection({
    root: parentRef.current,
    threshold: 0.05,
  })

  return (
    <SimpleGrid
      css={{
        width: widthBlockPreview,
        background: cabinetColors.white,
        border: `1px solid ${cabinetColors.border}`,
        borderRadius: cabinetSizes.radius,
        boxShadow: cabinetShadows.input,
        overflow: 'hidden',
      }}
      spacing={0}
    >
      {/* <Tooltip position="top-start" openDelay={0} withArrow label={'Добавить'}> */}
      <div
        css={{
          borderBottom: `1px solid ${cabinetColors.border}`,
          overflowY: 'auto',
          height: heightBlockPreview,
          cursor: 'pointer',
          background: 'white',
        }}
        className={classes.hideScrollbar}
        onClick={() => {}}
        onContextMenu={() => {}}
        ref={ref}
      >
        <div
          onClick={() => {
            if (onChooseBlock) onChooseBlock({ blockID: modelID })
          }}
          css={{
            cursor: 'pointer',
          }}
        >
          {entry?.isIntersecting ? (
            <div
              css={{
                pointerEvents: 'none',
              }}
            >
              <ReduceComponent
                hideBorder={true}
                component={<BoxBlockSync hideLine={true} key={modelID} modelID={modelID} selectID={selectID} width={1920} />}
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* </Tooltip> */}

      <Group
        onClick={event => {
          event.stopPropagation()
        }}
        position="apart"
        p="xs"
      >
        {/* <ActionIcon
      onClick={() => {
        if (onChooseBlock) onChooseBlock({ blockID: boxBlock.id })
      }}
      color={colors.blue}
      variant="light"
    >
      <IconPlus size={20} />
    </ActionIcon> */}
        <GrayButton
          onClick={() => {
            if (onChooseBlock) onChooseBlock({ blockID: modelID })
          }}
          small={true}
        >
          Добавить
        </GrayButton>

        <ActionIcon
          onClick={() => {
            deleteBoxBlock.mutate({
              boxBlockID: modelID,
            })
          }}
          color={'red'}
          variant="light"
        >
          <IconTrash size={20} />
        </ActionIcon>
      </Group>
      {user.isAdmin ? (
        <>
          <Divider />

          <Group
            onClick={event => {
              event.stopPropagation()
            }}
            position="center"
            spacing={4}
            p="xs"
          >
            <ChooseBlockCategorySync blockID={boxBlock.id} />
            {boxBlock.categories.map(category => (
              <CategoryBlockSync
                onClick={() => {
                  pinOrUnpinCategoryToBlock.mutate({
                    blockID: boxBlock.id,
                    categoryID: category.id,
                  })
                }}
                selectID={category.selectID}
                modelID={category.id}
                key={category.id}
              />
            ))}
          </Group>
        </>
      ) : null}
    </SimpleGrid>
  )
}

const CategoryBlockSync = ({
  selectID,
  modelID,
  onClick,
}: {
  selectID: 'BlockCategory1'
  modelID: string
  onClick: () => void
}) => {
  const category = useGetSelectData({ modelID, selectID })
  return (
    <Badge
      onClick={() => {
        onClick()
      }}
      css={{
        cursor: 'pointer',
      }}
    >
      {category.name}
    </Badge>
  )
}
