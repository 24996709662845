import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { BoxSyncMemoV2 } from 'syncComponents/BoxSync/BoxSyncV2'

export function PopupBoxEditorSync({ popupID, width }: { popupID: string; width: number }) {
  const { siteID } = useSyncSiteContext()
  const popup = useGetSelectData({ modelID: popupID, selectID: 'Popup1' })
  const boxBlock = useGetSelectDataUndefined({ modelID: popup.boxBlock?.id, selectID: popup.boxBlock?.selectID })
  const { classes } = useMainStyles()

  const isVerticalVector =
    popup.horizontalPosition == 'Center' && (popup.verticalPosition == 'Top' || popup.verticalPosition == 'Bottom')
  const isHorizontalVector =
    popup.verticalPosition == 'Center' && (popup.horizontalPosition == 'Left' || popup.horizontalPosition == 'Right')

  const isCenter = popup.verticalPosition == 'Center' && popup.horizontalPosition == 'Center'

  return (
    <>
      {boxBlock ? (
        <BoxSyncMemoV2
          onAddBox={() => {}}
          onDeleteBox={() => {}}
          getParentBoxWidth={() => {
            return width
          }}
          getParentBoxHeight={() => {
            return 0
          }}
          modelID={boxBlock.box.id}
          selectID={boxBlock.box.selectID}
          style={{
            zIndex: 3,

            right: popup.horizontalPosition == 'Right' ? 0 : undefined,
            bottom: popup.verticalPosition == 'Bottom' ? 0 : undefined,

            left: isVerticalVector ? '50%' : popup.horizontalPosition == 'Left' || isCenter ? 0 : undefined,
            top: isHorizontalVector ? '50%' : popup.verticalPosition == 'Top' || isCenter ? 0 : undefined,
            transform: `translate(${isVerticalVector ? `-50%` : 0}, ${isHorizontalVector ? `-50%` : 0})`,

            position: 'absolute',
            maxHeight: isCenter ? '100%' : undefined,
            minHeight: isCenter ? '100%' : undefined,
            width: isCenter ? '100%' : undefined,
            overflowY: isCenter ? 'auto' : undefined,
          }}
        />
      ) : null}
    </>
  )
}
