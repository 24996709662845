import { useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useEffect, useRef, useState } from 'react'
import { setImageAddAbsoluteLayerState, turnAddAbsoluteLayerState, useAddAbsoluteLayerState } from 'utils/addAbsoluteLayerState'
import { getPathToFile } from 'utils/getPathToFile'
import { colors } from 'utils/styles'

type Coordinate = { x: number; y: number }

export function CreateFreeAbsoluteLayer({
  onCreate,
}: {
  onCreate: ({}: { x: number; y: number; width: number; height: number; imageID?: string; text?: string }) => void
}) {
  const isCan = useAddAbsoluteLayerState(state => state.isCan)
  const imageID = useAddAbsoluteLayerState(state => state.imageID)
  const [startCoordinate, setStartCoordinate] = useState<undefined | Coordinate>(undefined)

  const [rechtagleDiffCoordinate, setRechtagleDiffCoordinate] = useState<Coordinate>({
    x: 0,
    y: 0,
  })
  const [rechtaglePositionCoordinate, setRechtaglePositionCoordinate] = useState<Coordinate>({
    x: 0,
    y: 0,
  })

  const shellSizeRef = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(0)

  const [rechtagles, setRechtagles] = useState<{ x: number; y: number; width: number; height: number }[]>([])

  const imageData = useGetSelectDataUndefined({ modelID: imageID, selectID: 'Image1' })

  useEffect(() => {
    if (shellSizeRef.current) {
      setWidth(shellSizeRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (shellSizeRef.current) {
          setWidth(Math.ceil(shellSizeRef.current.offsetWidth))
        }
      })
      resizeObserver.observe(shellSizeRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <div
      ref={shellSizeRef}
      style={{
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        position: 'absolute',
        cursor: 'crosshair',
        zIndex: 10000,
        // background: isCan ? 'pink' : 'green',
        pointerEvents: isCan ? undefined : 'none',
      }}
      onMouseDown={event => {
        setStartCoordinate({
          x: event.nativeEvent.offsetX,
          y: event.nativeEvent.offsetY,
        })
      }}
      onMouseUp={event => {
        if (rechtagleDiffCoordinate.x >= 0) {
          if (rechtagleDiffCoordinate.y >= 0) {
            // console.log('right bottom')

            if (startCoordinate) {
              onCreate({
                x: Math.floor(-(width / 2 - startCoordinate.x)),
                y: Math.floor(startCoordinate.y),
                width: Math.floor(rechtagleDiffCoordinate.x),
                height: Math.floor(rechtagleDiffCoordinate.y),
                imageID,
                text: isCan == 'text' ? '' : undefined,
              })
            }
          } else {
            // console.log('right top')
            if (startCoordinate) {
              onCreate({
                x: Math.floor(-(width / 2 - startCoordinate.x)),
                y: Math.floor(startCoordinate.y + rechtagleDiffCoordinate.y),
                width: Math.floor(rechtagleDiffCoordinate.x),
                height: Math.floor(Math.abs(rechtagleDiffCoordinate.y)),
                imageID,
                text: isCan == 'text' ? '' : undefined,
              })
            }
          }
        } else {
          if (rechtagleDiffCoordinate.y >= 0) {
            // console.log('left bottom')
            if (startCoordinate) {
              onCreate({
                x: Math.floor(-(width / 2 - startCoordinate.x) - Math.abs(rechtagleDiffCoordinate.x)),
                y: Math.floor(startCoordinate.y),
                width: Math.floor(Math.abs(rechtagleDiffCoordinate.x)),
                height: Math.floor(Math.abs(rechtagleDiffCoordinate.y)),
                imageID,
                text: isCan == 'text' ? '' : undefined,
              })
            }
          } else {
            // console.log('left top')
            if (startCoordinate) {
              onCreate({
                x: Math.floor(-(width / 2 - startCoordinate.x) - Math.abs(rechtagleDiffCoordinate.x)),
                y: Math.floor(startCoordinate.y + rechtagleDiffCoordinate.y),
                width: Math.floor(Math.abs(rechtagleDiffCoordinate.x)),
                height: Math.floor(Math.abs(rechtagleDiffCoordinate.y)),
                imageID,
                text: isCan == 'text' ? '' : undefined,
              })
            }
          }
        }

        turnAddAbsoluteLayerState(undefined)
        setImageAddAbsoluteLayerState({ imageID: undefined })

        setStartCoordinate(undefined)
        setRechtagleDiffCoordinate({ x: 0, y: 0 })
      }}
      onMouseMove={event => {
        if (startCoordinate) {
          setRechtagleDiffCoordinate({
            x: event.nativeEvent.offsetX - startCoordinate.x,
            y: event.nativeEvent.offsetY - startCoordinate.y,
          })

          setRechtaglePositionCoordinate({
            x: event.nativeEvent.offsetX,
            y: event.nativeEvent.offsetY,
          })
        }
      }}
    >
      {startCoordinate ? (
        <div
          style={{
            width: 0,
            height: 0,
            position: 'absolute',
            transform: `translate(${-(width / 2 - startCoordinate.x)}px, ${0}px)`,

            left: '50%',
            top: startCoordinate.y,
            pointerEvents: 'none',
          }}
        >
          <div
            style={{
              border: `1px solid ${colors.blue}`,
              width: Math.abs(rechtagleDiffCoordinate.x),
              height: Math.abs(rechtagleDiffCoordinate.y),
              position: 'absolute',

              transform: `translate(${rechtagleDiffCoordinate.x >= 0 ? 0 : rechtagleDiffCoordinate.x}px, ${
                rechtagleDiffCoordinate.y >= 0 ? 0 : rechtagleDiffCoordinate.y
              }px)`,

              left: 0,
              top: 0,
            }}
            css={
              imageData
                ? {
                    backgroundImage: `url(${getPathToFile({ fileName: imageData.name, folderName: imageData.folder })})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }
                : undefined
            }
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                position: 'absolute',
                background: colors.blue,
                pointerEvents: 'none',
                opacity: 0.3,
              }}
            ></div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
