import { ActionIcon, Button, Group, Paper, Select, SimpleGrid, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconHandGrab } from '@tabler/icons'
import EditorText from 'components/EditorText/EditorText'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { List, arrayMove } from 'react-movable'
import { QuizSelect } from 'server/selects'
import { trpc } from 'utils/trpc'

export function SiteQuizzes() {
  const site = useContext(SiteContext)
  const createQuiz = trpc.useMutation('user.createQuiz')

  return (
    <div>
      <Button
        loading={createQuiz.isLoading}
        m={30}
        compact
        onClick={() =>
          createQuiz.mutate({
            siteID: site.id,
          })
        }
      >
        Создать квиз
      </Button>

      <SimpleGrid cols={1}>
        {site.quizzesV1.map(quiz => (
          <Quiz key={quiz.id} {...quiz} />
        ))}
      </SimpleGrid>
    </div>
  )
}

function Quiz(quiz: NonNullable<QuizSelect>) {
  const site = useContext(SiteContext)

  const updateQuiz = trpc.useMutation('user.updateQuiz')
  const deleteQuiz = trpc.useMutation('user.deleteQuiz')
  const createQuizStep = trpc.useMutation('user.createQuizStep')
  const updateQuizStep = trpc.useMutation('user.updateQuizStep')

  const form = useForm({
    initialValues: {
      title: quiz.title,
      description: quiz.description,
      formId: quiz.form ? quiz.form.id : '',
    },
  })

  const quizSteps = [...quiz.steps].sort((a, b) => a.order - b.order)

  return (
    <div>
      <Paper withBorder p="xs">
        <form
          onSubmit={form.onSubmit(async values => {
            await updateQuiz.mutate({
              quizID: quiz.id,
              title: values.title,
              formID: values.formId || undefined,
            })
          })}
        >
          <Select
            mb={'md'}
            onChange={value => {
              return form.setValues({ ...form.values, formId: value ? value : '' })
            }}
            label="Выбрать форму"
            defaultValue={quiz.form?.id}
            placeholder="Без формы"
            searchable
            nothingFound="Не найдена"
            data={[{ value: '', label: 'Без формы' }, ...site.formsV1.map(form => ({ value: form.id, label: form.title }))]}
          />
          <EditorText
            defaultValue={quiz.title}
            title="Заголовок"
            onChange={value => form.setValues({ ...form.values, title: value })}
          />

          <Group position="right" my="md">
            <Button color="red" loading={deleteQuiz.isLoading} onClick={() => deleteQuiz.mutate({ quizID: quiz.id })}>
              Удалить
            </Button>
            <Button
              compact
              loading={createQuizStep.isLoading}
              onClick={() =>
                createQuizStep.mutate({
                  quizID: quiz.id,
                  type: 'checkbox',
                })
              }
            >
              + шаг с чекбоксами
            </Button>
            <Button
              compact
              loading={createQuizStep.isLoading}
              onClick={() =>
                createQuizStep.mutate({
                  quizID: quiz.id,
                  type: 'radio',
                })
              }
            >
              + шаг с радио
            </Button>

            <Button compact loading={updateQuiz.isLoading} type="submit">
              Обновить квиз
            </Button>
          </Group>
        </form>

        <List
          lockVertically
          values={quizSteps}
          onChange={({ oldIndex, newIndex }) => {
            // TODO это пиздец какой костыль массивом проходиться и сохранять порядок
            arrayMove(quizSteps, oldIndex, newIndex).map(async (quizStep, index) => {
              await updateQuizStep.mutate({
                quizStepID: quizStep.id,
                order: index,
              })
            })
          }}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
              }}
              key={value.id}
              {...props}
            >
              <StepQuiz key={value.id} {...value} />
            </div>
          )}
        />
      </Paper>
    </div>
  )
}

function StepQuiz(step: NonNullable<QuizSelect>['steps'][0]) {
  const deleteQuizStep = trpc.useMutation('user.deleteQuizStep')
  return (
    <div
      onKeyDown={event => {
        event.stopPropagation()
      }}
      onKeyUp={event => {
        event.stopPropagation()
      }}
    >
      <Paper mb={8} withBorder p="xs">
        <Group mb="md" position="apart">
          <Text>
            {step.checkboxStepsV1 ? 'Шаг с чекбосами' : null}
            {step.radioStepsV1 ? 'Шаг с радио' : null}
            {step.checkboxStepsV1 && step.radioStepsV1 ? 'Something wrong, tell to programmer' : ''}
          </Text>
          <Group>
            <ActionIcon size={'md'} data-movable-handle>
              <IconHandGrab />
            </ActionIcon>
            <Button
              compact
              color={'red'}
              loading={deleteQuizStep.isLoading}
              onClick={() =>
                deleteQuizStep.mutate({
                  quizStepID: step.id,
                })
              }
            >
              Удалить шаг
            </Button>
          </Group>
        </Group>

        {step.checkboxStepsV1 ? <CheckboxStepQuiz {...step.checkboxStepsV1} /> : null}
        {step.radioStepsV1 ? <RadioStepQuiz {...step.radioStepsV1} /> : null}
      </Paper>
    </div>
  )
}

function RadioStepQuiz(radioStep: NonNullable<NonNullable<QuizSelect>['steps'][0]['radioStepsV1']>) {
  const createQuizStepRadioItem = trpc.useMutation('user.createQuizStepRadioItem')
  const updateQuizStepRadio = trpc.useMutation('user.updateQuizStepRadio')
  const updateQuizStepRadioItem = trpc.useMutation('user.updateQuizStepRadioItem')
  const form = useForm({
    initialValues: {
      title: radioStep.title,
    },
  })

  return (
    <div>
      <form
        onSubmit={form.onSubmit(async values => {
          await updateQuizStepRadio.mutate({
            quizStepRadioID: radioStep.id,
            title: values.title,
          })
        })}
      >
        <EditorText
          defaultValue={radioStep.title}
          title="Заголовок"
          onChange={value => form.setValues({ ...form.values, title: value })}
        />

        <Group position="right" my="md">
          <Button
            compact
            loading={createQuizStepRadioItem.isLoading}
            onClick={() =>
              createQuizStepRadioItem.mutate({
                quizStepRadioID: radioStep.id,
              })
            }
          >
            Добавить вариант выбора
          </Button>
          <Button compact loading={updateQuizStepRadio.isLoading} type="submit">
            Обновить шаг
          </Button>
        </Group>
      </form>

      <Paper withBorder p="xs">
        <List
          lockVertically
          values={radioStep.radioItemsV1}
          onChange={({ oldIndex, newIndex }) => {
            // TODO это пиздец какой костыль массивом проходиться и сохранять порядок
            arrayMove(radioStep.radioItemsV1, oldIndex, newIndex).map(async (radioItem, index) => {
              await updateQuizStepRadioItem.mutate({
                quizStepRadioItemID: radioItem.id,
                order: index,
              })
            })
          }}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
              }}
              key={value.id}
              {...props}
            >
              <RadioItemQuiz key={value.id} {...value} />
            </div>
          )}
        />
      </Paper>
    </div>
  )
}

function RadioItemQuiz(radioItem: NonNullable<NonNullable<QuizSelect>['steps'][0]['radioStepsV1']>['radioItemsV1'][0]) {
  const updateQuizStepRadioItem = trpc.useMutation('user.updateQuizStepRadioItem')
  const deleteQuizStepRadioItem = trpc.useMutation('user.deleteQuizStepRadioItem')
  const form = useForm({
    initialValues: {
      title: radioItem.title,
    },
  })

  return (
    <form
      onSubmit={form.onSubmit(async values => {
        await updateQuizStepRadioItem.mutate({
          quizStepRadioItemID: radioItem.id,
          title: values.title,
        })
      })}
    >
      <TextInput size="xs" required label="Заголовок" placeholder="" {...form.getInputProps('title')} />

      <Group position="right" mt="md">
        <ActionIcon size={'md'} data-movable-handle>
          <IconHandGrab />
        </ActionIcon>
        <Button
          onClick={() => deleteQuizStepRadioItem.mutate({ quizStepRadioItemID: radioItem.id })}
          compact
          loading={deleteQuizStepRadioItem.isLoading}
          color="red"
        >
          Удалить вариант
        </Button>
        <Button compact loading={deleteQuizStepRadioItem.isLoading} type="submit">
          Обновить вариант
        </Button>
      </Group>
    </form>
  )
}

function CheckboxStepQuiz(checkboxStep: NonNullable<NonNullable<QuizSelect>['steps'][0]['checkboxStepsV1']>) {
  const createQuizStepCheckboxItem = trpc.useMutation('user.createQuizStepCheckboxItem')
  const updateQuizStepCheckbox = trpc.useMutation('user.updateQuizStepCheckbox')
  const updateQuizStepCheckboxItem = trpc.useMutation('user.updateQuizStepCheckboxItem')

  const form = useForm({
    initialValues: {
      title: checkboxStep.title,
    },
  })

  return (
    <div>
      <form
        onSubmit={form.onSubmit(async values => {
          await updateQuizStepCheckbox.mutate({
            quizStepCheckboxID: checkboxStep.id,
            title: values.title,
          })
        })}
      >
        <EditorText
          defaultValue={checkboxStep.title}
          title="Заголовок"
          onChange={value => form.setValues({ ...form.values, title: value })}
        />

        <Group position="right" my="md">
          <Button
            compact
            loading={createQuizStepCheckboxItem.isLoading}
            onClick={() =>
              createQuizStepCheckboxItem.mutate({
                quizStepCheckboxID: checkboxStep.id,
              })
            }
          >
            Добавить вариант выбора
          </Button>
          <Button compact loading={updateQuizStepCheckbox.isLoading} type="submit">
            Обновить шаг
          </Button>
        </Group>
      </form>
      <Paper withBorder p="xs">
        <List
          lockVertically
          values={checkboxStep.checkboxItemsV1}
          onChange={({ oldIndex, newIndex }) => {
            // TODO это пиздец какой костыль массивом проходиться и сохранять порядок
            arrayMove(checkboxStep.checkboxItemsV1, oldIndex, newIndex).map(async (checkboxItem, index) => {
              await updateQuizStepCheckboxItem.mutate({
                quizStepCheckboxItemID: checkboxItem.id,
                order: index,
              })
            })
          }}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
              }}
              key={value.id}
              {...props}
            >
              <CheckboxItemQuiz key={value.id} {...value} />
            </div>
          )}
        />
      </Paper>
    </div>
  )
}

function CheckboxItemQuiz(
  checkboxItem: NonNullable<NonNullable<QuizSelect>['steps'][0]['checkboxStepsV1']>['checkboxItemsV1'][0]
) {
  const updateQuizStepCheckboxItem = trpc.useMutation('user.updateQuizStepCheckboxItem')
  const deleteQuizStepCheckboxItem = trpc.useMutation('user.deleteQuizStepCheckboxItem')

  const form = useForm({
    initialValues: {
      title: checkboxItem.title,
    },
  })
  return (
    <form
      onSubmit={form.onSubmit(async values => {
        await updateQuizStepCheckboxItem.mutate({
          quizStepCheckboxItemID: checkboxItem.id,
          title: values.title,
        })
      })}
    >
      <TextInput size="xs" required label="Заголовок" placeholder="" {...form.getInputProps('title')} />

      <Group position="right" mt="md">
        <ActionIcon size={'md'} data-movable-handle>
          <IconHandGrab />
        </ActionIcon>
        <Button
          onClick={() => deleteQuizStepCheckboxItem.mutate({ quizStepCheckboxItemID: checkboxItem.id })}
          compact
          loading={deleteQuizStepCheckboxItem.isLoading}
          color={'red'}
        >
          Удалить вариант
        </Button>
        <Button compact loading={updateQuizStepCheckboxItem.isLoading} type="submit">
          Обновить вариант
        </Button>
      </Group>
    </form>
  )
}
