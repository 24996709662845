import { Interpolation, Theme } from '@emotion/react'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { mobileAndTabletCheck } from 'utils/mobileAndTabletCheck'

export type OffsetPosition = {
  x: number
  y: number
}

export type ResultSizes = {
  x?: number
  y?: number
  xT?: number // x Translate
  yT?: number
}

type OnChangePosition<P> = ({}: {
  offsetPosition: OffsetPosition
  differencePositionX: number
  differencePositionY: number
  startX: number
  startY: number
  startValue?: P
}) => {
  resultSizes: ResultSizes
}
type OnEndChangePosition = ({}: { resultPosition: ResultSizes }) => void
type OnDrag = ({}: { is: true | false }) => void
type OnStart = void

export function DragOffsetPosition<P>({
  style,
  css,
  children,
  onChangePosition,
  onEndChangePosition,
  onDrag,
  onClick,
  onStart,
  limited,
  valueX,
  valueY,
  startValue,
}: {
  style: React.CSSProperties
  css?: Interpolation<Theme>
  children?: ReactNode
  onChangePosition: OnChangePosition<P>
  onEndChangePosition: OnEndChangePosition
  onDrag?: OnDrag
  onStart?: () => OnStart
  onClick?: () => void
  limited?: true
  valueX?: number | null
  valueY?: number | null
  startValue?: P
}) {
  // const { hovered, ref } = useHover()

  return (
    <div
      css={css}
      style={
        style.position
          ? {
              ...style,
              touchAction: 'none',
              userSelect: 'none',
              // @ts-ignore
              // '-webkit-user-select': 'none',
              // '-webkit-touch-callout': 'none',
            }
          : {
              ...style,
              position: 'relative',
              touchAction: 'none',
              userSelect: 'none',
              // @ts-ignore
              // '-webkit-user-select': 'none',
              // '-webkit-touch-callout': 'none',
            }
      }
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      {/* <div
        // ref={ref}
        css={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          userSelect: 'none',
        }}
      > */}
      {/* {hovered ? ( */}
      <DragOffsetCore
        onStart={onStart}
        limited={limited}
        onChangePosition={onChangePosition}
        onEndChangePosition={onEndChangePosition}
        onDrag={onDrag}
        valueX={typeof valueX == 'number' ? valueX : 0}
        valueY={typeof valueY == 'number' ? valueY : 0}
        startValue={startValue}
      />
      {/* ) : null} */}
      {/* </div> */}
      {children}
    </div>
  )
}

function DragOffsetCore<P>({
  onChangePosition,
  onEndChangePosition,
  onDrag,
  limited,
  onStart,
  valueX,
  valueY,
  startValue,
}: {
  onChangePosition: OnChangePosition<P>
  onEndChangePosition: OnEndChangePosition
  onDrag?: OnDrag
  limited?: true
  onStart?: () => OnStart
  valueX: number
  valueY: number
  startValue?: P
}) {
  const startXDragPosition = useRef(0)
  const startYDragPosition = useRef(0)

  const resultXSize = useRef<undefined | number>()
  const resultYSize = useRef<undefined | number>()
  const resultXTSize = useRef<undefined | number>()
  const resultYTSize = useRef<undefined | number>()

  const dragRef = useRef<HTMLDivElement>(null)
  const [isDrag, setIsDrag, isDragRef] = useStateRef(false)

  const [startValueX, setStartValueX, startValueXRef] = useStateRef(valueX)
  const [startValueY, setStartValueY, startValueYRef] = useStateRef(valueY)
  const [startValueState, setStartValue, startValueRef] = useStateRef(startValue)

  useEffect(() => {
    if (!isDrag) setStartValueX(valueX)
  }, [valueX, isDrag])

  useEffect(() => {
    if (!isDrag) setStartValueY(valueY)
  }, [valueY, isDrag])

  useEffect(() => {
    if (!isDrag) setStartValue(startValue)
  }, [startValue, isDrag])

  const x = useRef(0)
  const y = useRef(0)

  const pointerIsLocked = useRef(false)

  const [localLimited] = useState(mobileAndTabletCheck() ? true : limited)

  useEffect(() => {
    const mousedownCallback = (event: MouseEvent) => {
      event.preventDefault()
      startCallback({
        x: event.x,
        y: event.y,
      })
    }
    const touchdownCallback = (event: TouchEvent) => {
      // event.preventDefault()
      // event.stopPropagation()
      const touch = event.touches[0]
      startCallback({
        x: touch.pageX,
        y: touch.pageY,
      })
    }
    const startCallback = async ({ x, y }: { x: number; y: number }) => {
      startXDragPosition.current = x
      startYDragPosition.current = y
      if (onStart) onStart()
      setIsDrag(true)
      if (onDrag) onDrag({ is: true })
    }

    dragRef.current?.addEventListener('mousedown', mousedownCallback)
    dragRef.current?.addEventListener('touchstart', touchdownCallback)
    return () => {
      dragRef.current?.removeEventListener('mousedown', mousedownCallback)
      dragRef.current?.removeEventListener('touchstart', touchdownCallback)
    }
  }, [])

  useEffect(() => {
    if (isDragRef.current) {
      const mousemoveCallback = ({
        xPosition,
        yPosition,
        movementX,
        movementY,
      }: {
        xPosition: number
        yPosition: number
        movementX: number
        movementY: number
      }) => {
        x.current += movementX //event.movementX
        y.current += movementY //event.movementY

        const positionDifferenceX = x.current
        const positionDifferenceY = y.current

        const positionDifferenceXWithoutPointerLock = xPosition - startXDragPosition.current // event.x -
        const positionDifferenceYWithoutPointerLock = yPosition - startYDragPosition.current // event.y -
        if (
          !pointerIsLocked.current
          // &&
          // Math.abs(positionDifferenceXWithoutPointerLock) > 1 &&
          // Math.abs(positionDifferenceYWithoutPointerLock) > 1
        ) {
          pointerIsLocked.current = true
          if (!localLimited) {
            if (dragRef.current) dragRef.current.requestPointerLock()
          }
        }

        if (localLimited) {
          const { resultSizes } = onChangePosition({
            offsetPosition: {
              x: Math.floor(positionDifferenceXWithoutPointerLock),
              y: Math.floor(positionDifferenceYWithoutPointerLock),
            },

            differencePositionX: startValueXRef.current + positionDifferenceXWithoutPointerLock,
            differencePositionY: startValueYRef.current + positionDifferenceYWithoutPointerLock,

            startX: startValueXRef.current,
            startY: startValueYRef.current,

            startValue: startValueRef.current,
          })
          resultXSize.current = resultSizes.x
          resultYSize.current = resultSizes.y
          resultXTSize.current = resultSizes.xT
          resultYTSize.current = resultSizes.yT
        } else {
          const { resultSizes } = onChangePosition({
            offsetPosition: {
              x: positionDifferenceX,
              y: positionDifferenceY,
            },

            differencePositionX: startValueXRef.current + positionDifferenceX,
            differencePositionY: startValueYRef.current + positionDifferenceY,

            startX: startValueXRef.current,
            startY: startValueYRef.current,

            startValue: startValueRef.current,
          })
          resultXSize.current = resultSizes.x
          resultYSize.current = resultSizes.y
          resultXTSize.current = resultSizes.xT
          resultYTSize.current = resultSizes.yT
        }
      }
      const mouseMove = (event: MouseEvent) => {
        mousemoveCallback({ xPosition: event.x, yPosition: event.y, movementX: event.movementX, movementY: event.movementY })
      }
      const touchMove = (event: TouchEvent) => {
        event.stopPropagation()
        const touch = event.touches[0]

        mousemoveCallback({
          xPosition: touch.pageX,
          yPosition: touch.pageY,
          movementX: touch.pageX,
          movementY: touch.pageY,
        })
      }

      const mouseupCallback = () => {
        // if (typeof resultXSize.current == 'undefined' && typeof resultYSize.current == 'undefined') {
        // }

        x.current = 0
        y.current = 0

        onEndChangePosition({
          resultPosition: {
            x: typeof resultXSize.current == 'number' ? Math.floor(resultXSize.current) : undefined,
            y: typeof resultYSize.current == 'number' ? Math.floor(resultYSize.current) : undefined,
            xT: typeof resultXTSize.current == 'number' ? Math.floor(resultXTSize.current) : undefined,
            yT: typeof resultYTSize.current == 'number' ? Math.floor(resultYTSize.current) : undefined,
          },
        })

        setIsDrag(false)
        if (onDrag) {
          onDrag({ is: false })
        }
        pointerIsLocked.current = false
        if (!localLimited) document.exitPointerLock()

        resultXSize.current = undefined
        resultYSize.current = undefined
        resultXTSize.current = undefined
        resultYTSize.current = undefined
      }

      window.addEventListener('mousemove', mouseMove)
      window.addEventListener('mouseup', mouseupCallback)

      window.addEventListener('touchmove', touchMove)
      window.addEventListener('touchend', mouseupCallback)

      return () => {
        window.removeEventListener('mousemove', mouseMove)
        window.removeEventListener('mouseup', mouseupCallback)

        window.removeEventListener('touchmove', touchMove)
        window.removeEventListener('touchend', mouseupCallback)
      }
    }
  }, [isDrag])

  return (
    <>
      <div
        css={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          // userSelect: 'none',
        }}
        ref={dragRef}
      ></div>
    </>
  )
}
