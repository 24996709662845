import { ScreeenType } from '@prisma/client'
import { breakpointsBox } from 'config/mq'
import { useEffect, useState } from 'react'

const ActiveResponsiveModeCenter = () => {
  let activeResponsiveMode: ScreeenType = 'Desktop'
  let activeIsDrag: boolean = false
  let activeWidth = 1920

  let listeners: Array<{
    callback: ({}: { responsiveMode: ScreeenType }) => void
    callbackIsDrag?: ({}: { isDrag: boolean }) => void
    callbackWidth?: ({}: { width: number }) => void
    id: string
  }> = []

  return {
    subscribe: ({
      callback,
      callbackIsDrag,
      callbackWidth,
    }: {
      callback: ({}: { responsiveMode: ScreeenType }) => void
      callbackIsDrag?: ({}: { isDrag: boolean }) => void
      callbackWidth?: ({}: { width: number }) => void
    }) => {
      const id = 'id' + Math.random().toString(16).slice(2)
      listeners.push({ callback, callbackIsDrag, callbackWidth, id })
      return {
        unlisten: () => {
          listeners = listeners.filter(listener => listener.id !== id)
        },
      }
    },
    setResponsiveMode: ({ responsiveMode }: { responsiveMode: ScreeenType }) => {
      if (activeResponsiveMode !== responsiveMode) {
        activeResponsiveMode = responsiveMode
        listeners.map(listener => {
          listener.callback({ responsiveMode })
        })
      }
    },
    getResponsiveMode: () => {
      return activeResponsiveMode
    },
    setIsDrag: ({ isDrag }: { isDrag: boolean }) => {
      if (activeIsDrag != isDrag) {
        activeIsDrag = isDrag
        listeners.map(listener => {
          if (listener.callbackIsDrag) listener.callbackIsDrag({ isDrag })
        })
      }
    },
    getIsDrag: () => {
      return activeIsDrag
    },
    setWidth: ({ width }: { width: number }) => {
      if (activeWidth != width) {
        activeWidth = width
        listeners.map(listener => {
          if (listener.callbackWidth) listener.callbackWidth({ width })
        })

        let newResponsiveMode: ScreeenType

        if (width >= breakpointsBox.desktop) {
          newResponsiveMode = 'Desktop'
        } else if (width >= breakpointsBox.tablet) {
          newResponsiveMode = 'Tablet'
        } else {
          newResponsiveMode = 'Mobile'
        }

        activeResponsiveModeCenter.setResponsiveMode({ responsiveMode: newResponsiveMode })
      }
    },
    getWidth: () => {
      return activeWidth
    },
  }
}

export const activeResponsiveModeCenter = ActiveResponsiveModeCenter()

export function useCurrentResponsiveMode() {
  const [currentResponsiveMode, setHookCurrentResponsiveMode] = useState(activeResponsiveModeCenter.getResponsiveMode())

  useEffect(() => {
    const { unlisten } = activeResponsiveModeCenter.subscribe({
      callback: ({ responsiveMode }) => {
        setHookCurrentResponsiveMode(responsiveMode)
      },
    })
    return () => {
      unlisten()
    }
  }, [])

  return currentResponsiveMode
}
