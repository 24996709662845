import { Button, Group, SimpleGrid, Title } from '@mantine/core'

import { EditBoxBlock } from 'components/EditBoxBlock/EditBoxBlock'

import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { trpc } from 'utils/trpc'

export function SiteBlocks() {
  const site = useContext(SiteContext)

  const createSimpleBlock = trpc.useMutation('user.createSimpleBlock')
  const deleteBoxBlock = trpc.useMutation('user.site.deleteBoxBlock')

  return (
    <>
      <div>
        <SimpleGrid>
          <>
            <Group position="apart" mb={'md'}>
              <Title order={2}>Боксы</Title>
              <Button
                loading={createSimpleBlock.isLoading}
                onClick={() =>
                  createSimpleBlock.mutate({
                    siteID: site.id,
                    type: 'box',
                  })
                }
              >
                Создать боксы
              </Button>
            </Group>
            <SimpleGrid cols={1}>
              {site.boxBlocks.map(boxBlock => (
                <div key={boxBlock.id}>
                  <Button
                    loading={deleteBoxBlock.isLoading}
                    onClick={() => deleteBoxBlock.mutate({ boxBlockID: boxBlock.id })}
                    compact
                  >
                    Удалить блок
                  </Button>
                  <EditBoxBlock key={boxBlock.id} {...boxBlock} />
                </div>
              ))}
            </SimpleGrid>
          </>
        </SimpleGrid>
      </div>
    </>
  )
}
