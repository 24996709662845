import { useForm } from '@mantine/form'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useBoxBlockContext } from 'contexts/BoxBlockContext'
import { useEffect, useRef } from 'react'
import { Portal } from 'react-portal'
import { TextComponentSelect } from 'server/selects'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { Button, Divider, Grid, Group, HoverCard, SelectItem, SimpleGrid, TextInput, createStyles } from '@mantine/core'
import { HorizontalPosition, TextComponentType } from '@prisma/client'
import { Editor } from '@tiptap/react'
import {
  TransofrmTextContentHTML,
  getTextComponentStyles,
  getTextSettingsStyles,
} from 'components/TransofrmTextContentHTML/TransofrmTextContentHTML'
import { useResponsiveProperty } from 'hooks/useResponsiveProperty/useResponsiveProperty'

import { IconAlignCenter, IconAlignLeft, IconAlignRight, IconGridDots } from '@tabler/icons'
import { EditOneBoxEntity, TitleOfSettings } from 'components/EditOneBoxEntity/EditOneBoxEntity'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { Feather } from 'utils/Feather'

const iconWidth = 12
const buttonWidth = 25
const colorButtonWidth = 15

const useStyles = createStyles(theme => ({
  button: {},
}))

export const RichEditorControlsTipTap = ({ editor, hideTypes }: { editor: Editor | null; hideTypes?: boolean }) => {
  const site = useContext(SiteContext)
  const styles = useStyles()

  if (!editor) {
    return null
  }

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }

  return (
    <div
      onClick={event => {
        event.stopPropagation()
      }}
      css={{
        button: {
          margin: `0 2px 0 0 `,
          width: buttonWidth,
          height: buttonWidth,
          display: 'inline-flex',
          // padding: '2px 6px',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          border: '1px solid grey',
          background: 'transparent',
          lineHeight: `${buttonWidth}px`,

          '&:hover': {
            cursor: 'pointer',
          },
        },
        'button.is-active': {
          background: '#333',
          color: '#fff',
        },
      }}
    >
      <SimpleGrid mb={8} spacing={2}>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TitleOfSettings title={'Расскрасить'} />
          {site.colors.map(color => (
            <div
              onClick={() => editor.commands.setColor(`var(--color-${color.name})`)}
              css={{
                width: colorButtonWidth,
                height: colorButtonWidth,
                background: `${color.value}`,
                border: '1px solid grey',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
          ))}
          <Feather.Slash onClick={() => editor.commands.unsetColor()} width={colorButtonWidth} />
          {/* <button className={editor.isActive('color') ? 'is-active' : ''} >
            
          </button> */}
        </div>

        {/* <button
        className={editor.isActive('color') ? 'is-active' : ''}
        onClick={() => editor.commands.setColor('var(--main-bg-color)')}
      >
        attribute color 1
      </button> */}
        {/* <br /> */}

        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TitleOfSettings title={'Выделить'} />
          {site.colors.map(color => (
            <div
              onClick={() => editor.commands.setHighlight({ color: `var(--color-${color.name})` })}
              css={{
                width: colorButtonWidth,
                height: colorButtonWidth,
                background: `${color.value}`,
                border: '1px solid grey',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
          ))}
          <Feather.Slash onClick={() => editor.commands.unsetHighlight()} width={colorButtonWidth} />
          {/* <button className={editor.isActive('highlight') ? 'is-active' : ''} >
          </button> */}
        </div>

        {/* <button
        className={editor.isActive('highlight') ? 'is-active' : ''}
        onClick={() => editor.commands.setHighlight({ color: 'var(--main-bg-color)' })}
      >
        highlight color 2
      </button> */}
      </SimpleGrid>
      <SimpleGrid>
        {/* <br /> */}
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '0 7px',
          }}
        >
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <Feather.Bold width={iconWidth} />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <Feather.Italic width={iconWidth} />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            <Feather.Minus width={iconWidth} />
          </button>
          {/* <button onClick={() => editor.chain().focus().toggleCode().run()} className={editor.isActive('code') ? 'is-active' : ''}>
        code
      </button> */}
          {/* <br /> */}
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
          >
            <Feather.List width={iconWidth} />
          </button>
          {/* <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Feather.List width={iconWidth} /> цифры
      </button> */}
          {/* <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        code block
      </button> */}
          {/* <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <Feather.Square width={iconWidth} />
        </button> */}
          {/* <br /> */}
          {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          <Feather.Slash width={iconWidth} />
        </button>

        <button onClick={() => editor.chain().focus().clearNodes().run()}>
          <Feather.Slash width={iconWidth} />
        </button> */}

          <button
            onClick={() => {
              editor.chain().focus().clearNodes().run()
              editor.chain().focus().unsetAllMarks().run()
            }}
          >
            <Feather.Slash width={iconWidth} />
            {/* clear marks */}
          </button>

          <button onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
            <Feather.Link width={iconWidth} />
          </button>
          <button
            css={{
              position: 'relative',
              '&:after': {
                content: `""`,
                position: 'absolute',
                width: '70%',
                height: '1px',
                transform: 'translate(-50%, -50%)',
                left: '50%',
                top: '50%',
                transformOrigin: 'center center',
                background: 'black',
              },
            }}
            onClick={() => editor.chain().focus().unsetLink().run()}
            disabled={!editor.isActive('link')}
          >
            <Feather.Link width={iconWidth} />
          </button>
          {/* <button onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</button> */}
          {/* <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</button>
      <button onClick={() => editor.chain().focus().undo().run()}>undo</button>
      <button onClick={() => editor.chain().focus().redo().run()}>redo</button> */}
        </div>
      </SimpleGrid>
    </div>
  )
}

export function EditorTextComponent({
  textComponent,
  active,
  canEdit,
}: {
  textComponent: NonNullable<TextComponentSelect>
  active: boolean
  canEdit: boolean
}) {
  const { responsiveMode } = useBoxBlockContext()

  const responsiveModeRef = useRef(responsiveMode)
  useEffect(() => {
    responsiveModeRef.current = responsiveMode
  }, [responsiveMode])

  const site = useContext(SiteContext)

  const updateTextComponent = trpc.useMutation('user.box.updateTextComponent')
  const updateTextSettings = trpc.useMutation('user.box.updateTextSettings')
  const createTextSettings = trpc.useMutation('user.box.createTextSettings')

  const updateTextComponentResponsiveSettings = trpc.useMutation('user.box.updateTextComponentResponsiveSettings')
  const updateTextSettingsResponsiveSettings = trpc.useMutation('user.box.updateTextSettingsResponsiveSettings')

  const editor = useEditor({
    onUpdate: event => {
      console.log('update')
      updateTextComponent.mutate({ textComponentID: textComponent.id, text: event.editor?.getHTML() })
    },
    extensions: [
      StarterKit,
      TextStyle,
      Color.configure({
        types: ['textStyle'],
      }),
      Text,
      Highlight.configure({
        multicolor: true,
      }),
      Link.configure({
        HTMLAttributes: {
          class: 'tip-tap-link',
        },
      }),
    ],
    content: textComponent?.text ? `${textComponent.text}` : '<p></p>',
  })

  const shellRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const callbackKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation()
    }
    shellRef.current?.addEventListener('keydown', callbackKeyDown)
    return () => {
      shellRef.current?.removeEventListener('keydown', callbackKeyDown)
    }
  }, [])

  const textFontSize = useResponsiveProperty({
    resposiveMode: {
      desktop: textComponent.textSettings.fontSizeDesktopResponsiveMode,
      tablet: textComponent.textSettings.fontSizeTabletResponsiveMode,
      mobile: textComponent.textSettings.fontSizeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textComponent.textSettings.fontSizeDesktop,
      tablet: textComponent.textSettings.fontSizeTablet,
      mobile: textComponent.textSettings.fontSizeMobile,
    },
    propertyName: 'FS',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateTextSettings.mutateAsync({
        textSettingsID: textComponent.textSettings.id,
        fontSizeDesktop: responsiveMode == 'Desktop' ? value : undefined,
        fontSizeTablet: responsiveMode == 'Tablet' ? value : undefined,
        fontSizeMobile: responsiveMode == 'Mobile' ? value : undefined,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateTextSettingsResponsiveSettings.mutateAsync({
        textSettingsID: textComponent.textSettings.id,
        type: 'fontSize',
        textSettingsScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const lineHeight = useResponsiveProperty({
    resposiveMode: {
      desktop: textComponent.textSettings.lineHeightDesktopResponsiveMode,
      tablet: textComponent.textSettings.lineHeightTabletResponsiveMode,
      mobile: textComponent.textSettings.lineHeightMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textComponent.textSettings.lineHeightDesktop,
      tablet: textComponent.textSettings.lineHeightTablet,
      mobile: textComponent.textSettings.lineHeightMobile,
    },
    propertyName: 'Line Height',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateTextSettings.mutateAsync({
        textSettingsID: textComponent.textSettings.id,
        lineHeightDesktop: responsiveMode == 'Desktop' ? value : undefined,
        lineHeightTablet: responsiveMode == 'Tablet' ? value : undefined,
        lineHeightMobile: responsiveMode == 'Mobile' ? value : undefined,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateTextSettingsResponsiveSettings.mutateAsync({
        textSettingsID: textComponent.textSettings.id,
        type: 'lineHeight',
        textSettingsScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const textAlign = useResponsiveProperty({
    resposiveMode: {
      desktop: textComponent.alignDesktopResponsiveMode,
      tablet: textComponent.alignTabletResponsiveMode,
      mobile: textComponent.alignMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: textComponent.alignDesktop,
      tablet: textComponent.alignTablet,
      mobile: textComponent.alignMobile,
    },
    propertyName: 'Align',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateTextComponent.mutateAsync({
        textComponentID: textComponent.id,
        alignDesktop: responsiveMode == 'Desktop' ? value : undefined,
        alignTablet: responsiveMode == 'Tablet' ? value : undefined,
        alignMobile: responsiveMode == 'Mobile' ? value : undefined,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateTextComponentResponsiveSettings.mutateAsync({
        textComponentID: textComponent.id,
        type: 'align',
        textComponentScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const fontSizes: SelectItem[] = [{ value: '', label: 'По умолчанию' }]

  for (let i = 0; i < 40; i++) {
    let fontSize = 10 + i * 2
    fontSizes.push({ value: `${fontSize}`, label: `${fontSize}px` })
  }

  const lineHeights: SelectItem[] = [{ value: '', label: 'По умолчанию' }]

  for (let i = 0; i < 40; i++) {
    let lineHeight = (0 + i * 0.1).toFixed(1)
    lineHeights.push({ value: `${lineHeight}`, label: `${lineHeight}` })
  }

  const types: TextComponentType[] = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Paragraph']
  const aligns: HorizontalPosition[] = ['Left', 'Center', 'Right']

  const createTextSettingsForm = useForm({
    initialValues: {
      name: '',
    },
  })

  return (
    <div
      onContextMenu={event => event.stopPropagation()}
      ref={shellRef}
      css={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {canEdit ? (
        <div
          onKeyUp={event => {
            event.stopPropagation()
          }}
          onKeyDown={event => {
            event.stopPropagation()
          }}
          onMouseDown={event => {
            event.stopPropagation()
          }}
          onDoubleClick={event => {
            event.stopPropagation()
          }}
          onMouseUp={event => {
            event.stopPropagation()
          }}
          onMouseEnter={event => {
            event.stopPropagation()
          }}
          onMouseOver={event => {
            event.stopPropagation()
          }}
          onMouseMove={event => {
            event.stopPropagation()
          }}
          onMouseLeave={event => {
            event.stopPropagation()
          }}
          onMouseDownCapture={event => {
            event.stopPropagation()
          }}
          onMouseOut={event => {
            event.stopPropagation()
          }}
          css={{
            width: '100%',
            '[contenteditable]': {
              minHeight: '10px',
              width: '100%',
              outline: 'none',
            },
          }}
        >
          <EditorContent
            placeholder="Введите текст"
            css={getTextComponentStyles({ textComponent, responsiveMode })}
            editor={editor}
          />
        </div>
      ) : (
        <TransofrmTextContentHTML responsiveMode={responsiveMode} textComponent={textComponent} />
      )}

      {active ? (
        <Portal node={document.getElementById('text-settings')}>
          <div
            onClick={event => {
              event.stopPropagation()
              event.isPropagationStopped()
              event.preventDefault()
            }}
          >
            <SimpleGrid spacing={8} cols={1}>
              <RichEditorControlsTipTap hideTypes={true} editor={editor} />
              <Grid columns={10}>
                <Grid.Col span={4}>
                  <TitleOfSettings title={'Выравнивание'} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Group spacing={2} position="right">
                    {aligns.map(align => (
                      <div
                        css={{
                          border: `1px solid ${align == textAlign.value ? colors.blue : '#A9A9A9'}`,
                          width: 20,
                          height: 20,
                          borderRadius: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => textAlign.setValue(align)}
                      >
                        {align == 'Left' ? (
                          <IconAlignLeft
                            css={{ display: 'inline-block' }}
                            width={'90%'}
                            height={'90%'}
                            color={align == textAlign.value ? colors.blue : '#A9A9A9'}
                          />
                        ) : null}
                        {align == 'Center' ? (
                          <IconAlignCenter
                            css={{ display: 'inline-block' }}
                            width={'90%'}
                            height={'90%'}
                            color={align == textAlign.value ? colors.blue : '#A9A9A9'}
                          />
                        ) : null}
                        {align == 'Right' ? (
                          <IconAlignRight
                            css={{ display: 'inline-block' }}
                            width={'90%'}
                            height={'90%'}
                            color={align == textAlign.value ? colors.blue : '#A9A9A9'}
                          />
                        ) : null}
                      </div>
                    ))}
                  </Group>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Group position="right"> {textAlign.controls()}</Group>
                </Grid.Col>
              </Grid>
              <Group spacing={0} position="apart">
                <TitleOfSettings title={'Тип текста'} />
                <Group spacing={1}>
                  {types.map(type => (
                    <div
                      css={{
                        display: 'inline-block',
                        padding: '1px 2px',
                        marginRight: 2,
                        border: `1px solid ${type == textComponent.type ? colors.blue : '#A9A9A9'}`,
                        color: `${type == textComponent.type ? colors.blue : '#A9A9A9'}`,
                        fontSize: 10,
                      }}
                      onClick={() => updateTextComponent.mutate({ textComponentID: textComponent.id, type })}
                    >
                      {type}
                    </div>
                  ))}
                </Group>
              </Group>
            </SimpleGrid>
            <Divider color={'#EFEFEF'} ml={8} my={12} />
            <SimpleGrid cols={1} spacing={4}>
              <Group mb={12} css={{ alignItems: 'center' }} position="apart">
                <HoverCard position="left-end" width={380} shadow="md">
                  <HoverCard.Target>
                    <Group css={{ alignItems: 'center', width: '100%' }} position="apart">
                      <TitleOfSettings
                        title={`Шрифт: ${
                          textComponent.textSettings.lib ? `${textComponent.textSettings.name || 'Без названия'}` : 'кастомный'
                        }`}
                      />

                      <IconGridDots color={colors.blue} size={20} />
                    </Group>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    {/* <IconPlus onClick={async () => {}} color={colors.blue} size={20} /> */}
                    <SimpleGrid cols={1} spacing={8} mb={8}>
                      {site.textSettings.map(textSettings => {
                        const styles = getTextSettingsStyles({
                          textSettings,
                          responsiveMode: responsiveModeRef.current,
                        }).withResposiveMode
                        return (
                          <>
                            {' '}
                            <Divider color={'#EFEFEF'} />
                            <div
                              css={{
                                ':hover': {
                                  cursor: 'pointer',
                                },
                                ...styles,
                              }}
                              onClick={async () => {
                                await updateTextComponent.mutateAsync({
                                  textComponentID: textComponent.id,
                                  textSettingsID: textSettings.id,
                                })
                              }}
                            >
                              {textComponent.textSettings.id == textSettings.id ? '+ ' : undefined}{' '}
                              {textSettings.name || 'Без названия'}
                            </div>
                          </>
                        )
                      })}
                    </SimpleGrid>

                    {textComponent.textSettings.lib ? (
                      <Button
                        fullWidth
                        size={'sm'}
                        onClick={async () => {
                          await createTextSettings.mutateAsync({
                            textComponentID: textComponent.id,
                          })
                        }}
                      >
                        Открепиться от библиотеки
                      </Button>
                    ) : (
                      <form>
                        <SimpleGrid cols={2}>
                          <TextInput size="xs" placeholder={'Название'} {...createTextSettingsForm.getInputProps('name')} />
                          <Button
                            onClick={async () => {
                              await updateTextSettings.mutateAsync({
                                textSettingsID: textComponent.textSettings.id,
                                lib: true,
                                name: createTextSettingsForm.values.name,
                              })
                            }}
                            loading={updateTextSettings.isLoading}
                            size="xs"
                            type="submit"
                          >
                            создать шрифт
                          </Button>
                        </SimpleGrid>
                      </form>
                    )}
                  </HoverCard.Dropdown>
                </HoverCard>
              </Group>
              <Grid css={{ alignItems: 'center' }} columns={10}>
                <Grid.Col span={7}>
                  <EditOneBoxEntity
                    value={textFontSize.viewValue}
                    onChange={value => typeof value == 'number' && textFontSize.setValue(value)}
                    title={'Размер шрифта'}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Group position="right">{textFontSize.controls()}</Group>
                </Grid.Col>
              </Grid>
              <Grid css={{ alignItems: 'center' }} columns={10}>
                <Grid.Col span={7}>
                  <EditOneBoxEntity
                    value={lineHeight.viewValue}
                    onChange={value => typeof value == 'number' && lineHeight.setValue(value)}
                    title={'Высота шрифта'}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Group position="right">{lineHeight.controls()}</Group>
                </Grid.Col>
              </Grid>
            </SimpleGrid>
          </div>
        </Portal>
      ) : null}
    </div>
  )
}
