import { Button, Group, Modal, SimpleGrid } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { memo, useState } from 'react'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { trpc } from 'utils/trpc'

export const CodeComponentSyncMemo = memo(CodeComponentSync, (prev, next) => {
  return prev.modelID == next.modelID && prev.opened == next.opened
})

export function CodeComponentSync({
  modelID,
  selectID,
  opened,
  onClose,
}: {
  modelID: string
  selectID: 'CodeComponent1'
  opened: boolean
  onClose?: () => void
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const codeComponent = useGetSelectData({ modelID, selectID })
  const updateCodeComponent = trpc.useMutation('user.codeComponent.updateCodeComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [code, setCode] = useState(codeComponent.code)

  return (
    <>
      <Modal
        transitionDuration={0}
        opened={opened}
        onClose={() => {
          if (onClose) onClose()
        }}
      >
        <SimpleGrid>
          <TextInputWithUpdate
            textarea={true}
            value={codeComponent.code}
            autosize={true}
            onChange={value => {
              setCode(value)
            }}
            label={'Вставить html код'}
          />

          <Group>
            <Button
              loading={updateCodeComponent.isLoading}
              onClick={() => {
                updateCodeComponent.mutate({
                  codeComponentID: codeComponent.id,
                  code,
                })
              }}
            >
              Сохранить
            </Button>
          </Group>
        </SimpleGrid>
      </Modal>
    </>
  )
}
