import { keyframes } from '@emotion/react'

export const animationEmotionBounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: scale(1);
  }

  40%, 43% {
    transform: scale(1.03);
  }

  70% {
    transform: scale(1.02);
  }
`
