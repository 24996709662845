import { ScreeenType } from '@prisma/client'
import { createContext, useContext } from 'react'

export type ResponsiveMode = 'mobile' | 'tablet' | 'desktop'

export type BoxBlockContext = {
  lineIsShow: boolean
  setLineIsShow: (c: boolean) => void
  responsiveMode: ScreeenType
  setResponsiveMode: (c: ScreeenType) => void
  focusBoxID: string
  setFocusBoxID: (b: string) => void
  focusBoxBlockID: string
  setFocusBoxBlockID: (b: string) => void
}
export const MyBoxBlockContext = createContext<BoxBlockContext>({
  lineIsShow: false,
  setLineIsShow: () => {},
  responsiveMode: 'Desktop',
  setResponsiveMode: () => {},
  focusBoxID: '',
  setFocusBoxID: () => {},
  focusBoxBlockID: '',
  setFocusBoxBlockID: () => {},
})
export const useBoxBlockContext = () => useContext(MyBoxBlockContext)
