import { Group, Loader, SimpleGrid, Skeleton, Title } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconArrowRight } from '@tabler/icons'
import BoxBlock from 'blocks/BoxBlock/BoxBlock'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import React, { RefObject, useEffect, useState } from 'react'
import { heightBlockPreview, widthBlockPreview } from 'syncComponents/SiteBlocksSync/SiteBlocksSync'
import { ErrorBoundary } from 'utils/ErrorBoundary'
import { cabinetColors, cabinetShadows, cabinetSizes } from 'utils/cabinetTheme'
import { trpc } from 'utils/trpc'

export function BlockCollections({
  // onChooseBoxBlock,
  parentRef,
  pageID,
  onCopied,
  pinToPopup,
}: {
  parentRef: RefObject<HTMLDivElement>
  pageID?: string
  onCopied: () => void
  pinToPopup?: boolean
  // onChooseBoxBlock: ({}: { boxBlockID: string }) => void
}) {
  const [currentPaidPlaceID, setCurrentPaidPlaceID] = useState('')
  const blockCollections = trpc.useQuery(['user.blockCollections.getBlockCollections'], {
    onSuccess: data => {
      if (data && !currentPaidPlaceID) {
        for (let index = 0; index < data.paidPlaces.length; index++) {
          const paidPlace = data.paidPlaces[index]
          if (paidPlace.site) return setCurrentPaidPlaceID(paidPlace.id)
        }
      }
    },
  })

  const templateSite = blockCollections.data?.paidPlaces.find(p => p.id == currentPaidPlaceID)?.site

  return (
    <>
      {blockCollections.data ? (
        <SimpleGrid spacing={12}>
          <Group
            css={{
              width: '100%',
            }}
            spacing={4}
          >
            {[...blockCollections.data.paidPlaces]
              .filter(p => p.site)
              .map(paidPlace => {
                return (
                  <GrayButton
                    onClick={() => {
                      setCurrentPaidPlaceID(paidPlace.id)
                    }}
                    white={paidPlace.id == currentPaidPlaceID ? false : true}
                    leftIcon={paidPlace.id == currentPaidPlaceID ? <IconArrowRight /> : undefined}
                    small={true}
                  >
                    {paidPlace.site?.name}
                  </GrayButton>
                )
              })}
          </Group>

          {templateSite
            ? templateSite.categoryBlocks.map(category => {
                return category.blocks.length ? (
                  <SimpleGrid
                    css={{
                      background: cabinetColors.background,
                      border: `1px solid ${cabinetColors.border}`,
                      boxShadow: cabinetShadows.input,
                      borderRadius: cabinetSizes.radius,
                    }}
                    p={'md'}
                    spacing={'md'}
                    key={category.id}
                  >
                    <Title order={4}>{category.name}</Title>

                    <Group spacing={'md'}>
                      {category.blocks.map(boxBlock => (
                        <BlockCollectionShell
                          pinToPopup={pinToPopup}
                          onCopied={onCopied}
                          key={boxBlock.id}
                          pageID={pageID}
                          parentRef={parentRef}
                          boxBlockID={boxBlock.id}
                        />
                      ))}
                    </Group>
                  </SimpleGrid>
                ) : (
                  <></>
                )
              })
            : null}
        </SimpleGrid>
      ) : (
        <>
          <SimpleGrid title="Загрузка шаблоных блоков">
            <Group>
              {[1, 2, 3, 4, 5].map(() => (
                <Skeleton height={20} width={60}></Skeleton>
              ))}
            </Group>
            <Group>
              {[1, 2, 3, 4, 5, 6, 1, 1, 1, 1, 1, 1].map(() => (
                <Skeleton height={130} width={150}></Skeleton>
              ))}
            </Group>
          </SimpleGrid>
          {/* <Skeleton  height={600} /> */}

          {/* <Text></Text> */}
        </>
      )}
    </>
  )
}

export function MySiteBlockCollections({
  // onChooseBoxBlock,
  parentRef,
  pageID,
  onCopied,
  pinToPopup,
}: {
  parentRef: RefObject<HTMLDivElement>
  pageID?: string
  onCopied: () => void
  pinToPopup?: boolean
  // onChooseBoxBlock: ({}: { boxBlockID: string }) => void
}) {
  const [currentPaidPlaceID, setCurrentPaidPlaceID] = useState('')

  const siteCollections = trpc.useQuery(['user.blockCollections.getBlockSites'], {
    onSuccess: data => {
      if (data && !currentPaidPlaceID) {
        for (let index = 0; index < data.paidPlaces.length; index++) {
          const paidPlace = data.paidPlaces[index]
          if (paidPlace.site) return setCurrentPaidPlaceID(paidPlace.id)
        }
      }
    },
  })

  const mySite = siteCollections.data?.paidPlaces.find(p => p.id == currentPaidPlaceID)?.site

  return (
    <>
      {siteCollections.data ? (
        <SimpleGrid spacing={12}>
          <Group
            css={{
              width: '100%',
            }}
            spacing={4}
          >
            {siteCollections.data ? (
              [...siteCollections.data.paidPlaces]
                .filter(p => p.site)
                .map(paidPlace => {
                  return (
                    <GrayButton
                      onClick={() => {
                        setCurrentPaidPlaceID(paidPlace.id)
                      }}
                      white={paidPlace.id == currentPaidPlaceID ? false : true}
                      leftIcon={paidPlace.id == currentPaidPlaceID ? <IconArrowRight /> : undefined}
                      small={true}
                    >
                      {paidPlace.site?.name}
                    </GrayButton>
                  )
                })
            ) : (
              <></>
            )}
          </Group>

          {mySite ? (
            <SimpleGrid
              css={{
                background: cabinetColors.background,
                border: `1px solid ${cabinetColors.border}`,
                boxShadow: cabinetShadows.input,
                borderRadius: cabinetSizes.radius,
              }}
              p={'md'}
              spacing={'md'}
            >
              <Title order={4}>Скопировать блоки с другого сайта</Title>
              <Group spacing={'md'}>
                {mySite.boxBlocks.map(boxBlock => {
                  return (
                    <BlockCollectionShell
                      pinToPopup={pinToPopup}
                      onCopied={onCopied}
                      key={boxBlock.id}
                      pageID={pageID}
                      parentRef={parentRef}
                      boxBlockID={boxBlock.id}
                    />
                  )
                })}
              </Group>
            </SimpleGrid>
          ) : (
            <></>
          )}
        </SimpleGrid>
      ) : (
        <>
          <SimpleGrid title="Загрузка шаблоных блоков">
            <Group>
              {[1, 2, 3, 4, 5].map(() => (
                <Skeleton height={20} width={60}></Skeleton>
              ))}
            </Group>
            <Group>
              {[1, 2, 3, 4, 5, 6, 1, 1, 1, 1, 1, 1].map(() => (
                <Skeleton height={130} width={150}></Skeleton>
              ))}
            </Group>
          </SimpleGrid>
          {/* <Skeleton  height={600} /> */}

          {/* <Text></Text> */}
        </>
      )}
    </>
  )
}

function BlockCollectionShell({
  boxBlockID,
  parentRef,

  pageID,
  pinToPopup,
  onCopied,
}: {
  boxBlockID: string
  parentRef: RefObject<HTMLDivElement>

  pageID?: string
  pinToPopup?: boolean
  onCopied: () => void
}) {
  const { ref, entry } = useIntersection({
    root: parentRef.current,
    threshold: 0.05,
  })
  const { classes } = useMainStyles()

  const [opened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(!!entry?.isIntersecting)
  }, [entry?.isIntersecting])
  const copyFavoriteBoxBlock = trpc.useMutation('user.blockCollections.copyFavoriteBoxBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createBoxBlockWithPopup = trpc.useMutation('user.box.createBoxBlockWithPopup', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const { siteID } = useSyncSiteContext()

  const isLoading = copyFavoriteBoxBlock.isLoading || createBoxBlockWithPopup.isLoading

  return (
    <>
      <SimpleGrid
        spacing={0}
        onClick={async () => {
          if (pageID) {
            await copyFavoriteBoxBlock.mutateAsync({
              boxBlockID: boxBlockID,
              pageID,
            })
          }
          if (pinToPopup) {
            await createBoxBlockWithPopup.mutateAsync({
              boxBlockID: boxBlockID,
              siteID,
            })
          }
          if (onCopied) onCopied()
        }}
        css={{
          width: widthBlockPreview,
          background: cabinetColors.white,
          border: `1px solid ${cabinetColors.border}`,
          borderRadius: cabinetSizes.radius,
          boxShadow: cabinetShadows.input,
          overflow: 'hidden',
        }}
      >
        <div
          css={{
            width: '100%',
            height: heightBlockPreview,
            borderBottom: `1px solid ${cabinetColors.border}`,
            overflowY: 'auto',
            cursor: 'pointer',
            position: 'relative',
            transition: '0.3s',
            background: 'white',
            '&:hover': {
              transform: `scale(1.02)`,
            },
          }}
          ref={ref}
          className={classes.hideScrollbar}
        >
          {isLoading ? (
            <div
              css={{
                left: '50%',
                top: '50%',
                position: 'absolute',
                transform: `translate(-50%, -50%)`,
                zIndex: 1000,
              }}
            >
              <Loader />
            </div>
          ) : null}
          {opened ? <BlockCollectionMemo boxBlockID={boxBlockID} /> : <></>}
        </div>
        <Group p="xs">
          <GrayButton loading={isLoading} small={true} fullWidth>
            {pinToPopup ? 'использовать блок' : 'скопировать блок'}
          </GrayButton>
        </Group>
      </SimpleGrid>
    </>
  )
}

const BlockCollectionMemo = React.memo(BlockCollection, () => true)

function BlockCollection({ boxBlockID }: { boxBlockID: string }) {
  const blockCollection = trpc.useQuery(
    [
      'user.blockCollections.getBlockCollection',
      {
        boxBlockID,
      },
    ],
    {
      refetchInterval: 5000,
    }
  )

  return (
    <>
      <ErrorBoundary message={'Пока недоступно копирование блоков с формой и квизами, мы работаем над этим'}>
        {blockCollection.data ? (
          <>
            {/* @ts-ignore */}
            <ReduceComponent hideBorder={true} component={<BoxBlock {...blockCollection.data} />} />
          </>
        ) : (
          <div
            css={{
              left: '50%',
              top: '50%',
              position: 'absolute',
              transform: `translate(-50%, -50%)`,
            }}
          >
            <Loader />
          </div>
        )}
      </ErrorBoundary>
    </>
  )
}
