import {
  Button,
  CSSObject,
  Checkbox,
  Group,
  MantineProvider,
  MantineSize,
  Radio,
  SimpleGrid,
  Text,
  TextInput,
  TextInputProps,
} from '@mantine/core'
import { FakeForm, Form, commonBorderRadius } from 'components/Form/Form'
import { TextWrap } from 'components/TextWrap'
import { mqBox } from 'config/mq'
import { PublicSiteContext } from 'contexts/PublicSiteContextProvider'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { ReactNode, useContext, useState } from 'react'
import { QuizSelect } from 'server/selects'
import { formWithAtQuiz, minHeightQuizStep } from 'syncComponents/SiteQuizzesSync/SiteQuizzesSync'
import { getPathToFile } from 'utils/getPathToFile'
import { landingTheme } from 'utils/landingTheme'
import { colors } from 'utils/styles'
import { QuizProgress } from '../../syncComponents/QuizProgress/QuizProgress'

type ItemType = 'radio' | 'checkbox'
type ColorType = {
  color: string
  textColor?: string
  buttonTextColor?: string
}
type ImageType = {
  name: string
  folder: string | null
}

function BorderForQuizItem({ active, color }: { active: boolean; color: string }) {
  return (
    <div
      css={{
        opacity: active ? 1 : 0,
        borderRadius: commonBorderRadius,
        position: 'absolute',
        pointerEvents: 'none',
        left: '50%',
        top: '50%',
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        border: `2px solid ${color}`,
        transform: 'translate(-50%, -50%)',
        transition: '0.05s',
      }}
    ></div>
  )
}

function ImageForQuizItem({ image, color }: { image?: ImageType; color: string }) {
  return (
    <div
      css={{
        borderBottom: `1px solid ${color}`,
        height: '140px',
        width: '100%',
        backgroundImage: image
          ? `url(${getPathToFile({
              fileName: image.name,
              folderName: image.folder,
            })})`
          : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        [mqBox.desktop]: {
          height: '160px',
        },
      }}
    ></div>
  )
}

function textInputCommonProps({ colors }: { colors: ColorType }): TextInputProps {
  return {
    styles: {
      root: {
        width: '100%',
      },
      input: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: colors.textColor,
        borderRadius: commonBorderRadius,
        '&:focused, &:focus-within, &:hover': {
          borderColor: colors.color,
          backgroundColor: 'rgba(0,0,0,0)',
        },
        color: colors.textColor,
      },
    },
    error: '',
    size: 'lg',
  }
}

function checkboxCommonProps({ type, isItemWithImage, color }: { type: ItemType; isItemWithImage: boolean; color: string }) {
  const root: CSSObject = {
    padding: 0,
    borderRadius: commonBorderRadius,
    position: isItemWithImage ? 'absolute' : undefined,
    right: isItemWithImage ? 12 : undefined,
    top: isItemWithImage ? 12 : undefined,
    zIndex: 10,
  }

  const input: CSSObject = {
    borderRadius: type == 'checkbox' ? commonBorderRadius : undefined,
    backgroundColor: 'rgba(0,0,0,0)',
    borderColor: color,
    '&:checked': {
      borderColor: color,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  }

  const size: MantineSize = 'md'

  return {
    size,
    styles: {
      root: type == 'checkbox' ? root : undefined,
      inner: type == 'radio' ? root : undefined,

      input: type == 'checkbox' ? input : undefined,
      radio: type == 'radio' ? input : undefined,

      icon: {
        color: color,
      },
    },
  }
}

function QuizFormShell({ children }: { children: ReactNode }) {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        minHeight: minHeightQuizStep,
        width: '100%',
      }}
    >
      <div
        css={{
          width: formWithAtQuiz,
        }}
      >
        {children}
      </div>
    </div>
  )
}

function OneQuizItem({
  type,
  onClick,
  isItemWithImage,
  color,
  textColor,
  image,
  itemIsActive,
  title,
}: {
  type: ItemType
  onClick: () => void
  isItemWithImage: boolean
  color: string
  textColor?: string
  image?: ImageType
  itemIsActive: boolean
  title: string
}) {
  return (
    <div
      css={{
        cursor: 'pointer',
        width: isItemWithImage ? 140 : '100%',
        border: `1px solid ${color}`,
        position: 'relative',
        borderRadius: commonBorderRadius,
        display: 'flex',
        flexWrap: 'wrap',
        [mqBox.desktop]: {
          width: isItemWithImage ? 160 : '100%',
        },
      }}
      onClick={() => {
        onClick()
      }}
    >
      {isItemWithImage ? (
        <>
          <BorderForQuizItem active={itemIsActive} color={color} />
          <ImageForQuizItem image={image} color={color} />
        </>
      ) : (
        <></>
      )}
      <Group p={'xs'}>
        {type == 'checkbox' ? (
          <Checkbox {...checkboxCommonProps({ type, isItemWithImage, color })} checked={itemIsActive} value={title} />
        ) : (
          <Radio {...checkboxCommonProps({ type, isItemWithImage, color })} checked={itemIsActive} value={title} />
        )}

        <Text
          css={{
            color: textColor,
          }}
          size={isItemWithImage ? 14 : 18}
        >
          {title}
        </Text>
      </Group>
    </div>
  )
}

function QuizStepStucture({
  color,
  textColor,
  buttonTextColor,
  title,
  stepJSX,
  stepTitle,
  quizProgressPercent,
  quizProgressText,
  showOnBack,
  onBack,
  onNext,
}: {
  color: string
  textColor?: string
  buttonTextColor?: string
  title: string
  stepJSX?: ReactNode
  stepTitle?: string
  quizProgressPercent: number
  quizProgressText: string
  showOnBack: boolean
  onBack: () => void
  onNext: () => void
}) {
  const showTitle = title !== '<p></p>' && title !== ''
  return (
    <SimpleGrid
      css={{
        minHeight: minHeightQuizStep,
        // height: '100%',
        alignContent: 'space-between',
        width: '100%',
      }}
    >
      <SimpleGrid
        css={{
          width: '100%',
        }}
      >
        {showTitle ? <TextWrap color={textColor} text={title} /> : null}

        <QuizProgress secondColor={buttonTextColor} color={color} percent={quizProgressPercent} text={quizProgressText} />
        {stepTitle ? <TextWrap color={textColor} text={stepTitle} /> : null}
        {stepJSX ? (
          <Group
            css={{
              width: '100%',
              alignItems: 'flex-start',
            }}
            spacing={6}
          >
            {stepJSX ? stepJSX : null}
          </Group>
        ) : null}
      </SimpleGrid>

      <Group position="apart">
        <div>
          {showOnBack ? (
            <Button
              radius={commonBorderRadius}
              css={{
                color: buttonTextColor,
              }}
              onClick={() => onBack()}
            >
              Назад
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            radius={commonBorderRadius}
            css={{
              color: buttonTextColor,
            }}
            size="lg"
            onClick={() => onNext()}
          >
            Дальше
          </Button>
        </div>
      </Group>
    </SimpleGrid>
  )
}

export default function Quiz({ onSended, ...quiz }: NonNullable<QuizSelect> & { onSended?: () => void }) {
  const { publicSite } = useContext(PublicSiteContext)

  const [step, setStep] = useState(0)
  const percentage = (step / quiz.steps.length) * 100
  const currentStep: undefined | NonNullable<QuizSelect>['steps'][0] = quiz.steps[step]
  const [stepsValue, setStepsValue] = useState<
    { id: string; value: string | { id: string; value: string }[]; title: string }[]
  >(
    quiz.steps.map(step => ({
      id: step.id,
      value: step.checkboxStepsV1 ? [] : '',
      title: step.checkboxStepsV1?.title || step.radioStepsV1?.title || step.fieldInputStep?.title || '',
    }))
  )

  const isLastStep = step == quiz.steps.length

  const quizProgress = `${step + 1} из ${quiz.steps.length + 1} шагов`

  const color = quiz.mainColor
    ? quiz.mainColor.value
    : publicSite.siteV1.mainColor
    ? publicSite.siteV1.mainColor.value
    : colors.blue

  const textColor = quiz.textColor?.value
  const buttonTextColor = quiz.buttonTextColor?.value

  const textInputValue = currentStep ? stepsValue.find(step => step.id == currentStep.id) : ''

  return (
    <>
      <MantineProvider theme={landingTheme({ color: color })}>
        {!isLastStep ? (
          <>
            <QuizStepStucture
              color={color}
              textColor={textColor}
              buttonTextColor={buttonTextColor}
              title={quiz.title}
              stepJSX={
                currentStep ? (
                  <>
                    {currentStep.checkboxStepsV1 ? (
                      <>
                        {currentStep.checkboxStepsV1.checkboxItemsV1.map(item => {
                          const itemIsActive = stepsValue.find(stepValue => {
                            if (stepValue.id == currentStep.id) {
                              const thereIsItem = Array.isArray(stepValue.value)
                                ? stepValue.value.find(itemLoop => itemLoop.id == item.id)
                                : false
                              return thereIsItem ? true : false
                            }
                            return false
                          })
                            ? true
                            : false
                          return (
                            <OneQuizItem
                              type={'checkbox'}
                              onClick={() => {
                                try {
                                  setStepsValue(
                                    stepsValue.map(stepValue => {
                                      if (stepValue.id == currentStep.id) {
                                        return {
                                          ...stepValue,
                                          value: Array.isArray(stepValue.value)
                                            ? stepValue.value.find(itemLoop => itemLoop.id == item.id)
                                              ? stepValue.value.filter(itemLoop => itemLoop.id != item.id)
                                              : [
                                                  ...stepValue.value,
                                                  {
                                                    id: item.id,
                                                    value: item.title,
                                                  },
                                                ]
                                            : [],
                                        }
                                      }
                                      return stepValue
                                    })
                                  )
                                } catch (error) {}
                                if (item.target && publicSite.siteV1.yandexMetrikaId) {
                                  try {
                                    // @ts-ignore
                                    window.ym(publicSite.siteV1.yandexMetrikaId, 'reachGoal', item.target.name)
                                  } catch (error) {}
                                }
                                if (item.target && publicSite.siteV1.vkontaktePixelId) {
                                  try {
                                    // @ts-ignore
                                    VK.Retargeting.Event(item.target.name)
                                  } catch (error) {}
                                }
                              }}
                              isItemWithImage={currentStep.isItemWithImage}
                              color={color}
                              textColor={textColor}
                              image={
                                item.image
                                  ? {
                                      name: item.image.name,
                                      folder: item.image.folder,
                                    }
                                  : undefined
                              }
                              itemIsActive={itemIsActive}
                              title={item.title}
                            />
                          )
                        })}
                      </>
                    ) : null}
                    {currentStep.radioStepsV1 ? (
                      <>
                        {currentStep.radioStepsV1.radioItemsV1.map(item => {
                          const itemIsActive = stepsValue.find(stepValue => {
                            if (stepValue.id == currentStep.id) {
                              return typeof stepValue.title == 'string' ? stepValue.value == item.title : false
                            }
                            return false
                          })
                            ? true
                            : false
                          return (
                            <OneQuizItem
                              type={'radio'}
                              onClick={() => {
                                try {
                                  setStepsValue(
                                    stepsValue.map(stepValue => {
                                      if (stepValue.id == currentStep.id) {
                                        stepValue.value = item.title
                                      }
                                      return stepValue
                                    })
                                  )
                                  setStep(step + 1)
                                } catch (error) {}
                                if (item.target && publicSite.siteV1.yandexMetrikaId) {
                                  try {
                                    // @ts-ignore
                                    window.ym(publicSite.siteV1.yandexMetrikaId, 'reachGoal', item.target.name)
                                  } catch (error) {}
                                }
                                if (item.target && publicSite.siteV1.vkontaktePixelId) {
                                  try {
                                    // @ts-ignore
                                    VK.Retargeting.Event(item.target.name)
                                  } catch (error) {}
                                }
                              }}
                              isItemWithImage={currentStep.isItemWithImage}
                              color={color}
                              textColor={textColor}
                              image={
                                item.image
                                  ? {
                                      name: item.image.name,
                                      folder: item.image.folder,
                                    }
                                  : undefined
                              }
                              itemIsActive={itemIsActive}
                              title={item.title}
                            />
                          )
                        })}
                      </>
                    ) : null}

                    {currentStep.fieldInputStep ? (
                      <>
                        <TextInput
                          {...textInputCommonProps({
                            colors: {
                              color,
                              textColor,
                            },
                          })}
                          value={textInputValue ? (typeof textInputValue.value == 'string' ? textInputValue.value : '') : ''}
                          onChange={event => {
                            const value = event.target.value

                            setStepsValue(
                              stepsValue.map(stepValue => {
                                if (stepValue.id == currentStep.id) {
                                  stepValue.value = value
                                }
                                return stepValue
                              })
                            )
                          }}
                          placeholder={currentStep.fieldInputStep.placeholder}
                        />
                      </>
                    ) : null}
                  </>
                ) : undefined
              }
              stepTitle={
                currentStep
                  ? currentStep.checkboxStepsV1?.title ||
                    currentStep.radioStepsV1?.title ||
                    currentStep.fieldInputStep?.title ||
                    ''
                  : undefined
              }
              quizProgressPercent={percentage}
              quizProgressText={quizProgress}
              showOnBack={step > 0}
              onBack={() => setStep(step - 1)}
              onNext={() => setStep(step + 1)}
            />
          </>
        ) : (
          <>
            {quiz.form ? (
              <QuizFormShell>
                <Form
                  buttonTextColor={buttonTextColor}
                  textColor={textColor}
                  mainColor={color}
                  onSended={() => {
                    if (onSended) onSended()
                  }}
                  info={stepsValue.reduce<string>((prevValue, currentValue, index) => {
                    return `${
                      index == 0
                        ? `${quiz.title}
`
                        : `${prevValue}
`
                    }${currentValue.title}: ${
                      typeof currentValue.value == 'string'
                        ? currentValue.value
                        : currentValue.value.map(item => item.value).join(',')
                    }`
                  }, '')}
                  form={quiz.form}
                  onBack={() => {
                    setStep(step - 1)
                  }}
                />
              </QuizFormShell>
            ) : null}
          </>
        )}
      </MantineProvider>
    </>
  )
}

export function FakeQuiz({ modelID, selectID }: { modelID: string; selectID: 'Quiz1' }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const mainColor = useGetSelectDataUndefined({ modelID: site.mainColor?.id, selectID: 'Color1' })
  const quiz = useGetSelectData({ modelID, selectID })

  const quizMainColor = useGetSelectDataUndefined({ modelID: quiz.mainColor?.id, selectID: quiz.mainColor?.selectID })
  const quizTextColor = useGetSelectDataUndefined({ modelID: quiz.textColor?.id, selectID: quiz.textColor?.selectID })
  const quizButtonTextColor = useGetSelectDataUndefined({
    modelID: quiz.buttonTextColor?.id,
    selectID: quiz.buttonTextColor?.selectID,
  })
  const color = quizMainColor ? quizMainColor.value : mainColor ? mainColor.value : colors.buttonMantineDefaultColor
  const textColor = quizTextColor ? quizTextColor.value : '#333'

  const quizProgress = `${1} из ${quiz.steps.length + 1} шагов`

  const quizStep = useGetSelectDataUndefined({ modelID: quiz.steps[0]?.id, selectID: quiz.steps[0]?.selectID })

  const checkboxStep = useGetSelectDataUndefined({
    modelID: quizStep?.checkboxStepsV1?.id,
    selectID: quizStep?.checkboxStepsV1?.selectID,
  })
  const radioStep = useGetSelectDataUndefined({
    modelID: quizStep?.radioStepsV1?.id,
    selectID: quizStep?.radioStepsV1?.selectID,
  })
  const fieldInputStep = useGetSelectDataUndefined({
    modelID: quizStep?.fieldInputStep?.id,
    selectID: quizStep?.fieldInputStep?.selectID,
  })

  return (
    <>
      <MantineProvider theme={landingTheme({ color: color })}>
        {quiz.steps[0] ? (
          <QuizStepStucture
            color={color}
            textColor={textColor}
            buttonTextColor={quizButtonTextColor?.value}
            title={quiz.title}
            stepJSX={
              quiz.steps[0] && quizStep ? (
                <FakeStepQuiz
                  modelID={quiz.steps[0].id}
                  selectID={quiz.steps[0].selectID}
                  colors={{ color: color, textColor, buttonTextColor: quizButtonTextColor?.value }}
                  isItemWithImage={quizStep.isItemWithImage}
                />
              ) : undefined
            }
            stepTitle={quizStep ? checkboxStep?.title || radioStep?.title || fieldInputStep?.title || '' : undefined}
            quizProgressPercent={(1 / quiz.steps.length) * 100}
            quizProgressText={quizProgress}
            showOnBack={false}
            onBack={() => {}}
            onNext={() => {}}
          />
        ) : quiz.form ? (
          <QuizFormShell>
            <FakeForm parentColor={color} modelID={quiz.form.id} selectID={quiz.form.selectID} onBack={() => {}} />
          </QuizFormShell>
        ) : null}
      </MantineProvider>
    </>
  )
}

function FakeStepQuiz({
  modelID,
  selectID,
  colors,
  isItemWithImage,
}: {
  modelID: string
  selectID: 'QuizStep1'
  colors: ColorType
  isItemWithImage: boolean
}) {
  const step = useGetSelectData({ modelID, selectID })

  const checkboxStep = useGetSelectDataUndefined({
    modelID: step.checkboxStepsV1?.id,
    selectID: step.checkboxStepsV1?.selectID,
  })
  const radioStep = useGetSelectDataUndefined({
    modelID: step.radioStepsV1?.id,
    selectID: step.radioStepsV1?.selectID,
  })
  const fieldInputStep = useGetSelectDataUndefined({
    modelID: step.fieldInputStep?.id,
    selectID: step.fieldInputStep?.selectID,
  })

  return (
    <>
      {radioStep
        ? radioStep.radioItemsV1.map(item => (
            <FakeRadioItemQuiz
              key={item.id}
              modelID={item.id}
              selectID={item.selectID}
              colors={colors}
              isItemWithImage={isItemWithImage}
            />
          ))
        : null}
      {checkboxStep
        ? checkboxStep.checkboxItemsV1.map(item => (
            <FakeCheckboxItemQuiz
              key={item.id}
              modelID={item.id}
              selectID={item.selectID}
              colors={colors}
              isItemWithImage={isItemWithImage}
            />
          ))
        : null}
      {fieldInputStep ? (
        <TextInput
          {...textInputCommonProps({ colors })}
          value={''}
          onChange={event => {}}
          placeholder={fieldInputStep.placeholder}
        />
      ) : null}
    </>
  )
}

function FakeRadioItemQuiz({
  modelID,
  selectID,
  isItemWithImage,
  colors,
}: {
  modelID: string
  selectID: 'QuizRadioStepItem1'
  isItemWithImage: boolean
  colors: ColorType
}) {
  const radioItem = useGetSelectData({ modelID, selectID })
  const image = useGetSelectDataUndefined({ modelID: radioItem.image?.id, selectID: radioItem.image?.selectID })

  return (
    <>
      <OneQuizItem
        type={'radio'}
        onClick={() => {}}
        isItemWithImage={isItemWithImage}
        color={colors.color}
        textColor={colors.textColor}
        image={
          image
            ? {
                name: image.name,
                folder: image.folder,
              }
            : undefined
        }
        itemIsActive={false}
        title={radioItem.title}
      />
    </>
  )
}

function FakeCheckboxItemQuiz({
  modelID,
  selectID,
  isItemWithImage,
  colors,
}: {
  modelID: string
  selectID: 'QuizCheckboxStepItem1'
  isItemWithImage: boolean
  colors: ColorType
}) {
  const checkboxItem = useGetSelectData({ modelID, selectID })
  const image = useGetSelectDataUndefined({ modelID: checkboxItem.image?.id, selectID: checkboxItem.image?.selectID })

  return (
    <>
      <OneQuizItem
        type={'checkbox'}
        onClick={() => {}}
        isItemWithImage={isItemWithImage}
        color={colors.color}
        textColor={colors.textColor}
        image={
          image
            ? {
                name: image.name,
                folder: image.folder,
              }
            : undefined
        }
        itemIsActive={false}
        title={checkboxItem.title}
      />
    </>
  )
}
