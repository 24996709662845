import { create } from 'zustand'

export type IsCan = 'rechtagle' | 'image' | 'text' | undefined
export type imageID = string

interface BearState {
  isCan: IsCan
  imageID?: string
}

export const useAddAbsoluteLayerState = create<BearState>()(set => ({
  isCan: undefined,
  imageID: undefined,
}))

export const turnAddAbsoluteLayerState = (value: IsCan) => {
  useAddAbsoluteLayerState.setState({ isCan: value })
}
export const setImageAddAbsoluteLayerState = ({ imageID }: { imageID?: imageID }) => {
  useAddAbsoluteLayerState.setState({ imageID, isCan: imageID ? 'rechtagle' : undefined })
}
