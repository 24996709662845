import { create } from 'zustand'

interface BearState {
  sections: { sectionID: string; bottomIndent?: number; subscriberID: string }[]
}

export const usePublicPageSectionsPosition = create<BearState>()(set => ({
  sections: [],
}))

export const addSection = ({ sectionID }: { sectionID: string }) => {
  const subscriberID = 'id' + Math.random().toString(16).slice(2)

  usePublicPageSectionsPosition.setState(state => ({
    sections: [...state.sections, { sectionID, bottomIndent: undefined, subscriberID }],
  }))
  return {
    subscriberID,
  }
}

export const unlistenSection = ({ subscriberID }: { subscriberID: string }) => {
  usePublicPageSectionsPosition.setState(state => ({
    sections: state.sections.filter(s => s.subscriberID !== subscriberID),
  }))
}

export const updateSectionBottomIndent = ({ sectionID, bottomIndent }: { sectionID: string; bottomIndent: number }) => {
  usePublicPageSectionsPosition.setState(state => ({
    sections: state.sections.map(section => {
      if (section.sectionID !== sectionID) {
        return {
          ...section,
          bottomIndent,
        }
      }
      return section
    }),
  }))
}

export const getSectionIDAtBottomOfWindow = () => {
  const sectionID = usePublicPageSectionsPosition
    .getState()
    .sections.sort((a, b) =>
      typeof a.bottomIndent == 'number' && typeof b.bottomIndent == 'number'
        ? a.bottomIndent > b.bottomIndent && a.bottomIndent <= 0 && b.bottomIndent <= 0
          ? 1
          : -1
        : 0
    )[0].sectionID

  return {
    sectionID,
  }
}
