import { DndContext, DragEndEvent, MouseSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import {
  ActionIcon,
  Button,
  ColorSwatch,
  Drawer,
  Group,
  Kbd,
  Menu,
  Modal,
  Paper,
  Popover,
  Portal,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'

import { useForm } from '@mantine/form'
import { useHotkeys } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { BoxVector, FlexBoxPosition, ScreeenType, WidthType } from '@prisma/client'
import {
  IconArrowLeftRight,
  IconArrowMoveLeft,
  IconArrowMoveRight,
  IconBox,
  IconBuildingCarousel,
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDeviceTablet,
  IconForms,
  IconPhoto,
  IconPlus,
  IconQuestionMark,
  IconRefresh,
  IconTextSize,
  IconTransform,
  IconVideoPlus,
  IconWashDrycleanOff,
  IconX,
} from '@tabler/icons'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { SiteContext } from 'contexts/SiteContextProvider'
import useEmblaCarousel from 'embla-carousel-react'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useResponsiveProperty } from 'hooks/useResponsiveProperty/useResponsiveProperty'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { CSSProperties, ReactNode, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { NestedBoxSelect } from 'server/selects'
import { BoxBackgroundSyncMemo } from 'syncComponents/BoxBackgroundSync/BoxBackgroundSync'
import { ChooseSitePopupsSync } from 'syncComponents/ChooseSitePopupsSync/ChooseSitePopupsSync'

import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { EditImageSync } from 'syncComponents/EditImageSync/EditImageSync'
import { EditorTextComponentSyncMemo } from 'syncComponents/EditorTextComponentSync/EditorTextComponentSync'
import { FaqComponentSync } from 'syncComponents/FaqComponentSync/FaqComponentSync'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { ImagePreviewSyncMemo } from 'syncComponents/ImagePreviewSync/ImagePreviewSync'
import { QuizComponentSync } from 'syncComponents/QuizComponentSync/QuizComponentSync'
import { SiteQuizzesSync } from 'syncComponents/SiteQuizzesSync/SiteQuizzesSync'
import { VideoComponentSync } from 'syncComponents/VideoComponentSync/VideoComponentSync'

import { eventenerWithDetail } from 'utils/eventener'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'
import { boxActiveIDCenter } from 'utils/boxActiveIDCenter'

export class MyMouseSensor extends MouseSensor {
  // static activators = [
  //   {
  //     eventName: 'onMouseDown' as const,
  //     handler: ({ nativeEvent: event }: MouseEvent) => {
  //       return false
  //       // return shouldHandleEvent(event.target as HTMLElement)
  //     },
  //   },
  // ]
}

export const borderSizeDrag = 8

export const boxClickedByIDEventener = eventenerWithDetail<{ id: string }>({
  name: 'boxClickedByID',
})

export const marginColor = '#ffb800'
export const paddingColor = '#81ff9d'

export const responsiveModes = (long?: boolean): { mode: ScreeenType; text: ReactNode }[] => {
  return [
    {
      mode: 'Mobile',
      text: <IconDeviceMobile color={colors.blue} size={20} />,
    },
    {
      mode: 'Tablet',
      text: <IconDeviceTablet color={colors.blue} size={20} />,
    },
    {
      mode: 'Desktop',
      text: <IconDeviceDesktop color={colors.blue} size={20} />,
    },
  ]
}

export type ParentBoxWidth = number

export const transformBoxPosition = (position: FlexBoxPosition | null) => {
  let transformedPosition = 'flex-start'
  if (position == 'Center') transformedPosition = 'center'
  if (position == 'FlexEnd') transformedPosition = 'flex-end'
  if (position == 'FlexStart') transformedPosition = 'flex-start'
  if (position == 'SpaceAround') transformedPosition = 'space-around'
  if (position == 'SpaceBetween') transformedPosition = 'space-between'
  return transformedPosition
}

export const transformBoxWidth = (widthType: WidthType) => {
  return widthType == 'Percent' ? '%' : 'px'
}

export const BoxSyncMemo = memo(BoxSync, (prev, next) => {
  return (
    prev.modelID == next.modelID &&
    prev.boxesLength == next.boxesLength &&
    prev.parentIsSlider == next.parentIsSlider &&
    prev.index == next.index
  )
})

export function BoxSync({
  modelID,
  selectID,
  getParentBoxWidth,
  getParentBoxHeight,
  onAddBox,
  onDeleteBox,
  parentBoxId,
  boxesLength,
  // boxBlockID,
  parentIsSlider,
  index,
  hideLine,
}: {
  getParentBoxWidth: () => ParentBoxWidth
  getParentBoxHeight: () => ParentBoxWidth
  onAddBox: ({ box }: { box: NonNullable<NestedBoxSelect> }) => void
  onDeleteBox: ({ id }: { id: string }) => void
  parentBoxId?: string
  boxesLength?: number
  // boxBlockID: string
  parentIsSlider?: boolean
  modelID: string
  selectID: 'Box1'
  index?: number
  hideLine?: true
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const box = useGetSelectData({ modelID, selectID })
  const link = useGetSelectDataUndefined({ modelID: box.link?.id, selectID: box.link?.selectID })

  const responsiveBoxSettings = useGetSelectData({
    modelID: box.responsiveBoxSettings.id,
    selectID: box.responsiveBoxSettings.selectID,
  })
  const desktopBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.desktopBoxSettings.id,
    selectID: responsiveBoxSettings.desktopBoxSettings.selectID,
  })
  const tabletBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.tabletBoxSettings.id,
    selectID: responsiveBoxSettings.tabletBoxSettings.selectID,
  })
  const mobileBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.mobileBoxSettings.id,
    selectID: responsiveBoxSettings.mobileBoxSettings.selectID,
  })

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxResponsiveSettings = trpc.useMutation('user.box.updateBoxResponsiveSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxOrder = trpc.useMutation('user.box.updateBoxOrder', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBox = trpc.useMutation('user.box.updateBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [boxesPositionIsHover, setBoxesPositionIsHover] = useState(false) // false

  const boxRef = useRef<HTMLDivElement>(null)
  const fullBoxAreaRef = useRef<HTMLDivElement>(null)
  const positionAbsoluteBoxRef = useRef<HTMLDivElement>(null)
  const boxInsideRef = useRef<HTMLDivElement>(null)

  // const [width, setWidth, widthRef] = useStateRef<number>(0)
  // const [height, setHeight, heightRef] = useStateRef<number>(0)

  // const { updateBoxByID } = useSiteDataEventsContext()

  const boxWidth = useResponsiveProperty({
    long: true,
    resposiveMode: {
      desktop: box.widthDesktopResponsiveMode,
      tablet: box.widthTabletResponsiveMode,
      mobile: box.widthMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.width,
      tablet: tabletBoxSettings.width,
      mobile: mobileBoxSettings.width,
    },
    propertyName: 'Ширина',
    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        width: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'width',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxBackground = useResponsiveProperty({
    resposiveMode: {
      desktop: box.backgroundDesktopResponsiveMode,
      tablet: box.backgroundTabletResponsiveMode,
      mobile: box.backgroundMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.background,
      tablet: tabletBoxSettings.background,
      mobile: mobileBoxSettings.background,
    },
    propertyName: 'Фон',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value) {
        // await updateBoxBackground.mutateAsync({
        //   boxBackgroundID: value.id,
        //   height: value.height,
        //   width: value.width,
        //   positionX: value.positionX,
        //   positionY: value.positionY,
        // })
      }
    },

    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'background',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHeight = useResponsiveProperty({
    resposiveMode: {
      desktop: box.heightDesktopResponsiveMode,
      tablet: box.heightTabletResponsiveMode,
      mobile: box.heightMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.height,
      tablet: tabletBoxSettings.height,
      mobile: mobileBoxSettings.height,
    },
    propertyName: 'Высота',
    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        height: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'height',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxMargin = useResponsiveProperty({
    resposiveMode: {
      desktop: box.marginDesktopResponsiveMode,
      tablet: box.marginTabletResponsiveMode,
      mobile: box.marginMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        top: desktopBoxSettings.marginTop,
        right: desktopBoxSettings.marginRight,
        bottom: desktopBoxSettings.marginBottom,
        left: desktopBoxSettings.marginLeft,
      },
      tablet: {
        top: tabletBoxSettings.marginTop,
        right: tabletBoxSettings.marginRight,
        bottom: tabletBoxSettings.marginBottom,
        left: tabletBoxSettings.marginLeft,
      },
      mobile: {
        top: mobileBoxSettings.marginTop,
        right: mobileBoxSettings.marginRight,
        bottom: mobileBoxSettings.marginBottom,
        left: mobileBoxSettings.marginLeft,
      },
    },
    propertyName: 'Внешний отступ',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        marginTop: value.top,
        marginRight: value.right,
        marginBottom: value.bottom,
        marginLeft: value.left,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'margin',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPadding = useResponsiveProperty({
    resposiveMode: {
      desktop: box.paddingDesktopResponsiveMode,
      tablet: box.paddingTabletResponsiveMode,
      mobile: box.paddingMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        top: desktopBoxSettings.paddingTop,
        right: desktopBoxSettings.paddingRight,
        bottom: desktopBoxSettings.paddingBottom,
        left: desktopBoxSettings.paddingLeft,
      },
      tablet: {
        top: tabletBoxSettings.paddingTop,
        right: tabletBoxSettings.paddingRight,
        bottom: tabletBoxSettings.paddingBottom,
        left: tabletBoxSettings.paddingLeft,
      },
      mobile: {
        top: mobileBoxSettings.paddingTop,
        right: mobileBoxSettings.paddingRight,
        bottom: mobileBoxSettings.paddingBottom,
        left: mobileBoxSettings.paddingLeft,
      },
    },
    propertyName: 'Внутренний отступ',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        paddingTop: value.top,
        paddingRight: value.right,
        paddingBottom: value.bottom,
        paddingLeft: value.left,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'padding',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPosition = useResponsiveProperty({
    resposiveMode: {
      desktop: box.positionDesktopResponsiveMode,
      tablet: box.positionTabletResponsiveMode,
      mobile: box.positionMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        vector: desktopBoxSettings.vector || BoxVector.Right,
        horizontalPosition: desktopBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: desktopBoxSettings.verticalPosition || 'FlexStart',
      },
      tablet: {
        vector: tabletBoxSettings.vector || BoxVector.Right,
        horizontalPosition: tabletBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: tabletBoxSettings.verticalPosition || 'FlexStart',
      },
      mobile: {
        vector: mobileBoxSettings.vector || BoxVector.Right,
        horizontalPosition: mobileBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: mobileBoxSettings.verticalPosition || 'FlexStart',
      },
    },
    propertyName: 'Выравнивание',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        vector: value.vector,
        horizontalPosition: value.horizontalPosition,
        verticalPosition: value.verticalPosition,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'position',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHidden = useResponsiveProperty({
    resposiveMode: {
      desktop: box.hiddenDesktopResponsiveMode,
      tablet: box.hiddenTabletResponsiveMode,
      mobile: box.hiddenMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.hidden,
      tablet: tabletBoxSettings.hidden,
      mobile: mobileBoxSettings.hidden,
    },
    propertyName: 'Скрытость',

    onValueUpdated: async ({ responsiveMode, value }) => {
      // updateBoxByID({
      //   id: box.id,
      //   box: {
      //     responsiveBoxSettings: {
      //       ...responsiveBoxSettings,
      //       desktopBoxSettings: {
      //         ...desktopBoxSettings,
      //         hidden: responsiveMode == 'Desktop' ? value : desktopBoxSettings.hidden,
      //       },
      //       tabletBoxSettings: {
      //         ...tabletBoxSettings,
      //         hidden: responsiveMode == 'Tablet' ? value : tabletBoxSettings.hidden,
      //       },
      //       mobileBoxSettings: {
      //         ...mobileBoxSettings,
      //         hidden: responsiveMode == 'Mobile' ? value : mobileBoxSettings.hidden,
      //       },
      //     },
      //   },
      // })
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        hidden: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'hidden',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxGap = useResponsiveProperty({
    resposiveMode: {
      desktop: box.gapDesktopResponsiveMode,
      tablet: box.gapTabletResponsiveMode,
      mobile: box.gapMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.gap,
      tablet: tabletBoxSettings.gap,
      mobile: mobileBoxSettings.gap,
    },
    propertyName: 'Отступы',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        gap: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'gap',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxBorderRadius = useResponsiveProperty({
    resposiveMode: {
      desktop: box.borderRadiusDesktopResponsiveMode,
      tablet: box.borderRadiusTabletResponsiveMode,
      mobile: box.borderRadiusMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        LeftTop: desktopBoxSettings.borderRadiusLeftTop,
        LeftBottom: desktopBoxSettings.borderRadiusLeftBottom,
        RightTop: desktopBoxSettings.borderRadiusRightTop,
        RightBottom: desktopBoxSettings.borderRadiusRightBottom,
      },
      tablet: {
        LeftTop: tabletBoxSettings.borderRadiusLeftTop,
        LeftBottom: tabletBoxSettings.borderRadiusLeftBottom,
        RightTop: tabletBoxSettings.borderRadiusRightTop,
        RightBottom: tabletBoxSettings.borderRadiusRightBottom,
      },
      mobile: {
        LeftTop: mobileBoxSettings.borderRadiusLeftTop,
        LeftBottom: mobileBoxSettings.borderRadiusLeftBottom,
        RightTop: mobileBoxSettings.borderRadiusRightTop,
        RightBottom: mobileBoxSettings.borderRadiusRightBottom,
      },
    },
    propertyName: 'Округление',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        borderRadiusLeftTop: value.LeftTop,
        borderRadiusLeftBottom: value.LeftBottom,
        borderRadiusRightTop: value.RightTop,
        borderRadiusRightBottom: value.RightBottom,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'borderRadius',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxShadow = useResponsiveProperty({
    resposiveMode: {
      desktop: box.shadowDesktopResponsiveMode,
      tablet: box.shadowTabletResponsiveMode,
      mobile: box.shadowMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.shadow,
      tablet: tabletBoxSettings.shadow,
      mobile: mobileBoxSettings.shadow,
    },
    propertyName: 'Тень',

    onValueUpdated: async ({ responsiveMode, value }) => {},
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {},
  })
  const boxShadowData = useGetSelectDataUndefined({
    modelID: boxShadow.value?.id,
    selectID: boxShadow.value?.selectID,
  })
  const boxShadowColorData = useGetSelectDataUndefined({
    modelID: boxShadowData?.color?.id,
    selectID: boxShadowData?.color?.selectID,
  })

  const boxBorder = useResponsiveProperty({
    resposiveMode: {
      desktop: box.borderDesktopResponsiveMode,
      tablet: box.borderTabletResponsiveMode,
      mobile: box.borderMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.border,
      tablet: tabletBoxSettings.border,
      mobile: mobileBoxSettings.border,
    },
    propertyName: 'Бордер',

    onValueUpdated: async ({ responsiveMode, value }) => {},
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {},
  })

  const boxBorderData = useGetSelectDataUndefined({
    modelID: boxBorder.value?.id,
    selectID: boxBorder.value?.selectID,
  })
  const boxBorderColorData = useGetSelectDataUndefined({
    modelID: boxBorderData?.color?.id,
    selectID: boxBorderData?.color?.selectID,
  })

  const boxWidthType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.widthTypeDesktopResponsiveMode,
      tablet: box.widthTypeTabletResponsiveMode,
      mobile: box.widthTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.widthType,
      tablet: tabletBoxSettings.widthType,
      mobile: mobileBoxSettings.widthType,
    },
    propertyName: 'Тип ширины',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          widthType: value,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'widthType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHeightType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.heightTypeDesktopResponsiveMode,
      tablet: box.heightTypeTabletResponsiveMode,
      mobile: box.heightTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.heightType,
      tablet: tabletBoxSettings.heightType,
      mobile: mobileBoxSettings.heightType,
    },
    propertyName: 'Тип высоты',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          heightType: value,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'heightType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPostionType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.positionTypeDesktopResponsiveMode,
      tablet: box.positionTypeTabletResponsiveMode,
      mobile: box.positionTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        positionType: desktopBoxSettings.positionType,
        positionX: desktopBoxSettings.positionX,
        positionY: desktopBoxSettings.positionY,
      },
      tablet: {
        positionType: tabletBoxSettings.positionType,
        positionX: tabletBoxSettings.positionX,
        positionY: tabletBoxSettings.positionY,
      },
      mobile: {
        positionType: mobileBoxSettings.positionType,
        positionX: mobileBoxSettings.positionX,
        positionY: mobileBoxSettings.positionY,
      },
    },
    propertyName: 'Тип положения',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          positionType: value.positionType,
          positionX: value.positionX,
          positionY: value.positionY,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'positionType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  // const [component, setComponent] = useState(box.component)

  const [settingsIsShow, setSettingsIsShow, settingsIsShowRef] = useStateRef(false)

  useEffect(() => {
    if (!settingsIsShow) setUpLayerForCheckDoubleClickIsHide(false)
  }, [settingsIsShow])

  // useEffect(() => {
  //   setSettingsIsShow(focusBoxID == box.id && focusBoxBlockID == boxBlockID)
  // }, [focusBoxID, focusBoxBlockID])

  const [backgroundEditIsShow, setBackgroundEditIsShow] = useState(false)

  // useEffect(() => {
  //   const unlisten = boxActiveIDCenter.subscribe({
  //     boxID: box.id,
  //     callback: isActive => {
  //       setSettingsIsShow(isActive)
  //     },
  //     backgroundBoxEditIsChange: ({ is }) => {
  //       setBackgroundEditIsShow(is)
  //     },
  //   })
  //   return () => {
  //     unlisten()
  //   }
  // }, [])

  useEffect(() => {
    if (!settingsIsShow) setBackgroundEditIsShow(false)
  }, [boxesPositionIsHover])

  // useEffect(() => {
  //   if (boxInsideRef.current) {
  //     setWidth(boxInsideRef.current.offsetWidth)
  //     const resizeObserver: ResizeObserver = new ResizeObserver(() => {
  //       if (boxInsideRef.current) {
  //         if (widthRef.current !== Math.ceil(boxInsideRef.current.offsetWidth)) {
  //           setWidth(Math.ceil(boxInsideRef.current.offsetWidth))
  //         }
  //       }
  //     })
  //     resizeObserver.observe(boxInsideRef.current)
  //     return () => {
  //       resizeObserver.disconnect()
  //     }
  //   }
  // }, [])
  // useEffect(() => {
  //   if (positionAbsoluteBoxRef.current) {
  //     setHeight(positionAbsoluteBoxRef.current.offsetHeight)
  //     const resizeObserver: ResizeObserver = new ResizeObserver(() => {
  //       if (positionAbsoluteBoxRef.current) {
  //         if (heightRef.current !== Math.ceil(positionAbsoluteBoxRef.current.offsetHeight)) {
  //           setHeight(Math.ceil(positionAbsoluteBoxRef.current.offsetHeight))
  //         }
  //       }
  //     })
  //     resizeObserver.observe(positionAbsoluteBoxRef.current)
  //     return () => {
  //       resizeObserver.disconnect()
  //     }
  //   }
  // }, [])

  useEffect(() => {
    const mouseoutCallback = (event: MouseEvent) => {
      event.stopPropagation()
      setBoxesPositionIsHover(false)
    }
    const mouseoverCallback = (event: MouseEvent) => {
      event.stopPropagation()
      setBoxesPositionIsHover(true)
    }

    boxRef.current?.addEventListener('mouseout', mouseoutCallback)
    boxRef.current?.addEventListener('mouseover', mouseoverCallback)
    return () => {
      boxRef.current?.removeEventListener('mouseout', mouseoutCallback)
      boxRef.current?.removeEventListener('mouseover', mouseoverCallback)
    }
  }, [])

  const widthAndHeight = 8

  const somethingIsDrag = boxWidth.isDrag || boxPadding.isDrag || boxHeight.isDrag || boxMargin.isDrag

  useEffect(() => {
    setTimeout(() => {
      if (somethingIsDrag) localStorage.setItem('somethingIsDrag', ``)
      else localStorage.removeItem('somethingIsDrag')
    }, 50)
  }, [somethingIsDrag])

  const boxIsActive = settingsIsShow || somethingIsDrag

  const boxBackgroundData = useGetSelectDataUndefined({
    modelID: boxBackground.viewValue?.id,
    selectID: boxBackground.viewValue?.selectID,
  })
  const boxBackgroundColor = useGetSelectDataUndefined({
    modelID: boxBackgroundData?.color?.id,
    selectID: boxBackgroundData?.color?.selectID,
  })

  const createBox = trpc.useMutation('user.box.createBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBox = trpc.useMutation('user.box.deleteBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const copyBox = trpc.useMutation('user.box.copyBox', {
    onSuccess: () => {
      showNotification({
        title: 'Блок вставлен',
        message: '',
        color: 'green',
      })
    },
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinTextComponentToBox = trpc.useMutation('user.box.pinTextComponentToBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createImageComponent = trpc.useMutation('user.box.createImageComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateImageComponent = trpc.useMutation('user.box.updateImageComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const changeImageComponentImage = trpc.useMutation('user.box.changeImageComponentImage', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createFormComponent = trpc.useMutation('user.box.createFormComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createQuizComponent = trpc.useMutation('user.box.createQuizComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createVideoComponent = trpc.useMutation('user.box.createVideoComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createFaqComponent = trpc.useMutation('user.box.createFaqComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const transformedVerticalPosition = transformBoxPosition(boxPosition.value.verticalPosition)
  const transformedHorizontalPosition = transformBoxPosition(boxPosition.value.horizontalPosition)

  const [imagesOpened, setImagesOpened] = useState(false)
  const [imageComponentEditOpened, setImageComponentEditOpened] = useState(false)

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: box.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: box.id,
  })

  const [boxes, setBoxes, boxesRef] = useStateRef<NonNullable<typeof box.boxes>>(box.boxes || [])

  useEffect(() => {
    setBoxes(box.boxes || [])
  }, [box.boxes])

  const [chooseURLVideoOpened, setChooseURLVideoOpened] = useState(false)

  const formCreateUrlVideo = useForm({
    initialValues: {
      url: '',
    },
  })

  const [editFaqOpened, setEditFaqOpened] = useState(false)

  const [textComponent, setTextComponent] = useState(box.textComponent)

  useEffect(() => {
    setTextComponent(box.textComponent)
  }, [box?.textComponent])

  const onPasteBox = async () => {
    if (settingsIsShowRef.current) {
      const copiedBlockID = await navigator.clipboard.readText()
      if (copiedBlockID == box.id) {
        if (typeof boxesLength == 'number') {
          const copiedBox = await copyBox.mutateAsync({
            boxID: box.id,
            parentBoxID: box.parentBoxId,
            order: boxesLength + 1,
          })
        }
      } else {
        const copiedBox = await copyBox.mutateAsync({
          boxID: copiedBlockID,
          parentBoxID: box.id,
          order: boxesRef.current ? boxesRef.current.length + 1 : 0,
        })
      }
    }
  }
  const onCopyBox = async () => {
    if (settingsIsShowRef.current) {
      await navigator.clipboard.writeText(box.id)
      showNotification({
        title: 'Блок скопирован',
        message: '',
        color: 'green',
      })
    }
  }
  const onDeleteKeyBox = async () => {
    if (settingsIsShowRef.current) {
      onDeleteBox({ id: box.id })
    }
  }

  const addInsideBox = async () => {
    if (!thereIsComponentRef.current && settingsIsShowRef.current) {
      const newBox = await createBox.mutateAsync({
        boxID: box.id,
        positionType: 'Relative',
        order: (boxesRef.current ? boxesRef.current.length + 1 : 100) + 1,
        mode: activeResponsiveModeCenter.getResponsiveMode(),
      })
    }
  }

  useHotkeys([
    ['mod+V', onPasteBox],
    ['mod+C', onCopyBox],
    ['backspace', onDeleteKeyBox],
    ['enter', addInsideBox],
    [
      'b',
      () => {
        if (settingsIsShowRef.current) {
          if (backgroundEditIsShow) {
            setBackgroundEditIsShow(false)
          } else {
            if (settingsIsShow) setBackgroundEditIsShow(true)
          }
        }
      },
    ],
    [
      'h',
      () => {
        if (settingsIsShowRef.current) boxHidden.setValue(!boxHidden.valueRef.current)
      },
    ],
  ])

  const upLayerSetTimeout = useRef<undefined | number | NodeJS.Timeout>()
  const [upLayerForCheckDoubleClickIsHide, setUpLayerForCheckDoubleClickIsHide, upLayerForCheckDoubleClickIsHideRef] =
    useStateRef(false)

  useEffect(() => {
    if (upLayerForCheckDoubleClickIsHideRef.current) {
      const interval = setTimeout(() => {
        setUpLayerForCheckDoubleClickIsHide(false)
      }, 300)
      upLayerSetTimeout.current = interval
    }
  }, [upLayerForCheckDoubleClickIsHide])

  // const [resposiveModeIsDrag, setResposiveModeIsDrag] = useState(activeResponsiveModeCenter.getIsDrag())

  // useEffect(() => {
  //   const { unlisten } = activeResponsiveModeCenter.subscribe({
  //     callbackIsDrag: ({ isDrag }) => {
  //       setResposiveModeIsDrag(isDrag)
  //     },
  //     callback: () => {},
  //   })
  //   return () => {
  //     unlisten()
  //   }
  // }, [])

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
      // delay: 100,
      // tolerance: 100,
    },
  })

  const sensors = useSensors(mouseSensor)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const setFocusBoxIDAndFocusBoxBlockID = () => {
    if (typeof localStorage.getItem('somethingIsDrag') !== 'string') {
      boxActiveIDCenter.setActiveID({ boxID: box.id })
      // setFocusBoxID(box.id)
      // setFocusBoxBlockID(boxBlockID)
    }

    if (upLayerSetTimeout.current) {
      clearTimeout(upLayerSetTimeout.current)
    }
  }

  const [thereIsComponent, setThereIsComponent, thereIsComponentRef] = useStateRef(
    !!(
      box.textComponent ||
      box.imageComponent ||
      box.formComponent ||
      box.quizComponent ||
      box.videoComponent ||
      box.faqComponent
    )
  )

  useEffect(() => {
    setThereIsComponent(
      !!(
        box.textComponent ||
        box.imageComponent ||
        box.formComponent ||
        box.quizComponent ||
        box.videoComponent ||
        box.faqComponent
      )
    )
  }, [box.textComponent, box.imageComponent, box.formComponent, box.quizComponent, box.videoComponent, box.faqComponent])

  const [chooseSectionOpened, setChooseSectionOpened] = useState(false)
  const [chooseURLOpened, setChooseURLOpened] = useState(false)
  const [chooseFormQuizOpened, setChooseFormQuizOpened] = useState(false)
  const deleteLink = trpc.useMutation('user.deleteLink', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateLink = trpc.useMutation('user.updateLink', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createLink = trpc.useMutation('user.createLink', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const formCreateUrlLink = useForm({
    initialValues: {
      url: link?.url ? link.url : '',
    },
  })

  useEffect(() => {
    formCreateUrlLink.setFieldValue('url', `${link?.url ? link.url : ''}`)
  }, [link?.url])

  const linkIDs = {
    boxID: box.id,
  }

  const boxIsOverflowHidden = useResponsiveProperty({
    resposiveMode: {
      desktop: box.overflowHiddenMobileResponsiveMode,
      tablet: box.overflowHiddenTabletResponsiveMode,
      mobile: box.overflowHiddenDesktopResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.isOverflowHidden,
      tablet: tabletBoxSettings.isOverflowHidden,
      mobile: mobileBoxSettings.isOverflowHidden,
    },
    propertyName: 'Обрезан',

    onValueUpdated: async ({ responsiveMode, value }) => {},
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {},
  })

  const { classes } = useMainStyles()

  const boxesNode = useMemo(() => {
    return boxes.map((boxLoop, index) => (
      <BoxSyncMemo
        index={index}
        parentIsSlider={box.isSlider}
        key={boxLoop.id}
        parentBoxId={box.id}
        boxesLength={boxes?.length}
        onAddBox={({ box }) => {
          // setBoxes(boxes => {
          //   return Array.isArray(boxes) ? [...boxes, box] : boxes
          // })
        }}
        onDeleteBox={async ({ id }) => {
          await deleteBox.mutate({
            boxID: id,
          })
          setBoxes(boxes.filter(box => box.id !== id))
          boxActiveIDCenter.setActiveID({ boxID: undefined })
          // setBoxes(boxes => (Array.isArray(boxes) ? boxes.filter(box => box.id !== id) : boxes))
        }}
        getParentBoxWidth={() => {
          return boxInsideRef.current ? boxInsideRef.current.offsetWidth : 0
        }}
        getParentBoxHeight={() => {
          return positionAbsoluteBoxRef.current ? positionAbsoluteBoxRef.current.offsetHeight : 0
        }}
        // boxBlockID={boxBlockID}
        selectID={boxLoop.selectID}
        modelID={boxLoop.id}
      />
    ))
  }, [boxes, box.isSlider]) // boxBlockID, width

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
  })

  useEffect(() => {
    if (!boxIsActive) setMenuIsOpen(false)
  }, [boxIsActive])

  const [createFormBlockOpened, setCreateFormBlockOpened] = useState(false)
  const [createQuizBlockOpened, setCreateQuizBlockOpened] = useState(false)

  const [formsOpened, setFormsOpened] = useState(false)
  const [quizzesOpened, setQuizzesOpened] = useState(false)

  const borderRadiusString = `${boxBorderRadius.viewValue.LeftTop || 0}px ${boxBorderRadius.viewValue.RightTop || 0}px ${
    boxBorderRadius.viewValue.RightBottom || 0
  }px ${boxBorderRadius.viewValue.LeftBottom || 0}px`

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (over) {
        if (active.id !== over.id) {
          if (boxes) {
            const oldIndex = boxes.findIndex(box => box.id == active.id)
            const newIndex = boxes.findIndex(box => box.id == over.id)

            if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
              const updatedBoxes = arrayMove(boxes, oldIndex, newIndex)

              setBoxes(updatedBoxes)

              updateBoxOrder.mutate({
                parentBoxID: box.id,
                order: updatedBoxes.map((box, index) => ({ order: index, id: box.id })),
              })

              // updatedBoxes.map((box, index) => {
              //   updateBoxSettings.mutate({
              //     boxID: box.id,
              //     order: index,
              //     screenType: 'Desktop',
              //   })
              // })
            }
          }
        }
      }
    },
    [boxes]
  )

  console.log('boxsync render')

  return (
    <div
      id={`box-id-${box.id}`}
      onContextMenu={
        // boxesPositionIsHover
        //   ?
        //   : undefined
        event => {
          event.stopPropagation()
          event.preventDefault()
          setMenuIsOpen(menuIsOpen => !menuIsOpen)

          setFocusBoxIDAndFocusBoxBlockID()
        }
      }
      ref={setNodeRef}
      style={{
        willChange:
          'margin, paddingTop, paddingRight, paddingBottom, paddingLeft, display, opacity, width, minHeight, backgroundColor, borderRadius, boxShadow, borderTop, borderRight, borderBottom, borderLeft',
        maxWidth: boxPostionType.viewValue.positionType == 'Relative' ? '100%' : undefined,
        position: boxPostionType.viewValue.positionType == 'Relative' ? 'relative' : 'absolute',
        left: boxPostionType.viewValue.positionType == 'Relative' ? `${boxPostionType.viewValue.positionX}px` : `50%`,
        top: boxPostionType.viewValue.positionType == 'Relative' ? `${boxPostionType.viewValue.positionY}px` : `0%`,
        transform:
          boxPostionType.viewValue.positionType == 'Relative'
            ? undefined
            : `translate3d(${boxPostionType.viewValue.positionX || 0}px, ${boxPostionType.viewValue.positionY || 0}px, 0)`,

        margin: `${boxMargin.viewValue.top || 0}px ${boxMargin.viewValue.right || 0}px ${boxMargin.viewValue.bottom || 0}px ${
          boxMargin.viewValue.left || 0
        }px`,
        paddingTop: `${boxPadding.viewValue.top || 0}px`,
        paddingRight: `${boxPadding.viewValue.right || 0}px`,
        paddingBottom: `${boxPadding.viewValue.bottom || 0}px`,
        paddingLeft: `${boxPadding.viewValue.left || 0}px`,

        zIndex: index,

        display: boxHidden.value ? 'none' : 'flex',
        width: boxWidth.viewValue ? `${boxWidth.viewValue}${transformBoxWidth(boxWidthType.viewValue)}` : 'auto',
        minWidth: parentIsSlider
          ? boxWidth.viewValue
            ? `${boxWidth.viewValue}${transformBoxWidth(boxWidthType.viewValue)}`
            : 'auto'
          : undefined,
        minHeight: `${boxHeight.viewValue ? `${boxHeight.viewValue}${transformBoxWidth(boxHeightType.viewValue)}` : 'auto'}`,
        // backgroundColor: `${boxBackgroundViewColor?.value || 'transparent'}`,
        borderRadius: borderRadiusString,
        boxShadow:
          boxShadowData && boxShadowColorData
            ? `${boxShadowData.xShift}px ${boxShadowData.yShift}px ${boxShadowData.blur}px ${boxShadowData.spread}px ${boxShadowColorData.value}`
            : undefined,
        border: boxBorderData && boxBorderColorData ? `${boxBorderData.top}px solid ${boxBorderColorData.value}` : undefined,
        overflow: boxIsOverflowHidden.viewValue ? 'hidden' : undefined,
        ...styleDrag,
      }}
      onClick={
        boxesPositionIsHover
          ? event => {
              // location.hash = `#layer-id-${box.id}`
              event.stopPropagation()

              setMenuIsOpen(false)

              setFocusBoxIDAndFocusBoxBlockID()

              const layer = document.getElementById(`layer-id-${box.id}`)
              if (layer) {
                layer.scrollIntoView({ block: 'nearest', inline: 'nearest' })
              }
            }
          : undefined
      }
    >
      {quizzesOpened ? (
        <Modal transitionDuration={0} size="xl" opened={quizzesOpened} onClose={() => setQuizzesOpened(false)}>
          <SiteQuizzesSync
            isModal={true}
            onClickChooseButton={async ({ quizID }) => {
              await createQuizComponent.mutate({
                boxID: box.id,
                quizID,
              })
              setQuizzesOpened(false)
            }}
          />
        </Modal>
      ) : null}

      {chooseURLOpened ? (
        <Modal withinPortal transitionDuration={0} opened={chooseURLOpened} onClose={() => setChooseURLOpened(false)}>
          <form
            onSubmit={formCreateUrlLink.onSubmit(async values => {
              // createLink.mutate({
              //   url: values.url,
              //   ...linkIDs,
              // })
              setChooseURLOpened(false)
            })}
          >
            <TextInput mb="xs" required placeholder="url" {...formCreateUrlLink.getInputProps('url')} />

            <Group position="right">
              <Button loading={createLink.isLoading} type="submit">
                Создать
              </Button>
            </Group>
          </form>
        </Modal>
      ) : null}
      {chooseFormQuizOpened ? (
        <Modal withinPortal transitionDuration={0} opened={chooseFormQuizOpened} onClose={() => setChooseFormQuizOpened(false)}>
          {/* {site.popupsV1.map(popup => (
            <Button
              key={popup.id}
              compact
              fullWidth
              loading={createLink.isLoading}
              onClick={() =>
                createLink.mutate({
                  popupID: popup.id,
                  ...linkIDs,
                })
              }
            >
              {popup.quiz ? `Квиз: ${popup.quiz.title}` : ''}
              {popup.form ? `Форма: ${popup.form.title}` : ''}
            </Button>
          ))} */}
        </Modal>
      ) : null}
      {chooseSectionOpened ? (
        <Drawer
          transitionDuration={0}
          overlayOpacity={0.55}
          overlayBlur={3}
          padding={'md'}
          size="calc(100vh - 50px)"
          lockScroll={true}
          position="bottom"
          opened={chooseSectionOpened}
          onClose={() => setChooseSectionOpened(false)}
          title="Выбрать блок для ссылки"
          css={{
            overflowY: 'scroll',
          }}
        >
          {/* <ChooseSectionForLinkSync boxID={box.id} link={box.link} /> */}
        </Drawer>
      ) : null}

      {(boxesPositionIsHover || boxIsActive) &&
      (box.isSlider || box.isSliderShell || box.isSliderArrowNext || box.isSliderArrowPrev) ? (
        <Tooltip
          opened={true}
          label={`${box.isSlider ? 'Cлайды' : ''}${box.isSliderShell ? 'Слайдер' : ''}${
            box.isSliderArrowNext ? 'Слайдер "Дальше"' : ''
          }${box.isSliderArrowPrev ? 'Слайдер "Назад"' : ''}`}
        >
          <div
            css={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          ></div>
        </Tooltip>
      ) : null}

      {createFormBlockOpened ? (
        <Modal
          centered
          transitionDuration={0}
          opened={createFormBlockOpened}
          onClose={() => setCreateFormBlockOpened(false)}
          title="Прикрепить форму к блоку"
        >
          {/* <form>
          <Radio.Group required orientation="vertical" label="Выбери форму по заголовку">
            {site.formsV1.map(form => (
              <Radio
                onClick={() => {
                  createFormComponent.mutate({
                    boxID: box.id,
                    formID: form.id,
                  })
                  setCreateFormBlockOpened(false)
                }}
                key={form.id}
                value={form.id}
                label={form.title}
              />
            ))}
          </Radio.Group>
        </form> */}
        </Modal>
      ) : null}

      {createQuizBlockOpened ? (
        <Modal
          centered
          transitionDuration={0}
          opened={createQuizBlockOpened}
          onClose={() => setCreateQuizBlockOpened(false)}
          title="Прикрепить квиз к блоку"
        >
          {/* <form>
          <Radio.Group required orientation="vertical" label="Выбери квиз по заголовку">
            {site.quizzesV1.map(quiz => (
              <Radio
                onClick={() => {
                  createQuizComponent.mutate({
                    boxID: box.id,
                    quizID: quiz.id,
                  })
                  setCreateQuizBlockOpened(false)
                }}
                key={quiz.id}
                value={quiz.id}
                label={quiz.title}
              />
            ))}
          </Radio.Group>
        </form> */}
        </Modal>
      ) : null}

      {boxIsActive ? ( // || boxesPositionIsHover
        <Menu
          width={250}
          opened={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          closeOnItemClick={false}
          closeOnClickOutside={false}
          transitionDuration={0}
          withinPortal={true}
          shadow="md"
        >
          <Menu.Target>
            <div
              css={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                height: 1,
                pointerEvents: 'none',
              }}
            ></div>
          </Menu.Target>

          <Menu.Dropdown
            className={classes.hideScrollbar}
            css={{
              maxHeight: 400,
              overflowY: 'auto',
            }}
          >
            <div
              onClick={event => {
                event.stopPropagation()
                // setMenuIsOpen(false)
              }}
            >
              {thereIsComponent || box.boxes?.length ? (
                <>
                  {box.boxes?.length ? (
                    <>
                      <Menu.Item
                        onClick={async () => {
                          setMenuIsOpen(false)
                          addInsideBox()
                        }}
                        icon={<IconPlus color={colors.blue} size={14} />}
                        rightSection={<Kbd>Enter</Kbd>}
                      >
                        Добавить слой
                      </Menu.Item>
                      <Menu.Divider />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Menu.Label>Добавить</Menu.Label>
                  <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const newBox = await createBox.mutateAsync({
                        boxID: box.id,
                        positionType: 'Absolute',
                        order: 1,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (newBox) setBoxes(boxes => (Array.isArray(boxes) ? [...boxes, newBox] : boxes))
                    }}
                    icon={<IconBox color={colors.blue} size={14} />}
                  >
                    Слой свободный
                  </Menu.Item>
                  <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const newBox = await createBox.mutateAsync({
                        boxID: box.id,
                        positionType: 'Relative',
                        order: 1,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (newBox) setBoxes(boxes => (Array.isArray(boxes) ? [...boxes, newBox] : boxes))
                    }}
                    icon={<IconBox color={colors.blue} size={14} />}
                  >
                    Слой вложенный
                  </Menu.Item>
                  <Menu.Label>Сделать</Menu.Label>
                  {/* <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const updatedBox = await pinTextComponentToBox.mutateAsync({
                        boxID: box.id,
                        type: 'headline',
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (updatedBox?.textComponent) setTextComponent(updatedBox?.textComponent)
                    }}
                    icon={<IconHeading color={colors.blue} size={14} />}
                  >
                    Заголовком
                  </Menu.Item> */}
                  <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const updatedBox = await pinTextComponentToBox.mutateAsync({
                        boxID: box.id,
                        type: 'paragraph',
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (updatedBox?.textComponent) setTextComponent(updatedBox?.textComponent)
                    }}
                    icon={<IconTextSize color={colors.blue} size={14} />}
                  >
                    Текстом
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setMenuIsOpen(false)
                      setImagesOpened(true)
                    }}
                    icon={<IconPhoto color={colors.blue} size={14} />}
                  >
                    Картинкой
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTransform color={colors.blue} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)
                      setQuizzesOpened(true)
                    }}
                  >
                    Квизом
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconForms color={colors.blue} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)
                      setFormsOpened(true)
                    }}
                  >
                    Формой
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconVideoPlus color={'red  '} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)

                      createVideoComponent.mutate({
                        boxID: box.id,
                        videoURL: '',
                      })
                    }}
                  >
                    YouTube Видео
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconQuestionMark color={colors.blue} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)
                      createFaqComponent.mutate({ boxID: box.id })
                    }}
                  >
                    {' '}
                    Вопрос-ответом
                  </Menu.Item>
                  <Menu.Divider />
                </>
              )}

              <Menu.Label>
                {link ? (
                  <>
                    Ссылка{' '}
                    {link.popup ? (
                      <>
                        на всплывашку
                        {/* {box.link.popup.quiz ? `с квизом "${box.link.popup.quiz.title}"` : ''}
                        {box.link.popup.form ? `с формой "${box.link.popup.form.title}"` : ''} */}
                        <TextInputWithUpdate
                          value={typeof link.popupData == 'string' ? link.popupData : ''}
                          size="xs"
                          onChange={value => {
                            if (link.id) {
                              updateLink.mutate({
                                linkID: link.id,
                                popupData: value,
                              })
                            }
                          }}
                          unstyled={true}
                          placeholder={'Данные для попапа в форму заявки'}
                        />
                      </>
                    ) : null}
                    {link.url ? <> на другой сайт: {link.url}</> : ''}
                    {link.page ? (
                      <>
                        {' '}
                        на cтраницу:
                        {/* {link.page.pathname} */}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>Добавить ссылку на:</>
                )}
              </Menu.Label>

              {link ? (
                <>
                  {link.page ? (
                    <Menu.Item
                      onClick={() => {
                        setMenuIsOpen(false)
                        setChooseSectionOpened(true)
                      }}
                    >
                      Показать выбранный блок
                    </Menu.Item>
                  ) : null}
                  {link.url ? (
                    <Menu.Item
                      onClick={() => {
                        setMenuIsOpen(false)
                        setChooseURLOpened(true)
                      }}
                    >
                      Отредактировать ссылку
                    </Menu.Item>
                  ) : null}
                  <Menu.Item
                    color={'red'}
                    // loading={deleteLink.isLoading}
                    onClick={() => {
                      // event.stopPropagation()
                      if (link)
                        deleteLink.mutate({
                          linkID: link.id,
                        })
                    }}
                  >
                    Удалить ссылку
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item
                    onClick={() => {
                      setMenuIsOpen(false)
                      setChooseSectionOpened(true)
                    }}
                  >
                    Блок или страницу
                  </Menu.Item>
                  <Popover withinPortal position="right-start" width={'auto'} shadow="md">
                    <Popover.Target>
                      <Menu.Item>Всплывашку</Menu.Item>
                    </Popover.Target>
                    <Popover.Dropdown
                      css={{
                        padding: '8px 12px',
                        borderRadius: 2,
                      }}
                    >
                      <ChooseSitePopupsSync
                        onChoosePopup={({ popupID }) => {
                          // createLink.mutate({
                          //   popupID,
                          //   ...linkIDs,
                          // })
                        }}
                      />
                    </Popover.Dropdown>
                  </Popover>
                  <Menu.Item
                    onClick={() => {
                      setMenuIsOpen(false)
                      setChooseURLOpened(true)
                    }}
                  >
                    Другой сайт
                  </Menu.Item>
                </>
              )}

              <Menu.Divider />

              <Menu.Label>Действия</Menu.Label>
              <Menu.Item
                onClick={() => {
                  setMenuIsOpen(false)
                  onCopyBox()
                }}
                rightSection={<Kbd>Ctr+C</Kbd>}
              >
                Скопировать блок
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setMenuIsOpen(false)
                  onPasteBox()
                }}
                rightSection={<Kbd>Ctr+V</Kbd>}
              >
                Вставить блок
              </Menu.Item>

              <Menu.Item
                color={'red'}
                onClick={() => {
                  setMenuIsOpen(false)
                  onDeleteKeyBox()
                }}
                rightSection={<Kbd>Backspace</Kbd>}
              >
                Удалить блок
              </Menu.Item>

              <Menu.Divider />

              {box.isClosePopup ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isClosePopup: false,
                    })
                  }}
                  icon={<IconX color={colors.blue} size={14} />}
                >
                  Убрать кнопку закрытия
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isClosePopup: true,
                    })
                  }}
                  icon={<IconX color={colors.blue} size={14} />}
                >
                  Сделать кнопкой закрытия
                </Menu.Item>
              )}
              <Menu.Divider />

              {thereIsComponent ? null : (
                <>
                  <Menu.Label>Слайдер</Menu.Label>
                  {box.isSliderShell ? (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSliderShell: false,
                        })
                      }}
                      icon={<IconArrowLeftRight color={colors.blue} size={14} />}
                    >
                      Убрать слайдер
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSliderShell: true,
                        })
                      }}
                      icon={<IconArrowLeftRight color={colors.blue} size={14} />}
                    >
                      Сделать слайдером
                    </Menu.Item>
                  )}
                  {box.isSlider ? (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSlider: false,
                        })
                      }}
                      icon={<IconBuildingCarousel color={colors.blue} size={14} />}
                    >
                      Убрать слайды
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSlider: true,
                        })
                      }}
                      icon={<IconBuildingCarousel color={colors.blue} size={14} />}
                    >
                      Сделать слайдами
                    </Menu.Item>
                  )}
                </>
              )}

              {box.isSliderArrowNext ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowNext: false,
                    })
                  }}
                  icon={<IconArrowMoveRight color={colors.blue} size={14} />}
                >
                  Убрать кнопку "Дальше"
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowNext: true,
                    })
                  }}
                  icon={<IconArrowMoveRight color={colors.blue} size={14} />}
                >
                  Сделать кнопкой "Дальше"
                </Menu.Item>
              )}
              {box.isSliderArrowPrev ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowPrev: false,
                    })
                  }}
                  icon={<IconArrowMoveLeft color={colors.blue} size={14} />}
                >
                  Убрать кнопку "Назад"
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowPrev: true,
                    })
                  }}
                  icon={<IconArrowMoveLeft color={colors.blue} size={14} />}
                >
                  Сделать кнопкой "Назад"
                </Menu.Item>
              )}

              {/* <Menu.Label>Поле ввода</Menu.Label>

              {box.isInput ? (
                <Menu.Item
                  onClick={() => {
                    updateBox.mutate({
                      boxID: box.id,
                      isInput: false,
                    })
                  }}
                  icon={<IconForms color={colors.blue} size={14} />}
                >
                  Убрать поле
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    updateBox.mutate({
                      boxID: box.id,
                      isInput: true,
                    })
                  }}
                  icon={<IconForms color={colors.blue} size={14} />}
                >
                  Сделать полем
                </Menu.Item>
              )} */}
            </div>
          </Menu.Dropdown>
        </Menu>
      ) : null}

      <div
        ref={setNodeRefDrop}
        style={{
          width: '100%',
          display: 'flex',
        }}
      >
        <div
          ref={boxRef}
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          {boxWidth.isDrag || boxHeight.isDrag ? (
            <div
              style={{
                willChange: 'opacity',
                position: 'absolute',
                left: '50%',
                top: 'calc(100% + 10px)',
                display: 'flex',
                alignContent: 'SpaceAround',
                justifyContent: 'SpaceAround',
                transform: `translate(-50%, 0)`,
                border: '1px solid rgb(0, 134, 255, 0.5)',
                transition: '0.3s',
                background: 'white',
                padding: 2,
                zIndex: 10,
                pointerEvents: 'none',
                fontSize: 10,
                color: 'rgb(0, 134, 255)',
              }}
            >
              {boxWidth.isDrag ? `ш: ${boxWidth.viewValue}${transformBoxWidth(boxWidthType.viewValue)}` : null}
              {boxHeight.isDrag ? ` в: ${boxHeight.viewValue}${transformBoxWidth(boxHeightType.viewValue)}` : null}
            </div>
          ) : null}
          <div
            style={{
              willChange: 'gap, alignItems, alignContent, justifyContent, flexDirection, pointerEvents, opacity',
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              minHeight: '100%',
              zIndex: 2,
              // position: 'relative', // ломает свободные блоки, от паддинга расстояние учитывается - не правильно
              gap: boxGap.viewValue,
              alignItems: transformedVerticalPosition,
              alignContent: transformedVerticalPosition,
              justifyContent: transformedHorizontalPosition,
              flexDirection: boxPosition.value.vector == 'Bottom' ? 'column' : undefined, // | row-reverse | box | box-reverse;
              pointerEvents: backgroundEditIsShow ? 'none' : undefined,
              opacity: backgroundEditIsShow ? 0.5 : undefined,
            }}
            ref={boxInsideRef}
          >
            {boxes && boxes.length ? undefined : (
              <>
                {textComponent ? (
                  <EditorTextComponentSyncMemo
                    active={boxIsActive}
                    canEdit={upLayerForCheckDoubleClickIsHide}
                    textComponentData={{
                      id: textComponent.id,
                      selectID: textComponent.selectID,
                    }}
                  />
                ) : box.imageComponent ? (
                  <>
                    <ImagePreviewSyncMemo
                      borderRadius={borderRadiusString}
                      onDoubleClick={() => setImageComponentEditOpened(true)}
                      image={{
                        id: box.imageComponent.id,
                        selectID: box.imageComponent.selectID,
                      }}
                    />

                    {boxIsActive && imageComponentEditOpened ? (
                      <Portal>
                        <div
                          title="Редактировать размеры картинки"
                          css={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(255,255,255,0.85)',
                            zIndex: 100,
                          }}
                          onClick={event => {
                            event.stopPropagation()
                            setImageComponentEditOpened(false)
                          }}
                        >
                          <Group
                            css={{
                              position: 'absolute',
                              left: '50%',
                              bottom: 30,
                              transform: 'translateX(-50%)',
                              zIndex: 2,
                            }}
                          >
                            <Paper
                              withBorder
                              radius={1000}
                              shadow="xs"
                              css={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <IconX size={24} color={colors.blue} />
                            </Paper>
                            <Paper
                              onClick={() => {
                                setImagesOpened(true)
                              }}
                              withBorder
                              radius={1000}
                              shadow="xs"
                              css={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <IconRefresh size={24} color={colors.blue} />
                            </Paper>
                          </Group>
                          <div
                            css={{
                              width: '100%',
                              height: '100%',
                              position: 'absolute',
                              left: '50%',
                              top: '50%',
                              transform: 'translate(-50%, -50%)',
                              display: 'flex',
                              alignContent: 'center',
                              alignItems: 'center',
                              justifyContent: 'center',
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <div
                              css={{
                                // padding: 12,
                                maxWidth: '100%',
                                maxHeight: '100%',
                                padding: 12,
                              }}
                              onClick={event => {
                                event.stopPropagation()
                              }}
                            >
                              <EditImageSync
                                image={{
                                  id: box.imageComponent.id,
                                  selectID: box.imageComponent.selectID,
                                }}
                                onChangeShellSize={({ width, height }) => {
                                  if (box.imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: box.imageComponent.id,
                                      shellWidth: width,
                                      shellHeight: height,
                                    })
                                }}
                                onUpdatePosition={({ x, y }) => {
                                  if (box.imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: box.imageComponent.id,
                                      positionX: x,
                                      positionY: y,
                                    })
                                }}
                                onUpdateSize={({ width, height }) => {
                                  if (box.imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: box.imageComponent.id,
                                      width: width,
                                      height: height,
                                    })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Portal>
                    ) : null}
                  </>
                ) : box.formComponent ? (
                  <></>
                ) : box.quizComponent ? (
                  <QuizComponentSync modelID={box.quizComponent.id} selectID={box.quizComponent.selectID} />
                ) : box.videoComponent ? (
                  <VideoComponentSync
                    borderRadius={borderRadiusString}
                    modelID={box.videoComponent.id}
                    selectID={box.videoComponent.selectID}
                  />
                ) : box.faqComponent ? (
                  <FaqComponentSync modelID={box.faqComponent.id} selectID={box.faqComponent.selectID} />
                ) : undefined}
              </>
            )}

            {upLayerForCheckDoubleClickIsHide ? undefined : (
              <div
                ref={positionAbsoluteBoxRef}
                onClick={() => {
                  setUpLayerForCheckDoubleClickIsHide(true)
                }}
                style={{
                  // zIndex: 0,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  // pointerEvents: 'none',
                }}
              >
                {boxesPositionIsHover && (box.imageComponent || box.formComponent || box.quizComponent) ? (
                  <div
                    css={{
                      background: boxesPositionIsHover ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0)',
                      // zIndex: 0,
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      pointerEvents: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      fontSize: 14,
                      lineHeight: '14px',
                      borderRadius: borderRadiusString,
                    }}
                  >
                    <p
                      css={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: 4,
                      }}
                    >
                      Нажмите два раза чтобы отредактировать
                    </p>
                  </div>
                ) : null}

                {boxPostionType.viewValue.positionType == 'Relative' ? (
                  parentBoxId && boxesPositionIsHover ? (
                    <div
                      {...listeners}
                      {...attributes}
                      css={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // background: 'purple',
                        top: 0,
                        left: 0,
                        cursor: 'grab',
                        touchAction: 'none',
                      }}
                    />
                  ) : (
                    <></>
                  )
                ) : true ? ( // boxesPositionIsHover
                  <DragOffsetPosition
                    limited={true}
                    onChangePosition={({ offsetPosition }) => {
                      const pixelBoxDifferentPositionYWithPrevPixel =
                        (boxPostionType.valueRef.current.positionY || 0) + Math.ceil(offsetPosition.y)
                      const pixelBoxDifferentPositionXWithPrevPixel =
                        (boxPostionType.valueRef.current.positionX || 0) + Math.ceil(offsetPosition.x)

                      boxPostionType.setTemporary({
                        ...boxPostionType.valueRef.current,
                        positionY: pixelBoxDifferentPositionYWithPrevPixel,
                        positionX: pixelBoxDifferentPositionXWithPrevPixel,
                      })

                      return {
                        resultSizes: {
                          y: pixelBoxDifferentPositionYWithPrevPixel,
                          x: pixelBoxDifferentPositionXWithPrevPixel,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number' && typeof resultPosition.x == 'number')
                        boxPostionType.setValue({
                          ...boxPostionType.valueRef.current,
                          positionX: resultPosition.x,
                          positionY: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // border: '1px solid #0086ff',
                      right: 0,
                      top: 0,
                      // transform: 'translate(50%, -50%) rotate(45deg)',
                      cursor: 'move',
                      // background: boxPostionType.isDrag ? '#0086ff' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxPostionType.setIsDrag(is)
                    }}
                  ></DragOffsetPosition>
                ) : null}
              </div>
            )}

            {boxes ? (
              box.isSlider ? (
                <div
                  css={{
                    display: 'flex',
                    width: '100%',
                    overflow: boxIsOverflowHidden.viewValue ? 'hidden' : undefined,
                  }}
                  ref={emblaRef}
                >
                  <div
                    css={{
                      display: 'flex',
                      width: '100%',
                      // height: 'auto',
                      alignItems: 'flex-start',
                      gap: boxGap.viewValue,
                    }}
                  >
                    {boxesNode}
                  </div>
                </div>
              ) : boxes.length ? (
                <>
                  <DndContext sensors={sensors} onDragEnd={onDragEnd}>
                    {boxesNode}
                  </DndContext>
                </>
              ) : (
                boxesNode
              )
            ) : undefined}
          </div>

          {/* <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              zIndex: 2,
              left: -1,
              top: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              zIndex: 2,
              right: -1,
              top: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              height: 1,
              width: '100%',
              zIndex: 2,
              right: 0,
              top: -1,
            }}
          />
          <div
            css={{
              position: 'absolute',
              height: 1,
              width: '100%',
              zIndex: 2,
              right: 0,
              bottom: -1,
            }}
          /> */}

          {boxIsActive || boxesPositionIsHover ? (
            <div
              css={{
                willChange: 'opacity, pointerEvents',
                zIndex: 3,
              }}
              onClick={event => {
                // event.stopPropagation()
              }}
            >
              {boxMargin.viewValue.top ? (
                <div
                  css={{
                    willChange: 'height, background',
                    position: 'absolute',
                    width: '100%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    left: '50%',
                    bottom: '100%',
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                  style={{
                    height: `calc(${boxMargin.viewValue.top}px)`,
                    background: marginColor,
                  }}
                />
              ) : null}
              {boxMargin.viewValue.bottom ? (
                <div
                  css={{
                    position: 'absolute',
                    width: '100%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    left: '50%',
                    top: '100%',
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                  style={{
                    height: `calc(${boxMargin.viewValue.bottom}px)`,
                    background: marginColor,
                  }}
                />
              ) : null}
              {boxMargin.viewValue.right ? (
                <div
                  style={{
                    position: 'absolute',
                    // transform: 'translate(0, -50%)',
                    width: `${boxMargin.viewValue.right}px`,
                    height: `calc(100% + ${(boxMargin.viewValue.bottom || 0) + (boxMargin.viewValue.top || 0)}px)`,
                    zIndex: 1,
                    left: 'calc(100%)',
                    bottom: `-${boxMargin.viewValue.bottom || 0}px`,
                    background: marginColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxMargin.viewValue.left ? (
                <div
                  style={{
                    position: 'absolute',
                    // transform: 'translate(0, -50%)',
                    width: `${boxMargin.viewValue.left}px`,
                    height: `calc(100% + ${(boxMargin.viewValue.bottom || 0) + (boxMargin.viewValue.top || 0)}px)`,
                    zIndex: 1,
                    right: 'calc(100%)',
                    bottom: `-${boxMargin.viewValue.bottom || 0}px`,
                    background: marginColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}

              {boxPadding.viewValue.right ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `${boxPadding.viewValue.right}px`,
                    height: `100%`,
                    zIndex: 1,
                    right: 0,
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxPadding.viewValue.left ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `${boxPadding.viewValue.left}px`,
                    height: `100%`,
                    zIndex: 1,
                    left: 0,
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    backgroundColor: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxPadding.viewValue.top ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `calc(100% - ${(boxPadding.viewValue.left || 0) + (boxPadding.viewValue.right || 0)}px)`,
                    height: `${boxPadding.viewValue.top}px`,
                    zIndex: 1,
                    left: boxPadding.viewValue.left || 0,
                    top: 0,
                    // transform: 'translate(-50% , 0)',
                    opacity: 0.1,
                    backgroundColor: paddingColor,
                    pointerEvents: 'none',

                    // backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1.41422L1.41418 10H0V8.58575L8.58569 0H10V1.41422Z' fill='%23E9E9E9'/%3E%3C/svg%3E%0A")`,
                    // backgroundAttachment: 'fixed',
                    // backgroundRepeat: 'repeat',
                    // pointerEvents: 'none',
                    // backgroundPosition: 'center',
                  }}
                />
              ) : null}
              {boxPadding.viewValue.bottom ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `calc(100% - ${(boxPadding.viewValue.left || 0) + (boxPadding.viewValue.right || 0)}px)`,
                    height: `${boxPadding.viewValue.bottom}px`,
                    zIndex: 1,
                    left: boxPadding.viewValue.left || 0,
                    bottom: 0,
                    // transform: 'translate(-50% , 0)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
            </div>
          ) : null}

          {boxBackground.viewValue ? (
            <BoxBackgroundSyncMemo
              modelID={boxBackground.viewValue.id}
              selectID={boxBackground.viewValue.selectID}
              backgroundEditIsShow={backgroundEditIsShow}
              boxBorderRadius={borderRadiusString}
            />
          ) : null}

          {/* <div
            css={{
              willChange: 'opacity, border, borderRadius',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 2,
              left: 0,
              top: 0,
              pointerEvents: 'none',
            }}
            style={{
              opacity: lineIsShow ? 1 : 0,
              border: `1px dashed ${
                isOver ? 'purple' : boxesPositionIsHover || settingsIsShow ? '#0086ff' : 'rgba(49, 149, 217, 0.2)'
              }`,
              // borderRadius: boxBorderRadius.viewValue || 0,
            }}
          /> */}

          <div
            style={{
              willChange: 'opacity, border, borderRadius',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 2,
              left: 0,
              top: 0,
              pointerEvents: 'none',

              opacity: hideLine ? 0 : undefined, // boxesPositionIsHover || settingsIsShow ? 1 : lineIsShow ? 1 : 0,
              border: `1px ${boxesPositionIsHover || settingsIsShow ? 'solid' : 'dashed'} ${
                isOver ? 'purple' : boxesPositionIsHover || settingsIsShow ? '#0086ff' : 'rgba(49, 149, 217, 0.2)'
              }`,
              borderRadius: borderRadiusString,
            }}
            ref={fullBoxAreaRef}
          />

          {boxIsActive ? (
            <div
              css={{
                willChange: 'opacity, pointerEvents',
                zIndex: 4,
              }}
              style={{}}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <ImagesSync
                opened={imagesOpened}
                onClose={() => setImagesOpened(false)}
                imageChoosen={async ({ imageID, imageName }) => {
                  if (box.imageComponent) {
                    await changeImageComponentImage.mutateAsync({
                      imageComponentID: box.imageComponent.id,
                      imageID: imageID,
                    })
                  } else {
                    await createImageComponent.mutateAsync({
                      boxID: box.id,
                      imageID: imageID,
                    })
                  }
                  setImagesOpened(false)
                  // setImageComponentEditOpened(true)
                }}
              />

              {chooseURLVideoOpened ? (
                <Modal transitionDuration={0} opened={chooseURLVideoOpened} onClose={() => setChooseURLVideoOpened(false)}>
                  <form onSubmit={formCreateUrlVideo.onSubmit(async values => {})}>
                    <TextInput mb="xs" required placeholder="url" {...formCreateUrlVideo.getInputProps('url')} />

                    <Group position="right">
                      <Button loading={createVideoComponent.isLoading} type="submit">
                        Создать
                      </Button>
                    </Group>
                  </form>
                </Modal>
              ) : null}

              {/* <div
              onClick={() => {
                onDeleteBox({ id: box.id })
              }}
              css={{
                position: 'absolute',
                width: widthAndHeight,
                height: widthAndHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#ff9797',
                top: 0,
                right: 0,
                transform: 'translate(50%, -50%)',
                cursor: 'not-allowed',
                zIndex: 2,
              }}
            /> */}

              {parentBoxId ? (
                <>
                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      if (
                        boxWidthType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxWidth.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetWidth > boxWidth.valueRef.current
                          : true)
                      ) {
                        boxWidth.setValue(fullBoxAreaRef.current.offsetWidth)
                      }
                      if (
                        boxHeightType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxHeight.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetHeight > boxHeight.valueRef.current
                          : true)
                      ) {
                        if (fullBoxAreaRef.current) boxHeight.setValue(fullBoxAreaRef.current.offsetHeight)
                      }
                    }}
                    onChangePosition={({ offsetPosition }) => {
                      let pixelBoxDifferentPositionYWithPrevPixel = undefined
                      if (boxPostionType.viewValue.positionType == 'Absolute') {
                        pixelBoxDifferentPositionYWithPrevPixel =
                          (boxPostionType.valueRef.current.positionY || 0) + Math.ceil(offsetPosition.y)

                        boxPostionType.setTemporary({
                          ...boxPostionType.valueRef.current,
                          positionY: pixelBoxDifferentPositionYWithPrevPixel,
                        })
                      }
                      let boxDifferentWidth = 0

                      if (boxWidthType.valueRef.current == 'Percent') {
                        const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                        const percentBoxDifferentWidthWithPrevPercent =
                          (boxWidth.valueRef.current || 0) + percentBoxDifferentWidth
                        const roughPercentBoxDifferentWidth =
                          percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                        boxDifferentWidth = roughPercentBoxDifferentWidth > 100 ? 100 : roughPercentBoxDifferentWidth
                      }
                      if (boxWidthType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) + offsetPosition.x
                        const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                        boxDifferentWidth = roughPixelBoxDifferentWidth
                      }

                      boxWidth.setTemporary(boxDifferentWidth)

                      let boxDifferentHeight = 0

                      if (boxHeightType.valueRef.current == 'Percent') {
                        const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                        const percentBoxDifferentHeightWithPrevPercent =
                          (boxHeight.valueRef.current || 0) - percentBoxDifferentHeight
                        const roughPercentBoxDifferentHeight =
                          percentBoxDifferentHeightWithPrevPercent - (percentBoxDifferentHeightWithPrevPercent % 1)
                        boxDifferentHeight = roughPercentBoxDifferentHeight > 100 ? 100 : roughPercentBoxDifferentHeight
                      }
                      if (boxHeightType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) - offsetPosition.y
                        // const roughPixelBoxDifferentHeight =
                        //   pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 10)
                        boxDifferentHeight = pixelBoxDifferentHeightWithPrevPixel
                      }

                      boxHeight.setTemporary(boxDifferentHeight)

                      return {
                        resultSizes: {
                          x: boxDifferentWidth,
                          y: boxDifferentHeight,
                          yT: pixelBoxDifferentPositionYWithPrevPixel,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number') {
                        boxWidth.setValue(resultPosition.x)
                      }
                      if (typeof resultPosition.y == 'number') {
                        boxHeight.setValue(resultPosition.y)
                      }
                      if (typeof resultPosition.yT == 'number') {
                        boxPostionType.setValue({
                          ...boxPostionType.valueRef.current,
                          positionY: resultPosition.yT,
                        })
                      }
                    }}
                    style={{
                      width: widthAndHeight,
                      height: widthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      top: 0,
                      right: 0,
                      transform: 'translate(50%, -50%)',
                      cursor: 'nesw-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      boxWidth.setIsDrag(is)
                      boxHeight.setIsDrag(is)
                    }}
                    onClick={() => {
                      // boxWidth.setValue(null)
                      // boxHeight.setValue(null)
                    }}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      if (
                        boxWidthType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxWidth.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetWidth > boxWidth.valueRef.current
                          : true)
                      ) {
                        boxWidth.setValue(fullBoxAreaRef.current.offsetWidth)
                      }
                      if (
                        boxHeightType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxHeight.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetHeight > boxHeight.valueRef.current
                          : true)
                      ) {
                        if (fullBoxAreaRef.current) boxHeight.setValue(fullBoxAreaRef.current.offsetHeight)
                      }
                    }}
                    onChangePosition={({ offsetPosition }) => {
                      let pixelBoxDifferentPositionXWithPrevPixel = undefined
                      let pixelBoxDifferentPositionYWithPrevPixel = undefined
                      if (boxPostionType.viewValue.positionType == 'Absolute') {
                        pixelBoxDifferentPositionYWithPrevPixel =
                          (boxPostionType.valueRef.current.positionY || 0) + Math.ceil(offsetPosition.y)
                        pixelBoxDifferentPositionXWithPrevPixel =
                          (boxPostionType.valueRef.current.positionX || 0) + Math.ceil(offsetPosition.x)

                        boxPostionType.setTemporary({
                          ...boxPostionType.valueRef.current,
                          positionY: pixelBoxDifferentPositionYWithPrevPixel,
                          positionX: pixelBoxDifferentPositionXWithPrevPixel,
                        })
                      }

                      let boxDifferentWidth = 0

                      if (boxWidthType.valueRef.current == 'Percent') {
                        const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                        const percentBoxDifferentWidthWithPrevPercent =
                          (boxWidth.valueRef.current || 0) - percentBoxDifferentWidth
                        const roughPercentBoxDifferentWidth =
                          percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                        boxDifferentWidth = roughPercentBoxDifferentWidth > 100 ? 100 : roughPercentBoxDifferentWidth
                      }
                      if (boxWidthType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) - offsetPosition.x
                        const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                        boxDifferentWidth = roughPixelBoxDifferentWidth
                      }

                      boxWidth.setTemporary(boxDifferentWidth)

                      let boxDifferentHeight = 0

                      if (boxHeightType.valueRef.current == 'Percent') {
                        const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                        const percentBoxDifferentHeightWithPrevPercent =
                          (boxHeight.valueRef.current || 0) - percentBoxDifferentHeight
                        const roughPercentBoxDifferentHeight =
                          percentBoxDifferentHeightWithPrevPercent - (percentBoxDifferentHeightWithPrevPercent % 1)
                        boxDifferentHeight = roughPercentBoxDifferentHeight > 100 ? 100 : roughPercentBoxDifferentHeight
                      }
                      if (boxHeightType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) - offsetPosition.y
                        // const roughPixelBoxDifferentHeight =
                        //   pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 10)
                        boxDifferentHeight = pixelBoxDifferentHeightWithPrevPixel
                      }

                      boxHeight.setTemporary(boxDifferentHeight)

                      return {
                        resultSizes: {
                          x: boxDifferentWidth,
                          y: boxDifferentHeight,
                          yT: pixelBoxDifferentPositionYWithPrevPixel,
                          xT: pixelBoxDifferentPositionXWithPrevPixel,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number') {
                        boxWidth.setValue(resultPosition.x)
                      }
                      if (typeof resultPosition.y == 'number') {
                        boxHeight.setValue(resultPosition.y)
                      }
                      if (typeof resultPosition.yT == 'number' && typeof resultPosition.xT == 'number') {
                        boxPostionType.setValue({
                          ...boxPostionType.valueRef.current,
                          positionY: resultPosition.yT,
                          positionX: resultPosition.xT,
                        })
                      }
                    }}
                    style={{
                      width: widthAndHeight,
                      height: widthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      top: 0,
                      left: 0,
                      transform: 'translate(-50%, -50%)',
                      cursor: 'nwse-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      boxWidth.setIsDrag(is)
                      boxHeight.setIsDrag(is)
                    }}
                    onClick={() => {
                      // boxWidth.setValue(null)
                      // boxHeight.setValue(null)
                    }}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      if (
                        boxWidthType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxWidth.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetWidth > boxWidth.valueRef.current
                          : true)
                      ) {
                        boxWidth.setValue(fullBoxAreaRef.current.offsetWidth)
                      }
                      if (
                        boxHeightType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxHeight.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetHeight > boxHeight.valueRef.current
                          : true)
                      ) {
                        if (fullBoxAreaRef.current) boxHeight.setValue(fullBoxAreaRef.current.offsetHeight)
                      }
                    }}
                    onChangePosition={({ offsetPosition }) => {
                      let pixelBoxDifferentPositionXWithPrevPixel = undefined
                      if (boxPostionType.viewValue.positionType == 'Absolute') {
                        pixelBoxDifferentPositionXWithPrevPixel =
                          (boxPostionType.valueRef.current.positionX || 0) + Math.ceil(offsetPosition.x)

                        boxPostionType.setTemporary({
                          ...boxPostionType.valueRef.current,
                          positionX: pixelBoxDifferentPositionXWithPrevPixel,
                        })
                      }
                      let boxDifferentWidth = 0

                      if (boxWidthType.valueRef.current == 'Percent') {
                        const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                        const percentBoxDifferentWidthWithPrevPercent =
                          (boxWidth.valueRef.current || 0) - percentBoxDifferentWidth
                        const roughPercentBoxDifferentWidth =
                          percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                        boxDifferentWidth = roughPercentBoxDifferentWidth > 100 ? 100 : roughPercentBoxDifferentWidth
                      }
                      if (boxWidthType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) - offsetPosition.x
                        const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                        boxDifferentWidth = roughPixelBoxDifferentWidth
                      }

                      boxWidth.setTemporary(boxDifferentWidth)

                      let boxDifferentHeight = 0

                      if (boxHeightType.valueRef.current == 'Percent') {
                        const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                        const percentBoxDifferentHeightWithPrevPercent =
                          (boxHeight.valueRef.current || 0) + percentBoxDifferentHeight
                        const roughPercentBoxDifferentHeight =
                          percentBoxDifferentHeightWithPrevPercent - (percentBoxDifferentHeightWithPrevPercent % 1)
                        boxDifferentHeight = roughPercentBoxDifferentHeight > 100 ? 100 : roughPercentBoxDifferentHeight
                      }
                      if (boxHeightType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) + offsetPosition.y
                        // const roughPixelBoxDifferentHeight =
                        //   pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 10)
                        boxDifferentHeight = pixelBoxDifferentHeightWithPrevPixel
                      }

                      boxHeight.setTemporary(boxDifferentHeight)

                      return {
                        resultSizes: {
                          x: boxDifferentWidth,
                          y: boxDifferentHeight,
                          xT: pixelBoxDifferentPositionXWithPrevPixel,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number') {
                        boxWidth.setValue(resultPosition.x)
                      }
                      if (typeof resultPosition.y == 'number') {
                        boxHeight.setValue(resultPosition.y)
                      }
                      if (typeof resultPosition.xT == 'number') {
                        boxPostionType.setValue({
                          ...boxPostionType.valueRef.current,
                          positionX: resultPosition.xT,
                        })
                      }
                    }}
                    style={{
                      width: widthAndHeight,
                      height: widthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      bottom: 0,
                      left: 0,
                      transform: 'translate(-50%, 50%)',
                      cursor: 'nesw-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      boxWidth.setIsDrag(is)
                      boxHeight.setIsDrag(is)
                    }}
                    onClick={() => {
                      // boxWidth.setValue(null)
                      // boxHeight.setValue(null)
                    }}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      if (
                        boxWidthType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxWidth.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetWidth > boxWidth.valueRef.current
                          : true)
                      ) {
                        boxWidth.setValue(fullBoxAreaRef.current.offsetWidth)
                      }
                      if (
                        boxHeightType.valueRef.current == 'Pixel' &&
                        fullBoxAreaRef.current &&
                        (typeof boxHeight.valueRef.current == 'number'
                          ? fullBoxAreaRef.current.offsetHeight > boxHeight.valueRef.current
                          : true)
                      ) {
                        if (fullBoxAreaRef.current) boxHeight.setValue(fullBoxAreaRef.current.offsetHeight)
                      }
                    }}
                    onChangePosition={({ offsetPosition }) => {
                      let boxDifferentWidth = 0

                      if (boxWidthType.valueRef.current == 'Percent') {
                        const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                        const percentBoxDifferentWidthWithPrevPercent =
                          (boxWidth.valueRef.current || 0) + percentBoxDifferentWidth
                        const roughPercentBoxDifferentWidth =
                          percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                        boxDifferentWidth = roughPercentBoxDifferentWidth > 100 ? 100 : roughPercentBoxDifferentWidth
                      }
                      if (boxWidthType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) + offsetPosition.x
                        const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                        boxDifferentWidth = roughPixelBoxDifferentWidth
                      }

                      boxWidth.setTemporary(boxDifferentWidth)

                      let boxDifferentHeight = 0

                      if (boxHeightType.valueRef.current == 'Percent') {
                        const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                        const percentBoxDifferentHeightWithPrevPercent =
                          (boxHeight.valueRef.current || 0) + percentBoxDifferentHeight
                        const roughPercentBoxDifferentHeight =
                          percentBoxDifferentHeightWithPrevPercent - (percentBoxDifferentHeightWithPrevPercent % 1)
                        boxDifferentHeight = roughPercentBoxDifferentHeight > 100 ? 100 : roughPercentBoxDifferentHeight
                      }
                      if (boxHeightType.valueRef.current == 'Pixel') {
                        const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) + offsetPosition.y
                        // const roughPixelBoxDifferentHeight =
                        //   pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 10)
                        boxDifferentHeight = pixelBoxDifferentHeightWithPrevPixel
                      }

                      boxHeight.setTemporary(boxDifferentHeight)

                      return {
                        resultSizes: {
                          x: boxDifferentWidth,
                          y: boxDifferentHeight,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number') boxWidth.setValue(resultPosition.x)
                      if (typeof resultPosition.y == 'number') boxHeight.setValue(resultPosition.y)
                    }}
                    style={{
                      width: widthAndHeight,
                      height: widthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      bottom: 0,
                      right: 0,
                      transform: 'translate(50%, 50%)',
                      cursor: 'nwse-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      boxWidth.setIsDrag(is)
                      boxHeight.setIsDrag(is)
                    }}
                    onClick={() => {
                      // boxWidth.setValue(null)
                      // boxHeight.setValue(null)
                    }}
                  ></DragOffsetPosition>
                </>
              ) : null}

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  if (
                    boxHeightType.valueRef.current == 'Pixel' &&
                    fullBoxAreaRef.current &&
                    (typeof boxHeight.valueRef.current == 'number'
                      ? fullBoxAreaRef.current.offsetHeight > boxHeight.valueRef.current
                      : true)
                  ) {
                    if (fullBoxAreaRef.current) boxHeight.setValue(fullBoxAreaRef.current.offsetHeight)
                  }
                }}
                onChangePosition={({ offsetPosition }) => {
                  let pixelBoxDifferentPositionYWithPrevPixel = undefined
                  if (boxPostionType.viewValue.positionType == 'Absolute') {
                    pixelBoxDifferentPositionYWithPrevPixel =
                      (boxPostionType.valueRef.current.positionY || 0) + Math.ceil(offsetPosition.y)

                    boxPostionType.setTemporary({
                      ...boxPostionType.valueRef.current,
                      positionY: pixelBoxDifferentPositionYWithPrevPixel,
                    })
                  }

                  let boxDifferentHeight = 0

                  if (boxHeightType.valueRef.current == 'Percent') {
                    const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                    const percentBoxDifferentHeightWithPrevPercent =
                      (boxHeight.valueRef.current || 0) - percentBoxDifferentHeight
                    const roughPercentBoxDifferentHeight =
                      percentBoxDifferentHeightWithPrevPercent - (percentBoxDifferentHeightWithPrevPercent % 1)
                    boxDifferentHeight = roughPercentBoxDifferentHeight > 100 ? 100 : roughPercentBoxDifferentHeight
                  }
                  if (boxHeightType.valueRef.current == 'Pixel') {
                    const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) - offsetPosition.y
                    // const roughPixelBoxDifferentHeight =
                    //   pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 10)
                    boxDifferentHeight = pixelBoxDifferentHeightWithPrevPixel
                  }

                  boxHeight.setTemporary(boxDifferentHeight)

                  return {
                    resultSizes: {
                      y: boxDifferentHeight,
                      yT: pixelBoxDifferentPositionYWithPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number') {
                    boxHeight.setValue(resultPosition.y)
                  }
                  if (typeof resultPosition.yT == 'number') {
                    boxPostionType.setValue({
                      ...boxPostionType.valueRef.current,
                      positionY: resultPosition.yT,
                    })
                  }
                }}
                style={{
                  height: borderSizeDrag,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: 'translate(0%, -50%)',
                  cursor: 'ns-resize',
                  zIndex: 2,
                }}
                onDrag={({ is }) => {
                  boxHeight.setIsDrag(is)
                }}
                onClick={() => {
                  // boxWidth.setValue(null)
                  // boxHeight.setValue(null)
                }}
              ></DragOffsetPosition>

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  if (
                    boxWidthType.valueRef.current == 'Pixel' &&
                    fullBoxAreaRef.current &&
                    (typeof boxWidth.valueRef.current == 'number'
                      ? fullBoxAreaRef.current.offsetWidth > boxWidth.valueRef.current
                      : true)
                  ) {
                    boxWidth.setValue(fullBoxAreaRef.current.offsetWidth)
                  }
                }}
                onChangePosition={({ offsetPosition }) => {
                  let pixelBoxDifferentPositionXWithPrevPixel = undefined
                  if (boxPostionType.viewValue.positionType == 'Absolute') {
                    pixelBoxDifferentPositionXWithPrevPixel =
                      (boxPostionType.valueRef.current.positionX || 0) + Math.ceil(offsetPosition.x)

                    boxPostionType.setTemporary({
                      ...boxPostionType.valueRef.current,
                      positionX: pixelBoxDifferentPositionXWithPrevPixel,
                    })
                  }
                  let boxDifferentWidth = 0

                  if (boxWidthType.valueRef.current == 'Percent') {
                    const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                    const percentBoxDifferentWidthWithPrevPercent = (boxWidth.valueRef.current || 0) - percentBoxDifferentWidth
                    const roughPercentBoxDifferentWidth =
                      percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                    boxDifferentWidth = roughPercentBoxDifferentWidth > 100 ? 100 : roughPercentBoxDifferentWidth
                  }
                  if (boxWidthType.valueRef.current == 'Pixel') {
                    const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) - offsetPosition.x
                    const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                    boxDifferentWidth = roughPixelBoxDifferentWidth
                  }

                  boxWidth.setTemporary(boxDifferentWidth)

                  return {
                    resultSizes: {
                      x: boxDifferentWidth,
                      xT: pixelBoxDifferentPositionXWithPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.x == 'number') {
                    boxWidth.setValue(resultPosition.x)
                  }
                  if (typeof resultPosition.xT == 'number') {
                    boxPostionType.setValue({
                      ...boxPostionType.valueRef.current,
                      positionX: resultPosition.xT,
                    })
                  }
                }}
                style={{
                  width: borderSizeDrag,
                  height: '100%',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bottom: 0,
                  left: 0,
                  transform: 'translate(-50%, 0%)',
                  cursor: 'ew-resize',
                  zIndex: 2,
                }}
                onDrag={({ is }) => {
                  boxWidth.setIsDrag(is)
                }}
                onClick={() => {
                  // boxWidth.setValue(null)
                  // boxHeight.setValue(null)
                }}
              ></DragOffsetPosition>

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  if (
                    boxWidthType.valueRef.current == 'Pixel' &&
                    fullBoxAreaRef.current &&
                    (typeof boxWidth.valueRef.current == 'number'
                      ? fullBoxAreaRef.current.offsetWidth > boxWidth.valueRef.current
                      : true)
                  ) {
                    boxWidth.setValue(fullBoxAreaRef.current.offsetWidth)
                  }
                }}
                onChangePosition={({ offsetPosition }) => {
                  let boxDifferentWidth = 0

                  if (boxWidthType.valueRef.current == 'Percent') {
                    const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                    const percentBoxDifferentWidthWithPrevPercent = (boxWidth.valueRef.current || 0) + percentBoxDifferentWidth
                    const roughPercentBoxDifferentWidth =
                      percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                    boxDifferentWidth = roughPercentBoxDifferentWidth > 100 ? 100 : roughPercentBoxDifferentWidth
                  }
                  if (boxWidthType.valueRef.current == 'Pixel') {
                    const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) + offsetPosition.x
                    const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                    boxDifferentWidth = roughPixelBoxDifferentWidth
                  }

                  boxWidth.setTemporary(boxDifferentWidth)

                  return {
                    resultSizes: {
                      x: boxDifferentWidth,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.x == 'number') boxWidth.setValue(resultPosition.x)
                }}
                style={{
                  width: borderSizeDrag,
                  height: '100%',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // border: '1px solid #0086ff',
                  bottom: '0',
                  right: 0,
                  transform: 'translate(50%, 0%)',
                  cursor: 'ew-resize',
                  zIndex: 2,
                  // background: boxWidth.isDrag ? '#0086ff' : 'white',
                }}
                onDrag={({ is }) => {
                  boxWidth.setIsDrag(is)
                }}
                onClick={() => {
                  // boxWidth.setValue(null)
                }}
              >
                {/* {boxWidth.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null} */}
              </DragOffsetPosition>

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  if (
                    boxHeightType.valueRef.current == 'Pixel' &&
                    fullBoxAreaRef.current &&
                    (typeof boxHeight.valueRef.current == 'number'
                      ? fullBoxAreaRef.current.offsetHeight > boxHeight.valueRef.current
                      : true)
                  ) {
                    if (fullBoxAreaRef.current) boxHeight.setValue(fullBoxAreaRef.current.offsetHeight)
                  }
                }}
                onChangePosition={({ offsetPosition }) => {
                  let boxDifferentHeight = 0

                  if (boxHeightType.valueRef.current == 'Percent') {
                    const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                    const percentBoxDifferentHeightWithPrevPercent =
                      (boxHeight.valueRef.current || 0) + percentBoxDifferentHeight
                    const roughPercentBoxDifferentHeight =
                      percentBoxDifferentHeightWithPrevPercent - (percentBoxDifferentHeightWithPrevPercent % 1)
                    boxDifferentHeight = roughPercentBoxDifferentHeight > 100 ? 100 : roughPercentBoxDifferentHeight
                  }
                  if (boxHeightType.valueRef.current == 'Pixel') {
                    // const roughPixelBoxDifferentHeight =
                    //   pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 1)
                    const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) + offsetPosition.y
                    boxDifferentHeight = pixelBoxDifferentHeightWithPrevPixel
                  }

                  boxHeight.setTemporary(boxDifferentHeight)

                  return {
                    resultSizes: {
                      y: boxDifferentHeight,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number') boxHeight.setValue(resultPosition.y)
                }}
                style={{
                  willChange: 'background',
                  height: borderSizeDrag,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // border: '1px solid #0086ff',
                  width: '100%',
                  left: 0,
                  bottom: 0,
                  transform: 'translate(0%, 50%)',
                  cursor: 'ns-resize',
                  zIndex: 2,
                  // background: boxHeight.isDrag ? '#0086ff' : 'white',
                }}
                onDrag={({ is }) => {
                  boxHeight.setIsDrag(is)
                }}
                onClick={() => {
                  // boxHeight.setValue(null)
                }}
              >
                {/* {boxHeight.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null} */}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHorizontalPaddingPrevPixel =
                    (boxPadding.valueRef.current.right || 0) - offsetPosition.x
                  // const roughPixelBoxDifferentHorizontalPadding =
                  //   pixelBoxDifferentHorizontalPaddingPrevPixel - (pixelBoxDifferentHorizontalPaddingPrevPixel % 5)

                  boxPadding.setTemporary({
                    ...boxPadding.valueRef.current,
                    // left: roughPixelBoxDifferentHorizontalPadding,
                    right: pixelBoxDifferentHorizontalPaddingPrevPixel,
                  })

                  return {
                    resultSizes: {
                      x: pixelBoxDifferentHorizontalPaddingPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.x == 'number')
                    boxPadding.setValue({
                      ...boxPadding.valueRef.current,
                      // left: resultPosition.x,
                      right: resultPosition.x,
                    })
                }}
                style={{
                  willChange: 'background, border',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bottom: '50%',
                  transform: 'translate(0%, 50%)',
                  right: 0,
                  cursor: 'w-resize',
                  zIndex: 2,
                  width: 2,
                  height: 10,
                  maxHeight: '10%',
                  background: paddingColor,
                  // border: `1px solid ${paddingColor}`,
                }}
                onDrag={({ is }) => {
                  boxPadding.setIsDrag(is)
                }}
              >
                {/* {boxPadding.value.right ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: paddingColor,
                    }}
                  />
                ) : null} */}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHorizontalPaddingPrevPixel = (boxPadding.valueRef.current.left || 0) + offsetPosition.x
                  // const roughPixelBoxDifferentHorizontalPadding =
                  //   pixelBoxDifferentHorizontalPaddingPrevPixel + (pixelBoxDifferentHorizontalPaddingPrevPixel % 5)

                  boxPadding.setTemporary({
                    ...boxPadding.valueRef.current,
                    left: pixelBoxDifferentHorizontalPaddingPrevPixel,
                    // right: roughPixelBoxDifferentHorizontalPadding,
                  })

                  return {
                    resultSizes: {
                      x: pixelBoxDifferentHorizontalPaddingPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.x == 'number')
                    boxPadding.setValue({
                      ...boxPadding.valueRef.current,
                      left: resultPosition.x,
                      // right: resultPosition.x,
                    })
                }}
                style={{
                  willChange: 'background, border',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bottom: '50%',
                  transform: 'translate(0%, 50%)',
                  left: 0,
                  cursor: 'e-resize',
                  zIndex: 2,
                  width: 2,
                  height: 10,
                  maxHeight: '10%',
                  background: paddingColor,
                  // border: `1px solid ${paddingColor}`,
                }}
                onDrag={({ is }) => {
                  boxPadding.setIsDrag(is)
                }}
              >
                {/* {boxPadding.value.right ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: paddingColor,
                    }}
                  />
                ) : null} */}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHorizontalPaddingPrevPixel =
                    (boxPadding.valueRef.current.bottom || 0) - offsetPosition.y
                  const roughPixelBoxDifferentHorizontalPadding =
                    pixelBoxDifferentHorizontalPaddingPrevPixel - (pixelBoxDifferentHorizontalPaddingPrevPixel % 5)

                  boxPadding.setTemporary({
                    ...boxPadding.valueRef.current,
                    // top: roughPixelBoxDifferentHorizontalPadding,
                    bottom: pixelBoxDifferentHorizontalPaddingPrevPixel,
                  })

                  return {
                    resultSizes: {
                      y: pixelBoxDifferentHorizontalPaddingPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number')
                    boxPadding.setValue({
                      ...boxPadding.valueRef.current,
                      // top: resultPosition.y,
                      bottom: resultPosition.y,
                    })
                }}
                style={{
                  willChange: 'background, border',
                  width: 10,
                  maxWidth: '10%',
                  height: 2,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: '50%',
                  bottom: 0,
                  transform: 'translate(50%, 0%)',
                  cursor: 'n-resize',
                  zIndex: 10,
                  background: paddingColor,
                  // border: `1px solid ${paddingColor}`,
                }}
                onDrag={({ is }) => {
                  boxPadding.setIsDrag(is)
                }}
              >
                {/* {boxPadding.value.bottom ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: paddingColor,
                    }}
                  />
                ) : null} */}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHorizontalPaddingPrevPixel = (boxPadding.valueRef.current.top || 0) + offsetPosition.y
                  const roughPixelBoxDifferentHorizontalPadding =
                    pixelBoxDifferentHorizontalPaddingPrevPixel - (pixelBoxDifferentHorizontalPaddingPrevPixel % 5)

                  boxPadding.setTemporary({
                    ...boxPadding.valueRef.current,
                    top: pixelBoxDifferentHorizontalPaddingPrevPixel,
                    // bottom: pixelBoxDifferentHorizontalPaddingPrevPixel,
                  })

                  return {
                    resultSizes: {
                      y: pixelBoxDifferentHorizontalPaddingPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number')
                    boxPadding.setValue({
                      ...boxPadding.valueRef.current,
                      top: resultPosition.y,
                      // bottom: resultPosition.y,
                    })
                }}
                style={{
                  willChange: 'background, border',
                  width: 10,
                  maxWidth: '10%',
                  height: 2,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: '50%',
                  top: 0,
                  transform: 'translate(50%, 0%)',
                  cursor: 's-resize',
                  zIndex: 2,
                  background: paddingColor,
                  // border: `1px solid ${paddingColor}`,
                }}
                onDrag={({ is }) => {
                  boxPadding.setIsDrag(is)
                }}
              >
                {/* {boxPadding.value.bottom ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: paddingColor,
                    }}
                  />
                ) : null} */}
              </DragOffsetPosition>

              {/* <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHeightWithPrevPixel = (boxBorderRadius.valueRef.current || 0) + offsetPosition.x
                  const roughPixelBoxDifferentHeight =
                    pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 1)

                  const resultBoxRadius = pixelBoxDifferentHeightWithPrevPixel >= 0 ? pixelBoxDifferentHeightWithPrevPixel : 0

                  boxBorderRadius.setTemporary(resultBoxRadius)

                  return {
                    resultSizes: {
                      y: resultBoxRadius,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number') boxBorderRadius.setValue(resultPosition.y)
                }}
                style={{
                  willChange: 'background, left, bottom',
                  width: widthAndHeight,
                  height: widthAndHeight,
                  position: 'absolute',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #0086ff',
                  transform: 'translate(-50%, 50%)',
                  cursor: 'nesw-resize',
                  zIndex: 2,
                  background: boxBorderRadius.isDrag ? '#0086ff' : 'white',
                  left: boxBorderRadius.viewValue || 0,
                  bottom: boxBorderRadius.viewValue || 0,
                }}
                onDrag={({ is }) => {
                  boxBorderRadius.setIsDrag(is)
                }}
                onClick={() => {
                  // boxBorderRadius.setValue(null)
                }}
              >
                {boxBorderRadius.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null}
              </DragOffsetPosition> */}

              {boxPostionType.viewValue.positionType == 'Relative' ? (
                <>
                  {/* <div
                    {...listeners}
                    {...attributes}
                    css={{
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'purple',
                      top: 0,
                      left: 0,
                      transform: 'translate(-50%, -50%)',
                      cursor: 'grab',
                      zIndex: 2,
                    }}
                  /> */}

                  {/* <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginRight = (offsetPosition.x / getParentBoxWidth()) * 100
                      const boxDifferentMarginRightWithPrev = (boxMargin.valueRef.current.right || 0) + boxDifferentMarginRight
                      const roughBoxDifferentMarginRightWithPrev =
                        boxDifferentMarginRightWithPrev - (boxDifferentMarginRightWithPrev % 5)
                      const roughBoxDifferentMarginRightLimit =
                        roughBoxDifferentMarginRightWithPrev < 0 ? 0 : roughBoxDifferentMarginRightWithPrev
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        right: roughBoxDifferentMarginRightLimit,
                      })

                      return {
                        resultSizes: {
                          x: roughBoxDifferentMarginRightLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          right: resultPosition.x,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      bottom: '50%',
                      left: 'calc(100% + 2px)',
                      transform: 'translate(0%, 50%)',
                      cursor: 'e-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        right: 0,
                      })
                    }}
                  >
                    {boxMargin.value.right ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginLeft = (offsetPosition.x / getParentBoxWidth()) * 100
                      const boxDifferentMarginLeftWithPrev = (boxMargin.valueRef.current.left || 0) - boxDifferentMarginLeft
                      const roughBoxDifferentMarginLeftWithPrev =
                        boxDifferentMarginLeftWithPrev - (boxDifferentMarginLeftWithPrev % 5)
                      const roughBoxDifferentMarginLeftLimit =
                        roughBoxDifferentMarginLeftWithPrev < 0 ? 0 : roughBoxDifferentMarginLeftWithPrev
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        left: roughBoxDifferentMarginLeftLimit,
                      })

                      return {
                        resultSizes: {
                          x: roughBoxDifferentMarginLeftLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          left: resultPosition.x,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      bottom: '50%',
                      right: 'calc(100% + 2px)',
                      transform: 'translate(0%, 50%)',
                      cursor: 'w-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        left: 0,
                      })
                    }}
                  >
                    {boxMargin.value.left ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginTopWithPrev = (boxMargin.valueRef.current.top || 0) - offsetPosition.y
                      const roughBoxDifferentMarginTop = boxDifferentMarginTopWithPrev - (boxDifferentMarginTopWithPrev % 5)
                      const roughBoxDifferentMarginRightLimit = roughBoxDifferentMarginTop < 0 ? 0 : roughBoxDifferentMarginTop
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        top: roughBoxDifferentMarginRightLimit,
                      })

                      return {
                        resultSizes: {
                          y: roughBoxDifferentMarginRightLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          top: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      left: '50%',
                      bottom: 'calc(100% + 2px)',
                      transform: 'translate(-50%, 0%)',
                      cursor: 'n-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        top: 0,
                      })
                    }}
                  >
                    {boxMargin.value.top ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginBottomWithPrev = (boxMargin.valueRef.current.bottom || 0) + offsetPosition.y
                      const roughBoxDifferentMarginBottom =
                        boxDifferentMarginBottomWithPrev - (boxDifferentMarginBottomWithPrev % 5)
                      const roughBoxDifferentMarginBottomLimit =
                        roughBoxDifferentMarginBottom < 0 ? 0 : roughBoxDifferentMarginBottom

                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        bottom: roughBoxDifferentMarginBottomLimit,
                      })

                      return {
                        resultSizes: {
                          y: roughBoxDifferentMarginBottomLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          bottom: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      border: `1px solid #ffb800`,
                      left: '50%',
                      top: 'calc(100% + 2px)',
                      transform: 'translate(-50%, 0%)',
                      cursor: 's-resize',
                      zIndex: 2,
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        bottom: 0,
                      })
                    }}
                  >
                    {boxMargin.value.bottom ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition> */}
                </>
              ) : undefined}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const LineBoxSettings = ({ onAdd, title, children }: { onAdd: () => void; title: string; children?: ReactNode }) => {
  return (
    <div>
      <Group mb={children ? 'xs' : undefined} position="apart">
        <Text>{title}</Text>
        <ActionIcon onClick={() => onAdd()}>
          <IconPlus color={colors.blue} />
        </ActionIcon>
      </Group>
      {children}
    </div>
  )
}

const ChooseSiteColors = ({
  onChooseColor,
  onClearColor,
  currentColorID,
  children,
}: {
  onChooseColor: ({}: { colorID: string }) => void
  onClearColor?: () => void
  currentColorID?: string
  children: ReactNode
}) => {
  const site = useContext(SiteContext)

  const [open, setOpen] = useState(false)

  const offset = 10

  return (
    <div
      css={{
        position: 'relative',
      }}
      onMouseEnter={() => {
        setOpen(true)
      }}
      onMouseLeave={() => setOpen(false)}
    >
      <div
        css={{
          '& > svg': {
            display: 'block',
          },
        }}
      >
        {' '}
        {children}
      </div>
      {open ? (
        <Paper
          css={{
            position: 'absolute',
            right: `calc(100% + ${offset}px)`,
            bottom: 0,
            width: 200,
            '&::after': {
              content: `""`,
              width: offset,
              height: '100%',
              dispaly: 'block',
              position: 'absolute',
              left: '100%',
              bottom: 0,
            },
          }}
          shadow="xs"
          p={12}
        >
          <Group spacing={4}>
            {/* {site.colors.map(color => (
              <ColorSwatch
                key={color.id}
                component="button"
                color={color.value}
                onClick={() => {
                  onChooseColor({ colorID: color.id })
                }}
                sx={{ color: '#fff', cursor: 'pointer' }}
              >
                {currentColorID == color.id && <CheckIcon width={10} />}
              </ColorSwatch>
            ))} */}
            {onClearColor ? (
              <ColorSwatch
                title="Очистить цвет"
                component="button"
                color={'#fff'}
                onClick={() => {
                  onClearColor()
                }}
                sx={{ color: 'rgb(0, 134, 255)', cursor: 'pointer' }}
              >
                <IconWashDrycleanOff width={'60%'} />
              </ColorSwatch>
            ) : null}
          </Group>
        </Paper>
      ) : null}
    </div>
  )
}
