import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { Group, Text } from '@mantine/core'
import { IconArrowsMoveVertical, IconStar, IconStarOff } from '@tabler/icons'
import { useBoxBlockContext } from 'contexts/BoxBlockContext'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useSiteDataEventsContext } from 'contexts/SiteDataEventsContextProvider'
import { useResponsiveProperty } from 'hooks/useResponsiveProperty/useResponsiveProperty'
import { CSSProperties, useContext, useEffect } from 'react'
import useStateRef from 'react-usestateref'
import { BoxBlockSelect, NestedBoxSelect } from 'server/selects'
import { trpc } from 'utils/trpc'

export function LayerBox({
  box,
  indent,
  boxBlockID,
  boxBlock,
  isMainParent = true,
}: {
  box: NestedBoxSelect
  indent: number
  boxBlockID: string
  boxBlock?: BoxBlockSelect
  isMainParent?: boolean
}) {
  const [settingsIsShow, setSettingsIsShow, settingsIsShowRef] = useStateRef(false)
  const { lineIsShow, responsiveMode, focusBoxID, setFocusBoxID, focusBoxBlockID, setFocusBoxBlockID } = useBoxBlockContext()

  const site = useContext(SiteContext)

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings')
  const pinBoxBlockFavorite = trpc.useMutation('user.box.pinBoxBlockFavorite')

  useEffect(() => {
    setSettingsIsShow(focusBoxID == box.id && focusBoxBlockID == boxBlockID)
  }, [focusBoxID, focusBoxBlockID])

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: box.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: box.id,
  })

  const boxHidden = useResponsiveProperty({
    resposiveMode: {
      desktop: box.hiddenDesktopResponsiveMode,
      tablet: box.hiddenTabletResponsiveMode,
      mobile: box.hiddenMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.hidden,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.hidden,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.hidden,
    },
    propertyName: 'Скрытость',

    onValueUpdated: async ({ responsiveMode, value }) => {},
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {},
  })

  const { updateBoxByID } = useSiteDataEventsContext()

  const [boxes, setBoxes, boxesRef] = useStateRef(box.boxes)

  const [needUpdateBoxes, setNeedUpdateBoxes, needUpdateBoxesRef] = useStateRef(true)

  useEffect(() => {
    setNeedUpdateBoxes(false)
    setBoxes(box.boxes)
  }, [JSON.stringify(box.boxes)])

  useEffect(() => {
    if (needUpdateBoxesRef.current)
      updateBoxByID({
        id: box.id,
        box: {
          boxes: boxesRef.current,
        },
      })
    setNeedUpdateBoxes(true)
  }, [boxes])

  return boxes ? (
    <>
      <div
        ref={setNodeRef}
        onClick={() => {
          setFocusBoxID(box.id)
          setFocusBoxBlockID(boxBlockID)
          document.getElementById(`box-id-${box.id}`)?.scrollIntoView()
        }}
        style={{
          backdropFilter: 'blur(10px)',
          backgroundColor: `${isOver ? '#cee4f4' : settingsIsShow ? '#e5f4ff' : 'rgba(255, 255, 255, 0.75)'}`,
          borderRadius: isDragging ? 4 : undefined,
          boxShadow: isDragging
            ? '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px'
            : undefined,
          zIndex: isDragging ? 10000000 : undefined,
          position: 'relative',
          ...styleDrag,
        }}
        id={`layer-id-${box.id}`}
        css={{
          //   borderBottom: `1px solid #eee`,
          // padding: `6px 0 6px ${indent * 12}px`,
          opacity: boxHidden.viewValue ? 0.5 : 1,
          ':hover': {
            backgroundColor: `#eee`,
          },
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Array.from({ length: indent }, (_, i) => i + 1).map((key, index) => (
          <div
            key={index}
            css={{
              width: 23,
              height: 30,
              position: 'relative',
              // borderRight: `1px solid ${index == 0 ? 'transparent' : '#e4e4e4'}`,
              // '&::after': {
              //   content: `""`,
              //   display: 'block',
              //   width: '1px',
              //   left: '50%',
              //   top: '50%',
              //   height: '100%',
              //   background: index == 0 ? 'transparent' : '#e6e6e6',
              //   transform: 'translate(-50%, -50%)',
              //   position: 'absolute',
              // },
              '&::before': {
                content: `""`,
                display: 'block',
                height: '2px',
                left: '50%',
                top: '50%',
                width: '100%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                background: index == 0 ? 'transparent' : '#000000',
              },
            }}
          ></div>
        ))}
        <div ref={setNodeRefDrop}>
          <Group
            css={{
              alignItems: 'center',
              paddingLeft: '4px',
            }}
            spacing={8}
          >
            {isMainParent ? null : (
              <IconArrowsMoveVertical
                color={'#333'}
                css={{
                  cursor: 'pointer',
                  outline: 'none',
                }}
                size={16}
                {...listeners}
                {...attributes}
              />
            )}
            <Text
              css={{
                color: '#333',
              }}
              size="xs"
            >
              {box.textComponent
                ? 'Текст'
                : box.imageComponent
                ? 'Картинка'
                : box.formComponent
                ? 'Форм'
                : box.quizComponent
                ? 'Квиз'
                : box.videoComponent
                ? 'Видео'
                : box.faqComponent
                ? 'Вопрос - ответ'
                : isMainParent
                ? 'Секция'
                : 'Бокс'}
              <sup>{box.responsiveBoxSettings.desktopBoxSettings.order} </sup>
              {box.link ? <strong> - Ссылка</strong> : <></>}
            </Text>
            {boxBlock ? (
              <>
                {boxBlock.favoriteOfUsers.find(favoriteUser => favoriteUser.id == site.user.id) ? (
                  <IconStar
                    css={{
                      display: 'inline-floe',
                    }}
                    size={20}
                    onClick={() => pinBoxBlockFavorite.mutate({ boxBlockID: boxBlock.id })}
                  />
                ) : (
                  <IconStarOff
                    css={{
                      display: 'inline-floe',
                    }}
                    size={20}
                    onClick={() => pinBoxBlockFavorite.mutate({ boxBlockID: boxBlock.id })}
                  />
                )}
              </>
            ) : null}
          </Group>
        </div>
      </div>

      <DndContext
        onDragEnd={event => {
          const { active, over } = event

          if (over) {
            if (active.id !== over.id) {
              setBoxes(boxes => {
                if (boxes) {
                  const oldIndex = boxes.findIndex(box => box.id == active.id)
                  const newIndex = boxes.findIndex(box => box.id == over.id)

                  if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
                    const updatedBoxes = arrayMove(boxes, oldIndex, newIndex)

                    updatedBoxes.map((box, index) => {
                      updateBoxSettings.mutate({
                        boxID: box.id,
                        order: index,
                        screenType: 'Desktop',
                      })
                    })
                    return updatedBoxes
                  }

                  return boxes
                }
              })
            }
          }
        }}
      >
        {boxes.map(boxLoop => (
          <LayerBox isMainParent={false} key={boxLoop.id} boxBlockID={boxBlockID} indent={indent + 1} box={boxLoop} />
        ))}
      </DndContext>
    </>
  ) : (
    <></>
  )
}
