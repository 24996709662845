import { Popover } from '@mantine/core'
import { Color } from '@tiptap/extension-color'
import FontFamily from '@tiptap/extension-font-family'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { textWrapStyles } from 'components/TextWrap'
import { useEffect, useState } from 'react'
import { RichEditorControlsTipTapSync } from 'syncComponents/EditorTextComponentSync/EditorTextComponentSync'
import { useCurrentResponsiveMode } from 'utils/activeResponsiveModeCenter'
import { cabinetColors } from 'utils/cabinetTheme'
import { clearHTMLTags } from 'utils/clearHTMLTags'

export function EditorTextSync({
  defaultValue,
  onChange,
  title,
  placeholder,
  hideTypes,
}: {
  defaultValue: string
  placeholder?: string
  onChange: (value: string) => void
  title?: string
  hideTypes?: boolean
}) {
  const responsiveMode = useCurrentResponsiveMode()

  const editor = useEditor({
    onUpdate: event => {
      const html = event.editor?.getHTML()
      if (html) onChange(html)
    },
    extensions: [
      StarterKit,
      Text,
      TextStyle,
      FontFamily.configure({
        types: ['textStyle'],
      }),
      Color.configure({
        types: ['textStyle'],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Link.configure({
        HTMLAttributes: {
          class: 'tip-tap-link',
        },
      }),
    ],
    content: defaultValue,
    parseOptions: {
      preserveWhitespace: 'full',
    },
    injectCSS: false,
  })

  useEffect(() => {
    if (editor) {
      const html = editor.getHTML()
      if (typeof html == 'string' && html != defaultValue && !editor.isFocused) {
        editor.commands.setContent(defaultValue)
      }
    }
  }, [defaultValue])

  const [opened, setOpened] = useState(false)

  const html = clearHTMLTags({
    html: `${editor?.getHTML()}`,
  })

  return (
    <Popover
      onClose={() => setOpened(false)}
      // onOpen={() => setOpened(true)}
      opened={opened}
      // withinPortal={true}
      width={'auto'}
      position="bottom"
      withArrow
      shadow="md"
      withinPortal
    >
      <Popover.Target>
        <div
          // start duplicate
          onKeyDown={event => {
            event.stopPropagation()
          }}
          onKeyUp={event => {
            event.stopPropagation()
          }}
          onMouseDown={event => {
            event.stopPropagation()
          }}
          onDoubleClick={event => {
            event.stopPropagation()
          }}
          onMouseUp={event => {
            event.stopPropagation()
          }}
          onMouseEnter={event => {
            event.stopPropagation()
          }}
          onMouseOver={event => {
            event.stopPropagation()
          }}
          onMouseMove={event => {
            event.stopPropagation()
          }}
          onMouseLeave={event => {
            event.stopPropagation()
          }}
          onMouseDownCapture={event => {
            event.stopPropagation()
          }}
          onMouseOut={event => {
            event.stopPropagation()
          }}
          // end duplicate

          css={{
            mark: {
              color: 'inherit !important',
            },
            position: 'relative',
            display: 'block',
            width: '100%',
            '& [contenteditable="true"]': {
              outline: 'none',
            },
            '& *': {
              userSelect: 'auto',
            },
            '& * p': {
              fontSize: `${16}px !important`,
              lineHeight: '120% !important',
            },
          }}
        >
          {html == '' || html == '<p></p>' ? (
            <div
              style={{
                pointerEvents: 'none',
                color: cabinetColors.placeholder,
                position: 'absolute',
                left: 0,
                top: 0,
              }}
              css={textWrapStyles({ all: true, isDesktop: responsiveMode == 'Desktop' })}
            >
              <p>{placeholder ? placeholder : 'Введите текст'}</p>
            </div>
          ) : null}
          <EditorContent
            onClick={() => setOpened(true)}
            css={textWrapStyles({ all: true, isDesktop: responsiveMode == 'Desktop' })}
            editor={editor}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown
        css={{
          background: '#fff',
          // border: `1px solid ${colors.blue}`,
          borderRadius: 4,
          padding: '4px',
        }}
      >
        <RichEditorControlsTipTapSync hideTypes={hideTypes} editor={editor} />
      </Popover.Dropdown>
    </Popover>
  )
}
