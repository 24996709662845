import { Button, Group, HoverCard, Paper, Text, Title } from '@mantine/core'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext, useState } from 'react'
import { SketchPicker } from 'react-color'
import { trpc } from 'utils/trpc'

export const SiteColors = ({}: {}) => {
  const site = useContext(SiteContext)

  const [color, setColor] = useState('#fff')

  const createColor = trpc.useMutation('user.createColor')
  const updateSiteMainColor = trpc.useMutation('user.updateSiteMainColor')
  const updateOneColor = trpc.useMutation('user.updateOneColor')

  return (
    <>
      <Paper mt="sm" withBorder p="xs">
        <Group mb="xs" position="apart">
          <Text size={'xs'}>Цвета</Text>
          <Group>
            <div
              css={{
                width: 25,
                height: 25,
                background: color,
                border: '1px solid grey',
              }}
            ></div>{' '}
            <HoverCard width={'auto'} shadow="md">
              <HoverCard.Target>
                <Button
                  loading={createColor.isLoading}
                  onClick={() => {
                    createColor.mutate({
                      value: color,
                      siteID: site.id,
                    })
                  }}
                  size="xs"
                >
                  Добавить
                </Button>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SketchPicker
                  color={color}
                  onChange={color => {
                    setColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
                  }}
                />
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
        </Group>
        <Group>
          <Title order={2}>Цвета</Title>
          {site.colors.map(color => (
            <HoverCard key={color.id} width={'auto'} shadow="md">
              <HoverCard.Target>
                <div
                  onClick={() => {
                    updateSiteMainColor.mutate({
                      siteID: site.id,
                      colorID: color.id,
                    })
                  }}
                  css={{
                    width: 25,
                    height: 25,
                    background: color.value,
                    border: '1px solid grey',
                    position: 'relative',
                    cursor: 'pointer',
                    ':after':
                      site.mainColor && site.mainColor.id == color.id
                        ? {
                            content: '""',
                            display: 'block',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            position: 'absolute',
                            left: '100%',
                            bottom: '100%',
                            background: 'green',
                            transform: 'translate(-50%, 50%)',
                          }
                        : undefined,
                  }}
                />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SketchPicker
                  color={color.value}
                  onChangeComplete={newColor => {
                    updateOneColor.mutate({
                      value: `rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`,
                      colorID: color.id,
                    })
                  }}
                />
              </HoverCard.Dropdown>
            </HoverCard>
          ))}
        </Group>
      </Paper>
    </>
  )
}
