import { ActionIcon, ColorSwatch, Grid, Group, SimpleGrid, Switch } from '@mantine/core'
import { IconGridDots, IconPlus, IconSettings, IconX } from '@tabler/icons'
import { EditOneBoxEntity, EntityLineSettingsBox, TitleOfSettings } from 'components/EditOneBoxEntity/EditOneBoxEntity'
import { PositionSettingsItem } from 'components/PositionSettingsItem/PositionSettingsItem'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useEffect, useState } from 'react'
import { ChooseSiteColorsSync } from 'syncComponents/ChooseSiteColorsSync/ChooseSiteColorsSync'
import { ChooseSiteShadowSync } from 'syncComponents/ChooseSiteShadowSync/ChooseSiteShadowSync'

import { showNotification } from '@mantine/notifications'
import { TextSettingsV2 } from 'syncComponents/EditorTextComponentSync/EditorTextComponentSync'
import { activeResponsiveModeCenter, useCurrentResponsiveMode } from 'utils/activeResponsiveModeCenter'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import React from 'react'

import { synchronFrontendEngineAtSite } from 'generation/synchronFrontendAtSite'
import { useResponsiveBoxGroup } from 'syncComponents/BoxSync/BoxSyncV2'
import { EditBoxBackground, positionSettingsItemsGroups } from './BoxSettingsSync'

const BoxSettingsShellSyncV2 = ({ boxID }: { boxID: string }) => {
  const { siteID } = useSyncSiteContext()

  const box = useGetSelectData({ modelID: boxID, selectID: 'Box1' })

  const { responsiveGroup, setResponsiveBoxSettings, beforeSet } = useResponsiveBoxGroup({
    modelID: boxID,
    selectID: 'Box1',
    name: 'settings',
  })

  const responsiveBoxSettings = useGetSelectData({
    modelID: box.responsiveBoxSettings.id,
    selectID: box.responsiveBoxSettings.selectID,
  })
  const desktopBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.desktopBoxSettings.id,
    selectID: responsiveBoxSettings.desktopBoxSettings.selectID,
  })
  const tabletBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.tabletBoxSettings.id,
    selectID: responsiveBoxSettings.tabletBoxSettings.selectID,
  })
  const mobileBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.mobileBoxSettings.id,
    selectID: responsiveBoxSettings.mobileBoxSettings.selectID,
  })

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxResponsiveSettings = trpc.useMutation('user.box.updateBoxResponsiveSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createBoxBackground = trpc.useMutation('user.box.createBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBoxBackground = trpc.useMutation('user.box.deleteBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const boxBorderData = useGetSelectDataUndefined({
    modelID: responsiveGroup.border.value?.id,
    selectID: responsiveGroup.border.value?.selectID,
  })
  const boxBorderColorData = useGetSelectDataUndefined({
    modelID: boxBorderData?.color?.id,
    selectID: boxBorderData?.color?.selectID,
  })

  const boxShadowData = useGetSelectDataUndefined({
    modelID: responsiveGroup.shadow.value?.id,
    selectID: responsiveGroup.shadow.value?.selectID,
  })
  const boxShadowColorData = useGetSelectDataUndefined({
    modelID: boxShadowData?.color?.id,
    selectID: boxShadowData?.color?.selectID,
  })

  const createBoxBorder = trpc.useMutation('user.box.createBoxBorder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxBorder = trpc.useMutation('user.box.updateBoxBorder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBoxBorder = trpc.useMutation('user.box.deleteBoxBorder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createBoxShadow = trpc.useMutation('user.box.createBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateBox = trpc.useMutation('user.box.updateBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const unpinBoxShadow = trpc.useMutation('user.box.unpinBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxShadow = trpc.useMutation('user.box.updateBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinBoxShadow = trpc.useMutation('user.box.pinBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [chooseBoxShadowOpened, setChooseBoxShadowOpened] = useState(false)

  const responsiveMode = useCurrentResponsiveMode()

  const boxMarginIsEqual = () =>
    responsiveGroup.margin.value.left == responsiveGroup.margin.value.top &&
    responsiveGroup.margin.value.top == responsiveGroup.margin.value.right &&
    responsiveGroup.margin.value.right == responsiveGroup.margin.value.bottom

  const [marginIsOpen, setMarginIsOpen] = useState(!boxMarginIsEqual())

  useEffect(() => {
    setMarginIsOpen(!boxMarginIsEqual())
  }, [JSON.stringify(responsiveGroup.margin.value)])

  const boxPaddingIsEqual = () =>
    responsiveGroup.padding.value.left == responsiveGroup.padding.value.top &&
    responsiveGroup.padding.value.top == responsiveGroup.padding.value.right &&
    responsiveGroup.padding.value.right == responsiveGroup.padding.value.bottom

  const [paddingIsOpen, setPaddingIsOpen] = useState(!boxPaddingIsEqual())

  useEffect(() => {
    setPaddingIsOpen(!boxPaddingIsEqual())
  }, [JSON.stringify(responsiveGroup.padding.value)])

  const borderRadiusIsEqual = () =>
    responsiveGroup.borderRadius.value.LeftTop == responsiveGroup.borderRadius.value.RightTop &&
    responsiveGroup.borderRadius.value.RightTop == responsiveGroup.borderRadius.value.RightBottom &&
    responsiveGroup.borderRadius.value.RightBottom == responsiveGroup.borderRadius.value.LeftBottom

  const [borderRadiusIsOpen, setBorderRadiusIsOpen] = useState(!borderRadiusIsEqual())

  useEffect(() => {
    setBorderRadiusIsOpen(!borderRadiusIsEqual())
  }, [JSON.stringify(responsiveGroup.borderRadius.value)])

  const updateImageComponent = trpc.useMutation('user.box.updateImageComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const imageComponent = useGetSelectDataUndefined({ modelID: box.imageComponent?.id, selectID: box.imageComponent?.selectID })

  return (
    <div
      onClick={event => {
        event.stopPropagation()
      }}
      style={{
        transition: '0.3s',
        zIndex: 10,
      }}
    >
      <EntityLineSettingsBox
        childs={[
          <SimpleGrid css={{ alignItems: 'center' }} cols={2}>
            <EditOneBoxEntity
              value={responsiveGroup.width.value}
              onStart={() => {
                beforeSet.width()
              }}
              onTemporaryChange={value => {
                setResponsiveBoxSettings({
                  data: {
                    width: value,
                  },
                })
              }}
              onChange={value => {
                setResponsiveBoxSettings({
                  data: {
                    width: value,
                  },
                })
                updateBoxSettings.mutateAsync({
                  boxID: box.id,
                  screenType: activeResponsiveModeCenter.getResponsiveMode(),
                  width: value,
                })
              }}
              title={'Ширина'}
            />

            <Group css={{ alignItems: 'center' }} position="apart">
              {responsiveGroup.width.controls}
              <>
                <div
                  css={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    beforeSet.widthType()
                    const widthType = responsiveGroup.widthType.getValue()
                    const newWidthType = widthType == 'Pixel' ? 'Percent' : 'Pixel'
                    setResponsiveBoxSettings({
                      data: {
                        widthType: newWidthType,
                      },
                    })
                    updateBoxSettings.mutateAsync({
                      boxID: box.id,
                      screenType: activeResponsiveModeCenter.getResponsiveMode(),
                      widthType: newWidthType,
                    })
                  }}
                >
                  {responsiveGroup.widthType.value == 'Pixel' ? 'PX' : '%'}
                </div>
                {responsiveGroup.widthType.controls}
              </>
            </Group>
          </SimpleGrid>,
          <SimpleGrid css={{ alignItems: 'center' }} cols={2}>
            <EditOneBoxEntity
              value={responsiveGroup.height.value}
              onStart={() => {
                beforeSet.height()
              }}
              onTemporaryChange={value => {
                setResponsiveBoxSettings({
                  data: {
                    height: value,
                  },
                })
              }}
              onChange={value => {
                setResponsiveBoxSettings({
                  data: {
                    height: value,
                  },
                })
                updateBoxSettings.mutateAsync({
                  boxID: box.id,
                  screenType: activeResponsiveModeCenter.getResponsiveMode(),
                  height: value,
                })
              }}
              title={'Высота'}
            />

            <Group css={{ alignItems: 'center' }} position="apart">
              {responsiveGroup.height.controls}
              <>
                <div
                  css={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    beforeSet.heightType()
                    const heightType = responsiveGroup.heightType.getValue()
                    const newHeightType = heightType == 'Pixel' ? 'Percent' : 'Pixel'
                    setResponsiveBoxSettings({
                      data: {
                        heightType: newHeightType,
                      },
                    })
                    updateBoxSettings.mutateAsync({
                      boxID: box.id,
                      screenType: activeResponsiveModeCenter.getResponsiveMode(),
                      heightType: newHeightType,
                    })
                  }}
                >
                  {responsiveGroup.heightType.value == 'Pixel' ? 'PX' : '%'}
                </div>
                {responsiveGroup.heightType.controls}
              </>
            </Group>
          </SimpleGrid>,
          <div>
            <Grid css={{ alignItems: 'center' }} columns={10}>
              <Grid.Col span={7}>
                <EditOneBoxEntity
                  value={borderRadiusIsEqual() ? responsiveGroup.borderRadius.value.LeftTop : null}
                  onStart={() => {
                    beforeSet.borderRadius()
                  }}
                  onTemporaryChange={value => {
                    setResponsiveBoxSettings({
                      data: {
                        borderRadiusLeftBottom: value,
                        borderRadiusLeftTop: value,
                        borderRadiusRightBottom: value,
                        borderRadiusRightTop: value,
                      },
                    })
                  }}
                  onChange={value => {
                    updateBoxSettings.mutateAsync({
                      boxID: box.id,
                      screenType: activeResponsiveModeCenter.getResponsiveMode(),
                      borderRadiusLeftBottom: value,
                      borderRadiusLeftTop: value,
                      borderRadiusRightBottom: value,
                      borderRadiusRightTop: value,
                    })
                  }}
                  title={'Cкругления'}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Group position="right">
                  <ActionIcon
                    css={{
                      opacity: borderRadiusIsOpen ? 1 : 0.5,
                    }}
                    size={20}
                    onClick={async event => {
                      setBorderRadiusIsOpen(o => !o)
                    }}
                  >
                    <IconSettings
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                    />
                  </ActionIcon>
                  <Group position="right">{responsiveGroup.borderRadius.controls}</Group>
                </Group>
              </Grid.Col>
            </Grid>

            {borderRadiusIsOpen ? (
              <>
                <SimpleGrid mt={4} cols={2}>
                  <EditOneBoxEntity
                    value={responsiveGroup.borderRadius.value.LeftTop}
                    onStart={() => {
                      beforeSet.borderRadius()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          borderRadiusLeftTop: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        borderRadiusLeftTop: value,
                      })
                    }}
                    title={'Слев-сверх'}
                  />
                  <EditOneBoxEntity
                    value={responsiveGroup.borderRadius.value.RightTop}
                    onStart={() => {
                      beforeSet.borderRadius()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          borderRadiusRightTop: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        borderRadiusRightTop: value,
                      })
                    }}
                    title={'Справ-сверх'}
                  />
                </SimpleGrid>
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={responsiveGroup.borderRadius.value.LeftBottom}
                    onStart={() => {
                      beforeSet.borderRadius()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          borderRadiusLeftBottom: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        borderRadiusLeftBottom: value,
                      })
                    }}
                    title={'Слев-сниз'}
                  />
                  <EditOneBoxEntity
                    value={responsiveGroup.borderRadius.value.RightBottom}
                    onStart={() => {
                      beforeSet.borderRadius()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          borderRadiusRightBottom: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        borderRadiusRightBottom: value,
                      })
                    }}
                    title={'Справ-сниз'}
                  />
                </SimpleGrid>
              </>
            ) : null}
          </div>,
          1 ? ( // box.boxes && box.boxes.length > 1
            <Grid css={{ alignItems: 'center' }} columns={10}>
              <Grid.Col span={8}>
                <EditOneBoxEntity
                  value={responsiveGroup.gap.value}
                  onStart={() => {
                    beforeSet.gap()
                  }}
                  onTemporaryChange={value => {
                    setResponsiveBoxSettings({
                      data: {
                        gap: value || 0,
                      },
                    })
                  }}
                  onChange={value => {
                    updateBoxSettings.mutateAsync({
                      boxID: box.id,
                      screenType: activeResponsiveModeCenter.getResponsiveMode(),
                      gap: value || 0,
                    })
                  }}
                  title={'Отступы между слоями'}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Group position="right">{responsiveGroup.gap.controls}</Group>
              </Grid.Col>
            </Grid>
          ) : undefined,
          responsiveGroup.positionType.value.positionType == 'Relative' && (box.parentBoxId || box.popup?.id) ? (
            <div>
              <Grid css={{ alignItems: 'center' }} columns={10}>
                <Grid.Col span={7}>
                  <EditOneBoxEntity
                    value={boxMarginIsEqual() ? responsiveGroup.margin.value.top : null}
                    onStart={() => {
                      beforeSet.margin()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          marginTop: value,
                          marginBottom: value,
                          marginLeft: value,
                          marginRight: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        marginTop: value,
                        marginBottom: value,
                        marginLeft: value,
                        marginRight: value,
                      })
                    }}
                    title={'Отступы снаружи'}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Group position="right">
                    <ActionIcon
                      css={{
                        opacity: marginIsOpen ? 1 : 0.5,
                      }}
                      size={20}
                      onClick={async event => {
                        setMarginIsOpen(o => !o)
                      }}
                    >
                      <IconSettings
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                      />
                    </ActionIcon>
                    <Group position="right">{responsiveGroup.margin.controls}</Group>
                  </Group>
                </Grid.Col>
              </Grid>

              {marginIsOpen ? (
                <>
                  <SimpleGrid mt={4} cols={2} mb={4}>
                    <EditOneBoxEntity
                      value={responsiveGroup.margin.value.top}
                      onStart={() => {
                        beforeSet.margin()
                      }}
                      onTemporaryChange={value => {
                        setResponsiveBoxSettings({
                          data: {
                            marginTop: value,
                          },
                        })
                      }}
                      onChange={value => {
                        updateBoxSettings.mutateAsync({
                          boxID: box.id,
                          screenType: activeResponsiveModeCenter.getResponsiveMode(),
                          marginTop: value,
                        })
                      }}
                      title={'Сверху'}
                    />
                    <EditOneBoxEntity
                      value={responsiveGroup.margin.value.right}
                      onStart={() => {
                        beforeSet.margin()
                      }}
                      onTemporaryChange={value => {
                        setResponsiveBoxSettings({
                          data: {
                            marginRight: value,
                          },
                        })
                      }}
                      onChange={value => {
                        updateBoxSettings.mutateAsync({
                          boxID: box.id,
                          screenType: activeResponsiveModeCenter.getResponsiveMode(),
                          marginRight: value,
                        })
                      }}
                      title={'Справа'}
                    />
                  </SimpleGrid>
                  <SimpleGrid cols={2}>
                    <EditOneBoxEntity
                      value={responsiveGroup.margin.value.bottom}
                      onStart={() => {
                        beforeSet.margin()
                      }}
                      onTemporaryChange={value => {
                        setResponsiveBoxSettings({
                          data: {
                            marginBottom: value,
                          },
                        })
                      }}
                      onChange={value => {
                        updateBoxSettings.mutateAsync({
                          boxID: box.id,
                          screenType: activeResponsiveModeCenter.getResponsiveMode(),
                          marginBottom: value,
                        })
                      }}
                      title={'Снизу'}
                    />
                    <EditOneBoxEntity
                      value={responsiveGroup.margin.value.left}
                      onStart={() => {
                        beforeSet.margin()
                      }}
                      onTemporaryChange={value => {
                        setResponsiveBoxSettings({
                          data: {
                            marginLeft: value,
                          },
                        })
                      }}
                      onChange={value => {
                        updateBoxSettings.mutateAsync({
                          boxID: box.id,
                          screenType: activeResponsiveModeCenter.getResponsiveMode(),
                          marginLeft: value,
                        })
                      }}
                      title={'Слева'}
                    />
                  </SimpleGrid>
                </>
              ) : null}
            </div>
          ) : undefined,
          <div>
            <Grid css={{ alignItems: 'center' }} columns={10}>
              <Grid.Col span={7}>
                <EditOneBoxEntity
                  value={boxPaddingIsEqual() ? responsiveGroup.padding.value.top : null}
                  onStart={() => {
                    beforeSet.padding()
                  }}
                  onTemporaryChange={value => {
                    setResponsiveBoxSettings({
                      data: {
                        paddingTop: value,
                        paddingBottom: value,
                        paddingLeft: value,
                        paddingRight: value,
                      },
                    })
                  }}
                  onChange={value => {
                    updateBoxSettings.mutateAsync({
                      boxID: box.id,
                      screenType: activeResponsiveModeCenter.getResponsiveMode(),
                      paddingTop: value,
                      paddingBottom: value,
                      paddingLeft: value,
                      paddingRight: value,
                    })
                  }}
                  title={'Отступы внутри'}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Group position="right">
                  <ActionIcon
                    css={{
                      opacity: paddingIsOpen ? 1 : 0.5,
                    }}
                    size={20}
                  >
                    <IconSettings
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                      onClick={async () => {
                        setPaddingIsOpen(o => !o)
                      }}
                    />
                  </ActionIcon>
                  <Group position="right">{responsiveGroup.padding.controls}</Group>
                </Group>
              </Grid.Col>
            </Grid>
            {paddingIsOpen ? (
              <>
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={responsiveGroup.padding.value.top}
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          paddingTop: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingTop: value,
                      })
                    }}
                    title={'Сверху'}
                    // position="top"
                  />
                  <EditOneBoxEntity
                    value={responsiveGroup.padding.value.right}
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          paddingRight: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingRight: value,
                      })
                    }}
                    // position={'right'}
                    title={'Справа'}
                  />
                </SimpleGrid>
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={responsiveGroup.padding.value.bottom}
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          paddingBottom: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingBottom: value,
                      })
                    }}
                    title={'Снизу'}
                    // position="bottom"
                  />
                  <EditOneBoxEntity
                    value={responsiveGroup.padding.value.left}
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          paddingLeft: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingLeft: value,
                      })
                    }}
                    title={'Слева'}
                  />
                </SimpleGrid>
              </>
            ) : null}
            {/* <SimpleGrid cols={1}>
            </SimpleGrid> */}
          </div>,
          1 ? ( // box.parentBoxId
            <div>
              <SimpleGrid spacing={8} cols={1}>
                <Grid columns={10}>
                  <Grid.Col span={8}>
                    <div
                      css={{
                        fontSize: 12,
                        padding: '0 7px',
                      }}
                    >
                      <span
                        css={{
                          color: colors.blue,
                        }}
                      >
                        Положение слоя:{' '}
                      </span>
                      <span
                        css={{
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          beforeSet.positionType()

                          const positionType = responsiveGroup.positionType.getValue().positionType

                          const newPositionType = positionType == 'Relative' ? 'Absolute' : 'Relative'

                          setResponsiveBoxSettings({
                            data: {
                              positionType: newPositionType,
                            },
                          })

                          updateBoxSettings.mutateAsync({
                            boxID: box.id,
                            screenType: activeResponsiveModeCenter.getResponsiveMode(),
                            positionType: newPositionType,
                          })
                        }}
                      >
                        {responsiveGroup.positionType.value.positionType == 'Relative' ? 'Вложенный' : 'Свободное'}
                      </span>
                    </div>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Group position="right">{responsiveGroup.positionType.controls}</Group>
                  </Grid.Col>
                </Grid>

                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={responsiveGroup.positionType.value.positionX}
                    onStart={() => {
                      beforeSet.positionType()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          positionX: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        positionX: value,
                      })
                    }}
                    title={'X'}
                  />

                  <EditOneBoxEntity
                    value={responsiveGroup.positionType.value.positionY}
                    onStart={() => {
                      beforeSet.positionType()
                    }}
                    onTemporaryChange={value => {
                      setResponsiveBoxSettings({
                        data: {
                          positionY: value,
                        },
                      })
                    }}
                    onChange={value => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        positionY: value,
                      })
                    }}
                    title={'Y'}
                  />
                </SimpleGrid>
              </SimpleGrid>
            </div>
          ) : null,
          box.textComponent ? (
            <TextSettingsV2 textComponentData={{ id: box.textComponent.id, selectID: box.textComponent.selectID }} />
          ) : undefined,
          <div css={{}}>
            <SimpleGrid spacing={8} cols={1}>
              <Group position="apart">
                <TitleOfSettings title={'Выравнивание'} />
                <Group>{responsiveGroup.position.controls}</Group>
              </Group>
              <Group css={{ padding: '0px 7px' }} spacing={4}>
                {positionSettingsItemsGroups.map((group, index) => {
                  return (
                    <SimpleGrid spacing={1} cols={1} key={index}>
                      {group.map((positionSettingsItems, index) => {
                        return (
                          <Group key={index} spacing={1}>
                            {positionSettingsItems.map(
                              (
                                { horizontalPosition: horizontalPositionLoop, verticalPosition: verticalPositionLoop, vector },
                                index
                              ) => {
                                return (
                                  <PositionSettingsItem
                                    key={index}
                                    actualVector={responsiveGroup.position.value.vector}
                                    vector={vector}
                                    actualHorizontalPosition={responsiveGroup.position.value.horizontalPosition}
                                    actualVerticalPosition={responsiveGroup.position.value.verticalPosition}
                                    horizontalPosition={horizontalPositionLoop}
                                    verticalPosition={verticalPositionLoop}
                                    onUpdatePosition={({ horizontalPosition, verticalPosition }) => {
                                      beforeSet.position()

                                      setResponsiveBoxSettings({
                                        data: {
                                          vector: vector,
                                          horizontalPosition,
                                          verticalPosition,
                                        },
                                      })

                                      updateBoxSettings.mutateAsync({
                                        boxID: box.id,
                                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                                        vector: vector,
                                        horizontalPosition,
                                        verticalPosition,
                                      })
                                    }}
                                  />
                                )
                              }
                            )}
                          </Group>
                        )
                      })}
                    </SimpleGrid>
                  )
                })}
              </Group>
            </SimpleGrid>
          </div>,
          <div>
            <SimpleGrid cols={1}>
              <SimpleGrid
                css={{
                  alignItems: 'center',
                }}
                cols={2}
              >
                <TitleOfSettings title={'Фон'} />
                <Group
                  css={{
                    alignItems: 'center',
                  }}
                  position={responsiveGroup.background.mode !== responsiveMode ? 'right' : 'apart'}
                >
                  {responsiveGroup.background.controls}

                  {responsiveGroup.background.mode !== responsiveMode ? (
                    <></>
                  ) : (
                    <>
                      {responsiveGroup.background.value ? (
                        <ActionIcon size={20}>
                          <IconX
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={async () => {
                              const background = responsiveGroup.background.getValue()
                              if (background) {
                                await deleteBoxBackground.mutateAsync({
                                  boxBackgroundID: background.id,
                                })
                              }
                            }}
                          />
                        </ActionIcon>
                      ) : (
                        <ActionIcon size={20}>
                          <IconPlus
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={async () => {
                              await createBoxBackground.mutateAsync({
                                boxID: box.id,
                                siteID: siteID,
                                screenType: responsiveGroup.background.getMode(),
                              })
                            }}
                          />
                        </ActionIcon>
                      )}
                    </>
                  )}
                </Group>
              </SimpleGrid>

              {responsiveGroup.background.mode !== responsiveMode ? null : (
                <>
                  {responsiveGroup.background.value ? (
                    <>
                      <EditBoxBackground
                        modelID={responsiveGroup.background.value.id}
                        selectID={responsiveGroup.background.value.selectID}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </SimpleGrid>
          </div>,
          <div>
            <SimpleGrid cols={1}>
              <Group spacing={2} position="apart">
                <TitleOfSettings title={'Бордер'} />

                {responsiveGroup.border.value ? (
                  <ActionIcon size={20}>
                    <IconX
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                      onClick={async () => {
                        if (responsiveGroup.border.getValue())
                          await deleteBoxBorder.mutateAsync({
                            boxID: box.id,
                            screenType: responsiveGroup.border.getMode(),
                          })
                      }}
                    />
                  </ActionIcon>
                ) : (
                  <ChooseSiteColorsSync
                    onChooseColor={async ({ colorID }) => {
                      const createdBoxBorder = await createBoxBorder.mutateAsync({
                        siteID: siteID,
                        boxID: box.id,
                        colorID: colorID,
                        screenType: responsiveGroup.border.getMode(),
                      })
                    }}
                  >
                    <ActionIcon size={20}>
                      <IconPlus
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                      />
                    </ActionIcon>
                  </ChooseSiteColorsSync>
                )}
              </Group>

              {boxBorderData ? (
                <>
                  <SimpleGrid cols={2}>
                    <EditOneBoxEntity
                      value={boxBorderData.left}
                      onTemporaryChange={value => {
                        const border = responsiveGroup.border.getValue()
                        if (border) {
                          synchronFrontendEngineAtSite.updatePartialData.BoxBorder({
                            id: border.id,
                            data: {
                              left: value || 0,
                              right: value || 0,
                              top: value || 0,
                              bottom: value || 0,
                            },
                          })
                        }
                      }}
                      onChange={async value => {
                        const border = responsiveGroup.border.getValue()
                        if (typeof value == 'number' && border) {
                          await updateBoxBorder.mutateAsync({
                            boxBorderID: border.id,
                            left: value,
                            right: value,
                            top: value,
                            bottom: value,
                          })
                        }
                      }}
                      title={'Размер'}
                    />
                    <Group position="right">
                      <ChooseSiteColorsSync
                        currentColorID={boxBorderColorData?.id}
                        onChooseColor={async ({ colorID }) => {
                          const border = responsiveGroup.border.getValue()
                          if (border) {
                            await updateBoxBorder.mutateAsync({
                              boxBorderID: border.id,
                              colorID: colorID,
                            })
                          }
                        }}
                      >
                        {boxBorderColorData ? (
                          <ColorSwatch
                            component="button"
                            color={boxBorderColorData?.value}
                            sx={{ color: '#fff', cursor: 'pointer' }}
                          ></ColorSwatch>
                        ) : (
                          <></>
                        )}
                      </ChooseSiteColorsSync>
                    </Group>
                  </SimpleGrid>
                </>
              ) : (
                <></>
              )}
            </SimpleGrid>
          </div>,
          <div css={{ width: '100%' }}>
            <SimpleGrid cols={1}>
              <Group spacing={2} position="apart">
                <TitleOfSettings title={'Тень'} />
                <Group css={{ alignItems: 'center' }}>
                  <ChooseSiteShadowSync
                    currentShadowID={boxShadowData?.id}
                    onChooseShadow={async ({ shadowID }) => {
                      await pinBoxShadow.mutateAsync({
                        boxID: box.id,
                        screenType: responsiveGroup.shadow.getMode(),
                        boxShadowID: shadowID,
                      })
                    }}
                  >
                    <ActionIcon size={20}>
                      <IconGridDots
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                        // onClick={() => setChooseBoxShadowOpened(true)}
                        // title="Выбрать одну из теней"
                      />
                    </ActionIcon>
                  </ChooseSiteShadowSync>

                  {boxShadowData ? (
                    <ActionIcon size={20}>
                      <IconX
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                        onClick={async () => {
                          if (boxShadowData)
                            await unpinBoxShadow.mutateAsync({
                              boxID: box.id,
                              screenType: responsiveGroup.shadow.getMode(),
                              boxShadowID: boxShadowData.id,
                            })
                        }}
                      />
                    </ActionIcon>
                  ) : (
                    <ChooseSiteColorsSync
                      onChooseColor={async ({ colorID }) => {
                        const updatedBox = await createBoxShadow.mutateAsync({
                          siteID: siteID,
                          boxID: box.id,
                          colorID: colorID,
                          screenType: responsiveGroup.shadow.getMode(),
                        })
                      }}
                    >
                      <ActionIcon size={20}>
                        <IconPlus
                          css={{
                            cursor: 'pointer',
                          }}
                          color={colors.blue}
                        />
                      </ActionIcon>
                    </ChooseSiteColorsSync>
                  )}
                </Group>
              </Group>

              {boxShadowData ? (
                <>
                  <SimpleGrid>
                    <Group spacing={2} position="apart">
                      <TitleOfSettings title={'Цвет'} />
                      <Group>
                        <ChooseSiteColorsSync
                          currentColorID={boxShadowColorData?.id}
                          onChooseColor={async ({ colorID }) => {
                            if (boxShadowData) {
                              await updateBoxShadow.mutateAsync({
                                boxShadowID: boxShadowData.id,
                                colorID: colorID,
                              })
                            }
                          }}
                        >
                          <ColorSwatch
                            component="button"
                            color={boxShadowColorData?.value || 'white'}
                            sx={{ color: '#fff', cursor: 'pointer' }}
                          />
                        </ChooseSiteColorsSync>
                      </Group>
                    </Group>
                    <SimpleGrid cols={2}>
                      <EditOneBoxEntity
                        value={boxShadowData.xShift}
                        onTemporaryChange={value => {
                          synchronFrontendEngineAtSite.updatePartialData.BoxShadow({
                            id: boxShadowData.id,
                            data: {
                              xShift: value || 0,
                            },
                          })
                        }}
                        onChange={async value => {
                          if (typeof value == 'number' && boxShadowData) {
                            await updateBoxShadow.mutateAsync({
                              boxShadowID: boxShadowData.id,
                              xShift: value,
                            })
                          }
                        }}
                        title={'X'}
                      />

                      <EditOneBoxEntity
                        value={boxShadowData.blur}
                        onTemporaryChange={value => {
                          synchronFrontendEngineAtSite.updatePartialData.BoxShadow({
                            id: boxShadowData.id,
                            data: {
                              blur: value || 0,
                            },
                          })
                        }}
                        onChange={async value => {
                          if (typeof value == 'number' && boxShadowData) {
                            await updateBoxShadow.mutateAsync({
                              boxShadowID: boxShadowData.id,
                              blur: value,
                            })
                          }
                        }}
                        title={'Blur'}
                      />

                      <EditOneBoxEntity
                        value={boxShadowData.yShift}
                        onTemporaryChange={value => {
                          synchronFrontendEngineAtSite.updatePartialData.BoxShadow({
                            id: boxShadowData.id,
                            data: {
                              yShift: value || 0,
                            },
                          })
                        }}
                        onChange={async value => {
                          if (typeof value == 'number' && boxShadowData) {
                            await updateBoxShadow.mutateAsync({
                              boxShadowID: boxShadowData.id,
                              yShift: value,
                            })
                          }
                        }}
                        title={'Y'}
                      />
                      <EditOneBoxEntity
                        value={boxShadowData.spread}
                        onTemporaryChange={value => {
                          synchronFrontendEngineAtSite.updatePartialData.BoxShadow({
                            id: boxShadowData.id,
                            data: {
                              spread: value || 0,
                            },
                          })
                        }}
                        onChange={async value => {
                          if (typeof value == 'number' && boxShadowData) {
                            await updateBoxShadow.mutateAsync({
                              boxShadowID: boxShadowData.id,
                              spread: value,
                            })
                          }
                        }}
                        title={'Spread'}
                      />
                    </SimpleGrid>
                  </SimpleGrid>
                </>
              ) : (
                <></>
              )}
            </SimpleGrid>
          </div>,

          <Group position="apart">
            <TitleOfSettings title={'Скрыт'} />
            <Group css={{ alignItems: 'center' }} position="right">
              <Switch

                checked={responsiveGroup.hidden.value}
                onChange={event => {
                  beforeSet.hidden()

                  const hidden = responsiveGroup.hidden.getValue()

                  const newHidden = !hidden

                  setResponsiveBoxSettings({
                    data: {
                      hidden: newHidden,
                    },
                  })

                  updateBoxSettings.mutateAsync({
                    boxID: box.id,
                    screenType: activeResponsiveModeCenter.getResponsiveMode(),
                    hidden: newHidden,
                  })
                }}
                size="md"
                // label={'Скрыт'}
                onLabel={'Да'}
                offLabel={'Нет'}
              />

              {responsiveGroup.hidden.controls}
            </Group>
          </Group>,

          <Group position="apart">
            <TitleOfSettings title={'Обрезано за краями'} />
            <Group css={{ alignItems: 'center' }} position="right">
              <Switch

                checked={responsiveGroup.overflowHidden.value}
                onChange={event => {
                  beforeSet.overflowHidden()

                  const overflowHidden = responsiveGroup.overflowHidden.getValue()

                  const newOverflowHidden = !overflowHidden

                  setResponsiveBoxSettings({
                    data: {
                      isOverflowHidden: newOverflowHidden,
                    },
                  })

                  updateBoxSettings.mutateAsync({
                    boxID: box.id,
                    screenType: activeResponsiveModeCenter.getResponsiveMode(),
                    isOverflowHidden: newOverflowHidden,
                  })
                }}
                size="md"
                // label={'Скрыт'}
                onLabel={'Да'}
                offLabel={'Нет'}
              />

              {responsiveGroup.overflowHidden.controls}
            </Group>
          </Group>,

          imageComponent ? (
            <Group position="apart">
              <TitleOfSettings title={'Растянута картинка'} />
              <Group css={{ alignItems: 'center' }} position="right">
                <Switch

                  checked={imageComponent.isCover}
                  onChange={event => {
                    if (imageComponent) {
                      const newIsCover = !imageComponent.isCover

                      synchronFrontendEngineAtSite.updatePartialData.ImageComponent({
                        id: imageComponent.id,
                        data: {
                          isCover: newIsCover,
                        },
                      })

                      updateImageComponent.mutateAsync({
                        imageComponentID: imageComponent.id,
                        isCover: newIsCover,
                      })
                    }
                  }}
                  size="md"
                  onLabel={'Да'}
                  offLabel={'Нет'}
                />
              </Group>
            </Group>
          ) : (
            <>
              <Group position="apart">
                <TitleOfSettings title={'Растянута картинка'} />
                <Group css={{ alignItems: 'center' }} position="right">
                  <Switch

                    checked={box.tableImageIsCover}
                    onChange={async event => {
                      const newValue = !box.tableImageIsCover

                      await updateBox.mutate({
                        boxID: box.id,
                        tableImageIsCover: newValue,
                      })

                      synchronFrontendEngineAtSite.updatePartialData.Box({
                        id: box.id,
                        data: {
                          tableImageIsCover: newValue,
                        },
                      })
                    }}
                    size="md"
                    onLabel={'Да'}
                    offLabel={'Нет'}
                  />
                </Group>
              </Group>
            </>
          ),
        ]}
      />
    </div>
  )
}

export const BoxSettingsShellSyncMemoV2 = React.memo(BoxSettingsShellSyncV2, (prevProps, nextProps) => {
  return prevProps.boxID == nextProps.boxID
})
