import React from 'react'

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode; message: string },
  {
    hasError: boolean
  }
> {
  // @ts-ignore
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   // logErrorToMyService(error, errorInfo)
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>{this.props.message}</p>
    }

    return this.props.children
  }
}
