import { CheckIcon, ColorSwatch, Divider, Popover, SimpleGrid } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { ReactNode, useState } from 'react'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { ActionIcon, Button, Group } from '@mantine/core'
import { IconSettings, IconTrash, IconWashDrycleanOff, IconX } from '@tabler/icons'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { SketchPicker } from 'react-color'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

export const ChooseSiteColorsSync = ({
  onChooseColor,
  onClearColor,
  currentColorID,
  children,
  inversion,
}: {
  onChooseColor: ({}: { colorID: string; colorName: string }) => void
  onClearColor?: () => void
  currentColorID?: string
  children: ReactNode
  inversion?: true
}) => {
  const { siteID } = useSyncSiteContext()

  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const offset = 10

  const [opened, setOpened] = useState(false)
  const [openedColorAdd, setOpenedColorAdd] = useState(false)

  const [color, setColor] = useState('#fff')

  const updateOneColor = trpc.useMutation('user.updateOneColor', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createColor = trpc.useMutation('user.createColor', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSiteMainColor = trpc.useMutation('user.updateSiteMainColor', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const { classes } = useMainStyles()

  return (
    <>
      <Popover withinPortal opened={opened} width={250} offset={4} shadow="md">
        {/* onChange={setOpened} */}
        <Popover.Target>
          <div onClick={() => setOpened(o => !o)}>{children}</div>
        </Popover.Target>
        <Popover.Dropdown
          css={{
            padding: 8,
            maxHeight: 'calc(50vh - 100px)',
            overflowY: 'auto',
          }}
          className={classes.hideScrollbar}
        >
          <SimpleGrid spacing={8}>
            <Group
              css={{
                alignItems: 'center',
                alignContent: 'center',
              }}
              position="apart"
              spacing={8}
            >
              <Group spacing={8}>
                <Popover withinPortal opened={openedColorAdd} onChange={setOpenedColorAdd} width={100} shadow="md">
                  <Popover.Target>
                    <ColorSwatch
                      css={{
                        cursor: 'pointer',
                      }}
                      component="button"
                      color={color}
                      sx={{ color: '#fff' }}
                      onClick={() => setOpenedColorAdd(o => !o)}
                      onContextMenu={event => {
                        event.preventDefault()
                        setOpenedColorAdd(true)
                      }}
                    ></ColorSwatch>
                  </Popover.Target>
                  <Popover.Dropdown
                    css={{
                      padding: 0,
                      borderRadius: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    <SketchPicker
                      color={color}
                      onChange={color => {
                        setColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
                      }}
                    />
                  </Popover.Dropdown>
                </Popover>
                <Button
                  loading={createColor.isLoading}
                  onClick={() => {
                    createColor.mutate({
                      value: color,
                      siteID: site.id,
                    })
                  }}
                  compact
                >
                  добавить цвет
                </Button>
              </Group>
              <ActionIcon onClick={() => {}} size={20}>
                <IconX
                  onClick={() => {
                    setOpened(false)
                  }}
                />
              </ActionIcon>
            </Group>
            <Divider color={'#efefef'} size={1} />
            <SimpleGrid spacing={8} cols={1}>
              {onClearColor ? (
                <CalcWidth
                  width={32}
                  side={'left'}
                  onClick={() => {
                    setOpened(false)
                    onClearColor()
                  }}
                  leftNode={
                    <ColorSwatch
                      css={{
                        display: 'block',
                      }}
                      title="Очистить цвет"
                      component="button"
                      color={'#fff'}
                      sx={{ color: 'rgb(0, 134, 255)', cursor: 'pointer' }}
                    >
                      <IconWashDrycleanOff width={'60%'} />
                    </ColorSwatch>
                  }
                  rightNode={
                    <Button  compact color={colors.blue}>
                      очистить
                    </Button>
                  }
                />
              ) : null}

              {site.colors.map(color => (
                <Color
                  currentColorID={currentColorID}
                  modelID={color.id}
                  selectID={color.selectID}
                  onChooseColor={args => {
                    if (inversion) {
                      updateSiteMainColor.mutate({
                        siteID: site.id,
                        colorID: color.id,
                      })
                    } else {
                      setOpened(false)
                    }
                    onChooseColor(args)
                  }}
                />
              ))}
            </SimpleGrid>
          </SimpleGrid>
        </Popover.Dropdown>
      </Popover>

      {/* <div
      // css={{
      //   position: 'relative',
      // }}
      // onMouseEnter={() => {
      //   console.log('enter')
      //   setOpen(true)
      // }}
      // onMouseLeave={() => setOpen(false)}
      > */}
      {/* <div
          css={{
            '& > svg': {
              display: 'block',
            },
          }}
        >
          {' '}
          {children}
        </div>
        {open ? (
          
        ) : null}
      </div> */}
    </>
  )
}

function Color({
  modelID,
  selectID,
  onChooseColor,
  currentColorID,
}: {
  modelID: string
  selectID: 'Color1'
  onChooseColor: ({}: { colorID: string; colorName: string }) => void
  currentColorID?: string
}) {
  const color = useGetSelectData({ modelID, selectID })
  const { siteID } = useSyncSiteContext()
  const updateSiteMainColor = trpc.useMutation('user.updateSiteMainColor', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateOneColor = trpc.useMutation('user.updateOneColor', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteColor = trpc.useMutation('user.deleteColor', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const [opened, setOpened] = useState(false)
  return (
    <>
      <CalcWidth
        width={60}
        leftNode={
          <CalcWidth
            width={32}
            side={'left'}
            leftNode={
              <ColorSwatch
                size={24}
                key={color.id}
                component="button"
                color={color.value}
                onContextMenu={event => {
                  event.preventDefault()
                  setOpened(o => !o)
                }}
                css={{
                  display: 'block',
                }}
                onClick={() => {
                  onChooseColor({ colorID: color.id, colorName: color.name })
                }}
                radius={site.mainColor && site.mainColor.id == color.id ? 2 : undefined}
                sx={{
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                {currentColorID == color.id && <CheckIcon width={10} />}
              </ColorSwatch>
            }
            rightNode={
              <TextInputWithUpdate
                unstyled={true}
                value={color.viewName}
                placeholder={'Название цвета'}
                onChange={async value => {
                  updateOneColor.mutate({
                    viewName: value,
                    colorID: color.id,
                  })
                }}
              />
            }
          />
        }
        rightNode={
          <Group spacing={4} position={'right'}>
            <ActionIcon color={'red'} onClick={() => {}} size={20}>
              <IconTrash
                onClick={() => {
                  deleteColor.mutate({
                    colorID: color.id,
                  })
                }}
              />
            </ActionIcon>

            <Popover withinPortal opened={opened} onChange={setOpened} key={color.id} width={250} shadow="md">
              <Popover.Target>
                <ActionIcon
                  color={colors.blue}
                  onClick={() => {
                    setOpened(o => !o)
                  }}
                  size={20}
                >
                  <IconSettings />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown
                css={{
                  padding: 8,
                }}
              >
                <SimpleGrid spacing={8} cols={1}>
                  <SketchPicker
                    styles={{
                      picker: {
                        // @ts-ignore
                        padding: 0,
                        // @ts-ignore
                        borderRadius: 'none',
                        // @ts-ignore
                        boxShadow: 'none',
                      },
                    }}
                    color={color.value}
                    onChangeComplete={newColor => {
                      updateOneColor.mutate({
                        value: `rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`,
                        colorID: color.id,
                      })
                    }}
                  />
                  <Button
                    onClick={() => {
                      updateSiteMainColor.mutate({
                        siteID: site.id,
                        colorID: color.id,
                      })
                    }}
                    color={colors.blue}

                    fullWidth
                    compact
                  >
                    {site.mainColor && site.mainColor.id == color.id ? 'Главный цвет' : 'Установить главным'}
                  </Button>
                </SimpleGrid>
              </Popover.Dropdown>
            </Popover>
          </Group>
        }
      />
    </>
  )
}
