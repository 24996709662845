import { Button, Container, Group, HoverCard, Paper, SimpleGrid } from '@mantine/core'
import { TextWrap } from 'components/TextWrap'
import { SiteContext, SiteContextProvider } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { trpc } from 'utils/trpc'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

export function SitePopups() {
  const createPopup = trpc.useMutation('user.createPopup')
  const deletePopup = trpc.useMutation('user.deletePopup')
  const site = useContext(SiteContext)
  return (
    <Container py={'md'}>
      <Group mb={'lg'}>
        <HoverCard width={280} shadow="md">
          <HoverCard.Target>
            <Button compact>Создать попап с формой</Button>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <SimpleGrid cols={1}>
              {site.formsV1
                .filter(form => {
                  return !site.popupsV1.find(popup => popup.form && popup.form.id == form.id)
                })
                .map(form => (
                  <Paper
                    withBorder
                    p={'xs'}
                    key={form.id}
                    css={{ cursor: 'pointer' }}
                    onClick={() =>
                      createPopup.mutate({
                        siteID: site.id,
                        formID: form.id,
                      })
                    }
                  >
                    <TextWrap text={form.title} />
                  </Paper>
                ))}
            </SimpleGrid>
          </HoverCard.Dropdown>
        </HoverCard>
        <HoverCard width={280} shadow="md">
          <HoverCard.Target>
            <Button compact>Создать попап с квизом</Button>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <SimpleGrid cols={1}>
              {site.quizzesV1
                .filter(quiz => {
                  return !site.popupsV1.find(popup => popup.quiz && popup.quiz.id == quiz.id)
                })
                .map(quiz => (
                  <Paper
                    withBorder
                    p="xs"
                    key={quiz.id}
                    css={{ cursor: 'pointer' }}
                    onClick={() =>
                      createPopup.mutate({
                        siteID: site.id,
                        quizID: quiz.id,
                      })
                    }
                  >
                    <TextWrap text={quiz.title} />
                  </Paper>
                ))}
            </SimpleGrid>
          </HoverCard.Dropdown>
        </HoverCard>
      </Group>
      <SimpleGrid cols={3}>
        {site.popupsV1.map(popup => (
          <Paper withBorder p={'xs'}>
            <Button
              mb={'xs'}
              fullWidth
              loading={deletePopup.isLoading}
              color={'red'}
              compact
              onClick={() =>
                deletePopup.mutate({
                  popupID: popup.id,
                })
              }
            >
              Удалить попап
            </Button>

            {popup.form ? (
              <div>
                Попап с формой: <TextWrap text={popup.form.title} />
              </div>
            ) : null}
            {popup.quiz ? (
              <div>
                Попап с квизом: <TextWrap text={popup.quiz.title} />
              </div>
            ) : null}
          </Paper>
        ))}
      </SimpleGrid>
    </Container>
  )
}
