import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import React, { CSSProperties, useEffect } from 'react'
import useStateRef from 'react-usestateref'
import { PagePopupsSyncMemo } from 'syncComponents/PagePopupsSync/PagePopupsSync'
import { boxActiveIDCenter } from 'utils/boxActiveIDCenter'

import { showNotification } from '@mantine/notifications'
import { trpc } from 'utils/trpc'

import { IconMinus } from '@tabler/icons'
import { useMainStyles } from 'pages/panel/sitesV2/[siteID]/pages/[pageID]'
import { useState } from 'react'

import { colors } from 'utils/styles'

import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'

import { Group, SimpleGrid, Text } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { useResponsiveBoxGroup } from 'syncComponents/BoxSync/BoxSyncV2'

type Position = {
  y: number
  x: number
}

export const LayerSettingsSync = ({ pageID }: { pageID: string }) => {
  const [position, setPosition] = useState<Position>({
    y: 700,
    x: 10,
  })
  const [temporaryPosition, setTemporaryPosition] = useState<Position | undefined>(undefined)
  const { classes } = useMainStyles()
  const tabHeight = 32
  const [hidden, setHidden] = useLocalStorage({ key: 'layers-is-open', defaultValue: false })
  const [tab, setTab] = useState(0)
  return (
    <div
      css={{
        position: 'absolute',
        bottom: 0,
        minHeight: tabHeight,
        maxHeight: `calc(100% - ${tabHeight}px)`,
        height: hidden ? tabHeight : temporaryPosition ? temporaryPosition.y : position.y,
        left: temporaryPosition ? temporaryPosition.x : position.x,
        width: '300px',
        borderRadius: '2px 2px 0px 0px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        // overflow: 'hidden',
        background: colors.blue,
        zIndex: 10000,
      }}
    >
      <div
        css={{
          width: '100%',
          height: tabHeight,
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'space-between',
          paddingRight: 8,
          // background: boxHeight.isDrag ? '#0086ff' : 'white',
          color: 'white',
        }}
      >
        <Group>
          <DragOffsetPosition
            limited={true}
            onChangePosition={({ offsetPosition }) => {
              const x = position.x + offsetPosition.x
              const y = position.y - offsetPosition.y
              setTemporaryPosition({ y, x })

              return {
                resultSizes: {
                  x,
                  y,
                },
              }
            }}
            onEndChangePosition={({ resultPosition }) => {
              setTemporaryPosition(undefined)
              if (typeof resultPosition.x == 'number' && typeof resultPosition.y == 'number') {
                setPosition({
                  x: resultPosition.x,
                  y: resultPosition.y,
                })
              }
            }}
            style={{
              zIndex: 2,
              paddingLeft: 8,
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              cursor: 'move',
              // background: boxHeight.isDrag ? '#0086ff' : 'white',
            }}
            onDrag={({ is }) => {}}
            onClick={() => {}}
          >
            <Text color="white">Слои</Text>
            {/* <IconArrowsMove color={'#fff'} size={20} /> */}
          </DragOffsetPosition>
          {/* <Text
            onClick={() => {
              setHidden(false)
              setTab(0)
            }}
            css={{
              cursor: 'pointer',
              textDecoration: tab == 0 ? 'underline' : undefined,
            }}
          >
            Слои
          </Text>{' '}
          <Text
            onClick={() => {
              setHidden(false)
              setTab(1)
            }}
            css={{
              cursor: 'pointer',
              textDecoration: tab == 1 ? 'underline' : undefined,
            }}
          >
            Цвета
          </Text> */}
        </Group>
        <IconMinus
          css={{
            cursor: 'pointer',
          }}
          color={'white'}
          onClick={() => {
            setHidden(hidden => !hidden)
          }}
        />
      </div>
      {hidden ? null : (
        <div
          css={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: `calc(100% - ${tabHeight}px)`,
            position: 'relative',
            zIndex: 3,
            background: 'white',
          }}
          className={classes.hideScrollbar}
        >
          {tab == 0 ? <PageSyncMemo pageID={pageID} /> : null}
          {/* {tab == 1 ? <SiteColorsSync /> : null} */}
        </div>
      )}
    </div>
  )
}

export const LayerSettingsSyncMemo = React.memo(LayerSettingsSync, (prev, next) => {
  if (prev.pageID != next.pageID) return false
  return true
})

export const PageSync = ({ pageID }: { pageID: string }) => {
  const page = useGetSelectData({ modelID: pageID, selectID: 'Page1' })

  const [sectionsPinnedCollection, setSectionsPinnedCollection, sectionsPinnedCollectionRef] = useStateRef(
    page.sectionsPinnedCollection
  )

  useEffect(() => {
    setSectionsPinnedCollection(page?.sectionsPinnedCollection)
  }, [page.sectionsPinnedCollection])
  const updateSectionsPinnedSync = trpc.useMutation('user.updateSectionsPinnedSync', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <>
      <PagePopupsSyncMemo />

      <DndContext
        onDragEnd={async event => {
          const { active, over } = event

          if (over) {
            if (active.id !== over.id) {
              const oldIndex = page.sectionsPinnedCollection.findIndex(
                sectionPinnedCollection => sectionPinnedCollection.id == active.id
              )
              const newIndex = page.sectionsPinnedCollection.findIndex(
                sectionPinnedCollection => sectionPinnedCollection.id == over.id
              )

              if (newIndex < 0 || oldIndex < 0) {
                // showNotification({
                //   title: 'Неудачное перетаскивание, попробуйте снова',
                //   message: '',
                //   color: 'red',
                // })
                return
              }

              if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
                const updatedSectionsPinnedCollection = arrayMove(page.sectionsPinnedCollection, oldIndex, newIndex)
                setSectionsPinnedCollection(updatedSectionsPinnedCollection)
                updateSectionsPinnedSync.mutate({
                  sectionsPinnedIDs: updatedSectionsPinnedCollection.map((box, index) => {
                    return {
                      id: box.id,
                      order: index,
                    }
                  }),
                  pageID: page.id,
                })
              }
            }
          }
        }}
      >
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 6,
            padding: 8,
          }}
        >
          {sectionsPinnedCollection.map(sectionPinnedCollection => (
            <SectionsPinnedCollectionSync selectID={sectionPinnedCollection.selectID} modelID={sectionPinnedCollection.id} />
          ))}
        </div>
      </DndContext>
    </>
  )
}
export const PageSyncMemo = React.memo(PageSync, (prev, next) => {
  if (prev.pageID != next.pageID) return false
  return true
})

const SectionsPinnedCollectionSync = ({ modelID, selectID }: { selectID: 'SectionsPinned1'; modelID: string }) => {
  const sectionPinnedCollection = useGetSelectData({ modelID: modelID, selectID: selectID })

  const sectionsCollection = useGetSelectData({
    modelID: sectionPinnedCollection.sectionsCollection.id,
    selectID: sectionPinnedCollection.sectionsCollection.selectID,
  })

  const updateSectionsPinnedSync = trpc.useMutation('user.updateSectionsPinnedSync', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateSectionsOrder = trpc.useMutation('user.updateSectionsOrder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [sectionsChild, setSectionsChild, sectionsChildRef] = useStateRef(sectionsCollection.sectionsChild)

  useEffect(() => {
    setSectionsChild(sectionsCollection.sectionsChild)
  }, [sectionsCollection.sectionsChild])

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: sectionPinnedCollection.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: sectionPinnedCollection.id,
  })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  return (
    <>
      <div
        ref={setNodeRefDrop}
        css={{
          // borderBottom: '1px solid #eee',
          zIndex: isDragging ? 100000000 : 0,
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          ref={setNodeRef}
          style={{
            padding: 4,
            borderRadius: 2,
            background: colors.blue,
            overflow: 'hidden',
            boxShadow: isDragging
              ? '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px'
              : undefined,
            ...styleDrag,
          }}
        >
          <Group
            css={{
              // height: 30,
              // padding: '0 12px',
              alignItems: 'center',
            }}
          >
            <Text
              css={{
                color: 'white',
                fontWeight: 'bold',
                pl: 4,
                cursor: 'pointer',
              }}
              {...listeners}
              {...attributes}
              size={'xs'}
            >
              {site.withBlockGroups ? 'Набор блоков' : 'Блок'}
            </Text>
          </Group>

          <SimpleGrid spacing={4}>
            <DndContext
              onDragEnd={async event => {
                const { active, over } = event

                if (over) {
                  if (active.id !== over.id) {
                    const oldIndex = sectionsCollection.sectionsChild.findIndex(
                      sectionPinnedCollection => sectionPinnedCollection.id == active.id
                    )
                    const newIndex = sectionsCollection.sectionsChild.findIndex(
                      sectionPinnedCollection => sectionPinnedCollection.id == over.id
                    )

                    if (newIndex < 0 || oldIndex < 0) {
                      // showNotification({
                      //   title: 'Неудачное перетаскивание, попробуйте снова',
                      //   message: '',
                      //   color: 'red',
                      // })
                      return
                    }

                    if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
                      const newOrder = arrayMove(sectionsCollection.sectionsChild, oldIndex, newIndex)

                      setSectionsChild(newOrder)

                      updateSectionsOrder.mutate({
                        sectionsID: sectionsCollection.id,
                        order: newOrder.map((input, index) => ({ id: input.id, order: index })),
                      })
                    }
                  }
                }
              }}
            >
              {sectionsChild.map(section => {
                return <SectionsSync key={section.id} modelID={section.id} selectID={section.selectID} />
              })}
            </DndContext>
          </SimpleGrid>
        </div>
      </div>
    </>
  )
}

const SectionsSync = ({ modelID, selectID }: { modelID: string; selectID: 'Section1' }) => {
  const section = useGetSelectData({ modelID, selectID })

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: section.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: section.id,
  })
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  return (
    <>
      {section.boxBlock ? (
        <div
          ref={setNodeRef}
          style={{
            // border: '1px solid green',
            overflow: 'hidden',
            background: `${isOver ? '#cee4f4' : 'white'}`,
            borderRadius: 2,
            boxShadow: isDragging
              ? '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px'
              : undefined,
            zIndex: isDragging ? 100000000 : 0,
            ...styleDrag,
          }}
        >
          <div ref={setNodeRefDrop}>
            {site.withBlockGroups ? (
              <Text
                css={{
                  color: '#333',
                  cursor: 'pointer',
                  padding: '2px 2px 2px 4px',
                }}
                size="xs"
                {...listeners}
                {...attributes}
              >
                Блок
              </Text>
            ) : null}
            <BoxBlockSync modelID={section.boxBlock.id} selectID={section.boxBlock.selectID} />
          </div>
        </div>
      ) : (
        'boxBlock not found'
      )}
    </>
  )
}

function BoxBlockSync({ modelID, selectID }: { modelID: string; selectID: 'BoxBlock1' }) {
  const boxBlock = useGetSelectData({ modelID, selectID })

  return (
    <>
      {/* <div
        css={
          {
            // border: '2px solid green',
          }
        }
      > */}
      {/* <DndContext> */}
      <LayerBoxSyncMemo
        // boxBlockID={boxBlock.id}
        boxData={{
          modelID: boxBlock.box.id,
          selectID: boxBlock.box.selectID,
        }}
        indent={0}
        parentIsHidden={false}
      />
      {/* </DndContext> */}
      {/* </div> */}
    </>
  )
}

export const LayerBoxSyncMemo = React.memo(LayerBoxSync, (prevProps, nextProps) => {
  if (prevProps.indent !== nextProps.indent) return false
  if (prevProps.boxData.modelID !== nextProps.boxData.modelID) return false
  if (prevProps.parentIsHidden !== nextProps.parentIsHidden) return false
  if (prevProps.parentIsOpen !== nextProps.parentIsOpen) return false
  return true
})

export function LayerBoxSync({
  boxData,
  indent,
  // boxBlockID,
  parentIsHidden,
  isMainParent = true,
  childOpened,
  parentIsOpen,
}: {
  boxData: {
    modelID: string
    selectID: 'Box1'
  }
  indent: number
  // boxBlockID: string
  isMainParent?: boolean
  parentIsHidden: boolean
  childOpened?: () => void
  parentIsOpen?: boolean
}) {
  const box = useGetSelectData({ modelID: boxData.modelID, selectID: boxData.selectID })

  const responsiveBoxSettings = useGetSelectData({
    modelID: box.responsiveBoxSettings.id,
    selectID: box.responsiveBoxSettings.selectID,
  })
  const desktopBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.desktopBoxSettings.id,
    selectID: responsiveBoxSettings.desktopBoxSettings.selectID,
  })
  const tabletBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.tabletBoxSettings.id,
    selectID: responsiveBoxSettings.tabletBoxSettings.selectID,
  })
  const mobileBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.mobileBoxSettings.id,
    selectID: responsiveBoxSettings.mobileBoxSettings.selectID,
  })

  const [settingsIsShow, setSettingsIsShow, settingsIsShowRef] = useStateRef(false)

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinBoxBlockFavorite = trpc.useMutation('user.box.pinBoxBlockFavorite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: box.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: box.id,
  })
  const { responsiveGroup, setResponsiveBoxSettings, beforeSet } = useResponsiveBoxGroup({
    modelID: box.id,
    selectID: 'Box1',
  })

  const [boxes, setBoxes, boxesRef] = useStateRef<NonNullable<typeof box.boxes>>(box.boxes || [])

  useEffect(() => {
    setBoxes(box.boxes || [])
  }, [box.boxes])

  const updateBoxOrder = trpc.useMutation('user.box.updateBoxOrder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  useEffect(() => {
    const unlisten = boxActiveIDCenter.subscribe({
      boxID: box.id,
      onDelete: () => {},
      callback: isActive => {
        setSettingsIsShow(isActive)
        if (isActive) {
          if (childOpened) childOpened()
        }
      },
    })
    return () => {
      unlisten()
    }
  }, [])

  const setActiveBoxID = ({ anchor }: { anchor?: boolean }) => {
    boxActiveIDCenter.setActiveID({ boxID: box.id })
    if (anchor) document.getElementById(`box-id-${box.id}`)?.scrollIntoView({ block: 'nearest', inline: 'nearest' })
  }

  const [isOpen, setIsOpen] = useState(false)

  const boxesNode = boxes.map(boxLoop => (
    <LayerBoxSyncMemo
      isMainParent={false}
      key={boxLoop.id}
      // boxBlockID={boxBlockID}
      indent={indent + 1}
      boxData={{ modelID: boxLoop.id, selectID: boxLoop.selectID }}
      parentIsHidden={responsiveGroup.hidden.value}
      parentIsOpen={isOpen ? parentIsOpen : isOpen}
      childOpened={() => {
        setIsOpen(true)
        if (childOpened) childOpened()
      }}
    />
  ))

  const bodyIsOpen = typeof parentIsOpen == 'boolean' ? parentIsOpen : true

  return boxes ? (
    <>
      {bodyIsOpen ? (
        <div
          ref={setNodeRef}
          // onClick={event => {
          //   setActiveBoxID({ anchor: true })
          // }}
          onMouseDown={() => {
            setActiveBoxID({ anchor: true })
          }}
          // onMouseOver={}
          style={{
            position: 'relative',
            backdropFilter: 'blur(10px)',
            backgroundColor: `${isOver ? '#cee4f4' : settingsIsShow ? '#e5f4ff' : 'rgba(255, 255, 255, 0.75)'}`,
            borderRadius: isDragging ? 4 : undefined,
            boxShadow: isDragging
              ? '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px'
              : undefined,
            zIndex: isDragging ? 10000000 : 0,
            opacity: parentIsHidden || responsiveGroup.hidden.value ? 0.5 : 1,
            ...styleDrag,
          }}
          id={`layer-id-${box.id}`}
          css={{
            //   borderBottom: `1px solid #eee`,
            // padding: `6px 0 6px ${indent * 12}px`,
            ':hover': {
              backgroundColor: `#eee`,
            },
            display: 'flex',
            alignItems: 'center',
            height: 30,
          }}
        >
          {/* {isMainParent ? null : (
          <div
            css={{
              cursor: 'pointer',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
            {...listeners}
            {...attributes}
          ></div>
        )} */}

          {Array.from({ length: indent }, (_, i) => i + 1).map((key, index) => (
            <div
              key={index}
              css={{
                width: 12,
                height: 30,
                position: 'relative',
                // borderRight: `1px solid ${index == 0 ? 'transparent' : '#e4e4e4'}`,
                // '&::after': {
                //   content: `""`,
                //   display: 'block',
                //   width: '1px',
                //   left: '50%',
                //   top: '50%',
                //   height: '100%',
                //   background: index == 0 ? 'transparent' : '#e6e6e6',
                //   transform: 'translate(-50%, -50%)',
                //   position: 'absolute',
                // },
                '&::before': {
                  content: `""`,
                  display: 'block',
                  height: '100%',
                  left: '50%',
                  top: '50%',
                  width: '0.5px',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                  background: index == 0 ? 'transparent' : '#86c4ff',
                },
              }}
            ></div>
          ))}
          <div ref={setNodeRefDrop}>
            <Group
              css={{
                alignItems: 'center',
                paddingLeft: '0px',
                width: '100%',
              }}
              spacing={8}
              position="apart"
            >
              {/* {isMainParent ? null : (
              <IconArrowsMoveVertical
                color={'#333'}
                css={{
                  cursor: 'pointer',
                  outline: 'none',
                }}
                size={16}
                {...listeners}
                {...attributes}
              />
            )} */}

              {isMainParent || boxes.length ? (
                <Text
                  size="xs"
                  css={{
                    cursor: 'pointer',
                    padding: `2px 0px 2px 4px`,
                    fontWeight: 'bold',
                    '&:hover * path': {
                      fill: '#000',
                    },
                  }}
                  onClick={event => {
                    event.stopPropagation()
                    setIsOpen(o => !o)
                  }}
                  onMouseDown={event => {
                    event.stopPropagation()
                  }}
                >
                  <svg
                    css={{
                      transform: isOpen ? `rotate(90deg)` : undefined,
                      display: 'block',
                    }}
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 4L1.50571e-07 7.4641L4.53412e-07 0.535898L6 4Z" fill="#D9D9D9" />
                  </svg>
                </Text>
              ) : null}
              <Text
                css={{
                  color: '#333',
                  cursor: 'pointer',
                  padding: 2,
                }}
                size="xs"
                {...listeners}
                {...attributes}
              >
                {box.textComponent
                  ? 'Текст'
                  : box.imageComponent
                  ? 'Картинка'
                  : box.formComponent
                  ? 'Форма'
                  : box.quizComponent
                  ? 'Квиз'
                  : box.videoComponent
                  ? 'Видео'
                  : box.faqComponent
                  ? 'Вопрос - ответ'
                  : 'Слой'}
                {/* <sup>{desktopBoxSettings.order} </sup> */}
                {box.link ? <strong> - Ссылка</strong> : <></>}
              </Text>
              {/* {boxBlock ? (
              <>
                {boxBlock.favoriteOfUsers.find(favoriteUser => favoriteUser.id == site.user.id) ? (
                  <IconStar
                    css={{
                      display: 'inline-floe',
                    }}
                    size={20}
                    onClick={() => pinBoxBlockFavorite.mutate({ boxBlockID: boxBlock.id })}
                  />
                ) : (
                  <IconStarOff
                    css={{
                      display: 'inline-floe',
                    }}
                    size={20}
                    onClick={() => pinBoxBlockFavorite.mutate({ boxBlockID: boxBlock.id })}
                  />
                )}
              </>
            ) : null} */}
            </Group>
          </div>
        </div>
      ) : null}

      <DndContext
        onDragEnd={event => {
          const { active, over } = event

          if (over) {
            if (active.id !== over.id) {
              if (box.boxes) {
                const oldIndex = box.boxes.findIndex(box => box.id == active.id)
                const newIndex = box.boxes.findIndex(box => box.id == over.id)

                if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
                  const updatedBoxes = arrayMove(box.boxes, oldIndex, newIndex)

                  setBoxes(updatedBoxes)

                  updateBoxOrder.mutate({
                    parentBoxID: box.id,
                    order: updatedBoxes.map((box, index) => ({ order: index, id: box.id })),
                  })

                  // updatedBoxes.map((box, index) => {
                  //   updateBoxSettings.mutate({
                  //     boxID: box.id,
                  //     order: index,
                  //     screenType: 'Desktop',
                  //   })
                  // })
                }
              }
            }
          }
        }}
      >
        {boxesNode}
      </DndContext>
    </>
  ) : (
    <></>
  )
}
