import { Group, List, Popover, Text, ThemeIcon } from '@mantine/core'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { trpc } from 'utils/trpc'

import { showNotification } from '@mantine/notifications'
import { IconTarget } from '@tabler/icons'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { TargetSync } from 'syncComponents/TargetSync/TargetSync'

export function ChooseTarget({
  targetID,
  parentType,
  parentID,
}: {
  targetID?: string
  parentType: 'form' | 'radioItem' | 'checkboxItem' | 'telegramGift'
  parentID: string
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const connectToTarget = trpc.useMutation('user.yandexMetrika.connectToTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const disconnectToTarget = trpc.useMutation('user.yandexMetrika.disconnectToTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <div>
      {targetID ? (
        <GrayButton
          css={{
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={() => {
            if (targetID) {
              disconnectToTarget.mutate({
                targetID,
                type: parentType,
                parentID,
              })
            }
          }}
        >
          Убрать цель: <TargetSync modelID={targetID} selectID={'Target1'} />
        </GrayButton>
      ) : (
        <Popover withinPortal position="right-start" width={'auto'} shadow="md">
          <Popover.Target>
            <Group>
              <GrayButton>Выбрать цель</GrayButton>
            </Group>
          </Popover.Target>
          <Popover.Dropdown
            css={{
              padding: '8px 12px',
              borderRadius: 2,
            }}
          >
            <List
              spacing="xs"
              size="sm"
              center
              icon={
                <ThemeIcon size={24} radius="xl">
                  <IconTarget />
                </ThemeIcon>
              }
            >
              {site.targets.map(target => (
                <List.Item
                  onClick={() => {
                    connectToTarget.mutate({
                      targetID: target.id,
                      type: parentType,
                      parentID,
                    })
                  }}
                  key={target.id}
                  css={{
                    cursor: 'pointer',
                  }}
                >
                  <Text>
                    <TargetSync key={target.id} modelID={target.id} selectID={target.selectID} />
                  </Text>
                </List.Item>
              ))}
            </List>
          </Popover.Dropdown>
        </Popover>
      )}
    </div>
  )
}
