import { Modal, useMantineTheme } from '@mantine/core'
import BoxBlock from 'blocks/BoxBlock/BoxBlock'
import { Form } from 'components/Form/Form'
import Quiz from 'components/Quiz/Quiz'
import { PopupPublicContext } from 'contexts/PopupPublicContext'
import { useEffect, useState } from 'react'
import { PopupSelect } from 'server/selects'
import { popupActiveCenter } from 'utils/popupActiveCenter'

import { useHotkeys } from '@mantine/hooks'

export function LandingPopup(popup: NonNullable<PopupSelect>) {
  const theme = useMantineTheme()
  const [opened, setOpened] = useState(false)
  const [data, setData] = useState<string | null>(null)

  const isCenter = popup.verticalPosition == 'Center' && popup.horizontalPosition == 'Center'

  useEffect(() => {
    const { unlisten, active } = popupActiveCenter.subscribePopupID({
      popupID: popup.id,
      callback: ({ popupID, data }) => {
        if (popup.id == popupID) {
          setData(data)
          setOpened(true)
        } else {
          if (isCenter) {
            setOpened(false)
          }
        }
      },
    })
    // const unlisten = eventener({ name: popup.id }).on(() => {
    //   setOpened(true)
    //   if (popup.popupOpenedEvent) targetsEventener.push({ id: popup.popupOpenedEvent.id })
    // })
    if (active) {
      setOpened(true)
    }
    return () => {
      unlisten()
    }
  }, [isCenter])

  useHotkeys([
    [
      'Escape',
      () => {
        setOpened(opened => {
          if (isCenter) return opened
          if (opened) return false
          return opened
        })
      },
    ],
  ])

  const isVerticalVector =
    popup.horizontalPosition == 'Center' && (popup.verticalPosition == 'Top' || popup.verticalPosition == 'Bottom')
  const isHorizontalVector =
    popup.verticalPosition == 'Center' && (popup.horizontalPosition == 'Left' || popup.horizontalPosition == 'Right')

  const isSticky = popup.verticalPosition == 'Bottom'

  return (
    <PopupPublicContext.Provider
      value={{
        data,
      }}
    >
      <>
        {popup.boxBlock ? (
          <>
            {opened ? (
              <>
                {/* <Portal> */}
                <div
                  css={
                    isCenter
                      ? {
                          position: 'fixed',

                          left: 0,
                          top: 0,

                          width: '100%',
                          height: '100%',

                          maxWidth: '100%',
                          maxHeight: '100%',

                          overflowY: 'auto',
                          overflowX: 'hidden',

                          zIndex: 350,
                        }
                      : isSticky
                      ? {
                          position: 'sticky',
                          bottom: 0,
                          zIndex: 300,
                        }
                      : undefined
                  }
                >
                  <BoxBlock
                    style={
                      isCenter
                        ? {
                            width: '100%',
                            minHeight: '100%',
                            // position: 'absolute',
                            // left: '50%',
                            // top: '50%',
                            // transform: 'translate(-50%, -50%)',
                            // maxWidth: '100%',
                            // maxHeight: '100%',
                            // zIndex: 1000000,
                            // overflowY: 'auto',
                            // overflowX: 'hidden',
                          }
                        : {
                            position: isSticky ? 'absolute' : 'fixed',
                            left: isVerticalVector ? '50%' : popup.horizontalPosition == 'Left' ? 0 : undefined,
                            top: isHorizontalVector ? '50%' : popup.verticalPosition == 'Top' ? 0 : undefined,
                            transform: `translate(${isVerticalVector ? `-50%` : 0}, ${isHorizontalVector ? `-50%` : 0})`,
                            bottom: popup.verticalPosition == 'Bottom' ? 0 : undefined,
                            right: popup.horizontalPosition == 'Right' ? 0 : undefined,
                            maxHeight: '100%',
                            zIndex: 300,
                          }
                    }
                    onClose={() => {
                      popupActiveCenter.closePopup({ popupID: popup.id })
                      setOpened(false)
                    }}
                    {...popup.boxBlock.box}
                  />
                </div>
                {/* </Portal> */}
              </>
            ) : null}
          </>
        ) : (
          <Modal
            withCloseButton={true}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            overlayOpacity={0.55}
            overlayBlur={3}
            centered
            opened={opened}
            size={popup.quiz ? 'xl' : 'xs'}
            onClose={() => {
              popupActiveCenter.closePopup({ popupID: popup.id })
              setOpened(false)
            }}
            overflow="outside"
            padding={'lg'}
            zIndex={300}
          >
            {popup.quiz ? <Quiz onSended={() => setOpened(false)} {...popup.quiz} /> : null}
            {popup.form ? <Form onSended={() => setOpened(false)} form={popup.form} /> : null}
          </Modal>
        )}
      </>
    </PopupPublicContext.Provider>
  )
}
