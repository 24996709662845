import { ActionIcon, ColorSwatch, Drawer, Grid, Group, SimpleGrid, Switch } from '@mantine/core'
import { BoxVector, FlexBoxPosition } from '@prisma/client'
import { IconGridDots, IconHandMove, IconMinus, IconPlus, IconRefresh, IconSettings, IconX } from '@tabler/icons'
import { EditOneBoxEntity, EntityLineSettingsBox, TitleOfSettings } from 'components/EditOneBoxEntity/EditOneBoxEntity'
import { PositionSettingsItem } from 'components/PositionSettingsItem/PositionSettingsItem'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { useResponsiveProperty } from 'hooks/useResponsiveProperty/useResponsiveProperty'
import { useMainStyles } from 'pages/panel/sitesV2/[siteID]/pages/[pageID]'
import { useEffect, useState } from 'react'
import { ChooseSiteColorsSync } from 'syncComponents/ChooseSiteColorsSync/ChooseSiteColorsSync'
import { ChooseSiteShadowSync } from 'syncComponents/ChooseSiteShadowSync/ChooseSiteShadowSync'
import { boxActiveIDCenter } from 'utils/boxActiveIDCenter'

import { showNotification } from '@mantine/notifications'
import { TextSettings } from 'syncComponents/EditorTextComponentSync/EditorTextComponentSync'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { useCurrentResponsiveMode } from 'utils/activeResponsiveModeCenter'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { useLocalStorage } from '@mantine/hooks'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import React from 'react'

import { BoxSettingsShellSyncMemoV2 } from './BoxSettingsSyncV2'

export const BoxSettingsSync = () => {
  const [activeBoxesID, setActiveBoxesID] = useState<string[]>([])

  useEffect(() => {
    const unlisten = boxActiveIDCenter.listener({
      callback: ({ ids }) => {
        setActiveBoxesID(ids)
      },
    })
    return () => {
      unlisten()
    }
  }, [])

  if (activeBoxesID.length == 1) {
    return <BoxWindow boxID={activeBoxesID[0]} />
  }
  return <></>
}

type Position = {
  y: number
  x: number
}

const BoxWindow = ({ boxID }: { boxID: string }) => {
  const [position, setPosition] = useState<Position>({
    y: 700,
    x: 10,
  })
  const [temporaryPosition, setTemporaryPosition] = useState<Position | undefined>(undefined)
  const { classes } = useMainStyles()
  const tabHeight = 32
  const [hidden, setHidden] = useLocalStorage({ key: 'box-settings-is-open', defaultValue: false })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  return (
    <div
      css={{
        position: 'absolute',
        bottom: 0,
        minHeight: tabHeight,
        maxHeight: `calc(100% - ${tabHeight}px)`,
        height: hidden ? tabHeight : temporaryPosition ? temporaryPosition.y : position.y,
        right: temporaryPosition ? temporaryPosition.x : position.x,
        width: '300px',
        borderRadius: '2px 2px 0px 0px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        overflow: 'hidden',
        background: colors.blue,
        zIndex: 10000,
      }}
    >
      <div
        css={{
          width: '100%',
          height: tabHeight,
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'space-between',
          paddingRight: 8,
          // background: boxHeight.isDrag ? '#0086ff' : 'white',
        }}
      >
        <DragOffsetPosition
          limited={true}
          onChangePosition={({ offsetPosition }) => {
            const x = position.x - offsetPosition.x
            const y = position.y - offsetPosition.y
            setTemporaryPosition({ y, x })

            return {
              resultSizes: {
                x,
                y,
              },
            }
          }}
          onEndChangePosition={({ resultPosition }) => {
            setTemporaryPosition(undefined)
            if (typeof resultPosition.x == 'number' && typeof resultPosition.y == 'number') {
              setPosition({
                x: resultPosition.x,
                y: resultPosition.y,
              })
            }
          }}
          style={{
            width: 'calc(100% - 40px)',
            height: '100%',
            zIndex: 2,
            paddingLeft: 8,
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            alignContent: 'center',
            cursor: 'move',
            // background: boxHeight.isDrag ? '#0086ff' : 'white',
          }}
          onDrag={({ is }) => {}}
          onClick={() => {}}
        >
          <>Настройки слоя</>
        </DragOffsetPosition>
        <IconMinus
          css={{
            cursor: 'pointer',
          }}
          color={'white'}
          onClick={() => {
            setHidden(hidden => !hidden)
          }}
        />
      </div>
      {hidden ? null : (
        <div
          css={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: `calc(100% - ${tabHeight}px)`,
            position: 'relative',
            zIndex: 3,
            background: 'white',
          }}
          className={classes.hideScrollbar}
        >
          <BoxSettingsShellSyncMemoV2 boxID={boxID} />
        </div>
      )}
    </div>
  )
}

export const positionSettingsItemsGroups: Array<
  Array<
    {
      horizontalPosition: FlexBoxPosition
      verticalPosition: FlexBoxPosition
      vector: BoxVector
    }[]
  >
> = [
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexStart',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'Center',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexEnd',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexStart',
        vector: 'Right',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'Center',
        vector: 'Right',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexEnd',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexStart',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'Center',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexEnd',
        vector: 'Right',
      },
    ],
  ],
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceBetween',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceAround',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceBetween',
        vector: 'Right',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceAround',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceBetween',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceAround',
        vector: 'Right',
      },
    ],
  ],
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexStart',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexStart',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexStart',
        vector: 'Bottom',
      },
    ],

    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'Center',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'Center',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'Center',
        vector: 'Bottom',
      },
    ],

    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexEnd',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexEnd',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexEnd',
        vector: 'Bottom',
      },
    ],
  ],
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceAround',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceAround',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceAround',
        vector: 'Bottom',
      },
    ],

    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceBetween',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceBetween',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceBetween',
        vector: 'Bottom',
      },
    ],
  ],
]

const BoxSettingsShellSync = ({ boxID }: { boxID: string }) => {
  const { siteID } = useSyncSiteContext()

  const box = useGetSelectData({ modelID: boxID, selectID: 'Box1' })
  const responsiveBoxSettings = useGetSelectData({
    modelID: box.responsiveBoxSettings.id,
    selectID: box.responsiveBoxSettings.selectID,
  })
  const desktopBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.desktopBoxSettings.id,
    selectID: responsiveBoxSettings.desktopBoxSettings.selectID,
  })
  const tabletBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.tabletBoxSettings.id,
    selectID: responsiveBoxSettings.tabletBoxSettings.selectID,
  })
  const mobileBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.mobileBoxSettings.id,
    selectID: responsiveBoxSettings.mobileBoxSettings.selectID,
  })

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxResponsiveSettings = trpc.useMutation('user.box.updateBoxResponsiveSettings', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const boxWidth = useResponsiveProperty({
    long: true,
    resposiveMode: {
      desktop: box.widthDesktopResponsiveMode,
      tablet: box.widthTabletResponsiveMode,
      mobile: box.widthMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.width,
      tablet: tabletBoxSettings.width,
      mobile: mobileBoxSettings.width,
    },
    propertyName: 'Ширина',
    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        width: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'width',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxWidthType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.widthTypeDesktopResponsiveMode,
      tablet: box.widthTypeTabletResponsiveMode,
      mobile: box.widthTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.widthType,
      tablet: tabletBoxSettings.widthType,
      mobile: mobileBoxSettings.widthType,
    },
    propertyName: 'Тип ширины',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          widthType: value,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'widthType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHeightType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.heightTypeDesktopResponsiveMode,
      tablet: box.heightTypeTabletResponsiveMode,
      mobile: box.heightTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.heightType,
      tablet: tabletBoxSettings.heightType,
      mobile: mobileBoxSettings.heightType,
    },
    propertyName: 'Тип высоты',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          heightType: value,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'heightType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPostionType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.positionTypeDesktopResponsiveMode,
      tablet: box.positionTypeTabletResponsiveMode,
      mobile: box.positionTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        positionType: desktopBoxSettings.positionType,
        positionX: desktopBoxSettings.positionX,
        positionY: desktopBoxSettings.positionY,
      },
      tablet: {
        positionType: tabletBoxSettings.positionType,
        positionX: tabletBoxSettings.positionX,
        positionY: tabletBoxSettings.positionY,
      },
      mobile: {
        positionType: mobileBoxSettings.positionType,
        positionX: mobileBoxSettings.positionX,
        positionY: mobileBoxSettings.positionY,
      },
    },
    propertyName: 'Тип положения',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          positionType: value.positionType,
          positionX: value.positionX,
          positionY: value.positionY,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'positionType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHeight = useResponsiveProperty({
    resposiveMode: {
      desktop: box.heightDesktopResponsiveMode,
      tablet: box.heightTabletResponsiveMode,
      mobile: box.heightMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.height,
      tablet: tabletBoxSettings.height,
      mobile: mobileBoxSettings.height,
    },
    propertyName: 'Высота',
    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        height: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'height',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxMargin = useResponsiveProperty({
    resposiveMode: {
      desktop: box.marginDesktopResponsiveMode,
      tablet: box.marginTabletResponsiveMode,
      mobile: box.marginMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        top: desktopBoxSettings.marginTop,
        right: desktopBoxSettings.marginRight,
        bottom: desktopBoxSettings.marginBottom,
        left: desktopBoxSettings.marginLeft,
      },
      tablet: {
        top: tabletBoxSettings.marginTop,
        right: tabletBoxSettings.marginRight,
        bottom: tabletBoxSettings.marginBottom,
        left: tabletBoxSettings.marginLeft,
      },
      mobile: {
        top: mobileBoxSettings.marginTop,
        right: mobileBoxSettings.marginRight,
        bottom: mobileBoxSettings.marginBottom,
        left: mobileBoxSettings.marginLeft,
      },
    },
    propertyName: 'Внешний отступ',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        marginTop: value.top,
        marginRight: value.right,
        marginBottom: value.bottom,
        marginLeft: value.left,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'margin',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPadding = useResponsiveProperty({
    resposiveMode: {
      desktop: box.paddingDesktopResponsiveMode,
      tablet: box.paddingTabletResponsiveMode,
      mobile: box.paddingMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        top: desktopBoxSettings.paddingTop,
        right: desktopBoxSettings.paddingRight,
        bottom: desktopBoxSettings.paddingBottom,
        left: desktopBoxSettings.paddingLeft,
      },
      tablet: {
        top: tabletBoxSettings.paddingTop,
        right: tabletBoxSettings.paddingRight,
        bottom: tabletBoxSettings.paddingBottom,
        left: tabletBoxSettings.paddingLeft,
      },
      mobile: {
        top: mobileBoxSettings.paddingTop,
        right: mobileBoxSettings.paddingRight,
        bottom: mobileBoxSettings.paddingBottom,
        left: mobileBoxSettings.paddingLeft,
      },
    },
    propertyName: 'Внутренний отступ',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        paddingTop: value.top,
        paddingRight: value.right,
        paddingBottom: value.bottom,
        paddingLeft: value.left,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'padding',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPosition = useResponsiveProperty({
    resposiveMode: {
      desktop: box.positionDesktopResponsiveMode,
      tablet: box.positionTabletResponsiveMode,
      mobile: box.positionMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        vector: desktopBoxSettings.vector || BoxVector.Right,
        horizontalPosition: desktopBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: desktopBoxSettings.verticalPosition || 'FlexStart',
      },
      tablet: {
        vector: tabletBoxSettings.vector || BoxVector.Right,
        horizontalPosition: tabletBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: tabletBoxSettings.verticalPosition || 'FlexStart',
      },
      mobile: {
        vector: mobileBoxSettings.vector || BoxVector.Right,
        horizontalPosition: mobileBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: mobileBoxSettings.verticalPosition || 'FlexStart',
      },
    },
    propertyName: 'Выравнивание',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        vector: value.vector,
        horizontalPosition: value.horizontalPosition,
        verticalPosition: value.verticalPosition,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'position',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHidden = useResponsiveProperty({
    resposiveMode: {
      desktop: box.hiddenDesktopResponsiveMode,
      tablet: box.hiddenTabletResponsiveMode,
      mobile: box.hiddenMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.hidden,
      tablet: tabletBoxSettings.hidden,
      mobile: mobileBoxSettings.hidden,
    },
    propertyName: 'Скрытость',

    onValueUpdated: async ({ responsiveMode, value }) => {
      // updateBoxByID({
      //   id: box.id,
      //   box: {
      //     responsiveBoxSettings: {
      //       ...responsiveBoxSettings,
      //       desktopBoxSettings: {
      //         ...desktopBoxSettings,
      //         hidden: responsiveMode == 'Desktop' ? value : desktopBoxSettings.hidden,
      //       },
      //       tabletBoxSettings: {
      //         ...tabletBoxSettings,
      //         hidden: responsiveMode == 'Tablet' ? value : tabletBoxSettings.hidden,
      //       },
      //       mobileBoxSettings: {
      //         ...mobileBoxSettings,
      //         hidden: responsiveMode == 'Mobile' ? value : mobileBoxSettings.hidden,
      //       },
      //     },
      //   },
      // })
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        hidden: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'hidden',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxIsOverflowHidden = useResponsiveProperty({
    resposiveMode: {
      desktop: box.overflowHiddenMobileResponsiveMode,
      tablet: box.overflowHiddenTabletResponsiveMode,
      mobile: box.overflowHiddenDesktopResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.isOverflowHidden,
      tablet: tabletBoxSettings.isOverflowHidden,
      mobile: mobileBoxSettings.isOverflowHidden,
    },
    propertyName: 'Обрезан',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        isOverflowHidden: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'isOverflowHidden',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxGap = useResponsiveProperty({
    resposiveMode: {
      desktop: box.gapDesktopResponsiveMode,
      tablet: box.gapTabletResponsiveMode,
      mobile: box.gapMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.gap,
      tablet: tabletBoxSettings.gap,
      mobile: mobileBoxSettings.gap,
    },
    propertyName: 'Отступы',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        gap: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'gap',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxBorderRadius = useResponsiveProperty({
    resposiveMode: {
      desktop: box.borderRadiusDesktopResponsiveMode,
      tablet: box.borderRadiusTabletResponsiveMode,
      mobile: box.borderRadiusMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        LeftTop: desktopBoxSettings.borderRadiusLeftTop,
        LeftBottom: desktopBoxSettings.borderRadiusLeftBottom,
        RightTop: desktopBoxSettings.borderRadiusRightTop,
        RightBottom: desktopBoxSettings.borderRadiusRightBottom,
      },
      tablet: {
        LeftTop: tabletBoxSettings.borderRadiusLeftTop,
        LeftBottom: tabletBoxSettings.borderRadiusLeftBottom,
        RightTop: tabletBoxSettings.borderRadiusRightTop,
        RightBottom: tabletBoxSettings.borderRadiusRightBottom,
      },
      mobile: {
        LeftTop: mobileBoxSettings.borderRadiusLeftTop,
        LeftBottom: mobileBoxSettings.borderRadiusLeftBottom,
        RightTop: mobileBoxSettings.borderRadiusRightTop,
        RightBottom: mobileBoxSettings.borderRadiusRightBottom,
      },
    },
    propertyName: 'Округление',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        borderRadiusLeftTop: value.LeftTop,
        borderRadiusLeftBottom: value.LeftBottom,
        borderRadiusRightTop: value.RightTop,
        borderRadiusRightBottom: value.RightBottom,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'borderRadius',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const createBoxBackground = trpc.useMutation('user.box.createBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBoxBackground = trpc.useMutation('user.box.deleteBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const boxBackground = useResponsiveProperty({
    resposiveMode: {
      desktop: box.backgroundDesktopResponsiveMode,
      tablet: box.backgroundTabletResponsiveMode,
      mobile: box.backgroundMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.background,
      tablet: tabletBoxSettings.background,
      mobile: mobileBoxSettings.background,
    },
    propertyName: 'Фон',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value) {
      }
    },

    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'background',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxBorder = useResponsiveProperty({
    resposiveMode: {
      desktop: box.borderDesktopResponsiveMode,
      tablet: box.borderTabletResponsiveMode,
      mobile: box.borderMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.border,
      tablet: tabletBoxSettings.border,
      mobile: mobileBoxSettings.border,
    },
    propertyName: 'Бордер',

    onValueUpdated: async ({ responsiveMode, value }) => {},
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      // await updateBoxResponsiveSettings.mutateAsync({
      //   boxID: box.id,
      //   type: 'border',
      //   boxBlockScreenType: boxBlockResponsiveMode,
      //   screenType: responsiveMode,
      // })
    },
  })
  const boxBorderData = useGetSelectDataUndefined({
    modelID: boxBorder.value?.id,
    selectID: boxBorder.value?.selectID,
  })
  const boxBorderColorData = useGetSelectDataUndefined({
    modelID: boxBorderData?.color?.id,
    selectID: boxBorderData?.color?.selectID,
  })

  const boxShadow = useResponsiveProperty({
    resposiveMode: {
      desktop: box.shadowDesktopResponsiveMode,
      tablet: box.shadowTabletResponsiveMode,
      mobile: box.shadowMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: desktopBoxSettings.shadow,
      tablet: tabletBoxSettings.shadow,
      mobile: mobileBoxSettings.shadow,
    },
    propertyName: 'Тень',

    onValueUpdated: async ({ responsiveMode, value }) => {},
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      // await updateBoxResponsiveSettings.mutateAsync({
      //   boxID: box.id,
      //   type: 'shadow',
      //   boxBlockScreenType: boxBlockResponsiveMode,
      //   screenType: responsiveMode,
      // })
    },
  })
  const boxShadowData = useGetSelectDataUndefined({
    modelID: boxShadow.value?.id,
    selectID: boxShadow.value?.selectID,
  })
  const boxShadowColorData = useGetSelectDataUndefined({
    modelID: boxShadowData?.color?.id,
    selectID: boxShadowData?.color?.selectID,
  })

  const createBoxBorder = trpc.useMutation('user.box.createBoxBorder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxBorder = trpc.useMutation('user.box.updateBoxBorder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBoxBorder = trpc.useMutation('user.box.deleteBoxBorder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createBoxShadow = trpc.useMutation('user.box.createBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const unpinBoxShadow = trpc.useMutation('user.box.unpinBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxShadow = trpc.useMutation('user.box.updateBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinBoxShadow = trpc.useMutation('user.box.pinBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [boxPositionIsOpen, setBoxPositionIsOpen] = useState(
    !(
      desktopBoxSettings.vector == 'Right' &&
      desktopBoxSettings.horizontalPosition == 'FlexStart' &&
      desktopBoxSettings.verticalPosition == 'FlexStart'
    )
  )

  const [chooseBoxShadowOpened, setChooseBoxShadowOpened] = useState(false)

  const responsiveMode = useCurrentResponsiveMode()

  const boxMarginIsEqual = () =>
    boxMargin.value.left == boxMargin.value.top &&
    boxMargin.value.top == boxMargin.value.right &&
    boxMargin.value.right == boxMargin.value.bottom

  const [marginIsOpen, setMarginIsOpen] = useState(!boxMarginIsEqual())

  useEffect(() => {
    setMarginIsOpen(!boxMarginIsEqual())
  }, [boxMargin.value])

  const boxPaddingIsEqual = () =>
    boxPadding.value.left == boxPadding.value.top &&
    boxPadding.value.top == boxPadding.value.right &&
    boxPadding.value.right == boxPadding.value.bottom

  const [paddingIsOpen, setPaddingIsOpen] = useState(!boxPaddingIsEqual())

  useEffect(() => {
    setPaddingIsOpen(!boxPaddingIsEqual())
  }, [boxPadding.value])

  const borderRadiusIsEqual = () =>
    boxBorderRadius.value.LeftTop == boxBorderRadius.value.RightTop &&
    boxBorderRadius.value.RightTop == boxBorderRadius.value.RightBottom &&
    boxBorderRadius.value.RightBottom == boxBorderRadius.value.LeftBottom

  const [borderRadiusIsOpen, setBorderRadiusIsOpen] = useState(!borderRadiusIsEqual())

  useEffect(() => {
    setBorderRadiusIsOpen(!borderRadiusIsEqual())
  }, [boxBorderRadius.value])

  return (
    <div
      onClick={event => {
        event.stopPropagation()
      }}
      style={{
        transition: '0.3s',
        zIndex: 10,
      }}
    >
      <EntityLineSettingsBox
        childs={[
          <SimpleGrid css={{ alignItems: 'center' }} cols={2}>
            <EditOneBoxEntity value={boxWidth.viewValue} onChange={boxWidth.setValue} title={'Ширина'} />

            <Group css={{ alignItems: 'center' }} position="apart">
              {boxWidth.controls()}
              <>
                <div
                  css={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => boxWidthType.setValue(boxWidthType.valueRef.current == 'Pixel' ? 'Percent' : 'Pixel')}
                >
                  {boxWidthType.viewValue == 'Pixel' ? 'PX' : '%'}
                </div>
                {boxWidthType.controls()}
              </>
            </Group>
          </SimpleGrid>,
          <SimpleGrid css={{ alignItems: 'center' }} cols={2}>
            <EditOneBoxEntity value={boxHeight.viewValue} onChange={boxHeight.setValue} title={'Высота'} />

            <Group css={{ alignItems: 'center' }} position="apart">
              {boxHeight.controls()}
              <>
                <div
                  css={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => boxHeightType.setValue(boxHeightType.valueRef.current == 'Pixel' ? 'Percent' : 'Pixel')}
                >
                  {boxHeightType.viewValue == 'Pixel' ? 'PX' : '%'}
                </div>
                {boxHeightType.controls()}
              </>
            </Group>
          </SimpleGrid>,
          <div>
            <Grid css={{ alignItems: 'center' }} columns={10}>
              <Grid.Col span={7}>
                <EditOneBoxEntity
                  value={borderRadiusIsEqual() ? boxBorderRadius.viewValue.LeftTop : null}
                  onChange={value => {
                    boxBorderRadius.setValue({
                      LeftBottom: value,
                      LeftTop: value,
                      RightBottom: value,
                      RightTop: value,
                    })
                  }}
                  title={'Cкругления'}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Group position="right">
                  <ActionIcon
                    css={{
                      opacity: borderRadiusIsOpen ? 1 : 0.5,
                    }}
                    size={20}
                    onClick={async event => {
                      setBorderRadiusIsOpen(o => !o)
                    }}
                  >
                    <IconSettings
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                    />
                  </ActionIcon>
                  <Group position="right">{boxBorderRadius.controls()}</Group>
                </Group>
              </Grid.Col>
            </Grid>

            {borderRadiusIsOpen ? (
              <>
                <SimpleGrid mt={4} cols={2}>
                  <EditOneBoxEntity
                    value={boxBorderRadius.viewValue.LeftTop}
                    onChange={value => {
                      boxBorderRadius.setValue({
                        ...boxBorderRadius.valueRef.current,
                        LeftTop: value,
                      })
                    }}
                    title={'Слев-сверх'}
                  />
                  <EditOneBoxEntity
                    value={boxBorderRadius.viewValue.RightTop}
                    onChange={value => {
                      boxBorderRadius.setValue({
                        ...boxBorderRadius.valueRef.current,
                        RightTop: value,
                      })
                    }}
                    title={'Справ-сверх'}
                  />
                </SimpleGrid>
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={boxBorderRadius.viewValue.LeftBottom}
                    onChange={value => {
                      boxBorderRadius.setValue({
                        ...boxBorderRadius.valueRef.current,
                        LeftBottom: value,
                      })
                    }}
                    title={'Слев-сниз'}
                  />
                  <EditOneBoxEntity
                    value={boxBorderRadius.viewValue.RightBottom}
                    onChange={value => {
                      boxBorderRadius.setValue({
                        ...boxBorderRadius.valueRef.current,
                        RightBottom: value,
                      })
                    }}
                    title={'Справ-сниз'}
                  />
                </SimpleGrid>
              </>
            ) : null}

            {/* <EditOneBoxEntity value={boxBorderRadius.viewValue} onChange={boxBorderRadius.setValue} title={'Cкругления'} /> */}
          </div>,
          box.boxes && box.boxes.length > 1 ? (
            <Grid css={{ alignItems: 'center' }} columns={10}>
              <Grid.Col span={8}>
                <EditOneBoxEntity
                  value={boxGap.viewValue}
                  onChange={value => boxGap.setValue(value == null ? 0 : value)}
                  title={'Отступы между слоями'}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Group position="right">{boxGap.controls()}</Group>
              </Grid.Col>
            </Grid>
          ) : undefined,
          boxPostionType.viewValue.positionType == 'Relative' && box.parentBoxId ? (
            <div>
              <Grid css={{ alignItems: 'center' }} columns={10}>
                <Grid.Col span={7}>
                  <EditOneBoxEntity
                    value={boxMarginIsEqual() ? boxMargin.viewValue.top : null}
                    onChange={value => {
                      boxMargin.setValue({
                        bottom: value,
                        left: value,
                        right: value,
                        top: value,
                      })
                    }}
                    title={'Отступы снаружи'}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Group position="right">
                    <ActionIcon
                      css={{
                        opacity: marginIsOpen ? 1 : 0.5,
                      }}
                      size={20}
                      onClick={async event => {
                        setMarginIsOpen(o => !o)
                      }}
                    >
                      <IconSettings
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                      />
                    </ActionIcon>
                    <Group position="right">{boxMargin.controls()}</Group>
                  </Group>
                </Grid.Col>
              </Grid>

              {marginIsOpen ? (
                <>
                  <SimpleGrid mt={4} cols={2} mb={4}>
                    <EditOneBoxEntity
                      value={boxMargin.viewValue.top}
                      onChange={value => {
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          top: value,
                        })
                      }}
                      title={'Сверху'}
                    />
                    <EditOneBoxEntity
                      value={boxMargin.viewValue.right}
                      onChange={value => {
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          right: value,
                        })
                      }}
                      title={'Справа'}
                    />
                  </SimpleGrid>
                  <SimpleGrid cols={2}>
                    <EditOneBoxEntity
                      value={boxMargin.viewValue.bottom}
                      onChange={value => {
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          bottom: value,
                        })
                      }}
                      title={'Снизу'}
                    />
                    <EditOneBoxEntity
                      value={boxMargin.viewValue.left}
                      onChange={value => {
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          left: value,
                        })
                      }}
                      title={'Слева'}
                    />
                  </SimpleGrid>
                </>
              ) : null}
            </div>
          ) : undefined,
          <div>
            <Grid css={{ alignItems: 'center' }} columns={10}>
              <Grid.Col span={7}>
                <EditOneBoxEntity
                  value={boxPaddingIsEqual() ? boxPadding.viewValue.top : null}
                  onChange={value => {
                    boxPadding.setValue({
                      bottom: value,
                      left: value,
                      right: value,
                      top: value,
                    })
                  }}
                  title={'Отступы внутри'}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Group position="right">
                  <ActionIcon
                    css={{
                      opacity: paddingIsOpen ? 1 : 0.5,
                    }}
                    size={20}
                  >
                    <IconSettings
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                      onClick={async () => {
                        setPaddingIsOpen(o => !o)
                      }}
                    />
                  </ActionIcon>
                  <Group position="right">{boxPadding.controls()}</Group>
                </Group>
              </Grid.Col>
            </Grid>

            {/* <SimpleGrid mb={4} cols={2}>
              <TitleOfSettings title={'Отступы внутри:'} />
              <Group position="right">{boxPadding.controls()}</Group>
            </SimpleGrid> */}
            {paddingIsOpen ? (
              <>
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={boxPadding.viewValue.top}
                    onChange={value => {
                      boxPadding.setValue({
                        ...boxPadding.valueRef.current,
                        top: value,
                        // bottom: value,
                      })
                    }}
                    title={'Сверху'}
                    // position="top"
                  />
                  <EditOneBoxEntity
                    value={boxPadding.viewValue.right}
                    onChange={value => {
                      boxPadding.setValue({
                        ...boxPadding.valueRef.current,
                        right: value,
                        // left: value,
                      })
                    }}
                    // position={'right'}
                    title={'Справа'}
                  />
                </SimpleGrid>
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={boxPadding.viewValue.bottom}
                    onChange={value => {
                      boxPadding.setValue({
                        ...boxPadding.valueRef.current,
                        // top: value,
                        bottom: value,
                      })
                    }}
                    title={'Снизу'}
                    // position="bottom"
                  />
                  <EditOneBoxEntity
                    value={boxPadding.viewValue.left}
                    onChange={value => {
                      boxPadding.setValue({
                        ...boxPadding.valueRef.current,
                        // right: value,
                        left: value,
                      })
                    }}
                    title={'Слева'}
                  />
                </SimpleGrid>
              </>
            ) : null}
            {/* <SimpleGrid cols={1}>
            </SimpleGrid> */}
          </div>,
          box.parentBoxId ? (
            <div>
              <SimpleGrid spacing={8} cols={1}>
                <Grid columns={10}>
                  <Grid.Col span={8}>
                    <div
                      css={{
                        fontSize: 12,
                        padding: '0 7px',
                      }}
                    >
                      <span
                        css={{
                          color: colors.blue,
                        }}
                      >
                        Положение слоя:{' '}
                      </span>
                      <span
                        css={{
                          textDecoration: 'underline',
                        }}
                        onClick={() =>
                          boxPostionType.setValue({
                            ...boxPostionType.valueRef.current,
                            positionType: boxPostionType.valueRef.current.positionType == 'Relative' ? 'Absolute' : 'Relative',
                          })
                        }
                      >
                        {boxPostionType.viewValue.positionType == 'Relative' ? 'Вложенный' : 'Свободное'}
                      </span>
                    </div>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Group position="right">{boxPostionType.controls()}</Group>
                  </Grid.Col>
                </Grid>

                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={boxPostionType.viewValue.positionX}
                    onChange={value => {
                      boxPostionType.setValue({
                        ...boxPostionType.valueRef.current,
                        positionX: value,
                      })
                    }}
                    title={'X'}
                  />

                  <EditOneBoxEntity
                    value={boxPostionType.viewValue.positionY}
                    onChange={value => {
                      boxPostionType.setValue({
                        ...boxPostionType.valueRef.current,
                        positionY: value,
                      })
                    }}
                    title={'Y'}
                  />
                </SimpleGrid>
              </SimpleGrid>
            </div>
          ) : null,
          box.textComponent ? (
            <TextSettings textComponentData={{ id: box.textComponent.id, selectID: box.textComponent.selectID }} />
          ) : undefined,
          <div css={{}}>
            <SimpleGrid spacing={8} cols={1}>
              <Group position="apart">
                <TitleOfSettings title={'Выравнивание'} />
                <Group>
                  {boxPosition.controls()}
                  {/* {boxPositionIsOpen ? (
                    <IconSquareArrowUp
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                      size={20}
                      onClick={() => setBoxPositionIsOpen(false)}
                    />
                  ) : (
                    <IconSquareArrowDown
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                      size={20}
                      onClick={() => setBoxPositionIsOpen(true)}
                    />
                  )} */}
                </Group>
              </Group>
              {/* {boxPositionIsOpen ? (
                
              ) : null} */}
              <Group css={{ padding: '0px 7px' }} spacing={4}>
                {positionSettingsItemsGroups.map((group, index) => {
                  return (
                    <SimpleGrid spacing={1} cols={1} key={index}>
                      {group.map((positionSettingsItems, index) => {
                        return (
                          <Group key={index} spacing={1}>
                            {positionSettingsItems.map(
                              (
                                { horizontalPosition: horizontalPositionLoop, verticalPosition: verticalPositionLoop, vector },
                                index
                              ) => {
                                return (
                                  <PositionSettingsItem
                                    key={index}
                                    actualVector={boxPosition.valueRef.current.vector}
                                    vector={vector}
                                    actualHorizontalPosition={boxPosition.valueRef.current.horizontalPosition}
                                    actualVerticalPosition={boxPosition.valueRef.current.verticalPosition}
                                    horizontalPosition={horizontalPositionLoop}
                                    verticalPosition={verticalPositionLoop}
                                    onUpdatePosition={({ horizontalPosition, verticalPosition }) => {
                                      boxPosition.setValue({
                                        vector: vector,
                                        horizontalPosition,
                                        verticalPosition,
                                      })
                                    }}
                                  />
                                )
                              }
                            )}
                          </Group>
                        )
                      })}
                    </SimpleGrid>
                  )
                })}
              </Group>
            </SimpleGrid>
          </div>,
          <div>
            <SimpleGrid cols={1}>
              <SimpleGrid
                css={{
                  alignItems: 'center',
                }}
                cols={2}
              >
                <TitleOfSettings title={'Фон'} />
                <Group
                  css={{
                    alignItems: 'center',
                  }}
                  position={boxBackground.getCurrentResponsiveMode() !== responsiveMode ? 'right' : 'apart'}
                >
                  {boxBackground.controls()}

                  {boxBackground.getCurrentResponsiveMode() !== responsiveMode ? (
                    <></>
                  ) : (
                    <>
                      {boxBackground.viewValue ? (
                        <ActionIcon size={20}>
                          <IconX
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={async () => {
                              if (boxBackground.valueRef.current?.id) {
                                await deleteBoxBackground.mutateAsync({
                                  boxBackgroundID: boxBackground.valueRef.current?.id,
                                })
                                boxBackground.setValue(null)
                              }
                            }}
                          />
                        </ActionIcon>
                      ) : (
                        <ActionIcon size={20}>
                          <IconPlus
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={async () => {
                              const createdBackground = await createBoxBackground.mutateAsync({
                                boxID: box.id,
                                siteID: siteID,
                                screenType: boxBackground.getCurrentResponsiveMode(),
                              })
                              // boxBackground.setDesktopValue(createdBackground.responsiveBoxSettings.desktopBoxSettings.background)
                              // boxBackground.setTabletValue(createdBackground.responsiveBoxSettings.tabletBoxSettings.background)
                              // boxBackground.setMobileValue(createdBackground.responsiveBoxSettings.mobileBoxSettings.background)
                            }}
                          />
                        </ActionIcon>
                      )}
                    </>
                  )}
                </Group>
              </SimpleGrid>

              {boxBackground.getCurrentResponsiveMode() !== responsiveMode ? null : (
                <>
                  {boxBackground.viewValue ? (
                    <>
                      <EditBoxBackground modelID={boxBackground.viewValue.id} selectID={boxBackground.viewValue.selectID} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </SimpleGrid>
          </div>,
          <div>
            <SimpleGrid cols={1}>
              <Group spacing={2} position="apart">
                <TitleOfSettings title={'Бордер'} />

                {boxBorder.viewValue ? (
                  <ActionIcon size={20}>
                    <IconX
                      css={{
                        cursor: 'pointer',
                      }}
                      color={colors.blue}
                      onClick={async () => {
                        if (boxBorder.viewValue)
                          await deleteBoxBorder.mutateAsync({
                            boxID: box.id,
                            screenType: boxBorder.getCurrentResponsiveMode(),
                          })
                        // boxBorder.setValue(null)
                      }}
                    />
                  </ActionIcon>
                ) : (
                  <ChooseSiteColorsSync
                    onChooseColor={async ({ colorID }) => {
                      const createdBoxBorder = await createBoxBorder.mutateAsync({
                        siteID: siteID,
                        boxID: box.id,
                        colorID: colorID,
                        screenType: boxBorder.getCurrentResponsiveMode(),
                      })
                      // boxBorder.setDesktopValue(createdBoxBorder.responsiveBoxSettings.desktopBoxSettings.border)
                      // boxBorder.setTabletValue(createdBoxBorder.responsiveBoxSettings.tabletBoxSettings.border)
                      // boxBorder.setMobileValue(createdBoxBorder.responsiveBoxSettings.mobileBoxSettings.border)
                    }}
                  >
                    <ActionIcon size={20}>
                      <IconPlus
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                      />
                    </ActionIcon>
                  </ChooseSiteColorsSync>
                )}
              </Group>

              {boxBorderData ? (
                <>
                  <SimpleGrid cols={2}>
                    <EditOneBoxEntity
                      value={boxBorderData.left}
                      onChange={async value => {
                        if (typeof value == 'number' && boxBorder.valueRef.current)
                          await updateBoxBorder.mutateAsync({
                            boxBorderID: boxBorder.valueRef.current.id,
                            left: value,
                            right: value,
                            top: value,
                            bottom: value,
                          })
                        // boxBorder.setValue({
                        //   ...boxBorder.valueRef.current,
                        //   left: value,
                        //   right: value,
                        //   top: value,
                        //   bottom: value,
                        // })
                      }}
                      title={'Размер'}
                    />
                    <Group position="right">
                      <ChooseSiteColorsSync
                        currentColorID={boxBorderColorData?.id}
                        onChooseColor={async ({ colorID }) => {
                          if (boxBorder.valueRef.current?.id) {
                            const updatedBoxBorder = await updateBoxBorder.mutateAsync({
                              boxBorderID: boxBorder.valueRef.current.id,
                              colorID: colorID,
                            })
                            // boxBorder.setValue(updatedBoxBorder)
                          }
                        }}
                      >
                        {boxBorderColorData ? (
                          <ColorSwatch
                            component="button"
                            color={boxBorderColorData?.value}
                            sx={{ color: '#fff', cursor: 'pointer' }}
                          ></ColorSwatch>
                        ) : (
                          <></>
                        )}
                      </ChooseSiteColorsSync>
                    </Group>
                  </SimpleGrid>
                </>
              ) : (
                <></>
              )}
            </SimpleGrid>
          </div>,
          <div css={{ width: '100%' }}>
            <SimpleGrid cols={1}>
              <Group spacing={2} position="apart">
                <TitleOfSettings title={'Тень'} />
                <Group css={{ alignItems: 'center' }}>
                  <ChooseSiteShadowSync
                    onChooseShadow={async ({ shadowID }) => {
                      await pinBoxShadow.mutateAsync({
                        boxID: box.id,
                        screenType: boxShadow.getCurrentResponsiveMode(),
                        boxShadowID: shadowID,
                      })
                    }}
                  >
                    <ActionIcon size={20}>
                      <IconGridDots
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                        // onClick={() => setChooseBoxShadowOpened(true)}
                        // title="Выбрать одну из теней"
                      />
                    </ActionIcon>
                  </ChooseSiteShadowSync>

                  {boxShadowData ? (
                    <ActionIcon size={20}>
                      <IconX
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                        onClick={async () => {
                          if (boxShadowData)
                            await unpinBoxShadow.mutateAsync({
                              boxID: box.id,
                              screenType: boxShadow.getCurrentResponsiveMode(),
                              boxShadowID: boxShadowData.id,
                            })
                          boxShadow.setValue(null)
                        }}
                      />
                    </ActionIcon>
                  ) : (
                    <ChooseSiteColorsSync
                      onChooseColor={async ({ colorID }) => {
                        const updatedBox = await createBoxShadow.mutateAsync({
                          siteID: siteID,
                          boxID: box.id,
                          colorID: colorID,
                          screenType: boxShadow.getCurrentResponsiveMode(),
                        })
                      }}
                    >
                      <ActionIcon size={20}>
                        <IconPlus
                          css={{
                            cursor: 'pointer',
                          }}
                          color={colors.blue}
                        />
                      </ActionIcon>
                    </ChooseSiteColorsSync>
                  )}
                </Group>
              </Group>

              {boxShadowData ? (
                <>
                  <SimpleGrid cols={2}>
                    <EditOneBoxEntity
                      value={boxShadowData.xShift}
                      onChange={async value => {
                        if (typeof value == 'number' && boxShadowData)
                          await updateBoxShadow.mutateAsync({
                            boxShadowID: boxShadowData.id,
                            xShift: value,
                          })
                      }}
                      title={'X'}
                    />

                    <EditOneBoxEntity
                      value={boxShadowData.blur}
                      onChange={async value => {
                        if (typeof value == 'number' && boxShadowData)
                          await updateBoxShadow.mutateAsync({
                            boxShadowID: boxShadowData.id,
                            blur: value,
                          })
                      }}
                      title={'Blur'}
                    />

                    <EditOneBoxEntity
                      value={boxShadowData.yShift}
                      onChange={async value => {
                        if (typeof value == 'number' && boxShadowData)
                          await updateBoxShadow.mutateAsync({
                            boxShadowID: boxShadowData.id,
                            yShift: value,
                          })
                      }}
                      title={'Y'}
                    />
                    <EditOneBoxEntity
                      value={boxShadowData.spread}
                      onChange={async value => {
                        if (typeof value == 'number' && boxShadowData)
                          await updateBoxShadow.mutateAsync({
                            boxShadowID: boxShadowData.id,
                            spread: value,
                          })
                      }}
                      title={'Spread'}
                    />
                  </SimpleGrid>
                </>
              ) : (
                <></>
              )}
            </SimpleGrid>
          </div>,

          <Group position="apart">
            <TitleOfSettings title={'Скрыт'} />
            <Group css={{ alignItems: 'center' }} position="right">
              <Switch
                checked={boxHidden.viewValue}
                onChange={event => boxHidden.setValue(event.currentTarget.checked)}
                size="md"
                // label={'Скрыт'}
                onLabel={'Да'}
                offLabel={'Нет'}
              />
              {/* {boxHidden.viewValue ? (
                <ActionIcon size={20}>
                  <IconEyeOff
                    css={{
                      cursor: 'pointer',
                    }}
                    color={colors.blue}
                    onClick={() => }
                  />
                </ActionIcon>
              ) : (
                <ActionIcon size={20}>
                  <IconEye
                    css={{
                      cursor: 'pointer',
                    }}
                    color={colors.blue}
                    onClick={() => boxHidden.setValue(true)}
                  />
                </ActionIcon>
              )} */}

              {boxHidden.controls()}
            </Group>
          </Group>,

          <Group position="apart">
            <TitleOfSettings title={'Обрезано за краями'} />
            <Group css={{ alignItems: 'center' }} position="right">
              <Switch
                checked={boxIsOverflowHidden.viewValue}
                onChange={event => boxIsOverflowHidden.setValue(event.currentTarget.checked)}
                size="md"
                // label={'Скрыт'}
                onLabel={'Да'}
                offLabel={'Нет'}
              />
              {/* {boxIsOverflowHidden.viewValue ? (
                <ActionIcon size={20}>
                  <IconFrame
                    css={{
                      cursor: 'pointer',
                    }}
                    color={colors.blue}
                    onClick={() => boxIsOverflowHidden.setValue(false)}
                  />
                </ActionIcon>
              ) : (
                <ActionIcon size={20}>
                  <IconFrameOff
                    css={{
                      cursor: 'pointer',
                    }}
                    color={colors.blue}
                    onClick={() => boxIsOverflowHidden.setValue(true)}
                  />
                </ActionIcon>
              )} */}

              {boxIsOverflowHidden.controls()}
            </Group>
          </Group>,
        ]}
      />
    </div>
  )
}

const BoxSettingsShellSyncMemo = React.memo(BoxSettingsShellSync, (prevProps, nextProps) => {
  return prevProps.boxID == nextProps.boxID
})

export const EditBoxBackground = ({ modelID, selectID }: { selectID: 'BoxBackground1'; modelID: string }) => {
  const boxBackground = useGetSelectData({ modelID, selectID })
  const unpinImageToBoxBackground = trpc.useMutation('user.box.unpinImageToBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinImageToBoxBackground = trpc.useMutation('user.box.pinImageToBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [boxBackgroundOpened, setBoxBackgroundOpened] = useState(false)

  const [backgroundEditIsShow, setBackgroundEditIsShow] = useState(false)

  return (
    <>
      <ImagesSync
        opened={boxBackgroundOpened}
        onClose={() => setBoxBackgroundOpened(false)}
        imageChoosen={async ({ imageID, imageName }) => {
          const updatedBoxBackround = await pinImageToBoxBackground.mutateAsync({
            boxBackgroundID: boxBackground.id,
            imageID: imageID,
          })
          // boxBackground.setValue(updatedBoxBackround)
          setBoxBackgroundOpened(false)
        }}
      />

      <Group spacing={2} position="apart">
        <TitleOfSettings title={'Картинка'} />
        <Group>
          {boxBackground.image ? (
            <>
              <ActionIcon size={20}>
                <IconHandMove
                  css={{
                    cursor: 'pointer',
                  }}
                  color={colors.blue}
                  onClick={() => {
                    const result = !backgroundEditIsShow
                    setBackgroundEditIsShow(result)
                    boxActiveIDCenter.changeEditBoxBackgroundIsOpen({ is: result })
                  }}
                />{' '}
              </ActionIcon>
              <ActionIcon size={20}>
                <IconRefresh
                  color={colors.blue}
                  css={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setBoxBackgroundOpened(true)}
                />
              </ActionIcon>
              <ActionIcon size={20}>
                <IconX
                  color={colors.blue}
                  css={{
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    await unpinImageToBoxBackground.mutateAsync({
                      boxBackgroundID: boxBackground.id,
                    })
                  }}
                />
              </ActionIcon>
            </>
          ) : (
            <>
              <ActionIcon size={20}>
                <IconPlus
                  css={{
                    cursor: 'pointer',
                  }}
                  color={colors.blue}
                  onClick={() => setBoxBackgroundOpened(true)}
                />
              </ActionIcon>
            </>
          )}
        </Group>
      </Group>
      <Group spacing={2} position="apart">
        <TitleOfSettings title={'Цвет'} />
        <Group>
          <ChooseSiteColorsSync
            onClearColor={async () => {
              if (boxBackground.id) {
                await updateBoxBackground.mutate({
                  boxBackgroundID: boxBackground.id,
                  colorID: null,
                })
                // boxBackground.setValue({
                //   ...boxBackground.valueRef.current,
                //   color: null,
                // })
              }
            }}
            currentColorID={boxBackground.color?.id}
            onChooseColor={async ({ colorID }) => {
              if (boxBackground.id) {
                const updatedBoxBackround = await updateBoxBackground.mutateAsync({
                  boxBackgroundID: boxBackground.id,
                  colorID: colorID,
                })
                // boxBackground.setValue(updatedBoxBackround)
              }
            }}
          >
            {boxBackground.color ? (
              <EditImageBoxBackgroundColor modelID={boxBackground.color.id} selectID={boxBackground.color.selectID} />
            ) : (
              <ActionIcon size={20}>
                <IconPlus
                  css={{
                    cursor: 'pointer',
                  }}
                  color={colors.blue}
                />
              </ActionIcon>
            )}
          </ChooseSiteColorsSync>
        </Group>
      </Group>
    </>
  )
}

const EditImageBoxBackgroundColor = ({ modelID, selectID }: { selectID: 'Color1'; modelID: string }) => {
  const color = useGetSelectData({ modelID, selectID })
  return <ColorSwatch component="button" color={color.value} sx={{ color: '#fff', cursor: 'pointer' }}></ColorSwatch>
}
