import { DndContext, MouseSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import {
  ActionIcon,
  Button,
  CheckIcon,
  ColorSwatch,
  Drawer,
  Grid,
  Group,
  Kbd,
  Menu,
  Modal,
  Paper,
  Portal,
  Radio,
  SimpleGrid,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core'
import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'

import { useForm } from '@mantine/form'
import { useHotkeys } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { BoxVector, FlexBoxPosition, ScreeenType, WidthType } from '@prisma/client'
import {
  IconArrowLeftRight,
  IconArrowMoveLeft,
  IconArrowMoveRight,
  IconBox,
  IconBuildingCarousel,
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDeviceTablet,
  IconEye,
  IconEyeOff,
  IconForms,
  IconGridDots,
  IconHandMove,
  IconHeading,
  IconPhoto,
  IconPlus,
  IconQuestionMark,
  IconRefresh,
  IconSquareArrowDown,
  IconSquareArrowUp,
  IconTextSize,
  IconTransform,
  IconVideoPlus,
  IconWashDrycleanOff,
  IconX,
} from '@tabler/icons'
import { FaqComponent } from 'blocks/FAQBlockV1/FAQBlockV1'
import { VideoComponent } from 'blocks/VideoBlockV1/VideoBlockV1'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { EditImage } from 'components/EditImage/EditImage'
import { EditOneBoxEntity, EntityLineSettingsBox, TitleOfSettings } from 'components/EditOneBoxEntity/EditOneBoxEntity'
import { EditOneFaqComponent } from 'components/EditOneFaqComponent/EditOneFaqComponent'
import { EditorTextComponent } from 'components/EditorTextComponent/EditorTextComponent'
import { Form } from 'components/Form/Form'
import { Images } from 'components/ImageGallery'
import { ImagePreview } from 'components/ImagePreview/ImagePreview'
import { PositionSettingsItem } from 'components/PositionSettingsItem/PositionSettingsItem'
import Quiz from 'components/Quiz/Quiz'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { Section } from 'components/Section/Section'
import { useBoxBlockContext } from 'contexts/BoxBlockContext'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useSiteDataEventsContext } from 'contexts/SiteDataEventsContextProvider'
import useEmblaCarousel from 'embla-carousel-react'
import { useResponsiveProperty } from 'hooks/useResponsiveProperty/useResponsiveProperty'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { CSSProperties, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { NestedBoxSelect } from 'server/selects'
import { eventenerWithDetail } from 'utils/eventener'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

// create component
// update setting element in all share components
// add element to compnent
// hidden element in component
// delete element in component
// delete component
// copy component
// order box

// make box with nested elements to component
// set settings box only in one element with hot key and without update settings in share components

export const boxClickedByIDEventener = eventenerWithDetail<{ id: string }>({
  name: 'boxClickedByID',
})

const positionSettingsItemsGroups: Array<
  Array<
    {
      horizontalPosition: FlexBoxPosition
      verticalPosition: FlexBoxPosition
      vector: BoxVector
    }[]
  >
> = [
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexStart',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'Center',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexEnd',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexStart',
        vector: 'Right',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'Center',
        vector: 'Right',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexEnd',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexStart',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'Center',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexEnd',
        vector: 'Right',
      },
    ],
  ],
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceBetween',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceAround',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceBetween',
        vector: 'Right',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceAround',
        vector: 'Right',
      },
    ],

    [
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceBetween',
        vector: 'Right',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceAround',
        vector: 'Right',
      },
    ],
  ],
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexStart',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexStart',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexStart',
        vector: 'Bottom',
      },
    ],

    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'Center',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'Center',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'Center',
        vector: 'Bottom',
      },
    ],

    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'FlexEnd',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'FlexEnd',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'FlexEnd',
        vector: 'Bottom',
      },
    ],
  ],
  [
    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceAround',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceAround',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceAround',
        vector: 'Bottom',
      },
    ],

    [
      {
        verticalPosition: 'FlexStart',
        horizontalPosition: 'SpaceBetween',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'Center',
        horizontalPosition: 'SpaceBetween',
        vector: 'Bottom',
      },
      {
        verticalPosition: 'FlexEnd',
        horizontalPosition: 'SpaceBetween',
        vector: 'Bottom',
      },
    ],
  ],
]

const marginColor = '#ffb800'
const paddingColor = '#81ff9d'

export const responsiveModes = (long?: boolean): { mode: ScreeenType; text: ReactNode }[] => {
  return [
    {
      mode: 'Mobile',
      text: <IconDeviceMobile color={colors.blue} />,
    },
    {
      mode: 'Tablet',
      text: <IconDeviceTablet color={colors.blue} />,
    },
    {
      mode: 'Desktop',
      text: <IconDeviceDesktop color={colors.blue} />,
    },
  ]
}

type ParentBoxWidth = number

export const transformBoxPosition = (position: FlexBoxPosition | null) => {
  let transformedPosition = 'flex-start'
  if (position == 'Center') transformedPosition = 'center'
  if (position == 'FlexEnd') transformedPosition = 'flex-end'
  if (position == 'FlexStart') transformedPosition = 'flex-start'
  if (position == 'SpaceAround') transformedPosition = 'space-around'
  if (position == 'SpaceBetween') transformedPosition = 'space-between'
  return transformedPosition
}

export const transformBoxWidth = (widthType: WidthType) => {
  return widthType == 'Percent' ? '%' : 'px'
}

export function Box({
  box,
  parentBoxWidth,
  onAddBox,
  onDeleteBox,
  parentBoxId,
  boxesLength,
  boxBlockID,
  parentIsSlider,
}: {
  box: NonNullable<NestedBoxSelect>
  parentBoxWidth: ParentBoxWidth
  onAddBox: ({ box }: { box: NonNullable<NestedBoxSelect> }) => void
  onDeleteBox: ({ id }: { id: string }) => void
  parentBoxId?: string
  boxesLength?: number
  boxBlockID: string
  parentIsSlider?: boolean
}) {
  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings')
  const updateBox = trpc.useMutation('user.box.updateBox')
  const updateBoxResponsiveSettings = trpc.useMutation('user.box.updateBoxResponsiveSettings')

  const { lineIsShow, responsiveMode, focusBoxID, setFocusBoxID, focusBoxBlockID, setFocusBoxBlockID } = useBoxBlockContext()

  const responsiveModeRef = useRef(responsiveMode)
  useEffect(() => {
    responsiveModeRef.current = responsiveMode
  }, [responsiveMode])

  const [boxesPositionIsHover, setBoxesPositionIsHover] = useState(false)

  const boxRef = useRef<HTMLDivElement>(null)
  const positionAbsoluteBoxRef = useRef<HTMLDivElement>(null)
  const boxInsideRef = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  const { updateBoxByID } = useSiteDataEventsContext()

  const boxWidth = useResponsiveProperty({
    long: true,
    resposiveMode: {
      desktop: box.widthDesktopResponsiveMode,
      tablet: box.widthTabletResponsiveMode,
      mobile: box.widthMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.width,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.width,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.width,
    },
    propertyName: 'Ширина',
    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        width: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'width',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  // useEffect(() => {
  //   if (!boxWidthIsDrag) {
  //     boxWidth.setDesktopValue(box.responsiveBoxSettings.desktopBoxSettings.width)
  //     boxWidth.setTabletValue(box.responsiveBoxSettings.tabletBoxSettings.width)
  //     boxWidth.setMobileValue(box.responsiveBoxSettings.mobileBoxSettings.width)
  //   }
  // }, [box.responsiveBoxSettings.desktopBoxSettings.width, box.responsiveBoxSettings.tabletBoxSettings.width, box.responsiveBoxSettings.mobileBoxSettings.width])

  const parentBoxWidthRef = useRef(parentBoxWidth)
  useEffect(() => {
    parentBoxWidthRef.current = parentBoxWidth
  }, [parentBoxWidth])

  const boxBackground = useResponsiveProperty({
    resposiveMode: {
      desktop: box.backgroundDesktopResponsiveMode,
      tablet: box.backgroundTabletResponsiveMode,
      mobile: box.backgroundMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.background,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.background,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.background,
    },
    propertyName: 'Фон',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value) {
        await updateBoxBackground.mutateAsync({
          boxBackgroundID: value.id,
          height: value.height,
          width: value.width,
          positionX: value.positionX,
          positionY: value.positionY,
        })
      }
    },

    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'background',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHeight = useResponsiveProperty({
    resposiveMode: {
      desktop: box.heightDesktopResponsiveMode,
      tablet: box.heightTabletResponsiveMode,
      mobile: box.heightMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.height,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.height,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.height,
    },
    propertyName: 'Высота',
    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        height: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'height',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxMargin = useResponsiveProperty({
    resposiveMode: {
      desktop: box.marginDesktopResponsiveMode,
      tablet: box.marginTabletResponsiveMode,
      mobile: box.marginMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        top: box.responsiveBoxSettings.desktopBoxSettings.marginTop,
        right: box.responsiveBoxSettings.desktopBoxSettings.marginRight,
        bottom: box.responsiveBoxSettings.desktopBoxSettings.marginBottom,
        left: box.responsiveBoxSettings.desktopBoxSettings.marginLeft,
      },
      tablet: {
        top: box.responsiveBoxSettings.tabletBoxSettings.marginTop,
        right: box.responsiveBoxSettings.tabletBoxSettings.marginRight,
        bottom: box.responsiveBoxSettings.tabletBoxSettings.marginBottom,
        left: box.responsiveBoxSettings.tabletBoxSettings.marginLeft,
      },
      mobile: {
        top: box.responsiveBoxSettings.mobileBoxSettings.marginTop,
        right: box.responsiveBoxSettings.mobileBoxSettings.marginRight,
        bottom: box.responsiveBoxSettings.mobileBoxSettings.marginBottom,
        left: box.responsiveBoxSettings.mobileBoxSettings.marginLeft,
      },
    },
    propertyName: 'Внешний отступ',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        marginTop: value.top,
        marginRight: value.right,
        marginBottom: value.bottom,
        marginLeft: value.left,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'margin',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPadding = useResponsiveProperty({
    resposiveMode: {
      desktop: box.paddingDesktopResponsiveMode,
      tablet: box.paddingTabletResponsiveMode,
      mobile: box.paddingMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        top: box.responsiveBoxSettings.desktopBoxSettings.paddingTop,
        right: box.responsiveBoxSettings.desktopBoxSettings.paddingRight,
        bottom: box.responsiveBoxSettings.desktopBoxSettings.paddingBottom,
        left: box.responsiveBoxSettings.desktopBoxSettings.paddingLeft,
      },
      tablet: {
        top: box.responsiveBoxSettings.tabletBoxSettings.paddingTop,
        right: box.responsiveBoxSettings.tabletBoxSettings.paddingRight,
        bottom: box.responsiveBoxSettings.tabletBoxSettings.paddingBottom,
        left: box.responsiveBoxSettings.tabletBoxSettings.paddingLeft,
      },
      mobile: {
        top: box.responsiveBoxSettings.mobileBoxSettings.paddingTop,
        right: box.responsiveBoxSettings.mobileBoxSettings.paddingRight,
        bottom: box.responsiveBoxSettings.mobileBoxSettings.paddingBottom,
        left: box.responsiveBoxSettings.mobileBoxSettings.paddingLeft,
      },
    },
    propertyName: 'Внутренний отступ',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        paddingTop: value.top,
        paddingRight: value.right,
        paddingBottom: value.bottom,
        paddingLeft: value.left,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'padding',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPosition = useResponsiveProperty({
    resposiveMode: {
      desktop: box.positionDesktopResponsiveMode,
      tablet: box.positionTabletResponsiveMode,
      mobile: box.positionMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        vector: box.responsiveBoxSettings.desktopBoxSettings.vector || BoxVector.Right,
        horizontalPosition: box.responsiveBoxSettings.desktopBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: box.responsiveBoxSettings.desktopBoxSettings.verticalPosition || 'FlexStart',
      },
      tablet: {
        vector: box.responsiveBoxSettings.tabletBoxSettings.vector || BoxVector.Right,
        horizontalPosition: box.responsiveBoxSettings.tabletBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: box.responsiveBoxSettings.tabletBoxSettings.verticalPosition || 'FlexStart',
      },
      mobile: {
        vector: box.responsiveBoxSettings.mobileBoxSettings.vector || BoxVector.Right,
        horizontalPosition: box.responsiveBoxSettings.mobileBoxSettings.horizontalPosition || 'FlexStart',
        verticalPosition: box.responsiveBoxSettings.mobileBoxSettings.verticalPosition || 'FlexStart',
      },
    },
    propertyName: 'Выравнивание',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        vector: value.vector,
        horizontalPosition: value.horizontalPosition,
        verticalPosition: value.verticalPosition,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'position',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxHidden = useResponsiveProperty({
    resposiveMode: {
      desktop: box.hiddenDesktopResponsiveMode,
      tablet: box.hiddenTabletResponsiveMode,
      mobile: box.hiddenMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.hidden,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.hidden,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.hidden,
    },
    propertyName: 'Скрытость',

    onValueUpdated: async ({ responsiveMode, value }) => {
      updateBoxByID({
        id: box.id,
        box: {
          responsiveBoxSettings: {
            ...box.responsiveBoxSettings,
            desktopBoxSettings: {
              ...box.responsiveBoxSettings.desktopBoxSettings,
              hidden: responsiveMode == 'Desktop' ? value : box.responsiveBoxSettings.desktopBoxSettings.hidden,
            },
            tabletBoxSettings: {
              ...box.responsiveBoxSettings.tabletBoxSettings,
              hidden: responsiveMode == 'Tablet' ? value : box.responsiveBoxSettings.tabletBoxSettings.hidden,
            },
            mobileBoxSettings: {
              ...box.responsiveBoxSettings.mobileBoxSettings,
              hidden: responsiveMode == 'Mobile' ? value : box.responsiveBoxSettings.mobileBoxSettings.hidden,
            },
          },
        },
      })
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        hidden: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'hidden',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxGap = useResponsiveProperty({
    resposiveMode: {
      desktop: box.gapDesktopResponsiveMode,
      tablet: box.gapTabletResponsiveMode,
      mobile: box.gapMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.gap,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.gap,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.gap,
    },
    propertyName: 'Отступы',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        gap: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'gap',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxBorderRadius = useResponsiveProperty({
    resposiveMode: {
      desktop: box.borderRadiusDesktopResponsiveMode,
      tablet: box.borderRadiusTabletResponsiveMode,
      mobile: box.borderRadiusMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.borderRadiusLeftTop,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.borderRadiusLeftTop,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.borderRadiusLeftTop,
    },
    propertyName: 'Округление',

    onValueUpdated: async ({ responsiveMode, value }) => {
      await updateBoxSettings.mutateAsync({
        boxID: box.id,
        screenType: responsiveMode,
        borderRadiusLeftTop: value,
      })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'borderRadius',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxShadow = useResponsiveProperty({
    resposiveMode: {
      desktop: box.shadowDesktopResponsiveMode,
      tablet: box.shadowTabletResponsiveMode,
      mobile: box.shadowMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.shadow,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.shadow,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.shadow,
    },
    propertyName: 'Тень',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxShadow.mutateAsync({
          boxShadowID: value.id,
          spread: value.spread,
          blur: value.blur,
          xShift: value.xShift,
          yShift: value.yShift,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      // await updateBoxResponsiveSettings.mutateAsync({
      //   boxID: box.id,
      //   type: 'shadow',
      //   boxBlockScreenType: boxBlockResponsiveMode,
      //   screenType: responsiveMode,
      // })
    },
  })

  const boxBorder = useResponsiveProperty({
    resposiveMode: {
      desktop: box.borderDesktopResponsiveMode,
      tablet: box.borderTabletResponsiveMode,
      mobile: box.borderMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.border,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.border,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.border,
    },
    propertyName: 'Бордер',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxBorder.mutateAsync({
          boxBorderID: value.id,
          left: value.left,
          right: value.right,
          top: value.top,
          bottom: value.bottom,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      // await updateBoxResponsiveSettings.mutateAsync({
      //   boxID: box.id,
      //   type: 'border',
      //   boxBlockScreenType: boxBlockResponsiveMode,
      //   screenType: responsiveMode,
      // })
    },
  })

  const boxWidthType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.widthTypeDesktopResponsiveMode,
      tablet: box.widthTypeTabletResponsiveMode,
      mobile: box.widthTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: box.responsiveBoxSettings.desktopBoxSettings.widthType,
      tablet: box.responsiveBoxSettings.tabletBoxSettings.widthType,
      mobile: box.responsiveBoxSettings.mobileBoxSettings.widthType,
    },
    propertyName: 'Тип ширины',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          widthType: value,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'widthType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  const boxPostionType = useResponsiveProperty({
    resposiveMode: {
      desktop: box.positionTypeDesktopResponsiveMode,
      tablet: box.positionTypeTabletResponsiveMode,
      mobile: box.positionTypeMobileResponsiveMode,
    },
    resposiveValue: {
      desktop: {
        positionType: box.responsiveBoxSettings.desktopBoxSettings.positionType,
        positionX: box.responsiveBoxSettings.desktopBoxSettings.positionX,
        positionY: box.responsiveBoxSettings.desktopBoxSettings.positionY,
      },
      tablet: {
        positionType: box.responsiveBoxSettings.tabletBoxSettings.positionType,
        positionX: box.responsiveBoxSettings.tabletBoxSettings.positionX,
        positionY: box.responsiveBoxSettings.tabletBoxSettings.positionY,
      },
      mobile: {
        positionType: box.responsiveBoxSettings.mobileBoxSettings.positionType,
        positionX: box.responsiveBoxSettings.mobileBoxSettings.positionX,
        positionY: box.responsiveBoxSettings.mobileBoxSettings.positionY,
      },
    },
    propertyName: 'Тип положения',

    onValueUpdated: async ({ responsiveMode, value }) => {
      if (value)
        await updateBoxSettings.mutateAsync({
          boxID: box.id,
          screenType: responsiveMode,
          positionType: value.positionType,
          positionX: value.positionX,
          positionY: value.positionY,
        })
    },
    onResponsiveModeUpdated: async ({ responsiveMode, boxBlockResponsiveMode }) => {
      await updateBoxResponsiveSettings.mutateAsync({
        boxID: box.id,
        type: 'positionType',
        boxBlockScreenType: boxBlockResponsiveMode,
        screenType: responsiveMode,
      })
    },
  })

  // const [component, setComponent] = useState(box.component)

  const [settingsIsShow, setSettingsIsShow, settingsIsShowRef] = useStateRef(false)

  useEffect(() => {
    if (!settingsIsShow) setUpLayerForCheckDoubleClickIsHide(false)
  }, [settingsIsShow])

  useEffect(() => {
    setSettingsIsShow(focusBoxID == box.id && focusBoxBlockID == boxBlockID)
  }, [focusBoxID, focusBoxBlockID])

  const [arrows, setArrows, arrowsRef] = useStateRef({
    space: false,
    shiftLeft: false,
    metaLeft: false,
    background: false,
    hidden: false,
  })

  const [backgroundEditIsShow, setBackgroundEditIsShow] = useState(false)

  useEffect(() => {
    if (arrows.background) {
      if (backgroundEditIsShow) {
        setBackgroundEditIsShow(false)
      } else {
        if (settingsIsShow) setBackgroundEditIsShow(true)
      }
    }
  }, [arrows.background])

  useEffect(() => {
    if (!settingsIsShow) setBackgroundEditIsShow(false)
  }, [boxesPositionIsHover])

  useEffect(() => {
    if (boxInsideRef.current) {
      setWidth(boxInsideRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxInsideRef.current) {
          setWidth(Math.ceil(boxInsideRef.current.offsetWidth))
        }
      })
      resizeObserver.observe(boxInsideRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])
  useEffect(() => {
    if (positionAbsoluteBoxRef.current) {
      setHeight(positionAbsoluteBoxRef.current.offsetHeight)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (positionAbsoluteBoxRef.current) {
          setHeight(Math.ceil(positionAbsoluteBoxRef.current.offsetHeight))
        }
      })
      resizeObserver.observe(positionAbsoluteBoxRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (arrows.hidden) {
      if (boxesPositionIsHover) boxHidden.setValue(!boxHidden.valueRef.current)
    }
  }, [arrows.hidden])

  useEffect(() => {
    const keydownCallback = async (event: KeyboardEvent) => {
      setArrows(arrows => {
        const newArrows = {
          space: event.code == 'Space' ? true : arrows.space,
          shiftLeft: event.code == 'ShiftLeft' ? true : arrows.shiftLeft,
          metaLeft: event.code == 'MetaLeft' ? true : arrows.metaLeft,
          background: event.key == 'i' ? true : arrows.background,
          hidden: event.key == 'h' ? true : arrows.hidden,
        }
        return newArrows
      })
    }
    const keyupCallback = (event: KeyboardEvent) => {
      setArrows(arrows => {
        const newArrows = {
          space: event.code == 'Space' ? false : arrows.space,
          shiftLeft: event.code == 'ShiftLeft' ? false : arrows.shiftLeft,
          metaLeft: event.code == 'MetaLeft' ? false : arrows.metaLeft,
          background: event.key == 'i' ? false : arrows.background,
          hidden: event.key == 'h' ? false : arrows.hidden,
        }
        return newArrows
      })
    }

    const mouseoutCallback = (event: MouseEvent) => {
      event.stopPropagation()
      setBoxesPositionIsHover(false)
    }
    const mouseoverCallback = (event: MouseEvent) => {
      event.stopPropagation()
      setBoxesPositionIsHover(true)
    }

    boxRef.current?.addEventListener('mouseout', mouseoutCallback)
    boxRef.current?.addEventListener('mouseover', mouseoverCallback)

    // boxRef.current?.addEventListener('keydown', keydownCallback)
    // boxRef.current?.addEventListener('keyup', keyupCallback)
    return () => {
      boxRef.current?.removeEventListener('mouseout', mouseoutCallback)
      boxRef.current?.removeEventListener('mouseover', mouseoverCallback)

      // boxRef.current?.removeEventListener('keydown', keydownCallback)
      // boxRef.current?.removeEventListener('keyup', keyupCallback)
    }
  }, [])

  const widthAndHeight = 8

  const somethingIsDrag = boxWidth.isDrag || boxPadding.isDrag || boxHeight.isDrag || boxMargin.isDrag

  useEffect(() => {
    setTimeout(() => {
      if (somethingIsDrag) localStorage.setItem('somethingIsDrag', ``)
      else localStorage.removeItem('somethingIsDrag')
    }, 50)
  }, [somethingIsDrag])

  const boxIsActive = settingsIsShow || somethingIsDrag

  const createBox = trpc.useMutation('user.box.createBox')
  const deleteBox = trpc.useMutation('user.box.deleteBox')
  const copyBox = trpc.useMutation('user.box.copyBox', {
    onSuccess: () => {
      showNotification({
        title: 'Блок вставлен',
        message: '',
        color: 'green',
      })
    },
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinTextComponentToBox = trpc.useMutation('user.box.pinTextComponentToBox')
  const createImageComponent = trpc.useMutation('user.box.createImageComponent')
  const updateImageComponent = trpc.useMutation('user.box.updateImageComponent')
  const changeImageComponentImage = trpc.useMutation('user.box.changeImageComponentImage')

  const createBoxBackground = trpc.useMutation('user.box.createBoxBackground')
  const deleteBoxBackground = trpc.useMutation('user.box.deleteBoxBackground')
  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground')

  const createBoxShadow = trpc.useMutation('user.box.createBoxShadow')
  const unpinBoxShadow = trpc.useMutation('user.box.unpinBoxShadow')
  const updateBoxShadow = trpc.useMutation('user.box.updateBoxShadow')
  const pinBoxShadow = trpc.useMutation('user.box.pinBoxShadow')

  const createBoxBorder = trpc.useMutation('user.box.createBoxBorder')
  const updateBoxBorder = trpc.useMutation('user.box.updateBoxBorder')
  const deleteBoxBorder = trpc.useMutation('user.box.deleteBoxBorder')

  const createFormComponent = trpc.useMutation('user.box.createFormComponent')
  const createQuizComponent = trpc.useMutation('user.box.createQuizComponent')
  const createVideoComponent = trpc.useMutation('user.box.createVideoComponent')
  const createFaqComponent = trpc.useMutation('user.box.createFaqComponent')

  const pinImageToBoxBackground = trpc.useMutation('user.box.pinImageToBoxBackground')
  const unpinImageToBoxBackground = trpc.useMutation('user.box.unpinImageToBoxBackground')

  const transformedVerticalPosition = transformBoxPosition(boxPosition.value.verticalPosition)
  const transformedHorizontalPosition = transformBoxPosition(boxPosition.value.horizontalPosition)

  const [boxBackgroundOpened, setBoxBackgroundOpened] = useState(false)

  const [imagesOpened, setImagesOpened] = useState(false)
  const [imageComponentEditOpened, setImageComponentEditOpened] = useState(false)

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: box.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: box.id,
  })

  const [boxes, setBoxes, boxesRef] = useStateRef(box.boxes)
  const [needUpdateBoxes, setNeedUpdateBoxes, needUpdateBoxesRef] = useStateRef(true)

  useEffect(() => {
    if (needUpdateBoxesRef.current) {
      updateBoxByID({
        id: box.id,
        box: {
          boxes: boxesRef.current,
        },
      })
    }
    setNeedUpdateBoxes(true)
  }, [boxes])

  useEffect(() => {
    setNeedUpdateBoxes(false)
    setBoxes(box.boxes)
  }, [JSON.stringify(box.boxes)])

  const site = useContext(SiteContext)
  const theme = useMantineTheme()

  const [createFormBlockOpened, setCreateFormBlockOpened] = useState(false)
  const [createQuizBlockOpened, setCreateQuizBlockOpened] = useState(false)

  const [chooseURLVideoOpened, setChooseURLVideoOpened] = useState(false)
  const [chooseBoxShadowOpened, setChooseBoxShadowOpened] = useState(false)

  const formCreateUrlVideo = useForm({
    initialValues: {
      url: '',
    },
  })

  const [editFaqOpened, setEditFaqOpened] = useState(false)

  const boxBackgroundImageRef = useRef<HTMLImageElement>(null)

  const [textComponent, setTextComponent] = useState(box.textComponent)

  useEffect(() => {
    setTextComponent(box.textComponent)
  }, [JSON.stringify(box?.textComponent)])

  const [imageComponent, setImageComponent, imageComponentRef] = useStateRef(box.imageComponent)

  useEffect(() => {
    setImageComponent(box.imageComponent)
  }, [JSON.stringify(box?.imageComponent)])

  const onPasteBox = async () => {
    if (settingsIsShowRef.current) {
      const copiedBlockID = await navigator.clipboard.readText()
      if (copiedBlockID == box.id) {
        if (typeof boxesLength == 'number') {
          const copiedBox = await copyBox.mutateAsync({
            boxID: box.id,
            parentBoxID: box.parentBoxId,
            order: boxesLength + 1,
          })
          // if (copiedBox) onAddBox({ box: copiedBox })
        }
      } else {
        const copiedBox = await copyBox.mutateAsync({
          boxID: copiedBlockID,
          parentBoxID: box.id,
          order: boxesRef.current ? boxesRef.current.length + 1 : 0,
        })
        // setBoxes(boxes => {
        //   if (boxes && copiedBox) {
        //     return [...boxes, copiedBox]
        //   }
        // })
      }
    }
  }
  const onCopyBox = async () => {
    if (settingsIsShowRef.current) {
      await navigator.clipboard.writeText(box.id)
      showNotification({
        title: 'Блок скопирован',
        message: '',
        color: 'green',
      })
    }
  }
  const onDeleteKeyBox = async () => {
    if (settingsIsShowRef.current) {
      onDeleteBox({ id: box.id })
    }
  }

  const addInsideBox = async () => {
    if (!thereIsComponentRef.current && settingsIsShowRef.current) {
      const newBox = await createBox.mutateAsync({
        boxID: box.id,
        positionType: 'Relative',
        order: (boxesRef.current ? boxesRef.current.length + 1 : 100) + 1,
        mode: activeResponsiveModeCenter.getResponsiveMode(),
      })

      // setBoxes(boxes => {
      //   return Array.isArray(boxes) ? [...boxes, newBox] : boxes
      // })
    }
  }

  useHotkeys([
    ['mod+V', onPasteBox],
    ['mod+C', onCopyBox],
    ['backspace', onDeleteKeyBox],
    ['enter', addInsideBox],
  ])

  const [boxPositionIsOpen, setBoxPositionIsOpen] = useState(
    !(
      box.responsiveBoxSettings.desktopBoxSettings.vector == 'Right' &&
      box.responsiveBoxSettings.desktopBoxSettings.horizontalPosition == 'FlexStart' &&
      box.responsiveBoxSettings.desktopBoxSettings.verticalPosition == 'FlexStart'
    )
  )

  const upLayerSetTimeout = useRef<undefined | number | NodeJS.Timeout>()
  const [upLayerForCheckDoubleClickIsHide, setUpLayerForCheckDoubleClickIsHide, upLayerForCheckDoubleClickIsHideRef] =
    useStateRef(false)

  useEffect(() => {
    if (upLayerForCheckDoubleClickIsHideRef.current) {
      const interval = setTimeout(() => {
        setUpLayerForCheckDoubleClickIsHide(false)
      }, 300)
      upLayerSetTimeout.current = interval
    }
  }, [upLayerForCheckDoubleClickIsHide])

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  })

  const sensors = useSensors(mouseSensor)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const setFocusBoxIDAndFocusBoxBlockID = () => {
    if (typeof localStorage.getItem('somethingIsDrag') !== 'string') {
      setFocusBoxID(box.id)
      setFocusBoxBlockID(boxBlockID)
    }

    if (upLayerSetTimeout.current) {
      clearTimeout(upLayerSetTimeout.current)
    }
  }

  const [thereIsComponent, setThereIsComponent, thereIsComponentRef] = useStateRef(
    !!(
      box.faqComponent ||
      box.formComponent ||
      box.imageComponent ||
      box.quizComponent ||
      box.textComponent ||
      box.videoComponent
    )
  )

  useEffect(() => {
    setThereIsComponent(
      !!(
        box.faqComponent ||
        box.formComponent ||
        box.imageComponent ||
        box.quizComponent ||
        box.textComponent ||
        box.videoComponent
      )
    )
  }, [box.faqComponent, box.formComponent, box.imageComponent, box.quizComponent, box.textComponent, box.videoComponent])

  const [chooseSectionOpened, setChooseSectionOpened] = useState(false)
  const [chooseURLOpened, setChooseURLOpened] = useState(false)
  const [chooseFormQuizOpened, setChooseFormQuizOpened] = useState(false)
  const deleteLink = trpc.useMutation('user.deleteLink')
  const createLink = trpc.useMutation('user.createLink')

  const formCreateUrlLink = useForm({
    initialValues: {
      url: '',
    },
  })

  const linkIDs = {
    pinID: box.id,
    type: 'box',
  }

  const { classes } = useMainStyles()

  const boxesNode = boxes ? (
    boxes.map(boxLoop => (
      <Box
        parentIsSlider={box.isSlider}
        key={boxLoop.id}
        parentBoxId={box.id}
        boxesLength={box.boxes?.length}
        onAddBox={({ box }) => {
          // setBoxes(boxes => {
          //   return Array.isArray(boxes) ? [...boxes, box] : boxes
          // })
        }}
        onDeleteBox={({ id }) => {
          deleteBox.mutate({
            boxID: id,
          })
          // setBoxes(boxes => (Array.isArray(boxes) ? boxes.filter(box => box.id !== id) : boxes))
        }}
        parentBoxWidth={width}
        box={boxLoop}
        boxBlockID={boxBlockID}
      />
    ))
  ) : (
    <></>
  )

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
  })

  useEffect(() => {
    if (!boxIsActive) setMenuIsOpen(false)
  }, [boxIsActive])

  return (
    <div
      id={`box-id-${box.id}`}
      onContextMenu={
        boxesPositionIsHover
          ? event => {
              event.stopPropagation()
              event.preventDefault()
              setMenuIsOpen(menuIsOpen => !menuIsOpen)

              setFocusBoxIDAndFocusBoxBlockID()
            }
          : undefined
      }
      ref={setNodeRef}
      css={{
        willChange:
          'margin, paddingTop, paddingRight, paddingBottom, paddingLeft, display, opacity, width, minHeight, backgroundColor, borderRadius, boxShadow, borderTop, borderRight, borderBottom, borderLeft',
      }}
      style={{
        maxWidth: boxPostionType.viewValue.positionType == 'Relative' ? '100%' : undefined,
        position: boxPostionType.viewValue.positionType == 'Relative' ? 'relative' : 'absolute',
        left: boxPostionType.viewValue.positionType == 'Relative' ? `${boxPostionType.viewValue.positionX}px` : `50%`,
        top: boxPostionType.viewValue.positionType == 'Relative' ? `${boxPostionType.viewValue.positionY}px` : `0%`,
        transform:
          boxPostionType.viewValue.positionType == 'Relative'
            ? undefined
            : `translate3d(${boxPostionType.viewValue.positionX || 0}px, ${boxPostionType.viewValue.positionY || 0}px, 0)`,

        margin: `${boxMargin.viewValue.top || 0}px ${boxMargin.viewValue.right || 0}% ${boxMargin.viewValue.bottom || 0}px ${
          boxMargin.viewValue.left || 0
        }%`,
        // padding: `${boxPadding.viewValue.top || 0}px ${boxPadding.viewValue.right || 0}px ${
        //   boxPadding.viewValue.bottom || 0
        // }px ${boxPadding.viewValue.left || 0}px`,
        paddingTop: `${boxPadding.viewValue.top || 0}px`,
        paddingRight: `${boxPadding.viewValue.right || 0}px`,
        paddingBottom: `${boxPadding.viewValue.bottom || 0}px`,
        paddingLeft: `${boxPadding.viewValue.left || 0}px`,

        zIndex: box.responsiveBoxSettings.desktopBoxSettings.order,

        display: boxHidden.value ? 'none' : 'flex',
        width: boxWidth.viewValue ? `${boxWidth.viewValue}${transformBoxWidth(boxWidthType.viewValue)}` : 'auto',
        minWidth: parentIsSlider
          ? boxWidth.viewValue
            ? `${boxWidth.viewValue}${transformBoxWidth(boxWidthType.viewValue)}`
            : 'auto'
          : undefined,
        minHeight: `${boxHeight.viewValue ? `${boxHeight.viewValue}px` : 'auto'}`,
        backgroundColor: `${boxBackground.viewValue?.color?.value || 'transparent'}`,
        borderRadius: boxBorderRadius.viewValue || 0,
        boxShadow: boxShadow.viewValue
          ? `${boxShadow.viewValue.xShift}px ${boxShadow.viewValue.yShift}px ${boxShadow.viewValue.blur}px ${boxShadow.viewValue.spread}px ${boxShadow.viewValue.color.value}`
          : undefined,
        borderTop: boxBorder.viewValue ? `${boxBorder.viewValue.top}px solid ${boxBorder.viewValue.color.value}` : undefined,
        borderRight: boxBorder.viewValue
          ? `${boxBorder.viewValue.right}px solid ${boxBorder.viewValue.color.value}`
          : undefined,
        borderBottom: boxBorder.viewValue
          ? `${boxBorder.viewValue.bottom}px solid ${boxBorder.viewValue.color.value}`
          : undefined,
        borderLeft: boxBorder.viewValue ? `${boxBorder.viewValue.left}px solid ${boxBorder.viewValue.color.value}` : undefined,
        ...styleDrag,
      }}
      onClick={
        boxesPositionIsHover
          ? event => {
              // location.hash = `#layer-id-${box.id}`
              event.stopPropagation()

              setMenuIsOpen(false)

              setFocusBoxIDAndFocusBoxBlockID()

              document.getElementById(`layer-id-${box.id}`)?.scrollIntoView()
            }
          : undefined
      }
    >
      {chooseURLOpened ? (
        <Modal withinPortal transitionDuration={0} opened={chooseURLOpened} onClose={() => setChooseURLOpened(false)}>
          <form
            onSubmit={formCreateUrlLink.onSubmit(async values => {
              // createLink.mutate({
              //   url: values.url,
              //   ...linkIDs,
              // })
            })}
          >
            <TextInput mb="xs" required placeholder="url" {...formCreateUrlLink.getInputProps('url')} />

            <Group position="right">
              <Button loading={createLink.isLoading} type="submit">
                Создать
              </Button>
            </Group>
          </form>
        </Modal>
      ) : null}
      {chooseFormQuizOpened ? (
        <Modal withinPortal transitionDuration={0} opened={chooseFormQuizOpened} onClose={() => setChooseFormQuizOpened(false)}>
          {site.popupsV1.map(popup => (
            <Button
              key={popup.id}
              compact
              fullWidth
              loading={createLink.isLoading}
              onClick={() => {
                // createLink.mutate({
                //   popupID: popup.id,
                //   ...linkIDs,
                // })
              }}
            >
              {popup.quiz ? `Квиз: ${popup.quiz.title}` : ''}
              {popup.form ? `Форма: ${popup.form.title}` : ''}
            </Button>
          ))}
        </Modal>
      ) : null}
      {chooseSectionOpened ? (
        <Drawer
          transitionDuration={0}
          overlayOpacity={0.55}
          overlayBlur={3}
          padding={'md'}
          size="calc(100vh - 50px)"
          lockScroll={true}
          position="bottom"
          opened={chooseSectionOpened}
          onClose={() => setChooseSectionOpened(false)}
          title="Выбрать блок для ссылки"
          css={{
            overflowY: 'scroll',
          }}
        >
          <div
            css={{
              overflowX: 'scroll',
              overflowY: 'hidden',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            {site.pagesV1.map(page => {
              return (
                <div key={page.id} css={{ minWidth: 300, marginRight: 30 }}>
                  /{page.pathname} - {page.title}{' '}
                  <Button
                    onClick={() => {
                      if (!box.link) {
                        // createLink.mutate({
                        //   anchor: {
                        //     pageID: page.id,
                        //   },
                        //   ...linkIDs,
                        // })
                        setChooseSectionOpened(false)
                      }
                    }}
                    compact
                  >
                    Выбрать страницу как ссылку
                  </Button>
                  <ReduceComponent
                    component={
                      <>
                        {page.sectionsPinnedCollection.map(sectionsPinnedCollection => {
                          return sectionsPinnedCollection.sectionsCollection.sectionsChild.map(sectionChild => {
                            const sectionIsChoosen =
                              box.link?.page?.id == page.id &&
                              box.link?.sectionCollection?.id == sectionsPinnedCollection.id &&
                              box.link?.section?.id == sectionChild.id

                            return (
                              <div
                                key={sectionChild.id}
                                css={
                                  box.link
                                    ? {
                                        border: sectionIsChoosen ? '20px dashed green' : 'none',
                                      }
                                    : {
                                        cursor: 'pointer',
                                        transition: '0.15s',
                                        ':hover': {
                                          transform: 'scale(1.05)',
                                        },
                                      }
                                }
                                onClick={() => {
                                  if (!box.link) {
                                    // createLink.mutate({
                                    //   anchor: {
                                    //     pageID: page.id,
                                    //     sectionCollectionID: sectionsPinnedCollection.id,
                                    //     sectionID: sectionChild.id,
                                    //   },
                                    //   ...linkIDs,
                                    // })
                                    setChooseSectionOpened(false)
                                  }
                                }}
                              >
                                <ReduceComponent component={<Section {...sectionChild} />} />
                              </div>
                            )
                          })
                        })}
                      </>
                    }
                  />
                </div>
              )
            })}
          </div>
        </Drawer>
      ) : null}

      {(boxesPositionIsHover || boxIsActive) &&
      (box.isSlider || box.isSliderShell || box.isSliderArrowNext || box.isSliderArrowPrev) ? (
        <Tooltip
          opened={true}
          label={`${box.isSlider ? 'Cлайдер' : ''}${box.isSliderShell ? 'Оболочка слайдера' : ''}${
            box.isSliderArrowNext ? 'Слайдер "Дальше"' : ''
          }${box.isSliderArrowPrev ? 'Слайдер "Назад"' : ''}`}
        >
          <div
            css={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          ></div>
        </Tooltip>
      ) : null}

      {boxIsActive || boxesPositionIsHover ? (
        <Menu
          width={250}
          opened={menuIsOpen}
          onClose={() => setMenuIsOpen(true)}
          transitionDuration={0}
          withinPortal={true}
          shadow="md"
        >
          <Menu.Target>
            <div
              css={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                height: 1,
                pointerEvents: 'none',
              }}
            ></div>
          </Menu.Target>

          <Menu.Dropdown
            className={classes.hideScrollbar}
            css={{
              maxHeight: 400,
              overflowY: 'auto',
            }}
          >
            <div
              onClick={event => {
                // event.stopPropagation()
                setMenuIsOpen(false)
              }}
            >
              {thereIsComponent || box.boxes?.length ? (
                <>
                  {box.boxes?.length ? (
                    <>
                      <Menu.Item
                        onClick={async () => {
                          addInsideBox()
                        }}
                        icon={<IconPlus color={colors.blue} size={14} />}
                        rightSection={<Kbd>Enter</Kbd>}
                      >
                        Добавить слой
                      </Menu.Item>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Menu.Label>Добавить</Menu.Label>
                  <Menu.Item
                    onClick={async () => {
                      const newBox = await createBox.mutateAsync({
                        boxID: box.id,
                        positionType: 'Absolute',
                        order: 1,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (newBox) setBoxes(boxes => (Array.isArray(boxes) ? [...boxes, newBox] : boxes))
                    }}
                    icon={<IconBox color={colors.blue} size={14} />}
                  >
                    Блок свободный
                  </Menu.Item>
                  <Menu.Item
                    onClick={async () => {
                      const newBox = await createBox.mutateAsync({
                        boxID: box.id,
                        positionType: 'Relative',
                        order: 1,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (newBox) setBoxes(boxes => (Array.isArray(boxes) ? [...boxes, newBox] : boxes))
                    }}
                    icon={<IconBox color={colors.blue} size={14} />}
                  >
                    Блок вложенный
                  </Menu.Item>
                  <Menu.Label>Сделать</Menu.Label>
                  <Menu.Item
                    onClick={async () => {
                      const updatedBox = await pinTextComponentToBox.mutateAsync({
                        boxID: box.id,
                        type: 'headline',
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      if (updatedBox?.textComponent) setTextComponent(updatedBox?.textComponent)
                    }}
                    icon={<IconHeading color={colors.blue} size={14} />}
                  >
                    Заголовком
                  </Menu.Item>
                  <Menu.Item
                    onClick={async () => {
                      const updatedBox = await pinTextComponentToBox.mutateAsync({
                        boxID: box.id,
                        type: 'paragraph',
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      if (updatedBox?.textComponent) setTextComponent(updatedBox?.textComponent)
                    }}
                    icon={<IconTextSize color={colors.blue} size={14} />}
                  >
                    Текстом
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setImagesOpened(true)
                    }}
                    icon={<IconPhoto color={colors.blue} size={14} />}
                  >
                    Картинкой
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTransform color={colors.blue} size={14} />}
                    onClick={() => setCreateQuizBlockOpened(true)}
                  >
                    Квизом
                  </Menu.Item>
                  <Menu.Item icon={<IconForms color={colors.blue} size={14} />} onClick={() => setCreateFormBlockOpened(true)}>
                    Формой
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconVideoPlus color={colors.blue} size={14} />}
                    onClick={() => setChooseURLVideoOpened(true)}
                  >
                    Видео
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconQuestionMark color={colors.blue} size={14} />}
                    onClick={() => createFaqComponent.mutate({ boxID: box.id })}
                  >
                    {' '}
                    Вопрос-ответом
                  </Menu.Item>
                </>
              )}
              <Menu.Divider />

              <Menu.Label>
                {box.link ? (
                  <>
                    Ссылка{' '}
                    {box.link.popup ? (
                      <>
                        на попап
                        {/* {box.link.popup.quiz ? `с квизом "${box.link.popup.quiz.title}"` : ''}
                        {box.link.popup.form ? `с формой "${box.link.popup.form.title}"` : ''} */}
                      </>
                    ) : null}
                    {box.link.url ? <> на другой сайт: {box.link.url}</> : ''}
                    {box.link.page ? <> на cтраницу: {box.link.page.pathname}</> : ''}
                  </>
                ) : (
                  <>Добавить ссылку на:</>
                )}
              </Menu.Label>

              {box.link ? (
                <>
                  {box.link.section ? (
                    <Menu.Item onClick={() => setChooseSectionOpened(true)}>Показать выбранный блок</Menu.Item>
                  ) : null}
                  <Menu.Item
                    color={'red'}
                    // loading={deleteLink.isLoading}
                    onClick={() => {
                      // event.stopPropagation()
                      if (box.link)
                        deleteLink.mutate({
                          linkID: box.link.id,
                        })
                    }}
                  >
                    Удалить ссылку
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item onClick={() => setChooseSectionOpened(true)}>Блок или страницу</Menu.Item>
                  <Menu.Item onClick={() => setChooseFormQuizOpened(true)}>Попап</Menu.Item>
                  <Menu.Item onClick={() => setChooseURLOpened(true)}>Другой сайт</Menu.Item>
                </>
              )}
              <Menu.Divider />

              <Menu.Label>Действия</Menu.Label>
              <Menu.Item
                onClick={() => {
                  onCopyBox()
                }}
                rightSection={<Kbd>Ctr+C</Kbd>}
              >
                Скопировать блок
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  onPasteBox()
                }}
                rightSection={<Kbd>Ctr+V</Kbd>}
              >
                Вставить блок
              </Menu.Item>

              <Menu.Item
                color={'red'}
                onClick={() => {
                  onDeleteKeyBox()
                }}
                rightSection={<Kbd>Backspace</Kbd>}
              >
                Удалить блок
              </Menu.Item>

              <Menu.Divider />

              {thereIsComponent ? null : (
                <>
                  <Menu.Label>Слайдер</Menu.Label>
                  {box.isSliderShell ? (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSliderShell: false,
                        })
                      }}
                      icon={<IconArrowLeftRight color={colors.blue} size={14} />}
                    >
                      Убрать оболочку слайдера
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSliderShell: true,
                        })
                      }}
                      icon={<IconArrowLeftRight color={colors.blue} size={14} />}
                    >
                      Сделать оболочкой слайдера
                    </Menu.Item>
                  )}
                  {box.isSlider ? (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSlider: false,
                        })
                      }}
                      icon={<IconBuildingCarousel color={colors.blue} size={14} />}
                    >
                      Убрать слайдер
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSlider: true,
                        })
                      }}
                      icon={<IconBuildingCarousel color={colors.blue} size={14} />}
                    >
                      Сделать слайдером
                    </Menu.Item>
                  )}
                </>
              )}

              {box.isSliderArrowNext ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowNext: false,
                    })
                  }}
                  icon={<IconArrowMoveRight color={colors.blue} size={14} />}
                >
                  Убрать кнопку "Дальше"
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowNext: true,
                    })
                  }}
                  icon={<IconArrowMoveRight color={colors.blue} size={14} />}
                >
                  Сделать кнопкой "Дальше"
                </Menu.Item>
              )}
              {box.isSliderArrowPrev ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowPrev: false,
                    })
                  }}
                  icon={<IconArrowMoveLeft color={colors.blue} size={14} />}
                >
                  Убратькнопку "Назад"
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowPrev: true,
                    })
                  }}
                  icon={<IconArrowMoveLeft color={colors.blue} size={14} />}
                >
                  Сделать кнопкой "Назад"
                </Menu.Item>
              )}
            </div>
          </Menu.Dropdown>
        </Menu>
      ) : null}
      {settingsIsShow ? (
        <Portal target="#box-settings">
          <div
            onClick={event => {
              event.stopPropagation()
            }}
            style={{
              transition: '0.3s',
              zIndex: 10,
              opacity: settingsIsShow ? 1 : 0,
              pointerEvents: settingsIsShow ? undefined : 'none',
            }}
          >
            <EntityLineSettingsBox
              childs={[
                <SimpleGrid css={{ alignItems: 'center' }} cols={2}>
                  <EditOneBoxEntity value={boxWidth.viewValue} onChange={boxWidth.setValue} title={'Ширина'} />

                  <Group css={{ alignItems: 'center' }} position="apart">
                    {boxWidth.controls()}
                    <>
                      <div
                        css={{
                          textDecoration: 'underline',
                        }}
                        onClick={() => boxWidthType.setValue(boxWidthType.valueRef.current == 'Pixel' ? 'Percent' : 'Pixel')}
                      >
                        {boxWidthType.viewValue == 'Pixel' ? 'PX' : '%'}
                      </div>
                      {boxWidthType.controls()}
                    </>
                  </Group>
                </SimpleGrid>,
                <SimpleGrid css={{ alignItems: 'center' }} cols={2}>
                  <EditOneBoxEntity value={boxHeight.viewValue} onChange={boxHeight.setValue} title={'Высота'} />
                  {boxHeight.controls()}
                </SimpleGrid>,
                <SimpleGrid cols={2}>
                  <EditOneBoxEntity
                    value={boxBorderRadius.viewValue}
                    onChange={boxBorderRadius.setValue}
                    title={'Cкругления'}
                  />
                </SimpleGrid>,
                box.boxes && box.boxes.length > 1 ? (
                  <Grid css={{ alignItems: 'center' }} columns={10}>
                    <Grid.Col span={8}>
                      <EditOneBoxEntity
                        value={boxGap.viewValue}
                        onChange={value => boxGap.setValue(value == null ? 0 : value)}
                        title={'Отступы между боксами'}
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Group position="right">{boxGap.controls()}</Group>
                    </Grid.Col>
                  </Grid>
                ) : undefined,
                boxPostionType.viewValue.positionType == 'Relative' ? (
                  <div>
                    <SimpleGrid cols={2} mb={4}>
                      <TitleOfSettings title={'Отступы снаружи:'} />
                      {boxMargin.controls()}
                    </SimpleGrid>
                    <SimpleGrid cols={2} mb={4}>
                      <EditOneBoxEntity
                        value={boxMargin.viewValue.top}
                        onChange={value => {
                          boxMargin.setValue({
                            ...boxMargin.valueRef.current,
                            top: value,
                          })
                        }}
                        title={'Сверху (px)'}
                      />
                      <EditOneBoxEntity
                        value={boxMargin.viewValue.right}
                        onChange={value => {
                          boxMargin.setValue({
                            ...boxMargin.valueRef.current,
                            right: value,
                          })
                        }}
                        title={'Справа (%)'}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2}>
                      <EditOneBoxEntity
                        value={boxMargin.viewValue.bottom}
                        onChange={value => {
                          boxMargin.setValue({
                            ...boxMargin.valueRef.current,
                            bottom: value,
                          })
                        }}
                        title={'Снизу (px)'}
                      />
                      <EditOneBoxEntity
                        value={boxMargin.viewValue.left}
                        onChange={value => {
                          boxMargin.setValue({
                            ...boxMargin.valueRef.current,
                            left: value,
                          })
                        }}
                        title={'Слева (%)'}
                      />
                    </SimpleGrid>
                  </div>
                ) : undefined,
                <div>
                  <SimpleGrid mb={4} cols={2}>
                    <TitleOfSettings title={'Отступы внутри px'} />
                    {boxPadding.controls()}
                  </SimpleGrid>
                  <SimpleGrid cols={2}>
                    <EditOneBoxEntity
                      value={boxPadding.viewValue.top}
                      onChange={value => {
                        boxPadding.setValue({
                          ...boxPadding.valueRef.current,
                          top: value,
                          bottom: value,
                        })
                      }}
                      title={'По вертик.'}
                    />
                    <EditOneBoxEntity
                      value={boxPadding.viewValue.right}
                      onChange={value => {
                        boxPadding.setValue({
                          ...boxPadding.valueRef.current,
                          right: value,
                          left: value,
                        })
                      }}
                      title={'По гориз.'}
                    />
                  </SimpleGrid>
                </div>,
                <div>
                  <SimpleGrid spacing={8} cols={1}>
                    <Grid columns={10}>
                      <Grid.Col span={8}>
                        <div
                          css={{
                            fontSize: 12,
                            padding: '0 7px',
                          }}
                        >
                          <span
                            css={{
                              color: colors.blue,
                            }}
                          >
                            Положение слоя:{' '}
                          </span>
                          <span
                            css={{
                              textDecoration: 'underline',
                            }}
                            onClick={() =>
                              boxPostionType.setValue({
                                ...boxPostionType.valueRef.current,
                                positionType:
                                  boxPostionType.valueRef.current.positionType == 'Relative' ? 'Absolute' : 'Relative',
                              })
                            }
                          >
                            {boxPostionType.viewValue.positionType == 'Relative' ? 'Вложенный' : 'Свободное'}
                          </span>
                        </div>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Group position="right">{boxPostionType.controls()}</Group>
                      </Grid.Col>
                    </Grid>

                    <SimpleGrid cols={2}>
                      <EditOneBoxEntity
                        value={boxPostionType.viewValue.positionX}
                        onChange={value => {
                          boxPostionType.setValue({
                            ...boxPostionType.valueRef.current,
                            positionX: value,
                          })
                        }}
                        title={'X'}
                      />

                      <EditOneBoxEntity
                        value={boxPostionType.viewValue.positionY}
                        onChange={value => {
                          boxPostionType.setValue({
                            ...boxPostionType.valueRef.current,
                            positionY: value,
                          })
                        }}
                        title={'Y'}
                      />
                    </SimpleGrid>
                  </SimpleGrid>
                </div>,
                textComponent ? <div id="text-settings"></div> : undefined,
                <div css={{}}>
                  <SimpleGrid spacing={8} cols={1}>
                    <Group position="apart">
                      <TitleOfSettings title={'Выравнивание'} />
                      <Group>
                        {boxPosition.controls()}
                        {boxPositionIsOpen ? (
                          <IconSquareArrowUp
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            size={20}
                            onClick={() => setBoxPositionIsOpen(false)}
                          />
                        ) : (
                          <IconSquareArrowDown
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            size={20}
                            onClick={() => setBoxPositionIsOpen(true)}
                          />
                        )}
                      </Group>
                    </Group>
                    {boxPositionIsOpen ? (
                      <Group css={{ padding: '0px 7px' }} spacing={4}>
                        {positionSettingsItemsGroups.map((group, index) => {
                          return (
                            <SimpleGrid spacing={1} cols={1} key={index}>
                              {group.map((positionSettingsItems, index) => {
                                return (
                                  <Group key={index} spacing={1}>
                                    {positionSettingsItems.map(
                                      (
                                        {
                                          horizontalPosition: horizontalPositionLoop,
                                          verticalPosition: verticalPositionLoop,
                                          vector,
                                        },
                                        index
                                      ) => {
                                        return (
                                          <PositionSettingsItem
                                            key={index}
                                            actualVector={boxPosition.valueRef.current.vector}
                                            vector={vector}
                                            actualHorizontalPosition={boxPosition.valueRef.current.horizontalPosition}
                                            actualVerticalPosition={boxPosition.valueRef.current.verticalPosition}
                                            horizontalPosition={horizontalPositionLoop}
                                            verticalPosition={verticalPositionLoop}
                                            onUpdatePosition={({ horizontalPosition, verticalPosition }) => {
                                              boxPosition.setValue({
                                                vector: vector,
                                                horizontalPosition,
                                                verticalPosition,
                                              })
                                            }}
                                          />
                                        )
                                      }
                                    )}
                                  </Group>
                                )
                              })}
                            </SimpleGrid>
                          )
                        })}
                      </Group>
                    ) : null}
                  </SimpleGrid>
                </div>,
                <div>
                  <SimpleGrid cols={1}>
                    <SimpleGrid
                      css={{
                        alignItems: 'center',
                      }}
                      cols={2}
                    >
                      <TitleOfSettings title={'Фон'} />
                      <Group
                        css={{
                          alignItems: 'center',
                        }}
                        position="apart"
                      >
                        {boxBackground.controls()}

                        {boxBackground.viewValue ? (
                          <IconX
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={async () => {
                              if (boxBackground.valueRef.current?.id) {
                                await deleteBoxBackground.mutateAsync({
                                  boxBackgroundID: boxBackground.valueRef.current?.id,
                                })
                                boxBackground.setValue(null)
                              }
                            }}
                            size={20}
                          />
                        ) : (
                          <IconPlus
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={async () => {
                              const createdBackground = await createBoxBackground.mutateAsync({
                                boxID: box.id,
                                siteID: site.id,
                                screenType: boxBackground.getCurrentResponsiveMode(),
                              })
                              boxBackground.setDesktopValue(
                                createdBackground.responsiveBoxSettings.desktopBoxSettings.background
                              )
                              boxBackground.setTabletValue(createdBackground.responsiveBoxSettings.tabletBoxSettings.background)
                              boxBackground.setMobileValue(createdBackground.responsiveBoxSettings.mobileBoxSettings.background)
                            }}
                            size={20}
                          />
                        )}
                      </Group>
                    </SimpleGrid>

                    {boxBackground.viewValue ? (
                      <>
                        <Group spacing={2} position="apart">
                          <TitleOfSettings title={'Картинка'} />
                          <Group>
                            {boxBackground.viewValue.image ? (
                              <>
                                <IconHandMove
                                  css={{
                                    cursor: 'pointer',
                                  }}
                                  size={20}
                                  onClick={() => setBackgroundEditIsShow(backgroundEditIsShow => !backgroundEditIsShow)}
                                />{' '}
                                <IconRefresh
                                  css={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setBoxBackgroundOpened(true)}
                                  size={20}
                                />
                                <IconX
                                  css={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={async () => {
                                    if (boxBackground.valueRef.current) {
                                      await unpinImageToBoxBackground.mutateAsync({
                                        boxBackgroundID: boxBackground.valueRef.current.id,
                                      })
                                      boxBackground.setValue({
                                        ...boxBackground.valueRef.current,
                                        image: null,
                                      })
                                    }
                                  }}
                                  size={20}
                                />
                              </>
                            ) : (
                              <>
                                <IconPlus
                                  css={{
                                    cursor: 'pointer',
                                  }}
                                  color={colors.blue}
                                  onClick={() => setBoxBackgroundOpened(true)}
                                  size={20}
                                />
                              </>
                            )}
                          </Group>
                        </Group>
                        <Group spacing={2} position="apart">
                          <TitleOfSettings title={'Цвет'} />
                          <Group>
                            <ChooseSiteColors
                              onClearColor={async () => {
                                if (boxBackground.valueRef.current?.id) {
                                  await updateBoxBackground.mutate({
                                    boxBackgroundID: boxBackground.valueRef.current?.id,
                                    colorID: null,
                                  })
                                  boxBackground.setValue({
                                    ...boxBackground.valueRef.current,
                                    color: null,
                                  })
                                }
                              }}
                              currentColorID={boxBackground.viewValue?.color?.id}
                              onChooseColor={async ({ colorID }) => {
                                if (boxBackground.valueRef.current?.id) {
                                  const updatedBoxBackround = await updateBoxBackground.mutateAsync({
                                    boxBackgroundID: boxBackground.valueRef.current?.id,
                                    colorID: colorID,
                                  })
                                  boxBackground.setValue(updatedBoxBackround)
                                }
                              }}
                            >
                              {boxBackground.viewValue.color ? (
                                <ColorSwatch
                                  component="button"
                                  color={boxBackground.viewValue.color.value}
                                  sx={{ color: '#fff', cursor: 'pointer' }}
                                ></ColorSwatch>
                              ) : (
                                <IconPlus
                                  css={{
                                    cursor: 'pointer',
                                  }}
                                  color={colors.blue}
                                  size={20}
                                />
                              )}
                            </ChooseSiteColors>
                          </Group>
                        </Group>
                      </>
                    ) : (
                      <></>
                    )}
                  </SimpleGrid>
                </div>,
                <div>
                  <SimpleGrid cols={1}>
                    <Group spacing={2} position="apart">
                      <TitleOfSettings title={'Бордер'} />

                      {boxBorder.viewValue ? (
                        <IconX
                          css={{
                            cursor: 'pointer',
                          }}
                          color={colors.blue}
                          onClick={async () => {
                            if (boxBorder.viewValue)
                              await deleteBoxBorder.mutateAsync({
                                boxID: box.id,
                                screenType: boxBorder.getCurrentResponsiveMode(),
                              })
                            boxBorder.setValue(null)
                          }}
                          size={20}
                        />
                      ) : (
                        <ChooseSiteColors
                          onChooseColor={async ({ colorID }) => {
                            const createdBoxBorder = await createBoxBorder.mutateAsync({
                              siteID: site.id,
                              boxID: box.id,
                              colorID: colorID,
                              screenType: boxBorder.getCurrentResponsiveMode(),
                            })
                            boxBorder.setDesktopValue(createdBoxBorder.responsiveBoxSettings.desktopBoxSettings.border)
                            boxBorder.setTabletValue(createdBoxBorder.responsiveBoxSettings.tabletBoxSettings.border)
                            boxBorder.setMobileValue(createdBoxBorder.responsiveBoxSettings.mobileBoxSettings.border)
                          }}
                        >
                          <IconPlus
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            size={20}
                          />
                        </ChooseSiteColors>
                      )}
                    </Group>

                    {boxBorder.viewValue ? (
                      <>
                        <SimpleGrid cols={2}>
                          <EditOneBoxEntity
                            value={boxBorder.viewValue.left}
                            onChange={value => {
                              if (typeof value == 'number' && boxBorder.valueRef.current)
                                boxBorder.setValue({
                                  ...boxBorder.valueRef.current,
                                  left: value,
                                  right: value,
                                  top: value,
                                  bottom: value,
                                })
                            }}
                            title={'Размер'}
                          />
                          <Group position="right">
                            <ChooseSiteColors
                              currentColorID={boxBorder.viewValue?.color?.id}
                              onChooseColor={async ({ colorID }) => {
                                if (boxBorder.valueRef.current?.id) {
                                  const updatedBoxBorder = await updateBoxBorder.mutateAsync({
                                    boxBorderID: boxBorder.valueRef.current.id,
                                    colorID: colorID,
                                  })
                                  boxBorder.setValue(updatedBoxBorder)
                                }
                              }}
                            >
                              <ColorSwatch
                                component="button"
                                color={boxBorder.viewValue.color.value}
                                sx={{ color: '#fff', cursor: 'pointer' }}
                              ></ColorSwatch>
                            </ChooseSiteColors>
                          </Group>
                        </SimpleGrid>
                      </>
                    ) : (
                      <></>
                    )}
                  </SimpleGrid>
                </div>,
                <div css={{ width: '100%' }}>
                  <SimpleGrid cols={1}>
                    <Group spacing={2} position="apart">
                      <TitleOfSettings title={'Тень'} />
                      {boxShadow.viewValue ? (
                        <IconX
                          css={{
                            cursor: 'pointer',
                          }}
                          color={colors.blue}
                          onClick={async () => {
                            if (boxShadow.viewValue)
                              await unpinBoxShadow.mutateAsync({
                                boxID: box.id,
                                screenType: boxShadow.getCurrentResponsiveMode(),
                                boxShadowID: boxShadow.viewValue.id,
                              })
                            boxShadow.setValue(null)
                          }}
                          size={20}
                        />
                      ) : (
                        <Group css={{ alignItems: 'center' }}>
                          <IconGridDots
                            css={{
                              cursor: 'pointer',
                            }}
                            color={colors.blue}
                            onClick={() => setChooseBoxShadowOpened(true)}
                            // title="Выбрать одну из теней"
                            size={20}
                          />
                          <ChooseSiteColors
                            onChooseColor={async ({ colorID }) => {
                              const updatedBox = await createBoxShadow.mutateAsync({
                                siteID: site.id,
                                boxID: box.id,
                                colorID: colorID,
                                screenType: boxShadow.getCurrentResponsiveMode(),
                              })
                              boxShadow.setDesktopValue(updatedBox.responsiveBoxSettings.desktopBoxSettings.shadow)
                              boxShadow.setTabletValue(updatedBox.responsiveBoxSettings.tabletBoxSettings.shadow)
                              boxShadow.setMobileValue(updatedBox.responsiveBoxSettings.mobileBoxSettings.shadow)
                            }}
                          >
                            <IconPlus
                              css={{
                                cursor: 'pointer',
                              }}
                              color={colors.blue}
                              size={20}
                            />
                          </ChooseSiteColors>
                        </Group>
                      )}
                    </Group>

                    {boxShadow.viewValue ? (
                      <>
                        <SimpleGrid cols={2}>
                          <EditOneBoxEntity
                            value={boxShadow.viewValue.xShift}
                            onChange={value => {
                              if (typeof value == 'number' && boxShadow.valueRef.current)
                                boxShadow.setValue({
                                  ...boxShadow.valueRef.current,
                                  xShift: value,
                                })
                            }}
                            title={'X'}
                          />

                          <EditOneBoxEntity
                            value={boxShadow.viewValue.blur}
                            onChange={value => {
                              if (typeof value == 'number' && boxShadow.valueRef.current)
                                boxShadow.setValue({
                                  ...boxShadow.valueRef.current,
                                  blur: value,
                                })
                            }}
                            title={'Blur'}
                          />

                          <EditOneBoxEntity
                            value={boxShadow.viewValue.yShift}
                            onChange={value => {
                              if (typeof value == 'number' && boxShadow.valueRef.current)
                                boxShadow.setValue({
                                  ...boxShadow.valueRef.current,
                                  yShift: value,
                                })
                            }}
                            title={'Y'}
                          />
                          <EditOneBoxEntity
                            value={boxShadow.viewValue.spread}
                            onChange={value => {
                              if (typeof value == 'number' && boxShadow.valueRef.current)
                                boxShadow.setValue({
                                  ...boxShadow.valueRef.current,
                                  spread: value,
                                })
                            }}
                            title={'Spread'}
                          />
                        </SimpleGrid>
                      </>
                    ) : (
                      <></>
                    )}
                  </SimpleGrid>
                </div>,

                <Group position="apart">
                  <TitleOfSettings title={'Скрытность'} />
                  <Group css={{ alignItems: 'center' }} position="right">
                    {boxHidden.viewValue ? (
                      <IconEyeOff
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                        onClick={() => boxHidden.setValue(false)}
                        size={20}
                      />
                    ) : (
                      <IconEye
                        css={{
                          cursor: 'pointer',
                        }}
                        color={colors.blue}
                        onClick={() => boxHidden.setValue(true)}
                        size={20}
                      />
                    )}

                    {boxHidden.controls()}
                  </Group>
                </Group>,
              ]}
            />
            {createFormBlockOpened ? (
              <Modal
                centered
                transitionDuration={0}
                opened={createFormBlockOpened}
                onClose={() => setCreateFormBlockOpened(false)}
                title="Прикрепить форму к блоку"
              >
                <form>
                  <Radio.Group required orientation="vertical" label="Выбери форму по заголовку">
                    {site.formsV1.map(form => (
                      <Radio
                        onClick={() => {
                          createFormComponent.mutate({
                            boxID: box.id,
                            formID: form.id,
                          })
                          setCreateFormBlockOpened(false)
                        }}
                        key={form.id}
                        value={form.id}
                        label={form.title}
                      />
                    ))}
                  </Radio.Group>
                </form>
              </Modal>
            ) : null}

            {createQuizBlockOpened ? (
              <Modal
                centered
                transitionDuration={0}
                opened={createQuizBlockOpened}
                onClose={() => setCreateQuizBlockOpened(false)}
                title="Прикрепить квиз к блоку"
              >
                <form>
                  <Radio.Group required orientation="vertical" label="Выбери квиз по заголовку">
                    {site.quizzesV1.map(quiz => (
                      <Radio
                        onClick={() => {
                          createQuizComponent.mutate({
                            boxID: box.id,
                            quizID: quiz.id,
                          })
                          setCreateQuizBlockOpened(false)
                        }}
                        key={quiz.id}
                        value={quiz.id}
                        label={quiz.title}
                      />
                    ))}
                  </Radio.Group>
                </form>
              </Modal>
            ) : null}
          </div>
        </Portal>
      ) : null}

      <div
        ref={setNodeRefDrop}
        css={{
          width: '100%',
          display: 'flex',
        }}
      >
        <div
          ref={boxRef}
          css={{
            width: '100%',
            display: 'flex',
          }}
        >
          <div
            css={{
              willChange: 'opacity',
              position: 'absolute',
              left: '50%',
              top: 'calc(100% + 10px)',
              display: 'flex',
              alignContent: 'SpaceAround',
              justifyContent: 'SpaceAround',
              transform: `translate(-50%, 0)`,
              border: '1px solid rgb(0, 134, 255, 0.5)',
              transition: '0.3s',
              background: 'white',
              padding: 2,
              zIndex: 10,
              pointerEvents: 'none',
              fontSize: 10,
              color: 'rgb(0, 134, 255)',
            }}
            style={{
              opacity: boxWidth.isDrag ? 1 : 0,
            }}
          >
            {boxWidth.isDrag ? `${boxWidth.viewValue}${transformBoxWidth(boxWidthType.viewValue)}` : null}
          </div>
          <div
            css={{
              willChange: 'gap, alignItems, alignContent, justifyContent, flexDirection, pointerEvents, opacity',
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              minHeight: '100%',
              zIndex: 2,
            }}
            style={{
              // position: 'relative', // ломает свободные блоки, от паддинга расстояние учитывается - не правильно
              gap: boxGap.viewValue,
              alignItems: transformedVerticalPosition,
              alignContent: transformedVerticalPosition,
              justifyContent: transformedHorizontalPosition,
              flexDirection: boxPosition.value.vector == 'Bottom' ? 'column' : undefined, // | row-reverse | box | box-reverse;
              pointerEvents: backgroundEditIsShow ? 'none' : undefined,
              opacity: backgroundEditIsShow ? 0.5 : undefined,
            }}
            ref={boxInsideRef}
          >
            {boxes && boxes.length ? undefined : (
              <div
                css={{
                  width: '100%',
                }}
              >
                {textComponent ? (
                  <EditorTextComponent
                    active={boxIsActive}
                    canEdit={upLayerForCheckDoubleClickIsHide}
                    textComponent={textComponent}
                  />
                ) : imageComponent ? (
                  <>
                    <ImagePreview
                      cssStyle={{
                        borderRadius: boxBorderRadius.viewValue || 0,
                      }}
                      onDoubleClick={() => setImageComponentEditOpened(true)}
                      image={imageComponent}
                    />
                    {boxIsActive && imageComponentEditOpened ? (
                      <Portal>
                        <div
                          title="Редактировать размеры картинки"
                          css={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(255,255,255,0.85)',
                            zIndex: 100,
                          }}
                          onClick={event => {
                            event.stopPropagation()
                            setImageComponentEditOpened(false)
                          }}
                        >
                          <Group
                            css={{
                              position: 'absolute',
                              left: '50%',
                              bottom: 30,
                              transform: 'translateX(-50%)',
                              zIndex: 2,
                            }}
                          >
                            <Paper
                              withBorder
                              radius={1000}
                              shadow="xs"
                              css={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <IconX size={24} color={colors.blue} />
                            </Paper>
                            <Paper
                              onClick={() => {
                                setImagesOpened(true)
                              }}
                              withBorder
                              radius={1000}
                              shadow="xs"
                              css={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <IconRefresh size={24} color={colors.blue} />
                            </Paper>
                          </Group>
                          <div
                            css={{
                              width: '100%',
                              height: '100%',
                              position: 'absolute',
                              left: '50%',
                              top: '50%',
                              transform: 'translate(-50%, -50%)',
                              display: 'flex',
                              alignContent: 'center',
                              alignItems: 'center',
                              justifyContent: 'center',
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <div
                              css={{
                                // padding: 12,
                                maxWidth: '100%',
                                maxHeight: '100%',
                                padding: 12,
                              }}
                              onClick={event => {
                                event.stopPropagation()
                              }}
                            >
                              <EditImage
                                image={imageComponent}
                                onChangeShellSize={({ width, height }) => {
                                  if (imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: imageComponent.id,
                                      shellWidth: width,
                                      shellHeight: height,
                                    })
                                }}
                                onUpdatePosition={({ x, y }) => {
                                  if (imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: imageComponent.id,
                                      positionX: x,
                                      positionY: y,
                                    })
                                }}
                                onUpdateSize={({ width, height }) => {
                                  if (imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: imageComponent.id,
                                      width: width,
                                      height: height,
                                    })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Portal>
                    ) : null}
                  </>
                ) : box.formComponent ? (
                  <Form form={box.formComponent.form} />
                ) : box.quizComponent ? (
                  <Quiz {...box.quizComponent.quiz} />
                ) : box.videoComponent ? (
                  <VideoComponent videoComponent={box.videoComponent} />
                ) : box.faqComponent ? (
                  <>
                    {boxIsActive ? (
                      editFaqOpened ? (
                        <Modal transitionDuration={0} opened={editFaqOpened} onClose={() => setEditFaqOpened(false)}>
                          <EditOneFaqComponent {...box.faqComponent} />
                        </Modal>
                      ) : null
                    ) : null}

                    <div css={{ width: '100%' }} onClick={() => setEditFaqOpened(true)}>
                      <FaqComponent {...box.faqComponent} />
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            )}

            {upLayerForCheckDoubleClickIsHide ? undefined : (
              <div
                ref={positionAbsoluteBoxRef}
                onClick={() => {
                  setUpLayerForCheckDoubleClickIsHide(true)
                }}
                css={{
                  // background: 'rgba(0, 0, 0, 0.3)',
                  // zIndex: 0,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  // pointerEvents: 'none',
                }}
              >
                {boxPostionType.viewValue.positionType == 'Relative' ? (
                  parentBoxId && boxesPositionIsHover ? (
                    <div
                      {...listeners}
                      {...attributes}
                      css={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // background: 'purple',
                        top: 0,
                        left: 0,
                        cursor: 'grab',
                      }}
                    />
                  ) : (
                    <></>
                  )
                ) : boxesPositionIsHover ? (
                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const pixelBoxDifferentPositionYWithPrevPixel =
                        (boxPostionType.valueRef.current.positionY || 0) + Math.ceil(offsetPosition.y)
                      const pixelBoxDifferentPositionXWithPrevPixel =
                        (boxPostionType.valueRef.current.positionX || 0) + Math.ceil(offsetPosition.x)

                      boxPostionType.setTemporary({
                        ...boxPostionType.valueRef.current,
                        positionY: pixelBoxDifferentPositionYWithPrevPixel,
                        positionX: pixelBoxDifferentPositionXWithPrevPixel,
                      })

                      return {
                        resultSizes: {
                          y: pixelBoxDifferentPositionYWithPrevPixel,
                          x: pixelBoxDifferentPositionXWithPrevPixel,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number' && typeof resultPosition.x == 'number')
                        boxPostionType.setValue({
                          ...boxPostionType.valueRef.current,
                          positionX: resultPosition.x,
                          positionY: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // border: '1px solid #0086ff',
                      right: 0,
                      top: 0,
                      // transform: 'translate(50%, -50%) rotate(45deg)',
                      cursor: 'move',
                      // background: boxPostionType.isDrag ? '#0086ff' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxPostionType.setIsDrag(is)
                    }}
                  ></DragOffsetPosition>
                ) : null}
              </div>
            )}

            {boxes && boxes.length ? (
              box.isSlider ? (
                <div
                  css={{
                    display: 'flex',
                    width: '100%',
                  }}
                  ref={emblaRef}
                >
                  <div
                    css={{
                      display: 'flex',
                      width: '100%',
                      // height: 'auto',
                      alignItems: 'flex-start',
                      gap: boxGap.viewValue,
                    }}
                  >
                    {boxesNode}
                  </div>
                </div>
              ) : (
                <DndContext
                  sensors={sensors}
                  onDragEnd={event => {
                    const { active, over } = event

                    if (over) {
                      if (active.id !== over.id) {
                        // setBoxes(boxes => {
                        //   if (boxes) {
                        //     const oldIndex = boxes.findIndex(box => box.id == active.id)
                        //     const newIndex = boxes.findIndex(box => box.id == over.id)
                        //     if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
                        //       const updatedBoxes = arrayMove(boxes, oldIndex, newIndex)
                        //       updatedBoxes.map((box, index) => {
                        //         updateBoxSettings.mutate({
                        //           boxID: box.id,
                        //           order: index,
                        //           screenType: 'Desktop',
                        //         })
                        //       })
                        //       return updatedBoxes
                        //     }
                        //     return boxes
                        //   }
                        // })
                      }
                    }
                  }}
                >
                  {boxesNode}
                </DndContext>
              )
            ) : undefined}
          </div>

          {/* <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              zIndex: 2,
              left: -1,
              top: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              zIndex: 2,
              right: -1,
              top: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              height: 1,
              width: '100%',
              zIndex: 2,
              right: 0,
              top: -1,
            }}
          />
          <div
            css={{
              position: 'absolute',
              height: 1,
              width: '100%',
              zIndex: 2,
              right: 0,
              bottom: -1,
            }}
          /> */}

          {boxIsActive || boxesPositionIsHover ? (
            <div
              css={{
                willChange: 'opacity, pointerEvents',
                zIndex: 3,
              }}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              {boxMargin.viewValue.top ? (
                <div
                  css={{
                    willChange: 'height, background',
                    position: 'absolute',
                    width: '100%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    left: '50%',
                    bottom: '100%',
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                  style={{
                    height: `calc(${boxMargin.viewValue.top}px)`,
                    background: marginColor,
                  }}
                />
              ) : null}
              {boxMargin.viewValue.bottom ? (
                <div
                  css={{
                    position: 'absolute',
                    width: '100%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    left: '50%',
                    top: '100%',
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                  style={{
                    height: `calc(${boxMargin.viewValue.bottom}px)`,
                    background: marginColor,
                  }}
                />
              ) : null}
              {boxMargin.viewValue.right ? (
                <div
                  style={{
                    position: 'absolute',
                    // transform: 'translate(0, -50%)',
                    width: `calc(${(parentBoxWidth / 100) * boxMargin.viewValue.right}px)`,
                    height: `${(boxMargin.viewValue.bottom || 0) + (boxMargin.viewValue.top || 0) + (height || 0)}px`,
                    zIndex: 1,
                    left: 'calc(100%)',
                    bottom: `-${boxMargin.viewValue.bottom || 0}px`,
                    background: marginColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxMargin.viewValue.left ? (
                <div
                  style={{
                    position: 'absolute',
                    // transform: 'translate(0, -50%)',
                    width: `calc(${(parentBoxWidth / 100) * boxMargin.viewValue.left}px)`,
                    height: `${(boxMargin.viewValue.bottom || 0) + (boxMargin.viewValue.top || 0) + (height || 0)}px`,
                    zIndex: 1,
                    right: 'calc(100%)',
                    bottom: `-${boxMargin.viewValue.bottom || 0}px`,
                    background: marginColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}

              {boxPadding.viewValue.right ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `${boxPadding.viewValue.right - 1}px`,
                    height: `calc(100% - ${
                      (typeof boxPadding.viewValue.top == 'number' ? boxPadding.viewValue.top : 0) +
                      (typeof boxPadding.viewValue.bottom == 'number' ? boxPadding.viewValue.bottom : 0)
                    }px)`,
                    zIndex: 1,
                    right: 1,
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxPadding.viewValue.left ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `${boxPadding.viewValue.left - 1}px`,
                    height: `calc(100% - ${
                      (typeof boxPadding.viewValue.top == 'number' ? boxPadding.viewValue.top : 0) +
                      (typeof boxPadding.viewValue.bottom == 'number' ? boxPadding.viewValue.bottom : 0)
                    }px)`,
                    zIndex: 1,
                    left: 1,
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxPadding.viewValue.top ? (
                <div
                  style={{
                    position: 'absolute',
                    width: 'calc(100% - 2px)',
                    height: `${boxPadding.viewValue.top - 1}px`,
                    zIndex: 1,
                    left: '50%',
                    top: 1,
                    transform: 'translate(-50% , 0)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {boxPadding.viewValue.bottom ? (
                <div
                  style={{
                    position: 'absolute',
                    width: 'calc(100% - 2px)',
                    height: `${boxPadding.viewValue.bottom - 1}px`,
                    zIndex: 1,
                    left: '50%',
                    bottom: 1,
                    transform: 'translate(-50% , 0)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
            </div>
          ) : null}

          {boxBackground.viewValue ? (
            <div
              style={{
                overflow: backgroundEditIsShow ? undefined : 'hidden', //
                borderRadius: boxBorderRadius.viewValue || 0,
              }}
              css={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
              }}
            >
              {boxBackground.viewValue.image ? (
                <div
                  css={{
                    position: 'absolute',
                    left: '50%',
                    cursor: 'move',
                    // zIndex: 2,
                    // opacity: 0.5,
                  }}
                  style={{
                    top:
                      boxBackground.viewValue.verticalPosition == 'Top'
                        ? 0
                        : boxBackground.viewValue.verticalPosition == 'Center'
                        ? '50%'
                        : '100%',
                    width: boxBackground.viewValue.width,
                    height: boxBackground.viewValue.height,
                    transform: `translate(calc(-50% + ${boxBackground.viewValue.positionX}px), calc(-50% + ${boxBackground.viewValue.positionY}px))`,
                  }}
                >
                  <>
                    <DragOffsetPosition
                      onChangePosition={({ offsetPosition }) => {
                        const pixelBoxDifferentBackgroundPositionPrevPixelX =
                          (boxBackground.valueRef.current?.positionX || 0) + offsetPosition.x

                        const pixelBoxDifferentBackgroundPositionPrevPixelY =
                          (boxBackground.valueRef.current?.positionY || 0) + offsetPosition.y

                        if (boxBackground.valueRef.current)
                          boxBackground.setTemporary({
                            ...boxBackground.valueRef.current,
                            positionX: pixelBoxDifferentBackgroundPositionPrevPixelX,
                            positionY: pixelBoxDifferentBackgroundPositionPrevPixelY,
                          })
                        return {
                          resultSizes: {
                            x: pixelBoxDifferentBackgroundPositionPrevPixelX,
                            y: pixelBoxDifferentBackgroundPositionPrevPixelY,
                          },
                        }
                      }}
                      onEndChangePosition={({ resultPosition }) => {
                        if (boxBackground.valueRef.current) {
                          boxBackground.setValue({
                            ...boxBackground.valueRef.current,
                            positionX: resultPosition.x || boxBackground.valueRef.current.positionX,
                            positionY: resultPosition.y || boxBackground.valueRef.current.positionY,
                          })
                        }
                      }}
                      onDrag={({ is }) => {
                        boxBackground.setIsDrag(is)
                      }}
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {/* <img
                        src={getPathToFile({
                          fileName: boxBackground.viewValue.image.name,
                        })}
                        css={{
                          position: 'fixed',
                          left: -99999,
                          pointerEvents: 'none',
                          opacity: 0,
                        }}
                        onLoad={() => {
                          if (boxBackgroundImageRef.current) {
                            const width = boxBackgroundImageRef.current.clientWidth
                            const height = boxBackgroundImageRef.current.clientHeight

                            if (boxBackground.valueRef.current) {
                              if (boxBackground.valueRef.current.height == 0 && boxBackground.valueRef.current.width == 0) {
                                boxBackground.setValue({
                                  ...boxBackground.valueRef.current,
                                  width,
                                  height,
                                })
                              }
                            }
                          }
                        }}
                        ref={boxBackgroundImageRef}
                      />
                      <img
                        css={{
                          width: '100%',
                          height: '100%',
                        }}
                        src={getPathToFile({
                          fileName: boxBackground.viewValue.image.name,
                        })}
                      /> */}
                    </DragOffsetPosition>

                    <DragOffsetPosition
                      onChangePosition={({ offsetPosition }) => {
                        let newBackgrounWidth = 0
                        let newBackgrounHeight = 0
                        if (boxBackground.valueRef.current) {
                          const actualImageWidth = boxBackground.valueRef.current.width
                          const actualImageHeight = boxBackground.valueRef.current.height
                          newBackgrounWidth = actualImageWidth + offsetPosition.x * 2

                          const widthPercentDifference = (newBackgrounWidth / actualImageWidth) * 100
                          newBackgrounHeight = (actualImageHeight * widthPercentDifference) / 100

                          boxBackground.setTemporary({
                            ...boxBackground.valueRef.current,
                            width: newBackgrounWidth,
                            height: newBackgrounHeight,
                          })
                        }
                        return {
                          resultSizes: {
                            x: newBackgrounWidth,
                            y: newBackgrounHeight,
                          },
                        }
                      }}
                      onEndChangePosition={({ resultPosition }) => {
                        if (boxBackground.valueRef.current) {
                          boxBackground.setValue({
                            ...boxBackground.valueRef.current,
                            width: resultPosition.x || 0,
                            height: resultPosition.y || 0,
                          })
                        }
                      }}
                      style={{
                        willChange: 'width, height',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #0086ff',
                        bottom: 0,
                        right: 0,
                        transform: 'translate(50%, 50%)',
                        cursor: 'nwse-resize',
                        zIndex: 2,
                        // background: boxWidthIsDrag ? '#0086ff' : 'white',
                        width: widthAndHeight,
                        height: widthAndHeight,
                      }}
                      onDrag={({ is }) => {
                        boxBackground.setIsDrag(is)
                      }}
                    />
                  </>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* <div
            css={{
              willChange: 'opacity, border, borderRadius',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 2,
              left: 0,
              top: 0,
              pointerEvents: 'none',
            }}
            style={{
              opacity: lineIsShow ? 1 : 0,
              border: `1px dashed ${
                isOver ? 'purple' : boxesPositionIsHover || settingsIsShow ? '#0086ff' : 'rgba(49, 149, 217, 0.2)'
              }`,
              // borderRadius: boxBorderRadius.viewValue || 0,
            }}
          /> */}

          <div
            css={{
              willChange: 'opacity, border, borderRadius',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 2,
              left: 0,
              top: 0,
              pointerEvents: 'none',
            }}
            style={{
              opacity: boxesPositionIsHover || settingsIsShow ? 1 : lineIsShow ? 1 : 0,
              border: `1px ${boxesPositionIsHover || settingsIsShow ? 'solid' : 'dashed'} ${
                isOver ? 'purple' : boxesPositionIsHover || settingsIsShow ? '#0086ff' : 'rgba(49, 149, 217, 0.2)'
              }`,
              borderRadius: boxBorderRadius.viewValue || 0,
            }}
          />

          {boxIsActive ? (
            <div
              css={{
                willChange: 'opacity, pointerEvents',
                zIndex: 4,
              }}
              style={{}}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              {boxBackgroundOpened ? (
                <Drawer
                  transitionDuration={0}
                  overlayOpacity={0.55}
                  overlayBlur={3}
                  padding={'md'}
                  size="calc(100vh - 50px)"
                  lockScroll={true}
                  position="bottom"
                  opened={boxBackgroundOpened}
                  onClose={() => setBoxBackgroundOpened(false)}
                  title="Выбрать картинку"
                  css={{
                    overflowY: 'scroll',
                  }}
                >
                  {boxBackgroundOpened ? (
                    <Images
                      imageChoosen={async ({ imageID, imageName }) => {
                        if (boxBackground.valueRef.current) {
                          const updatedBoxBackround = await pinImageToBoxBackground.mutateAsync({
                            boxBackgroundID: boxBackground.valueRef.current.id,
                            imageID: imageID,
                          })
                          boxBackground.setValue(updatedBoxBackround)
                          setBoxBackgroundOpened(false)
                        }
                      }}
                      onClose={() => {}}
                    />
                  ) : null}
                </Drawer>
              ) : null}

              {imagesOpened ? (
                <Drawer
                  transitionDuration={0}
                  overlayOpacity={0.55}
                  overlayBlur={3}
                  padding={'md'}
                  size="calc(100vh - 50px)"
                  lockScroll={true}
                  position="bottom"
                  opened={imagesOpened}
                  onClose={() => setImagesOpened(false)}
                  title="Выбрать картинку"
                  css={{
                    overflowY: 'scroll',
                  }}
                >
                  {imagesOpened ? (
                    <Images
                      imageChoosen={async ({ imageID, imageName }) => {
                        if (imageComponentRef.current) {
                          setImageComponent(
                            await changeImageComponentImage.mutateAsync({
                              imageComponentID: imageComponentRef.current.id,
                              imageID: imageID,
                            })
                          )
                        } else {
                          setImageComponent(
                            await createImageComponent.mutateAsync({
                              boxID: box.id,
                              imageID: imageID,
                            })
                          )
                        }
                        setImagesOpened(false)
                        // setImageComponentEditOpened(true)
                      }}
                      onClose={() => {}}
                    />
                  ) : null}
                </Drawer>
              ) : null}
              {chooseBoxShadowOpened ? (
                <Modal transitionDuration={0} opened={chooseBoxShadowOpened} onClose={() => setChooseBoxShadowOpened(false)}>
                  {site.boxShadows.map(boxShadowLoop => (
                    <div
                      key={boxShadowLoop.id}
                      onClick={async () => {
                        const updatedBox = await pinBoxShadow.mutateAsync({
                          boxID: box.id,
                          screenType: boxShadow.getCurrentResponsiveMode(),
                          boxShadowID: boxShadowLoop.id,
                        })
                        boxShadow.setDesktopValue(updatedBox.responsiveBoxSettings.desktopBoxSettings.shadow)
                        boxShadow.setTabletValue(updatedBox.responsiveBoxSettings.tabletBoxSettings.shadow)
                        boxShadow.setMobileValue(updatedBox.responsiveBoxSettings.mobileBoxSettings.shadow)
                        setChooseBoxShadowOpened(false)
                      }}
                      css={{
                        willChange: 'boxShadow',
                        width: '100%',
                        height: 30,
                        borderRadius: 2,
                        marginBottom: 40,
                        cursor: 'pointer',
                      }}
                      style={{
                        boxShadow: `${boxShadowLoop.xShift}px ${boxShadowLoop.yShift}px ${boxShadowLoop.blur}px ${boxShadowLoop.spread}px ${boxShadowLoop.color.value}`,
                      }}
                    ></div>
                  ))}
                </Modal>
              ) : null}

              {chooseURLVideoOpened ? (
                <Modal transitionDuration={0} opened={chooseURLVideoOpened} onClose={() => setChooseURLVideoOpened(false)}>
                  <form
                    onSubmit={formCreateUrlVideo.onSubmit(async values => {
                      createVideoComponent.mutate({
                        boxID: box.id,
                        videoURL: values.url,
                      })
                    })}
                  >
                    <TextInput mb="xs" required placeholder="url" {...formCreateUrlVideo.getInputProps('url')} />

                    <Group position="right">
                      <Button loading={createVideoComponent.isLoading} type="submit">
                        Создать
                      </Button>
                    </Group>
                  </form>
                </Modal>
              ) : null}

              {/* <div
              onClick={() => {
                onDeleteBox({ id: box.id })
              }}
              css={{
                position: 'absolute',
                width: widthAndHeight,
                height: widthAndHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#ff9797',
                top: 0,
                right: 0,
                transform: 'translate(50%, -50%)',
                cursor: 'not-allowed',
                zIndex: 2,
              }}
            /> */}

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  let boxDifferentWidth = 0

                  if (boxWidthType.valueRef.current == 'Percent') {
                    const percentBoxDifferentWidth = (offsetPosition.x / parentBoxWidthRef.current) * 100
                    const percentBoxDifferentWidthWithPrevPercent = (boxWidth.valueRef.current || 0) + percentBoxDifferentWidth
                    const roughPercentBoxDifferentWidth =
                      percentBoxDifferentWidthWithPrevPercent - (percentBoxDifferentWidthWithPrevPercent % 1)
                    boxDifferentWidth =
                      roughPercentBoxDifferentWidth > 100
                        ? 100
                        : roughPercentBoxDifferentWidth < 5
                        ? 5
                        : roughPercentBoxDifferentWidth
                  }
                  if (boxWidthType.valueRef.current == 'Pixel') {
                    const pixelBoxDifferentWidth = (boxWidth.valueRef.current || 0) + offsetPosition.x
                    const roughPixelBoxDifferentWidth = pixelBoxDifferentWidth - (pixelBoxDifferentWidth % 1)
                    boxDifferentWidth = roughPixelBoxDifferentWidth
                  }

                  boxWidth.setTemporary(boxDifferentWidth)

                  return {
                    resultSizes: {
                      x: boxDifferentWidth,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.x == 'number') boxWidth.setValue(resultPosition.x)
                }}
                style={{
                  width: widthAndHeight,
                  height: widthAndHeight,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #0086ff',
                  bottom: '50%',
                  right: 0,
                  transform: 'translate(50%, 50%)',
                  cursor: 'ew-resize',
                  zIndex: 2,
                  background: boxWidth.isDrag ? '#0086ff' : 'white',
                }}
                onDrag={({ is }) => {
                  boxWidth.setIsDrag(is)
                }}
                onClick={() => {
                  boxWidth.setValue(null)
                }}
              >
                {boxWidth.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHorizontalPaddingPrevPixel =
                    (boxPadding.valueRef.current.right || 0) - offsetPosition.x
                  const roughPixelBoxDifferentHorizontalPadding =
                    pixelBoxDifferentHorizontalPaddingPrevPixel - (pixelBoxDifferentHorizontalPaddingPrevPixel % 5)

                  boxPadding.setTemporary({
                    ...boxPadding.valueRef.current,
                    left: roughPixelBoxDifferentHorizontalPadding,
                    right: roughPixelBoxDifferentHorizontalPadding,
                  })

                  return {
                    resultSizes: {
                      x: roughPixelBoxDifferentHorizontalPadding,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.x == 'number')
                    boxPadding.setValue({
                      ...boxPadding.valueRef.current,
                      left: resultPosition.x,
                      right: resultPosition.x,
                    })
                }}
                style={{
                  willChange: 'background, border',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: 'calc(50% + 10px)',
                  right: 0,
                  transform: 'translate(50%, -50%)',
                  cursor: 'w-resize',
                  zIndex: 2,
                  width: widthAndHeight,
                  height: widthAndHeight,
                  background: boxPadding.isDrag ? paddingColor : 'white',
                  border: `1px solid ${paddingColor}`,
                }}
                onDrag={({ is }) => {
                  boxPadding.setIsDrag(is)
                }}
                onClick={() => {
                  boxPadding.setValue({
                    ...boxPadding.valueRef.current,
                    left: 0,
                    right: 0,
                  })
                }}
              >
                {boxPadding.value.right ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: paddingColor,
                    }}
                  />
                ) : null}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHorizontalPaddingPrevPixel = (boxPadding.valueRef.current.top || 0) - offsetPosition.y
                  const roughPixelBoxDifferentHorizontalPadding =
                    pixelBoxDifferentHorizontalPaddingPrevPixel - (pixelBoxDifferentHorizontalPaddingPrevPixel % 5)

                  boxPadding.setTemporary({
                    ...boxPadding.valueRef.current,
                    top: roughPixelBoxDifferentHorizontalPadding,
                    bottom: roughPixelBoxDifferentHorizontalPadding,
                  })

                  return {
                    resultSizes: {
                      y: roughPixelBoxDifferentHorizontalPadding,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number')
                    boxPadding.setValue({
                      ...boxPadding.valueRef.current,
                      top: resultPosition.y,
                      bottom: resultPosition.y,
                    })
                }}
                style={{
                  willChange: 'background, border',
                  width: widthAndHeight,
                  height: widthAndHeight,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: 'calc(50% + 10px)',
                  bottom: 0,
                  transform: 'translate(50%, 50%)',
                  cursor: 'n-resize',
                  zIndex: 2,
                  background: boxPadding.isDrag ? paddingColor : 'white',
                  border: `1px solid ${paddingColor}`,
                }}
                onDrag={({ is }) => {
                  boxPadding.setIsDrag(is)
                }}
                onClick={() => {
                  boxPadding.setValue({
                    ...boxPadding.valueRef.current,
                    top: 0,
                    bottom: 0,
                  })
                }}
              >
                {boxPadding.value.bottom ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: paddingColor,
                    }}
                  />
                ) : null}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHeightWithPrevPixel = (boxBorderRadius.valueRef.current || 0) + offsetPosition.x
                  const roughPixelBoxDifferentHeight =
                    pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 1)

                  const resultBoxRadius = pixelBoxDifferentHeightWithPrevPixel >= 0 ? pixelBoxDifferentHeightWithPrevPixel : 0

                  boxBorderRadius.setTemporary(resultBoxRadius)

                  return {
                    resultSizes: {
                      y: resultBoxRadius,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number') boxBorderRadius.setValue(resultPosition.y)
                }}
                style={{
                  willChange: 'background, left, bottom',
                  width: widthAndHeight,
                  height: widthAndHeight,
                  position: 'absolute',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #0086ff',
                  transform: 'translate(-50%, 50%)',
                  cursor: 'nesw-resize',
                  zIndex: 2,
                  background: boxBorderRadius.isDrag ? '#0086ff' : 'white',
                  left: boxBorderRadius.viewValue || 0,
                  bottom: boxBorderRadius.viewValue || 0,
                }}
                onDrag={({ is }) => {
                  boxBorderRadius.setIsDrag(is)
                }}
                onClick={() => {
                  boxBorderRadius.setValue(null)
                }}
              >
                {boxBorderRadius.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null}
              </DragOffsetPosition>

              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHeightWithPrevPixel = (boxHeight.valueRef.current || 0) + offsetPosition.y
                  const roughPixelBoxDifferentHeight =
                    pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 10)
                  boxHeight.setTemporary(roughPixelBoxDifferentHeight)

                  return {
                    resultSizes: {
                      y: roughPixelBoxDifferentHeight,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number') boxHeight.setValue(resultPosition.y)
                }}
                style={{
                  willChange: 'background',
                  width: widthAndHeight,
                  height: widthAndHeight,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #0086ff',
                  left: '50%',
                  bottom: 0,
                  transform: 'translate(-50%, 50%)',
                  cursor: 'ns-resize',
                  zIndex: 2,
                  background: boxHeight.isDrag ? '#0086ff' : 'white',
                }}
                onDrag={({ is }) => {
                  boxHeight.setIsDrag(is)
                }}
                onClick={() => {
                  boxHeight.setValue(null)
                }}
              >
                {boxHeight.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null}
              </DragOffsetPosition>

              {boxPostionType.viewValue.positionType == 'Relative' ? (
                <>
                  {/* <div
                    {...listeners}
                    {...attributes}
                    css={{
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'purple',
                      top: 0,
                      left: 0,
                      transform: 'translate(-50%, -50%)',
                      cursor: 'grab',
                      zIndex: 2,
                    }}
                  /> */}

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginRight = (offsetPosition.x / parentBoxWidthRef.current) * 100
                      const boxDifferentMarginRightWithPrev = (boxMargin.valueRef.current.right || 0) + boxDifferentMarginRight
                      const roughBoxDifferentMarginRightWithPrev =
                        boxDifferentMarginRightWithPrev - (boxDifferentMarginRightWithPrev % 5)
                      const roughBoxDifferentMarginRightLimit =
                        roughBoxDifferentMarginRightWithPrev < 0 ? 0 : roughBoxDifferentMarginRightWithPrev
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        right: roughBoxDifferentMarginRightLimit,
                      })

                      return {
                        resultSizes: {
                          x: roughBoxDifferentMarginRightLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          right: resultPosition.x,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      bottom: 'calc(50% + 10px)',
                      right: 0,
                      transform: 'translate(50%, 50%)',
                      cursor: 'e-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        right: 0,
                      })
                    }}
                  >
                    {boxMargin.value.right ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginLeft = (offsetPosition.x / parentBoxWidthRef.current) * 100
                      const boxDifferentMarginLeftWithPrev = (boxMargin.valueRef.current.left || 0) - boxDifferentMarginLeft
                      const roughBoxDifferentMarginLeftWithPrev =
                        boxDifferentMarginLeftWithPrev - (boxDifferentMarginLeftWithPrev % 5)
                      const roughBoxDifferentMarginLeftLimit =
                        roughBoxDifferentMarginLeftWithPrev < 0 ? 0 : roughBoxDifferentMarginLeftWithPrev
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        left: roughBoxDifferentMarginLeftLimit,
                      })

                      return {
                        resultSizes: {
                          x: roughBoxDifferentMarginLeftLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          left: resultPosition.x,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      bottom: 'calc(50% + 0px)',
                      left: 0,
                      transform: 'translate(-50%, 50%)',
                      cursor: 'w-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        left: 0,
                      })
                    }}
                  >
                    {boxMargin.value.left ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginTopWithPrev = (boxMargin.valueRef.current.top || 0) - offsetPosition.y
                      const roughBoxDifferentMarginTop = boxDifferentMarginTopWithPrev - (boxDifferentMarginTopWithPrev % 5)
                      const roughBoxDifferentMarginRightLimit = roughBoxDifferentMarginTop < 0 ? 0 : roughBoxDifferentMarginTop
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        top: roughBoxDifferentMarginRightLimit,
                      })

                      return {
                        resultSizes: {
                          y: roughBoxDifferentMarginRightLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          top: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      left: 'calc(50% + 0px)',
                      top: 0,
                      transform: 'translate(-50%, -50%)',
                      cursor: 'n-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        top: 0,
                      })
                    }}
                  >
                    {boxMargin.value.top ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginBottomWithPrev = (boxMargin.valueRef.current.bottom || 0) + offsetPosition.y
                      const roughBoxDifferentMarginBottom =
                        boxDifferentMarginBottomWithPrev - (boxDifferentMarginBottomWithPrev % 5)
                      const roughBoxDifferentMarginBottomLimit =
                        roughBoxDifferentMarginBottom < 0 ? 0 : roughBoxDifferentMarginBottom

                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        bottom: roughBoxDifferentMarginBottomLimit,
                      })

                      return {
                        resultSizes: {
                          y: roughBoxDifferentMarginBottomLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          bottom: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      border: `1px solid #ffb800`,
                      left: 'calc(50% + 10px)',
                      bottom: 0,
                      transform: 'translate(-50%, 50%)',
                      cursor: 's-resize',
                      zIndex: 2,
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        bottom: 0,
                      })
                    }}
                  >
                    {boxMargin.value.bottom ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>
                </>
              ) : undefined}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const LineBoxSettings = ({ onAdd, title, children }: { onAdd: () => void; title: string; children?: ReactNode }) => {
  return (
    <div>
      <Group mb={children ? 'xs' : undefined} position="apart">
        <Text>{title}</Text>
        <ActionIcon onClick={() => onAdd()}>
          <IconPlus color={colors.blue} />
        </ActionIcon>
      </Group>
      {children}
    </div>
  )
}

const ChooseSiteColors = ({
  onChooseColor,
  onClearColor,
  currentColorID,
  children,
}: {
  onChooseColor: ({}: { colorID: string }) => void
  onClearColor?: () => void
  currentColorID?: string
  children: ReactNode
}) => {
  const site = useContext(SiteContext)

  const [open, setOpen] = useState(false)

  const offset = 10

  return (
    <div
      css={{
        position: 'relative',
      }}
      onMouseEnter={() => {
        setOpen(true)
      }}
      onMouseLeave={() => setOpen(false)}
    >
      <div
        css={{
          '& > svg': {
            display: 'block',
          },
        }}
      >
        {' '}
        {children}
      </div>
      {open ? (
        <Paper
          css={{
            position: 'absolute',
            right: `calc(100% + ${offset}px)`,
            bottom: 0,
            width: 200,
            '&::after': {
              content: `""`,
              width: offset,
              height: '100%',
              dispaly: 'block',
              position: 'absolute',
              left: '100%',
              bottom: 0,
            },
          }}
          shadow="xs"
          p={12}
        >
          <Group spacing={4}>
            {site.colors.map(color => (
              <ColorSwatch
                key={color.id}
                component="button"
                color={color.value}
                onClick={() => {
                  onChooseColor({ colorID: color.id })
                }}
                sx={{ color: '#fff', cursor: 'pointer' }}
              >
                {currentColorID == color.id && <CheckIcon width={10} />}
              </ColorSwatch>
            ))}
            {onClearColor ? (
              <ColorSwatch
                title="Очистить цвет"
                component="button"
                color={'#fff'}
                onClick={() => {
                  onClearColor()
                }}
                sx={{ color: 'rgb(0, 134, 255)', cursor: 'pointer' }}
              >
                <IconWashDrycleanOff width={'60%'} />
              </ColorSwatch>
            ) : null}
          </Group>
        </Paper>
      ) : null}
    </div>
  )
}
