import { Button, Container, Group, HoverCard, SimpleGrid, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { SiteContext, SiteContextProvider } from 'contexts/SiteContextProvider'
import { useContext, useState } from 'react'
import { trpc } from 'utils/trpc'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

export function SiteEvents() {
  const site = useContext(SiteContext)

  const createformSendedEvent = trpc.useMutation('user.createformSendedEvent')
  const createPopupOpenedEvent = trpc.useMutation('user.createPopupOpenedEvent')

  const [createOnePageOpenedEventOpened, setCreateOnePageOpenedEventOpened] = useState(false)

  const form = useForm({
    initialValues: {
      seconds: 0,
    },
  })

  return (
    <Container py="md">
      <SimpleGrid cols={1}>
        {/* <div>
          <Group mb="md">
            <Title order={3}>События при открытии страницы</Title>
            <Button compact onClick={() => setCreateOnePageOpenedEventOpened(true)}>
              Создать событие
            </Button>
          </Group>
          <Drawer
            padding={'md'}
            opened={createOnePageOpenedEventOpened}
            onClose={() => setCreateOnePageOpenedEventOpened(false)}
          >
            <SimpleGrid cols={1}>
              {site.pagesV1.map(page => (
                <Button
                  key={page.id}
                  loading={createOnePageOpenedEventLoading}
                  fullWidth
                  onClick={() =>
                    createOnePageOpenedEvent({
                      variables: {
                        data: {
                          siteV1: {
                            connect: {
                              id: site.id,
                            },
                          },
                          page: {
                            connect: {
                              id: page.id,
                            },
                          },
                        },
                      },
                    })
                  }
                >
                  /{page.pathname} - {page.title}
                </Button>
              ))}
            </SimpleGrid>
          </Drawer>
          <Group>
            {site.pageOpenedEvents.map(pageOpendedEvent => (
              <Button
                variant="light"
                radius="xl"
                key={pageOpendedEvent.id}
                onClick={() => deleteOnePageOpenedEvent({ variables: { where: { id: pageOpendedEvent.id } } })}
                loading={deleteOnePageOpenedEventLoading}
                compact
                rightIcon={<IconX size={14} />}
                loaderPosition="right"
              >
                При открытии страницы: /{pageOpendedEvent.page.pathname} - {pageOpendedEvent.page.title}
              </Button>
            ))}
          </Group>
        </div>
        <div>
          <Group mb="md">
            <Title order={3}>События по времени на сайте в секундах</Title>
            <form
              onSubmit={form.onSubmit(async values => {
                createOneBySecondsLaunchEvent({
                  variables: {
                    data: {
                      seconds: values.seconds,
                      siteV1: {
                        connect: {
                          id: site.id,
                        },
                      },
                    },
                  },
                })
              })}
            >
              <Group>
                <NumberInput size="xs" required placeholder="" {...form.getInputProps('seconds')} />
                <Button size="xs" loading={createOneBySecondsLaunchEventLoading} type="submit">
                  Создать событие
                </Button>
              </Group>
            </form>
            <Group>
              {site.bySecondsLaunchEvent.map(bySecondsLaunchEvent => (
                <Button
                  variant="light"
                  radius="xl"
                  key={bySecondsLaunchEvent.id}
                  onClick={() => deleteOneBySecondsLaunchEvent({ variables: { where: { id: bySecondsLaunchEvent.id } } })}
                  loading={deleteOneBySecondsLaunchEventLoading}
                  compact
                  rightIcon={<IconX size={14} />}
                  loaderPosition="right"
                >
                  Запуск после: {bySecondsLaunchEvent.seconds} секунд
                </Button>
              ))}
            </Group>
          </Group>
        </div> */}
        <div>
          <Group mb="md">
            <Title order={3}>События при отправке определенной формы</Title>
            <HoverCard width={280} shadow="md">
              <HoverCard.Target>
                <Button compact>Создать событие</Button>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SimpleGrid cols={1}>
                  {site.formsV1.map(form => (
                    <Button
                      key={form.id}
                      compact
                      fullWidth
                      loading={createformSendedEvent.isLoading}
                      onClick={() =>
                        createformSendedEvent.mutate({
                          formID: form.id,
                          siteID: site.id,
                        })
                      }
                    >
                      {form.title}
                    </Button>
                  ))}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Group>
            {site.formSendedEvent.map(formSendedEvent => (
              <Button radius="xl" key={formSendedEvent.id} compact loaderPosition="right">
                Запуск после отправки формы: {formSendedEvent.formSended.title}
              </Button>
            ))}
          </Group>
        </div>
        <div>
          <Group mb="md">
            <Title order={3}>События при открытии определенного попапа</Title>
            <HoverCard width={280} shadow="md">
              <HoverCard.Target>
                <Button compact>Создать событие</Button>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SimpleGrid cols={1}>
                  {site.popupsV1.map(popup => (
                    <Button
                      key={popup.id}
                      compact
                      fullWidth
                      loading={createPopupOpenedEvent.isLoading}
                      onClick={() =>
                        createPopupOpenedEvent.mutate({
                          popupID: popup.id,
                          siteID: site.id,
                        })
                      }
                    >
                      {popup.form ? `Попап с формой ${popup.form.title}` : ''}
                      {popup.quiz ? `Попап с квизом ${popup.quiz.title}` : ''}
                    </Button>
                  ))}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Group>
            {site.popupOpenedEvent.map(popupOpenedEvent => (
              <Button radius="xl" key={popupOpenedEvent.id} compact loaderPosition="right">
                {popupOpenedEvent.popupOpened.form
                  ? `Запуск после открытии попапа с формой ${popupOpenedEvent.popupOpened.form.title}`
                  : ''}
                {popupOpenedEvent.popupOpened.quiz
                  ? `Запуск после открытии попапа с квизом ${popupOpenedEvent.popupOpened.quiz.title}`
                  : ''}
              </Button>
            ))}
          </Group>
        </div>
      </SimpleGrid>
    </Container>
  )
}
