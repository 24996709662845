import { Interpolation, Theme } from '@emotion/react'
import { AspectRatio } from '@mantine/core'
import { useEffect, useState } from 'react'
import { VideoComponentSelect } from 'server/selects'
import {
  getVideoIDFromYoutubeLink,
  getVideoYoutubePreviewByVideoID,
} from 'syncComponents/VideoComponentSync/VideoComponentSync'

export function VideoComponent({
  videoComponent,
  cssStyle = {},
}: {
  videoComponent: NonNullable<VideoComponentSelect>
  cssStyle?: Interpolation<Theme>
}) {
  let { videoID, isShorts } = getVideoIDFromYoutubeLink({ link: videoComponent.videoURL })

  const imageURL = getVideoYoutubePreviewByVideoID({ videoID }) // get the image URL
  const [opened, setOpened] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <AspectRatio
      ratio={isShorts ? 9 / 16 : 16 / 9}
      style={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
      }}
      css={cssStyle}
    >
      <div css={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, background: '#000' }}>
        {videoID && loaded ? (
          <>
            <iframe
              src={`https://www.youtube.com/embed/${videoID}?autoplay=0&controls=0`}
              css={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; playsinline"
              allowFullScreen
            />
            {/* {opened ? (
               <iframe
                src={`https://www.youtube.com/embed/${videoID}?autoplay=1`}
                css={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <>
                <img
                  src={imageURL}
                  onClick={() => {
                    setOpened(true)
                  }}
                  css={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '100%',
                    width: '100%',
                    maxHeight: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    zIndex: 1,
                    cursor: 'pointer',
                  }}
                />
                <div
                  css={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 68,
                    zIndex: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpened(true)
                  }}
                >
                  <YouTubePlay />
                </div>
              </>
            )} */}
          </>
        ) : null}
      </div>
    </AspectRatio>
  )
}
