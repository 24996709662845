import { ActionIcon, CheckIcon, ColorSwatch, Group, Popover, SimpleGrid } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconTrash } from '@tabler/icons'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { ReactNode, useState } from 'react'
import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'

export const ChooseSiteShadowSync = ({
  onChooseShadow,
  currentShadowID,
  children,
}: {
  onChooseShadow: ({}: { shadowID: string }) => void
  onClearColor?: () => void
  currentShadowID?: string
  children: ReactNode
}) => {
  const { siteID } = useSyncSiteContext()

  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const [open, setOpen] = useState(false)

  const offset = 10
  const [opened, setOpened] = useState(false)
  const { classes } = useMainStyles()

  return (
    <>
      <Popover withinPortal opened={opened} onChange={setOpened} width={300} offset={4} shadow="md">
        <Popover.Target>
          <div
            css={{
              '& > svg': {
                display: 'block',
              },
            }}
            onClick={() => setOpened(o => !o)}
          >
            {children}
          </div>
        </Popover.Target>
        <Popover.Dropdown
          css={{
            padding: 8,
            maxHeight: 'calc(50vh - 100px)',
            overflowY: 'auto',
          }}
          className={classes.hideScrollbar}
        >
          <SimpleGrid spacing={4} cols={1}>
            {site.boxShadows.map(shadow => (
              <Shadow
                key={shadow.id}
                currentShadowID={currentShadowID}
                modelID={shadow.id}
                selectID={shadow.selectID}
                onChooseShadow={args => {
                  setOpened(false)
                  onChooseShadow(args)
                }}
              />
            ))}
          </SimpleGrid>
        </Popover.Dropdown>
      </Popover>

      {/* // <div
    //   css={{
    //     position: 'relative',
    //   }}
    //   onMouseEnter={() => {
    //     setOpen(true)
    //   }}
    //   onMouseLeave={() => setOpen(false)}
    // >
    //   <div
    //     css={{
    //       '& > svg': {
    //         display: 'block',
    //       },
    //     }}
    //   >
    //     {' '}
    //     {children}
    //   </div>
    //   {open ? (
    //     <Paper
    //       css={{
    //         position: 'absolute',
    //         right: `calc(100% + ${offset}px)`,
    //         bottom: 0,
    //         width: 200,
    //         '&::after': {
    //           content: `""`,
    //           width: offset,
    //           height: '100%',
    //           dispaly: 'block',
    //           position: 'absolute',
    //           left: '100%',
    //           bottom: 0,
    //         },
    //       }}
    //       shadow="xs"
    //       p={12}
    //     >
    //       <Group spacing={12}>
    //       </Group>
    //     </Paper>
    //   ) : null}
    // </div> */}
    </>
  )
}

function Shadow({
  modelID,
  selectID,
  onChooseShadow,
  currentShadowID,
}: {
  modelID: string
  selectID: 'BoxShadow1'
  onChooseShadow: ({}: { shadowID: string }) => void
  currentShadowID?: string
}) {
  const shadow = useGetSelectData({ modelID, selectID })
  const boxShadowColorData = useGetSelectDataUndefined({
    modelID: shadow.color?.id,
    selectID: shadow.color?.selectID,
  })
  const widthAndHeight = 33

  const updateBoxShadow = trpc.useMutation('user.box.updateBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBoxShadow = trpc.useMutation('user.box.deleteBoxShadow', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  return (
    <CalcWidth
      width={40}
      leftNode={
        <CalcWidth
          side={'left'}
          width={42}
          leftNode={
            <Group
              p={8}
              position="center"
              css={{
                overflow: 'hidden',
                border: '1px solid #efefef',
                borderRadius: 2,
              }}
            >
              <ColorSwatch
                size={24}
                onClick={() => {
                  onChooseShadow({ shadowID: shadow.id })
                }}
                color={boxShadowColorData?.value || 'rgba(0, 0, 0, 0)'}
                css={{
                  boxShadow:
                    shadow && boxShadowColorData
                      ? `${shadow.xShift}px ${shadow.yShift}px ${shadow.blur}px ${shadow.spread}px ${boxShadowColorData.value}`
                      : undefined,
                }}
                sx={{ color: 'rgb(0, 134, 255)', cursor: 'pointer' }}
              >
                {currentShadowID == shadow.id && <CheckIcon color={colors.blue} width={10} />}
              </ColorSwatch>
            </Group>
          }
          rightNode={
            <Group pl="sm">
              <TextInputWithUpdate
                unstyled={true}
                value={shadow.viewName}
                placeholder={'Название тени'}
                onChange={async value => {
                  updateBoxShadow.mutate({
                    boxShadowID: shadow.id,
                    viewName: value,
                  })
                }}
              />
            </Group>
          }
        />
      }
      rightNode={
        <ActionIcon color={'red'} onClick={() => {}} size={20}>
          <IconTrash
            onClick={() => {
              deleteBoxShadow.mutate({
                boxShadowID: shadow.id,
              })
            }}
          />
        </ActionIcon>
      }
    />

    // <div
    //   onClick={() => {
    //     onChooseShadow({ shadowID: shadow.id })
    //   }}
    //   css={{
    //     width: widthAndHeight,
    //     height: widthAndHeight,
    //     borderRadius: 200,
    //     cursor: 'pointer',
    //     background: 'white',
    //     boxShadow:
    //       shadow && boxShadowColorData
    //         ? `${shadow.xShift}px ${shadow.yShift}px ${shadow.blur}px ${shadow.spread}px ${boxShadowColorData.value}`
    //         : undefined,
    //   }}
    // >
    //   {currentShadowID == shadow.id && <CheckIcon color={colors.blue} width={10} />}
    // </div>
  )
}
