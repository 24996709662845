import Resize from 'components/Resize/Resize'
import React, { useEffect, useRef, useState } from 'react'

export function ReduceComponent({
  component,
  hideBorder,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { component: JSX.Element; hideBorder?: boolean }) {
  const componentRef = useRef<HTMLDivElement>(null)
  const shellRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [componentHeight, setComponentHeight] = useState(0)

  useEffect(() => {
    if (shellRef.current) {
      setWidth(shellRef.current.clientWidth)
    }
  }, [])

  useEffect(() => {
    calcHeight()
  }, [width])

  useEffect(() => {
    calcHeight()
  }, [componentHeight])

  const calcHeight = () => {
    if (componentRef.current) {
      const { clientHeight, clientWidth } = componentRef.current
      setHeight((clientHeight / clientWidth) * width)
    }
  }

  const blockWidth = 1240

  return (
    <Resize onChange={({ width }) => setWidth(width)}>
      <div
        {...props}
        ref={shellRef}
        style={{
          height: `${height}px`,
          overflow: 'hidden',
          position: 'relative',
          border: hideBorder ? undefined : '2px solid red',
        }}
      >
        <div
          ref={componentRef}
          style={{
            width: `${blockWidth}px`,
            position: 'absolute',
            transform: `scale(${width / blockWidth})`,
            transformOrigin: 'left top',
          }}
        >
          <Resize
            onChange={({ height }) => {
              setComponentHeight(height)
            }}
          >
            {component}
          </Resize>
        </div>
      </div>
    </Resize>
  )
}
