import { Paper } from '@mantine/core'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { RichEditorControlsTipTap } from 'components/RichEditorControlsTipTap/RichEditorControlsTipTap'
import { useEffect } from 'react'

export default function EditorText({
  defaultValue,
  onChange,
  title,
}: {
  defaultValue: string
  onChange: (value: string) => void
  title?: string
}) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      Color.configure({
        types: ['textStyle'],
      }),
      Text,
      Highlight.configure({
        multicolor: true,
      }),
      Link.configure({
        HTMLAttributes: {
          class: 'tip-tap-link',
        },
      }),
    ],
    content: defaultValue || '<p></p>',
    parseOptions: {
      preserveWhitespace: 'full',
    },
  })

  const html = editor?.getHTML()

  useEffect(() => {
    html ? onChange(html) : null
  }, [html])

  return (
    <div
      css={{
        mark: {
          color: 'inherit !important',
        },
      }}
    >
      <Paper withBorder p={'xs'}>
        {title ? <p>{title}</p> : null}
        <Paper withBorder p={'xs'} mb={4}>
          <RichEditorControlsTipTap editor={editor} />
        </Paper>
        <EditorContent editor={editor} />
      </Paper>
    </div>
  )
}
