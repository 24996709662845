import { ScreeenType } from '@prisma/client'
import { createContext, useContext } from 'react'

export type PopupPublicContextType = {
  data: string | null
}
export const PopupPublicContext = createContext<PopupPublicContextType>({
  data: null,
})
export const usePopupPublicContext = () => useContext(PopupPublicContext)
