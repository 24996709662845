import { Group, Loader, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TextTransition, { presets } from 'react-text-transition'

export function PageLoader({ textAnimation }: { textAnimation?: string[] }) {
  return (
    <div
      css={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Loader size="xl" />
      {textAnimation && textAnimation.length ? <TextAnimation textAnimation={textAnimation} /> : null}
    </div>
  )
}

const TextAnimation = ({ textAnimation }: { textAnimation: string[] }) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 3000)
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <Group
      css={{
        width: '100%',
      }}
      mt={'md'}
      position={'center'}
    >
      <Text align="center">
        <TextTransition springConfig={presets.wobbly}>{textAnimation[index % textAnimation.length]}</TextTransition>
      </Text>
    </Group>
  )
}
