import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { colors } from 'utils/styles'

export function EntityLineSettingsBox({ childs }: { childs: Array<ReactNode | undefined> }) {
  return (
    <>
      {childs
        .reduce<ReactNode[]>((accum, child) => {
          if (child) accum.push(child)
          return accum
        }, [])
        .map((children, index) => {
          return (
            <div
              key={index}
              css={{
                borderBottom: '1px solid #EFEFEF',
                padding: '10px 8px',
              }}
            >
              {children}
            </div>
          )
        })}
    </>
  )
}

export function TitleOfSettings({ title, hidePadding }: { title: string; hidePadding?: boolean }) {
  return (
    <div
      css={{
        color: colors.blue,
        fontSize: 12,
        padding: hidePadding ? undefined : '0 8px',
      }}
    >
      {title}
    </div>
  )
}

export function EditOneBoxEntity({
  title,
  value,
  onChange,
  onTemporaryChange,
  onStart,
  position = 'left',
  decimal = 1,
}: {
  value: number | null
  title: string
  onChange: (value: number | null) => void
  onTemporaryChange?: (value: number | null) => void
  onStart?: () => void
  position?: 'top' | 'left' | 'right' | 'bottom'
  decimal?: number
}) {
  const inputWidth = 40

  const [temporaryValue, setTemporaryValue] = useState(value)

  const [focused, setFocused] = useState(false)

  const delayedChange = (value: number | null) => {
    if (onTemporaryChange) onTemporaryChange(value)
    setTemporaryValue(value)

    if (upLayerSetTimeout.current) {
      clearTimeout(upLayerSetTimeout.current)
    }

    upLayerSetTimeout.current = setTimeout(() => {
      onChange(value)
    }, 1000)
  }

  useEffect(() => {
    if (!focused) {
      setTemporaryValue(value)
    }
  }, [value, focused])

  const arrowUp = useCallback(
    (event: KeyboardEvent) => {
      const newValue = value == null ? 0 : value + decimal
      if (onStart) onStart()
      delayedChange(newValue)
    },
    [value]
  )

  const arrowDown = useCallback(
    (event: KeyboardEvent) => {
      const newValue = value == null ? 0 : value - decimal
      if (onStart) onStart()
      delayedChange(newValue)
    },
    [value]
  )

  const upLayerSetTimeout = useRef<undefined | number | NodeJS.Timeout>()

  return (
    <label
      css={{
        padding: '0px 7px',
        border: '1px solid transparent',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        height: position == 'top' || position == 'bottom' ? 52 : 32,
        position: 'relative',
        fontSize: 12,
        flexWrap: position == 'top' || position == 'bottom' ? 'wrap' : undefined,
        justifyContent: position == 'top' || position == 'bottom' ? 'center' : undefined,
        gap: position == 'top' || position == 'bottom' ? 8 : undefined,
        '&:hover > #border': {
          border: '1px solid #E6E6E6',
        },
      }}
    >
      <DragOffsetPosition
        onStart={() => {
          if (onStart) onStart()
        }}
        startValue={{
          value: temporaryValue,
        }}
        onChangePosition={({ offsetPosition, startValue }) => {
          if (startValue) {
            const pixelBoxDifferentWidth = Math.floor((startValue.value || 0) + offsetPosition.x)

            if (onTemporaryChange) onTemporaryChange(pixelBoxDifferentWidth)
            setTemporaryValue(pixelBoxDifferentWidth)

            return {
              resultSizes: {
                x: pixelBoxDifferentWidth,
              },
            }
          }

          return {
            resultSizes: {},
          }
        }}
        onEndChangePosition={({ resultPosition }) => {
          if (typeof resultPosition.x == 'number') {
            delayedChange(resultPosition.x)
          }
        }}
        style={{
          color: colors.blue,
          width: `calc(100% - ${inputWidth}px)`,
          cursor: 'ew-resize',
          order: position == 'right' || position == 'bottom' ? 1 : undefined,
          textAlign: position == 'right' ? 'right' : position == 'top' || position == 'bottom' ? 'center' : undefined,
        }}
        onDrag={({ is }) => {}}
        onClick={() => {}}
      >
        <span css={{}}>{title}</span>
      </DragOffsetPosition>

      <input
        value={temporaryValue == null ? '' : `${temporaryValue}`}
        onChange={event => {
          const newValue = event.target.value == '' ? null : Number(event.target.value)
          if (onStart) onStart()
          delayedChange(newValue)
        }}
        // onBlur={event => {
        //   onEnter()
        // }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type="number"
        onKeyDown={event => {
          switch (event.key) {
            case 'ArrowUp':
              event.preventDefault()
              arrowUp(event)
              break
            case 'ArrowDown':
              event.preventDefault()
              arrowDown(event)
              break

            default:
              break
          }
        }}
        onKeyUp={event => {
          event.preventDefault()

          switch (event.key) {
            case 'Enter':

            default:
              break
          }
        }}
        css={{
          width: position == 'top' || position == 'bottom' ? 50 : inputWidth,
          border: 'none',
          outline: 'none',
          order: position == 'right' || position == 'bottom' ? 0 : undefined,
          textAlign: position == 'right' ? 'left' : position == 'top' || position == 'bottom' ? 'center' : 'right',
          '&:focus': {
            border: 'none',
          },
          '&:focus + #border': {
            border: '1px solid #0D99FF !important',
          },
          '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'textfield',
          },
        }}
      />
      <span
        id="border"
        css={{
          position: 'absolute',
          left: 0,
          top: 0,
          borderRadius: 3,
          display: 'block',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      />
    </label>
  )
}
