import { Drawer } from '@mantine/core'
import { ScreeenType } from '@prisma/client'
import useStateRef from 'react-usestateref'

import { Box } from 'components/Box/Box'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'

import { MyBoxBlockContext } from 'contexts/BoxBlockContext'

import BoxBlock from 'blocks/BoxBlock/BoxBlock'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { breakpointsBox } from 'config/mq'
import { useEffect, useRef, useState } from 'react'
import { BoxBlockSelect } from 'server/selects'

export function EditBoxBlock(blockBox: NonNullable<BoxBlockSelect>) {
  const [opened, setOpened] = useState(false)

  return (
    <>
      <ReduceComponent css={{ cursor: 'pointer' }} onClick={() => setOpened(true)} component={<BoxBlock {...blockBox.box} />} />
      {opened ? 
      <Drawer
      transitionDuration={0}
        overlayOpacity={0.55}
        overlayBlur={3}
        padding={'md'}
        size="calc(100vh - 50px)"
        lockScroll={true}
        position="bottom"
        opened={opened}
        onClose={() => setOpened(false)}
        title="Выбрать картинку"
        css={{
          overflowY: 'scroll',
        }}
      >
         <EditBoxBlockDrawer {...blockBox} /> 
      </Drawer>
      : null}
    </>
  )
}

export function EditBoxBlockDrawer(blockBox: NonNullable<BoxBlockSelect>) {
  const [width, setWidth] = useState<number>(0)
  const [widthResponsive, setWidthResponsive, widthResponsiveRef] = useStateRef<number | undefined>(undefined)
  const [widthTemporaryResponsive, setWidthTemporaryResponsive, widthTemporaryResponsiveRef] = useStateRef<number | undefined>(
    undefined
  )

  const boxesRef = useRef<HTMLDivElement>(null)
  const boxesWidthRef = useRef<HTMLDivElement>(null)
  const boxesShellRef = useRef<HTMLDivElement>(null)

  const [lineIsShow, setLineIsShow] = useState(true)
  const [responsiveMode, setResponsiveMode] = useState<ScreeenType>('Desktop')

  const [focusBoxID, setFocusBoxID] = useState('')

  const [arrows, setArrows, arrowsRef] = useStateRef({
    lineIsShow: false,
  })

  useEffect(() => {
    if (arrows.lineIsShow) {
      setLineIsShow(!lineIsShow)
    }
  }, [arrows.lineIsShow])

  useEffect(() => {
    const keydownCallback = (event: KeyboardEvent) => {
      setArrows(arrows => {
        const newArrows = {
          lineIsShow: event.key == 's' ? true : arrows.lineIsShow,
        }
        return newArrows
      })
    }
    const keyupCallback = (event: KeyboardEvent) => {
      setArrows(arrows => {
        const newArrows = {
          lineIsShow: event.key == 's' ? false : arrows.lineIsShow,
        }
        return newArrows
      })
    }

    // boxesShellRef.current?.addEventListener('keydown', keydownCallback)
    // boxesShellRef.current?.addEventListener('keyup', keyupCallback)
    return () => {
      // boxesShellRef.current?.removeEventListener('keydown', keydownCallback)
      // boxesShellRef.current?.removeEventListener('keyup', keyupCallback)
    }
  }, [])

  useEffect(() => {
    if (boxesRef.current) {
      setWidth(boxesRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxesRef.current) {
          const width = boxesRef.current.offsetWidth
          setWidth(width)
          if (width >= breakpointsBox.desktop) {
            setResponsiveMode('Desktop')
          } else if (width >= breakpointsBox.tablet) {
            setResponsiveMode('Tablet')
          } else {
            setResponsiveMode('Mobile')
          }
        }
      })
      resizeObserver.observe(boxesRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (boxesWidthRef.current) setWidthResponsive(boxesWidthRef.current.offsetWidth)
  }, [])

  const dragWidthPX = 14

  const settingsWidth = 300

  return (
    <>
      <div
        ref={boxesShellRef}
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <div css={{ height: '100vh', overflowY: 'auto', width: `calc(100% - ${settingsWidth}px)` }}>
          <div
            ref={boxesWidthRef}
            style={{
              width: widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive,
              maxWidth: '100%',
              minWidth: '320px',
              display: 'flex',
              margin: '0 auto',
              borderLeft: '1px solid #0086ff',
              borderRight: '1px solid #0086ff',
              minHeight: '100%',
            }}
          >
            <div
              style={{
                width: dragWidthPX,
                minHeight: '100%',
              }}
            >
              <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelDifferentWidthPrevPixel = (widthResponsiveRef.current || 0) - offsetPosition.x * 2
                  const limitPixelDifferentWidthPrevPixel =
                    pixelDifferentWidthPrevPixel > 320 ? pixelDifferentWidthPrevPixel : 320
                  setWidthTemporaryResponsive(limitPixelDifferentWidthPrevPixel)

                  return {
                    resultSizes: {
                      x: limitPixelDifferentWidthPrevPixel,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  setWidthTemporaryResponsive(undefined)
                  if (resultPosition.x) setWidthResponsive(resultPosition.x)
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'ew-resize',
                }}
              >
                <div
                  style={{
                    width: 4,
                    height: 'calc(100% - 10px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: responsiveMode == 'Desktop' ? '#0086ff' : responsiveMode == 'Tablet' ? '#ffb800' : '#81ff9d',
                    zIndex: 2,
                  }}
                />
              </DragOffsetPosition>
            </div>

            <div
              ref={boxesRef}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                height: '100%',
                width: `calc(100% - ${dragWidthPX}px)`,
              }}
            >
              {/* <MyBoxBlockContext.Provider
                value={{
                  lineIsShow,
                  setLineIsShow,
                  responsiveMode,
                  setResponsiveMode,
                  focusBoxID: focusBoxID,
                  setFocusBoxID: setFocusBoxID,
                }}
              >
                <Box
                  onAddBox={({ box }) => {
                    // setBoxes(boxes => {
                    //   return Array.isArray(boxes) ? [...boxes, box] : boxes
                    // })
                  }}
                  onDeleteBox={({ id }) => {
                    // setBoxes(boxes.filter(box => box.id !== id))
                  }}
                  parentBoxWidth={width}
                  box={blockBox.box}
                />
              </MyBoxBlockContext.Provider> */}
            </div>
          </div>
        </div>

        <div
          css={{
            width: settingsWidth,
          }}
          id="box-settings"
        />
      </div>
    </>
  )
}
