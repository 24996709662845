import { Button, Drawer, Group, Menu, Modal, Popover, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useGetSelectDataUndefined } from 'generation/centerFrontedEngine'

import { useState } from 'react'
import { ChooseSitePopupsSync } from 'syncComponents/ChooseSitePopupsSync/ChooseSitePopupsSync'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { ChooseSectionForLinkSync, LinkType } from 'syncComponents/ChooseSectionForLinkSync/ChooseSectionForLinkSync'

import { trpc } from 'utils/trpc'

export function LinkSync({
  linkID,
  selectID,
  type,
  pinID,
  onClose,
}: {
  linkID?: string
  selectID?: 'Link1'
  type: LinkType
  pinID: string
  onClose: (arg: false) => void
}) {
  const deleteLink = trpc.useMutation('user.deleteLink', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateLink = trpc.useMutation('user.updateLink', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createLink = trpc.useMutation('user.createLink', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const link = useGetSelectDataUndefined({ modelID: linkID, selectID: selectID })

  const formCreateUrlLink = useForm({
    initialValues: {
      url: link?.url ? link.url : '',
    },
  })

  const [chooseSectionOpened, setChooseSectionOpened] = useState(false)
  const [chooseURLOpened, setChooseURLOpened] = useState(false)

  const linkIDs = {
    pinID,
    type,
  }

  return (
    <>
      {chooseURLOpened ? (
        <Modal
          withinPortal
          zIndex={100000000}
          transitionDuration={0}
          opened={chooseURLOpened}
          onClose={() => setChooseURLOpened(false)}
        >
          <form
            onSubmit={formCreateUrlLink.onSubmit(async values => {
              createLink.mutate({
                url: values.url,
                ...linkIDs,
              })
              setChooseURLOpened(false)
            })}
          >
            <TextInput mb="xs" required placeholder="url" {...formCreateUrlLink.getInputProps('url')} />

            <Group position="right">
              <Button loading={createLink.isLoading} type="submit">
                Создать
              </Button>
            </Group>
          </form>
        </Modal>
      ) : null}

      {chooseSectionOpened ? (
        <Drawer
          zIndex={100000000}
          withinPortal
          transitionDuration={0}
          overlayOpacity={0.55}
          overlayBlur={3}
          padding={'md'}
          size="calc(100vh - 50px)"
          lockScroll={true}
          position="bottom"
          opened={chooseSectionOpened}
          onClose={() => setChooseSectionOpened(false)}
          title="Выбрать блок для ссылки"
          css={{
            overflowY: 'scroll',
          }}
        >
          <ChooseSectionForLinkSync pinID={pinID} type={type} link={link && selectID ? { id: link.id, selectID } : null} />
        </Drawer>
      ) : null}

      <Menu.Label>
        {link ? (
          <>
            Ссылка{' '}
            {link.popup ? (
              <>
                на всплывашку
                {/* {box.link.popup.quiz ? `с квизом "${box.link.popup.quiz.title}"` : ''}
                        {box.link.popup.form ? `с формой "${box.link.popup.form.title}"` : ''} */}
                <TextInputWithUpdate
                  value={typeof link.popupData == 'string' ? link.popupData : ''}
                  size="xs"
                  onChange={value => {
                    if (link.id) {
                      updateLink.mutate({
                        linkID: link.id,
                        popupData: value,
                      })
                    }
                  }}
                  unstyled={true}
                  placeholder={'Данные для попапа в форму заявки'}
                />
              </>
            ) : null}
            {link.url ? <> на другой сайт: {link.url}</> : ''}
            {link.page ? (
              <>
                {' '}
                на cтраницу:
                {/* {link.page.pathname} */}
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          <>Добавить ссылку на:</>
        )}
      </Menu.Label>

      {link ? (
        <>
          {link.page ? (
            <Menu.Item
              onClick={() => {
                // onClose(false)
                setChooseSectionOpened(true)
              }}
            >
              Показать выбранный блок
            </Menu.Item>
          ) : null}
          {link.url ? (
            <Menu.Item
              onClick={() => {
                // onClose(false)
                formCreateUrlLink.setFieldValue('url', `${link?.url || ''}`)
                setChooseURLOpened(true)
              }}
            >
              Отредактировать ссылку
            </Menu.Item>
          ) : null}
          <Menu.Item
            color={'red'}
            // loading={deleteLink.isLoading}
            onClick={() => {
              // event.stopPropagation()
              if (link)
                deleteLink.mutate({
                  linkID: link.id,
                })
            }}
          >
            Удалить ссылку
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item
            onClick={() => {
              // onClose(false)
              setChooseSectionOpened(true)
            }}
          >
            Блок или страницу
          </Menu.Item>
          <Popover withinPortal position="right-start" width={'auto'} shadow="md">
            <Popover.Target>
              <Menu.Item>Всплывашку</Menu.Item>
            </Popover.Target>
            <Popover.Dropdown
              css={{
                padding: '8px 12px',
                borderRadius: 2,
              }}
            >
              <ChooseSitePopupsSync
                onChoosePopup={({ popupID }) => {
                  createLink.mutate({
                    popupID,
                    ...linkIDs,
                  })
                }}
              />
            </Popover.Dropdown>
          </Popover>
          <Menu.Item
            onClick={() => {
              // onClose(false)
              setChooseURLOpened(true)
            }}
          >
            Другой сайт
          </Menu.Item>
        </>
      )}
    </>
  )
}
