import dynamic from 'next/dynamic'

export const MapComponentSyncNoSSR = dynamic(() => import('./MapComponentSync').then(mode => mode.MapComponentSync))

// export const MapComponentSyncNoSSR = (props: React.ComponentProps<typeof MapComponentSync>) => {
//   return typeof window == 'undefined' ? null : (
//     <React.Suspense>
//       <MapComponentSync {...props} />
//     </React.Suspense>
//   )
// }
