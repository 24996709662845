import { Accordion, createStyles } from '@mantine/core'
import { TextWrap } from 'components/TextWrap'
import { FaqComponentSelect } from 'server/selects'

export const useFaqStyles = createStyles(theme => ({
  root: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    borderRadius: theme.radius.sm,
  },

  item: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    border: '1px solid transparent',
    position: 'relative',
    zIndex: 0,
    transition: 'transform 150ms ease',

    '&[data-active]': {
      transform: 'scale(1.03)',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      boxShadow: theme.shadows.md,
      borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
      borderRadius: theme.radius.md,
      zIndex: 1,
    },
  },

  chevron: {
    '&[data-rotate]': {
      transform: 'rotate(-90deg)',
    },
  },
}))

export function FaqComponent(faqComponent: NonNullable<FaqComponentSelect>) {
  const { classes } = useFaqStyles()
  return (
    <Accordion
      variant="filled"
      classNames={classes}
      className={classes.root}
      radius="lg"
      defaultValue={`${faqComponent.faqItems[0]?.id}`}
      css={{ width: '100%' }}
    >
      {faqComponent.faqItems.map(faq => (
        <Accordion.Item value={faq.id}>
          <Accordion.Control>
            <TextWrap text={faq.question} />
          </Accordion.Control>
          <Accordion.Panel>
            <TextWrap text={faq.answer} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
