import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { SectionsSync } from 'pages/panel/sitesV2/[siteID]/pages/[pageID]'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

type Link = ({ id: string } & { selectID: 'Link1' }) | null

export type LinkType = 'form' | 'box'

export const ChooseSectionForLinkSync = ({ link, pinID, type }: { link?: Link; pinID: string; type: LinkType }) => {
  const { siteID } = useSyncSiteContext()

  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: 30,
      }}
    >
      {site.pagesV1.map(page => (
        <PageForLink type={type} pinID={pinID} link={link} key={page.id} modelID={page.id} selectID={page.selectID} />
      ))}
    </div>
  )
}

const PageForLink = ({
  modelID,
  selectID,
  link,
  pinID,
  type,
}: {
  modelID: string
  selectID: 'Page1'
  link?: Link
  pinID: string
  type: LinkType
}) => {
  const page = useGetSelectData({ modelID, selectID })
  const createLink = trpc.useMutation('user.createLink', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const linkData = useGetSelectDataUndefined({ modelID: link?.id, selectID: link?.selectID })
  return (
    <div key={page.id} css={{ width: 300, background: colors.blue, padding: 10 }}>
      <div css={{ color: 'white', marginBottom: 10 }}>
        <Text
          onClick={() => {
            // if (!box.link) {
            if (!linkData)
              createLink.mutate({
                anchor: {
                  pageID: page.id,
                },
                pinID,
                type,
              })
            // setChooseSectionOpened(false)
            // }
          }}
          size={'sm'}
          css={{
            cursor: linkData ? undefined : 'pointer',
          }}
          title={linkData ? undefined : 'Добавить ссылку на страницу'}
        >
          {' '}
          /{page.pathname} - {page.title}{' '}
          {linkData?.page?.id == page.id ? (
            <>
              {' '}
              - <strong>Выбрана как ссылка</strong>
            </>
          ) : (
            ''
          )}
        </Text>

        {/* <Button
          
          compact
        >
          Выбрать страницу как ссылку
        </Button> */}
      </div>
      <div css={{ background: 'white' }}>
        <ReduceComponent
          hideBorder={true}
          component={
            <>
              {page.sectionsPinnedCollection.map(sectionsPinnedCollection => (
                <SectionsPinnedForLink
                  link={link}
                  key={sectionsPinnedCollection.id}
                  modelID={sectionsPinnedCollection.id}
                  selectID={sectionsPinnedCollection.selectID}
                  pageID={page.id}
                  pinID={pinID}
                  type={type}
                />
              ))}
            </>
          }
        />
      </div>
    </div>
  )
}

export const SectionsPinnedForLink = ({
  modelID,
  selectID,
  link,
  pageID,
  pinID,
  type,
}: {
  modelID: string
  selectID: 'SectionsPinned1'
  link?: Link
  pageID: string
  pinID: string
  type: LinkType
}) => {
  const sectionsPinnedCollection = useGetSelectData({ modelID, selectID })
  const sectionsCollection = useGetSelectData({
    modelID: sectionsPinnedCollection.sectionsCollection.id,
    selectID: sectionsPinnedCollection.sectionsCollection.selectID,
  })

  return (
    <>
      {sectionsCollection.sectionsChild.map(sectionChild => (
        <SectionSyncForLink
          sectionPinnedID={modelID}
          pageID={pageID}
          link={link}
          key={sectionChild.id}
          modelID={sectionChild.id}
          selectID={sectionChild.selectID}
          pinID={pinID}
          type={type}
        />
      ))}
    </>
  )
}

const SectionSyncForLink = ({
  modelID,
  selectID,
  link,
  pageID,
  sectionPinnedID,
  pinID,
  type,
}: {
  modelID: string
  selectID: 'Section1'
  link?: Link
  pageID: string
  sectionPinnedID: string
  pinID: string
  type: LinkType
}) => {
  const sectionChild = useGetSelectData({ modelID, selectID })

  const linkData = useGetSelectDataUndefined({ modelID: link?.id, selectID: link?.selectID })

  const sectionIsChoosen =
    linkData?.page?.id == pageID &&
    linkData?.sectionCollection?.id == sectionPinnedID &&
    linkData?.section?.id == sectionChild.id

  const hoverStyle = {
    transform: 'scale(1.05)',
  }
  const createLink = trpc.useMutation('user.createLink', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return (
    <div
      key={sectionChild.id}
      css={
        linkData
          ? {
              border: sectionIsChoosen ? `20px dashed ${colors.blue}` : 'none',
            }
          : {
              cursor: 'pointer',
              transition: '0.05s',
              ':hover': hoverStyle,
            }
      }
      onClick={() => {
        if (!linkData)
          createLink.mutate({
            anchor: {
              pageID: pageID,
              sectionCollectionID: sectionPinnedID,
              sectionID: sectionChild.id,
            },

            pinID,
            type,
          })
        // setChooseSectionOpened(false)
      }}
    >
      <div
        css={{
          pointerEvents: 'none',
        }}
      >
        <SectionsSync hideLine={true} width={1920} modelID={modelID} selectID={selectID} />
      </div>
    </div>
  )
}
