import { Badge, CopyButton, Divider, Group, Image, Modal, Paper, SimpleGrid, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconCirclePlus, IconExternalLink, IconTrash } from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'

import { PageLoader } from 'components/PageLoader/PageLoader'
import { useState } from 'react'
import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'
import { getPathToFile } from 'utils/getPathToFile'
import { trpc } from 'utils/trpc'

export const DomainsRefetch = ({ onChooseDomain }: { onChooseDomain?: ({}: { domainID: string }) => void }) => {
  const domains = trpc.useQuery(['user.getDomains'], {
    refetchInterval: 500,
  })

  const addDomainForm = useForm({
    initialValues: {
      domain: '',
    },
  })

  const addDomain = trpc.useMutation('user.domain.addDomain', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      showNotification({
        title: 'Вы уже можете использовать домен',
        message: '',
        color: 'green',
      })
    },
  })
  const deleteDomain = trpc.useMutation('user.domain.deleteDomain', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const [opened, setOpened] = useState(false)

  return (
    <>
      <Modal
        withCloseButton={true}
        centered
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        overflow="outside"
        padding={'lg'}
        zIndex={300}
        title={'Добавить домен'}
      >
        <Group>
          <form
            onSubmit={addDomainForm.onSubmit(async values => {
              addDomain.mutate({
                domain: values.domain,
              })
            })}
          >
            <SimpleGrid spacing={12}>
              <TextInput
                fullWidth
                label="Введите домен"
                required
                placeholder="имя-сайта.vingla.ru"
                {...addDomainForm.getInputProps('domain')}
              />
              <Text>
                Перед добавлением в DNS настройках домена в вашем хостинге измените или создайте <strong>A</strong> и{' '}
                <strong>AAAA</strong> записи
              </Text>
              <Group spacing={4}>
                <CopyButton css={{ marginRight: 4 }} value="109.68.214.168">
                  {({ copied, copy }) => (
                    <Badge color={copied ? 'teal' : 'pink'} onClick={copy} variant="outline">
                      {copied ? 'Скопирована' : 'Скопировать'} DNS запись A
                    </Badge>
                  )}
                </CopyButton>
                <CopyButton value="2a03:6f00:5:1::2aae">
                  {({ copied, copy }) => (
                    <Badge color={copied ? 'teal' : 'pink'} onClick={copy} variant="outline">
                      {copied ? 'Скопирована' : 'Скопировать'} DNS запись AAAA
                    </Badge>
                  )}
                </CopyButton>
              </Group>
              <AccentButton leftIcon={<IconCirclePlus />} loading={addDomain.isLoading} type="submit">
                Добавить домен
              </AccentButton>
            </SimpleGrid>
          </form>
        </Group>
      </Modal>
      {domains.isLoading ? (
        <PageLoader />
      ) : domains.data ? (
        <>
          <Group mb={cabinetSizes.headlineBottom} position="apart">
            <Title order={2}>Домены</Title>
            <AccentButton leftIcon={<IconCirclePlus />} onClick={() => setOpened(true)}>
              Добавить домен
            </AccentButton>
          </Group>

          {domains.data.length ? (
            <SimpleGrid spacing={16} cols={1}>
              {domains.data.map(domain => (
                <Paper
                  css={{
                    background: cabinetColors.background,
                    border: `1px solid ${cabinetColors.border}`,
                    boxShadow: 'none',
                    overflow: 'hidden',
                  }}
                >
                  <SimpleGrid spacing={0}>
                    <Group py={18} px={22}>
                      <a
                        css={{
                          display: 'block',
                          fontWeight: '700',
                          textDecoration: 'none',
                        }}
                        target="_blank"
                        href={`https://${domain.domain}`}
                      >
                        <Group>
                          <div
                            css={{
                              width: 18,
                              height: 18,
                              borderRadius: '50%',
                              background: domain.siteV1 ? cabinetColors.accent : cabinetColors.border,
                            }}
                          />
                          <Text size="md">{domain.domain}</Text>
                          <GrayButton white={true} compact>
                            <IconExternalLink />
                          </GrayButton>
                        </Group>
                      </a>

                      {onChooseDomain ? (
                        <GrayButton
                          white={true}
                          size={'xs'}
                          compact
                          onClick={() => {
                            if (onChooseDomain) onChooseDomain({ domainID: domain.id })
                          }}
                          leftIcon={<IconCirclePlus />}
                        >
                          Выбрать домен
                        </GrayButton>
                      ) : null}
                      <GrayButton
                        compact
                        white={true}
                        onClick={() => {
                          deleteDomain.mutate({
                            domainID: domain.id,
                          })
                        }}
                        leftIcon={<IconTrash color="red" />}
                      >
                        <Text color={'red'}>удалить</Text>
                      </GrayButton>
                    </Group>
                    {domain.siteV1 ? (
                      <>
                        <Divider />
                        <Group
                          py={18}
                          px={22}
                          css={{
                            background: cabinetColors.white,
                          }}
                        >
                          {domain.siteV1.favicon ? (
                            <Image
                              radius={2}
                              width={12}
                              height={12}
                              src={getPathToFile({
                                fileName: domain.siteV1.favicon.name,
                                folderName: domain.siteV1.favicon.folder,
                              })}
                            />
                          ) : null}
                          <Text weight={600}>{domain.siteV1.name}</Text>
                        </Group>
                      </>
                    ) : null}
                  </SimpleGrid>
                  {/* TODO дописать функционал удаления сертификатов */}
                  {/* <Group mt={'xs'} position="right">
                <Button
                  onClick={() =>
                    deleteOneDomainV1({
                      variables: {
                        where: {
                          id: domain.id,
                        },
                      },
                    })
                  }
                  loading={deleteOneDomainV1Loading}
                  size="xs"
                  color="red"
                >
                  Удалить домен
                </Button>
              </Group> */}
                </Paper>
              ))}
            </SimpleGrid>
          ) : null}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
