import { Interpolation, Theme } from '@emotion/react'

export function CalcWidth({
  side = 'right',
  width = 100,
  leftNode,
  rightNode,
  onClick,
  customCSS,
}: {
  side?: 'right' | 'left'
  width?: number
  leftNode?: JSX.Element
  rightNode?: JSX.Element
  onClick?: () => void
  customCSS?: Interpolation<Theme>
}) {
  const propsCSS = typeof customCSS == 'object' ? customCSS : {}
  return (
    <>
      <div
        onClick={onClick}
        css={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          ...propsCSS,
        }}
      >
        <div
          css={{
            width: side == 'right' ? `calc(100% - ${width}px)` : width,
          }}
        >
          {leftNode}
        </div>
        <div
          css={{
            width: side == 'left' ? `calc(100% - ${width}px)` : width,
          }}
        >
          {rightNode}
        </div>
      </div>
    </>
  )
}
