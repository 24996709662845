import { ScreeenType } from '@prisma/client'
import { createContext, useContext } from 'react'
import { NestedBoxSelect } from 'server/selects'

export type ResponsiveMode = 'mobile' | 'tablet' | 'desktop'

export type UpdateBoxByIDSiteData = { id: string; box: Partial<NonNullable<NestedBoxSelect>> }
export type SiteDataEventsContext = {
  updateBoxByID: ({}: UpdateBoxByIDSiteData) => void
}
export const MySiteDataEventsContext = createContext<SiteDataEventsContext>({
  updateBoxByID: ({}) => {},
})

export const useSiteDataEventsContext = () => useContext(MySiteDataEventsContext)
