import { Interpolation, Theme } from '@emotion/react'
import { Box, Button, ButtonProps, Group, Image, MantineProvider, SimpleGrid, TextInput, TextInputProps } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { TextWrap } from 'components/TextWrap'
import { usePopupPublicContext } from 'contexts/PopupPublicContext'
import { PublicSiteContext } from 'contexts/PublicSiteContextProvider'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import { FormSelect } from 'server/selects'
import { BackFormButton } from 'syncComponents/SiteFormsSync/SiteFormsSync'
import { CountryMask } from 'utils/countries'
import { targetsEventener } from 'utils/eventener'
import { getPathToFile } from 'utils/getPathToFile'
import { isInputValid } from 'utils/isInputValid'
import { landingTheme } from 'utils/landingTheme'
import { trpc } from 'utils/trpc'

import { getUrlToPageFromLink, pushOpenWindowFromLink } from 'blocks/BoxBlock/BoxBlock'
import { useGetSelectData } from 'generation/centerFrontedEngine'

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const russiaCountry: CountryMask = {
  name: 'Russia',
  code: '+7',
  iso: 'RU',
  flag: 'ru.svg',
  mask: '(999)-999-99-99',
}

function checkIfHTMLTextEmpty(html: string) {
  return html !== '<p></p>' && html !== ''
}

const commonMarginBottom = 'md'
export const commonBorderRadius = 4

function textInputStyles({
  type,
  textColor,
  color,
  radius,
}: {
  type: 'phone' | 'text'
  textColor?: string
  color?: string
  radius?: string | Interpolation<Theme>
}): TextInputProps['styles'] {
  const radiusCSS = typeof radius == 'object' ? radius : {}
  return {
    input: {
      backgroundColor: textColor || color ? 'rgba(0,0,0,0)' : undefined,
      borderColor: textColor,
      '&:focused, &:focus-within': {
        borderColor: color,
        backgroundColor: textColor || color ? 'rgba(0,0,0,0)' : undefined,
      },
      color: textColor,
      fontSize: type == 'phone' ? 16 : undefined,

      borderRadius: commonBorderRadius,
      // borderRadius: typeof radius == 'string' ? radius : undefined,
      // ...radiusCSS,
    },
  }
}

function textInputCommonProps({}: {}): TextInputProps {
  return {
    size: 'lg',
  }
}

function sendButtonCommonProps({ radius }: { radius?: string | Interpolation<Theme> }): ButtonProps {
  const radiusCSS = typeof radius == 'object' ? radius : {}
  return {
    size: 'xl',
    fullWidth: true,
    styles: {
      root: {
        borderRadius: commonBorderRadius,
        // borderRadius: typeof radius == 'string' ? radius : undefined,
        // ...radiusCSS,
      },
    },
  }
}

export function Form({
  form,
  info,
  onSended,
  onBack,
  textColor,
  mainColor,
  buttonTextColor,
  radiusCSS,
}: {
  form: NonNullable<FormSelect>
  info?: string
  onSended?: () => void
  onBack?: () => void
  textColor?: string
  mainColor?: string
  buttonTextColor?: string
  radiusCSS?: Interpolation<Theme>
}) {
  const { publicSite } = useContext(PublicSiteContext)
  const popupPublicContext = usePopupPublicContext()

  const [inputs, setInputs] = useState<{ id: string; value: string; placeholder: string; error: boolean }[]>([])

  const router = useRouter()
  const { page } = router.query
  const pagePathName = page ? (Array.isArray(page) ? `${page.join('/')}` : page) : ''

  const createLead = trpc.useMutation('public.createLead', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: error => {
      showNotification({
        title: 'Заявка отправлена',
        message: '',
        color: 'green',
      })
      if (form.eventBySendedForm) targetsEventener.push({ id: form.eventBySendedForm.id })
      if (onSended) onSended()
      setInputs(inputs.map(input => ({ ...input, value: '', error: false })))
    },
  })

  useEffect(() => {
    setInputs(() =>
      form.extraInputs.map(extraInput => ({
        id: extraInput.id,
        placeholder: extraInput.placeholder,
        value: '',
        error: false,
      }))
    )
  }, [])

  return (
    <>
      {checkIfHTMLTextEmpty(form.title) ? (
        <Box mb={commonMarginBottom}>
          <TextWrap color={textColor} text={form.title} />
        </Box>
      ) : null}
      <form
        onSubmit={async event => {
          event.preventDefault()

          let thereIsErrorInput = false

          setInputs(
            inputs.map(input => {
              const extraInput = form.extraInputs.find(extraInput => extraInput.id == input.id)
              if (extraInput) {
                if (extraInput.isRequired) {
                  const inputIsValid = isInputValid({ type: extraInput.type, value: input.value })

                  if (!inputIsValid) {
                    // thereIsErrorInput = true
                    return {
                      ...input,
                      error: true,
                    }
                  }
                }
              }

              return input
            })
          )

          if (thereIsErrorInput) return false

          await createLead.mutateAsync({
            pagePathName,
            inputs: inputs.map(input => {
              return {
                inputID: input.id,
                value: input.value,
                placeholder: input.placeholder,
              }
            }),
            formID: form.id,
            quizInfo: info,
            popupInfo: popupPublicContext?.data,
          })
          if (form.target && publicSite.siteV1.yandexMetrikaId) {
            try {
              // @ts-ignore
              window.ym(publicSite.siteV1.yandexMetrikaId, 'reachGoal', form.target.name)
            } catch (error) {}
          }

          if (form.target && publicSite.siteV1.vkontaktePixelId) {
            try {
              // @ts-ignore
              VK.Retargeting.Event(form.target.name)
            } catch (error) {}
          }

          if (form.link) {
            try {
              if (form.link.page) {
                window.location.replace(getUrlToPageFromLink({ link: form.link }))
              }
              if (form.link.url) {
                window.location.replace(form.link.url)
              }

              if (form.link.popup) {
                pushOpenWindowFromLink({ link: form.link })
              }
            } catch (error) {}
          }
        }}
      >
        {form.extraInputs.map(extraInput => {
          return (
            <ExtraInput
              textColor={textColor}
              color={mainColor}
              placeholder={extraInput.placeholder}
              value={inputs.find(input => input.id == extraInput.id)?.value || ''}
              onChange={({ value }) => {
                const copyInputs = [...inputs]
                const copyInputExist = copyInputs.find(copyInput => copyInput.id == extraInput.id)
                if (copyInputExist) {
                  if (extraInput.isRequired) {
                    const inputIsValid = isInputValid({ type: extraInput.type, value })
                    copyInputExist.error = !inputIsValid
                  }

                  copyInputExist.value = value
                } else {
                  copyInputs.push({ id: extraInput.id, value, placeholder: extraInput.placeholder, error: false })
                }
                setInputs(copyInputs)
              }}
              key={extraInput.id}
              type={extraInput.type}
              error={!!inputs.find(input => input.id == extraInput.id)?.error}
              radius={radiusCSS}
            />
          )
        })}
        {onBack ? (
          <Group mb={commonMarginBottom}>
            <BackFormButton
              color={textColor}
              onClick={() => {
                if (onBack) onBack()
              }}
            />
          </Group>
        ) : null}
        <Button
          css={{
            color: buttonTextColor,
          }}
          loading={createLead.isLoading}
          type={'submit'}
          {...sendButtonCommonProps({ radius: radiusCSS })}
        >
          {form.formButtonText}
        </Button>
      </form>
    </>
  )
}

function ExtraInput({
  value,
  onChange,
  placeholder,
  type,
  error,
  color,
  textColor,
  radius,
}: {
  value: string
  onChange: ({}: { value: string }) => void
  placeholder: string
  type: 'Phone' | 'Text'
  error: boolean
  color?: string
  textColor?: string
  radius?: string | Interpolation<Theme>
}) {
  return type == 'Text' ? (
    <ExtraTextInput
      error={error}
      value={value}
      color={color}
      textColor={textColor}
      onChange={value => {
        onChange({ value })
      }}
      placeholder={placeholder}
      radius={radius}
    />
  ) : (
    <ExtraPhoneInput
      error={error}
      value={value}
      color={color}
      textColor={textColor}
      onChange={({ value }) => {
        onChange({ value })
      }}
      placeholder={placeholder}
      radius={radius}
    />
  )
}

function ExtraTextInput({
  value,
  onChange,
  placeholder,
  error,
  color,
  textColor,
  radius,
}: {
  value: string
  onChange: (value: string) => void
  placeholder: string
  error: boolean
  color?: string
  textColor?: string
  radius?: string | Interpolation<Theme>
}) {
  return (
    <TextInput
      styles={textInputStyles({ type: 'text', textColor, color, radius })}
      error={error ? 'Поле обязательное' : ''}
      value={value}
      // required
      onChange={event => {
        const value = event.target.value
        onChange(value)
      }}
      placeholder={placeholder}
      mb={commonMarginBottom}
      {...textInputCommonProps({})}
    />
  )
}

function ExtraPhoneInput({
  value,
  onChange,
  placeholder,
  error,
  color,
  textColor,
  radius,
}: {
  value: string
  onChange: ({}: { value: string }) => void
  placeholder: string
  error: boolean
  color?: string
  textColor?: string
  radius?: string | Interpolation<Theme>
}) {
  const [currentCountry, setCurrentCountry] = useState<CountryMask>(russiaCountry)

  const mask = Array.isArray(currentCountry.mask)
    ? `${currentCountry.code} ${currentCountry.mask[0]}`
    : `${currentCountry.code} ${currentCountry.mask}`

  const [localValue, setLocalValue] = useState('')

  const previousValue = usePrevious(value)

  useEffect(() => {
    if (!value && previousValue) {
      setLocalValue('')
    }
  }, [value])

  useEffect(() => {
    onChange({
      value: ``,
    })
  }, [])

  return (
    <>
      <Group
        css={{
          position: 'relative',
        }}
        mb={commonMarginBottom}
      >
        <InputMask
          value={localValue}
          mask={mask}
          onChange={event => {
            let value = event.target.value
            try {
              value = value.replaceAll(' ', '')
            } catch (error) {}

            console.log(value)
            onChange({
              value: value ? `${value} ${currentCountry.name}` : '',
            })
            setLocalValue(value)
          }}
          // required
        >
          {/* @ts-ignore */}
          {() => (
            <TextInput
              error={error ? 'Поле обязательное' : ''}
              placeholder={mask}
              css={{ width: '100%' }}
              value={localValue}
              iconWidth={40}
              icon={<div></div>}
              inputMode="numeric"
              styles={textInputStyles({ type: 'phone', textColor, color, radius })}
              {...textInputCommonProps({})}
            />
          )}
        </InputMask>
        <CoutrySelect
          onChange={({ country }) => {
            setCurrentCountry(country)
          }}
          countryMask={currentCountry}
        />
      </Group>
      {/*  */}
      {/*  */}
    </>
  )
}

function CoutrySelect({
  onChange,
  countryMask,
}: {
  onChange: ({}: { country: CountryMask }) => void
  countryMask: CountryMask
}) {
  const serverPhoneMasks = trpc.useQuery(['public.getPhoneMasks'])
  const [phoneMasks, setPhoneMasks] = useState<CountryMask[]>([russiaCountry])

  useEffect(() => {
    if (typeof serverPhoneMasks.data != 'undefined') {
      setPhoneMasks(masks => {
        return [...masks, ...serverPhoneMasks.data]
      })
    }
  }, [serverPhoneMasks.data])

  return (
    <>
      <div
        css={{
          position: 'absolute',
          left: 8,
          top: 25,
          transform: 'translate(0, -50%)',
          zIndex: 2,
          // padding: `4px 8px`,
          border: '1px solid #ced4da',
          display: 'flex',
          gap: 4,
          alignContent: 'center',
          alignItems: 'center',
          // height: 17,

          borderRadius: 2,
        }}
      >
        <Image
          css={
            {
              // border: '1px solid #999',
            }
          }
          width={20}
          src={getPathToFile({ fileName: `${countryMask.flag}`, folderName: '000-flags' })}
        />
        {/* <Text size={'xs'}>{countryMask.code}</Text> */}
        <select
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            zIndex: 5,
            cursor: 'pointer',
          }}
          value={countryMask.iso}
          onChange={event => {
            const newCountry = phoneMasks.find(mask => mask.iso == event.target.value)
            if (newCountry) onChange({ country: newCountry })
          }}
        >
          {phoneMasks.map(mask => (
            <option value={mask.iso}>
              {mask.name} {mask.code}
            </option>
          ))}
        </select>
      </div>
      {/* <Select
        styles={theme => ({
          dropdown: {
            width: 150,
          },
        })}
        onClick={event => {
          event.stopPropagation()
        }}
        size="xs"
        value={value}
        onChange={event => {
          const newCountry = phoneMasks.find(mask => mask.code == value)
          if (newCountry) onChange({ country: newCountry })
        }}
        data={}
      /> */}
    </>
  )
}

export function FakeForm({
  parentColor,

  textColor,
  buttonTextColor,
  onBack,
  radius,

  modelID,
  selectID,
}: {
  parentColor?: string
  textColor?: string
  buttonTextColor?: string
  onBack?: () => void
  radius?: string

  modelID: string
  selectID: 'Form1'
}) {
  const form = useGetSelectData({ modelID, selectID })

  return (
    <>
      <MantineProvider theme={landingTheme({ color: parentColor })}>
        <SimpleGrid
          css={{
            width: '100%',
          }}
          spacing={0}
        >
          {checkIfHTMLTextEmpty(form.title) ? (
            <Box mb={commonMarginBottom}>
              <TextWrap color={textColor} text={form.title} />
            </Box>
          ) : null}
          <form>
            {form.extraInputs.map(extraInput => {
              return (
                <FakeInputForm
                  key={extraInput.id}
                  modelID={extraInput.id}
                  selectID={extraInput.selectID}
                  textColor={textColor}
                  mainColor={parentColor}
                  radius={radius}
                />
              )
            })}

            {onBack ? (
              <Group mb={commonMarginBottom}>
                <BackFormButton
                  onClick={() => {
                    if (onBack) onBack()
                  }}
                  color={textColor}
                />
              </Group>
            ) : null}

            <Button
              css={{
                color: buttonTextColor,
              }}
              {...sendButtonCommonProps({ radius })}
            >
              {form.formButtonText}
            </Button>
          </form>
        </SimpleGrid>
      </MantineProvider>
    </>
  )
}

function FakeInputForm({
  modelID,
  selectID,
  mainColor,
  textColor,
  radius,
}: {
  modelID: string
  selectID: 'ExtraInput1'
  mainColor?: string
  textColor?: string
  radius: string | Interpolation<Theme>
}) {
  const input = useGetSelectData({ modelID, selectID })

  return (
    <ExtraInput
      textColor={textColor}
      color={mainColor}
      placeholder={input.placeholder}
      onChange={({ value }) => {}}
      type={input.type}
      value=""
      error={false}
      radius={radius}
    />
  )
}
