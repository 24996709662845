import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'
import { useServiceState } from 'utils/useServiceState'
import { tprcVanila } from './trpc'

const BoxActiveIDCenter = () => {
  let boxActiveIDs: string[] = []

  type CallbackForChangeEditBoxBackgroundIsOpen = ({}: { is: boolean }) => void

  let listeners: Array<{
    callback: (isActive: boolean) => void
    backgroundBoxEditIsChange?: CallbackForChangeEditBoxBackgroundIsOpen
    onDelete?: () => void
    boxID: string
    id: string
  }> = []

  type ListenerCallback = ({}: { ids: string[] }) => void
  let listenerCallbacks: { callback: ListenerCallback; id: string }[] = []

  return {
    addInside: () => {
      boxActiveIDs.map(boxID => {
        tprcVanila.mutation('user.box.createBox', {
          boxID,
          order: 100,
          positionType: 'Relative',
          mode: activeResponsiveModeCenter.getResponsiveMode(),
        })
      })
    },
    getActiveIDs: () => boxActiveIDs,
    subscribe: ({
      boxID,
      callback,
      backgroundBoxEditIsChange,
      onDelete,
    }: {
      boxID: string
      callback: (isActive: boolean) => void
      onDelete: () => void
      backgroundBoxEditIsChange?: CallbackForChangeEditBoxBackgroundIsOpen
    }) => {
      const id = 'id' + Math.random().toString(16).slice(2)
      listeners.push({ callback, backgroundBoxEditIsChange, id, boxID, onDelete })
      return () => {
        listeners = listeners.filter(boxListener => boxListener.id !== id)
      }
    },
    listener: ({ callback }: { callback: ListenerCallback }) => {
      const id = 'id' + Math.random().toString(16).slice(2)
      listenerCallbacks.push({ callback, id })
      return () => {
        listenerCallbacks = listenerCallbacks.filter(listener => listener.id !== id)
      }
    },
    delete: () => {
      boxActiveIDs.map(boxID => {
        tprcVanila.mutation('user.box.deleteBox', {
          boxID,
        })
      })

      boxActiveIDs = []
    },
    setActiveID: ({ boxID }: { boxID?: string }) => {
      console.log(JSON.stringify(useServiceState.getState(), null, 2))
      const resetList = () => {
        listeners.map(listener => {
          if (boxActiveIDs.includes(listener.boxID)) listener.callback(false)
        })

        boxActiveIDs = []
        listenerCallbacks.map(listenerCallback => listenerCallback.callback({ ids: boxActiveIDs }))
      }

      if (0 && useServiceState.getState().isShiftDown) {
        if (boxID) {
          const idIsInActiveIDs = boxActiveIDs.find(id => id == boxID)
          if (idIsInActiveIDs) {
            listeners.map(listener => {
              if (listener.boxID == boxID) listener.callback(false)
            })
            boxActiveIDs = boxActiveIDs.filter(id => id != boxID)
          } else {
            listeners.map(listener => {
              if (listener.boxID == boxID) listener.callback(true)
            })
            boxActiveIDs = [...boxActiveIDs, boxID]
          }
          listenerCallbacks.map(listenerCallback => listenerCallback.callback({ ids: boxActiveIDs }))
        } else {
          resetList()
        }
      } else {
      }
      if (boxID) {
        listeners.map(listener => {
          if (listener.boxID !== boxID) listener.callback(false)
        })
        boxActiveIDs = [boxID]
        listenerCallbacks.map(listenerCallback => listenerCallback.callback({ ids: boxActiveIDs }))
        listeners.map(listener => {
          if (listener.boxID == boxID) listener.callback(true)
        })
      } else {
        resetList()
      }
    },
    changeEditBoxBackgroundIsOpen: ({ is }: { is: boolean }) => {
      listeners.map(listener => {
        if (boxActiveIDs.includes(listener.boxID)) {
          if (listener.backgroundBoxEditIsChange) listener.backgroundBoxEditIsChange({ is })
        }
      })
    },
  }
}

export const boxActiveIDCenter = BoxActiveIDCenter()
