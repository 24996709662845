export function eventener({ name }: { name: string }): {
  push: () => void
  on: (func: () => void) => () => void
} {
  return {
    push: () => {
      window.dispatchEvent(new CustomEvent(name, { detail: {} }))
    },
    on: func => {
      const eventCallback = () => {
        func()
      }
      window.addEventListener(name, eventCallback)
      return () => {
        window.removeEventListener(name, eventCallback)
      }
    },
  }
}

export function eventenerWithDetail<D>({ name }: { name: string }): {
  push: (detail: D) => void
  on: (func: (detail: D) => void) => () => void
} {
  return {
    push: (detail: D) => {
      window.dispatchEvent(new CustomEvent(name, { detail }))
    },
    on: func => {
      // @ts-ignore
      const eventCallback = event => {
        // @ts-ignore
        func(event.detail)
      }
      window.addEventListener(name, eventCallback)
      return () => {
        window.removeEventListener(name, eventCallback)
      }
    },
  }
}

export const targetsEventener = eventenerWithDetail<{ id: string }>({ name: 'targets' })
