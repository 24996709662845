import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { ActionIcon, Button, Drawer, Grid, Group, HoverCard, Modal, Paper, SimpleGrid, Text, createStyles } from '@mantine/core'
import { useFullscreen } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { ScreeenType } from '@prisma/client'
import {
  IconArrowBackUp,
  IconArrowsDownUp,
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconArrowsMoveVertical,
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDeviceTablet,
  IconExternalLink,
  IconPlus,
  IconX,
} from '@tabler/icons'
import BoxBlock from 'blocks/BoxBlock/BoxBlock'
import { Box } from 'components/Box/Box'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { LayerBox } from 'components/LayerBox/LayerBox'
import { ReduceComponent } from 'components/ReduceComponent/ReduceComponent'
import { Section } from 'components/Section/Section'
import { SiteBlocks } from 'components/SiteBlocks/SiteBlocks'
import { SiteColors } from 'components/SiteColors/SiteColors'
import { SiteEvents } from 'components/SiteEvents/SiteEvents'
import { SiteForms } from 'components/SiteForms/SiteForms'
import { SitePopups } from 'components/SitePopups/SitePopups'
import { SiteQuizzes } from 'components/SiteQuizzes/SiteQuizzes'
import { SiteTargets } from 'components/SiteTargets/SiteTargets'
import { breakpointsBox } from 'config/mq'
import { MyBoxBlockContext, useBoxBlockContext } from 'contexts/BoxBlockContext'
import { SiteContext, SiteContextProvider } from 'contexts/SiteContextProvider'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { List } from 'react-movable'
import useStateRef from 'react-usestateref'
import { NestedBoxSelect, SectionsPinnedSelect, SectionsSelect } from 'server/selects'
import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

export const useMainStyles = createStyles((theme, _params, getRef) => ({
  hideScrollbar: {
    '::-webkit-scrollbar': {
      // display: 'none',
      width: 0,
      height: 0,
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.blue,
    },
  },
}))

const findNestedBox = (box: NestedBoxSelect, boxID: string) => {
  if (box.id == boxID) {
    return box
  } else {
    let nestedBox: NestedBoxSelect | undefined
    box.boxes?.map(box => {
      if (typeof nestedBox == 'undefined') nestedBox = findNestedBox(box, boxID)
    })
    return nestedBox
  }
}

export default function Page() {
  return (
    <>
      <IsLoggedUserWrap fullWidth={true}>
        <SiteContextProvider fullWidth={true}>
          <SitePages />
        </SiteContextProvider>
      </IsLoggedUserWrap>
    </>
  )
}

export function SitePages() {
  const site = useContext(SiteContext)
  const router = useRouter()
  const { pageId } = router.query

  const { classes } = useMainStyles()

  const page = site.pagesV1.find(page => page.id == `${pageId}`)

  const [width, setWidth, widthRef] = useStateRef<number>(0)
  const [widthResponsive, setWidthResponsive, widthResponsiveRef] = useStateRef<number | undefined>(1230)
  const [widthTemporaryResponsive, setWidthTemporaryResponsive, widthTemporaryResponsiveRef] = useStateRef<number | undefined>(
    undefined
  )

  const createBoxBlockWithPagePinAndSectionCreate = trpc.useMutation('user.box.createBoxBlockWithPagePinAndSectionCreate')

  const boxesRef = useRef<HTMLDivElement>(null)
  const boxesWidthRef = useRef<HTMLDivElement>(null)
  const boxesShellRef = useRef<HTMLDivElement>(null)

  const [lineIsShow, setLineIsShow] = useState(true)
  const [responsiveMode, setResponsiveMode, setResponsiveModeRef] = useStateRef<ScreeenType>('Desktop')

  const [arrows, setArrows, arrowsRef] = useStateRef({
    lineIsShow: false,
  })

  useEffect(() => {
    if (arrows.lineIsShow) {
      setLineIsShow(!lineIsShow)
    }
  }, [arrows.lineIsShow])

  useEffect(() => {
    const keydownCallback = (event: KeyboardEvent) => {
      setArrows(arrows => {
        const newArrows = {
          lineIsShow: event.key == 's' ? true : arrows.lineIsShow,
        }
        return newArrows
      })
    }
    const keyupCallback = (event: KeyboardEvent) => {
      setArrows(arrows => {
        const newArrows = {
          lineIsShow: event.key == 's' ? false : arrows.lineIsShow,
        }
        return newArrows
      })
    }

    // boxesShellRef.current?.addEventListener('keydown', keydownCallback)
    // boxesShellRef.current?.addEventListener('keyup', keyupCallback)
    return () => {
      // boxesShellRef.current?.removeEventListener('keydown', keydownCallback)
      // boxesShellRef.current?.removeEventListener('keyup', keyupCallback)
    }
  }, [])

  useEffect(() => {
    if (boxesRef.current) {
      setWidth(boxesRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxesRef.current) {
          const width = Math.ceil(boxesRef.current.offsetWidth)
          if (width != widthRef.current) setWidth(width)
        }
      })
      resizeObserver.observe(boxesRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    let newResponsiveMode: ScreeenType
    if (widthRef.current >= breakpointsBox.desktop) {
      newResponsiveMode = 'Desktop'
    } else if (widthRef.current >= breakpointsBox.tablet) {
      newResponsiveMode = 'Tablet'
    } else {
      newResponsiveMode = 'Mobile'
    }
    if (setResponsiveModeRef.current != newResponsiveMode) setResponsiveMode(newResponsiveMode)
  }, [width])

  useEffect(() => {
    if (boxesWidthRef.current) setWidthResponsive(boxesWidthRef.current.offsetWidth)
  }, [])

  const dragWidthPX = 30

  const settingsWidth = 290
  const layersWidth = 290

  const [sectionsPinnedCollection, setSectionsPinnedCollection, sectionsPinnedCollectionRef] = useStateRef(
    page?.sectionsPinnedCollection
  )

  useEffect(() => {
    setSectionsPinnedCollection(page?.sectionsPinnedCollection)
  }, [page?.sectionsPinnedCollection])

  const updateSectionsPinned = trpc.useMutation('user.updateSectionsPinned')
  const deleteSectionsPinned = trpc.useMutation('user.deleteSectionsPinned')
  const createSectionsPinned = trpc.useMutation('user.createSectionsPinned')
  const copyFavoriteBoxBlock = trpc.useMutation('user.blockCollections.copyFavoriteBoxBlock', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [chooseSectionsOpened, setChooseSectionsOpened] = useState(false)

  const boxHeightRef = useRef<HTMLDivElement>(null)
  const boxHeightBottomRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [heightBottom, setHeightBottom] = useState(0)

  useEffect(() => {
    if (boxHeightRef.current && boxHeightBottomRef.current) {
      setHeight(boxHeightRef.current.offsetHeight)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxHeightRef.current) setHeight(boxHeightRef.current.offsetHeight)
      })
      resizeObserver.observe(boxHeightRef.current)

      setHeightBottom(boxHeightBottomRef.current.offsetHeight)
      const resizeObserverBottom: ResizeObserver = new ResizeObserver(() => {
        if (boxHeightBottomRef.current) setHeightBottom(boxHeightBottomRef.current.offsetHeight)
      })
      resizeObserverBottom.observe(boxHeightBottomRef.current)
      return () => {
        resizeObserver.disconnect()
        resizeObserverBottom.disconnect()
      }
    }
  }, [])

  const entitiesRoutes: { name: string; path: string }[] = [
    { name: 'Секции', path: 'sections' },
    { name: 'Блоки', path: 'blocks' },
    { name: 'Формы', path: 'forms' },
    { name: 'Квизы', path: 'quizzes' },
    { name: 'Всплывашки', path: 'popups' },
    { name: 'События', path: 'events' },
    { name: 'Цели', path: 'targets' },
    { name: 'Цвета', path: 'colors' },
  ]

  const [focusBoxID, setFocusBoxID] = useState('')
  const [focusBoxBlockID, setFocusBoxBlockID] = useState('')

  const [sectionsOpened, setSectionsOpened] = useState(false)
  const [blocksOpened, setBlocksOpened] = useState(false)
  const [eventsOpened, setEventsOpened] = useState(false)
  const [formsOpened, setFormsOpened] = useState(false)
  const [popupsOpened, setPopupsOpened] = useState(false)
  const [quizzessOpened, setQuizzessOpened] = useState(false)
  const [targetsOpened, setTargetsOpened] = useState(false)
  const [colorsOpened, setColorsOpened] = useState(false)

  const { toggle, fullscreen } = useFullscreen()

  const pinBoxBlockFavorite = trpc.useMutation('user.box.pinBoxBlockFavorite')

  const tabHeight = 40
  const responsiveHeight = 40

  const [widthIsDrag, setWidthIsDrag] = useState(false)

  if (page) {
    let focusedBox: undefined | NestedBoxSelect
    page.sectionsPinnedCollection.map(sectionPinnedCollection => {
      sectionPinnedCollection.sectionsCollection.sectionsChild.map(section => {
        if (section.boxBlock && typeof focusedBox == 'undefined') focusedBox = findNestedBox(section.boxBlock.box, focusBoxID)
      })
    })

    return (
      <>
        {sectionsOpened ? (
          <Drawer
            transitionDuration={0}
            position="bottom"
            size="calc(100vh - 50px)"
            css={{ overflowY: 'auto' }}
            opened={sectionsOpened}
            onClose={() => setSectionsOpened(false)}
          >
            <SiteSections />
          </Drawer>
        ) : null}
        {blocksOpened ? (
          <Modal transitionDuration={0} opened={blocksOpened} onClose={() => setBlocksOpened(false)}>
            <SiteBlocks />
          </Modal>
        ) : null}
        {colorsOpened ? (
          <Modal transitionDuration={0} opened={colorsOpened} onClose={() => setColorsOpened(false)}>
            <SiteColors />
          </Modal>
        ) : null}
        {eventsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={eventsOpened} onClose={() => setEventsOpened(false)}>
            <SiteEvents />
          </Modal>
        ) : null}
        {formsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={formsOpened} onClose={() => setFormsOpened(false)}>
            <SiteForms />
          </Modal>
        ) : null}
        {popupsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={popupsOpened} onClose={() => setPopupsOpened(false)}>
            <SitePopups />
          </Modal>
        ) : null}
        {quizzessOpened ? (
          <Modal transitionDuration={0} size="md" opened={quizzessOpened} onClose={() => setQuizzessOpened(false)}>
            <SiteQuizzes />
          </Modal>
        ) : null}
        {targetsOpened ? (
          <Modal transitionDuration={0} size="xl" opened={targetsOpened} onClose={() => setTargetsOpened(false)}>
            <SiteTargets />
          </Modal>
        ) : null}
        <div css={{ padding: '4px' }} ref={boxHeightRef}>
          <div
            css={{
              width: `calc(100% - ${settingsWidth}px - ${layersWidth}px)`,
              margin: '0 auto',
              padding: '0 8px',
            }}
          ></div>
        </div>
        <div style={{ height: `calc(100% - ${height + heightBottom}px)` }}>
          <MyBoxBlockContext.Provider
            value={{
              lineIsShow,
              setLineIsShow,
              responsiveMode,
              setResponsiveMode,
              focusBoxID: focusBoxID,
              setFocusBoxID,
              focusBoxBlockID,
              setFocusBoxBlockID,
            }}
          >
            <div
              ref={boxesShellRef}
              style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                maxHeight: '100%',
                // borderTop: '1px solid #EFEFEF',
              }}
            >
              <div
                className={classes.hideScrollbar}
                css={{
                  width: layersWidth,
                  padding: '8px 0',
                  height: 'calc(100% - 20px)',
                  // padding: 12,
                  overflowY: 'auto',
                  borderTop: '1px solid #EFEFEF',
                  borderBottom: '1px solid #EFEFEF',
                }}
              >
                <div>
                  {page.sectionsPinnedCollection.map(sectionsPinnedCollection => {
                    return sectionsPinnedCollection.sectionsCollection.sectionsChild.map(sectionChild => {
                      return sectionChild.boxBlock ? (
                        <LayerBox
                          boxBlock={sectionChild.boxBlock}
                          boxBlockID={sectionChild.boxBlock.id}
                          key={sectionChild.id}
                          indent={1}
                          box={sectionChild.boxBlock.box}
                        />
                      ) : undefined
                    })
                  })}
                </div>
              </div>

              <div
                css={{
                  width: `calc(100% - ${settingsWidth}px - ${layersWidth}px)`,
                  height: '100%',
                  maxHeight: '100%',
                  position: 'relative',
                  boxShadow:
                    '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 22px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px',
                  borderRadius: '4px',
                  border: '1px solid #EFEFEF',
                  overflow: 'hidden',
                }}
              >
                <div
                  css={{
                    width: `100%`,
                    height: tabHeight,
                    background: '#3791f0',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 12px',
                  }}
                >
                  {/* <Group px={8} spacing={4}>
                    {Array.from({ length: 3 }, (_, i) => i + 1).map((key, index) => (
                      <div
                        css={{
                          width: 10,
                          height: 10,
                          background: 'white',
                          borderRadius: '50%',
                        }}
                      ></div>
                    ))}
                  </Group> */}

                  <Group
                    css={{
                      width: '100%',
                    }}
                    position="apart"
                  >
                    <Group>
                      <Link href={`/panel/`}>
                        {/* <Image css={{ cursor: 'pointer' }} width={20} src={getPathToFile({ fileName: '000-favicon.svg', folderName: null })} />
                         */}
                        <Text size="lg" css={{ fontWeight: 'bold', cursor: 'pointer', color: 'white' }}>
                          VINGLA
                        </Text>
                      </Link>
                      <IconArrowBackUp
                        css={{ cursor: 'pointer' }}
                        onClick={() => router.push(`/panel/sites/${site.id}/pages`)}
                        width={20}
                        color={'white'}
                      />
                    </Group>

                    <div
                      css={{
                        background: `rgba(255,255,255, 0.1)`,
                        borderRadius: '3px',
                        padding: '4px 7px',
                        cursor: 'pointer',
                        transition: '0.1s',
                        ':hover': {
                          background: `rgba(255,255,255, 0.4)`,
                          transform: 'scale(1.05)',
                        },
                      }}
                      onClick={() => {
                        setLineIsShow(lineIsShow => !lineIsShow)
                      }}
                    >
                      <Text color={'white'} size={'xs'}>
                        {lineIsShow ? 'Скрыть линии' : 'Показать линии'}
                      </Text>
                    </div>

                    <Group
                      css={{
                        alignItems: 'center',
                      }}
                      spacing={7}
                    >
                      <Group
                        onClick={() => setWidthResponsive(499)}
                        css={{
                          background: `rgba(255,255,255, ${responsiveMode == 'Mobile' ? '0.3' : '0.1'})`,
                          borderRadius: '3px',
                          padding: '4px 7px',
                          cursor: 'pointer',
                          ':hover': {
                            background: `rgba(255,255,255, ${responsiveMode == 'Mobile' ? '0.4' : '0.2'})`,
                          },
                        }}
                        spacing={6}
                      >
                        <div
                          css={{
                            background: 'rgba(255,255,255, 0.1)',
                            padding: 3,
                            width: 23,
                            height: 23,
                          }}
                        >
                          <IconDeviceMobile
                            css={{
                              display: 'inline-block',
                            }}
                            size={18}
                            color={'white'}
                          />
                        </div>
                        <Text color={'white'} size={'xs'}>
                          499px {'<'}
                        </Text>
                      </Group>

                      <Group
                        onClick={() => setWidthResponsive(1199)}
                        css={{
                          background: `rgba(255,255,255, ${responsiveMode == 'Tablet' ? '0.3' : '0.1'})`,
                          borderRadius: '3px',
                          padding: '4px 7px',
                          cursor: 'pointer',
                          ':hover': {
                            background: `rgba(255,255,255, ${responsiveMode == 'Tablet' ? '0.4' : '0.2'})`,
                          },
                        }}
                        spacing={6}
                      >
                        <div
                          css={{
                            background: 'rgba(255,255,255, 0.1)',
                            padding: 3,
                            width: 23,
                            height: 23,
                          }}
                        >
                          <IconDeviceTablet
                            css={{
                              display: 'inline-block',
                            }}
                            size={18}
                            color={'white'}
                          />
                        </div>
                        <Text color={'white'} size={'xs'}>
                          {'>='} 500px - 1199px {'<'}
                        </Text>
                      </Group>

                      <Group
                        onClick={() => setWidthResponsive(1920)}
                        css={{
                          background: `rgba(255,255,255, ${responsiveMode == 'Desktop' ? '0.3' : '0.1'})`,
                          borderRadius: '3px',
                          padding: '4px 7px',
                          cursor: 'pointer',
                          ':hover': {
                            background: `rgba(255,255,255, ${responsiveMode == 'Desktop' ? '0.4' : '0.2'})`,
                          },
                        }}
                        spacing={6}
                      >
                        <div
                          css={{
                            background: 'rgba(255,255,255, 0.1)',
                            padding: 3,
                            width: 23,
                            height: 23,
                          }}
                        >
                          <IconDeviceDesktop
                            css={{
                              display: 'inline-block',
                            }}
                            size={18}
                            color={'white'}
                          />
                        </div>
                        <Text color={'white'} size={'xs'}>
                          {'>='} 1200px
                        </Text>
                      </Group>

                      {/* <Divider size={4} color={'black'} orientation="vertical" /> */}

                      <Text
                        css={{
                          fontWeight: 'bold',
                          minWidth: 100,
                        }}
                        color={'white'}
                      >
                        {' - '}
                        {widthResponsive
                          ? `${widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive} px`
                          : null}{' '}
                      </Text>
                    </Group>

                    <div>
                      {site.domain ? (
                        <a
                          target={'_blank'}
                          href={`${site.domain.domain.includes('localhost') ? 'http' : 'https'}://${site.domain.domain}/${
                            page.pathname
                          }`}
                          css={{
                            ':hover': {
                              opacity: 0.5,
                            },
                            textDecoration: 'none',
                          }}
                        >
                          <Group spacing={6}>
                            <Text
                              css={{
                                fontWeight: 'bold',
                              }}
                              color={'white'}
                              size={'xs'}
                            >
                              {site.domain.domain}/{page.pathname}
                            </Text>
                            <IconExternalLink width={16} color="white" />
                          </Group>
                        </a>
                      ) : null}
                    </div>
                  </Group>
                </div>
                <div
                  css={{
                    width: `100%`,
                    height: responsiveHeight,
                    // background: '#3791f0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid #EFEFEF',
                  }}
                >
                  <div
                    style={{
                      width: dragWidthPX,
                      minWidth: dragWidthPX,
                      borderLeft: '1px solid #EFEFEF',
                      borderRight: '1px solid #EFEFEF',
                      height: '100%',
                      // background: colors.blue,
                    }}
                  >
                    <DragOffsetPosition
                      onChangePosition={({ offsetPosition }) => {
                        const pixelDifferentWidthPrevPixel = (widthResponsiveRef.current || 0) - offsetPosition.x * 2
                        const limitPixelDifferentWidthPrevPixel =
                          pixelDifferentWidthPrevPixel > 320 ? pixelDifferentWidthPrevPixel : 320
                        const resultLimitPixelDifferentWidthPrevPixel = limitPixelDifferentWidthPrevPixel
                        setWidthTemporaryResponsive(resultLimitPixelDifferentWidthPrevPixel)

                        return {
                          resultSizes: {
                            x: resultLimitPixelDifferentWidthPrevPixel,
                          },
                        }
                      }}
                      onEndChangePosition={({ resultPosition }) => {
                        setWidthTemporaryResponsive(undefined)
                        if (resultPosition.x) setWidthResponsive(resultPosition.x)
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'ew-resize',
                      }}
                      onDrag={({ is }) => {
                        setWidthIsDrag(is)
                      }}
                    >
                      <IconArrowsDownUp
                        css={{
                          transform: 'rotate(90deg)',
                          pointerEvents: 'none',
                        }}
                        color={colors.blue}
                        size={24}
                      />
                      {/* <div
                    style={{
                      width: 4,
                      height: 'calc(100% - 10px)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background:
                        responsiveMode == 'Desktop' ? '#0086ff' : responsiveMode == 'Tablet' ? '#ffb800' : '#81ff9d',
                      zIndex: 2,
                    }}
                  /> */}
                    </DragOffsetPosition>
                  </div>

                  <div
                    style={{
                      width: widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive,
                      maxWidth: '100%',
                      minWidth: '320px',
                      display: 'flex',
                      height: '100%',
                    }}
                  ></div>
                  <div
                    style={{
                      width: dragWidthPX,
                      minWidth: dragWidthPX,
                      borderLeft: '1px solid #EFEFEF',
                      borderRight: '1px solid #EFEFEF',
                      height: '100%',
                    }}
                  >
                    <DragOffsetPosition
                      onChangePosition={({ offsetPosition }) => {
                        const pixelDifferentWidthPrevPixel = (widthResponsiveRef.current || 0) + offsetPosition.x * 2
                        const limitPixelDifferentWidthPrevPixel =
                          pixelDifferentWidthPrevPixel > 320 ? pixelDifferentWidthPrevPixel : 320
                        const resultLimitPixelDifferentWidthPrevPixel = limitPixelDifferentWidthPrevPixel
                        setWidthTemporaryResponsive(resultLimitPixelDifferentWidthPrevPixel)

                        return {
                          resultSizes: {
                            x: resultLimitPixelDifferentWidthPrevPixel,
                          },
                        }
                      }}
                      onEndChangePosition={({ resultPosition }) => {
                        setWidthTemporaryResponsive(undefined)
                        if (resultPosition.x) setWidthResponsive(resultPosition.x)
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'ew-resize',
                      }}
                      onDrag={({ is }) => {
                        setWidthIsDrag(is)
                      }}
                    >
                      <IconArrowsDownUp
                        css={{
                          transform: 'rotate(90deg)',
                          pointerEvents: 'none',
                        }}
                        color={colors.blue}
                        size={24}
                      />
                      {/* <div
                    style={{
                      width: 4,
                      height: 'calc(100% - 10px)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background:
                        responsiveMode == 'Desktop' ? '#0086ff' : responsiveMode == 'Tablet' ? '#ffb800' : '#81ff9d',
                      zIndex: 2,
                    }}
                  /> */}
                    </DragOffsetPosition>
                  </div>
                </div>
                <div
                  css={{
                    width: `100%`,
                    height: `calc(100% - ${tabHeight + responsiveHeight}px)`,
                    maxHeight: `calc(100% - ${tabHeight + responsiveHeight}px)`,
                    position: 'relative',
                  }}
                >
                  <div
                    css={{
                      zIndex: 2,
                      position: 'absolute',
                      width:
                        responsiveMode == 'Mobile'
                          ? breakpointsBox.mobile
                          : responsiveMode == 'Tablet'
                          ? breakpointsBox.tablet
                          : breakpointsBox.desktop,
                      height: '100%',
                      top: 0,
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                      borderRight: '1px dashed red',
                      borderLeft: '1px dashed red',
                      pointerEvents: 'none',
                      opacity: 0.3,
                    }}
                  />

                  {responsiveMode == 'Desktop' ? (
                    <div
                      css={{
                        zIndex: 2,
                        position: 'absolute',
                        width: 1920,
                        height: '100%',
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        borderRight: '1px dashed red',
                        borderLeft: '1px dashed red',
                        pointerEvents: 'none',
                        opacity: 0.3,
                      }}
                    />
                  ) : null}
                  <div
                    css={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                      background: '#80808005',
                    }}
                    className={classes.hideScrollbar}
                  >
                    <div
                      className={classes.hideScrollbar}
                      ref={boxesWidthRef}
                      style={{
                        width: widthTemporaryResponsive ? widthTemporaryResponsive : widthResponsive,
                        maxWidth: '100%',
                        minWidth: '320px',
                        height: '100%',
                        maxHeight: '100%',
                      }}
                    >
                      <div
                        className={classes.hideScrollbar}
                        ref={boxesRef}
                        style={{
                          width: `100%`,
                          boxShadow: '0px 0px 19px 4px rgb(0, 0, 0, 0.11)',
                          background: 'white',
                        }}
                      >
                        <DndContext
                          onDragEnd={async event => {
                            const { active, over } = event

                            if (over) {
                              if (active.id !== over.id) {
                                setSectionsPinnedCollection(sectionsPinnedCollection => {
                                  if (sectionsPinnedCollectionRef.current && sectionsPinnedCollection) {
                                    const oldIndex = sectionsPinnedCollection.findIndex(
                                      sectionPinnedCollection => sectionPinnedCollection.id == active.id
                                    )
                                    const newIndex = sectionsPinnedCollection.findIndex(
                                      sectionPinnedCollection => sectionPinnedCollection.id == over.id
                                    )

                                    if (newIndex < 0 || oldIndex < 0) {
                                      showNotification({
                                        title: 'Неудачное перетаскивание, попробуйте снова',
                                        message: '',
                                        color: 'red',
                                      })
                                      return sectionsPinnedCollection
                                    }

                                    if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
                                      const updatedBoxes = arrayMove(sectionsPinnedCollection, oldIndex, newIndex)

                                      updatedBoxes.map(async (sectionPinned, index) => {
                                        await updateSectionsPinned.mutate({
                                          sectionsPinnedID: sectionPinned.id,
                                          order: index,
                                        })
                                      })

                                      return updatedBoxes
                                    }

                                    return sectionsPinnedCollection
                                  }
                                })
                              }
                            }
                          }}
                        >
                          {sectionsPinnedCollection
                            ? sectionsPinnedCollection.map(sectionsPinned => {
                                return (
                                  <SectionCollection
                                    onUnpin={() => {
                                      setSectionsPinnedCollection(sectionsPinnedCollection => {
                                        return Array.isArray(sectionsPinnedCollection)
                                          ? sectionsPinnedCollection.filter(
                                              sectionPinnedCollection => sectionPinnedCollection.id !== sectionsPinned.id
                                            )
                                          : sectionsPinnedCollection
                                      })
                                      deleteSectionsPinned.mutate({
                                        sectionsPinnedID: sectionsPinned.id,
                                      })
                                    }}
                                    key={sectionsPinned.id}
                                    parentBoxWidth={width}
                                    sectionPinnedCollection={sectionsPinned}
                                    widthIsDrag={widthIsDrag}
                                  />
                                )
                              })
                            : undefined}
                        </DndContext>
                        <Group p={'xs'} css={{ width: '100%' }} position="center">
                          <ActionIcon title="Добавить набор секций" onClick={() => setChooseSectionsOpened(true)}>
                            <IconPlus color={colors.blue} />
                          </ActionIcon>
                        </Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <DndContext>
                <div
                  className={classes.hideScrollbar}
                  css={{
                    width: settingsWidth,
                    height: 'calc(100% - 20px)',
                    // maxHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    // borderLeft: '1px solid #EFEFEF',

                    borderTop: '1px solid #EFEFEF',
                    borderBottom: '1px solid #EFEFEF',
                  }}
                  id="box-settings"
                />
              </DndContext>
            </div>
          </MyBoxBlockContext.Provider>

          {chooseSectionsOpened ? (
            <Drawer
              transitionDuration={0}
              css={{
                overflowY: 'auto',
              }}
              onClose={() => setChooseSectionsOpened(false)}
              position="bottom"
              size="calc(100vh - 50px)"
              opened={chooseSectionsOpened}
            >
              <SimpleGrid p="xs" cols={1}>
                <Group position="right">
                  <Button
                    mb="xs"
                    onClick={() => {
                      setChooseSectionsOpened(false)
                      createBoxBlockWithPagePinAndSectionCreate.mutate({
                        pageID: page.id,
                        siteID: site.id,
                        order: sectionsPinnedCollectionRef?.current?.length || 100,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                    }}
                  >
                    Добавить чистый блок
                  </Button>
                </Group>
                <SimpleGrid
                  css={{
                    alignItems: 'flex-start',
                  }}
                  spacing={50}
                  cols={2}
                >
                  <SimpleGrid cols={1}>
                    <Text>Наборы секций с этого сайта</Text>
                    <SimpleGrid cols={3}>
                      {site.sectionsCollection.map(section => (
                        <div
                          key={section.id}
                          //  css={{ cursor: 'pointer' }}
                        >
                          <SimpleGrid spacing={6} cols={1}>
                            {section.sectionsChild.map(sectionChild => (
                              <ReduceComponent component={<Section key={sectionChild.id} {...sectionChild} />} />
                            ))}
                            <Group spacing={4}>
                              <Button
                                onClick={() => {
                                  createSectionsPinned.mutate({
                                    pageID: page.id,
                                    sectionsID: section.id,
                                    order: sectionsPinnedCollectionRef?.current?.length || 100,
                                  })
                                  setChooseSectionsOpened(false)
                                }}
                                compact
                                fullWidth
                              >
                                Дублировать
                              </Button>{' '}
                              <Button fullWidth compact>
                                Копировать (не работает)
                              </Button>
                            </Group>
                          </SimpleGrid>
                        </div>
                      ))}
                    </SimpleGrid>
                  </SimpleGrid>
                  <SimpleGrid cols={1}>
                    <Text>Блоки из коллекции со всех сайтов</Text>
                    <SimpleGrid cols={3}>
                      {site.user.favoriteBoxBlocks.map(boxBlock => (
                        <div
                          key={boxBlock.id}

                          // css={{ cursor: 'pointer' }}
                        >
                          <SimpleGrid spacing={6} cols={1}>
                            <ReduceComponent component={<BoxBlock key={boxBlock.id} {...boxBlock.box} />} />
                            {/* <Button
                              onClick={() => {
                                setChooseSectionsOpened(false)
                                copyFavoriteBoxBlock.mutate({
                                  boxBlockID: boxBlock.id,
                                  pageID: page.id,
                                })
                              }}
                              compact
                            >
                              Копировать
                            </Button> */}
                          </SimpleGrid>
                        </div>
                      ))}
                    </SimpleGrid>
                  </SimpleGrid>
                </SimpleGrid>
              </SimpleGrid>
            </Drawer>
          ) : null}
        </div>

        <div
          css={{
            padding: '12px',
            // borderTop: '1px solid #EFEFEF'
          }}
          ref={boxHeightBottomRef}
        >
          <div
            css={{
              display: 'flex',
            }}
          >
            <div
              css={{
                width: settingsWidth,
              }}
            >
              {/* <Text size={12} color={'red'}>
                Многие функции бокса теперь <br /> открываются правой кнопкой мыши
              </Text> */}
            </div>
            <Group
              position="center"
              css={{
                width: `calc(100% - ${settingsWidth}px - ${layersWidth}px)`,
              }}
            >
              {entitiesRoutes.map(entity => (
                <div
                  css={{
                    padding: '6px 12px',
                    background: colors.blue,
                    borderRadius: 2,
                    cursor: 'pointer',
                    transition: '0.1s',
                    ':hover': {
                      opacity: 0.75,
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => {
                    switch (entity.path) {
                      case 'sections':
                        setSectionsOpened(true)
                        break
                      case 'blocks':
                        setBlocksOpened(true)
                        break
                      case 'events':
                        setEventsOpened(true)
                        break
                      case 'forms':
                        setFormsOpened(true)
                        break
                      case 'popups':
                        setPopupsOpened(true)
                        break
                      case 'quizzes':
                        setQuizzessOpened(true)
                        break
                      case 'targets':
                        setTargetsOpened(true)
                        break
                      case 'colors':
                        setColorsOpened(true)
                        break

                      default:
                        break
                    }
                  }}
                >
                  <Text color={'white'} size={'sm'}>
                    {entity.name}
                  </Text>
                </div>
              ))}
            </Group>
            <Group
              position="right"
              css={{
                alignItems: 'center',
                width: layersWidth,
              }}
            >
              <div>
                {fullscreen ? (
                  <IconArrowsMinimize
                    css={{
                      display: 'block',
                    }}
                    onClick={toggle}
                    size={20}
                  />
                ) : (
                  <IconArrowsMaximize
                    css={{
                      display: 'block',
                    }}
                    onClick={toggle}
                    size={20}
                  />
                )}
              </div>
            </Group>
          </div>
        </div>
      </>
    )
  }

  return <> {pageId}</>
}

function SectionCollection({
  parentBoxWidth,
  sectionPinnedCollection,
  onUnpin,
  widthIsDrag,
}: {
  onUnpin: () => void
  sectionPinnedCollection: NonNullable<SectionsPinnedSelect>
  parentBoxWidth: number
  widthIsDrag: boolean
}) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: sectionPinnedCollection.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: sectionPinnedCollection.id,
  })
  const deleteSectionsPinned = trpc.useMutation('user.deleteSectionsPinned')

  const { responsiveMode } = useBoxBlockContext()

  return (
    <div
      ref={setNodeRef}
      style={{
        width: '100%',
        position: 'relative',
        ...styleDrag,
      }}
    >
      <div
        ref={setNodeRefDrop}
        style={{
          // border: `1px solid ${isOver ? 'purple' : '#85c2f9'}`,
          transform: `scale(${isOver ? '0.95' : '1'})`,
          transition: 'transform 0.3s',
        }}
      >
        <div css={{ position: 'absolute', right: 10, top: 10, zIndex: 10, display: 'flex', gap: 10 }}>
          <Paper shadow="md">
            <ActionIcon color="grape" variant="light" {...listeners} {...attributes}>
              <IconArrowsMoveVertical style={{ cursor: 'ns-resize' }} />
            </ActionIcon>
          </Paper>

          <Paper shadow="md">
            <ActionIcon color="red" variant="light">
              <IconX
                css={{
                  cursor: 'not-allowed',
                }}
                onClick={() => onUnpin()}
                width={20}
              />
            </ActionIcon>
          </Paper>
        </div>
        <DndContext>
          {sectionPinnedCollection.sectionsCollection.sectionsChild.map(section => {
            if (section.boxBlock) {
              return (
                <InView>
                  {({ ref, inView }) => (
                    <div ref={ref}>
                      {section.boxBlock ? (
                        inView && !widthIsDrag ? (
                          <Box
                            key={section.id}
                            onAddBox={({ box }) => {
                              // setBoxes(boxes => {
                              //   return Array.isArray(boxes) ? [...boxes, box] : boxes
                              // })
                            }}
                            onDeleteBox={({ id }) => {
                              // setBoxes(boxes.filter(box => box.id !== id))
                            }}
                            parentBoxWidth={parentBoxWidth}
                            box={section.boxBlock.box}
                            boxBlockID={section.boxBlock.id}
                          />
                        ) : (
                          <BoxBlock responsiveMode={responsiveMode} {...section.boxBlock.box} />
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </InView>
              )
            }
            return <div key={section.id}>Не бокс</div>
          })}
        </DndContext>
      </div>
    </div>
  )
}

function SiteSections() {
  const site = useContext(SiteContext)
  const createSections = trpc.useMutation('user.createSections')

  return (
    <>
      <Button
        loading={createSections.isLoading}
        m={30}
        compact
        onClick={() =>
          createSections.mutate({
            siteID: site.id,
          })
        }
      >
        Создать набор секций
      </Button>

      <div
        css={{
          padding: '0 0 0 30px',
          overflowX: 'scroll',
          overflowY: 'hidden',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {site.sectionsCollection.map(sectionsCollection => (
          <SectionsCollection key={sectionsCollection.id} {...sectionsCollection} />
        ))}
      </div>
    </>
  )
}

function SectionsCollection(sectionsCollection: NonNullable<SectionsSelect>) {
  const site = useContext(SiteContext)
  const [createOpened, setCreateOpened] = useState(false)

  const deleteSections = trpc.useMutation('user.deleteSections')
  const createSection = trpc.useMutation('user.createSection')
  const updateSection = trpc.useMutation('user.updateSection')
  const deleteSection = trpc.useMutation('user.deleteSection')

  return (
    <>
      <div css={{ minWidth: '500px', marginRight: '80px' }}>
        <Group>
          <Button mb="xs" onClick={() => setCreateOpened(true)}>
            Добавить блок
          </Button>
          <Button
            color="red"
            loading={deleteSections.isLoading}
            mb="xs"
            onClick={() => deleteSections.mutate({ sectionsID: sectionsCollection.id })}
          >
            Удалить набор
          </Button>
        </Group>

        <List
          lockVertically
          values={sectionsCollection.sectionsChild}
          onChange={({ oldIndex, newIndex }) => {
            // TODO это пиздец какой костыль массивом проходиться и сохранять порядок
            arrayMove(sectionsCollection.sectionsChild, oldIndex, newIndex).map(async (sectionChild, index) => {
              await updateSection.mutate({
                sectionID: sectionChild.id,
                order: index,
              })
            })
          }}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
              }}
              key={value.id}
              {...props}
            >
              <Grid columns={12}>
                <Grid.Col span={8}>
                  <ReduceComponent component={<Section {...value} />} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button
                    color="red"
                    loading={deleteSection.isLoading}
                    onClick={() => deleteSection.mutate({ sectionID: value.id })}
                  >
                    Открепить
                  </Button>
                  {value.popupOpen ? (
                    <Button
                      loading={updateSection.isLoading}
                      onClick={() =>
                        updateSection.mutate({
                          sectionID: value.id,
                          popupID: '',
                        })
                      }
                    >
                      Открепить попап
                    </Button>
                  ) : (
                    <HoverCard position="right" width={280} shadow="md">
                      <HoverCard.Target>
                        <Button>Открывать попап</Button>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <SimpleGrid cols={1}>
                          {site.popupsV1.map(popup => (
                            <Button
                              key={popup.id}
                              compact
                              fullWidth
                              loading={updateSection.isLoading}
                              onClick={() =>
                                updateSection.mutate({
                                  sectionID: value.id,
                                  popupID: popup.id,
                                })
                              }
                            >
                              {popup.quiz ? `Квиз: ${popup.quiz.title}` : ''}
                              {popup.form ? `Форма: ${popup.form.title}` : ''}
                            </Button>
                          ))}
                        </SimpleGrid>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  )}
                </Grid.Col>
              </Grid>
            </div>
          )}
        />
      </div>

      {createOpened ? (
        <Drawer
          transitionDuration={0}
          size="calc(100vh - 50px)"
          opened={createOpened}
          onClose={() => setCreateOpened(false)}
          padding="md"
          title="Добавить блок"
        >
          <div css={{ maxHeight: '100%', overflowY: 'auto' }}>
            <div css={{}}>
              {site.boxBlocks.map(boxBlock => (
                <div
                  key={boxBlock.id}
                  onClick={() =>
                    createSection.mutate({
                      sectionsID: sectionsCollection.id,
                      blockID: boxBlock.id,
                      type: 'box',
                    })
                  }
                  css={{ cursor: 'pointer' }}
                >
                  <ReduceComponent component={<BoxBlock {...boxBlock.box} />} />
                </div>
              ))}
            </div>
          </div>
        </Drawer>
      ) : null}
    </>
  )
}
