import { ActionIcon, Badge, Container, Grid, Group, Paper, SimpleGrid, Text, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconX } from '@tabler/icons'

import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'

import { SyncSiteContextProvider, useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { frontendCallbackDataCenterSite } from 'generation/synchronFrontendAtSite'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { SiteMenu } from 'syncComponents/SiteMenu/SiteMenu'

import { ruMoment } from 'utils/ruMoment'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip as TooltipChart, XAxis, YAxis } from 'recharts'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

import { Favicon } from 'utils/Favicon'

export default function () {
  const { classes } = useMainStyles()
  return (
    <>
      <Head>
        <title>Заявки</title>

        <Favicon />
      </Head>
      <IsLoggedUserWrap>
        <div
          css={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
          className={classes.hideScrollbar}
        >
          <PageSyncMemo />
        </div>
      </IsLoggedUserWrap>
    </>
  )
}

const PageSyncMemo = React.memo(
  () => {
    return (
      <SyncSiteContextProvider>
        <PageSync />
      </SyncSiteContextProvider>
    )
  },
  (prevProps, nextProps) => {
    return true
  }
)

export function PageSync() {
  const { siteID } = useSyncSiteContext()

  return (
    <>
      <SiteMenu activeTab="leads">
        <Container>
          <SiteLeads />
        </Container>
      </SiteMenu>
    </>
  )
}

export const SiteLeadsMemo = React.memo(SiteLeads, () => true)
function SiteLeads({ onClose }: { onClose?: () => void }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const [data, setData] = useState<{ name: string; заявки: number }[]>([
    // { name: '1 Июня', заявки: 4, посетители: 81, amt: 2400 },
    // { name: '2 Июня', заявки: 6, посетители: 13, amt: 2400 },
    // { name: '3 Июня', заявки: 2, посетители: 87, amt: 2400 },
    // { name: '4 Июня', заявки: 1, посетители: 46, amt: 2400 },
    // { name: '5 Июня', заявки: 0, посетители: 14, amt: 2400 },
    // { name: '6 Июня', заявки: 10, посетители: 20, amt: 2400 },
  ])

  useEffect(() => {
    let newData: { name: string; заявки: number }[] = []

    const today = new Date()
    for (let index = 0; index < 31; index++) {
      newData = [
        {
          name: ruMoment(today)
            .add('hours', -24 * index)
            .format('DD.MM'),
          заявки: 0,
        },
        ...newData,
      ]
      // date = ruMoment(date).add('hours', 24).toDate()
    }

    site.leadsV1.map((lead, index) => {
      const leadStore = frontendCallbackDataCenterSite.get({ modelID: lead.id, selectID: lead.selectID })
      const date = ruMoment(leadStore.createdAt).format('DD.MM')
      const lineIsExist = newData.find(line => line.name == date)
      if (lineIsExist) {
        newData = newData.map(line => (line.name == date ? { ...line, заявки: line.заявки + 1 } : line))
      } else {
        // newData = [{ name: date, заявки: 1 }, ...newData]
      }
    })
    setData(newData)
  }, [site.leadsV1])

  return (
    <SimpleGrid cols={1}>
      <Group mb={cabinetSizes.headlineBottom} position="apart">
        <Title order={2}>ЗАЯВКИ</Title>
        {onClose ? (
          <ActionIcon
            onClick={() => {
              if (onClose) onClose()
            }}
          >
            <IconX />
          </ActionIcon>
        ) : null}
      </Group>

      {1 ? null : (
        <Grid.Col span={1}>
          <Paper p="xs">
            <Text mb={'xs'}>Заявки за последний месяц</Text>
            <ResponsiveContainer width="100%" height={150}>
              <LineChart width={1000} height={150} data={data}>
                <Line type="monotone" dataKey="заявки" stroke={colors.blue} />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis />
                <TooltipChart />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid.Col>
      )}

      <SimpleGrid spacing={12}>
        {site.leadsV1.length ? (
          site.leadsV1.map(lead => <SiteLead key={lead.id} leadID={lead.id} selectID={lead.selectID} />)
        ) : (
          <Text size={'md'}>Заявок пока нет</Text>
        )}
      </SimpleGrid>
    </SimpleGrid>
  )
}

const SiteLead = ({ leadID, selectID }: { leadID: string; selectID: 'Lead1' }) => {
  const lead = useGetSelectData({ modelID: leadID, selectID })

  return (
    <Paper p="xs">
      <Grid
        css={{
          alignContent: 'flex-start',
          alignItems: 'start',
        }}
        columns={10}
      >
        <Grid.Col sm={6} span={10}>
          <Group
            css={{
              background: cabinetColors.paperInner,
              borderRadius: cabinetSizes.radius,
            }}
            p="md"
          >
            <Text
              css={{
                whiteSpace: 'pre-wrap',
              }}
              size="xl"
            >
              {lead.information}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col sm={4} span={10}>
          <Group p={'xs'} position="right">
            {lead.AmazonEmailMessageId ? (
              <Group position="right">
                <Badge mt="xs" variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                  Отправлено по почте
                </Badge>
              </Group>
            ) : null}
            <Text size={'md'}>{ruMoment(lead.createdAt).format('LLLL')}</Text>
          </Group>
        </Grid.Col>
      </Grid>
    </Paper>
  )
}
