import { Theme } from '@emotion/react'
import { useMantineTheme } from '@mantine/core'
import { ScreeenType } from '@prisma/client'
import { CodeComponentMemo } from 'blocks/CodeComponent/CodeComponent'
import { FaqComponent } from 'blocks/FAQBlockV1/FAQBlockV1'

import { VideoComponent } from 'blocks/VideoBlockV1/VideoBlockV1'
import { transformBoxPosition, transformBoxWidth } from 'components/Box/Box'
import { Form } from 'components/Form/Form'
import { ImagePreview } from 'components/ImagePreview/ImagePreview'
import Quiz from 'components/Quiz/Quiz'
import { TransofrmTextContentHTML } from 'components/TransofrmTextContentHTML/TransofrmTextContentHTML'
import { mqBox } from 'config/mq'
import useEmblaCarousel from 'embla-carousel-react'
import { memo, useCallback, useEffect, useState } from 'react'
import {
  BoxSettingsSelect,
  LinkSelect,
  NestedBoxSelect,
  NestedTableDataSelect,
  ResponsiveBoxSettingsSelect,
} from 'server/selects'
import { clearHTMLTags } from 'utils/clearHTMLTags'
import { getPathToFile } from 'utils/getPathToFile'
import { popupActiveCenter } from 'utils/popupActiveCenter'

import { MapComponentNoSSR } from 'blocks/MapComponent/MapComponentNoSSR'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'

export const getUrlToPageFromLink = ({
  link,
  parentTableID,
  parentRowSlug,
}: {
  link: NonNullable<LinkSelect>
  parentTableID?: string
  parentRowSlug?: string
}) => {
  if (link.page) {
    let rowSlug = ''
    try {
      if (link.page.pinTableID && parentTableID && parentRowSlug && link.page.pinTableID == parentTableID) {
        rowSlug = `/${parentRowSlug}`
      }
    } catch (error) {}
    return `/${link.page.pathname}${rowSlug}${
      link.sectionCollection && link.section ? `#${link.sectionCollection.id}-${link.section.id}` : '' // #top
    }`
  }
  return ''
}

export const pushOpenWindowFromLink = ({ link, popupData }: { link: NonNullable<LinkSelect>; popupData?: string }) => {
  if (link.popup) {
    popupActiveCenter.setPopupID({
      popupID: link.popup.id,
      data: popupData ? popupData : link.popupData,
      isCenter: link.popup.horizontalPosition == 'Center' && link.popup.verticalPosition == 'Center',
    })
    // eventener({ name: link.popup.id }).push()
  }
}

const arrowSlider = ({ onClick, rotate }: { rotate?: boolean; color?: string; onClick?: () => void }) => {
  const theme = useMantineTheme()

  return (
    <div
      css={{
        width: 30,
        transform: `translate(${rotate ? '-50%' : '50%'}, -50%)`,
        position: 'absolute',
        top: '50%',
        left: rotate ? 0 : undefined,
        right: rotate ? undefined : 0,
        zIndex: 2,
        cursor: 'pointer',
      }}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <svg
        css={{
          transform: `${rotate ? 'rotate(180deg) ' : ' '}`,
          width: '100%',
        }}
        width="423"
        height="730"
        viewBox="0 0 423 730"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M412.561 355.087C418.046 360.558 418.046 369.442 412.561 374.913L129.886 656.843C121.062 665.644 106 659.394 106 646.93L106 83.0698C106 70.606 121.062 64.3557 129.886 73.1572L412.561 355.087Z"
          fill={theme.colors.brand[5] || '#D9D9D9'}
        />
        <path
          d="M370.098 355.6C375.566 361.067 375.566 369.933 370.098 375.4L250.149 495.326C241.329 504.144 226.25 497.897 226.25 485.425L226.25 245.575C226.25 233.103 241.329 226.856 250.149 235.674L370.098 355.6Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

const getResponsiveValue = ({
  desktopResponsiveMode,
  tabletResponsiveMode,
  mobileResponsiveMode,
  responsiveSettings,
}: {
  desktopResponsiveMode: ScreeenType
  tabletResponsiveMode: ScreeenType
  mobileResponsiveMode: ScreeenType
  responsiveSettings: NonNullable<ResponsiveBoxSettingsSelect>
}) => {
  let mobileBoxSettings: BoxSettingsSelect
  if (mobileResponsiveMode == 'Mobile') {
    mobileBoxSettings = responsiveSettings.mobileBoxSettings
  } else if (mobileResponsiveMode == 'Tablet') {
    mobileBoxSettings = responsiveSettings.tabletBoxSettings
  } else {
    mobileBoxSettings = responsiveSettings.desktopBoxSettings
  }
  let tabletBoxSettings: BoxSettingsSelect
  if (tabletResponsiveMode == 'Mobile') {
    tabletBoxSettings = responsiveSettings.mobileBoxSettings
  } else if (tabletResponsiveMode == 'Tablet') {
    tabletBoxSettings = responsiveSettings.tabletBoxSettings
  } else {
    tabletBoxSettings = responsiveSettings.desktopBoxSettings
  }
  let desktopBoxSettings: BoxSettingsSelect
  if (desktopResponsiveMode == 'Mobile') {
    desktopBoxSettings = responsiveSettings.mobileBoxSettings
  } else if (desktopResponsiveMode == 'Tablet') {
    desktopBoxSettings = responsiveSettings.tabletBoxSettings
  } else {
    desktopBoxSettings = responsiveSettings.desktopBoxSettings
  }
  return {
    mobileBoxSettings,
    tabletBoxSettings,
    desktopBoxSettings,
  }
}

type SliderValue = 'prev' | 'next'

export const replaceUrlAndPopupData = ({
  text,
  values,
}: {
  text?: string
  values?: NestedTableDataSelect['rows'][0]['items']
}) => {
  let textForChange = `${text}`
  try {
    if (values) {
      values.map(item => {
        if (item.text) {
          const clearedData = clearHTMLTags({ html: item.text })
          textForChange = textForChange?.replaceAll(`{${item.tableColumn.name}}`, clearedData)
          textForChange = textForChange?.replaceAll(`{${item.tableColumn.name.toLowerCase()}}`, clearedData)
        }
      })
    }
  } catch (error) {}
  return textForChange
}

export default function BoxBlock({
  parentRowID,
  parentRowSlug,
  parentTableID,
  tableData,
  values,
  parentIsSlider,
  parentSliderValue,
  setParentSliderValue,
  responsiveMode,
  onClose,
  style,
  ...boxBlock
}: {
  parentRowID?: string
  parentRowSlug?: string
  parentTableID?: string
  tableData?: NestedTableDataSelect
  values?: NestedTableDataSelect['rows'][0]['items']
  parentIsSlider?: boolean
  parentSliderValue?: SliderValue
  setParentSliderValue?: (sliderValue: SliderValue) => void
  responsiveMode?: ScreeenType
  onClose?: () => void
  style?: {
    zIndex?: string | number
    position?: string

    left?: number | string
    top?: number | string
    right?: number | string
    bottom?: number | string

    maxHeight?: string
    maxWidth?: string

    minHeight?: string

    height?: string
    width?: string

    overflowY?: string
    overflowX?: string

    transform?: string
  }
} & NonNullable<NestedBoxSelect>) {
  const position = getResponsiveValue({
    desktopResponsiveMode: boxBlock.positionDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.positionTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.positionMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const width = getResponsiveValue({
    desktopResponsiveMode: boxBlock.widthDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.widthTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.widthMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const height = getResponsiveValue({
    desktopResponsiveMode: boxBlock.heightDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.heightTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.heightMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const margin = getResponsiveValue({
    desktopResponsiveMode: boxBlock.marginDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.marginTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.marginMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const padding = getResponsiveValue({
    desktopResponsiveMode: boxBlock.paddingDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.paddingTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.paddingMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const background = getResponsiveValue({
    desktopResponsiveMode: boxBlock.backgroundDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.backgroundTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.backgroundMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const borderRadius = getResponsiveValue({
    desktopResponsiveMode: boxBlock.borderRadiusDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.borderRadiusTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.borderRadiusMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const shadow = getResponsiveValue({
    desktopResponsiveMode: boxBlock.shadowDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.shadowTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.shadowMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const gap = getResponsiveValue({
    desktopResponsiveMode: boxBlock.gapDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.gapTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.gapMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const border = getResponsiveValue({
    desktopResponsiveMode: boxBlock.borderDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.borderTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.borderMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const hidden = getResponsiveValue({
    desktopResponsiveMode: boxBlock.hiddenDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.hiddenTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.hiddenMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const widthType = getResponsiveValue({
    desktopResponsiveMode: boxBlock.widthTypeDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.widthTypeTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.widthTypeMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const heightType = getResponsiveValue({
    desktopResponsiveMode: boxBlock.heightTypeDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.heightTypeTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.heightTypeMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })
  const positionType = getResponsiveValue({
    desktopResponsiveMode: boxBlock.positionTypeDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.positionTypeTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.positionTypeMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })

  const boxIsOverflowHidden = getResponsiveValue({
    desktopResponsiveMode: boxBlock.overflowHiddenMobileResponsiveMode,
    tabletResponsiveMode: boxBlock.overflowHiddenTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.overflowHiddenDesktopResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })

  let textValue: string = ''
  let image: { name: string; folder: string | null } | undefined = undefined
  if (boxBlock.tableColumnDataOutputID && values) {
    const value = values.find(value => value.tableColumnID == boxBlock.tableColumnDataOutputID)
    if (value && value.tableColumn) {
      if (value.tableColumn.type == 'Text') {
        if (value.text) textValue = value.text
      }
      if (value.tableColumn.type == 'Number') {
        if (value.number) textValue = `${value.number}`
      }
      if (value.tableColumn.type == 'Image') {
        if (value.image) {
          image = {
            name: value.image.name,
            folder: value.image.folder,
          }
        }
      }
    }
  }

  const tabletBorderRadius = `${borderRadius.tabletBoxSettings.borderRadiusLeftTop || 0}px ${
    borderRadius.tabletBoxSettings.borderRadiusRightTop || 0
  }px ${borderRadius.tabletBoxSettings.borderRadiusRightBottom || 0}px ${
    borderRadius.tabletBoxSettings.borderRadiusLeftBottom || 0
  }px`

  const desktopBorderRadius = `${borderRadius.desktopBoxSettings.borderRadiusLeftTop || 0}px ${
    borderRadius.desktopBoxSettings.borderRadiusRightTop || 0
  }px ${borderRadius.desktopBoxSettings.borderRadiusRightBottom || 0}px ${
    borderRadius.desktopBoxSettings.borderRadiusLeftBottom || 0
  }px`

  const mobileBorderRadius = `${borderRadius.mobileBoxSettings.borderRadiusLeftTop || 0}px ${
    borderRadius.mobileBoxSettings.borderRadiusRightTop || 0
  }px ${borderRadius.mobileBoxSettings.borderRadiusRightBottom || 0}px ${
    borderRadius.mobileBoxSettings.borderRadiusLeftBottom || 0
  }px`

  const tabletCSS: Theme = {
    display: hidden.tabletBoxSettings.hidden ? 'none' : 'flex',

    overflow: boxIsOverflowHidden.tabletBoxSettings.isOverflowHidden ? 'hidden' : undefined,

    alignContent: transformBoxPosition(position.tabletBoxSettings.verticalPosition),
    alignItems: transformBoxPosition(position.tabletBoxSettings.verticalPosition),
    justifyContent: transformBoxPosition(position.tabletBoxSettings.horizontalPosition),
    flexDirection: position.tabletBoxSettings.vector == 'Bottom' ? 'column' : 'row',

    position:
      typeof style?.position == 'string'
        ? style.position
        : positionType.tabletBoxSettings.positionType == 'Relative'
        ? 'relative'
        : 'absolute',

    left:
      typeof style?.left == 'number' || typeof style?.left == 'string'
        ? style.left
        : typeof style?.right == 'number'
        ? undefined
        : positionType.tabletBoxSettings.positionType == 'Relative'
        ? `${positionType.tabletBoxSettings.positionX}px`
        : `50%`,
    top:
      typeof style?.top == 'number' || typeof style?.top == 'string'
        ? style.top
        : typeof style?.bottom == 'number'
        ? undefined
        : positionType.tabletBoxSettings.positionType == 'Relative'
        ? `${positionType.tabletBoxSettings.positionY}px`
        : `0%`,
    right: typeof style?.right == 'number' ? style.right : undefined,
    bottom: typeof style?.bottom == 'number' ? style.bottom : undefined,

    transform:
      typeof style?.transform == 'string'
        ? style.transform
        : positionType.tabletBoxSettings.positionType == 'Relative'
        ? undefined
        : `translate3d(${positionType.tabletBoxSettings.positionX || 0}px, ${
            positionType.tabletBoxSettings.positionY || 0
          }px, 0)`,

    minWidth: parentIsSlider
      ? width.tabletBoxSettings.width
        ? `${width.tabletBoxSettings.width}${transformBoxWidth(widthType.tabletBoxSettings.widthType)}`
        : 'auto'
      : undefined,

    width:
      typeof style?.width == 'string'
        ? style.width
        : width.tabletBoxSettings.width
        ? `${width.tabletBoxSettings.width}${transformBoxWidth(widthType.tabletBoxSettings.widthType)}`
        : 'auto',
    maxWidth:
      typeof style?.maxWidth == 'string'
        ? style.maxWidth
        : positionType.tabletBoxSettings.positionType == 'Relative'
        ? '100%'
        : undefined,

    minHeight:
      typeof style?.minHeight == 'string'
        ? style.minHeight
        : `${
            height.tabletBoxSettings.height
              ? `${height.tabletBoxSettings.height}${transformBoxWidth(heightType.tabletBoxSettings.heightType)}`
              : 'auto'
          }`,

    margin: `${margin.tabletBoxSettings.marginTop || 0}px ${margin.tabletBoxSettings.marginRight || 0}px ${
      margin.tabletBoxSettings.marginBottom || 0
    }px ${margin.tabletBoxSettings.marginLeft || 0}px`,

    padding: `${padding.tabletBoxSettings.paddingTop || 0}px ${padding.tabletBoxSettings.paddingRight || 0}px ${
      padding.tabletBoxSettings.paddingBottom || 0
    }px ${padding.tabletBoxSettings.paddingLeft || 0}px`,

    backgroundColor: `${background.tabletBoxSettings.background?.color?.value || 'transparent'}`,

    borderRadius: tabletBorderRadius,

    gap: gap.tabletBoxSettings.gap,

    borderTop: border.tabletBoxSettings.border
      ? `${border.tabletBoxSettings.border.top}px solid ${border.tabletBoxSettings.border.color.value}`
      : undefined,
    borderRight: border.tabletBoxSettings.border
      ? `${border.tabletBoxSettings.border.left}px solid ${border.tabletBoxSettings.border.color.value}`
      : undefined,
    borderBottom: border.tabletBoxSettings.border
      ? `${border.tabletBoxSettings.border.bottom}px solid ${border.tabletBoxSettings.border.color.value}`
      : undefined,
    borderLeft: border.tabletBoxSettings.border
      ? `${border.tabletBoxSettings.border.left}px solid ${border.tabletBoxSettings.border.color.value}`
      : undefined,

    boxShadow: shadow.tabletBoxSettings.shadow
      ? `${shadow.tabletBoxSettings.shadow.xShift}px ${shadow.tabletBoxSettings.shadow.yShift}px ${shadow.tabletBoxSettings.shadow.blur}px ${shadow.tabletBoxSettings.shadow.spread}px ${shadow.tabletBoxSettings.shadow.color.value}`
      : 'none',
  }
  const desktopCSS: Theme = {
    display: hidden.desktopBoxSettings.hidden ? 'none' : 'flex',

    overflow: boxIsOverflowHidden.desktopBoxSettings.isOverflowHidden ? 'hidden' : undefined,

    alignContent: transformBoxPosition(position.desktopBoxSettings.verticalPosition),
    alignItems: transformBoxPosition(position.desktopBoxSettings.verticalPosition),
    justifyContent: transformBoxPosition(position.desktopBoxSettings.horizontalPosition),
    flexDirection: position.desktopBoxSettings.vector == 'Bottom' ? 'column' : 'row',

    position:
      typeof style?.position == 'string'
        ? style.position
        : positionType.desktopBoxSettings.positionType == 'Relative'
        ? 'relative'
        : 'absolute',

    left:
      typeof style?.left == 'number' || typeof style?.left == 'string'
        ? style.left
        : typeof style?.right == 'number'
        ? undefined
        : positionType.desktopBoxSettings.positionType == 'Relative'
        ? `${positionType.desktopBoxSettings.positionX}px`
        : `50%`,
    top:
      typeof style?.top == 'number' || typeof style?.top == 'string'
        ? style.top
        : typeof style?.bottom == 'number'
        ? undefined
        : positionType.desktopBoxSettings.positionType == 'Relative'
        ? `${positionType.desktopBoxSettings.positionY}px`
        : `0%`,
    right: typeof style?.right == 'number' ? style.right : undefined,
    bottom: typeof style?.bottom == 'number' ? style.bottom : undefined,

    transform:
      typeof style?.transform == 'string'
        ? style.transform
        : positionType.desktopBoxSettings.positionType == 'Relative'
        ? undefined
        : `translate3d(${positionType.desktopBoxSettings.positionX || 0}px, ${
            positionType.desktopBoxSettings.positionY || 0
          }px, 0)`,

    minWidth: parentIsSlider
      ? width.desktopBoxSettings.width
        ? `${width.desktopBoxSettings.width}${transformBoxWidth(widthType.desktopBoxSettings.widthType)}`
        : 'auto'
      : undefined,

    width:
      typeof style?.width == 'string'
        ? style.width
        : width.desktopBoxSettings.width
        ? `${width.desktopBoxSettings.width}${transformBoxWidth(widthType.desktopBoxSettings.widthType)}`
        : 'auto',
    maxWidth:
      typeof style?.maxWidth == 'string'
        ? style.maxWidth
        : positionType.desktopBoxSettings.positionType == 'Relative'
        ? '100%'
        : undefined,

    minHeight:
      typeof style?.minHeight == 'string'
        ? style.minHeight
        : `${
            height.desktopBoxSettings.height
              ? `${height.desktopBoxSettings.height}${transformBoxWidth(heightType.desktopBoxSettings.heightType)}`
              : 'auto'
          }`,

    margin: `${margin.desktopBoxSettings.marginTop || 0}px ${margin.desktopBoxSettings.marginRight || 0}px ${
      margin.desktopBoxSettings.marginBottom || 0
    }px ${margin.desktopBoxSettings.marginLeft || 0}px`,

    padding: `${padding.desktopBoxSettings.paddingTop || 0}px ${padding.desktopBoxSettings.paddingRight || 0}px ${
      padding.desktopBoxSettings.paddingBottom || 0
    }px ${padding.desktopBoxSettings.paddingLeft || 0}px`,

    backgroundColor: `${background.desktopBoxSettings.background?.color?.value || 'transparent'}`,

    borderRadius: desktopBorderRadius,

    gap: gap.desktopBoxSettings.gap,

    borderTop: border.desktopBoxSettings.border
      ? `${border.desktopBoxSettings.border.top}px solid ${border.desktopBoxSettings.border.color.value}`
      : undefined,
    borderRight: border.desktopBoxSettings.border
      ? `${border.desktopBoxSettings.border.left}px solid ${border.desktopBoxSettings.border.color.value}`
      : undefined,
    borderBottom: border.desktopBoxSettings.border
      ? `${border.desktopBoxSettings.border.bottom}px solid ${border.desktopBoxSettings.border.color.value}`
      : undefined,
    borderLeft: border.desktopBoxSettings.border
      ? `${border.desktopBoxSettings.border.left}px solid ${border.desktopBoxSettings.border.color.value}`
      : undefined,

    boxShadow: shadow.desktopBoxSettings.shadow
      ? `${shadow.desktopBoxSettings.shadow.xShift}px ${shadow.desktopBoxSettings.shadow.yShift}px ${shadow.desktopBoxSettings.shadow.blur}px ${shadow.desktopBoxSettings.shadow.spread}px ${shadow.desktopBoxSettings.shadow.color.value}`
      : 'none',
  }

  let styleShell: Theme = {
    zIndex: typeof style?.zIndex == 'number' ? style.zIndex : 1,
    display: hidden.mobileBoxSettings.hidden ? 'none' : 'flex',

    overflow: boxIsOverflowHidden.mobileBoxSettings.isOverflowHidden ? 'hidden' : undefined,

    flexWrap: 'wrap',
    alignContent: transformBoxPosition(position.mobileBoxSettings.verticalPosition),
    alignItems: transformBoxPosition(position.mobileBoxSettings.verticalPosition),
    justifyContent: transformBoxPosition(position.mobileBoxSettings.horizontalPosition),
    flexDirection: position.mobileBoxSettings.vector == 'Bottom' ? 'column' : 'row',

    position:
      typeof style?.position == 'string'
        ? style.position
        : positionType.mobileBoxSettings.positionType == 'Relative'
        ? 'relative'
        : 'absolute',
    left:
      typeof style?.left == 'number' || typeof style?.left == 'string'
        ? style.left
        : typeof style?.right == 'number'
        ? undefined
        : positionType.mobileBoxSettings.positionType == 'Relative'
        ? `${positionType.mobileBoxSettings.positionX}px`
        : `50%`,
    top:
      typeof style?.top == 'number' || typeof style?.top == 'string'
        ? style.top
        : typeof style?.bottom == 'number'
        ? undefined
        : positionType.mobileBoxSettings.positionType == 'Relative'
        ? `${positionType.mobileBoxSettings.positionY}px`
        : `0%`,
    right: typeof style?.right == 'number' ? style.right : undefined,
    bottom: typeof style?.bottom == 'number' ? style.bottom : undefined,

    transform:
      typeof style?.transform == 'string'
        ? style.transform
        : positionType.mobileBoxSettings.positionType == 'Relative'
        ? undefined
        : `translate3d(${positionType.mobileBoxSettings.positionX || 0}px, ${
            positionType.mobileBoxSettings.positionY || 0
          }px, 0)`,

    minWidth: parentIsSlider
      ? width.mobileBoxSettings.width
        ? `${width.mobileBoxSettings.width}${transformBoxWidth(widthType.mobileBoxSettings.widthType)}`
        : 'auto'
      : undefined,

    width:
      typeof style?.width == 'string'
        ? style.width
        : width.mobileBoxSettings.width
        ? `${width.mobileBoxSettings.width}${transformBoxWidth(widthType.mobileBoxSettings.widthType)}`
        : 'auto',
    maxWidth:
      typeof style?.maxWidth == 'string'
        ? style.maxWidth
        : positionType.mobileBoxSettings.positionType == 'Relative'
        ? '100%'
        : undefined,

    minHeight:
      typeof style?.minHeight == 'string'
        ? style.minHeight
        : `${
            height.mobileBoxSettings.height
              ? `${height.mobileBoxSettings.height}${transformBoxWidth(heightType.mobileBoxSettings.heightType)}`
              : 'auto'
          }`,
    maxHeight: typeof style?.maxHeight == 'string' ? style.maxHeight : undefined,

    height: typeof style?.height == 'string' ? style.height : undefined,

    overflowY: typeof style?.overflowY == 'string' ? style.overflowY : undefined,
    overflowX: typeof style?.overflowX == 'string' ? style.overflowX : undefined,

    margin: `${margin.mobileBoxSettings.marginTop || 0}px ${margin.mobileBoxSettings.marginRight || 0}px ${
      margin.mobileBoxSettings.marginBottom || 0
    }px ${margin.mobileBoxSettings.marginLeft || 0}px`,

    padding: `${padding.mobileBoxSettings.paddingTop || 0}px ${padding.mobileBoxSettings.paddingRight || 0}px ${
      padding.mobileBoxSettings.paddingBottom || 0
    }px ${padding.mobileBoxSettings.paddingLeft || 0}px`,

    backgroundColor: `${background.mobileBoxSettings.background?.color?.value || 'transparent'}`,

    borderRadius: mobileBorderRadius,

    gap: gap.mobileBoxSettings.gap,

    borderTop: border.mobileBoxSettings.border
      ? `${border.mobileBoxSettings.border.top}px solid ${border.mobileBoxSettings.border.color.value}`
      : undefined,
    borderRight: border.mobileBoxSettings.border
      ? `${border.mobileBoxSettings.border.left}px solid ${border.mobileBoxSettings.border.color.value}`
      : undefined,
    borderBottom: border.mobileBoxSettings.border
      ? `${border.mobileBoxSettings.border.bottom}px solid ${border.mobileBoxSettings.border.color.value}`
      : undefined,
    borderLeft: border.mobileBoxSettings.border
      ? `${border.mobileBoxSettings.border.left}px solid ${border.mobileBoxSettings.border.color.value}`
      : undefined,

    boxShadow: shadow.mobileBoxSettings.shadow
      ? `${shadow.mobileBoxSettings.shadow.xShift}px ${shadow.mobileBoxSettings.shadow.yShift}px ${shadow.mobileBoxSettings.shadow.blur}px ${shadow.mobileBoxSettings.shadow.spread}px ${shadow.mobileBoxSettings.shadow.color.value}`
      : 'none',

    [mqBox.tablet]: responsiveMode ? undefined : tabletCSS,
    [mqBox.desktop]: responsiveMode ? undefined : desktopCSS,
    transition: 'transform 0.3s',
    ':hover': {
      // transform: boxBlock.link ? 'scale(1.025)' : undefined,
      cursor:
        boxBlock.link || boxBlock.isSliderArrowNext || boxBlock.isSliderArrowPrev || boxBlock.isClosePopup
          ? 'pointer'
          : undefined,
    },
  }

  if (responsiveMode == 'Tablet') {
    styleShell = {
      ...styleShell,
      '@media (min-width: 1px)': tabletCSS,
    }
  }
  if (responsiveMode == 'Desktop') {
    styleShell = {
      ...styleShell,
      '@media (min-width: 1px)': tabletCSS,
      '@media (min-width: 2px)': desktopCSS,
    }
  }

  const boxBackground = getResponsiveValue({
    desktopResponsiveMode: boxBlock.backgroundDesktopResponsiveMode,
    tabletResponsiveMode: boxBlock.backgroundTabletResponsiveMode,
    mobileResponsiveMode: boxBlock.backgroundMobileResponsiveMode,
    responsiveSettings: boxBlock.responsiveBoxSettings,
  })

  const { classes } = useMainStyles()

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
  })

  const [sliderValue, setSliderValue] = useState<SliderValue | undefined>(undefined)

  const setParentSliderValueLocale = (sliderValue: SliderValue) => {
    if (setParentSliderValue) {
      if (!boxBlock.isSliderShell) {
        setParentSliderValue(sliderValue)
      } else {
        setSliderValue(sliderValue)
        setTimeout(() => {
          setSliderValue(undefined)
        }, 0)
      }
    } else {
      setSliderValue(sliderValue)
      setTimeout(() => {
        setSliderValue(undefined)
      }, 0)
    }
  }
  const parentSliderValueLocale = boxBlock.isSliderShell ? sliderValue : parentSliderValue ? parentSliderValue : sliderValue

  useEffect(() => {
    if (parentSliderValue) {
      if (parentSliderValue == 'next') embla?.scrollNext()
      else embla?.scrollPrev()
    }
  }, [parentSliderValue])

  const shellBoxID = `shell-box-id-${boxBlock.id}`

  const boxes: {
    box: NestedBoxSelect
    tableData?: NestedTableDataSelect
    values?: NestedTableDataSelect['rows'][0]['items']
    rowID?: string
    rowSlug?: string
    parentTableIDProp?: string
  }[] = []

  if (tableData || boxBlock.pinTable?.connector?.tableData) {
    // boxBlock.tableColumnDataOutputID
    if (boxBlock.boxes) {
      const firstBox = boxBlock.boxes[0]
      if (firstBox) {
        const data = boxBlock.pinTable?.connector?.tableData || tableData
        data?.rows?.map(row => {
          boxes.push({
            box: firstBox,
            rowID: row.id,
            rowSlug: row.slug ? row.slug : undefined,
            // @ts-ignore
            values: row.items,
          })
        })
      }
    }
    // boxBlock.pinTable?.connector?.tableData
    //NestedTableDataSelect['rows'][0]['items']
  } else {
    boxBlock.boxes?.map(box => {
      if (box.tableColumnDataOutputID && values) {
        const value = values.find(value => value.tableColumnID == box.tableColumnDataOutputID)
        if (value && value.tableColumn) {
          if (value.tableColumn.type == 'Table' && value.tableData) {
            return boxes.push({
              box,
              tableData: value.tableData,
              parentTableIDProp: value.tableColumn.columnTableSchemaID ? value.tableColumn.columnTableSchemaID : undefined,
              values,
            })
          }
        }
        if (
          !value && box.tableColumnDataOutputID && box.tableColumnDataOutput ? box.tableColumnDataOutput.type == 'Table' : false
        ) {
          return
        }
      }
      boxes.push({
        box,
        values,
      })
    })
  }

  const boxesJSX = ({ parentIsSlider }: { parentIsSlider: boolean }) =>
    boxes.map(boxLoop => {
      const { box, tableData, rowID, rowSlug, parentTableIDProp } = boxLoop
      const valuesLoop = boxLoop.values
      return (
        <BoxBlock
          values={valuesLoop ? valuesLoop : values}
          tableData={tableData}
          parentSliderValue={parentSliderValueLocale}
          setParentSliderValue={setParentSliderValueLocale}
          parentIsSlider={parentIsSlider}
          key={box.id}
          responsiveMode={responsiveMode}
          onClose={onClose}
          parentTableID={parentTableIDProp ? parentTableIDProp : boxBlock.pinTable ? boxBlock.pinTable.id : parentTableID}
          parentRowID={rowID ? rowID : parentRowID}
          parentRowSlug={rowSlug ? rowSlug : parentRowSlug}
          {...box}
        />
      )
    })

  const nested = (
    <>
      <div
        id={`box-id-${boxBlock.id}`}
        css={{
          overflow: 'hidden',
          zIndex: -1,
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,

          backgroundImage:
            boxBlock.tableImageIsCover && image
              ? `url(${getPathToFile({ fileName: image.name, folderName: image.folder })})`
              : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',

          borderRadius: mobileBorderRadius,
          [mqBox.tablet]: {
            borderRadius: tabletBorderRadius,
          },
          [mqBox.desktop]: {
            borderRadius: desktopBorderRadius,
          },
        }}
      >
        <div
          css={{
            pointerEvents: 'none',
            position: 'absolute',
            left: '50%',
            top:
              boxBackground.mobileBoxSettings.background?.verticalPosition == 'Top'
                ? 0
                : boxBackground.mobileBoxSettings.background?.verticalPosition == 'Center'
                ? '50%'
                : '100%',
            width: boxBackground.mobileBoxSettings.background?.width,
            height: boxBackground.mobileBoxSettings.background?.height,
            transform: `translate(calc(-50% + ${boxBackground.mobileBoxSettings.background?.positionX}px), calc(-50% + ${boxBackground.mobileBoxSettings.background?.positionY}px))`,

            [mqBox.tablet]: {
              top:
                boxBackground.tabletBoxSettings.background?.verticalPosition == 'Top'
                  ? 0
                  : boxBackground.tabletBoxSettings.background?.verticalPosition == 'Center'
                  ? '50%'
                  : '100%',
              width: boxBackground.tabletBoxSettings.background?.width,
              height: boxBackground.tabletBoxSettings.background?.height,
              transform: `translate(calc(-50% + ${boxBackground.tabletBoxSettings.background?.positionX}px), calc(-50% + ${boxBackground.tabletBoxSettings.background?.positionY}px))`,
            },

            [mqBox.desktop]: {
              top:
                boxBackground.desktopBoxSettings.background?.verticalPosition == 'Top'
                  ? 0
                  : boxBackground.desktopBoxSettings.background?.verticalPosition == 'Center'
                  ? '50%'
                  : '100%',
              width: boxBackground.desktopBoxSettings.background?.width,
              height: boxBackground.desktopBoxSettings.background?.height,
              transform: `translate(calc(-50% + ${boxBackground.desktopBoxSettings.background?.positionX}px), calc(-50% + ${boxBackground.desktopBoxSettings.background?.positionY}px))`,
            },
          }}
        >
          {boxBackground.mobileBoxSettings.background?.image ? (
            <img
              alt=""
              css={{
                [mqBox.tablet]: {
                  display: 'none',
                },
                width: '100%',
                height: '100%',
              }}
              src={getPathToFile({
                fileName: boxBackground.mobileBoxSettings.background.image.name,
                folderName: boxBackground.mobileBoxSettings.background.image.folder,
              })}
            />
          ) : null}

          {boxBackground.tabletBoxSettings.background?.image ? (
            <img
              alt=""
              css={{
                display: 'none',
                [mqBox.tablet]: {
                  display: 'block',
                },
                [mqBox.desktop]: {
                  display: 'none',
                },
                width: '100%',
                height: '100%',
              }}
              src={getPathToFile({
                fileName: boxBackground.tabletBoxSettings.background.image.name,
                folderName: boxBackground.tabletBoxSettings.background.image.folder,
              })}
            />
          ) : null}

          {boxBackground.desktopBoxSettings.background?.image ? (
            <img
              loading="lazy"
              alt=""
              css={{
                display: 'none',
                [mqBox.desktop]: {
                  display: 'block',
                },
                width: '100%',
                height: '100%',
              }}
              src={getPathToFile({
                fileName: boxBackground.desktopBoxSettings.background.image.name,
                folderName: boxBackground.desktopBoxSettings.background.image.folder,
              })}
            />
          ) : null}
        </div>
      </div>
      {!boxBlock.tableImageIsCover && image ? (
        <img
          css={{
            width: '100%',

            borderRadius: mobileBorderRadius,
            [mqBox.tablet]: {
              borderRadius: tabletBorderRadius,
            },
            [mqBox.desktop]: {
              borderRadius: desktopBorderRadius,
            },
          }}
          src={getPathToFile({ fileName: image.name, folderName: image.folder })}
        />
      ) : boxBlock.mapComponent ? (
        <MapComponentNoSSR
          cssStyle={{
            transform: 'translateZ(0)',
            borderRadius: mobileBorderRadius,
            [mqBox.tablet]: {
              borderRadius: tabletBorderRadius,
            },
            [mqBox.desktop]: {
              borderRadius: desktopBorderRadius,
            },
          }}
          mapComponent={boxBlock.mapComponent}
        />
      ) : boxBlock.textComponent ? (
        <TransofrmTextContentHTML
          value={textValue}
          responsiveMode={responsiveMode}
          prepositions={true}
          textComponent={boxBlock.textComponent}
        />
      ) : boxBlock.imageComponent ? (
        <ImagePreview
          cssStyle={{
            transform: 'translateZ(0)',
            borderRadius: mobileBorderRadius,
            [mqBox.tablet]: {
              borderRadius: tabletBorderRadius,
            },
            [mqBox.desktop]: {
              borderRadius: desktopBorderRadius,
            },
          }}
          image={boxBlock.imageComponent}
        />
      ) : boxBlock.formComponent ? (
        <div css={{ width: '100%' }}>
          <Form
            radiusCSS={{
              borderRadius: mobileBorderRadius,
              [mqBox.tablet]: {
                borderRadius: tabletBorderRadius,
              },
              [mqBox.desktop]: {
                borderRadius: desktopBorderRadius,
              },
            }}
            form={boxBlock.formComponent.form}
          />
        </div>
      ) : boxBlock.quizComponent ? (
        <Quiz {...boxBlock.quizComponent.quiz} />
      ) : boxBlock.codeComponent ? (
        <CodeComponentMemo code={boxBlock.codeComponent.code} />
      ) : boxBlock.videoComponent ? (
        <VideoComponent
          cssStyle={{
            transform: 'translateZ(0)',
            borderRadius: mobileBorderRadius,
            [mqBox.tablet]: {
              borderRadius: tabletBorderRadius,
            },
            [mqBox.desktop]: {
              borderRadius: desktopBorderRadius,
            },
          }}
          videoComponent={boxBlock.videoComponent}
        />
      ) : boxBlock.faqComponent ? (
        <FaqComponent {...boxBlock.faqComponent} />
      ) : boxes.length ? (
        boxBlock.isSlider ? (
          <>
            {/* <div
            css={{
              width: '100%',
              position: 'relative',
            }}
          > */}
            {/* {embla?.canScrollPrev
              ? arrowSlider({
                  onClick: () => {
                    embla?.scrollPrev()
                  },
                  rotate: true,
                })
              : null}
            {embla?.canScrollNext
              ? arrowSlider({
                  onClick: () => {
                    embla?.scrollNext()
                  },
                })
              : null} */}
            <div
              css={{
                display: 'flex',
                width: '100%',
              }}
              ref={emblaRef}
            >
              <div
                css={{
                  display: 'flex',
                  width: '100%',
                  // height: 'auto',
                  alignItems: 'flex-start',
                  gap: gap.mobileBoxSettings.gap,
                  [mqBox.tablet]: {
                    gap: gap.tabletBoxSettings.gap,
                  },
                  [mqBox.desktop]: {
                    gap: gap.desktopBoxSettings.gap,
                  },
                  cursor: 'ew-resize',
                }}
              >
                {boxesJSX({ parentIsSlider: true })}
              </div>
            </div>
            {/* </div> */}
          </>
        ) : (
          boxesJSX({ parentIsSlider: false })
        )
      ) : (
        <DivWithCustomCodeMemo id={`boxScriptID-${boxBlock.id}`} />
      )}
      {/* </div> */}
    </>
  )

  const link = boxBlock.link

  const mainOnClick = useCallback(() => {
    if (setParentSliderValue && (boxBlock.isSliderArrowNext || boxBlock.isSliderArrowPrev)) {
      setParentSliderValue(boxBlock.isSliderArrowNext ? 'next' : 'prev')
    }

    if (boxBlock.isClosePopup && onClose) onClose()
  }, [boxBlock.isSliderArrowNext, boxBlock.isSliderArrowPrev, setParentSliderValue, boxBlock.isClosePopup])

  // if (style?.overflowY == 'auto') {
  //   return (
  //     <div
  //       css={{ ...styleShell }}
  //       onClick={event => {
  //         mainOnClick()
  //       }}
  //      id={shellBoxID}
  //     >
  //       <div
  //         // className={classes.hideScrollbar}
  //         css={{
  //           overflowY: 'auto',
  //           maxHeight: '100%',
  //         }}
  //       >
  //         {nested}
  //       </div>
  //     </div>
  //   )
  // }

  if (link) {
    if (link.page) {
      return (
        <div
          onClick={event => {
            // event.stopPropagation()
            mainOnClick()
          }}
          css={{ ...styleShell, cursor: 'pointer' }}
          id={shellBoxID}
        >
          <a
            aria-label={boxBlock.id}
            css={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              display: 'block',
              zIndex: 2,
            }}
            href={getUrlToPageFromLink({ link, parentTableID, parentRowSlug })}
          >
            <span
              css={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                display: 'block',
                zIndex: 2,
                opacity: 0,
              }}
            />
          </a>
          {nested}
        </div>
      )
    }

    if (link.url) {
      return (
        <a
          aria-label={boxBlock.id}
          css={{ ...styleShell, color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
          target={'_blank'}
          rel="nofollow"
          href={replaceUrlAndPopupData({ text: link.url, values: values })}
          onClick={event => {
            // event.stopPropagation()
            mainOnClick()
          }}
          id={shellBoxID}
        >
          {nested}
        </a>
      )
    }
    if (link.popup) {
      return (
        <div
          css={{ ...styleShell, cursor: 'pointer' }}
          onClick={event => {
            pushOpenWindowFromLink({
              link,
              popupData: replaceUrlAndPopupData({ text: link.popupData ? link.popupData : undefined, values: values }),
            })
            // event.stopPropagation()
            mainOnClick()
          }}
          id={shellBoxID}
        >
          {nested}
        </div>
      )
    }
  }
  return (
    <div
      onClick={event => {
        // event.stopPropagation()
        mainOnClick()
      }}
      css={{ ...styleShell }}
      id={shellBoxID}
    >
      {nested}
    </div>
  )
}

const DivWithCustomCodeMemo = memo(DivWithCustomCode, () => true)

function DivWithCustomCode({ id }: { id: string }) {
  return <div id={id} />
}
