import { Button, Container, Group, Image, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconExternalLink } from '@tabler/icons'
import { PageLoader } from 'components/PageLoader/PageLoader'
import { useRouter } from 'next/router'
import { createContext, useEffect, useRef, useState } from 'react'
import { NestedBoxSelect } from 'server/selects'
import { getPathToFile } from 'utils/getPathToFile'
import { InferQueryOutput, trpc } from 'utils/trpc'
import { PublicSiteContextProvider } from './PublicSiteContextProvider'
import { MySiteDataEventsContext, UpdateBoxByIDSiteData } from './SiteDataEventsContextProvider'

// @ts-ignore
export const SiteContext = createContext<InferQueryOutput<'user.getSite'>>()

export type SitePage =
  | 'settings'
  | 'sections'
  | 'blocks'
  | 'leads'
  | ''
  | 'forms'
  | 'quizzes'
  | 'popups'
  | 'targets'
  | 'events'
  | 'pages'

export const SiteContextProvider = ({
  children,
  fullWidth,
}: {
  children: JSX.Element | JSX.Element[] | undefined
  fullWidth?: boolean
}) => {
  const router = useRouter()
  const { siteId } = router.query

  const site = trpc.useQuery(
    [
      'user.getSite',
      {
        siteId: `${siteId}`,
      },
    ],
    {
      onError: error => {
        showNotification({
          title: error.message,
          message: '',
          color: 'red',
        })
        router.back()
      },
      refetchInterval: 500,
      notifyOnChangeProps: ['isSuccess', 'data'],
      retry: 0,
    }
  )

  const [siteData, setSiteData] = useState(site.data)
  useEffect(() => {
    setSiteData(site.data)
  }, [site.data])

  if (site.error) return <>{site.error.message}</>

  const boxesRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (boxesRef.current) {
      setHeight(boxesRef.current.offsetHeight)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxesRef.current) setHeight(boxesRef.current.offsetHeight)
      })
      resizeObserver.observe(boxesRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const updateBoxByID = ({ id, box }: UpdateBoxByIDSiteData) => {
    const nestedChangeBox = ({ boxNested }: { boxNested: NonNullable<NestedBoxSelect> }): NonNullable<NestedBoxSelect> => {
      return boxNested.id == id
        ? {
            ...boxNested,
            ...box,
            boxes: box.boxes
              ? box.boxes.map(box => nestedChangeBox({ boxNested: box }))
              : boxNested.boxes
              ? boxNested.boxes.map(box => nestedChangeBox({ boxNested: box }))
              : undefined,
          }
        : {
            ...boxNested,
            boxes: boxNested.boxes ? boxNested.boxes.map(box => nestedChangeBox({ boxNested: box })) : undefined,
          }
    }

    setSiteData(siteData => {
      return siteData
        ? {
            ...siteData,
            pagesV1: siteData.pagesV1.map(page => {
              return {
                ...page,
                sectionsPinnedCollection: page.sectionsPinnedCollection.map(sectionPinnedCollection => {
                  return {
                    ...sectionPinnedCollection,
                    sectionsCollection: {
                      ...sectionPinnedCollection.sectionsCollection,
                      sectionsChild: sectionPinnedCollection.sectionsCollection.sectionsChild.map(sectionChild => {
                        return {
                          ...sectionChild,
                          boxBlock: sectionChild.boxBlock
                            ? {
                                ...sectionChild.boxBlock,
                                box: nestedChangeBox({ boxNested: sectionChild.boxBlock.box }),
                              }
                            : sectionChild.boxBlock,
                        }
                      }),
                    },
                  }
                }),
              }
            }),
          }
        : siteData
    })
  }

  if (siteData) {
    return (
      <MySiteDataEventsContext.Provider value={{ updateBoxByID }}>
        <SiteContext.Provider value={siteData}>
          <PublicSiteContextProvider siteID={siteData.id}>
            <div css={{ height: `100%`, overflow: 'hidden' }}>
              <div ref={boxesRef}>
                {fullWidth ? null : (
                  <div
                    css={{
                      borderBottom: '1px solid #eeeeee',
                    }}
                  >
                    <Container>
                      <Group py="xs" position="apart">
                        <Group>
                          {siteData.favicon ? (
                            <Image
                              width={24}
                              height={24}
                              src={getPathToFile({ fileName: siteData.favicon.name, folderName: siteData.favicon.folder })}
                            />
                          ) : null}
                          <Text size="md">{siteData.name}</Text>
                        </Group>

                        <Group>
                          {siteData.domain ? (
                            <a
                              target={'_blank'}
                              href={`${siteData.domain.domain.includes('localhost') ? 'http' : 'https'}://${
                                siteData.domain.domain
                              }`}
                            >
                              <Button compact rightIcon={<IconExternalLink size={18} />}>
                                {siteData.domain.domain}
                              </Button>
                            </a>
                          ) : null}
                        </Group>
                      </Group>
                    </Container>
                  </div>
                )}
              </div>
              <div style={{ height: `calc(100% - ${height}px)`, overflowY: 'auto' }}>{children}</div>
            </div>
          </PublicSiteContextProvider>
        </SiteContext.Provider>
      </MySiteDataEventsContext.Provider>
    )
  }

  return site.isLoading ? <PageLoader /> : <>Сайт не найден</>
}
