import { Interpolation, Theme } from '@emotion/react'
import { mqBox } from 'config/mq'
import { parsePrepositions } from 'utils/parsePrepositions'

export const textWrapStyles = (options?: { all?: boolean; isDesktop?: boolean; color?: string }): Interpolation<Theme> => {
  const operator = options?.all ? '*' : '>'
  return {
    '*': {
      color: `${options ? options.color : '#333'}`,
    },
    '*:last-child': {
      marginBottom: '0 !important',
    },
    [`& ${operator} p, & ${operator} ol, & ${operator} ul`]: {
      fontSize: '16px',
      lineHeight: '1.4',
      margin: '0 0 12px 0',
    },
    [`& ${operator} li`]: {
      marginBottom: '8px',
    },
    [`& *`]: {
      lineHeight: '1.2',
    },
    mark: {
      padding: '0px 4px 1px 4px',
      borderRadius: '2px',
      color: 'inherit !important',
    },
    [`& ${operator} ul, & ${operator} ol`]: {
      padding: '0 0 0 35px',
    },
    [`& ${operator} h1, & ${operator} h2, & ${operator} h3, & ${operator} h4`]: {
      // strong: {
      //   background: 'linear-gradient(45deg, #09009f, #00ff95 80%)',
      //   backgroundClip: 'text',
      //   textFillColor: 'transparent',
      //   display: 'inline-block',
      // },
    },
    [`& ${operator} h1`]: {
      margin: '0 0 15px 0',

      fontSize: '32px',
    },
    [`& ${operator} h2`]: {
      margin: '0 0 10px 0',

      fontSize: '28px',
    },
    [`& ${operator} h3`]: {
      margin: '0 0 5px 0',

      fontSize: '24px',
    },
    [`& ${operator} h4`]: {
      margin: '0 0 5px 0',

      fontSize: '20px',
    },
    [mqBox.desktop]:
      options?.isDesktop || typeof options?.isDesktop == 'undefined'
        ? {
            [`& ${operator} h1`]: {
              fontSize: '36px',
            },
            [`& ${operator} h2`]: {
              fontSize: '32px',
            },
            [`& ${operator} h3`]: {
              fontSize: '28px',
            },
            [`& ${operator} h4`]: {
              fontSize: '24px',
            },
            [`& ${operator} p, & ${operator} ol, & ${operator} ul`]: {
              fontSize: '18px',
              margin: '0 0 14px 0',
            },
          }
        : undefined,
  }
}

export function TextWrap({ text, color }: { text: string; color?: string }) {
  return <div css={textWrapStyles({ color })} dangerouslySetInnerHTML={{ __html: parsePrepositions(text) }} />
}
