import { useGetSelectData } from 'generation/centerFrontedEngine'
import React, { useEffect, useRef, useState } from 'react'
import { getPathToFile } from 'utils/getPathToFile'

export const ImagePreviewSyncMemo = React.memo(ImagePreviewSync, (prev, next) => {
  return prev.image.id == next.image.id && prev.borderRadius == next.borderRadius
})

export function ImagePreviewSync({
  image,
  onDoubleClick,
  borderRadius,
}: {
  image: {
    id: string
    selectID: 'ImageComponent1'
  }
  onDoubleClick?: ({ isCover }: { isCover: boolean }) => void
  borderRadius?: string
}) {
  const boxesRef = useRef<HTMLDivElement>(null)
  const imageComponent = useGetSelectData({ modelID: image.id, selectID: image.selectID })

  const imageData = useGetSelectData({ modelID: imageComponent.image.id, selectID: imageComponent.image.selectID })

  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (boxesRef.current) {
      setWidth(boxesRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxesRef.current) setWidth(Math.ceil(boxesRef.current.offsetWidth))
      })
      resizeObserver.observe(boxesRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [imageComponent.isCover])

  const commonCSS = {
    borderRadius,
  }

  if (imageComponent.isCover)
    return (
      <div
        onDoubleClick={() => (onDoubleClick ? onDoubleClick({ isCover: imageComponent.isCover }) : undefined)}
        css={{
          ...commonCSS,

          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${getPathToFile({ fileName: imageData.name, folderName: imageData.folder })})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
    )

  const onDoubleClickLocal = () => {
    if (onDoubleClick) onDoubleClick({ isCover: imageComponent.isCover })
  }

  if (
    imageComponent.width == imageComponent.shellWidth &&
    imageComponent.height == imageComponent.shellHeight &&
    imageComponent.positionX == 0 &&
    imageComponent.positionY == 0
  ) {
    return (
      <img
        onDoubleClick={() => {
          onDoubleClickLocal()
        }}
        style={{
          width: '100%',
        }}
        loading="lazy"
        css={{
          ...commonCSS,
        }}
        src={getPathToFile({ fileName: imageData.name, folderName: imageData.folder })}
      />
    )
  }

  if (imageComponent.width == 0)
    return (
      <div onClick={() => (onDoubleClick ? onDoubleClick({ isCover: imageComponent.isCover }) : undefined)}>
        Картинка сплющена
      </div>
    )

  return (
    <div
      onDoubleClick={() => {
        onDoubleClickLocal()
      }}
      ref={boxesRef}
      style={{
        ...commonCSS,

        // maxWidth: imageComponent.shellWidth,

        willChange: 'maxWidth',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          willChange: 'paddingBottom',
          paddingBottom: `${(imageComponent.shellHeight / imageComponent.shellWidth) * 100}%`,
        }}
      ></div>
      <div
        css={{
          willChange: 'transform',
          width: imageComponent.shellWidth,
          height: imageComponent.shellHeight,
          position: 'absolute',
          left: 0,
          top: 0,
          transformOrigin: 'left top',
        }}
        style={{
          transform: `scale(${width < imageComponent.shellWidth ? width / imageComponent.shellWidth : 1})`,
        }}
      >
        <div
          css={{
            willChange: 'transform',
            position: 'absolute',
            left: '50%',
            top: '50%',
            // border: '1px solid #0086ff',
          }}
          style={{
            transform: `translate(calc(-50% + ${imageComponent.positionX}px), calc(-50% + ${imageComponent.positionY}px))`,
          }}
        >
          {imageData ? (
            <img
              loading="lazy"
              css={{
                willChange: 'width, height',
                display: 'block',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
              style={{
                width: imageComponent.width,
                height: imageComponent.height,
              }}
              src={getPathToFile({ fileName: imageData.name, folderName: imageData.folder })}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
