import { ActionIcon } from '@mantine/core'
import { ScreeenType } from '@prisma/client'
import { responsiveModes } from 'components/Box/Box'
import { useCallback, useEffect, useState } from 'react'
import useStateRef from 'react-usestateref'
import { useCurrentResponsiveMode } from 'utils/activeResponsiveModeCenter'
import { colors } from 'utils/styles'

export function useResponsiveProperty<P>({
  long,
  resposiveValue,
  resposiveMode,
  propertyName,
  onValueUpdated,
  onResponsiveModeUpdated,
}: {
  long?: boolean
  resposiveValue: {
    desktop: P
    tablet: P
    mobile: P
  }
  resposiveMode: {
    desktop: ScreeenType
    tablet: ScreeenType
    mobile: ScreeenType
  }
  propertyName: string

  onValueUpdated?: ({}: { value: P; responsiveMode: ScreeenType }) => Promise<void>
  onResponsiveModeUpdated?: ({}: { boxBlockResponsiveMode: ScreeenType; responsiveMode: ScreeenType }) => Promise<void>
}) {
  const responsiveMode = useCurrentResponsiveMode()
  const [responsiveModeState, setResponsiveMode, responsiveModeRef] = useStateRef(responsiveMode)
  useEffect(() => {
    setResponsiveMode(responsiveMode)
  }, [responsiveMode])

  const [desktopProperty, setDesktopProperty, desktopPropertyRef] = useStateRef(resposiveValue.desktop)
  const [tabletProperty, setTabletProperty, tabletPropertyRef] = useStateRef(resposiveValue.tablet)
  const [mobileProperty, setMobileProperty, mobilePropertyRef] = useStateRef(resposiveValue.mobile)

  const [temporaryDesktopProperty, setTemporaryDesktopProperty, temporaryDesktopPropertyRef] = useStateRef<P | undefined>(
    resposiveValue.desktop
  )
  const [temporaryTabletProperty, setTemporaryTabletProperty, temporaryTabletPropertyRef] = useStateRef<P | undefined>(
    resposiveValue.tablet
  )
  const [temporaryMobileProperty, setTemporaryMobileProperty, temporaryMobilePropertyRef] = useStateRef<P | undefined>(
    resposiveValue.mobile
  )

  const [propertyDesktopResponsiveMode, setPropertyDesktopResponsiveMode, propertyDesktopResponsiveModeRef] = useStateRef(
    resposiveMode.desktop
  )
  const [propertyTabletResponsiveMode, setPropertyTabletResponsiveMode, propertyTabletResponsiveModeRef] = useStateRef(
    resposiveMode.tablet
  )
  const [propertyMobileResponsiveMode, setPropertyMobileResponsiveMode, propertyMobileResponsiveModeRef] = useStateRef(
    resposiveMode.mobile
  )

  const getUpdatedValueAndTemporaryValue = useCallback(() => {
    let actualProperty: P
    let actualTemporaryProperty: P | undefined = undefined

    if (responsiveModeRef.current == 'Desktop') {
      if (propertyDesktopResponsiveModeRef.current == 'Desktop') {
        actualProperty = desktopPropertyRef.current
        actualTemporaryProperty = temporaryDesktopPropertyRef.current
      } else if (propertyDesktopResponsiveModeRef.current == 'Tablet') {
        actualProperty = tabletPropertyRef.current
        actualTemporaryProperty = temporaryTabletPropertyRef.current
      } else {
        actualProperty = mobilePropertyRef.current
        actualTemporaryProperty = temporaryMobilePropertyRef.current
      }
    } else if (responsiveModeRef.current == 'Tablet') {
      if (propertyTabletResponsiveModeRef.current == 'Desktop') {
        actualProperty = desktopPropertyRef.current
        actualTemporaryProperty = temporaryDesktopPropertyRef.current
      } else if (propertyTabletResponsiveModeRef.current == 'Tablet') {
        actualProperty = tabletPropertyRef.current
        actualTemporaryProperty = temporaryTabletPropertyRef.current
      } else {
        actualProperty = mobilePropertyRef.current
        actualTemporaryProperty = temporaryMobilePropertyRef.current
      }
    } else {
      if (propertyMobileResponsiveModeRef.current == 'Desktop') {
        actualProperty = desktopPropertyRef.current
        actualTemporaryProperty = temporaryDesktopPropertyRef.current
      } else if (propertyMobileResponsiveModeRef.current == 'Tablet') {
        actualProperty = tabletPropertyRef.current
        actualTemporaryProperty = temporaryTabletPropertyRef.current
      } else {
        actualProperty = mobilePropertyRef.current
        actualTemporaryProperty = temporaryMobilePropertyRef.current
      }
    }

    return {
      value: actualProperty,
      temporaryValue: actualTemporaryProperty,
    }
  }, [])

  const [value, setValue, valueRef] = useStateRef<P>(getUpdatedValueAndTemporaryValue().value)
  const [temporaryValue, setTemporaryValue, temporaryValueRef] = useStateRef<P | undefined>(
    getUpdatedValueAndTemporaryValue().temporaryValue
  )

  useEffect(() => {
    const { value, temporaryValue } = getUpdatedValueAndTemporaryValue()
    setValue(value)
    setTemporaryValue(temporaryValue)
  }, [
    responsiveModeRef.current,
    propertyDesktopResponsiveModeRef.current,
    propertyTabletResponsiveModeRef.current,
    propertyMobileResponsiveModeRef.current,
    temporaryDesktopPropertyRef.current,
    temporaryTabletPropertyRef.current,
    temporaryMobilePropertyRef.current,
    desktopPropertyRef.current,
    tabletPropertyRef.current,
    mobilePropertyRef.current,
  ])

  useEffect(() => {
    setDesktopProperty(resposiveValue.desktop)
    setTabletProperty(resposiveValue.tablet)
    setMobileProperty(resposiveValue.mobile)
    setTemporaryDesktopProperty(resposiveValue.desktop)
    setTemporaryTabletProperty(resposiveValue.tablet)
    setTemporaryMobileProperty(resposiveValue.mobile)
  }, [JSON.stringify(resposiveValue)])

  useEffect(() => {
    setPropertyDesktopResponsiveMode(resposiveMode.desktop)
    setPropertyTabletResponsiveMode(resposiveMode.tablet)
    setPropertyMobileResponsiveMode(resposiveMode.mobile)
  }, [JSON.stringify(resposiveMode)])

  const [viewValue, setViewValue, viewValueRef] = useStateRef(temporaryValue ? temporaryValue : value)
  useEffect(() => {
    setViewValue(temporaryValueRef.current ? temporaryValueRef.current : valueRef.current)
  }, [temporaryValue, value])

  const [propertyIsDrag, setPropertyIsDrag] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const getCurrentResponsiveMode = (): ScreeenType => {
    if (responsiveModeRef.current == 'Desktop') {
      if (propertyDesktopResponsiveModeRef.current == 'Desktop') return 'Desktop'
      else if (propertyDesktopResponsiveModeRef.current == 'Tablet') return 'Tablet'
      else return 'Mobile'
    } else if (responsiveModeRef.current == 'Tablet') {
      if (propertyTabletResponsiveModeRef.current == 'Desktop') return 'Desktop'
      else if (propertyTabletResponsiveModeRef.current == 'Tablet') return 'Tablet'
      else return 'Mobile'
    } else {
      if (propertyMobileResponsiveModeRef.current == 'Desktop') return 'Desktop'
      else if (propertyMobileResponsiveModeRef.current == 'Tablet') return 'Tablet'
      else return 'Mobile'
    }
  }

  const setDesktopResponsiveMode = (mode: ScreeenType) => {
    const prevPropertyDesktopResponsiveMode = propertyDesktopResponsiveModeRef.current
    setPropertyDesktopResponsiveMode(mode)
    if (onResponsiveModeUpdated)
      onResponsiveModeUpdated({
        boxBlockResponsiveMode: responsiveModeRef.current,
        responsiveMode: mode,
      }).catch(() => {
        setPropertyDesktopResponsiveMode(prevPropertyDesktopResponsiveMode)
      })
  }
  const setTabletResponsiveMode = (mode: ScreeenType) => {
    const prevPropertyTabletResponsiveMode = propertyTabletResponsiveModeRef.current
    setPropertyTabletResponsiveMode(mode)
    if (onResponsiveModeUpdated)
      onResponsiveModeUpdated({
        boxBlockResponsiveMode: responsiveModeRef.current,
        responsiveMode: mode,
      }).catch(() => {
        setPropertyTabletResponsiveMode(prevPropertyTabletResponsiveMode)
      })
  }
  const setMobileResponsiveMode = (mode: ScreeenType) => {
    const prevPropertyMobileResponsiveMode = propertyMobileResponsiveModeRef.current
    setPropertyMobileResponsiveMode(mode)
    if (onResponsiveModeUpdated)
      onResponsiveModeUpdated({
        boxBlockResponsiveMode: responsiveModeRef.current,
        responsiveMode: mode,
      }).catch(() => {
        setPropertyMobileResponsiveMode(prevPropertyMobileResponsiveMode)
      })
  }

  const checkMatchResponsiveMode = (property: P) => {
    if (getCurrentResponsiveMode() != responsiveModeRef.current) {
      if (responsiveModeRef.current == 'Desktop') {
        setDesktopResponsiveMode(ScreeenType.Desktop)
        setDesktopProperty(property)
      } else if (responsiveModeRef.current == 'Tablet') {
        setTabletResponsiveMode(ScreeenType.Tablet)
        setTabletProperty(property)
      } else {
        setMobileResponsiveMode(ScreeenType.Mobile)
        setMobileProperty(property)
      }
    }
  }

  return {
    value,
    valueRef,
    viewValue,
    setValue: (property: P) => {
      checkMatchResponsiveMode(property)

      let responsiveModeProperty: ScreeenType
      if (responsiveModeRef.current == 'Desktop') {
        responsiveModeProperty = propertyDesktopResponsiveModeRef.current
      } else if (responsiveModeRef.current == 'Tablet') {
        responsiveModeProperty = propertyTabletResponsiveModeRef.current
      } else {
        responsiveModeProperty = propertyMobileResponsiveModeRef.current
      }
      const prevValue = valueRef.current

      setTemporaryDesktopProperty(undefined)
      setTemporaryTabletProperty(undefined)
      setTemporaryMobileProperty(undefined)

      if (responsiveModeRef.current == 'Desktop') {
        if (propertyDesktopResponsiveModeRef.current == 'Desktop') setDesktopProperty(property)
        if (propertyDesktopResponsiveModeRef.current == 'Tablet') setTabletProperty(property)
        if (propertyDesktopResponsiveModeRef.current == 'Mobile') setMobileProperty(property)
      }
      if (responsiveModeRef.current == 'Tablet') {
        if (propertyTabletResponsiveModeRef.current == 'Desktop') setDesktopProperty(property)
        if (propertyTabletResponsiveModeRef.current == 'Tablet') setTabletProperty(property)
        if (propertyTabletResponsiveModeRef.current == 'Mobile') setMobileProperty(property)
      }
      if (responsiveModeRef.current == 'Mobile') {
        if (propertyMobileResponsiveModeRef.current == 'Desktop') setDesktopProperty(property)
        if (propertyMobileResponsiveModeRef.current == 'Tablet') setTabletProperty(property)
        if (propertyMobileResponsiveModeRef.current == 'Mobile') setMobileProperty(property)
      }

      if (onValueUpdated) {
        onValueUpdated({ value: property, responsiveMode: responsiveModeProperty }).catch(() => {
          if (responsiveModeRef.current == 'Desktop') {
            if (propertyDesktopResponsiveModeRef.current == 'Desktop') setDesktopProperty(prevValue)
            if (propertyDesktopResponsiveModeRef.current == 'Tablet') setTabletProperty(prevValue)
            if (propertyDesktopResponsiveModeRef.current == 'Mobile') setMobileProperty(prevValue)
          }
          if (responsiveModeRef.current == 'Tablet') {
            if (propertyTabletResponsiveModeRef.current == 'Desktop') setDesktopProperty(prevValue)
            if (propertyTabletResponsiveModeRef.current == 'Tablet') setTabletProperty(prevValue)
            if (propertyTabletResponsiveModeRef.current == 'Mobile') setMobileProperty(prevValue)
          }
          if (responsiveModeRef.current == 'Mobile') {
            if (propertyMobileResponsiveModeRef.current == 'Desktop') setDesktopProperty(prevValue)
            if (propertyMobileResponsiveModeRef.current == 'Tablet') setTabletProperty(prevValue)
            if (propertyMobileResponsiveModeRef.current == 'Mobile') setMobileProperty(prevValue)
          }
        })
      }
    },

    temporaryValue,
    setTemporary: (property: P | undefined) => {
      if (typeof property !== 'undefined') checkMatchResponsiveMode(property)
      if (responsiveModeRef.current == 'Desktop') {
        if (propertyDesktopResponsiveModeRef.current == 'Desktop') setTemporaryDesktopProperty(property)
        if (propertyDesktopResponsiveModeRef.current == 'Tablet') setTemporaryTabletProperty(property)
        if (propertyDesktopResponsiveModeRef.current == 'Mobile') setTemporaryMobileProperty(property)
      }
      if (responsiveModeRef.current == 'Tablet') {
        if (propertyTabletResponsiveModeRef.current == 'Desktop') setTemporaryDesktopProperty(property)
        if (propertyTabletResponsiveModeRef.current == 'Tablet') setTemporaryTabletProperty(property)
        if (propertyTabletResponsiveModeRef.current == 'Mobile') setTemporaryMobileProperty(property)
      }
      if (responsiveModeRef.current == 'Mobile') {
        if (propertyMobileResponsiveModeRef.current == 'Desktop') setTemporaryDesktopProperty(property)
        if (propertyMobileResponsiveModeRef.current == 'Tablet') setTemporaryTabletProperty(property)
        if (propertyMobileResponsiveModeRef.current == 'Mobile') setTemporaryMobileProperty(property)
      }
    },

    isDrag: propertyIsDrag,
    setIsDrag: (b: boolean) => setPropertyIsDrag(b),

    controls: () => {
      const checkedModes = responsiveModes().map((mode, index) => {
        let isActive = false
        if (responsiveModeRef.current == 'Desktop') {
          if (propertyDesktopResponsiveModeRef.current == mode.mode) isActive = true
          if (propertyDesktopResponsiveModeRef.current == mode.mode) isActive = true
          if (propertyDesktopResponsiveModeRef.current == mode.mode) isActive = true
        }
        if (responsiveModeRef.current == 'Tablet') {
          if (propertyTabletResponsiveModeRef.current == mode.mode) isActive = true
          if (propertyTabletResponsiveModeRef.current == mode.mode) isActive = true
          if (propertyTabletResponsiveModeRef.current == mode.mode) isActive = true
        }
        if (responsiveModeRef.current == 'Mobile') {
          if (propertyMobileResponsiveModeRef.current == mode.mode) isActive = true
          if (propertyMobileResponsiveModeRef.current == mode.mode) isActive = true
          if (propertyMobileResponsiveModeRef.current == mode.mode) isActive = true
        }
        return {
          ...mode,
          isActive,
        }
      })

      const activeMode = checkedModes.find(mode => mode.isActive)

      return (
        <div
          title={propertyName}
          css={{
            position: 'relative',
          }}
          onClick={() => {
            setIsOpen(isOpen => !isOpen)
          }}
        >
          {isOpen ? (
            <div
              css={{
                width: 30,
                border: `1px solid ${colors.blue}`,
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 100,
                background: 'white',
                borderRadius: '2px',
                // boxShadow: '0 0 9px 1px rgba(0, 145, 227, 0.47)',
              }}
            >
              {checkedModes.map((mode, index) => {
                return (
                  <div
                    onClick={() => {
                      if (responsiveModeRef.current == 'Desktop') {
                        setDesktopResponsiveMode(mode.mode)
                      }
                      if (responsiveModeRef.current == 'Tablet') {
                        setTabletResponsiveMode(mode.mode)
                      }
                      if (responsiveModeRef.current == 'Mobile') {
                        setMobileResponsiveMode(mode.mode)
                      }
                    }}
                    css={{
                      willChange: 'background, color',
                      padding: 2,
                      width: '100%',
                      fontSize: long ? 10 : 14,
                      height: 25,
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        background: `rgba(0, 145, 227, ${mode.isActive ? '0.45' : '0.3'}) !important`,
                      },
                    }}
                    style={{
                      background: `rgba(0, 145, 227, ${mode.isActive ? '0.15' : '0'})`,
                    }}
                  >
                    {mode.text}
                  </div>
                )
              })}
            </div>
          ) : null}

          <ActionIcon size={20}>{activeMode ? activeMode.text : null}</ActionIcon>
        </div>
      )
    },

    setDesktopValue: (property: P) => setDesktopProperty(property),
    setTabletValue: (property: P) => setTabletProperty(property),
    setMobileValue: (property: P) => setMobileProperty(property),

    getCurrentResponsiveMode,
  }
}
