import { ActionIcon, Box, Divider, Group, Menu, Paper, SimpleGrid, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  IconArrowBackUp,
  IconCirclePlus,
  IconHandGrab,
  IconMedicalCross,
  IconMedicalCrossOff,
  IconPhone,
  IconSettings,
  IconTextSize,
  IconTrash,
  IconTrashX,
} from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import React, { useEffect, useRef, useState } from 'react'
import { List, arrayMove } from 'react-movable'
import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'
import { ChooseTarget } from 'syncComponents/ChooseTarget/ChooseTarget'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { cabinetColors, cabinetShadows, cabinetSizes } from 'utils/cabinetTheme'

import { trpc } from 'utils/trpc'

import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { EditorTextSync } from 'syncComponents/EditorTextSync/EditorTextSync'
import { colors } from 'utils/styles'

import { showNotification } from '@mantine/notifications'
import Link from 'next/link'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { LinkSync } from 'syncComponents/LinkSync/LinkSync'

const inputWidth = 600
const inputSideWidth = 170

export const BackFormButton = ({ onClick, color }: { onClick?: () => void; color?: string }) => {
  return (
    <div
      css={{
        color: color,
        textAlign: 'center',
        fontSize: 14,
        cursor: 'pointer',
        width: '100%',
        opacity: 0.5,
      }}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      Назад
    </div>
  )
}

export function SiteFormsSync({
  onClickChooseButton,
  onClose,
  linkType,
}: {
  onClose?: () => void
  onClickChooseButton?: ({}: { formID: string }) => void
  linkType?: true
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const createForm = trpc.useMutation('user.createForm', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [currentForm, setCurrentForm] = useState<{ formID: string; selectID: 'Form1' } | undefined>(undefined)

  const thereIsFormInSite = site.formsV1.find(formLoop => formLoop.id == currentForm?.formID)

  return (
    <SimpleGrid spacing={0}>
      {currentForm && thereIsFormInSite ? (
        <>
          <SiteFormSyncMemo
            onBack={() => {
              setCurrentForm(undefined)
            }}
            formData={{
              modelID: currentForm.formID,
              selectID: currentForm.selectID,
            }}
          />
        </>
      ) : (
        <>
          <Group px="md" position="apart">
            <Group
              css={{
                alignCenter: 'flex-start',
              }}
            >
              <Title order={2}>ФОРМЫ</Title>
            </Group>
            <AccentButton
              loading={createForm.isLoading}
              onClick={() =>
                createForm.mutate({
                  siteID: site.id,
                })
              }
              leftIcon={<IconCirclePlus />}
            >
              Добавить форму
            </AccentButton>
          </Group>
          {site.formsV1.length ? (
            <>
              <Divider my={'lg'} />
              <SimpleGrid px="md">
                {site.formsV1.map(form => (
                  <SiteFormSimpleSync
                    onEdit={
                      !linkType
                        ? () => {
                            setCurrentForm({ formID: form.id, selectID: form.selectID })
                          }
                        : undefined
                    }
                    key={form.id}
                    onClickChooseButton={onClickChooseButton}
                    formData={{ modelID: form.id, selectID: form.selectID }}
                  />
                ))}
              </SimpleGrid>
            </>
          ) : null}
        </>
      )}
    </SimpleGrid>
  )
}

export function SiteFormSimpleSync({
  formData,
  showDeleteButton,
  onClickChooseButton,
  onEdit,
}: {
  formData: { modelID: string; selectID: 'Form1' }
  showDeleteButton?: true
  onClickChooseButton?: ({}: { formID: string }) => void
  onBack?: () => void
  parentColor?: string
  onEdit?: () => void
}) {
  const deleteForm = trpc.useMutation('user.deleteForm', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const form = useGetSelectData({ modelID: formData.modelID, selectID: formData.selectID })

  const updateForm = trpc.useMutation('user.updateForm', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const linkToForm = `/panel/sitesV2/${siteID}/forms/${form.id}`
  const titleJSX = (
    <Title
      css={{
        cursor: 'pointer',
      }}
      order={3}
    >
      {form.name || 'Без названия'}
    </Title>
  )
  const editJSX = (
    <GrayButton
      onClick={() => {
        if (onEdit) onEdit()
      }}
      white={true}
      leftIcon={<IconSettings />}
    >
      Редактировать
    </GrayButton>
  )
  return (
    <>
      <Paper
        css={{
          background: cabinetColors.background,
          border: `1px solid ${cabinetColors.border}`,
          boxShadow: 'none',
        }}
        p="md"
        pl="lg"
      >
        <Group position="apart">
          {onEdit ? (
            <>{titleJSX}</>
          ) : (
            <>
              <Link href={linkToForm}>{titleJSX}</Link>
            </>
          )}

          <Group>
            {onEdit ? (
              <>{editJSX}</>
            ) : (
              <>
                <Link href={linkToForm}>{editJSX}</Link>
              </>
            )}

            {onClickChooseButton ? (
              <GrayButton onClick={() => onClickChooseButton({ formID: form.id })}>Выбрать</GrayButton>
            ) : null}
            <GrayButton
              loading={deleteForm.isLoading}
              onClick={() =>
                deleteForm.mutate({
                  formID: form.id,
                })
              }
              leftIcon={<IconTrash color="red" />}
            >
              <Text color="red">Удалить</Text>
            </GrayButton>
          </Group>
        </Group>
      </Paper>
    </>
  )
}

export const SiteFormSyncMemo = React.memo(SiteFormSync, (prev, next) => {
  return prev.formData.modelID == next.formData.modelID
})

export function SiteFormSync({
  formData,
  showDeleteButton,
  onClickChooseButton,
  onBack,
  parentColor,
  backButton,
}: {
  formData: { modelID: string; selectID: 'Form1' }
  showDeleteButton?: true
  onClickChooseButton?: ({}: { formID: string }) => void
  onBack?: () => void
  parentColor?: string
  backButton?: boolean
}) {
  const updateForm = trpc.useMutation('user.updateForm', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteForm = trpc.useMutation('user.deleteForm', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createFormInput = trpc.useMutation('user.createFormInput', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateFormInput = trpc.useMutation('user.updateFormInput', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateFormInputOrder = trpc.useMutation('user.updateFormInputOrder', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const mainColor = useGetSelectDataUndefined({ modelID: site.mainColor?.id, selectID: 'Color1' })

  const form = useGetSelectData({ modelID: formData.modelID, selectID: formData.selectID })

  const updateFormForm = useForm({
    initialValues: {
      title: form.title,
      formButtonText: form.formButtonText,
    },
  })
  const [formButtonText, setFormButtonText] = useState(form.formButtonText)
  const isFocused = useRef(false)

  useEffect(() => {
    if (!isFocused.current) setFormButtonText(form.formButtonText)
  }, [form.formButtonText])

  const [extraInputs, setExtraInputs] = useState(form.extraInputs)
  useEffect(() => {
    setExtraInputs(form.extraInputs)
  }, [form.extraInputs])

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const { classes } = useMainStyles()

  const condition = showDeleteButton ? true : form.title == '<p></p>' || form.title == '' ? false : true

  const color = parentColor ? parentColor : mainColor ? mainColor.value : colors.buttonMantineDefaultColor

  const backButtonJSX = (
    <ActionIcon size="lg">
      <IconArrowBackUp />
    </ActionIcon>
  )

  return (
    <SimpleGrid spacing={0}>
      <Group
        css={{
          alignItems: 'flex-start',
        }}
        px={'lg'}
      >
        {backButton ? <Link href={`/panel/sitesV2/${siteID}/forms`}>{backButtonJSX}</Link> : null}
        {onBack ? (
          <Group
            onClick={() => {
              if (onBack) onBack()
            }}
          >
            {backButtonJSX}
          </Group>
        ) : null}
        <TextInputWithUpdate
          value={form.name}
          label={'Введите название формы'}
          placeholder={'Название формы'}
          onChange={async value => {
            await updateForm.mutate({
              formID: form.id,
              name: value,
            })
          }}
        />
      </Group>
      <Divider my="lg" />
      <SimpleGrid spacing={'md'} px="lg">
        <EditorTextSync
          defaultValue={form.title}
          placeholder={'Заголовок формы'}
          onChange={async value => {
            await updateForm.mutate({
              formID: form.id,
              title: value,
            })
          }}
        />

        <List
          lockVertically
          values={extraInputs}
          onChange={({ oldIndex, newIndex }) => {
            const newOrder = arrayMove(form.extraInputs, oldIndex, newIndex)
            setExtraInputs(newOrder)
            updateFormInputOrder.mutate({
              formID: formData.modelID,
              order: newOrder.map((input, index) => ({ id: input.id, order: index })),
            })
          }}
          renderList={({ children, props }) => (
            <SimpleGrid
              css={{
                width: '100%',
                border: `1px solid ${cabinetColors.border}`,
                background: cabinetColors.background,
                boxShadow: cabinetShadows.input,
                borderRadius: cabinetSizes.radius,
              }}
              p="sm"
              {...props}
            >
              <Box px={'sm'}>
                <SimpleGrid
                  css={{
                    maxWidth: inputWidth,
                  }}
                >
                  <Title order={3}>Поля ввода</Title>
                  {children}

                  <GrayButton
                    onClick={() =>
                      createFormInput.mutate({
                        formID: form.id,
                        order: form.extraInputs.length + 1,
                      })
                    }
                    loading={createFormInput.isLoading}
                    white={true}
                    leftIcon={<IconCirclePlus />}
                    css={{
                      maxWidth: inputWidth - inputSideWidth,
                    }}
                  >
                    Добавить поле ввода
                  </GrayButton>
                </SimpleGrid>
              </Box>
              <Divider my="sm" />
              <Group px={'sm'}>
                <TextInputWithUpdate
                  styles={{
                    root: {
                      width: inputWidth - inputSideWidth,
                    },
                  }}
                  value={form.formButtonText}
                  label={'Текст кнопки'}
                  placeholder={'например: "Отправить"'}
                  onChange={async value => {
                    await updateForm.mutate({
                      formID: form.id,
                      formButtonText: value,
                    })
                  }}
                />
              </Group>
            </SimpleGrid>
          )}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
                width: '100%',
              }}
              key={value.id}
              {...props}
            >
              <SiteFormExtraInputSync
                key={value.id}
                formInputData={{
                  modelID: value.id,
                  selectID: value.selectID,
                }}
              />
            </div>
          )}
        />
      </SimpleGrid>
      <Divider my="lg" />
      <Group px="lg">
        <ChooseTarget parentType="form" parentID={form.id} targetID={form.target?.id} />
        <Menu
          width={250}
          opened={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          closeOnItemClick={false}
          closeOnClickOutside={false}
          transitionDuration={0}
          withinPortal={true}
          shadow="md"
        >
          <Menu.Target>
            <Group>
              <GrayButton onClick={() => setMenuIsOpen(true)}>Перенаправление после заявки</GrayButton>
            </Group>
          </Menu.Target>

          <Menu.Dropdown
            className={classes.hideScrollbar}
            css={{
              maxHeight: 400,
              overflowY: 'auto',
            }}
          >
            <LinkSync
              onClose={setMenuIsOpen}
              pinID={form.id}
              type="form"
              linkID={form.link?.id}
              selectID={form.link?.selectID}
            />
          </Menu.Dropdown>
        </Menu>
      </Group>
    </SimpleGrid>
  )
}

function SiteFormExtraInputSync({ formInputData }: { formInputData: { modelID: string; selectID: 'ExtraInput1' } }) {
  const updateFormInput = trpc.useMutation('user.updateFormInput', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteFormInput = trpc.useMutation('user.deleteFormInput', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const formInput = useGetSelectData({ modelID: formInputData.modelID, selectID: formInputData.selectID })

  const form = useForm({
    initialValues: {
      placeholder: formInput.placeholder,
    },
  })
  const [placeholder, setPlaceholder] = useState(formInput.placeholder)
  const isFocused = useRef(false)

  useEffect(() => {
    if (!isFocused.current) setPlaceholder(formInput.placeholder)
  }, [formInput.placeholder])

  return (
    <Box
      css={{
        maxWidth: '100%',
      }}
    >
      <CalcWidth
        customCSS={{
          alignItems: 'center',
          alignContent: 'center',
          maxWidth: '100%',
        }}
        width={inputSideWidth}
        leftNode={
          <TextInput
            onChange={async event => {
              setPlaceholder(event.target.value)
              await updateFormInput.mutate({
                formInputID: formInput.id,
                placeholder: event.target.value,
              })
            }}
            onFocus={() => {
              isFocused.current = true
            }}
            onBlur={() => {
              isFocused.current = false
            }}
            css={{
              width: '100%',
            }}
            required
            placeholder="Заполните имя поля: номер, город..."
            value={placeholder}
            // {...form.getInputProps('placeholder')}
          />
        }
        rightNode={
          <Group spacing={8} position="right">
            <ActionIcon
              size={'md'}
              onClick={async () => {
                if (formInput.isRequired) {
                  await updateFormInput.mutate({
                    formInputID: formInput.id,
                    isRequired: false,
                  })
                } else {
                  await updateFormInput.mutate({
                    formInputID: formInput.id,
                    isRequired: true,
                  })
                }
              }}
              title={formInput.isRequired ? 'Обязательное поле' : 'Не обязательное поле'}
            >
              {formInput.isRequired ? <IconMedicalCross /> : <IconMedicalCrossOff />}
            </ActionIcon>

            <ActionIcon
              title={formInput.type == 'Text' ? 'Поле с текстом' : 'Поле с телефоном'}
              size={'md'}
              onClick={async () => {
                if (formInput.type == 'Text') {
                  await updateFormInput.mutate({
                    formInputID: formInput.id,
                    type: 'Phone',
                  })
                } else if (formInput.type == 'Phone') {
                  await updateFormInput.mutate({
                    formInputID: formInput.id,
                    type: 'Text',
                  })
                }
              }}
            >
              {formInput.type == 'Text' ? <IconTextSize /> : <IconPhone />}
            </ActionIcon>
            <ActionIcon title="Поменять порядок полей ввода" size={'md'} data-movable-handle>
              <IconHandGrab />
            </ActionIcon>
            <ActionIcon
              title="Удалить поле"
              loading={deleteFormInput.isLoading}
              // color="red"
              onClick={() =>
                deleteFormInput.mutate({
                  formInputID: formInput.id,
                })
              }
              size={'md'}
            >
              <IconTrashX color={'red'} />
            </ActionIcon>
          </Group>
        }
      />
    </Box>
  )
}
