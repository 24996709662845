import { Button, Container, Group, MultiSelect, Paper, SimpleGrid, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { SiteContext } from 'contexts/SiteContextProvider'
import { useContext } from 'react'
import { ComplexTargetSelect, TargetsSelect } from 'server/selects'
import { compareStringArray } from 'utils/compareStringArray'
import { trpc } from 'utils/trpc'

export function SiteTargets() {
  const site = useContext(SiteContext)

  const createComplexTarget = trpc.useMutation('user.createComplexTarget')

  const form = useForm({
    initialValues: {
      yandexMetrikaTargetId: '',
    },
    validate: {
      yandexMetrikaTargetId: value => (value ? null : 'укажи имя цели метрики'),
    },
  })

  return (
    <Container py="md">
      <Group mb={'md'} position="apart">
        <Title order={3}>Комплексные цели</Title>
        <form
          onSubmit={form.onSubmit(async values => {
            createComplexTarget.mutate({
              yandexMetrikaTargetId: values.yandexMetrikaTargetId,
              siteID: site.id,
            })
          })}
        >
          <Group>
            <TextInput
              size="xs"
              required
              placeholder="название цели в метрике"
              {...form.getInputProps('yandexMetrikaTargetId')}
            />
            <Button size="xs" loading={createComplexTarget.isLoading} type="submit">
              Создать коплексную цель
            </Button>
          </Group>
        </form>
      </Group>
      <SimpleGrid cols={2}>
        {site.complexTargets.map(complexTarget => (
          <Paper key={complexTarget.id} withBorder p={'md'}>
            <ComplexTarget {...complexTarget} />
          </Paper>
        ))}
      </SimpleGrid>
    </Container>
  )
}

function ComplexTarget(complexTarget: NonNullable<ComplexTargetSelect>) {
  const createAndOrTarget = trpc.useMutation('user.createAndOrTarget')

  return (
    <SimpleGrid cols={1}>
      <div>Имя цели: {complexTarget.yandexMetrikaTargetId}</div>

      <Group>
        <Button
          loading={createAndOrTarget.isLoading}
          onClick={() =>
            createAndOrTarget.mutate({
              type: 'and',
              complexTargetID: complexTarget.id,
            })
          }
        >
          Создать "И" цели
        </Button>
        <Button
          loading={createAndOrTarget.isLoading}
          onClick={() =>
            createAndOrTarget.mutate({
              type: 'or',
              complexTargetID: complexTarget.id,
            })
          }
        >
          Создать "Или" цели
        </Button>
      </Group>
      <div>
        <SimpleGrid cols={1}>
          {complexTarget.andTargets.map(andTargets => {
            return (
              <div key={andTargets.id}>
                "И" цели {andTargets.targets ? <Target key={andTargets.id} {...andTargets.targets} /> : null}
              </div>
            )
          })}
          {complexTarget.orTargets.map(orTargets => {
            return (
              <div key={orTargets.id}>
                "Или" цели {orTargets.targets ? <Target key={orTargets.id} {...orTargets.targets} /> : null}
              </div>
            )
          })}
        </SimpleGrid>
      </div>
    </SimpleGrid>
  )
}

type TargetsGroups = {
  value: string
  label: string
  group: 'bySecondsLaunchEvent' | 'formSendedEvent' | 'pageOpenedEvent' | 'popupOpenedEvent'
}[]

function Target(targets: NonNullable<TargetsSelect>) {
  const site = useContext(SiteContext)

  const targetsGroups: TargetsGroups = []

  const updateTargets = trpc.useMutation('user.updateTargets')

  //   site.bySecondsLaunchEvent.map(bySecondsLaunchEvent => {
  //     targetsGroups.push({
  //       value: bySecondsLaunchEvent.id,
  //       label: `Запускается после ${bySecondsLaunchEvent.seconds} секунд`,
  //       group: 'bySecondsLaunchEvent',
  //     })
  //   })
  //   site.pageOpenedEvents.map(pageOpenedEvent => {
  //     targetsGroups.push({
  //       value: pageOpenedEvent.id,
  //       label: `Открыта страница /${pageOpenedEvent.page.pathname}`,
  //       group: 'pageOpenedEvent',
  //     })
  //   })
  site.popupOpenedEvent.map(popupOpenedEvent => {
    targetsGroups.push({
      value: popupOpenedEvent.id,
      label: `Открыт попап ${popupOpenedEvent.popupOpened.form ? `с формой${popupOpenedEvent.popupOpened.form.title}` : ''}${
        popupOpenedEvent.popupOpened.quiz ? `с квизом ${popupOpenedEvent.popupOpened.quiz.title}` : ''
      }`,
      group: 'popupOpenedEvent',
    })
  })
  site.formSendedEvent.map(formSendedEvent => {
    targetsGroups.push({
      value: formSendedEvent.id,
      label: `Отправлена форма ${formSendedEvent.formSended.title}`,
      group: 'formSendedEvent',
    })
  })

  const selectedTargets: string[] = []

  //   targets.bySecondsLaunchEvent.map(bySecondsLaunchEvent => selectedTargets.push(bySecondsLaunchEvent.id))
  //   targets.pageOpenedEvents.map(pageOpenedEvent => selectedTargets.push(pageOpenedEvent.id))
  targets.popupOpenedEvent.map(popupOpenedEvent => selectedTargets.push(popupOpenedEvent.id))
  targets.formSendedEvent.map(formSendedEvent => selectedTargets.push(formSendedEvent.id))

  const detectDeletedOrAddedTarget = (targetIds: string[]) => {
    const { type, id } = compareStringArray({ oldArray: selectedTargets, newArray: targetIds })

    if (type == 'added') {
      const targetData = targetsGroups.find(target => target.value == id)
      if (targetData) {
        updateTargets.mutate({
          type: 'connect',
          formSendedEventID: targetData.group == 'formSendedEvent' ? targetData.value : undefined,
          popupOpenedEventID: targetData.group == 'popupOpenedEvent' ? targetData.value : undefined,
          targetsID: targets.id,
        })
      }
    } else {
      const targetData = targetsGroups.find(target => target.value == id)

      if (targetData) {
        updateTargets.mutate({
          type: 'disconnect',
          formSendedEventID: targetData.group == 'formSendedEvent' ? targetData.value : undefined,
          popupOpenedEventID: targetData.group == 'popupOpenedEvent' ? targetData.value : undefined,
          targetsID: targets.id,
        })
      }
    }
  }

  return <MultiSelect onChange={detectDeletedOrAddedTarget} data={targetsGroups} value={selectedTargets} />
}
