export function compareStringArray({ newArray, oldArray }: { newArray: string[]; oldArray: string[] }): {
  type: 'deleted' | 'added'
  id: string
} {
  if (newArray.length > oldArray.length) {
    let addedId = ''
    newArray.map(newId => {
      const idExistInPrevArray = oldArray.find(oldId => oldId == newId)
      if (!idExistInPrevArray) addedId = newId
    })

    return {
      type: 'added',
      id: addedId,
    }
  } else {
    let deletedId = ''
    oldArray.map(oldId => {
      const idExistInPrevArray = newArray.find(newId => newId == oldId)
      if (!idExistInPrevArray) deletedId = oldId
    })
    return {
      type: 'deleted',
      id: deletedId,
    }
  }
}
