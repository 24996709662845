import { Interpolation, Theme, css, keyframes } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import { ImageComponentSelect } from 'server/selects'
import { getPathToFile } from 'utils/getPathToFile'

const bounce = keyframes`
  0% {
    background-color: rgba(255,255,255,0.1);
  }

  70% {
    background-color: rgba(0,0,0,0.1);
  }

  100% {
    background-color: rgba(255,255,255,0.1);
  }
`

export function ImagePreview({
  image,
  onDoubleClick,
  cssStyle,
}: {
  image: NonNullable<ImageComponentSelect>
  onDoubleClick?: () => void
  cssStyle?: Interpolation<Theme>
}) {
  const boxesRef = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (boxesRef.current) {
      setWidth(boxesRef.current.offsetWidth)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxesRef.current) setWidth(Math.ceil(boxesRef.current.offsetWidth))
      })
      resizeObserver.observe(boxesRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  if (image.isCover)
    return (
      <div
        onDoubleClick={() => (onDoubleClick ? onDoubleClick() : undefined)}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${getPathToFile({ fileName: image.image.name, folderName: image.image.folder })})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        css={cssStyle}
      ></div>
    )

  if (image.width == 0) return <div onClick={() => (onDoubleClick ? onDoubleClick() : undefined)}>Картинка сплющена</div>

  if (image.width == image.shellWidth && image.height == image.shellHeight && image.positionX == 0 && image.positionY == 0) {
    return (
      <img
        style={{
          width: '100%',
        }}
        loading="lazy"
        css={cssStyle}
        src={getPathToFile({ fileName: image.image.name, folderName: image.image.folder })}
      />
    )
  }

  return (
    <div
      onDoubleClick={() => (onDoubleClick ? onDoubleClick() : undefined)}
      ref={boxesRef}
      style={{
        // maxWidth: image.shellWidth,
        willChange: 'maxWidth',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
      css={cssStyle}
    >
      <div
        style={{
          willChange: 'paddingBottom',
          paddingBottom: `${(image.shellHeight / image.shellWidth) * 100}%`,
          // background: width == 0 ? '#eee' : undefined,
          // backgroundImage: 'linear-gradient(90deg, #eee 0%, #ddd 40%, #eee 80%)',
          // backgroundSize: '100%',
          borderRadius: 2,
        }}
        css={
          width == 0
            ? css`
                animation: ${bounce} 2s infinite;
              `
            : undefined
        }
      ></div>
      <div
        css={{
          willChange: 'transform',
          width: image.shellWidth,
          height: image.shellHeight,
          position: 'absolute',
          left: 0,
          top: 0,
          transformOrigin: 'left top',
        }}
        style={{
          transform: `scale(${width < image.shellWidth ? width / image.shellWidth : 1})`,
        }}
      >
        <div
          css={{
            willChange: 'transform',
            position: 'absolute',
            left: '50%',
            top: '50%',
            // border: '1px solid #0086ff',
          }}
          style={{
            transform: `translate(calc(-50% + ${image.positionX}px), calc(-50% + ${image.positionY}px))`,
          }}
        >
          <img
            alt=""
            loading="lazy"
            css={{
              willChange: 'width, height',
              display: 'block',
              pointerEvents: 'none',
              userSelect: 'none',
            }}
            style={{
              width: image.width,
              height: image.height,
            }}
            src={getPathToFile({ fileName: image.image.name, folderName: image.image.folder })}
          />
        </div>
      </div>
    </div>
  )
}
