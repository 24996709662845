import { IconPlus } from '@tabler/icons'
import { useGetSelectData } from 'generation/centerFrontedEngine'

import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'

import { colors } from 'utils/styles'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'

import { ActionIcon, Group, SimpleGrid, Text } from '@mantine/core'

export function ChooseSitePopupsSync({
  onChoosePopup,
  title = 'Выберите всплывашку',
}: {
  title?: string
  onChoosePopup: ({}: { popupID: string }) => void
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  return (
    <>
      <SimpleGrid spacing={8}>
        <Text
          css={{
            fontWeight: 'bold',
          }}
        >
          {title}
        </Text>
        {site.popupsV1.length ? (
          site.popupsV1.map(popup => (
            <PopupSync
              key={popup.id}
              onClick={() => {
                onChoosePopup({ popupID: popup.id })
              }}
              modelID={popup.id}
              selectID={popup.selectID}
            />
          ))
        ) : (
          <Text size="sm">Добавьте всплывашку в слоях</Text>
        )}
      </SimpleGrid>
    </>
  )
}
function PopupSync({ modelID, selectID, onClick }: { modelID: string; selectID: 'Popup1'; onClick?: () => void }) {
  const popup = useGetSelectData({ modelID, selectID })

  return (
    <>
      <SimpleGrid
        css={{
          cursor: 'pointer',
        }}
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        <CalcWidth
          width={40}
          leftNode={<Text>{popup.name}</Text>}
          rightNode={
            <Group position={'right'}>
              <ActionIcon color={colors.blue} onClick={() => {}} size={20}>
                <IconPlus />
              </ActionIcon>
            </Group>
          }
        />
      </SimpleGrid>
    </>
  )
}
