import { AspectRatio } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { trpc } from 'utils/trpc'

export const YouTubePlay = () => {
  return (
    <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
      <path
        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
        fill="#f00"
      ></path>
      <path d="M 45,24 27,14 27,34" fill="#fff"></path>
    </svg>
  )
}

export const getVideoIDFromYoutubeLink = ({ link }: { link: string }): { videoID: string; isShorts?: boolean } => {
  if (link) {
    try {
      if (link.indexOf('youtu.be') == -1) {
        if (link.includes('/shorts/')) {
          return {
            videoID: link.split('/shorts/')[1].split('?')[0],
            isShorts: true,
          }
        } else {
          let arrayParams = link.split('?')[1].split('&')
          for (let i = 0; i < arrayParams.length; i++) {
            let param = arrayParams[i]
            if (param.split('=')[0] == 'v') {
              return {
                videoID: param.split('=')[1],
              }
            }
          }
        }
      } else {
        return { videoID: link.split('youtu.be/')[1] }
      }
    } catch (error) {
      return { videoID: '' }
    }
  } else {
    return { videoID: '' }
  }
  return { videoID: '' }
}

export const getVideoYoutubePreviewByVideoID = ({ videoID }: { videoID: string }): string => {
  return `https://img.youtube.com/vi/${videoID}/sddefault.jpg` // sddefault maxresdefault

  // webp format: const imageURL = `https://img.youtube.com/vi_webp/${videoId}/hqdefault.webp`;
}

export function VideoComponentSync({
  modelID,
  selectID,
  borderRadius,
}: {
  modelID: string
  selectID: 'VideoComponent1'
  borderRadius: string
}) {
  const videoComponent = useGetSelectData({ modelID, selectID })

  const updatedVideoComponent = trpc.useMutation('user.box.updatedVideoComponent', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { videoID, isShorts } = getVideoIDFromYoutubeLink({ link: videoComponent.videoURL })

  const imageURL = getVideoYoutubePreviewByVideoID({ videoID }) // get the image URL

  return (
    <AspectRatio
      ratio={isShorts ? 9 / 16 : 16 / 9}
      css={{
        position: 'relative',
        width: '100%',
        borderRadius,
        background: '#000',
        overflow: 'hidden',
      }}
    >
      <div css={{ width: '100%', height: '100%' }}>
        <div
          css={{
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translate(-50%, 0)',
            width: 300,
            maxWidth: 'calc(100% - 12px)',
            zIndex: 3,
            padding: 6,
          }}
        >
          <TextInputWithUpdate
            placeholder={'Ссылка на YouTube видео'}
            value={videoComponent.videoURL}
            size={'xs'}
            onChange={value => {
              updatedVideoComponent.mutate({
                videoComponentID: videoComponent.id,
                videoURL: value,
              })
            }}
          />
        </div>
        {videoID ? (
          <>
            <iframe
              src={`https://www.youtube.com/embed/${videoID}?controls=0`}
              title="YouTube video player"
              css={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {/* <img
              src={imageURL}
              css={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '100%',
                width: '100%',
                maxHeight: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
            <div
              css={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: 68,
                zIndex: 2,
              }}
            >
              <YouTubePlay />
            </div> */}
          </>
        ) : null}
      </div>
    </AspectRatio>
  )
}
