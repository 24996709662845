import { Group, SimpleGrid, Text, createStyles } from '@mantine/core'
import {
  IconBrandPagekit,
  IconForms,
  IconLayoutList,
  IconMessageCircle,
  IconPlugConnected,
  IconSettings,
  IconZoomQuestion,
} from '@tabler/icons'

import { SiteFaviconSync } from 'syncComponents/SiteFaviconSync/SiteFaviconSync'
import { SitePagePathSync } from 'syncComponents/SitePages/SitePages'
import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData } from 'generation/centerFrontedEngine'
import Link from 'next/link'
import React from 'react'

import { mqBox } from 'config/mq'

type SitePage = 'pages' | 'tables' | 'settings' | 'intergrations' | 'forms' | 'quizzes' | 'telegram' | 'leads'

const siteInnerPages: {
  tab: SitePage
  name: string
  icon: (color: string) => React.ReactNode
}[] = [
  {
    tab: 'pages',
    name: 'Страницы',
    icon: (color: string) => (
      <>
        <IconBrandPagekit color={color} />
      </>
    ),
  },
  {
    tab: 'tables',
    name: 'CMS',
    icon: (color: string) => (
      <>
        <IconLayoutList color={color} />
      </>
    ),
  },
  {
    tab: 'leads',
    name: 'Заявки',
    icon: (color: string) => (
      <>
        <IconMessageCircle color={color} />
      </>
    ),
  },
  {
    tab: 'forms',
    name: 'Формы',
    icon: (color: string) => (
      <>
        <IconForms color={color} />
      </>
    ),
  },
  {
    tab: 'quizzes',
    name: 'Квизы',
    icon: (color: string) => (
      <>
        <IconZoomQuestion color={color} />
      </>
    ),
  },
  {
    tab: 'intergrations',
    name: 'Интеграции',
    icon: (color: string) => (
      <>
        <IconPlugConnected color={color} />
      </>
    ),
  },
  {
    tab: 'settings',
    name: 'Настройки',
    icon: (color: string) => (
      <>
        <IconSettings color={color} />
      </>
    ),
  },
]

const menuWidth = 250

export function SiteMenu({ activeTab, children }: { activeTab: SitePage; children: React.ReactNode }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const { classes: classesForTab } = createStyles({
    tab: {
      border: 'none',
      background: 'none',
      margin: '0 8px 0 0',
    },
  })()

  const siteInfoHeight = 36

  return (
    <div
      css={{
        position: 'relative',
        zIndex: 1000,
        height: '100%',
        width: '100%',
        borderBottom: `1px solid ${cabinetColors.close}`,
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <div
        css={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Group
          css={{
            height: siteInfoHeight,
            alignItems: 'center',
            borderBottom: `1px solid ${cabinetColors.close}`,
            width: '100%',
          }}
          px={12}
          position={'apart'}
        >
          <Group
            css={{
              alignItems: 'center',
            }}
            spacing={8}
          >
            {site.favicon ? <SiteFaviconSync selectID={site.favicon.selectID} imageID={site.favicon.id} /> : null}
            <Text
              css={{
                lineHeight: '100%',
              }}
              size="sm"
              weight={600}
            >
              {site.name}
            </Text>
          </Group>
          {site.domain ? <SitePagePathSync domainID={site.domain.id} selectID={site.domain.selectID} pagePathName="" /> : null}
        </Group>

        <div
          css={{
            height: `calc(100% - ${siteInfoHeight}px)`,
            overflowY: 'auto',
            display: 'flex',
            alignItems: 'stretch',
            flexWrap: 'wrap',
          }}
        >
          <div
            css={{
              borderRight: 'none',
              height: 'auto',
              width: '100%',
              borderBottom: `1px solid ${cabinetColors.close}`,
              [mqBox.desktop]: {
                width: menuWidth,
                height: '100%',
                borderRight: `1px solid ${cabinetColors.close}`,
                borderBottom: 'none',
                overflowY: 'auto',
                flexWrap: 'wrap',
              },
            }}
          >
            <>
              <SimpleGrid p={12}>
                {/* <SimpleGrid
              css={{
                boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.08)',
                background: 'white',
                borderRadius: 8,
                padding: 12,
              }}
              spacing={8}
            >
            </SimpleGrid> */}

                <Group
                  css={{
                    gap: 12,
                    display: 'flex',
                    flexWrap: 'wrap',
                    [mqBox.desktop]: {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      display: 'flex',
                      gap: 4,
                    },
                  }}
                >
                  {siteInnerPages.map(tab => {
                    return (
                      <Link href={`/panel/sitesV2/${siteID}/${tab.tab == 'pages' ? '' : tab.tab}`}>
                        <Group
                          css={{
                            cursor: 'pointer',
                            [mqBox.desktop]: {
                              border: `1px solid ${activeTab == tab.tab ? cabinetColors.border : 'transparent'}`,
                              padding: '16px 8px',
                              borderRadius: 8,
                              width: '100%',
                              display: 'block',
                              textAlign: 'left',
                              '&:hover': {
                                background: `white`,
                                border: `1px dashed ${cabinetColors.border}`,
                              },
                            },
                          }}
                          className={classesForTab.tab}
                        >
                          <Group
                            css={{
                              gap: 4,
                              [mqBox.desktop]: {
                                gap: 12,
                              },
                            }}
                          >
                            {tab.icon(activeTab == tab.tab ? '#065BFF' : '#969696')}
                            <Text
                              size={16}
                              css={{
                                color: activeTab == tab.tab ? '555454' : '#333',
                              }}
                            >
                              {tab.name}
                            </Text>
                          </Group>
                        </Group>
                      </Link>
                    )
                  })}
                </Group>
              </SimpleGrid>
            </>
          </div>
          <div
            css={{
              width: '100%',
              height: 'auto',
              position: 'relative',
              paddingTop: activeTab == 'intergrations' ? undefined : cabinetSizes.pageTop,
              paddingBottom: activeTab == 'intergrations' ? undefined : cabinetSizes.pageTop,
              [mqBox.desktop]: {
                width: `calc(100% - ${menuWidth}px)`,
                height: '100%',
                overflowY: 'auto',
              },
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
