import { IconArrowDown, IconArrowRight, IconEye, IconEyeOff, IconPlus, IconTrash } from '@tabler/icons'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import React, { useEffect, useRef } from 'react'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { CalcWidth } from 'syncComponents/CalcWidth/CalcWidth'
import { LayerBoxSyncMemo } from 'syncComponents/LayerSettingsSync/LayerSettingsSync'
import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'

import { useState } from 'react'

import { colors } from 'utils/styles'

import { ActionIcon, Group, Modal, SimpleGrid, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { BlockCollections, MySiteBlockCollections } from 'components/BlockCollections/BlockCollections'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { trpc } from 'utils/trpc'

const PopupActiveIDCenter = () => {
  let popupActiveID: undefined | string = undefined

  let listeners: Array<{
    callback: (isActive: boolean) => void
    popupID: string
    id: string
  }> = []

  type ListenerCallback = ({}: { popupID: string | undefined }) => void
  let listenerCallbacks: { callback: ListenerCallback; id: string }[] = []

  return {
    subscribe: ({ popupID, callback }: { popupID: string; callback: (isActive: boolean) => void }) => {
      const id = 'id' + Math.random().toString(16).slice(2)
      listeners.push({ callback, id, popupID })
      if (popupID == popupActiveID) callback(true)
      return () => {
        listeners = listeners.filter(popupListener => popupListener.id !== id)
      }
    },
    listener: ({ callback }: { callback: ListenerCallback }) => {
      const id = 'id' + Math.random().toString(16).slice(2)
      listenerCallbacks.push({ callback, id })
      if (popupActiveID) callback({ popupID: popupActiveID })
      return () => {
        listenerCallbacks = listenerCallbacks.filter(listener => listener.id !== id)
      }
    },
    setActiveID: ({ popupID }: { popupID?: string }) => {
      if (popupID) {
        if (popupActiveID) {
          listeners.map(listener => {
            if (listener.popupID == popupActiveID) listener.callback(false)
          })
        }
        popupActiveID = popupID
        listenerCallbacks.map(listenerCallback => listenerCallback.callback({ popupID }))
        listeners.map(listener => {
          if (listener.popupID == popupActiveID) listener.callback(true)
        })
      } else {
        listeners.map(listener => {
          if (listener.popupID == popupActiveID) listener.callback(false)
        })
        popupActiveID = popupID
        listenerCallbacks.map(listenerCallback => listenerCallback.callback({ popupID }))
      }
    },
  }
}

export const popupActiveIDCenter = PopupActiveIDCenter()

export const PagePopupsSyncMemo = React.memo(PagePopupsSync, (prev, next) => {
  return true
})
function PagePopupsSync() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const [popupIsOpened, setPopupIsOpened] = useState(false)

  const createPopup = trpc.useMutation('user.createPopup', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createBoxBlockWithPopup = trpc.useMutation('user.box.createBoxBlockWithPopup', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [chooseBlockIsOpen, setChooseBlockIsOpen] = useState(false)

  const [currentBlocks, setCurrentBlocks] = useState<string>('template')
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {chooseBlockIsOpen ? (
        <Modal
          size={1300} // isShowCreateFreeSite ? 800 : 400
          withinPortal={true}
          opened={chooseBlockIsOpen}
          onClose={() => setChooseBlockIsOpen(false)}
          // @ts-ignore
          ref={containerRef}
          title={
            <Group>
              <AccentButton
                loading={createBoxBlockWithPopup.isLoading}
                leftIcon={<IconPlus />}
                onClick={async () => {
                  await createBoxBlockWithPopup.mutateAsync({
                    siteID: site.id,
                    mode: activeResponsiveModeCenter.getResponsiveMode(),
                  })
                  setChooseBlockIsOpen(false)
                }}
              >
                Добавить чистый попап
              </AccentButton>
              {[
                { name: 'ШАБЛОННЫЕ БЛОКИ', value: 'template' },
                { name: 'БЛОКИ С ДРУГИХ САЙТОВ', value: 'other' },
              ].map(item => (
                <GrayButton
                  leftIcon={currentBlocks == item.value ? <IconArrowRight /> : undefined}
                  white={currentBlocks == item.value ? false : true}
                  onClick={() => {
                    setCurrentBlocks(item.value)
                  }}
                >
                  {item.name}
                </GrayButton>
              ))}
            </Group>
          }
        >
          <SimpleGrid>
            <SimpleGrid>
              {currentBlocks == 'template' ? (
                <BlockCollections
                  onCopied={() => {
                    setChooseBlockIsOpen(false)
                  }}
                  parentRef={containerRef}
                  pinToPopup={true}
                />
              ) : null}
              {currentBlocks == 'other' ? (
                <MySiteBlockCollections
                  onCopied={() => {
                    setChooseBlockIsOpen(false)
                  }}
                  parentRef={containerRef}
                  pinToPopup={true}
                />
              ) : null}
            </SimpleGrid>
          </SimpleGrid>
        </Modal>
      ) : null}
      <SimpleGrid
        css={{
          borderBottom: '1px solid #eee',
        }}
        p={12}
        spacing={8}
      >
        <div
          css={{
            cursor: 'pointer',
          }}
          onClick={() => setPopupIsOpened(opened => !opened)}
        >
          <CalcWidth
            width={60}
            leftNode={
              <Text
                css={{
                  color: '#333',
                  padding: '2px 0',
                  fontWeight: 'bold',
                }}
                size="sm"
              >
                Всплывашки
              </Text>
            }
            rightNode={
              <Group position={'right'}>
                <ActionIcon
                  onClick={async event => {
                    event.stopPropagation()
                    setChooseBlockIsOpen(true)
                  }}
                  size={20}
                >
                  <IconPlus color={colors.blue} />
                </ActionIcon>
                <ActionIcon onClick={() => {}} size={20}>
                  <IconArrowDown
                    css={{
                      transform: `rotate(${popupIsOpened ? '180deg' : '0deg'})`,
                    }}
                    color={colors.blue}
                  />
                </ActionIcon>
              </Group>
            }
          />
        </div>

        {popupIsOpened
          ? site.popupsV1.map(popup => <PopupSync key={popup.id} modelID={popup.id} selectID={popup.selectID} />)
          : null}
      </SimpleGrid>
    </>
  )
}
function PopupSync({ modelID, selectID }: { modelID: string; selectID: 'Popup1' }) {
  const popup = useGetSelectData({ modelID, selectID })

  const boxBlock = useGetSelectDataUndefined({ modelID: popup.boxBlock?.id, selectID: popup.boxBlock?.selectID })

  const [isActive, setIsActive] = useState(false)
  const updatePopup = trpc.useMutation('user.updatePopup')

  useEffect(() => {
    const unlisten = popupActiveIDCenter.subscribe({
      popupID: popup.id,
      callback: isActive => {
        setIsActive(isActive)
      },
    })
    return () => {
      unlisten()
    }
  }, [])
  const deletePopup = trpc.useMutation('user.deletePopup')

  return (
    <>
      <SimpleGrid>
        <CalcWidth
          width={120}
          leftNode={
            <TextInputWithUpdate
              unstyled={true}
              value={popup.name}
              placeholder={'Название всплывашки'}
              onChange={async value => {
                updatePopup.mutate({
                  popupID: popup.id,
                  name: value,
                })
              }}
            />
          }
          rightNode={
            <Group position={'right'}>
              <div
                css={{
                  width: 28,
                  height: 28,
                  display: 'flex',
                  gap: 2,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  position: 'relative',
                  alignItems: 'center',
                  alignContent: 'center',
                  borderRadius: 2,
                  border: `1px solid ${
                    popup.verticalPosition == 'Center' && popup.horizontalPosition == 'Center' ? colors.blue : 'rgba(0,0,0,0)'
                  }`,
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
                  <div
                    css={{
                      width: 6,
                      height: 6,
                      cursor: 'pointer',
                      background:
                        (n == 1 && popup.verticalPosition == 'Top' && popup.horizontalPosition == 'Left') ||
                        (n == 2 && popup.verticalPosition == 'Top' && popup.horizontalPosition == 'Center') ||
                        (n == 3 && popup.verticalPosition == 'Top' && popup.horizontalPosition == 'Right') ||
                        (n == 4 && popup.verticalPosition == 'Center' && popup.horizontalPosition == 'Left') ||
                        (n == 5 && popup.verticalPosition == 'Center' && popup.horizontalPosition == 'Center') ||
                        (n == 6 && popup.verticalPosition == 'Center' && popup.horizontalPosition == 'Right') ||
                        (n == 7 && popup.verticalPosition == 'Bottom' && popup.horizontalPosition == 'Left') ||
                        (n == 8 && popup.verticalPosition == 'Bottom' && popup.horizontalPosition == 'Center') ||
                        (n == 9 && popup.verticalPosition == 'Bottom' && popup.horizontalPosition == 'Right')
                          ? colors.blue
                          : undefined,
                      borderRadius: 2,
                      border: `1px solid ${colors.blue}`,
                    }}
                    onClick={() => {
                      if (n == 1) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Top',
                          horizontalPosition: 'Left',
                        })
                      }
                      if (n == 2) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Top',
                          horizontalPosition: 'Center',
                        })
                      }
                      if (n == 3) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Top',
                          horizontalPosition: 'Right',
                        })
                      }
                      if (n == 4) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Center',
                          horizontalPosition: 'Left',
                        })
                      }
                      if (n == 5) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Center',
                          horizontalPosition: 'Center',
                        })
                      }
                      if (n == 6) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Center',
                          horizontalPosition: 'Right',
                        })
                      }
                      if (n == 7) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Bottom',
                          horizontalPosition: 'Left',
                        })
                      }
                      if (n == 8) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Bottom',
                          horizontalPosition: 'Center',
                        })
                      }
                      if (n == 9) {
                        updatePopup.mutate({
                          popupID: popup.id,
                          verticalPosition: 'Bottom',
                          horizontalPosition: 'Right',
                        })
                      }
                    }}
                  ></div>
                ))}
              </div>
              <ActionIcon color={'red'} onClick={() => {}} size={20}>
                <IconTrash
                  onClick={() => {
                    deletePopup.mutate({ popupID: popup.id })
                  }}
                />
              </ActionIcon>
              <ActionIcon onClick={() => {}} size={20}>
                {isActive ? (
                  <IconEyeOff
                    onClick={() => {
                      popupActiveIDCenter.setActiveID({ popupID: undefined })
                    }}
                    color={colors.blue}
                  />
                ) : (
                  <IconEye
                    onClick={() => {
                      popupActiveIDCenter.setActiveID({ popupID: popup.id })
                    }}
                    color={colors.blue}
                  />
                )}
              </ActionIcon>
            </Group>
          }
        />

        {boxBlock && isActive ? (
          <div
            css={{
              borderRadius: 2,
              border: `2px solid ${colors.blue}`,
            }}
          >
            <LayerBoxSyncMemo
              boxData={{
                modelID: boxBlock.box.id,
                selectID: boxBlock.box.selectID,
              }}
              indent={0}
              parentIsHidden={false}
            />
          </div>
        ) : null}
      </SimpleGrid>
    </>
  )
}
