import {
  ActionIcon,
  ColorSwatch,
  Divider,
  Drawer,
  Group,
  Menu,
  Modal,
  Paper,
  SimpleGrid,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { IconArrowBackUp, IconCirclePlus, IconHandGrab, IconMinus, IconRefresh, IconSettings, IconTrash } from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { cabinetColors, cabinetShadows, cabinetSizes } from 'utils/cabinetTheme'

import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import { List, arrayMove } from 'react-movable'
import { ImageWithUpdateSync } from 'syncComponents/ImageWithUpdateSync/ImageWithUpdateSync'
import { SiteFormSyncMemo } from 'syncComponents/SiteFormsSync/SiteFormsSync'

import { ChooseSiteColorsSync } from 'syncComponents/ChooseSiteColorsSync/ChooseSiteColorsSync'
import { ChooseTarget } from 'syncComponents/ChooseTarget/ChooseTarget'
import { EditorTextSync } from 'syncComponents/EditorTextSync/EditorTextSync'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { SiteFormsSync } from 'syncComponents/SiteFormsSync/SiteFormsSync'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'

import { colors } from 'utils/styles'

import { showNotification } from '@mantine/notifications'
import { trpc } from 'utils/trpc'

const textEditorMinWidth = 250

export const minHeightQuizStep = 330
export const formWithAtQuiz = 300
export const quizMainGap = 16

export function SiteQuizzesSync({
  onClickChooseButton,
  isModal,
  onClose,
  linkType,
}: {
  isModal?: true
  onClickChooseButton?: ({}: { quizID: string }) => void
  onClose?: () => void
  linkType?: true
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const createQuiz = trpc.useMutation('user.createQuiz', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [currentQuiz, setCurrentQuiz] = useState<{ quizID: string; selectID: 'Quiz1' } | undefined>(undefined)

  const thereIsQuizInSite = site.quizzesV1.find(quizLoop => quizLoop.id == currentQuiz?.quizID)

  return (
    <>
      <SimpleGrid spacing={0}>
        {currentQuiz && thereIsQuizInSite ? (
          <>
            <QuizSyncMemo
              onBack={() => {
                setCurrentQuiz(undefined)
              }}
              modelID={currentQuiz.quizID}
              selectID={currentQuiz.selectID}
            />
          </>
        ) : (
          <>
            <Group px="md" position="apart">
              <Group
                css={{
                  alignCenter: 'flex-start',
                }}
              >
                <Title order={2}>КВИЗЫ</Title>
              </Group>
              <AccentButton
                loading={createQuiz.isLoading}
                onClick={() =>
                  createQuiz.mutate({
                    siteID: site.id,
                  })
                }
                leftIcon={<IconCirclePlus />}
              >
                Добавить квиз
              </AccentButton>
            </Group>
            {site.quizzesV1.length ? (
              <>
                <Divider my={'lg'} />
                <SimpleGrid px="md">
                  {site.quizzesV1.map(quiz => (
                    <SiteQuizSimpleSync
                      onEdit={
                        !linkType
                          ? () => {
                              setCurrentQuiz({ quizID: quiz.id, selectID: quiz.selectID })
                            }
                          : undefined
                      }
                      key={quiz.id}
                      onClickChooseButton={onClickChooseButton}
                      quizData={{ modelID: quiz.id, selectID: quiz.selectID }}
                    />
                  ))}
                </SimpleGrid>
              </>
            ) : null}
          </>
        )}
      </SimpleGrid>
    </>
  )
}

export function SiteQuizSimpleSync({
  quizData,
  showDeleteButton,
  onClickChooseButton,
  onEdit,
}: {
  quizData: { modelID: string; selectID: 'Quiz1' }
  showDeleteButton?: true
  onClickChooseButton?: ({}: { quizID: string }) => void
  onBack?: () => void
  parentColor?: string
  onEdit?: () => void
}) {
  const deleteQuiz = trpc.useMutation('user.deleteQuiz', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })

  const quiz = useGetSelectData({ modelID: quizData.modelID, selectID: quizData.selectID })

  const linkToQuiz = `/panel/sitesV2/${siteID}/quizzes/${quiz.id}`
  const titleJSX = (
    <Title
      onClick={() => {
        if (onEdit) onEdit()
      }}
      css={{
        cursor: 'pointer',
      }}
      order={3}
    >
      {quiz.name || 'Без названия'}
    </Title>
  )
  const editJSX = (
    <GrayButton
      onClick={() => {
        if (onEdit) onEdit()
      }}
      white={true}
      leftIcon={<IconSettings />}
    >
      Редактировать
    </GrayButton>
  )
  return (
    <>
      <Paper
        css={{
          background: cabinetColors.background,
          border: `1px solid ${cabinetColors.border}`,
          boxShadow: 'none',
        }}
        p="md"
        pl="lg"
      >
        <Group position="apart">
          {onEdit ? (
            <>{titleJSX}</>
          ) : (
            <>
              <Link href={linkToQuiz}>{titleJSX}</Link>
            </>
          )}

          <Group>
            {onEdit ? (
              <>{editJSX}</>
            ) : (
              <>
                <Link href={linkToQuiz}>{editJSX}</Link>
              </>
            )}

            {onClickChooseButton ? (
              <GrayButton onClick={() => onClickChooseButton({ quizID: quiz.id })}>Выбрать</GrayButton>
            ) : null}
            <GrayButton
              loading={deleteQuiz.isLoading}
              onClick={() =>
                deleteQuiz.mutate({
                  quizID: quiz.id,
                })
              }
              leftIcon={<IconTrash color="red" />}
            >
              <Text color="red">Удалить</Text>
            </GrayButton>
          </Group>
        </Group>
      </Paper>
    </>
  )
}

export const QuizSyncMemo = React.memo(QuizSync, (prev, next) => {
  return prev.modelID == next.modelID
})

export function QuizSync({
  modelID,
  selectID,
  onClickChooseButton,
  isModal,
  backButton,
  onBack,
}: {
  modelID: string
  selectID: 'Quiz1'
  onClickChooseButton?: ({}: { quizID: string }) => void
  isModal?: true
  onBack?: () => void
  backButton?: boolean
}) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const mainColor = useGetSelectDataUndefined({ modelID: site.mainColor?.id, selectID: 'Color1' })
  const quiz = useGetSelectData({ modelID, selectID })
  const form = useGetSelectDataUndefined({ modelID: quiz.form?.id, selectID: quiz.form?.selectID })

  const quizMainColor = useGetSelectDataUndefined({ modelID: quiz.mainColor?.id, selectID: quiz.mainColor?.selectID })
  const quizTextColor = useGetSelectDataUndefined({ modelID: quiz.textColor?.id, selectID: quiz.textColor?.selectID })
  const quizButtonTextColor = useGetSelectDataUndefined({
    modelID: quiz.buttonTextColor?.id,
    selectID: quiz.buttonTextColor?.selectID,
  })
  const color = quizMainColor ? quizMainColor.value : mainColor ? mainColor.value : colors.buttonMantineDefaultColor
  const textColor = quizTextColor ? quizTextColor.value : '#333'

  const updateScalarQuiz = trpc.useMutation('user.updateScalarQuiz', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateQuizForm = trpc.useMutation('user.updateQuizForm', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const deleteQuiz = trpc.useMutation('user.deleteQuiz', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createQuizStep = trpc.useMutation('user.createQuizStep', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateQuizStepOrder = trpc.useMutation('user.updateQuizStepOrder', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const [steps, setSteps] = useState(quiz.steps)

  const updateColorQuiz = trpc.useMutation('user.updateColorQuiz', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  useEffect(() => {
    setSteps(quiz.steps)
  }, [quiz.steps])

  const [step, setStep] = useState(0)

  const quizProgress = `${step + 1} из ${steps.length + 1} шагов`

  useEffect(() => {
    if (step > steps.length) {
      setStep(steps.length)
    }
  }, [steps.length])

  const [formsOpened, setFormsOpened] = useState(false)

  const [orderIsShow, setOrderIsShow] = useState(false)

  const currentStep = orderIsShow ? undefined : steps.find((stepLoop, index) => index == step)

  const incrementStep = () => {
    setStep(step => step + 1)
  }
  const decrementStep = () => {
    setStep(step => step - 1)
  }

  const isLastStep = step == steps.length

  const showTitleEdit = isModal ? true : quiz.title == '<p></p>' || quiz.title == '' ? false : true

  const { ref, height } = useElementSize()

  const [editFormIsOpened, setEditFormIsOpened] = useState(false)

  const backButtonJSX = (
    <ActionIcon size="lg">
      <IconArrowBackUp />
    </ActionIcon>
  )
  return (
    <>
      {editFormIsOpened && quiz.form ? (
        <Modal
          withinPortal
          size={800}
          transitionDuration={0}
          opened={editFormIsOpened}
          onClose={() => {
            setEditFormIsOpened(false)
          }}
        >
          <SiteFormSyncMemo
            formData={{
              modelID: quiz.form.id,
              selectID: quiz.form.selectID,
            }}
          />
        </Modal>
      ) : null}
      <SimpleGrid
        css={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Group
          css={{
            alignItems: 'flex-start',
          }}
          px={'lg'}
        >
          {backButton ? <Link href={`/panel/sitesV2/${siteID}/quizzes`}>{backButtonJSX}</Link> : null}
          {onBack ? (
            <Group
              onClick={() => {
                if (onBack) onBack()
              }}
            >
              {backButtonJSX}
            </Group>
          ) : null}
          <TextInputWithUpdate
            value={quiz.name ? quiz.name : ''}
            label={'Введите название квиза'}
            placeholder={'Название квиза'}
            onChange={async value => {
              await updateScalarQuiz.mutate({
                quizID: quiz.id,
                name: value,
              })
            }}
          />
        </Group>

        <Divider my="lg" />
        <SimpleGrid
          px="lg"
          css={{
            width: '100%',
          }}
        >
          <EditorTextSync
            placeholder={'Заголовок квиза'}
            defaultValue={quiz.title}
            onChange={async value => {
              await updateScalarQuiz.mutate({
                quizID: quiz.id,
                title: value,
              })
            }}
          />
        </SimpleGrid>
        <Divider my="lg" />

        <SimpleGrid px="lg">
          <Group position="apart">
            <Title order={3}>Шаги</Title>
            <Menu position="bottom-end" withArrow shadow="md" width={300}>
              <Menu.Target>
                <Group css={{}}>
                  <AccentButton leftIcon={<IconCirclePlus />}>Добавить шаг</AccentButton>
                </Group>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Добавить новый шаг</Menu.Label>

                <Menu.Item
                  onClick={() =>
                    createQuizStep.mutate({
                      quizID: quiz.id,
                      type: 'checkbox',
                    })
                  }
                  icon={<IconCirclePlus size={14} />}
                >
                  Шаг с выбором нескольких ответов
                </Menu.Item>

                <Menu.Item
                  onClick={() =>
                    createQuizStep.mutate({
                      quizID: quiz.id,
                      type: 'radio',
                    })
                  }
                  icon={<IconCirclePlus size={14} />}
                >
                  Шаг с выбором одного ответа
                </Menu.Item>

                <Menu.Item
                  onClick={() =>
                    createQuizStep.mutate({
                      quizID: quiz.id,
                      type: 'fieldInput',
                    })
                  }
                  icon={<IconCirclePlus size={14} />}
                >
                  Шаг с вводом ответа
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>

          <List
            lockVertically
            values={steps}
            onChange={({ oldIndex, newIndex }) => {
              const newOrder = arrayMove(quiz.steps, oldIndex, newIndex)
              setSteps(newOrder)
              updateQuizStepOrder.mutate({
                quizID: quiz.id,
                order: newOrder.map((step, index) => ({ id: step.id, order: index })),
              })
            }}
            renderList={({ children, props }) => <SimpleGrid {...props}>{children}</SimpleGrid>}
            renderItem={({ value, props, index }) => (
              <div
                css={{
                  zIndex: 10000,
                  position: 'relative',
                }}
                key={value.id}
                {...props}
              >
                <StepQuizSync
                  key={value.id}
                  modelID={value.id}
                  selectID={value.selectID}
                  orderIsShow={orderIsShow}
                  color={color}
                  index={typeof index == 'number' ? index : 0}
                  topHeight={height}
                />
              </div>
            )}
          />
        </SimpleGrid>
        <Divider my="lg" />
        <Group px="lg">
          {formsOpened ? (
            <Modal transitionDuration={0} size="xl" opened={formsOpened} onClose={() => setFormsOpened(false)}>
              <SiteFormsSync
                onClickChooseButton={async ({ formID }) => {
                  updateQuizForm.mutate({
                    quizID: quiz.id,
                    formID,
                  })
                  setFormsOpened(false)
                }}
              />
            </Modal>
          ) : null}
          {quiz.form ? (
            <>
              <Group>
                {form ? (
                  <Text>
                    Выбрана форма: <strong> {form.name}</strong>
                  </Text>
                ) : null}
                <ActionIcon onClick={() => setFormsOpened(true)} title={'Изменить форму'} size={'md'}>
                  <IconRefresh />
                </ActionIcon>
                <ActionIcon onClick={() => setEditFormIsOpened(true)} title={'Редактировать форму'} size={'md'}>
                  <IconSettings />
                </ActionIcon>
              </Group>
            </>
          ) : (
            <Group position="center">
              <GrayButton onClick={() => setFormsOpened(true)}>Выбрать форму</GrayButton>
            </Group>
          )}
        </Group>
        <Divider my="lg" />
        <Group px="lg">
          <ChooseSiteColorsSync
            currentColorID={quiz.mainColor?.id}
            onChooseColor={async ({ colorID }) => {
              updateColorQuiz.mutate({
                quizID: quiz.id,
                mainColorID: colorID,
              })
            }}
            onClearColor={async () => {
              updateColorQuiz.mutate({
                quizID: quiz.id,
                mainColorID: '',
              })
            }}
          >
            <GrayButton
              leftIcon={
                quizMainColor ? (
                  <ColorSwatch
                    component="button"
                    color={quizMainColor.value}
                    sx={{ color: '#fff', cursor: 'pointer' }}
                  ></ColorSwatch>
                ) : (
                  <IconCirclePlus
                    css={{
                      cursor: 'pointer',
                    }}
                  />
                )
              }
            >
              Основной цвет
            </GrayButton>
          </ChooseSiteColorsSync>
          <ChooseSiteColorsSync
            currentColorID={quiz.textColor?.id}
            onChooseColor={async ({ colorID }) => {
              updateColorQuiz.mutate({
                quizID: quiz.id,
                textColorID: colorID,
              })
            }}
            onClearColor={async () => {
              updateColorQuiz.mutate({
                quizID: quiz.id,
                textColorID: '',
              })
            }}
          >
            <GrayButton
              leftIcon={
                quizTextColor ? (
                  <ColorSwatch
                    component="button"
                    color={quizTextColor.value}
                    sx={{ color: '#fff', cursor: 'pointer' }}
                  ></ColorSwatch>
                ) : (
                  <ActionIcon>
                    <IconCirclePlus
                      css={{
                        cursor: 'pointer',
                      }}
                    />
                  </ActionIcon>
                )
              }
            >
              Цвет текста
            </GrayButton>
          </ChooseSiteColorsSync>
          <ChooseSiteColorsSync
            currentColorID={quiz.textColor?.id}
            onChooseColor={async ({ colorID }) => {
              updateColorQuiz.mutate({
                quizID: quiz.id,
                buttonTextColorID: colorID,
              })
            }}
            onClearColor={async () => {
              updateColorQuiz.mutate({
                quizID: quiz.id,
                buttonTextColorID: '',
              })
            }}
          >
            <GrayButton
              leftIcon={
                quizButtonTextColor ? (
                  <ColorSwatch
                    component="button"
                    color={quizButtonTextColor.value}
                    sx={{ color: '#fff', cursor: 'pointer' }}
                  ></ColorSwatch>
                ) : (
                  <ActionIcon>
                    <IconCirclePlus
                      css={{
                        cursor: 'pointer',
                      }}
                    />
                  </ActionIcon>
                )
              }
            >
              Цвет текста в кнопках
            </GrayButton>
          </ChooseSiteColorsSync>
        </Group>
      </SimpleGrid>
    </>
  )
}

function StepQuizSync({
  modelID,
  selectID,
  orderIsShow,
  color,
  index,
  topHeight,
}: {
  modelID: string
  selectID: 'QuizStep1'
  orderIsShow?: boolean
  color: string
  index: number
  topHeight: number
}) {
  const deleteQuizStep = trpc.useMutation('user.deleteQuizStep', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateQuizStep = trpc.useMutation('user.updateQuizStep', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const createQuizStepRadioItem = trpc.useMutation('user.createQuizStepRadioItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createQuizStepCheckboxItem = trpc.useMutation('user.createQuizStepCheckboxItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const step = useGetSelectData({ modelID, selectID })

  const checkboxStep = useGetSelectDataUndefined({
    modelID: step.checkboxStepsV1?.id,
    selectID: step.checkboxStepsV1?.selectID,
  })
  const radioStep = useGetSelectDataUndefined({
    modelID: step.radioStepsV1?.id,
    selectID: step.radioStepsV1?.selectID,
  })
  const fieldInputStep = useGetSelectDataUndefined({
    modelID: step.fieldInputStep?.id,
    selectID: step.fieldInputStep?.selectID,
  })

  const updateQuizStepCheckbox = trpc.useMutation('user.updateQuizStepCheckbox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateQuizStepFieldInput = trpc.useMutation('user.updateQuizStepFieldInput', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const updateQuizStepRadio = trpc.useMutation('user.updateQuizStepRadio', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  return (
    <SimpleGrid
      onKeyDown={event => {
        event.stopPropagation()
      }}
      onKeyUp={event => {
        event.stopPropagation()
      }}
      css={{
        position: 'relative',
        background: cabinetColors.background,
        border: `1px solid ${cabinetColors.border}`,
        boxShadow: 'none',
        borderRadius: cabinetSizes.radius,
      }}
      spacing={0}
      py="md"
    >
      <Group position="apart" px="md">
        <Group
          css={{
            minWidth: textEditorMinWidth,
          }}
        >
          {checkboxStep ? (
            <EditorTextSync
              placeholder={'Заголовок шага'}
              defaultValue={checkboxStep.title}
              onChange={async value => {
                await updateQuizStepCheckbox.mutate({
                  quizStepCheckboxID: checkboxStep.id,
                  title: value,
                })
              }}
            />
          ) : null}
          {radioStep ? (
            <EditorTextSync
              placeholder={'Заголовок шага'}
              defaultValue={radioStep.title}
              onChange={async value => {
                await updateQuizStepRadio.mutate({
                  quizStepRadioID: radioStep.id,
                  title: value,
                })
              }}
            />
          ) : null}
          {fieldInputStep ? (
            <EditorTextSync
              placeholder={'Заголовок шага'}
              defaultValue={fieldInputStep.title}
              onChange={async value => {
                await updateQuizStepFieldInput.mutate({
                  quizStepFieldInputID: fieldInputStep.id,
                  title: value,
                })
              }}
            />
          ) : null}
        </Group>
        <Group>
          <ActionIcon title={'Сместить шаг'} data-movable-handle>
            <IconHandGrab />
          </ActionIcon>

          <GrayButton
            onClick={() =>
              deleteQuizStep.mutate({
                quizStepID: step.id,
              })
            }
            leftIcon={<IconTrash color={'red'} />}
            small={true}
            white={true}
          >
            <Text color={'red'}>Удалить шаг</Text>
          </GrayButton>

          {step.isItemWithImage ? (
            <GrayButton
              onClick={() =>
                updateQuizStep.mutate({
                  quizStepID: step.id,
                  isItemWithImage: false,
                })
              }
              leftIcon={<IconMinus />}
              small={true}
              white={true}
            >
              Убрать картинки
            </GrayButton>
          ) : (
            <GrayButton
              onClick={() =>
                updateQuizStep.mutate({
                  quizStepID: step.id,
                  isItemWithImage: true,
                })
              }
              leftIcon={<IconCirclePlus />}
              small={true}
              white={true}
            >
              Добавить картинки
            </GrayButton>
          )}

          <Text>
            {step.checkboxStepsV1 ? 'выбор несколько' : null}
            {step.radioStepsV1 ? 'выбор одного' : null}
            {step.fieldInputStep ? 'поле ввода' : null}
            {step.checkboxStepsV1 && step.radioStepsV1 ? 'Something wrong, tell to programmer' : ''}
          </Text>
        </Group>
      </Group>
      <Divider my="md" />

      <SimpleGrid px="md">
        {step.checkboxStepsV1 ? (
          <CheckboxStepQuizSync
            withImage={step.isItemWithImage}
            modelID={step.checkboxStepsV1.id}
            selectID={step.checkboxStepsV1.selectID}
          />
        ) : null}
        {step.radioStepsV1 ? (
          <RadioStepQuizSync
            withImage={step.isItemWithImage}
            modelID={step.radioStepsV1.id}
            selectID={step.radioStepsV1.selectID}
          />
        ) : null}
        {step.fieldInputStep ? (
          <FieldInputStepQuizSync modelID={step.fieldInputStep.id} selectID={step.fieldInputStep.selectID} />
        ) : null}

        {step.radioStepsV1 || step.checkboxStepsV1 ? (
          <Group>
            {' '}
            <GrayButton
              onClick={() => {
                if (step.checkboxStepsV1) {
                  createQuizStepCheckboxItem.mutate({
                    quizStepCheckboxID: step.checkboxStepsV1.id,
                  })
                }
                if (step.radioStepsV1) {
                  createQuizStepRadioItem.mutate({
                    quizStepRadioID: step.radioStepsV1.id,
                  })
                }
              }}
              leftIcon={<IconCirclePlus />}
              white={true}
            >
              Добавить вариант выбора
            </GrayButton>
          </Group>
        ) : null}
      </SimpleGrid>
    </SimpleGrid>
  )
}

function FieldInputStepQuizSync({ modelID, selectID }: { modelID: string; selectID: 'QuizFieldInputStep1' }) {
  const updateQuizStepFieldInput = trpc.useMutation('user.updateQuizStepFieldInput', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const fieldInputStep = useGetSelectData({ modelID, selectID })

  const [placeholder, setPlaceholder] = useState(fieldInputStep.placeholder)

  const isFocused = useRef(false)

  useEffect(() => {
    if (!isFocused.current) setPlaceholder(fieldInputStep.placeholder)
  }, [fieldInputStep.placeholder])

  return (
    <SimpleGrid spacing={'md'}>
      <TextInput
        label={'Текст подсказки'}
        onChange={async event => {
          setPlaceholder(event.target.value)
          await updateQuizStepFieldInput.mutate({
            quizStepFieldInputID: fieldInputStep.id,
            placeholder: event.target.value,
          })
        }}
        css={{
          width: '100%',
        }}
        onFocus={() => {
          isFocused.current = true
        }}
        onBlur={() => {
          isFocused.current = false
        }}
        size="lg"
        mb={'md'}
        placeholder="Введите..."
        value={placeholder}
      />
    </SimpleGrid>
  )
}

function RadioStepQuizSync({
  modelID,
  selectID,
  withImage,
}: {
  modelID: string
  selectID: 'QuizRadioStep1'
  withImage?: boolean
}) {
  const updateQuizStepRadio = trpc.useMutation('user.updateQuizStepRadio', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateQuizStepRadioItem = trpc.useMutation('user.updateQuizStepRadioItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const radioStep = useGetSelectData({ modelID, selectID })

  const updateQuizRadioItemOrder = trpc.useMutation('user.updateQuizRadioItemOrder', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const [items, setItems] = useState(radioStep.radioItemsV1)

  useEffect(() => {
    setItems(radioStep.radioItemsV1)
  }, [radioStep.radioItemsV1])

  return (
    <SimpleGrid spacing={'md'}>
      {items.length ? (
        <List
          lockVertically
          values={items}
          onChange={({ oldIndex, newIndex }) => {
            const newOrder = arrayMove(radioStep.radioItemsV1, oldIndex, newIndex)
            setItems(newOrder)
            updateQuizRadioItemOrder.mutate({
              radioStepID: radioStep.id,
              order: newOrder.map((step, index) => ({ id: step.id, order: index })),
            })
          }}
          renderList={({ children, props }) => (
            <SimpleGrid pt={10} {...props}>
              {children}
            </SimpleGrid>
          )}
          css={{
            gap: 16,
          }}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
              }}
              key={value.id}
              {...props}
            >
              <RadioItemQuizSync withImage={withImage} key={value.id} modelID={value.id} selectID={value.selectID} />
            </div>
          )}
        />
      ) : null}
    </SimpleGrid>
  )
}

function RadioItemQuizSync({
  modelID,
  selectID,
  withImage,
}: {
  modelID: string
  selectID: 'QuizRadioStepItem1'
  withImage?: boolean
}) {
  const updateQuizStepRadioItem = trpc.useMutation('user.updateQuizStepRadioItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteQuizStepRadioItem = trpc.useMutation('user.deleteQuizStepRadioItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const radioItem = useGetSelectData({ modelID, selectID })
  const [imagesOpened, setImagesOpened] = useState(false)

  return (
    <>
      <ImagesSync
        opened={imagesOpened}
        onClose={() => setImagesOpened(false)}
        imageChoosen={async ({ imageID, imageName }) => {
          updateQuizStepRadioItem.mutate({
            quizStepRadioItemID: radioItem.id,
            imageID,
          })
          setImagesOpened(false)
        }}
      />

      <Paper
        css={{
          border: `1px solid ${cabinetColors.border}`,
          boxShadow: cabinetShadows.input,
        }}
        p="md"
      >
        <Group
          css={{
            alignItems: 'center',
          }}
          position="apart"
        >
          <Group>
            {withImage ? (
              <ImageWithUpdateSync
                image={
                  radioItem.image
                    ? {
                        imageID: radioItem.image.id,
                        selectID: radioItem.image.selectID,
                      }
                    : undefined
                }
                onChoose={() => {
                  setImagesOpened(true)
                }}
                onClear={() => {
                  updateQuizStepRadioItem.mutate({
                    quizStepRadioItemID: radioItem.id,
                    imageID: null,
                  })
                }}
              />
            ) : null}
            <Group
              css={{
                widht: textEditorMinWidth,
              }}
            >
              <TextInputWithUpdate
                unstyled={true}
                value={radioItem.title}
                size="xs"
                placeholder={'Введите вариант ответа'}
                onChange={async value => {
                  await updateQuizStepRadioItem.mutate({
                    quizStepRadioItemID: radioItem.id,
                    title: value,
                  })
                }}
              />
            </Group>
          </Group>
          <Group spacing={4} position="right">
            <ChooseTarget parentType="radioItem" parentID={radioItem.id} targetID={radioItem.target?.id} />

            <ActionIcon title="Сместить" size={'xs'} data-movable-handle>
              <IconHandGrab />
            </ActionIcon>
            <ActionIcon
              title="Удалить вариант"
              size={'xs'}
              onClick={() => deleteQuizStepRadioItem.mutate({ quizStepRadioItemID: radioItem.id })}
              loading={deleteQuizStepRadioItem.isLoading}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        </Group>
      </Paper>
    </>
  )
}

function CheckboxStepQuizSync({
  modelID,
  selectID,
  withImage,
}: {
  modelID: string
  selectID: 'QuizCheckboxStep1'
  withImage?: boolean
}) {
  const updateQuizStepCheckboxItem = trpc.useMutation('user.updateQuizStepCheckboxItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const checkboxStep = useGetSelectData({ modelID, selectID })

  const updateQuizCheckboxItemOrder = trpc.useMutation('user.updateQuizCheckboxItemOrder', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const [items, setItems] = useState(checkboxStep.checkboxItemsV1)

  useEffect(() => {
    setItems(checkboxStep.checkboxItemsV1)
  }, [checkboxStep.checkboxItemsV1])

  return (
    <SimpleGrid>
      {items.length ? (
        <List
          lockVertically
          values={items}
          onChange={({ oldIndex, newIndex }) => {
            const newOrder = arrayMove(checkboxStep.checkboxItemsV1, oldIndex, newIndex)
            setItems(newOrder)
            updateQuizCheckboxItemOrder.mutate({
              checkboxStepID: checkboxStep.id,
              order: newOrder.map((step, index) => ({ id: step.id, order: index })),
            })
          }}
          renderList={({ children, props }) => (
            <SimpleGrid pt={10} {...props}>
              {children}
            </SimpleGrid>
          )}
          renderItem={({ value, props }) => (
            <div
              css={{
                zIndex: 10000,
                position: 'relative',
              }}
              key={value.id}
              {...props}
            >
              <CheckboxItemQuizSync withImage={withImage} key={value.id} modelID={value.id} selectID={value.selectID} />
            </div>
          )}
        />
      ) : null}
    </SimpleGrid>
  )
}

function CheckboxItemQuizSync({
  modelID,
  selectID,
  withImage,
}: {
  modelID: string
  selectID: 'QuizCheckboxStepItem1'
  withImage?: boolean
}) {
  const updateQuizStepCheckboxItem = trpc.useMutation('user.updateQuizStepCheckboxItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteQuizStepCheckboxItem = trpc.useMutation('user.deleteQuizStepCheckboxItem', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const checkboxItem = useGetSelectData({ modelID, selectID })
  const [imagesOpened, setImagesOpened] = useState(false)

  return (
    <>
      <ImagesSync
        opened={imagesOpened}
        onClose={() => setImagesOpened(false)}
        imageChoosen={async ({ imageID, imageName }) => {
          updateQuizStepCheckboxItem.mutate({
            quizStepCheckboxItemID: checkboxItem.id,
            imageID,
          })
          setImagesOpened(false)
        }}
      />
      <Paper
        p="md"
        css={{
          border: `1px solid ${cabinetColors.border}`,
          boxShadow: cabinetShadows.input,
        }}
      >
        <Group
          position="apart"
          css={{
            alignItems: 'center',
          }}
        >
          <Group>
            {withImage ? (
              <ImageWithUpdateSync
                image={
                  checkboxItem.image
                    ? {
                        imageID: checkboxItem.image.id,
                        selectID: checkboxItem.image.selectID,
                      }
                    : undefined
                }
                onChoose={() => {
                  setImagesOpened(true)
                }}
                onClear={() => {
                  updateQuizStepCheckboxItem.mutate({
                    quizStepCheckboxItemID: checkboxItem.id,
                    imageID: null,
                  })
                }}
              />
            ) : null}

            <Group
              css={{
                width: textEditorMinWidth,
              }}
            >
              <TextInputWithUpdate
                unstyled={true}
                placeholder={'Введите вариант ответа'}
                value={checkboxItem.title}
                size="xs"
                onChange={async value => {
                  await updateQuizStepCheckboxItem.mutate({
                    quizStepCheckboxItemID: checkboxItem.id,
                    title: value,
                  })
                }}
              />
            </Group>
          </Group>
          <Group spacing={4} position="right">
            <ChooseTarget parentType="checkboxItem" parentID={checkboxItem.id} targetID={checkboxItem.target?.id} />
            <ActionIcon title="Сместить" data-movable-handle>
              <IconHandGrab />
            </ActionIcon>

            <ActionIcon
              title="Удалить вариант"
              onClick={() => deleteQuizStepCheckboxItem.mutate({ quizStepCheckboxItemID: checkboxItem.id })}
              loading={deleteQuizStepCheckboxItem.isLoading}
              color={'red'}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        </Group>
      </Paper>
    </>
  )
}
